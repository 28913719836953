import React from 'react';

import '../App.css';
import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import { Empty } from 'antd';
import 'datatables.net';
import 'datatables.net-bs4';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
class Printarcheryworksheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event_data: [{}],
      relay: false,
      notrelay: false,
      windfinaldata: '',
      heatfinaldata: '',
      lanefinaldata: '',
      breakdata: '',
      recorddata: '',
      nationaldata: '',
      americasdata: '',
      americandata: '',
      njdcdata: '',
      trackdatas: [{}],
      prelimresultdata: '',
      prelimplacedata: '',
      finalplacedata: '',
      finalresultdata: '',
      norelayfinalplacedata: '',
      norelayfinalresultdata: '',
      norelayprelimplacedata: '',
      norelarprelimresultdata: '',
      qualifiesdata: '',
      loader: true,
      tracknjdcdatas: [{}],
    };
  }
  async componentDidMount() {
    var self = this;
    // var count = 0;
    const data = {
      meet_id: sessionStorage.getItem('ids'),
      selected_events: this.props.event,
    };

    await axios
      .post(`${url.url}/api/getarcheryworksheetprint/`, data, {})
      .then(function (response) {
        self.setState({ event_data: response.data, loader: false });
      })
      .catch(function (error) {});
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    document.getElementById('homelink').style.display = 'block';
  }
  render() {
    return (
      <>
        <Form>
          <CardHeader>
            <center>
              <h5 className='title'>Archery Results Worksheet</h5>
            </center>
          </CardHeader>
          {Object.keys(this.state.event_data).length == 0 ? (
            <Empty />
          ) : (
            <>
              <table class='worksheet_table'>
                <thead>
                  <tr class='worksheet_tr'>
                    <th>Target</th>
                    <th>Group</th>
                    <th>Last Name</th>
                    <th>First Name</th>
                    <th>Bib</th>
                    <th>Place</th>
                    <th>Mark</th>
                    <th>Class</th>
                    <th>Typebow</th>
                    <th>Bowstand</th>
                    <th>Age</th>
                    <th>Gender</th>
                    <th>QualMark</th>
                    <th>Qualifying Standard</th>
                    <th>country</th>
                    <th>EventName</th>
                    <th>Team Name</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.event_data.map((question, index) => (
                    <tr class='worksheet_tr'>
                      <td>{question.target}</td>
                      <td>{question.groups}</td>
                      <td>{question.last_name}</td>
                      <td>{question.first_name}</td>
                      <td>{question.bib_no}</td>
                      <td>{question.final_place}</td>
                      <td>{question.final_result}</td>
                      <td>{question.archery_class_value}</td>
                      <td>{question.typebow}</td>
                      <td>{question.bowstand}</td>
                      <td>{question.age_division}</td>
                      <td>{question.gender}</td>
                      <td>{question.qualifying_events}</td>
                      <td>{question.qualifying_standard}</td>
                      <td>{question.country}</td>
                      <td>{question.selected_events}</td>
                      <td>{question.team_name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </Form>
      </>
    );
  }
}

export default Printarcheryworksheet;
