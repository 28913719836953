import React from 'react';

import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import 'antd/dist/antd.css';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';

import Swal from 'sweetalert2';

import { Empty } from 'antd';

import 'antd/dist/antd.css';

const url = window.globalConfig;
class Entermeetresult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      meetdatas: [{}],
      vdatas: [{}],
      ahome: false,
      vhome: true,
      adetail: false,
      venuename: '',
      mid: '',
      vid: '',
      resultvalues: [{}],
      arcshow: false,
      fieldshow: false,
      ttennis: false,
      powerliftshow: false,
      trackshow: false,
      showswim: false,
      resultshow: false,
    };
    this.meetvenues = this.meetvenues.bind(this);
    this.back = this.back.bind(this);
    this.atheleteview = this.atheleteview.bind(this);
    this.saveresult = this.saveresult.bind(this);
    this.rpages = this.rpages.bind(this);
    this.fpages = this.fpages.bind(this);
    this.tabletennispage = this.tabletennispage.bind(this);
    this.powerlift = this.powerlift.bind(this);
    this.track = this.track.bind(this);
    this.swimming = this.swimming.bind(this);
    this.arcresult = this.arcresult.bind(this);
    this.trackresult = this.trackresult.bind(this);
    this.tennisresult = this.tennisresult.bind(this);
    this.swimresult = this.swimresult.bind(this);
    this.powerresult = this.powerresult.bind(this);
    this.fieldresult = this.fieldresult.bind(this);
  }

  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    var self = this;
    axios.get(`${url.url}/api/meets`, {}).then(function (response) {
      self.setState({
        meetdatas: response.data.data,
      });
    });

    axios.get(`${url.url}/api/venues`, {}).then(function (response) {
      self.setState({
        vdatas: response.data.data,
      });
    });
  }

  meetvenues(e) {
    var self = this;
    self.setState({
      ahome: false,
      vhome: true,
      mid: parseInt(this.state.meetdatas[e].id),
    });
  }

  atheleteview(e) {
    var v1 = parseInt(this.state.vdatas[e].id);
    axios
      .get(`${url.url}/api/getresultvenue/${v1}/${this.props.Mid}`, {})
      .then(function (response) {
        self.setState({
          resultvalues: response.data,
        });
      })
      .catch(function (error) {
        self.setState({
          resultvalues: [{}],
        });
      });

    var self = this;

    self.setState({
      venuename: this.state.vdatas[e].name,
      ahome: false,
      vhome: false,
      adetail: true,
      vid: this.state.vdatas[e].id,
    });
  }
  back() {
    var self = this;
    self.setState({
      ahome: false,
      vhome: true,
    });
  }

  saveresult() {
    Swal.fire({
      title: 'Sucess',
      text: 'Status updated successfully',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok!',
    });
  }

  // Result Page
  //Track
  trackresult() {
    const datatosend = {
      venue: this.state.tvenue,
      event: this.state.tevents,
      preliminary_results_point: this.state.tprepoint,
      preliminary_results_place: this.state.tpreplace,
      final_results_point: this.state.tfinalpoint,
      final_results_place: this.state.tfinalplace,
      created_by: 1,
    };

    // var self=this;
    axios.post(`${url.url}/api/results`, datatosend, {}).then(function (response) {
      if (response.status == 201) {
        Swal.fire({
          title: 'Success',
          text: 'successfully Updated',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      }
    });
  }

  //field
  fieldresult() {
    const datatosend = {
      venue: this.state.fvenue,
      event: this.state.fevents,
      preliminary_results_point: this.state.fprepoint,
      preliminary_results_place: this.state.fpreplace,
      final_results_point: this.state.ffinalpoint,
      final_results_place: this.state.ffinalplace,
      created_by: 1,
    };

    var self = this;
    axios.post(`${url.url}/api/results`, datatosend, {}).then(function (response) {
      if (response.status == 201) {
        Swal.fire({
          title: 'Success',
          text: 'successfully Updated',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      }
    });
  }

  //Swimming
  swimresult() {
    const datatosend = {
      venue: this.state.svenue,
      event: this.state.sevents,
      preliminary_results_point: this.state.sprepoint,
      preliminary_results_place: this.state.spreplace,
      final_results_point: this.state.sfinalpoint,
      final_results_place: this.state.sfinalplace,
      created_by: 1,
    };

    var self = this;
    axios.post(`${url.url}/api/results`, datatosend, {}).then(function (response) {
      if (response.status == 201) {
        Swal.fire({
          title: 'Success',
          text: 'successfully Updated',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      }
    });
  }
  //Archery
  arcresult() {
    const datatosend = {
      venue: this.state.Avenue,
      event: this.state.Aevents,
      preliminary_results_point: this.state.Aprepoint,
      preliminary_results_place: this.state.Apreplace,
      final_results_point: this.state.Afinalpoint,
      final_results_place: this.state.Afinalplace,
      created_by: 1,
    };

    var self = this;
    axios.post(`${url.url}/api/results`, datatosend, {}).then(function (response) {
      if (response.status == 201) {
        Swal.fire({
          title: 'Success',
          text: 'successfully Updated',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      }
    });
  }

  //powerlift
  powerresult() {
    const datatosend = {
      venue: this.state.pvenue,
      event: this.state.pevents,
      preliminary_results_point: this.state.pprepoint,
      preliminary_results_place: this.state.ppreplace,
      final_results_point: this.state.pfinalpoint,
      final_results_place: this.state.pfinalplace,
      created_by: 1,
    };

    var self = this;
    axios.post(`${url.url}/api/results`, datatosend, {}).then(function (response) {
      if (response.status == 201) {
        Swal.fire({
          title: 'Success',
          text: 'successfully Updated',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      }
    });
  }

  //table tennis
  tennisresult() {
    const datatosend = {
      venue: this.state.Tvenue,
      event: this.state.Tevents,
      preliminary_results_point: this.state.Tprepoint,
      preliminary_results_place: this.state.Tpreplace,
      final_results_point: this.state.Tfinalpoint,
      final_results_place: this.state.Tfinalplace,
      created_by: 1,
    };

    var self = this;
    axios.post(`${url.url}/api/results`, datatosend, {}).then(function (response) {
      if (response.status == 201) {
        Swal.fire({
          title: 'Success',
          text: 'successfully Updated',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      }
    });
  }
  //End of Result Page

  rpages(e) {
    var self = this;

    self.setState({
      arcshow: true,
    });
  }

  fpages() {
    var self = this;
    self.setState({
      fieldshow: true,
    });
  }

  tabletennispage() {
    var self = this;
    self.setState({
      ttennis: true,
    });
  }
  powerlift() {
    var self = this;
    self.setState({
      powerliftshow: true,
    });
  }
  track() {
    var self = this;
    self.setState({
      trackshow: true,
    });
  }
  swimming() {
    var self = this;
    self.setState({
      showswim: true,
    });
  }

  render() {
    if (this.state.venuename == 'Archery') {
      return (
        <div>
          <div className='content'>
            <Row>
              <Col md='8'>
                <button
                  type='button'
                  data-toggle='modal'
                  data-target='#newmeet'
                  class='btn btn-danger btn-round'
                  onClick={this.back}
                >
                  Back
                </button>
                {''}
                <Card>
                  <CardHeader>
                    <h5 className='title'>Enter Meets Results </h5>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <div class='form-group row'>
                        <div class='col-xs-5'>
                          <CardHeader>
                            <p>Search</p>
                            <input class='form-control' id='inputsm' placeholder='Search..' type='text' />
                          </CardHeader>
                        </div>
                      </div>
                      {/* table --------------------------------------------------- */}
                      {Object.keys(this.state.resultvalues[0]).length == 0 ? (
                        <Empty />
                      ) : (
                        <>
                          <Table id='my' responsive>
                            <thead>
                              <tr>
                                <th className='text-center'>#</th>
                                <th>bib</th>
                                <th>firstname</th>
                                <th>lastname</th>
                                {/* <th>Pit</th>
<th>groups</th>

<th>class</th>
<th>gender</th>
<th>age </th>

<th>preliminary result</th>
<th>preliminary place</th>
<th>final result</th>
<th>final place</th>


<th>qualifystandard</th>
<th>qualify</th>
<th>Break record</th>
<th>NDJC record</th>
<th>1</th>
<th>2</th>
<th>3</th>
<th>4</th>
<th>Event name</th>
<th>Type bow</th>
<th>bow stand</th>
<th>event team scoring</th> */}

                                <th class='disabled-sorting text-right'>Actions</th>
                              </tr>
                            </thead>
                            <tbody id='myTable'>
                              {this.state.resultvalues.map((question, index) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{question.bib_no}</td>
                                  <td>{question.user_name}</td>
                                  <td>{question.user_name}</td>

                                  {/* <td><input  name="Arpit"  onChange={e => this.setState({ Arpit: e.target.value })} type="text"></input></td>
<td><input  name="Argroups"  onChange={e => this.setState({ Argroups: e.target.value })} type="text"></input></td>
<td><input  name="Arclass"  onChange={e => this.setState({ Arclass: e.target.value })} type="text"></input></td>
<td><input  name="Argender"  onChange={e => this.setState({ Argender: e.target.value })} type="text"></input></td>
<td><input  name="Arage"  onChange={e => this.setState({ Arage: e.target.value })} type="text"></input></td>
<td><input  name="Arpresult"  onChange={e => this.setState({ Arpresult: e.target.value })} type="text"></input></td>
<td><input  name="Arpplace"  onChange={e => this.setState({ Arpplace: e.target.value })} type="text"></input></td>
<td><input  name="Arfresult"  onChange={e => this.setState({ Arfresult: e.target.value })} type="text"></input></td>
<td><input  name="Arfplace"  onChange={e => this.setState({ Arfplace: e.target.value })} type="text"></input></td>
<td><input  name="Arqtsd"  onChange={e => this.setState({ Arqtsd: e.target.value })} type="text"></input></td>
<td><input  name="Arqlfy"  onChange={e => this.setState({ Arqlfy: e.target.value })} type="text"></input></td>
<td><input  name="Arbreakrecord"  onChange={e => this.setState({ Arbreakrecord: e.target.value })} type="text"></input></td>
<td><input  name="Arndjc"  onChange={e => this.setState({ Arndjc: e.target.value })} type="text"></input></td>
<td><input  name="Ar1"  onChange={e => this.setState({ Ar1: e.target.value })} type="text"></input></td>
<td><input  name="Ar2"  onChange={e => this.setState({ Ar2: e.target.value })} type="text"></input></td>
<td><input  name="Ar3"  onChange={e => this.setState({ Ar3: e.target.value })} type="text"></input></td>
<td><input  name="Ar4"  onChange={e => this.setState({ Ar4: e.target.value })} type="text"></input></td>
<td><input  name="Areventname"  onChange={e => this.setState({ Areventname: e.target.value })} type="text"></input></td>
<td><input  name="Artypebow"  onChange={e => this.setState({ Artypebow: e.target.value })} type="text"></input></td>
<td><input  name="Arbow"  onChange={e => this.setState({ Arbow: e.target.value })} type="text"></input></td>
<td><input  name="Arteamscore"  onChange={e => this.setState({ Arteamscore: e.target.value })} type="text"></input></td> */}

                                  <td class='td-actions text-right'>
                                    <button
                                      type='button'
                                      rel='tooltip'
                                      class='btn btn-success '
                                      onClick={this.rpages('archery')}
                                      data-toggle='modal'
                                      data-target='#regmeet'
                                    >
                                      Enter Result
                                    </button>
                                    {` `}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </>
                      )}
                      {/* table --------------------------------------------------- */}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      );
    }

    // Archery--------------------------------------------------end-------------------------------------------------------

    if (this.state.venuename == 'Field') {
      return (
        <div>
          <div className='content'>
            <Row>
              <Col md='8'>
                <button
                  type='button'
                  data-toggle='modal'
                  data-target='#newmeet'
                  class='btn btn-danger btn-round'
                  onClick={this.back}
                >
                  Back
                </button>
                {''}
                <Card>
                  <CardHeader>
                    <h5 className='title'>Enter Meets Results </h5>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <div class='form-group row'>
                        <div class='col-xs-5'>
                          <CardHeader>
                            <p>Search</p>
                            <input class='form-control' id='inputsm' placeholder='Search..' type='text' />
                          </CardHeader>
                        </div>
                      </div>
                      {/* table --------------------------------------------------- */}
                      {Object.keys(this.state.resultvalues[0]).length == 0 ? (
                        <Empty />
                      ) : (
                        <>
                          <Table id='my' responsive>
                            <thead>
                              <tr>
                                <th className='text-center'>#</th>
                                <th>Bib no</th>
                                <th>firstname</th>
                                <th>lastname</th>

                                {/* <th>pit</th>
<th>groups</th>

<th>class</th>
<th>age</th>

<th>gender</th>
<th>final results </th>
<th>final place </th>
<th>wind</th>
<th>implement no </th>


<th>Qualify standard </th>
<th>Qualifies </th>

<th>break record</th>
<th>Ndjc record</th>
<th>1</th>
<th>2</th>
<th>3</th>
<th>4</th>
<th>Event name</th>
<th>Points</th>
<th>bestmark</th>
<th>perfomance point</th>
<th>preliminary result </th>
<th>preliminary place</th>
<th>wind </th>
<th>datetime</th> */}

                                <th class='disabled-sorting text-right'>Actions</th>
                              </tr>
                            </thead>
                            <tbody id='myTable'>
                              {this.state.resultvalues.map((question, index) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{question.bib_no}</td>
                                  <td>{question.user_name}</td>
                                  <td>{question.user_name}</td>
                                  {/* <td><input name="Fpit"  onChange={e => this.setState({ Fpit: e.target.value })} type="text"></input></td>
<td><input name="Fgroups"  onChange={e => this.setState({ Fgroups: e.target.value })}  type="text"></input></td>
<td><input name="Fclass"  onChange={e => this.setState({ Fclass: e.target.value })}  type="text"></input></td>
<td><input name="Fage"  onChange={e => this.setState({ Fage: e.target.value })}  type="text"></input></td>
<td><input  name="Fgender"  onChange={e => this.setState({ Fgender: e.target.value })} type="text"></input></td>
<td><input name='Ffinalresult'  onChange={e => this.setState({ Ffinalresult: e.target.value })} type="text"></input></td>
<td><input name='Ffinalplace'  onChange={e => this.setState({ Ffinalplace: e.target.value })}  type="text"></input></td>
<td><input name='Fwind'  onChange={e => this.setState({ Fwind: e.target.value })} type="text"></input></td>
<td><input name='Fimplno'  onChange={e => this.setState({ Fimplno: e.target.value })}  type="text"></input></td>
<td><input name='Fqtsd'  onChange={e => this.setState({ Fqtsd: e.target.value })} type="text"></input></td>
<td><input name='Fqual'   onChange={e => this.setState({ Fqual: e.target.value })} type="text"></input></td>
<td><input name='Fbreakrecord'  onChange={e => this.setState({ Fbreakrecord: e.target.value })} type="text"></input></td>
<td><input name='Fndjc'   onChange={e => this.setState({ Fndjc: e.target.value })}  type="text"></input></td>
<td><input name='F1'  onChange={e => this.setState({ F1: e.target.value })} type="text"></input></td>
<td><input name='F2'  onChange={e => this.setState({ F2: e.target.value })} type="text"></input></td>
<td><input name='F3'  onChange={e => this.setState({ F3: e.target.value })} type="text"></input></td>
<td><input name='F4'  onChange={e => this.setState({ F4: e.target.value })} type="text"></input></td>
<td><input name='Feventname'  onChange={e => this.setState({ twork: e.target.value })} type="text"></input></td>
<td><input name='Fpoints'  onChange={e => this.setState({ Fpoints: e.target.value })} type="text"></input></td>
<td><input name='Fbestmark'  onChange={e => this.setState({ Fbestmark: e.target.value })} type="text"></input></td>
<td><input name='Fperfmpoint'  onChange={e => this.setState({ Fperfmpoint: e.target.value })} type="text"></input></td>
<td><input name='Fperfmresult'  onChange={e => this.setState({ Fperfmresult: e.target.value })} type="text"></input></td>
<td><input name='Fplace'  onChange={e => this.setState({ Fplace: e.target.value })} type="text"></input></td>
<td><input name='Fwind'  onChange={e => this.setState({ Fwind: e.target.value })} type="text"></input></td>
<td><input name='Fdatetime'  onChange={e => this.setState({ Fdatetime: e.target.value })} type="text"></input></td> */}
                                  <td class='td-actions text-right'>
                                    {/* <button type="button" rel="tooltip" class="btn btn-success"  >Register */}
                                    <button
                                      type='button'
                                      rel='tooltip'
                                      class='btn btn-success '
                                      onClick={this.fpages}
                                      data-toggle='modal'
                                      data-target='#regmeet'
                                    >
                                      Enter Result
                                    </button>
                                    {` `}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </>
                      )}
                      {/* table --------------------------------------------------- */}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      );
    }

    if (this.state.venuename == 'Table Tennis') {
      return (
        <div>
          <div className='content'>
            <Row>
              <Col md='8'>
                <button
                  type='button'
                  data-toggle='modal'
                  data-target='#newmeet'
                  class='btn btn-danger btn-round'
                  onClick={this.back}
                >
                  Back
                </button>
                {''}
                <Card>
                  <CardHeader>
                    <h5 className='title'>Enter Meets Results </h5>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <div class='form-group row'>
                        <div class='col-xs-5'>
                          <CardHeader>
                            <p>Search</p>
                            <input class='form-control' id='inputsm' placeholder='Search..' type='text' />
                          </CardHeader>
                        </div>
                      </div>
                      {/* table --------------------------------------------------- */}
                      {Object.keys(this.state.resultvalues[0]).length == 0 ? (
                        <Empty />
                      ) : (
                        <>
                          <Table id='my' responsive>
                            <thead>
                              <tr>
                                <th className='text-center'>#</th>
                                <th>bib</th>
                                <th>firstname</th>
                                <th>lastname</th>
                                {/* <th>Group</th>
<th>class</th>
<th>age</th>
<th>gender</th>
<th>final results </th>
<th>final place </th>

<th>qualifies</th>
<th>event name</th>
 */}

                                <th class='disabled-sorting text-right'>Actions</th>
                              </tr>
                            </thead>
                            <tbody id='myTable'>
                              {this.state.resultvalues.map((question, index) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{question.bib_no}</td>
                                  <td>{question.user_name}</td>
                                  <td>{question.user_name}</td>
                                  {/* <td><input name="TTgroup"  onChange={e => this.setState({ TTgroup: e.target.value })} type="text"></input></td>
<td><input name="TTclass"  onChange={e => this.setState({ TTclass: e.target.value })}  type="text"></input></td>
<td><input name="TTage" onChange={e => this.setState({ TTage: e.target.value })}  type="text"></input></td>
<td><input name="TTgender"  onChange={e => this.setState({ TTgender: e.target.value })} type="text"></input></td>
<td><input name="TTfresult"  onChange={e => this.setState({ TTfresult: e.target.value })} type="text"></input></td>
<td><input name="TTfplace"  onChange={e => this.setState({ TTfplace: e.target.value })} type="text"></input></td>
<td><input name="TTqulify"  onChange={e => this.setState({ TTqulify: e.target.value })} type="text"></input></td>
<td><input name="TTeventname"  onChange={e => this.setState({ TTeventname: e.target.value })} type="text"></input></td> */}

                                  <td class='td-actions text-right'>
                                    {/* <button type="button" rel="tooltip" class="btn btn-success"  >Register */}
                                    <button
                                      type='button'
                                      rel='tooltip'
                                      class='btn btn-success '
                                      onClick={this.tabletennispage}
                                      data-toggle='modal'
                                      data-target='#regmeet'
                                    >
                                      Enter Result
                                    </button>
                                    {` `}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </>
                      )}
                      {/* table --------------------------------------------------- */}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      );
    }

    if (this.state.venuename == 'Power Lift') {
      return (
        <div>
          <div className='content'>
            <Row>
              <Col md='8'>
                <button
                  type='button'
                  data-toggle='modal'
                  data-target='#newmeet'
                  class='btn btn-danger btn-round'
                  onClick={this.back}
                >
                  Back
                </button>
                {''}
                <Card>
                  <CardHeader>
                    <h5 className='title'>Enter Meets Results </h5>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <div class='form-group row'>
                        <div class='col-xs-5'>
                          <CardHeader>
                            <p>Search</p>
                            <input class='form-control' id='inputsm' placeholder='Search..' type='text' />
                          </CardHeader>
                        </div>
                      </div>
                      {/* table --------------------------------------------------- */}
                      {Object.keys(this.state.resultvalues[0]).length == 0 ? (
                        <Empty />
                      ) : (
                        <>
                          <Table id='my' responsive>
                            <thead>
                              <tr>
                                <th className='text-center'>#</th>
                                <th>Firstname</th>
                                <th>lastname</th>
                                {/* <th>country</th>
<th>age</th>
<th>weight(in kg) </th>
<th>class</th>
<th>lift1</th>
<th>lift2 </th>
<th>lift3</th>
<th>record lift </th>
<th>result (in kg)</th>

<th>current record (in kg)</th>
<th>break record</th>

<th>place</th>
<th>gender</th>
<th>bench groups</th> */}

                                <th class='disabled-sorting text-right'>Actions</th>
                              </tr>
                            </thead>
                            <tbody id='myTable'>
                              {this.state.resultvalues.map((question, index) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{question.bib_no}</td>
                                  <td>{question.user_name}</td>
                                  <td>{question.user_name}</td>
                                  {/* <td><input type="text" name="" onChange={e => this.setState({ twork: e.target.value })} ></input></td>
<td><input type="text" name="" onChange={e => this.setState({ twork: e.target.value })}></input></td>
<td><input type="text" name="" onChange={e => this.setState({ twork: e.target.value })}></input></td>
<td><input type="text" name="" onChange={e => this.setState({ twork: e.target.value })}></input></td>
<td><input type="text" name=""  onChange={e => this.setState({ twork: e.target.value })}></input></td>
<td><input type="text" name="" onChange={e => this.setState({ twork: e.target.value })}></input></td>
<td><input type="text" name=""   onChange={e => this.setState({ twork: e.target.value })}></input></td>
<td><input type="text" name="" onChange={e => this.setState({ twork: e.target.value })}></input></td>
<td><input type="text" name="" onChange={e => this.setState({ twork: e.target.value })}></input></td>
<td><input type="text" name="" onChange={e => this.setState({ twork: e.target.value })}></input></td>
<td><input type="text" name="" onChange={e => this.setState({ twork: e.target.value })}></input></td>
<td><input type="text"name=""  onChange={e => this.setState({ twork: e.target.value })}></input></td>
<td><input type="text" name="" onChange={e => this.setState({ twork: e.target.value })}></input></td> */}
                                  <td class='td-actions text-right'>
                                    {/* <button type="button" rel="tooltip" class="btn btn-success"  >Register */}
                                    <button
                                      type='button'
                                      rel='tooltip'
                                      class='btn btn-success '
                                      onClick={this.powerlift}
                                      data-toggle='modal'
                                      data-target='#regmeet'
                                    >
                                      Enter Result
                                    </button>
                                    {` `}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </>
                      )}
                      {/* table --------------------------------------------------- */}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      );
    }

    if (this.state.venuename == 'Track') {
      return (
        <div>
          <div className='content'>
            <Row>
              <Col md='8'>
                <button
                  type='button'
                  data-toggle='modal'
                  data-target='#newmeet'
                  class='btn btn-danger btn-round'
                  onClick={this.back}
                >
                  Back
                </button>
                {''}
                <Card>
                  <CardHeader>
                    <h5 className='title'>Enter Meets Results </h5>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <div class='form-group row'>
                        <div class='col-xs-5'>
                          <CardHeader>
                            <p>Search</p>
                            <input class='form-control' id='inputsm' placeholder='Search..' type='text' />
                          </CardHeader>
                        </div>
                      </div>
                      {/* table --------------------------------------------------- */}
                      {Object.keys(this.state.resultvalues[0]).length == 0 ? (
                        <Empty />
                      ) : (
                        <>
                          <Table id='my' responsive>
                            <thead>
                              <tr>
                                <th className='text-center'>#</th>
                                <th>bib </th>
                                <th>firstname</th>
                                <th>lastname</th>
                                {/* <th>Work</th>
<th>Final groups</th>
<th>heat</th>
<th>lane</th>
<th>preliminary result</th>
<th>preliminary place</th>
<th>preliminary wind</th>
<th>final result</th>
<th>final place</th>
<th>final wind</th>
<th>country</th>
<th>class</th>
<th>age</th>
<th>gender</th>
<th>heatf</th>
<th>lanef</th>
<th>qualifies</th>
<th>qtime</th>
<th>break record</th>
<th>Record</th>
<th>1</th>
<th>2</th>
<th>3</th>
<th>4</th>
<th>relay point</th>
<th>relay name</th>
<th>relay gender</th>
<th>relay alt</th>
<th>Event name</th>
<th>points</th> */}

                                <th class='disabled-sorting text-right'>Actions</th>
                              </tr>
                            </thead>
                            <tbody id='myTable'>
                              {this.state.resultvalues.map((question, index) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{question.bib_no}</td>
                                  <td>{question.user_name}</td>
                                  <td>{question.user_name}</td>
                                  {/* <td><input type="text"  name="twork" onChange={e => this.setState({ twork: e.target.value })} ></input></td>
<td><input type="text"  name="tgroups" onChange={e => this.setState({ tgroups: e.target.value })} ></input></td>
<td><input type="text"  name="theat" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
<td><input type="text" name="tlane" onChange={e => this.setState({ tlane: e.target.value })} ></input></td>
<td><input type="text" name="tpresult" onChange={e => this.setState({ tpresult: e.target.value })} ></input></td>
<td><input type="text" name="tpplace" onChange={e => this.setState({ tpplace: e.target.value })} ></input></td>
<td><input type="text" name="tpwind" onChange={e => this.setState({ tpwind: e.target.value })} ></input></td>
<td><input type="text" name="tcountry" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
<td><input type="text" name="tclass" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
<td><input type="text" name="tage" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
<td><input type="text" name="tgender" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
<td><input type="text" name="theatf" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
<td><input type="text" name="tlanef" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
<td><input type="text" name="tqualifies" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
<td><input type="text" name="tqtime" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
<td><input type="text" name="tbreakrecord" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
<td><input type="text" name="t1" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
<td><input type="text" name="t2" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
<td><input type="text" name="t3" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
<td><input type="text" name="t4" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
<td><input type="text" name="theat" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
<td><input type="text" name="theat" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
<td><input type="text" name="theat" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
<td><input type="text" name="t1" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
<td><input type="text" name="t2" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
<td><input type="text" name="t3" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
<td><input type="text" name="t4" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
<td><input type="text" name="theat" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
<td><input type="text" name="theat" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
<td><input type="text" name="theat" onChange={e => this.setState({ theat: e.target.value })} ></input></td> */}

                                  <td class='td-actions text-right'>
                                    {/* <button type="button" rel="tooltip" class="btn btn-success"  >Register */}
                                    <button
                                      type='button'
                                      rel='tooltip'
                                      class='btn btn-success '
                                      onClick={this.track}
                                      data-toggle='modal'
                                      data-target='#regmeet'
                                    >
                                      Enter Result
                                    </button>
                                    {` `}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </>
                      )}
                      {/* table --------------------------------------------------- */}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      );
    }

    if (this.state.venuename == 'Swimming') {
      return (
        <div>
          <div className='content'>
            <Row>
              <Col md='8'>
                <button
                  type='button'
                  data-toggle='modal'
                  data-target='#newmeet'
                  class='btn btn-danger btn-round'
                  onClick={this.back}
                >
                  Back
                </button>
                {''}
                <Card>
                  <CardHeader>
                    <h5 className='title'>Enter Meets Results </h5>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <div class='form-group row'>
                        <div class='col-xs-5'>
                          <CardHeader>
                            <p>Search</p>
                            <input class='form-control' id='inputsm' placeholder='Search..' type='text' />
                          </CardHeader>
                        </div>
                      </div>
                      {/* table --------------------------------------------------- */}
                      {Object.keys(this.state.resultvalues[0]).length == 0 ? (
                        <Empty />
                      ) : (
                        <>
                          <Table id='my' responsive>
                            <thead>
                              <tr>
                                <th className='text-center'>#</th>
                                <th>bib</th>
                                <th>Firstname</th>
                                <th>Lastname</th>
                                {/* <th>Heat</th>
<th>lane</th>
<th>preliminary result</th>
<th>preliminary place</th>
<th>final result</th>
<th>final place</th>

<th>class </th>
<th>age</th>
<th>gender</th>
<th>qtime</th>
<th>relay point</th>
<th>relay gender</th>
<th>relay name</th>
<th>event name</th>
<th>points</th>
<th>record </th>
<th>events</th> */}

                                <th class='disabled-sorting text-right'>Actions</th>
                              </tr>
                            </thead>
                            <tbody id='myTable'>
                              {this.state.resultvalues.map((question, index) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{question.bib_no}</td>
                                  <td>{question.user_name}</td>
                                  <td>{question.user_name}</td>
                                  {/* <td><input type="text"  name="sheat" onChange={e => this.setState({ sheat: e.target.value })}></input></td>
<td><input type="text"  name="slane" onChange={e => this.setState({ slane: e.target.value })}></input></td>
<td><input type="text"  name="spresult" onChange={e => this.setState({ spresult: e.target.value })}></input></td>
<td><input type="text"  name="spplace" onChange={e => this.setState({ spplace: e.target.value })}></input></td>
<td><input type="text"  name="sfresult" onChange={e => this.setState({ sfresult: e.target.value })}></input></td>
<td><input type="text"  name="sfplace" onChange={e => this.setState({ sfplace: e.target.value })}></input></td>
<td><input type="text"  name="sclass" onChange={e => this.setState({ sclass: e.target.value })}></input></td>
<td><input type="text"  name="sage" onChange={e => this.setState({ sage: e.target.value })}></input></td>
<td><input type="text"  name="sgender" onChange={e => this.setState({ sgender: e.target.value })}></input></td>
<td><input type="text"  name="sqtime" onChange={e => this.setState({ sqtime: e.target.value })}></input></td>
<td><input type="text"  name="srelaypoint" onChange={e => this.setState({ srelaypoint: e.target.value })}></input></td>
<td><input type="text"  name="srelaygender" onChange={e => this.setState({ srelaygender: e.target.value })}></input></td>
<td><input type="text"  name="srelayname" onChange={e => this.setState({ srelayname: e.target.value })}></input></td>
<td><input type="text"  name="seventname" onChange={e => this.setState({ seventname: e.target.value })}></input></td>
<td><input type="text"  name="spoints" onChange={e => this.setState({ spoints: e.target.value })}></input></td>
<td><input type="text"  name="srecords" onChange={e => this.setState({ srecords: e.target.value })}></input></td>
<td><input type="text"  name="sevents" onChange={e => this.setState({ sevents: e.target.value })}></input></td> */}
                                  <td class='td-actions text-right'>
                                    {/* <button type="button" rel="tooltip" class="btn btn-success"  >Register */}
                                    <button
                                      type='button'
                                      rel='tooltip'
                                      class='btn btn-success '
                                      onClick={this.swimming}
                                      data-toggle='modal'
                                      data-target='#regmeet'
                                    >
                                      Enter Result
                                    </button>
                                    {` `}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </>
                      )}
                      {/* table --------------------------------------------------- */}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      );
    }

    if (this.state.venuename == 'Fencing') {
      return (
        <div>
          <div className='content'>
            <Row>
              <Col md='8'>
                <button
                  type='button'
                  data-toggle='modal'
                  data-target='#newmeet'
                  class='btn btn-danger btn-round'
                  onClick={this.back}
                >
                  Back
                </button>
                {''}
                <Card>
                  <CardHeader>
                    <h5 className='title'>Enter Meets Results </h5>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <div class='form-group row'>
                        <div class='col-xs-5'>
                          <CardHeader>
                            <p>Search</p>
                            <input class='form-control' id='inputsm' placeholder='Search..' type='text' />
                          </CardHeader>
                        </div>
                      </div>
                      {/* table --------------------------------------------------- */}
                      {Object.keys(this.state.resultvalues[0]).length == 0 ? (
                        <Empty />
                      ) : (
                        <>
                          <Table id='my' responsive>
                            <thead>
                              <tr>
                                <th className='text-center'>#</th>
                                <th>bib</th>
                                <th>Firstname</th>
                                <th>Lastname</th>
                                {/* <th>Group</th>


<th>class </th>
<th>age</th>
<th>gender</th>

<th>result </th>
<th>place</th>
<th>qualify</th>
<th>qualify</th>
<th>Eventname</th>
 */}

                                <th class='disabled-sorting text-right'>Actions</th>
                              </tr>
                            </thead>
                            <tbody id='myTable'>
                              {this.state.resultvalues.map((question, index) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{question.bib_no}</td>
                                  <td>{question.user_name}</td>
                                  <td>{question.user_name}</td>
                                  {/* <td><input type="text"  name="sheat" onChange={e => this.setState({ sheat: e.target.value })}></input></td>
<td><input type="text"  name="slane" onChange={e => this.setState({ slane: e.target.value })}></input></td>
<td><input type="text"  name="spresult" onChange={e => this.setState({ spresult: e.target.value })}></input></td>
<td><input type="text"  name="spplace" onChange={e => this.setState({ spplace: e.target.value })}></input></td>
<td><input type="text"  name="sfresult" onChange={e => this.setState({ sfresult: e.target.value })}></input></td>
<td><input type="text"  name="sfplace" onChange={e => this.setState({ sfplace: e.target.value })}></input></td>
<td><input type="text"  name="sclass" onChange={e => this.setState({ sclass: e.target.value })}></input></td>
<td><input type="text"  name="sage" onChange={e => this.setState({ sage: e.target.value })}></input></td>
<td><input type="text"  name="sgender" onChange={e => this.setState({ sgender: e.target.value })}></input></td> */}

                                  <td class='td-actions text-right'>
                                    {/* <button type="button" rel="tooltip" class="btn btn-success"  >Register */}
                                    <button
                                      type='button'
                                      rel='tooltip'
                                      class='btn btn-success '
                                      onClick={this.saveresult}
                                      data-toggle='modal'
                                      data-target='#regmeet'
                                    >
                                      Save
                                    </button>
                                    {` `}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </>
                      )}
                      {/* table --------------------------------------------------- */}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      );
    }
  }
}

export default Entermeetresult;
