import React, { useCallback } from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';
import { Empty } from 'antd';

import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Loader from 'react-loader-spinner';
import e from 'cors';
//import Iconsreload from './Meetload.js'
const url = window.globalConfig;
let tempDis = '';
class Fieldset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldsetdata: [{}],
      fieldsets: '',
      venuenamedata: '',
      venuelocationdata: '',
      numberdata: '',
      currentdata: '',
      eventdata: '',
      eventno: '',
      teamdata: '',
      typedata: '',
      venuedatas: [{}],
      venuespecfic: [{}],
      btncheck: false,
      btncheck1: false,
      venueevents: '',
      fieldeventsdata: '',
      fieldlocationset: {},
      fieldlocset: {},
      loader: true,
    };

    this.createfieldset = this.createfieldset.bind(this);
    this.editfieldset = this.editfieldset.bind(this);
    this.optionchange = this.optionchange.bind(this);
    this.optionchange1 = this.optionchange1.bind(this);
    this.optionchange2 = this.optionchange2.bind(this);
    this.optionchange3 = this.optionchange3.bind(this);
    this.optionchange4 = this.optionchange4.bind(this);
    this.eventnochange = this.eventnochange.bind(this);
    this.dropdownchange = this.dropdownchange.bind(this);
    this.dropdownchange1 = this.dropdownchange1.bind(this);
    this.updatefieldset = this.updatefieldset.bind(this);
    this.editlocationset = this.editlocationset.bind(this);
    this.createfieldlocationset = this.createfieldlocationset.bind(this);
    this.updatefieldlocationset = this.updatefieldlocationset.bind(this);
    this.back = this.back.bind(this);
    this.newset = this.newset.bind(this);
  }

  async componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    var self = this;
    var count = 0;
    let e = sessionStorage.getItem('e');
    let textval = sessionStorage.getItem('x');
    await axios.get(`${url.url}/api/venues`, {}).then(function (response) {
      self.setState({
        venuedatas: response.data.data,
        venuespecfic: response.data.data[textval],
        venueevents: response.data.data[textval].events,
      });
      count++;
      if (count == 3) {
        self.setState({
          loader: false,
        });
      }
    });
    //field set preference
    let meetid = sessionStorage.getItem('ids');
    axios
      .get(`${url.url}/api/getfieldmeetid/${meetid}`, {})
      .then(function (response) {
        self.setState({
          fieldsetdata: response.data,
        });
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
    axios
      .get(`${url.url}/api/fieldlocationmeetid/${meetid}`, {})
      .then(function (response) {
        self.setState({
          fieldlocationset: response.data,
          fieldlocset: response.data[0],
        });
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
    //Hide and show manager
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    // document.getElementById("venueesss").style.display = "block";
  }

  //dropdown values
  dropdownchange(e) {
    this.setState({
      typedata: e.target.value,
    });
    this.setState({
      selectval: e.target.value,
    });
  }

  dropdownchange1(e) {
    this.setState({
      teamdata: e.target.value,
    });
    this.setState({
      selectval1: e.target.value,
    });
  }

  optionchange(e) {
    this.setState({
      eventdata: e.target.value,
    });
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  optionchange1(e) {
    this.setState({
      venuedata: e.target.data,
    });
  }

  optionchange2(e) {
    this.setState({
      venuelocationdata: e.target.value,
    });
  }

  optionchange3(e) {
    this.setState({
      numberdata: e.taregt.value,
    });
  }

  optionchange4(e) {
    this.setState({
      currentdata: e.target.value,
    });
  }
  eventnochange(e) {
    this.setState({
      eventno: e.target.value,
    });
  }

  //Edit trackset
  editfieldset(e) {
    var self = this;
    self.setState({
      fieldeventsdata: self.state.venuespecfic.events[e].name,
    });
    var loop = true;
    let textval = sessionStorage.getItem('x');
    self.state.fieldsetdata.forEach((question, index) => {
      if (question.event_name == self.state.venuespecfic.events[e].name) {
        self.setState({
          fieldsets: question,
          selected_id: question.id,
          eventno: question.event_no,
          eventdata: question.event_name,
          typedata: question.type_of_event,
          teamdata: question.team_scoring,
          btncheck: false,
        });
        loop = false;
      } else if (loop) {
        self.setState({
          fieldsets: '',
          selected_id: '',
          eventno: '',
          eventdata: '',
          typedata: 'Horizontal',
          teamdata: 'Yes',
          btncheck: true,
        });
        loop = true;
      }
    });
    if (self.state.fieldsetdata.length == 0) {
      self.setState({
        fieldsets: '',
        selected_id: '',
        eventno: '',
        eventdata: '',
        typedata: 'Horizontal',
        teamdata: 'Yes',
        btncheck: true,
      });
    }
  }
  editlocationset() {
    let textval = sessionStorage.getItem('x');
    if (this.state.venuedatas[textval].name == this.state.fieldlocset.venue_name) {
      this.setState({
        venuedata: this.state.fieldlocationset[0].venue_name,
        venuelocationdata: this.state.fieldlocationset[0].venue_location,
        numberdata: this.state.fieldlocationset[0].number_of_events,
        currentdata: this.state.fieldlocationset[0].current_record,
        btncheck1: false,
      });
    } else {
      this.setState({
        venuedata: this.state.venuespecfic.name,
        venuelocationdata: '',
        numberdata: this.state.venuespecfic.events.length,
        currentdata: '',
        btncheck1: true,
      });
    }
  }
  createfieldlocationset() {
    const datatosend = {
      venue_name: this.state.venuedata,
      venue_location: this.state.venuelocationdata,
      number_of_events: this.state.numberdata,
      current_record: this.state.currentdata,
      meet_id: parseInt(sessionStorage.getItem('ids')),
    };
    axios
      .post(`${url.url}/api/createfieldlocationset`, datatosend, {})
      .then(function (response) {
        if (response.statusText == 'Created' || response.status === 201) {
          Swal.fire({
            icon: 'success',
            title: 'FieldLocation Set Created successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  updatefieldlocationset() {
    var self = this;
    //   e.preventDefault()
    const datatosend = {
      venue_name: this.state.venuedata,
      venue_location: this.state.venuelocationdata,
      number_of_events: this.state.numberdata,
      current_record: this.state.currentdata,
    };

    axios
      .put(`${url.url}/api/updatefieldlocationset/${this.state.fieldlocationset[0].id}`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK' || response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'FieldLocation Set Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  //Create Track set
  createfieldset(e) {
    var self = this;
    const setvalue = (q, index) => {
      if (self.state.fieldsetdata.length > index) {
        self.setState({
          fieldsets: self.state.fieldsetdata[index],
          selected_id: self.state.fieldsetdata[index].id,
          eventno: self.state.fieldsetdata[index].event_no,
          venuedata: self.state.fieldsetdata[index].venue_name,
          venuelocationdata: self.state.fieldsetdata[index].venue_location,
          numberdata: self.state.fieldsetdata[index].number_of_events,
          selectdata: self.state.fieldsetdata[index].performance_record,
          currentdata: self.state.fieldsetdata[index].current_record,
          eventdata: self.state.fieldsetdata[index].event_name,
          typedata: self.state.fieldsetdata[index].type_of_event,
          teamdata: self.state.fieldsetdata[index].team_scoring,
        });
      }
    };
    self.state.fieldsetdata.map((question, index) => {
      setvalue(question, index);
    });
    const datatosend = {
      event_no: this.state.eventno,
      event_name: this.state.fieldeventsdata,
      type_of_event: this.state.typedata,
      team_scoring: this.state.teamdata,
      created_by: 1,
      meet_id: parseInt(sessionStorage.getItem('ids')),
    };
    axios
      .post(`${url.url}/api/createfieldsetrecords`, datatosend, {})
      .then(function (response) {
        Swal.fire({
          icon: 'success',
          title: 'FieldSet Created successfully',
          showConfirmButton: false,
          timer: 1000,
        }).then(function (response) {
          window.location.reload();
        });
      })
      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  clearfieldset = (e) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        //document.getElementById("myTable").deleteRow(e);
        axios
          .delete(`${url.url}/api/deletefieldset/${this.state.fieldsets.id}`, {})
          .then(function (response) {
            if (response.status) {
              Swal.fire('Deleted!', 'Record has been deleted.', 'success').then((result) => {
                window.location.reload();
              });
            }
          })
          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
      }
    });
  };

  //Update trackset
  updatefieldset(e) {
    var self = this;

    const datatosend = {
      event_no: this.state.eventno,
      event_name: this.state.fieldsets.event_name,
      type_of_event: this.state.typedata,
      team_scoring: this.state.teamdata,
      meet_id: parseInt(sessionStorage.getItem('ids')),
    };
    axios
      .put(`${url.url}/api/updatefieldsetrecords/${this.state.fieldsets.id}`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK' || response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'FieldSet Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something Went Wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  newset() {
    this.setState({
      typedata: 'Horizontal',
      teamdata: 'Yes',
      btncheck: true,
    });
  }

  testFunction = (name) => {
    tempDis = '';
    this.state.fieldsetdata.map((element, index) => {
      if (name === element.event_name) {
        tempDis = 'disabled';
        return null;
      }
    });
    return tempDis;
  };

  //back Button
  back() {
    window.location.replace('/eventpreference');
  }

  render() {
    let textval = sessionStorage.getItem('x');
    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader
                type='ThreeDots'
                color='#00BFFF'
                height={70}
                width={70}
                // timeout={3000} //3 secs
              />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div className='content'>
              <Row>
                <Col md='8'>
                  {/* <button type="button" data-toggle="modal" data-target="#newmeet" class="btn btn-danger btn-round">New Field set</button> */}
                  <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                    Back
                  </button>
                  {''}
                  <Card>
                    <CardHeader>
                      <h5 className='title'>Field Set Preferences</h5>
                    </CardHeader>

                    <CardBody>
                      <Form>
                        <div class='form-group row'>
                          <div class='col-xs-5'>
                            <CardHeader id='deatils_heading'>
                              <tr>
                                <th>
                                  <p>
                                    Venue Name:
                                    <span>{this.state.venuedatas[textval].name}</span>
                                  </p>
                                </th>
                                <th>
                                  <p>
                                    Venue Location:
                                    <span>
                                      {this.state.fieldlocationset[0]
                                        ? this.state.fieldlocationset[0].venue_location
                                        : ''}
                                    </span>
                                  </p>
                                </th>
                                <th>
                                  <p>
                                    Current Record:
                                    <span>
                                      {this.state.fieldlocationset[0]
                                        ? this.state.fieldlocationset[0].current_record
                                        : ''}
                                    </span>
                                  </p>
                                </th>
                                <th>
                                  <button
                                    type='button'
                                    rel='tooltip'
                                    data-toggle='modal'
                                    data-target='#newmeet'
                                    class='btn btn-success btn-sm btn-icon'
                                    onClick={this.editlocationset}
                                  >
                                    <i class='now-ui-icons ui-2_settings-90'></i>
                                  </button>
                                </th>
                              </tr>
                            </CardHeader>
                          </div>
                        </div>

                        {/* table --------------------------------------------------- */}
                        {Object.keys(this.state.venuespecfic).length == 1 ? (
                          <Empty />
                        ) : (
                          <>
                            <Table id='my' responsive>
                              <thead>
                                <tr>
                                  <th className='text-center'>Event #</th>
                                  <th>Event name</th>
                                  <th>Type Of Event</th>
                                  <th>Team Scoring</th>

                                  <th class='disabled-sorting text-right'>Actions</th>
                                </tr>
                              </thead>
                              <tbody id='myTable'>
                                {this.state.venuespecfic.events.map((question, index) => (
                                  <tr>
                                    <td>
                                      {this.state.fieldsetdata.map((question1, index1) =>
                                        question.name === question1.event_name ? question1.event_no : ''
                                      )}
                                    </td>
                                    <td>{question.name}</td>
                                    <td>
                                      {this.state.fieldsetdata.map((question1, index1) =>
                                        question.name === question1.event_name ? question1.type_of_event : ''
                                      )}
                                    </td>
                                    <td>
                                      {this.state.fieldsetdata.map((question1, index1) =>
                                        question.name === question1.event_name ? question1.team_scoring : ''
                                      )}
                                    </td>
                                    <td class='td-actions text-right'>
                                      <button
                                        type='button'
                                        rel='tooltip'
                                        class='btn btn-success btn-sm btn-icon'
                                        onClick={() => this.editfieldset(index)}
                                        data-toggle='modal'
                                        data-target='#meetedit'
                                      >
                                        <i class='now-ui-icons ui-2_settings-90'></i>
                                      </button>
                                      {` `}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </>
                        )}
                        {/* table --------------------------------------------------- */}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* add meet------------------------------------------------------------------------------------------------- */}

              <div class='modal fade' id='newaddmeet' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        FieldSet
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='inputAddress'>Event name</label>
                          {/* <input
                            type="text"
                            class="form-control"
                            id="inputAddress"
                            placeholder="1234 Main St"
                            name="evntname"
                            value={this.state.eventdata}
                            onChange={this.optionchange}
                            disabled="disabled"
                            required
                          /> */}
                          <select
                            class='form-control'
                            id='inputAddress'
                            placeholder='Event Name'
                            name='evntname'
                            value={this.state.eventdata}
                            onChange={this.optionchange}
                            required
                          >
                            <option value='' disabled selected>
                              Please Select
                            </option>

                            {this.state.venuespecfic.events.map((question, index) => (
                              <option disabled={this.testFunction(question.name)}>{question.name}</option>
                            ))}
                          </select>
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Type of Event</label>
                          <select
                            class='form-control'
                            id='exampleFormControlSelect1'
                            value={this.state.typedata}
                            onChange={this.dropdownchange}
                            required
                          >
                            <option value='Horizontal'>Horizontal</option>
                            <option value='Vertical'>Vertical</option>
                            <option value='M'>M</option>
                          </select>
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Team Scoring</label>
                          <select
                            class='form-control'
                            id='exampleFormControlSelect1'
                            value={this.state.teamdata}
                            onChange={this.dropdownchange1}
                            required
                          >
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                          </select>
                        </div>

                        {this.state.btncheck == true ? (
                          <center>
                            <button
                              type='button'
                              onClick={(e) => this.createfieldset()}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Create
                            </button>
                          </center>
                        ) : (
                          <>
                            <center>
                              <button type='button' class='btn btn-danger' onClick={(e) => this.updatefieldset()}>
                                Update
                              </button>
                            </center>
                          </>
                        )}
                        {/* <center><button type="submit" onClick={this.updatefieldset} data-dismiss="modal" class="btn btn-danger">Update</button></center> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              {/*edit*/}
              <div class='modal fade' id='meetedit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        {this.state.fieldeventsdata + ' ' + 'FieldSet'}{' '}
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='inputAddress'>Event #</label>
                          <input
                            class='form-control'
                            id='inputAddress'
                            name='evntno'
                            value={this.state.eventno}
                            onChange={this.eventnochange}
                            // required
                            // disabled
                          ></input>
                        </div>
                        <div class='form-group'>
                          <label for='inputAddress'>Event name</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            placeholder='1234 Main St'
                            name='evntname'
                            value={this.state.fieldeventsdata}
                            onChange={this.optionchange}
                            // disabled="disabled"
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Type of Event</label>
                          <select
                            class='form-control'
                            id='exampleFormControlSelect1'
                            value={this.state.typedata}
                            onChange={this.dropdownchange}
                            required
                          >
                            <option value='Horizontal'>Horizontal</option>
                            <option value='Vertical'>Vertical</option>
                            <option value='M'>M</option>
                          </select>
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Team Scoring</label>
                          <select
                            class='form-control'
                            id='exampleFormControlSelect1'
                            value={this.state.teamdata}
                            onChange={this.dropdownchange1}
                            required
                          >
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                          </select>
                        </div>

                        {this.state.btncheck == true ? (
                          <center>
                            <button
                              type='button'
                              onClick={(e) => this.createfieldset()}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Create
                            </button>
                          </center>
                        ) : (
                          <>
                            <center>
                              <button type='button' class='btn btn-danger' onClick={(e) => this.updatefieldset()}>
                                Update
                              </button>
                              <button type='button' class='btn btn-danger' onClick={(e) => this.clearfieldset(e)}>
                                Clear
                              </button>
                            </center>
                          </>
                        )}
                        {/* <center><button type="submit" onClick={this.updatefieldset} data-dismiss="modal" class="btn btn-danger">Update</button></center> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class='modal fade' id='newmeet' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Field Location Set
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='inputAddress'>Venue Name</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='venuename'
                            value={this.state.venuedata}
                            onChange={this.optionchange1}
                            placeholder='Enter venue name'
                            disabled='disabled'
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Venue location</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='venuelocation'
                            value={this.state.venuelocationdata}
                            onChange={this.optionchange2}
                            placeholder='Enter Venue location'
                            required
                          />
                        </div>

                        {/* <div class="form-group">
                          <label for="inputAddress">Number of events</label>
                          <input
                            type="text"
                            class="form-control"
                            id="inputAddress"
                            name="numevents"
                            value={this.state.numberdata}
                            onChange={this.optionchange3}
                            placeholder="Enter Number of events"
                            disabled="disabled"
                            required
                          />
                        </div> */}

                        <div class='form-group'>
                          <label for='inputAddress'>Current Record Level</label>
                          <select
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='currecord'
                            value={this.state.currentdata}
                            onChange={this.optionchange4}
                            placeholder='Enter Current record'
                            required
                          >
                            <option>Please Select</option>
                            <option value='National'>National</option>
                            <option value='American IPC'>American IPC</option>
                            <option value='Americas IPC'>Americas IPC</option>
                            <option value='NJDC'>NJDC</option>
                          </select>
                        </div>
                        {this.state.btncheck1 == true ? (
                          <center>
                            <button
                              type='button'
                              onClick={(e) => this.createfieldlocationset()}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Create
                            </button>
                          </center>
                        ) : (
                          <>
                            <center>
                              <button
                                type='button'
                                class='btn btn-danger'
                                onClick={(e) => this.updatefieldlocationset()}
                              >
                                Update
                              </button>
                            </center>
                          </>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Fieldset;
