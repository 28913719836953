import React from 'react';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from 'reactstrap';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/bootstrap.min.css';
import './assets/css/now-ui-dashboard.css?v=1.5.0';
import './assets/demo/demo.css';
import Swal from 'sweetalert2';
import axios from 'axios';
import $ from 'jquery';
import logo from './logo.png';
import emailjs from 'emailjs-com';
import adaptive from './adaptive.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDribbble, faFacebook, faTwitter, faWindows } from '@fortawesome/free-brands-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import Login from './login';
import Register from './register';
const url = window.globalConfig;
class Page1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      log: false,
      reg: false,
    };
    this.logChange = this.logChange.bind(this);
    this.regChange = this.regChange.bind(this);
  }
  logChange() {
    this.setState({ log: true });
  }
  regChange() {
    this.setState({ reg: true });
  }
  render() {
    if (this.state.log) {
      return <Login />;
    }
    if (this.state.reg) {
      return <Register />;
    }
    return (
      <div>
        {/* top nav and image */}
        <nav class='navbar navbar-expand-lg bg-white'>
          <div class='container-fluid'>
            <div class='navbar-wrapper'>
              <img src={logo} alt='' width='100px' height='50px' />
              <img src={adaptive} alt='' width='100px' height='50px' />
            </div>

            <button
              class='navbar-toggler'
              type='button'
              data-toggle='collapse'
              data-target='#navigation'
              aria-controls='navigation-index'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span class='navbar-toggler-bar navbar-kebab'></span>
              <span class='navbar-toggler-bar navbar-kebab'></span>
              <span class='navbar-toggler-bar navbar-kebab'></span>
            </button>
            <div class='collapse navbar-collapse justify-content-end' id='navigation'>
              <ul class='navbar-nav'>
                <li class='nav-item '>
                  <a href='#' class='nav-link' onClick={this.rChange}>
                    <i class='now-ui-icons arrows-1_cloud-download-93'></i>
                    Results
                  </a>
                </li>

                <li class='nav-item ' data-toggle='modal' data-target='#registermodal'>
                  <a href='/register' class='nav-link' onClick={this.regChange}>
                    <i class='now-ui-icons tech_mobile'></i>
                    Register
                  </a>
                </li>
                <li class='nav-item ' data-toggle='modal' data-target='#loginmodal'>
                  <a href='/login' class='nav-link' onClick={this.logChange}>
                    <i class='now-ui-icons users_circle-08'></i>
                    Login
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div class='wrapper wrapper-full-page wrapper1 '>
          <div class='full-page login-page section-image' filter-color='black'></div>
        </div>

        <div id='icons' class=' container-fluid  '>
          <nav></nav>
        </div>
        <div class='col-md-4' id='social'>
          <ul>
            <a href='#'>
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href='#'>
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href='#'>
              <FontAwesomeIcon icon={faDribbble} />
            </a>
            <a href='#'>
              <FontAwesomeIcon icon={faGithub} />
            </a>
          </ul>
        </div>
        <div class='col-md-4'>
          <h4 class='text-primary font-weight-light mb-2'>Thank you for supporting us!</h4>
        </div>
        <footer>
          <hr></hr>
          <p id='footer' class='col-md-4'>
            © 2020 SAMS Meet Management Application.{' '}
          </p>
        </footer>

        {/* top nav and image */}

        {/* Log in model */}
        {/* <div class="modal fade" id="loginmodal" tabindex="-1" role="dialog"  aria-hidden="true">
<div class="modal-dialog" id="loginmodal">
<div class="modal-content">

<div class="modal-header " >

<h5 class="modal-title " id="loginmodalLabel">Log in</h5>
<button type="button" class="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true">&times;</span>
</button>
</div>
<div class="modal-body">
<form  onSubmit={this.submitlogin}>
<div class="form-group">
<label for="exampleInputEmail1">Email address</label>
<input type="email" class="form-control" id="exampleInputEmail1" name='username'  onChange={e => this.setState({ username: e.target.value })}  aria-describedby="emailHelp" placeholder="Enter email" required/>

</div>
<div class="form-group">
<label for="exampleInputPassword1">Password</label>
<input type="password" class="form-control" id="exampleInputPassword1" name="password" onChange={e => this.setState({ password: e.target.value })} placeholder="Password"  required/>
</div>
<a href="#" onClick ={this.forpass}>Forget Password ?</a>
<button type="submit"   class="btn btn-danger">Log in</button>
</form>
</div>
</div>
</div> */}

        {/* Log in model */}

        {/* </div> */}

        {/* export result --------------------------------------------------------------------------------------- */}
        <div
          class='modal fade bd-example-modal-lg'
          id='exportresult'
          tabindex='-1'
          role='dialog'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <div class='modal-dialog  modal-lg' role='document' id='exportresult'>
            <div class='modal-content'>
              <div class='modal-header'>
                <h5 class='modal-title' id='registermodalLabel'>
                  Export Result
                </h5>
                <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div class='modal-body'>
                <form>
                  <div class='form-group'>
                    <label for='exampleFormControlSelect1'>Report Type *</label>
                    <select class='form-control' id='exampleFormControlSelect1' name='desg' required>
                      <option>Please Select</option>
                      <option value='3-user-athelete'>Standards</option>
                      <option value='3-user-headcoach'>Standards USATF</option>
                      <option value='3-user-coach'>Record</option>
                      <option value='3-user-assitant coach'>PNR list</option>
                      <option value='3-user-parent'>USparastid</option>
                    </select>
                  </div>

                  <div class='form-group'>
                    <label for='exampleFormControlSelect1'>Athletics *</label>
                    <select class='form-control' id='exampleFormControlSelect1' name='desg' required>
                      <option>Please Select</option>
                      <option value='3-user-athelete'>Track</option>
                      <option value='3-user-headcoach'>Field</option>
                    </select>
                  </div>

                  <div class='form-group'>
                    <label for='exampleFormControlSelect1'>Year *</label>
                    <select class='form-control' id='exampleFormControlSelect1' name='desg' required>
                      <option>Please Select</option>
                    </select>
                  </div>

                  <div class='form-row'>
                    <div class='form-group col-md-6'>
                      <label for='inputEmail4'>FirstName</label>
                      <input type='text' class='form-control' id='inputEmail4' placeholder='firstname' required />
                    </div>
                    <div class='form-group col-md-6'>
                      <label for='inputPassword4'>LastName</label>
                      <input type='text' class='form-control' id='inputPassword4' placeholder='lastname' required />
                    </div>

                    <div class='form-group col-md-6'>
                      <label for='inputState'>Gender</label>
                      <select id='inputState' class='form-control' name='gender'>
                        <option selected>Choose...</option>
                        <option value='male'>Male</option>
                        <option value='female'>Female</option>
                      </select>
                    </div>

                    <div class='form-group col-md-6'>
                      <label for='inputState'>Track</label>
                      <select id='inputState' class='form-control' name='gender'>
                        <option selected>Choose...</option>
                      </select>
                    </div>

                    <div class='form-group col-md-6'>
                      <label for='inputState'>Field</label>
                      <select id='inputState' class='form-control' name='gender'>
                        <option selected>Choose...</option>
                      </select>
                    </div>
                  </div>
                  <div class='form-group'>
                    <label for='exampleFormControlSelect1'>Age *</label>
                    <select class='form-control' id='exampleFormControlSelect1' name='desg' required>
                      <option>Please Select</option>
                      <option value='3-user-athelete'>U18</option>
                      <option value='3-user-headcoach'>U19</option>
                    </select>
                  </div>

                  <div class='form-row'>
                    <div class='form-group col-md-6'>
                      <label for='exampleFormControlSelect1'>Visual Impairment *</label>
                      <select class='form-control' id='exampleFormControlSelect1' name='desg' required>
                        <option>Please Select</option>
                      </select>
                    </div>
                    <div class='form-group col-md-6'>
                      <label for='exampleFormControlSelect1'>Intellectual Impairment *</label>
                      <select class='form-control' id='exampleFormControlSelect1' name='desg' required>
                        <option>Please Select</option>
                      </select>
                    </div>
                  </div>

                  <div id='outer'>
                    <div class='inner'>
                      <button type='submit' class='btn btn-danger'>
                        PDF
                      </button>
                    </div>
                    <div class='inner'>
                      <button type='submit' class='btn btn-danger'>
                        HTML
                      </button>
                    </div>
                    <div class='inner'>
                      <button class='btn btn-danger'>Excel</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div
          class='modal fade bd-example-modal-lg'
          id='registermodal'
          tabindex='-1'
          role='dialog'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <div class='modal-dialog  modal-lg' role='document' id='registermodal'>
            <div class='modal-content'>
              <div class='modal-header'>
                <h5 class='modal-title' id='registermodalLabel'>
                  Register
                </h5>
                <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div class='modal-body'>
                <form onSubmit={this.submitregister}>
                  <div class='form-group'>
                    <label for='exampleFormControlSelect1'>Desigination</label>
                    <select
                      class='form-control'
                      id='exampleFormControlSelect1'
                      name='desg'
                      onChange={this.handlechange}
                      required
                    >
                      <option>Please Select</option>
                      <option value='3-user-athelete'>Athelete</option>
                      <option value='3-user-headcoach'>Head Coach</option>
                      <option value='3-user-coach'>Coach</option>
                      <option value='3-user-assitant coach'>Assitant Coach</option>
                      <option value='3-user-parent'>Parent</option>
                      <option value='3-user-staff'>Support Staff</option>
                      <option value='3-user-offical'>Official</option>
                    </select>
                  </div>

                  <div class='form-row'>
                    <div class='form-group col-md-6'>
                      <label for='inputEmail4'>FirstName</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputEmail4'
                        placeholder='firstname'
                        onChange={(e) => this.setState({ fname: e.target.value })}
                        required
                      />
                    </div>
                    <div class='form-group col-md-6'>
                      <label for='inputPassword4'>LastName</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputPassword4'
                        placeholder='lastname'
                        onChange={(e) => this.setState({ lname: e.target.value })}
                        required
                      />
                    </div>
                  </div>

                  <div class='form-row'>
                    <div class='form-group col-md-4'>
                      <label for='inputCity'>Email</label>
                      <input
                        type='email'
                        class='form-control'
                        id='inputCity'
                        placeholder='email'
                        onChange={(e) => this.setState({ email: e.target.value })}
                        required
                      />
                    </div>

                    <div class='form-group col-md-4'>
                      <label for='inputZip'>Password</label>
                      <input
                        type='password'
                        class='form-control'
                        id='inputZip'
                        placeholder='password'
                        onChange={(e) => this.setState({ pwd: e.target.value })}
                        required
                      />
                    </div>
                    <div class='form-group col-md-4 confirm'>
                      <label for='inputZip'>Confirm password</label>
                      <input
                        type='password'
                        class='form-control'
                        id='inputZip'
                        placeholder='confirm password'
                        onChange={(e) => this.setState({ cpwd: e.target.value })}
                        required
                      />
                    </div>
                  </div>
                  <div class='form-row'>
                    <div class='form-group col-md-6'>
                      <label for='inputCity'>DOB</label>
                      <input type='date' class='form-control' id='DOB ' onChange={this.handlechange1} required />
                    </div>
                    <div class='form-group col-md-6'>
                      <label for='inputState'>Gender</label>
                      <select id='inputState' class='form-control' name='gender' onChange={this.handlechange}>
                        <option selected>Choose...</option>
                        <option value='male'>Male</option>
                        <option value='female'>Female</option>
                      </select>
                    </div>
                  </div>
                  <div class='form-group'>
                    <label for='inputAddress'>Age</label>
                    <input
                      type='text'
                      class='form-control'
                      id='inputAddress'
                      placeholder='Enter your age'
                      value={this.state.age1}
                      disabled
                      required
                    />
                  </div>
                  <div class='form-group'>
                    <label for='inputAddress'>Address</label>
                    <input
                      type='text'
                      class='form-control'
                      id='inputAddress'
                      placeholder='Enter your address'
                      onChange={(e) => this.setState({ addr1: e.target.value })}
                      required
                    />
                  </div>
                  <div class='form-group'>
                    <label for='inputAddress2'>Address 2</label>
                    <input
                      type='text'
                      class='form-control'
                      id='inputAddress2'
                      placeholder='Enter your address'
                      onChange={(e) => this.setState({ addr2: e.target.value })}
                    />
                  </div>

                  <div class='form-row'>
                    <div class='form-group col-md-4'>
                      <label for='inputCity'>City</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputCity'
                        placeholder='Enter city'
                        onChange={(e) => this.setState({ city: e.target.value })}
                        required
                      />
                    </div>

                    <div class='form-group col-md-4'>
                      <label for='inputZip'>State/Province</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputZip'
                        placeholder='Enter State'
                        onChange={(e) => this.setState({ state: e.target.value })}
                        required
                      />
                    </div>
                    <div class='form-group col-md-4'>
                      <label for='inputZip'>Zip/Mail code</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputZip'
                        placeholder='Enter Zip code'
                        onChange={(e) => this.setState({ zipcode: e.target.value })}
                        required
                      />
                    </div>
                  </div>
                  <div class='form-row'>
                    <div class='form-group col-md-4'>
                      <label for='inputCity'>Country</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputCity'
                        placeholder='Enter country'
                        onChange={(e) => this.setState({ country: e.target.value })}
                        required
                      />
                    </div>

                    <div class='form-group col-md-4'>
                      <label for='inputZip'>Homephone</label>
                      <input
                        type='number'
                        class='form-control'
                        id='inputZip'
                        placeholder='Enter homephone'
                        onChange={(e) => this.setState({ homephone: e.target.value })}
                        required
                      />
                    </div>
                    <div class='form-group col-md-4'>
                      <label for='inputZip'>Cellphone</label>
                      <input
                        type='number'
                        class='form-control'
                        id='inputZip'
                        placeholder='Enter cellphone'
                        onChange={(e) => this.setState({ cellphone: e.target.value })}
                        required
                      />
                    </div>
                  </div>
                  <center>
                    <button type='submit' class='btn btn-danger'>
                      Register
                    </button>
                  </center>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Page1;
