import React from 'react';

import '../App.css';

import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';

import 'datatables.net';
import 'datatables.net-bs4';
import { faWindows } from '@fortawesome/free-brands-svg-icons';

import FullScreenMap2 from './Venueclasses';
const url = window.globalConfig;
class Eventset extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      venuedatas: [{}],
      newvenue: '',
      vname: '',
      vvalue: '',
      vedata: '',
      venuehome: false,
      eventhome: true,
      venueclasshome: false,
      venuespecfic: [{}],
      editename: '',
      editeid: '',
      newevents: '',
      newvenueclass: '',
      evenueclassname: '',
      evenueclassid: '',
      venuebyid: [{}],
      newclassvalue: '',
      vid: [{}],
      newclsvalue: '',
      newclsid: '',
      rerend: false,
      active1: false,
      loader: false,
      tracksetdata: [{}],
      trackset: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.createvenue = this.createvenue.bind(this);
    this.delete = this.delete.bind(this);
    this.updatevenue = this.updatevenue.bind(this);
    this.eventedit = this.eventedit.bind(this);
    this.updateevent = this.updateevent.bind(this);
    this.eventdelete = this.eventdelete.bind(this);
    this.createevent = this.createevent.bind(this);
    this.createvenueclass = this.createvenueclass.bind(this);
    this.venueclassedit = this.venueclassedit.bind(this);
    this.updatevenueclass = this.updatevenueclass.bind(this);
    this.venueclassdelete = this.venueclassdelete.bind(this);
    this.vhome = this.vhome.bind(this);

    this.backhome = this.backhome.bind(this);
  }

  componentWillMount() {
    var self = this;
    let textval = sessionStorage.getItem('x');

    self.setState({ vedata: textval });

    axios.get(`${url.url}/api/venues`, {}).then(function (response) {
      self.setState({
        venuedatas: response.data.data,
        venuespecfic: response.data.data[textval],
      });
    });
  }

  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });
    var self = this;

    let textval = sessionStorage.getItem('x');

    self.setState({ vedata: textval });

    axios.get(`${url.url}/api/venues`, {}).then(function (response) {
      self.setState({
        venuedatas: response.data.data,
        venuespecfic: response.data.data[textval],
      });
    });

    //Track set preference
    axios.get(`${url.url}/api/getalltracksetrecords`, {}).then(function (response) {
      self.setState({
        tracksetdata: response.data,
      });
    });
  }

  handleChange(e) {
    var self = this;
    self.setState({
      [e.target.name]: e.target.value,
    });
  }

  createvenue(e) {
    var self = this;
    e.preventDefault();
    const datatosend = {
      name: this.state.newvenue,
      created_by: 1,
    };

    axios
      .post(`${url.url}/api/createvenue/`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 201) {
          Swal.fire({
            title: 'Sucess',
            text: 'Venue Created successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong Please check the field name',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  // delete-----------------------------------------------------------
  delete(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deletevenue/${this.state.venuedatas[e].id}`, {})
          .then(function (response) {})

          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });
  }

  edit(e) {
    var self = this;
    self.setState({
      vname: this.state.venuedatas[e].name,
      vvalue: this.state.venuedatas[e].id,
    });
  }

  updatevenue(e) {
    var self = this;

    const datatosend = {
      name: this.state.vname,
      created_by: 1,
    };

    axios
      .put(`${url.url}/api/updatevenue/${this.state.vvalue}`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 200) {
          Swal.fire({
            title: 'Sucess',
            text: 'Venue updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  // event edit delete ---------------------------------------------------------------
  eventedit(e) {
    var self = this;
    self.setState({
      editename: this.state.venuespecfic.events[e].name,
      editeid: this.state.venuespecfic.events[e].id,
    });
  }

  updateevent(e) {
    var self = this;
    e.preventDefault();
    const datatosend = {
      name: this.state.editename,
      created_by: 1,
    };
    axios
      .put(`${url.url}/api/updateevent/${this.state.editeid}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            title: 'Sucess',
            text: 'Event updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  eventdelete(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deleteevent/${this.state.venuespecfic.events[e].id}`, {})
          .then(function (response) {})

          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });
  }

  createevent(e) {
    var self = this;
    e.preventDefault();
    const datatosend = {
      name: this.state.newevents,
      created_by: 1,
      venue_id: parseInt(this.state.venuespecfic.id),
    };
    axios
      .post(`${url.url}/api/createevent/`, datatosend, {})
      .then(function (response) {
        if (response.status == 201) {
          Swal.fire({
            title: 'Sucess',
            text: 'Event Created successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong Please check the field name',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  // event edit add delete ---------------------------------------------------------------

  createvenueclass(e) {
    var self = this;
    e.preventDefault();
    const datatosend = {
      name: this.state.newvenueclass,
      created_by: 1,
      venue_id: parseInt(this.state.venuespecfic.id),
    };

    axios
      .post(`${url.url}/api/createvenueclass/`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 201) {
          Swal.fire({
            title: 'Sucess',
            text: 'Venue Class Created Successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong Please check the field name',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  venueclassedit(e) {
    var self = this;
    self.setState({
      evenueclassname: this.state.venuespecfic.venueclasses[e].name,
      evenueclassid: parseInt(this.state.venuespecfic.venueclasses[e].id),
    });
  }
  updatevenueclass(e) {
    var self = this;
    e.preventDefault();
    const datatosend = {
      name: this.state.evenueclassname,
      created_by: 1,
      venue_id: parseInt(this.state.venuespecfic.id),
    };
    axios
      .put(`${url.url}/api/updatevenueclass/${this.state.evenueclassid}`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 200) {
          Swal.fire({
            title: 'Sucess',
            text: 'Event updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  venueclassdelete(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable1').deleteRow(e);
        axios
          .delete(`${url.url}/api/deletevenueclass/${this.state.venuespecfic.venueclasses[e].id}`, {})
          .then(function (response) {})
          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });
  }

  // class value edit delete create----------------------------------------------------------------------------
  vhome(e) {
    var self = this;
    sessionStorage.setItem('venue_id', this.state.venuespecfic.venueclasses[e].id);
    self.setState({ loader: true, vid: this.state.venuespecfic.venueclasses[e] });
  }

  backhome() {
    var self = this;
    sessionStorage.removeItem('x');
    window.location.replace('/venuepreference');
  }
  render() {
    let textval = sessionStorage.getItem('venueid');

    // Event of tracks-------------------------------------------------------------------------------------------

    if (this.state.loader) {
      return <Redirect to='/venueclasses' />;
    }
    if (Object.keys(this.state.venuedatas[0]).length == 0) {
      return (
        <>
          <div>
            <div className='content'>
              <Row>
                <Col md='8'>
                  <Card>
                    <CardHeader>
                      <h5 className='title'>Event Preference</h5>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <div class='form-group row'>
                          <div class='col-xs-5'>
                            <CardHeader>
                              <p>Search</p>
                              <input class='form-control' id='inputsm' placeholder='Search..' type='text' />
                            </CardHeader>
                          </div>
                        </div>
                        {/* Table -------------------------------------------- */}
                        <Table id='my' responsive>
                          <thead>
                            <tr>
                              <th className='text-center'>#</th>
                              <th>Name</th>
                              <th class='disabled-sorting text-right'>Actions</th>
                            </tr>
                          </thead>
                          <tbody id='myTable'>
                            <tr>
                              <td>1</td>
                              <td></td>
                              <td class='td-actions text-right'>
                                <button type='button' rel='tooltip' class='btn btn-danger btn-sm btn-icon'>
                                  <i class='now-ui-icons gestures_tap-01'></i>
                                </button>
                                {` `}
                                <button type='button' rel='tooltip' class='btn btn-success btn-sm btn-icon'>
                                  <i class='now-ui-icons ui-2_settings-90'></i>
                                </button>
                                {` `}
                                <button type='button' rel='tooltip' class='btn btn-danger btn-sm btn-icon'>
                                  <i class='now-ui-icons ui-1_simple-remove'></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        {/* Table -------------------------------------------- */}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </>
      );
    }

    // events ----------------------------------------------------------------------------------------

    if (Object.keys(this.state.venuespecfic).length != 0) {
      return (
        <div>
          <div className='content'>
            <Row>
              <Col md='8'>
                <button type='button' data-toggle='modal' data-target='#addevent' class='btn btn-danger btn-round'>
                  New Event
                </button>{' '}
                {/* <button type="button" data-toggle="modal" data-target="#addvenueclass" class="btn btn-danger btn-round">New Venue class</button>{' '}{' '}{' '}{' '} */}
                <button type='button' class='btn btn-danger btn-round' onClick={this.backhome}>
                  Back
                </button>
                <Card>
                  <CardHeader>
                    <h5 className='title'>Events of Track</h5>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <div class='form-group row'>
                        <div class='col-xs-5'>
                          <CardHeader>
                            <p>Search</p>
                            <input class='form-control' id='inputsm' placeholder='Search..' type='text' />
                          </CardHeader>
                        </div>
                      </div>
                      <Table id='my' responsive>
                        <thead>
                          <tr>
                            <th className='text-center'>#</th>
                            <th>Name</th>

                            <th class='disabled-sorting text-right'>Actions</th>
                          </tr>
                        </thead>
                        <tbody id='myTable'>
                          {this.state.venuespecfic.events.map((question, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{question.name}</td>
                              <td class='td-actions text-right'>
                                <button
                                  type='button'
                                  rel='tooltip'
                                  class='btn btn-success btn-sm btn-icon'
                                  onClick={() => this.eventedit(index)}
                                  data-toggle='modal'
                                  data-target='#eventedit'
                                >
                                  <i class='now-ui-icons ui-2_settings-90'></i>
                                </button>
                                {` `}
                                <button
                                  type='button'
                                  rel='tooltip'
                                  class='btn btn-danger btn-sm btn-icon'
                                  onClick={() => this.eventdelete(index)}
                                >
                                  <i class='now-ui-icons ui-1_simple-remove'></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>

          {/* venue class------------------------------------------------------------ */}

          {/* edit event ---------------------------------------------- */}
          <div class='modal fade' id='eventedit' tabindex='-1' role='dialog' aria-hidden='true'>
            <div class='modal-dialog '>
              <div class='modal-content'>
                <div class='modal-header '>
                  <h5 class='modal-title ' id='loginmodalLabel'>
                    Edit Event{' '}
                  </h5>
                  <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <form>
                  <div class='modal-body'></div>
                  <div class='form-group col-md-8'>
                    <label for='inputEmail4'>Name *</label>
                    <input
                      type='text'
                      class='form-control'
                      name='editename'
                      value={this.state.editename}
                      onChange={this.handleChange}
                    />
                  </div>
                  <center>
                    <button type='submit' onClick={this.updateevent} data-dismiss='modal' class='btn btn-danger'>
                      Update venue
                    </button>
                  </center>
                </form>
              </div>
            </div>
          </div>
          {/* edit event ---------------------------------------------- */}

          {/* add event -------------------------------------------------------------- */}
          <div class='modal fade' id='addevent' tabindex='-1' role='dialog' aria-hidden='true'>
            <div class='modal-dialog '>
              <div class='modal-content'>
                <div class='modal-header '>
                  <h5 class='modal-title ' id='loginmodalLabel'>
                    Add Event{' '}
                  </h5>
                  <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <form>
                  <div class='modal-body'></div>
                  <div class='form-group col-md-8'>
                    <label for='inputEmail4'>Name *</label>
                    <input type='text' class='form-control' name='newevents' onChange={this.handleChange} />
                  </div>
                  <center>
                    <button type='submit' onClick={this.createevent} data-dismiss='modal' class='btn btn-danger'>
                      Create Event
                    </button>
                  </center>
                </form>
              </div>
            </div>
          </div>
          {/* add event -------------------------------------------------------------- */}

          {/* add venue class ----------------------------------------------------------------- */}

          {/* add venue class ----------------------------------------------------------------- */}
        </div>
      );
    }
  }
}

export default Eventset;
