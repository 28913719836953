import React from 'react';

import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import axios from 'axios';
import { Select, Input } from 'antd';

import '../App.css';
import './compound.css';
import 'bootstrap/dist/css/bootstrap.css';
import '../assets/css/bootstrap.min.css';
import '../assets/css/now-ui-dashboard.css?v=1.5.0';
import '../assets/demo/demo.css';
import Login from '../login.js';
import Page1 from '../firstpage.js';
import Home from './home.js';
import $ from 'jquery';
import logo from '../logo.png';
import emailjs from 'emailjs-com';
import adaptive from '../adaptive.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDribbble, faFacebook, faKeycdn, faTwitter, faWindows } from '@fortawesome/free-brands-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import Resultpage from './Dashboard.js';
import Athletevenues from './Athletevenues';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from 'reactstrap';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import Swal from 'sweetalert2';
import responsiveObserve from 'antd/lib/_util/responsiveObserve';
import Loader from 'react-loader-spinner';
import Pagination from 'react-js-pagination';
import e from 'cors';
let classdata;
const url = window.globalConfig;
class Registeredsearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItems: [],
      venues: [],
      venueid: [{}],
      meetdatas: [{}],
      regidata: [{}],
      venitem: [],
      value: '',
      check: false,
      rtrack: false,
      rfield: false,
      rswim: false,
      regi: false,
      dob1: '',
      registered: [{}],
      trackevents: '',
      field_events: '',
      archery_events: '',
      swimevents: '',
      weightliftevents: '',
      tableevents: '',
      teamevents: '',
      fenceingevents: '',
      otherevents: '',
      trackdropdown: [],
      fielddropdown: [],
      swimdropdown: [],
      arcdropdown: [],
      weightdropdown: [],
      tabledropdown: [],
      teamdropdown: [],
      fencingdropdown: [],
      othersdropdown: [],
      selectgender: '',
      selectdata: '',
      trackvalue: [],
      fieldvalue: [],
      arcvalue: [],
      swimvalue: [],
      weightvalue: [],
      tablevalue: [],
      teamvalue: [],
      fencevalue: [],
      othervalue: [],
      registerdata: [{}],
      venueclassid: [{}],
      venuedatas: [{}],
      vclassesid: '',
      vcid: '',
      class: '',
      classdata: [{}],
      classvaluedata: '',
      selectedtrackvalue: '',
      selectedfieldvalue: '',
      selectedarcvalue: '',
      selectedswimvalue: '',
      selectedweightvalue: '',
      selectedtablevalue: '',
      selectedteamvalue: '',
      selectedfencevalue: '',
      selectedothervalue: '',
      classifiedby: '',
      sanctioned_meet: '',
      checkedvalue: 0,
      pageload: false,
      page1: true,
      loader: true,
      count: 0,
      activePage: 0,
      itemsCountPerPage: 1,
      totalItemsCount: 1,
      pageRangeDisplayed: 1,
      id: '',
      isAthlete: true,
      issearchAthlete: false,
      page2: false,
      searchdata: [],
      searchval: '',
      searchpageno: '',
      current_id: '',
      tabletennis_age: '',
      adaptivetennis_age: '',
      teamsports_age: '',
      age1: '',
      airrifle_age: '',
      paratriathlon100_age: '',
      paratriathlon200_age: '',
      paratriathlon500_age: '',
      boccia_age: '',
      cycling_age: '',
      otherven: [],
    };
    this.regTrack = this.regTrack.bind(this);
    this.regField = this.regField.bind(this);
    this.regSwim = this.regSwim.bind(this);
    this.regArchery = this.regArchery.bind(this);
    this.submitregister = this.submitregister.bind(this);
    this.updateage = this.updateage.bind(this);
    this.handlechange1 = this.handlechange1.bind(this);
    this.QuaSta = this.QuaSta.bind(this);
    this.genderchange = this.genderchange.bind(this);
    this.classifiedchange = this.classifiedchange.bind(this);
    this.coachmeetchange = this.coachmeetchange.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    // this.classvalue = this.classvalue.bind(this);
    this.handleChangeChk = this.handleChangeChk.bind(this);
    this.classdata = this.classdata.bind(this);
    this.QuaSta1 = this.QuaSta1.bind(this);
    this.close = this.close.bind(this);
    this.close1 = this.close1.bind(this);
    this.handlechange2 = this.handlechange2.bind(this);
    this.check = this.check.bind(this);
    this.eventd = this.eventd.bind(this);
    this.handlesearch = this.handlesearch.bind(this);
    this.save = this.save.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  async componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });
    this.handlesearch();
    var self = this;
    axios.get(`${url.url}/api/venues`, {}).then(function (response) {
      self.setState({
        venuedatas: response.data.data,
      });
    });
    var self = this;
    axios.get(`${url.url}/api/venues`, {}).then(function (response) {
      self.setState({
        venues: response.data.data,
      });
    });
    var self = this;
    axios.get(`${url.url}/api/meets`, {}).then(function (response) {
      self.setState({
        meetdatas: response.data.data,
      });
    });

    axios.get(`${url.url}/api/getregisterusers`, {}).then(function (response) {
      self.setState({
        registerdata: response.data,
      });
    });

    var hidenav = document.getElementById('eventset');
    hidenav.style.display = 'block';

    //Hide and show manager
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
  }
  handlesearch(pageNumber) {
    var self = this;
    let ids = sessionStorage.getItem('ids');
    axios
      .get(`${url.url}/api/searchalthletedata/${ids}?page=` + pageNumber, {})

      .then(function (response) {
        self.setState({
          allregdatas: response.data.data[0],
          itemsCountPerPage: response.data.per_page,

          totalItemsCount: response.data.total,
          activePage: response.data.current_page,
          loader: false,
          firstname: response.data.data[0].firstname,
          lastname: response.data.data[0].lastname,
          bib_no: response.data.data[0].bib_no,
          citizenship: response.data.data[0].citizenship,
          ipc: response.data.data[0].ipc,
          register_num: response.data.data[0].register_num,
          email: response.data.data[0].email,
          age: response.data.data[0].age,
          dob: response.data.data[0].dob,
          gender: response.data.data[0].gender,
          designation: response.data.data[0].designation,
          team_name: response.data.data[0].team_name,
          coach_email: response.data.data[0].coach_email,
          address: response.data.data[0].address,
          address2: response.data.data[0].address2,
          city: response.data.data[0].city,
          zipcode: response.data.data[0].zipcode,
          country: response.data.data[0].country,
          cellphone: response.data.data[0].cellphone,
          classification_site: response.data.data[0].classification_site,
          classified_whom: response.data.data[0].classified_whom,
          coach_come_meet: response.data.data[0].coach_come_meet,
          weightlifting_age: response.data.data[0].weightlifting_age,
          weightlift_events: response.data.data[0].weightlift_events,
          track_events: response.data.data[0].track_events,
          track_age: response.data.data[0].track_age,
          team_events: response.data.data[0].team_events,
          tabletennis_events: response.data.data[0].tabletennis_events,
          swim_events: response.data.data[0].swim_events,
          swim_age: response.data.data[0].swim_age,
          state: response.data.data[0].state,
          sanctioned_meet: response.data.data[0].sanctioned_meet,
          // othervenues_age: response.data.data[0].othervenues_age,
          // otherven: JSON.parse(response.data.data[0].othervenues_age),
          airrifle_age: response.data.data[0].airrifle_age === null ? null : response.data.data[0].airrifle_age,
          boccia_age: response.data.data[0].boccia_age,
          cycling_age: response.data.data[0].cycling_age,
          paratriathlon100_age: response.data.data[0].paratriathlon100_age,
          paratriathlon200_age: response.data.data[0].paratriathlon200_age,
          paratriathlon500_age: response.data.data[0].paratriathlon500_age,
          other_events: response.data.data[0].other_events,
          need_classified: response.data.data[0].need_classified,
          coach_homephone: response.data.data[0].coach_homephone,
          homephone: response.data.data[0].homephone,
          archery_age: response.data.data[0].archery_age,
          field_events: response.data.data[0].field_events,
          field_age: response.data.data[0].field_age,
          fencing_events: response.data.data[0].fencing_events,
          comments: response.data.data[0].comments,
          archery_events: response.data.data[0].archery_events,
          headcoach_name: response.data.data[0].headcoach_name,
          coach_workphone: response.data.data[0].coach_workphone,
          classifiedby: response.data.data[0].classified_whom,
          // sanctioned: response.data.data[0].sanctioned_meet,
          id: response.data.data[0].id,
        });
        $('#name').val(self.state.firstname);
        $('#name1').val(self.state.lastname);
        $('#bib_no').val(self.state.bib_no);
        $('#register_num').val(self.state.register_num);
        $('#citizenship').val(self.state.citizenship);
        $('#ipcno').val(self.state.ipc);
        $('#email').val(self.state.email);
        $('#age').val(self.state.age);
        $('#dob').val(self.state.dob);
        $('#gender').val(self.state.gender);
        $('#designate').val(self.state.designation);
        $('#team').val(self.state.team_name);
        $('#cemail').val(self.state.coach_email);
        $('#Address').val(self.state.address);
        $('#Address2').val(self.state.address2);
        $('#City').val(self.state.city);
        $('#zipcode').val(self.state.zipcode);
        $('#country').val(self.state.country);
        $('#homephone').val(self.state.homephone);
        $('#cellphone').val(self.state.cellphone);
        $('#classification_site').val(self.state.classification_site);
        $('#classified_whom').val(self.state.classified_whom);
        $('#coach_come_meet').val(self.state.coach_come_meet);
        $('#weightlifting_age').val(self.state.weightlifting_age);
        $('#weightlift_events').val(self.state.weightlift_events);
        $('#track_events').val(self.state.track_events);
        $('#track_age').val(self.state.track_age);
        $('#team_events').val(self.state.team_events);
        $('#tabletennis_events').val(self.state.tabletennis_events);
        $('#swim_events').val(self.state.swim_events);
        $('#swim_age').val(self.state.swim_age);
        $('#state').val(self.state.state);
        $('#sanctioned_meet').val(self.state.sanctioned_meet);
        // $('#othervenues_age').val(self.state.othervenues_age);
        $('#boccia_age').val(this.state.boccia_age);
        $('#cycling_age').val(this.state.cycling_age);
        $('#airrifle_age').val(this.state.airrifle_age);
        $('#paratriathlon100_age').val(this.state.paratriathlon100_age);
        $('#paratriathlon200_age').val(this.state.paratriathlon200_age);
        $('#paratriathlon500_age').val(this.state.paratriathlon500_age);
        $('#other_events').val(self.state.other_events);
        $('#need_classified').val(self.state.need_classified);
        $('#coach_homephone').val(self.state.coach_homephone);
        $('#homephone').val(self.state.homephone);
        $('#archery_age').val(self.state.archery_age);
        $('#field_events').val(self.state.field_events);
        $('#field_age').val(self.state.field_age);
        $('#fencing_events').val(self.state.fencing_events);
        $('#comments').val(self.state.comments);
        $('#archery_events').val(self.state.archery_events);
        $('#headcoach_name').val(self.state.headcoach_name);
        $('#coach_workphone').val(self.state.coach_workphone);
        $('#classifiedby').val(self.state.classifiedby);
        // $("#sanctioned").val(self.state.sanctioned);
        if (self.state.allregdatas.need_classified == 'Ambulatory') {
          document.getElementById('track-radio1').checked = true;
          document.getElementById('track-radio2').checked = false;
          document.getElementById('track-radio3').checked = false;
        }
        if (self.state.allregdatas.need_classified == 'Wheelchair') {
          document.getElementById('track-radio1').checked = false;
          document.getElementById('track-radio2').checked = true;
          document.getElementById('track-radio3').checked = false;
        }
        if (self.state.allregdatas.need_classified == 'No') {
          document.getElementById('track-radio1').checked = false;
          document.getElementById('track-radio2').checked = false;
          document.getElementById('track-radio3').checked = true;
        }
        self.setState({
          current_id: response.data.data[0].id,
        });
      })
      .catch(function (error) {
        self.setState({
          loader: false,
          page1: true,
        });
      });
  }
  save() {
    var self = this;
    const data = {
      firstname: self.state.firstname,
      lastname: self.state.lastname,
      bib_no: self.state.bib_no,
      citizenship: self.state.citizenship,
      ipc: self.state.ipc,
      email: self.state.email,
      age: self.state.age,
      dob: self.state.dob,
      gender: self.state.gender,
      designation: self.state.selectdesignation,
      team_name: self.state.team_name,
      coach_email: self.state.coach_email,
      address: self.state.address,
      address2: self.state.address2,
      city: self.state.city,
      zipcode: self.state.zipcode,
      country: self.state.country,
      cellphone: self.state.cellphone,
      classification_site: self.state.classification_site,
      classified_whom: self.state.classifiedby,
      coach_come_meet: self.state.coach_come_meet,
      weightlifting_age: self.state.weightlifting_age,
      weightlift_events: self.state.weightlift_events,
      track_events: self.state.track_events,
      track_age: self.state.track_age,
      team_events: self.state.team_events,
      tabletennis_events: self.state.tabletennis_events,
      swim_events: self.state.swim_events,
      swim_age: self.state.swim_age,
      state: self.state.state,
      sanctioned_meet: self.state.sanctioned_meet,
      // othervenues_age: self.state.othervenues_age,
      airrifle_age: self.state.airrifle_age,
      boccia_age: self.state.boccia_age,
      cycling_age: self.state.cycling_age,
      paratriathlon100_age: self.state.paratriathlon100_age,
      paratriathlon200_age: self.state.paratriathlon200_age,
      paratriathlon500_age: self.state.paratriathlon500_age,
      other_events: self.state.other_events,
      need_classified: self.state.need_classified,
      coach_homephone: self.state.coach_homephone,
      homephone: self.state.homephone,
      archery_age: self.state.archery_age,
      field_events: self.state.field_events,
      field_age: self.state.field_age,
      fencing_events: self.state.fencing_events,
      comments: self.state.comments,
      archery_events: self.state.archery_events,
      headcoach_name: self.state.headcoach_name,
      coach_workphone: self.state.coach_workphone,
      register_num: self.state.register_num,
    };
    axios
      .put(`${url.url}/api/athletainfoupdate/${self.state.id}`, data, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            title: 'Success',
            text: 'Status updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  // forward() {
  //   this.handlesearch(this.state.count + 1);
  // }
  // backward() {
  //   this.handlesearch(this.state.count - 1);
  // }
  close() {
    window.location.replace('/');
  }
  close1() {
    window.location.replace('/');
  }
  regTrack() {
    this.setState({ rtrack: true });
  }

  regField() {
    this.setState({ rfield: true });
  }

  regSwim() {
    this.setState({ rswim: true });
  }

  regArchery() {
    this.setState({ rarchery: true });
  }
  classval(e) {
    this.setState({
      searchval: e.target.value,
    });
  }

  handleChangeChk = (e) => {
    var test = e.target.value;
    var i = e.target.value.split('-');
    var l = parseInt(i[0]);
    var k = i[1];
    var m = parseInt(i[2]);
    this.setState({
      checkedvalue: l,
      selecteventval: k,
    });
    this.setState({ class: m });
    var temp = [];
    if (Object.keys(this.state.venitem).length != 0) {
      temp = this.state.venitem;
    }
    if (!this.state.venitem.some((item) => k === item.name)) {
      temp.push({
        events: this.state.venues[l].events,
        name: this.state.venues[l].name,
      });
    }

    this.setState({ venitem: temp });
    this.setState({
      value: e.target.value,
      check: !this.state.check,
    });

    if ($(e.target).prop('checked')) {
      $('.cbox').eq(l).css('display', 'block');
    } else {
      $('.cbox').eq(l).css('display', 'none');
    }
  };
  venuechange = () => {
    this.setState({ pageload: true });
  };
  classdata(e) {
    const dummy = (data) => {
      this.setState({ classdata: data });
    };
    axios.get(`${url.url}/api/geteventid/${e}`, {}).then(function (response) {
      dummy(response.data);
    });
  }

  QuaSta1(e) {
    this.setState({
      classvaluedata: e,
    });

    var l = Object.keys(this.state.venitem).length - 1;
    if (this.state.venues[this.state.checkedvalue].name == 'Track') {
      this.setState({
        selectedtrackvalue: e,
      });
    }

    if (this.state.venues[this.state.checkedvalue].name == 'Field') {
      this.setState({
        selectedfieldvalue: e,
      });
    }

    if (this.state.venues[this.state.checkedvalue].name == 'Swimming') {
      this.setState({
        selectedswimvalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'Archery') {
      this.setState({
        selectedarcvalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'WeightLift') {
      this.setState({
        selectedweightvalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'Table Tennis') {
      this.setState({
        selectedtablevalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'TeamSports') {
      this.setState({
        selectedteamvalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'Fencing') {
      this.setState({
        selectedfencevalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'Other Venues') {
      this.setState({
        selectedothervalue: e,
      });
    }
  }

  QuaSta(e, key) {
    var self = this;
    self.setState({
      value: e,
    });
    var l = Object.keys(this.state.venitem).length - 1;
    if (this.state.venues[this.state.checkedvalue].name == 'Track') {
      this.setState({
        trackvalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'Field') {
      this.setState({
        fieldvalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'Swimming') {
      this.setState({
        swimvalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'Archery') {
      this.setState({
        arcvalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'WeightLift') {
      this.setState({
        weightvalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'Table Tennis') {
      this.setState({
        tablevalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'TeamSports') {
      this.setState({
        teamvalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'Fencing') {
      this.setState({
        fencevalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'Other Venues') {
      this.setState({
        othervalue: e,
      });
    }

    this.classdata(key[0].id);
  }
  check(e) {
    if (e == 'Track' && $('#Track').is(':checked')) {
      $('#Track' + 'btn').prop('disabled', false);
    }
    if (e == 'Field' && $('#Field').is(':checked')) {
      $('#Field' + 'btn').prop('disabled', false);
    }
    if (e == 'Swimming' && $('#Swimming').is(':checked')) {
      $('#Swimming' + 'btn').prop('disabled', false);
    }
    if (e == 'Archery' && $('#Archery').is(':checked')) {
      $('#Archery' + 'btn').prop('disabled', false);
    }
    if (e == 'WeightLift' && $('#WeightLift').is(':checked')) {
      $('#WeightLift' + 'btn').prop('disabled', false);
    }
    if (e == 'Table Tennis' && $("input[id = 'Table Tennis']").is(':checked')) {
      $("button[id = 'Table Tennisbtn']").prop('disabled', false);
    }
    if (e == 'TeamSports' && $('#TeamSports').is(':checked')) {
      $('#TeamSports' + 'btn').prop('disabled', false);
    }
    if (e == 'Fencing' && $('#Fencing').is(':checked')) {
      $('#Fencing' + 'btn').prop('disabled', false);
    }
    if (e == 'Other Venues' && $("input[id = 'Other Venues']").is(':checked')) {
      $("button[id = 'Other Venuesbtn']").prop('disabled', false);
    }
  }
  eventd(e) {
    var x = e.split('-');
    var y = x[0];
    var z = x[1];
    var self = this;
    sessionStorage.setItem('venuesid', this.state.venuedatas[z].id);

    if (y == 'Track') {
      window.location.replace('/Athletetrack');
    }

    if (y == 'Field') {
      window.location.replace('/Athletefield');
    }

    if (y == 'Swimming') {
      window.location.replace('/Athleteswimming');
    }

    if (y == 'Archery') {
      window.location.replace('/Athletearchery');
    }

    if (y == 'WeightLift') {
      window.location.replace('/AthleteWeightlifting');
    }

    if (y == 'Table Tennis') {
      window.location.replace('/Athletetennis');
    }

    if (y == 'TeamSports') {
      window.location.replace('/Athleteteamsports');
    }

    if (y == 'Fencing') {
      window.location.replace('/Athletefencing');
    }

    if (y == 'Other Venues') {
      window.location.replace('/Athleteothers');
    }
  }
  calculate_age = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    return age_now;
  };

  handlechange1(event) {
    this.setState({ dob1: event.target.value }, () => {});
    var age_latest = { age_latest: this.calculate_age(event.target.value) };
    var x = JSON.stringify(age_latest);
    var y = x.split(':');
    var z = y[1].split('}');
    this.setState({ age1: z[0] }, () => {
      if (z[0] >= 60) {
        this.setState({
          tagediv: 'M60',
          fagediv: 'M60',
          sagediv: 'Masters',
          oagediv: 'M60',
        });
      }
      if (z[0] >= 50 && z[0] <= 59) {
        this.setState({
          tagediv: 'M50',
          fagediv: 'M50',
          sagediv: 'Masters',
          oagediv: 'M50',
        });
      }
      if (z[0] >= 35 && z[0] <= 49) {
        this.setState({
          tagediv: 'M35',
          fagediv: 'M35',
          sagediv: 'Masters',
          oagediv: 'M35',
        });
      }
      if (z[0] >= 23 && z[0] <= 34) {
        this.setState({
          tagediv: 'Adults',
          fagediv: 'Adults',
          sagediv: 'Senior',
          wagediv: 'Adults',
          oagediv: 'Adults',
        });
      }
      // if (z[0] >= 23) {

      //   this.setState({
      //     tagediv :"Adults",
      //     fagediv :"Adults",
      //     Aagediv :"Adults",
      //     sagediv :"Adults",
      //     wagediv :"Adults",
      //     oagediv :"Adults"
      //   });

      // }
      if (z[0] >= 20 && z[0] <= 22) {
        this.setState({
          tagediv: 'U23',
          fagediv: 'U23',
          // Aagediv :"U23",
          sagediv: 'U23',
          wagediv: 'U23',
          oagediv: 'U23',
        });
      }
      if (z[0] >= 17 && z[0] <= 19) {
        this.setState({
          tagediv: 'U20',
          fagediv: 'U20',
          sagediv: 'U20',
          oagediv: 'U20',
        });
      }
      if (z[0] >= 14 && z[0] <= 16) {
        this.setState({
          tagediv: 'U17',
          fagediv: 'U17',
          sagediv: 'U17',
          oagediv: 'U17',
        });
      }
      if (z[0] >= 11 && z[0] <= 13) {
        this.setState({
          tagediv: 'U14',
          fagediv: 'U14',
          sagediv: 'U14',
          oagediv: 'U14',
        });
      }
      if (z[0] >= 7 && z[0] <= 10) {
        this.setState({
          tagediv: 'U11',
          fagediv: 'U11',
          sagediv: 'U11',
          oagediv: 'U11',
        });
      }
      if (z[0] <= 6) {
        this.setState({
          tagediv: 'Futures',
          fagediv: 'Futures',
          sagediv: 'Futures',
          oagediv: 'Futures',
        });
      }
      if (z[0] >= 23) {
        this.setState({ Aagediv: 'Adults' });
      }
      if (z[0] >= 21 && z[0] <= 22) {
        this.setState({ Aagediv: 'YoungAdult' });
      }
      if (z[0] >= 18 && z[0] <= 20) {
        this.setState({ Aagediv: 'Junior' });
      }
      if (z[0] >= 15 && z[0] <= 17) {
        this.setState({ Aagediv: 'Cadet' });
      }
      if (z[0] >= 13 && z[0] <= 14) {
        this.setState({ Aagediv: 'Cub' });
      }
      if (z[0] >= 10 && z[0] <= 12) {
        this.setState({ Aagediv: 'Bowmen' });
      }
      if (z[0] >= 7 && z[0] <= 9) {
        this.setState({ Aagediv: 'Yeomen' });
      }
      if (z[0] < 7) {
        this.setState({ Aagediv: 'Invalid' });
      }
      if (z[0] >= 14 && z[0] <= 19) {
        this.setState({ wagediv: 'Junior' });
      }
      if (z[0] >= 23) {
        this.setState({ wagediv: 'Adults' });
      }
      if (z[0] < 14) {
        this.setState({ wagediv: 'Not Eligible' });
      }
    });
  }

  //Update Agedivision
  updateage(e) {
    e.preventDefault();
    if (this.state.age1 >= 23) {
      this.state.tagediv = 'Adults';
      this.state.fagediv = 'Adults';
      this.state.Aagediv = 'Adults';
      this.state.sagediv = 'Adults';
      this.state.wagediv = 'Adults';
      this.state.oagediv = 'Adults';
    }
    if (this.state.age1 >= 20 && this.state.age1 <= 22) {
      this.state.tagediv = 'U23';
      this.state.fagediv = 'U23';
      this.state.Aagediv = 'U23';
      this.state.sagediv = 'U23';
      this.state.wagediv = 'U23';
      this.state.oagediv = 'U23';
    }
    if (this.state.age1 >= 17 && this.state.age1 <= 19) {
      this.state.tagediv = 'U20';
      this.state.fagediv = 'U20';
      this.state.sagediv = 'U20';
      this.state.oagediv = 'U20';
    }
    if (this.state.age1 >= 14 && this.state.age1 <= 16) {
      this.state.tagediv = 'U17';
      this.state.fagediv = 'U17';
      this.state.sagediv = 'U17';
      this.state.oagediv = 'U17';
    }
    if (this.state.age1 >= 11 && this.state.age1 <= 13) {
      this.state.tagediv = 'U14';
      this.state.fagediv = 'U14';
      this.state.sagediv = 'U14';
      this.state.oagediv = 'U14';
    }
    if (this.state.age1 >= 7 && this.state.age1 <= 10) {
      this.state.tagediv = 'U11';
      this.state.fagediv = 'U11';
      this.state.sagediv = 'U11';
      this.state.oagediv = 'U11';
    }
    if (this.state.age1 <= 6) {
      this.state.tagediv = 'Futures';
      this.state.fagediv = 'Futures';
      this.state.sagediv = 'Futures';
      this.state.oagediv = 'Futures';
    }
    if (this.state.age1 >= 18 && this.state.age1 <= 20) {
      this.state.Aagediv = 'U21';
    }
    if (this.state.age1 >= 15 && this.state.age1 <= 17) {
      this.state.Aagediv = 'U18';
    }
    if (this.state.age1 >= 13 && this.state.age1 <= 14) {
      this.state.Aagediv = 'U15';
    }
    if (this.state.age1 >= 10 && this.state.age1 <= 12) {
      this.state.Aagediv = 'U13';
    }
    if (this.state.age1 >= 7 && this.state.age1 <= 9) {
      this.state.Aagediv = 'U10';
    }
    if (this.state.age1 < 7) {
      this.state.Aagediv = 'Invalid';
    }
    if (this.state.age1 >= 15 && this.state.age1 <= 19) {
      this.state.wagediv = 'Junior';
    }
    if (this.state.age1 < 15) {
      this.state.wagediv = 'Invalid';
    }
  }

  handlechange(e) {
    this.setState({
      selectdesignation: e.target.value,
    });
  }

  genderchange(e) {
    this.setState({
      gender: e.target.value,
    });
  }

  classifiedchange(e) {
    this.setState({
      need_classified: e.target.value,
    });
  }

  coachmeetchange(e) {
    this.setState({
      selectmeetcoach: e.target.value,
    });
  }

  handlechange2(e) {
    this.setState({
      selectdso: e.target.value,
    });
  }

  //Submit Register

  submitregister(e) {
    e.preventDefault();
    let j = JSON.parse(sessionStorage.getItem('spec'));
    const datatosend = {
      firstname: this.state.fname,
      lastname: this.state.lname,
      gender: this.state.selectgender,
      email: this.state.email,
      dob: this.state.dob1,
      age: this.state.age1,
      address: this.state.addr1,
      address2: this.state.addr2,
      city: this.state.city,
      state: this.state.states,
      zipcode: this.state.zipcode,
      country: this.state.country,
      homephone: this.state.homephone,
      cellphone: this.state.cellphone,
      designation: this.state.selectdesignation,
      track_age: this.state.tagediv,
      field_age: this.state.fagediv,
      archery_age: this.state.Aagediv,
      swim_age: this.state.sagediv,
      weightlifting_age: this.state.wagediv,
      tabletennis_age: this.state.tabletennis_age,
      team_age: this.state.team_age,
      fencing_age: this.state.adaptivetennis_age,
      airrifle_age: this.state.airrifle_age,
      paratriathlon100_age: this.state.paratriathlon100_age,
      paratriathlon200_age: this.state.paratriathlon200_age,
      paratriathlon500_age: this.state.paratriathlon500_age,
      boccia_age: this.state.boccia_age,
      cycling_age: this.state.cycling_age,
      // othervenues_age: this.state.oagediv,
      team_name: this.state.teamname,
      headcoach_name: this.state.headcoachname,
      coach_email: this.state.coachemail,
      coach_homephone: this.state.coachhomephone,
      coach_workphone: this.state.coachworkphone,
      need_classified: this.state.selectclassified,
      coach_come_meet: this.state.selectmeetcoach,
      classification_site: this.state.classisite,
      classified_whom: this.state.classifiedby,
      sanctioned_meet: this.state.sanctioned_meet,
      comments: this.state.comments,
      track_events: this.state.trackvalue != '' ? this.state.trackvalue.toString() : 'null',
      field_events: this.state.fieldvalue != '' ? this.state.fieldvalue.toString() : 'null',
      archery_events: this.state.arcvalue != '' ? this.state.arcvalue.toString() : 'null',
      swim_events: this.state.swimvalue != '' ? this.state.swimvalue.toString() : 'null',
      weightlift_events: this.state.weightvalue != '' ? this.state.weightvalue.toString() : 'null',
      tabletennis_events: this.state.tablevalue != '' ? this.state.tablevalue.toString() : 'null',
      team_events: this.state.teamvalue != '' ? this.state.teamvalue.toString() : 'null',
      fencing_events: this.state.fencevalue != '' ? this.state.fencevalue.toString() : 'null',
      other_events: this.state.othervalue != '' ? this.state.othervalue.toString() : 'null',
      meet_id: parseInt(j.id),
      track_class_value: this.state.selectedtrackvalue != '' ? this.state.selectedtrackvalue : 'null',
      field_class_value: this.state.selectedfieldvalue != '' ? this.state.selectedfieldvalue : 'null',
      archery_class_value: this.state.selectedarcvalue != '' ? this.state.selectedarcvalue : 'null',
      swim_class_value: this.state.selectedswimvalue != '' ? this.state.selectedswimvalue : 'null',
      weightlift_class_value: this.state.selectedweightvalue != '' ? this.state.selectedweightvalue : 'null',
      table_class_value: this.state.selectedtablevalue != '' ? this.state.selectedtablevalue : 'null',
      team_class_value: this.state.selectedteamvalue != '' ? this.state.selectedteamvalue : 'null',
      fence_class_value: this.state.selectedfencevalue != '' ? this.state.selectedfencevalue : 'null',
      other_class_value: this.state.selectedothervalue != '' ? this.state.selectedothervalue : 'null',
      citizenship: this.state.citizenship != '' ? this.state.citizenship : 'null',
      ipc: this.state.ipc != '' ? this.state.ipc : 'null',
      DSO: this.state.selectdso,
    };
    var self = this;
    axios
      .post(`${url.url}/api/createusers`, datatosend, {})
      .then(function (response) {
        self.setState({
          registered: response.data,
          page1: false,
          pageload: true,
        });
      })

      .catch(function (error) {});
  }
  handleRegister(e) {
    sessionStorage.setItem('ids', this.state.meetdatas[e].id);
    sessionStorage.setItem('e', e);
    sessionStorage.getItem('ids');
    sessionStorage.setItem('spec', JSON.stringify(this.state.meetdatas[e]));

    let j = JSON.parse(sessionStorage.getItem('spec'));

    this.setState({
      kids: parseInt(j.id),
    });

    this.setState({ regi: true, regidata: this.state.meetdatas[e] });
  }
  managevenue(e) {
    sessionStorage.setItem('athleteid', e);
    window.location.replace('/athletevenues?event=' + 'search');
  }
  onSearch = async (e) => {
    var searchvalue = this.state.searchval.toLowerCase();
    if (!searchvalue) {
      this.setState({
        page1: true,
        issearchAthlete: false,
      });
      return null;
    }
    var current_id = this.state.current_id - 1;
    const data = {
      meet_id: sessionStorage.getItem('ids'),
      searchvalue: searchvalue,
    };
    await axios
      .post(`${url.url}/api/searchalldata`, data)
      .then((response) => {
        if (response.data.data.length === 0) {
          Swal.fire({
            title: 'Athlete Registration',
            text: 'No Record Found',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          });
        } else {
          this.setState({
            searchdata: response.data.data[0],
            firstname: response.data.data[0].firstname,
            lastname: response.data.data[0].lastname,
            bib_no: response.data.data[0].bib_no,
            citizenship: response.data.data[0].citizenship,
            ipc: response.data.data[0].ipc,
            register_num: response.data.data[0].register_num,
            email: response.data.data[0].email,
            age: response.data.data[0].age,
            dob: response.data.data[0].dob,
            gender: response.data.data[0].gender,
            designation: response.data.data[0].designation,
            team_name: response.data.data[0].team_name,
            coach_email: response.data.data[0].coach_email,
            address: response.data.data[0].address,
            address2: response.data.data[0].address2,
            city: response.data.data[0].city,
            zipcode: response.data.data[0].zipcode,
            country: response.data.data[0].country,
            cellphone: response.data.data[0].cellphone,
            classification_site: response.data.data[0].classification_site,
            classified_whom: response.data.data[0].classified_whom,
            coach_come_meet: response.data.data[0].coach_come_meet,
            weightlifting_age: response.data.data[0].weightlifting_age,
            weightlift_events: response.data.data[0].weightlift_events,
            track_events: response.data.data[0].track_events,
            track_age: response.data.data[0].track_age,
            team_events: response.data.data[0].team_events,
            tabletennis_events: response.data.data[0].tabletennis_events,
            swim_events: response.data.data[0].swim_events,
            swim_age: response.data.data[0].swim_age,
            state: response.data.data[0].state,
            sanctioned_meet: response.data.data[0].sanctioned_meet,
            // othervenues_age: response.data.data[0].othervenues_age,
            other_events: response.data.data[0].other_events,
            tabletennis_age: response.data.data[0].tabletennis_age,
            team_age: response.data.data[0].team_age,
            // otherven: JSON.parse(response.data.data[0].othervenues_age),
            airrifle_age: response.data.data[0].airrifle_age,
            boccia_age: response.data.data[0].boccia_age,
            cycling_age: response.data.data[0].cycling_age,
            paratriathlon100_age: response.data.data[0].paratriathlon100_age,
            paratriathlon200_age: response.data.data[0].paratriathlon200_age,
            paratriathlon500_age: response.data.data[0].paratriathlon500_age,
            adaptivetennis_age: response.data.data[0].fencing_age,
            need_classified: response.data.data[0].need_classified,
            coach_homephone: response.data.data[0].coach_homephone,
            homephone: response.data.data[0].homephone,
            archery_age: response.data.data[0].archery_age,
            field_events: response.data.data[0].field_events,
            field_age: response.data.data[0].field_age,
            fencing_events: response.data.data[0].fencing_events,
            comments: response.data.data[0].comments,
            archery_events: response.data.data[0].archery_events,
            headcoach_name: response.data.data[0].headcoach_name,
            coach_workphone: response.data.data[0].coach_workphone,
            classifiedby: response.data.data[0].classified_whom,
            // sanctioned: response.data.data[0].sanctioned_meet,
            id: response.data.data[0].id,
            page1: false,
            issearchAthlete: true,
            activePage: response.data.data[0].id - current_id,
          });
          $('#name').val(this.state.firstname);
          $('#name1').val(this.state.lastname);
          $('#bib_no').val(this.state.bib_no);
          $('#register_num').val(this.state.register_num);
          $('#citizenship').val(this.state.citizenship);
          $('#ipcno').val(this.state.ipc);
          $('#email').val(this.state.email);
          $('#age').val(this.state.age);
          $('#dob').val(this.state.dob);
          $('#gender').val(this.state.gender);
          $('#designate').val(this.state.designation);
          $('#team').val(this.state.team_name);
          $('#cemail').val(this.state.coach_email);
          $('#Address').val(this.state.address);
          $('#Address2').val(this.state.address2);
          $('#City').val(this.state.city);
          $('#zipcode').val(this.state.zipcode);
          $('#country').val(this.state.country);
          $('#homephone').val(this.state.homephone);
          $('#cellphone').val(this.state.cellphone);
          $('#classification_site').val(this.state.classification_site);
          $('#classified_whom').val(this.state.classified_whom);
          $('#coach_come_meet').val(this.state.coach_come_meet);
          $('#weightlifting_age').val(this.state.weightlifting_age);
          $('#weightlift_events').val(this.state.weightlift_events);
          $('#track_events').val(this.state.track_events);
          $('#track_age').val(this.state.track_age);
          $('#team_events').val(this.state.team_events);
          $('#tabletennis_events').val(this.state.tabletennis_events);
          $('#swim_events').val(this.state.swim_events);
          $('#swim_age').val(this.state.swim_age);
          $('#state').val(this.state.state);
          $('#sanctioned_meet').val(this.state.sanctioned_meet);
          // $('#othervenues_age').val(this.state.othervenues_age);
          $('#boccia_age').val(this.state.boccia_age);
          $('#cycling_age').val(this.state.cycling_age);
          $('#airrifle_age').val(this.state.airrifle_age);
          $('#paratriathlon100_age').val(this.state.paratriathlon100_age);
          $('#paratriathlon200_age').val(this.state.paratriathlon200_age);
          $('#paratriathlon500_age').val(this.state.paratriathlon500_age);
          $('#other_events').val(this.state.other_events);
          $('#need_classified').val(this.state.need_classified);
          $('#coach_homephone').val(this.state.coach_homephone);
          $('#homephone').val(this.state.homephone);
          $('#archery_age').val(this.state.archery_age);
          $('#field_events').val(this.state.field_events);
          $('#field_age').val(this.state.field_age);
          $('#fencing_events').val(this.state.fencing_events);
          $('#comments').val(this.state.comments);
          $('#archery_events').val(this.state.archery_events);
          $('#headcoach_name').val(this.state.headcoach_name);
          $('#coach_workphone').val(this.state.coach_workphone);
          $('#classifiedby').val(this.state.classifiedby);
          // $("#sanctioned").val(this.state.sanctioned);
          if (this.state.searchdata.need_classified == 'Ambulatory') {
            document.getElementById('track-radio1').checked = true;
            document.getElementById('track-radio2').checked = false;
            document.getElementById('track-radio3').checked = false;
          }
          if (this.state.searchdata.need_classified == 'Wheelchair') {
            document.getElementById('track-radio1').checked = false;
            document.getElementById('track-radio2').checked = true;
            document.getElementById('track-radio3').checked = false;
          }
          if (this.state.searchdata.need_classified == 'No') {
            document.getElementById('track-radio1').checked = false;
            document.getElementById('track-radio2').checked = false;
            document.getElementById('track-radio3').checked = true;
          }
        }
      })
      .catch(function (error) {});
  };

  deleteAllData = () => {
    let ids = sessionStorage.getItem('ids');
    axios
      .delete(`${url.url}/api/getalldatadelete/${ids}`, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            title: 'Success',
            text: 'All Data will be deleted!',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Record Not Found',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  };

  render() {
    const { selectedItems } = this.state;

    if (this.state.pageload == true) {
      return (
        window.location.replace('/Athletevenues'), sessionStorage.setItem('athleteid', this.state.registered[0].id)
        //< Athletevenues registerdatas={this.state.registered}/>
      );
    }
    if (this.state.page1 == true) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='content'>
                  {/* <div class="modal-dialog2" role="document" id="registermodal"> */}
                  {/* <div class="modal-content modal-content1"> */}
                  {/* <div class="modal-header"> */}
                  {/* <h2>{this.state.meetspecfic.name}</h2> */}
                  <Row>
                    <Col md='8'>
                      <button
                        type='button'
                        class='btn btn-danger btn-round'
                        onClick={() => window.location.replace('/athleteregister')}
                      >
                        Back
                      </button>

                      {/* <button
                        type="button"
                        class="btn btn-danger btn-round"
                        onClick={this.deleteAllData}
                      >
                        All Clear/Delete
                      </button> */}

                      <Card>
                        <CardHeader>
                          <h5 className='title'>Athlete Search </h5>
                        </CardHeader>
                        <CardBody>
                          <Form>
                            <div class='modal-body'>
                              <form>
                                <div class='col-xs-6 attsearch'>
                                  <input
                                    class='form-control'
                                    id='inputsms'
                                    placeholder='Search...'
                                    type='text'
                                    onChange={(e) => this.classval(e)}
                                  />
                                  <button
                                    style={{ margin: '0px 10px' }}
                                    type='button'
                                    class='btn btn-danger btn-round attsearchbtn'
                                    onClick={(e) => this.onSearch(e)}
                                  >
                                    Search
                                  </button>
                                  <div class='attpagenation'>
                                    <Pagination
                                      innerClass='pagination pageatl'
                                      activePage={this.state.activePage}
                                      itemsCountPerPage={this.state.itemsCountPerPage}
                                      totalItemsCount={this.state.totalItemsCount}
                                      pageRangeDisplayed={this.state.pageRangeDisplayed}
                                      onChange={(pageNumber) => this.handlesearch(pageNumber)}
                                      itemClass='page-item'
                                      linkClass='page-link'
                                    />
                                  </div>
                                </div>

                                <div class='form-group'>
                                  <label for='exampleFormControlSelect1'>Designation</label>
                                  <select
                                    class='form-control'
                                    id='designate'
                                    name='desg'
                                    //value={this.state.allregdatas.designation}
                                    onChange={this.handlechange}
                                    required
                                  >
                                    <option>Please Select</option>
                                    <option value='Athlete'>Athlete</option>
                                    <option value='headcoach'>Head Coach</option>
                                    <option value='coach'>Coach</option>
                                    <option value='assitant coach'>Assitant Coach</option>
                                    {/* <option value="parent">Parent</option>
                                <option value="staff">Support Staff</option>
                                <option value="offical">Official</option> */}
                                  </select>
                                </div>
                                <div class='form-row'>
                                  <div class='form-group col-md-6'>
                                    <label for='inputEmail4'>FirstName</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='name'
                                      placeholder='firstname'
                                      // value={this.state.allregdatas.firstname}
                                      onChange={(e) =>
                                        this.setState({
                                          firstname: e.target.value,
                                        })
                                      }
                                      required
                                    />
                                  </div>

                                  <div class='form-group col-md-6'>
                                    <label for='inputPassword4'>LastName</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='name1'
                                      placeholder='lastname'
                                      //value={this.state.allregdatas.lastname}
                                      onChange={(e) =>
                                        this.setState({
                                          lastname: e.target.value,
                                        })
                                      }
                                      required
                                    />
                                  </div>
                                </div>
                                <div class='form-row'>
                                  <div class='form-group col-md-6'>
                                    <label for='email'>Email</label>
                                    <input
                                      type='email'
                                      class='form-control'
                                      id='email'
                                      placeholder='email'
                                      //value={this.state.allregdatas.email}
                                      onChange={(e) =>
                                        this.setState({
                                          email: e.target.value,
                                        })
                                      }
                                      // required
                                    />
                                  </div>

                                  {/* <div class="form-group col-md-4">
                    <label for="inputZip">Password</label>
                    <input type="password" class="form-control" id="inputZip" placeholder="password" onChange={e => this.setState({ pwd: e.target.value })} required/>
                    </div>
                    <div class="form-group col-md-4 confirm">
                    <label for="inputZip">Confirm password</label>
                    <input type="password" class="form-control" id="inputZip" placeholder="confirm password" onChange={e => this.setState({ cpwd: e.target.value })} required/>
                    </div> */}

                                  <div class='form-group col-md-6'>
                                    <label for='DOB'>DOB</label>
                                    <input
                                      type='date'
                                      class='form-control'
                                      id='dob'
                                      //value={this.state.allregdatas.dob}
                                      onChange={this.handlechange1}
                                      required
                                    />
                                  </div>
                                </div>
                                <div class='form-row'>
                                  <div class='form-group col-md-6'>
                                    <label for='age'>Age</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='age'
                                      placeholder='Enter your age'
                                      value={this.state.age1}
                                      disabled
                                      // required
                                    />
                                  </div>

                                  <div class='form-group col-md-6'>
                                    <label for='inputState'>Gender</label>
                                    <select
                                      id='gender'
                                      class='form-control'
                                      name='gender'
                                      //value={this.state.allregdatas.gender}
                                      onChange={this.genderchange}
                                      required
                                    >
                                      <option selected>Choose...</option>
                                      <option value='Male'>Male</option>
                                      <option value='Female'>Female</option>
                                    </select>
                                  </div>

                                  {/* <div class="form-group col-md-4">
                                <label for="dsoselect">DSO</label>
                                <select
                                  class="form-control"
                                  id="dsoselect"
                                  name="dso"
                                  onChange={this.handlechange2}
                                  required
                                >
                                  <option>Please Select</option>
                                  <option value="AMB">Ambulatory</option>
                                  <option value="BA">Blind</option>
                                  <option value="ID">
                                    Intelectually Disabled
                                  </option>
                                  <option value="CP">Cerebral palsey</option>
                                  <option value="DS">Amputees</option>
                                  <option value="DA">Dwarf</option>
                                  <option value="WC">Wheelchair</option>
                                  <option value="USATF">USATF</option>
                                </select>
                              </div> */}
                                </div>
                                <div class='form-row'>
                                  <div class='form-group col-md-6'>
                                    <label for='inputAddress'>Address</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='Address'
                                      //value={this.state.allregdatas.address}
                                      placeholder='Enter your address'
                                      onChange={(e) =>
                                        this.setState({
                                          address: e.target.value,
                                        })
                                      }
                                      // required
                                    />
                                  </div>

                                  <div class='form-group col-md-6'>
                                    <label for='inputAddress2'>Address 2</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='Address2'
                                      placeholder='Enter your address'
                                      //value={this.state.allregdatas.address2}
                                      onChange={(e) =>
                                        this.setState({
                                          address2: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                                <div class='form-row'>
                                  <div class='form-group col-md-6'>
                                    <label for='inputCity'>City</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='City'
                                      placeholder='Enter city'
                                      //value={this.state.allregdatas.city}
                                      onChange={(e) =>
                                        this.setState({
                                          city: e.target.value,
                                        })
                                      }
                                      // required
                                    />
                                  </div>

                                  <div class='form-group col-md-6'>
                                    <label for='state'>State/Province</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='state'
                                      placeholder='Enter State'
                                      //value={this.state.allregdatas.state}
                                      onChange={(e) =>
                                        this.setState({
                                          state: e.target.value,
                                        })
                                      }
                                      // required
                                    />
                                  </div>

                                  <div class='form-group col-md-4'>
                                    <label for='inputZip'>Zip/Mail code</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='zipcode'
                                      placeholder='Enter Zip code'
                                      //value={this.state.allregdatas.zipcode}
                                      onChange={(e) =>
                                        this.setState({
                                          zipcode: e.target.value,
                                        })
                                      }
                                      // required
                                    />
                                  </div>

                                  <div class='form-group col-md-4'>
                                    <label for='inputCountry'>Country</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='country'
                                      placeholder='Enter country'
                                      //value={this.state.allregdatas.country}
                                      onChange={(e) =>
                                        this.setState({
                                          country: e.target.value,
                                        })
                                      }
                                      // required
                                    />
                                  </div>

                                  <div class='form-group col-md-4'>
                                    <label for='inputCellphone'>Cellphone</label>
                                    <input
                                      type='number'
                                      class='form-control'
                                      id='cellphone'
                                      placeholder='Enter cellphone'
                                      //value={this.state.allregdatas.cellphone}
                                      onChange={(e) =>
                                        this.setState({
                                          cellphone: e.target.value,
                                        })
                                      }
                                      required
                                    />
                                  </div>
                                  <div class='form-group col-md-4'>
                                    <label for='citizenship'>Citizenship </label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='citizenship'
                                      placeholder='Enter Citizenship'
                                      // value={this.state.allregdatas.citizenship}
                                      onChange={(e) =>
                                        this.setState({
                                          citizenship: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                  <div class='form-group col-md-4'>
                                    <label for='inputCity'>Team Name</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='team'
                                      placeholder='Enter team name'
                                      //value={this.state.allregdatas.team_name}
                                      onChange={(e) =>
                                        this.setState({
                                          team_name: e.target.value,
                                        })
                                      }
                                      required
                                    />
                                  </div>
                                  <div class='form-group col-md-4'>
                                    <label for='ipcnum'>IPC</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='ipcno'
                                      placeholder='Enter ipc'
                                      // value={this.state.allregdatas.ipc}
                                      onChange={(e) => this.setState({ ipc: e.target.value })}
                                    />
                                  </div>
                                </div>
                                <div class='form-row'>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label>Track</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='track_age'
                                      disabled='disabled'
                                      value={this.state.tagediv}
                                      required
                                    />
                                  </div>

                                  <div class='from-group from-group1 col-md-6'>
                                    <label>Field</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='field_age'
                                      disabled='disabled'
                                      value={this.state.fagediv}
                                      required
                                    />
                                  </div>

                                  <div class='from-group from-group1 col-md-6'>
                                    <label>Archery</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='archery_age'
                                      disabled='disabled'
                                      value={this.state.Aagediv}
                                      required
                                    />
                                  </div>

                                  <div class='from-group from-group1 col-md-6'>
                                    <label>Swimming</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='swim_age'
                                      disabled='disabled'
                                      value={this.state.sagediv}
                                      required
                                    />
                                  </div>

                                  <div class='from-group from-group1 col-md-6'>
                                    <label>Para Powerlifting</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='weightlifting_age'
                                      disabled='disabled'
                                      value={this.state.wagediv}
                                      required
                                    />
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label>Adaptive Tennis</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='adaptivetennis_age'
                                      disabled='disabled'
                                      value={this.state.adaptivetennis_age}
                                      required
                                    />
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label>Team Sports</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='teamsports_age'
                                      disabled='disabled'
                                      value={this.state.teamsports_age}
                                      required
                                    />
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label>Air Rifle Age</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='airrifle_age'
                                      disabled='disabled'
                                      value={this.state.airrifle_age}
                                      required
                                    />
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label>Paratriathlon 100 Age</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='paratriathlon100_age'
                                      disabled='disabled'
                                      value={this.state.paratriathlon100_age}
                                      required
                                    />
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label>Paratriathlon 200 Age</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='paratriathlon200_age'
                                      disabled='disabled'
                                      value={this.state.paratriathlon200_age}
                                      required
                                    />
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label>Paratriathlon 500 Age</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='paratriathlon500_age'
                                      disabled='disabled'
                                      value={this.state.paratriathlon500_age}
                                      required
                                    />
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label>Boccia Age</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='boccia_age'
                                      disabled='disabled'
                                      value={this.state.boccia_age}
                                      required
                                    />
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label>Cycling Age</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='cycling_age'
                                      disabled='disabled'
                                      value={this.state.cycling_age}
                                      required
                                    />
                                  </div>
                                </div>
                                <div class='form-row'>
                                  <div class='form-group col-md-4'>
                                    <label for='inputCity'>Head Coach's Name</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='inputCity'
                                      placeholder="Head coach's name"
                                      onChange={(e) =>
                                        this.setState({
                                          headcoachname: e.target.value,
                                        })
                                      }
                                      // required
                                    />
                                  </div>
                                  <div class='form-group col-md-4'>
                                    <label for='inputCity'>Coach's Home Phone</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='inputCity'
                                      placeholder="Coach's home phone"
                                      onChange={(e) =>
                                        this.setState({
                                          coachhomephone: e.target.value,
                                        })
                                      }
                                      // required
                                    />
                                  </div>
                                </div>
                                <div class='checkalign'>
                                  <button
                                    type='button'
                                    rel='tooltip'
                                    class='btn btn-success btn-round'
                                    onClick={this.save}
                                  >
                                    Save
                                  </button>
                                  <button
                                    type='button'
                                    rel='tooltip'
                                    class='btn btn-danger btn-round'
                                    value='Save'
                                    onClick={(e) => this.managevenue(this.state.searchdata.id)}
                                  >
                                    Venues
                                  </button>
                                </div>
                              </form>

                              <Pagination
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.itemsCountPerPage}
                                totalItemsCount={this.state.totalItemsCount}
                                pageRangeDisplayed={this.state.pageRangeDisplayed}
                                onChange={(pageNumber) => this.handlesearch(pageNumber)}
                                itemClass='page-item'
                                linkClass='page-link'
                              />
                            </div>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
              {/* </div> */}
              {/* </div> */}
              {/* </div> */}
            </>
          )}
        </>
      );
    }

    if (this.state.issearchAthlete == true) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='content'>
                  {/* <div class="modal-dialog2" role="document" id="registermodal"> */}
                  {/* <div class="modal-content modal-content1"> */}
                  {/* <div class="modal-header"> */}
                  {/* <h2>{this.state.meetspecfic.name}</h2> */}
                  <Row>
                    <Col md='8'>
                      <button
                        type='button'
                        class='btn btn-danger btn-round'
                        onClick={() => window.location.replace('/athleteregister')}
                      >
                        Back
                      </button>
                      {/* <button
                        type="button"
                        class="btn btn-danger btn-round"
                        onClick={this.deleteAllData}
                      >
                        All Clear/Delete
                      </button> */}
                      <Card>
                        <CardHeader>
                          <h5 className='title'>Athlete Search </h5>
                        </CardHeader>
                        <CardBody>
                          <Form>
                            <div class='modal-body'>
                              <form>
                                <div class='col-xs-6 attsearch'>
                                  <input
                                    class='form-control'
                                    id='inputsms'
                                    placeholder='Search...'
                                    type='text'
                                    onChange={(e) => this.classval(e)}
                                  />
                                  <button
                                    style={{ margin: '0px 10px' }}
                                    type='button'
                                    class='btn btn-danger btn-round attsearchbtn'
                                    onClick={(e) => this.onSearch(e)}
                                  >
                                    Search
                                  </button>
                                  <div class='attpagenation'>
                                    <Pagination
                                      innerClass='pagination pageatl'
                                      activePage={this.state.activePage}
                                      itemsCountPerPage={this.state.itemsCountPerPage}
                                      totalItemsCount={this.state.totalItemsCount}
                                      pageRangeDisplayed={this.state.pageRangeDisplayed}
                                      onChange={(pageNumber) => this.handlesearch(pageNumber)}
                                      itemClass='page-item'
                                      linkClass='page-link'
                                    />
                                  </div>
                                </div>

                                <div class='form-group'>
                                  <label for='exampleFormControlSelect1'>Designation</label>
                                  <select
                                    class='form-control'
                                    id='designate'
                                    name='desg'
                                    //value={this.state.allregdatas.designation}
                                    onChange={this.handlechange}
                                    required
                                  >
                                    <option>Please Select</option>
                                    <option value='Athlete'>Athlete</option>
                                    <option value='headcoach'>Head Coach</option>
                                    <option value='coach'>Coach</option>
                                    <option value='assitant coach'>Assitant Coach</option>
                                    {/* <option value="parent">Parent</option>
                                <option value="staff">Support Staff</option>
                                <option value="offical">Official</option> */}
                                  </select>
                                </div>
                                <div class='form-row'>
                                  <div class='form-group col-md-6'>
                                    <label for='inputEmail4'>FirstName</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='name'
                                      placeholder='firstname'
                                      // value={this.state.allregdatas.firstname}
                                      onChange={(e) =>
                                        this.setState({
                                          firstname: e.target.value,
                                        })
                                      }
                                      required
                                    />
                                  </div>

                                  <div class='form-group col-md-6'>
                                    <label for='inputPassword4'>LastName</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='name1'
                                      placeholder='lastname'
                                      //value={this.state.allregdatas.lastname}
                                      onChange={(e) =>
                                        this.setState({
                                          lastname: e.target.value,
                                        })
                                      }
                                      required
                                    />
                                  </div>
                                </div>
                                <div class='form-row'>
                                  <div class='form-group col-md-6'>
                                    <label for='email'>Email</label>
                                    <input
                                      type='email'
                                      class='form-control'
                                      id='email'
                                      placeholder='email'
                                      //value={this.state.allregdatas.email}
                                      onChange={(e) =>
                                        this.setState({
                                          email: e.target.value,
                                        })
                                      }
                                      // required
                                    />
                                  </div>
                                  <div class='form-group col-md-6'>
                                    <label for='DOB'>DOB</label>
                                    <input
                                      type='date'
                                      class='form-control'
                                      id='dob'
                                      //value={this.state.allregdatas.dob}
                                      onChange={this.handlechange1}
                                      required
                                    />
                                  </div>
                                </div>
                                <div class='form-row'>
                                  <div class='form-group col-md-6'>
                                    <label for='age'>Age</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='age'
                                      placeholder='Enter your age'
                                      value={this.state.age1}
                                      disabled
                                      // required
                                    />
                                  </div>

                                  <div class='form-group col-md-6'>
                                    <label for='inputState'>Gender</label>
                                    <select
                                      id='gender'
                                      class='form-control'
                                      name='gender'
                                      //value={this.state.allregdatas.gender}
                                      onChange={this.genderchange}
                                      required
                                    >
                                      <option selected>Choose...</option>
                                      <option value='Male'>Male</option>
                                      <option value='Female'>Female</option>
                                    </select>
                                  </div>
                                </div>
                                <div class='form-row'>
                                  <div class='form-group col-md-6'>
                                    <label for='inputAddress'>Address</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='Address'
                                      //value={this.state.allregdatas.address}
                                      placeholder='Enter your address'
                                      onChange={(e) =>
                                        this.setState({
                                          address: e.target.value,
                                        })
                                      }
                                      // required
                                    />
                                  </div>

                                  <div class='form-group col-md-6'>
                                    <label for='inputAddress2'>Address 2</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='Address2'
                                      placeholder='Enter your address'
                                      //value={this.state.allregdatas.address2}
                                      onChange={(e) =>
                                        this.setState({
                                          address2: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                                <div class='form-row'>
                                  <div class='form-group col-md-6'>
                                    <label for='inputCity'>City</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='City'
                                      placeholder='Enter city'
                                      //value={this.state.allregdatas.city}
                                      onChange={(e) =>
                                        this.setState({
                                          city: e.target.value,
                                        })
                                      }
                                      // required
                                    />
                                  </div>

                                  <div class='form-group col-md-6'>
                                    <label for='state'>State/Province</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='state'
                                      placeholder='Enter State'
                                      //value={this.state.allregdatas.state}
                                      onChange={(e) =>
                                        this.setState({
                                          state: e.target.value,
                                        })
                                      }
                                      // required
                                    />
                                  </div>

                                  <div class='form-group col-md-4'>
                                    <label for='inputZip'>Zip/Mail code</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='zipcode'
                                      placeholder='Enter Zip code'
                                      //value={this.state.allregdatas.zipcode}
                                      onChange={(e) =>
                                        this.setState({
                                          zipcode: e.target.value,
                                        })
                                      }
                                      // required
                                    />
                                  </div>

                                  <div class='form-group col-md-4'>
                                    <label for='inputCountry'>Country</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='country'
                                      placeholder='Enter country'
                                      //value={this.state.allregdatas.country}
                                      onChange={(e) =>
                                        this.setState({
                                          country: e.target.value,
                                        })
                                      }
                                      // required
                                    />
                                  </div>

                                  <div class='form-group col-md-4'>
                                    <label for='inputCellphone'>Cellphone</label>
                                    <input
                                      type='number'
                                      class='form-control'
                                      id='cellphone'
                                      placeholder='Enter cellphone'
                                      //value={this.state.allregdatas.cellphone}
                                      onChange={(e) =>
                                        this.setState({
                                          cellphone: e.target.value,
                                        })
                                      }
                                      required
                                    />
                                  </div>
                                  <div class='form-group col-md-4'>
                                    <label for='citizenship'>Citizenship </label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='citizenship'
                                      placeholder='Enter Citizenship'
                                      // value={this.state.allregdatas.citizenship}
                                      onChange={(e) =>
                                        this.setState({
                                          citizenship: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                  <div class='form-group col-md-4'>
                                    <label for='inputCity'>Team Name</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='team'
                                      placeholder='Enter team name'
                                      //value={this.state.allregdatas.team_name}
                                      onChange={(e) =>
                                        this.setState({
                                          team_name: e.target.value,
                                        })
                                      }
                                      required
                                    />
                                  </div>
                                  <div class='form-group col-md-4'>
                                    <label for='ipcnum'>IPC</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='ipcno'
                                      placeholder='Enter ipc'
                                      // value={this.state.allregdatas.ipc}
                                      onChange={(e) => this.setState({ ipc: e.target.value })}
                                    />
                                  </div>
                                </div>
                                <div class='form-row'>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label>Track</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='track_age'
                                      disabled='disabled'
                                      value={this.state.tagediv}
                                      required
                                    />
                                  </div>

                                  <div class='from-group from-group1 col-md-6'>
                                    <label>Field</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='field_age'
                                      disabled='disabled'
                                      value={this.state.fagediv}
                                      required
                                    />
                                  </div>

                                  <div class='from-group from-group1 col-md-6'>
                                    <label>Archery</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='archery_age'
                                      disabled='disabled'
                                      value={this.state.Aagediv}
                                      required
                                    />
                                  </div>

                                  <div class='from-group from-group1 col-md-3'>
                                    <label>Swimming</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='swim_age'
                                      disabled='disabled'
                                      value={this.state.sagediv}
                                      required
                                    />
                                  </div>

                                  <div class='from-group from-group1 col-md-3'>
                                    <label>Para Powerlifting</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='weightlifting_age'
                                      disabled='disabled'
                                      value={this.state.wagediv}
                                      required
                                    />
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label>Adaptive Tennis</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='adaptivetennis_age'
                                      disabled='disabled'
                                      value={this.state.adaptivetennis_age}
                                      required
                                    />
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label>Team Sports</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='teamsports_age'
                                      disabled='disabled'
                                      value={this.state.teamsports_age}
                                      required
                                    />
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label>Air Rifle Age</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='airrifle_age'
                                      disabled='disabled'
                                      value={this.state.airrifle_age}
                                      required
                                    />
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label>Paratriathlon 100 Age</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='paratriathlon100_age'
                                      disabled='disabled'
                                      value={this.state.paratriathlon100_age}
                                      required
                                    />
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label>Paratriathlon 200 Age</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='paratriathlon200_age'
                                      disabled='disabled'
                                      value={this.state.paratriathlon200_age}
                                      required
                                    />
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label>Paratriathlon 500 Age</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='paratriathlon500_age'
                                      disabled='disabled'
                                      value={this.state.paratriathlon500_age}
                                      required
                                    />
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label>Boccia Age</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='boccia_age'
                                      disabled='disabled'
                                      value={this.state.boccia_age}
                                      required
                                    />
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label>Cycling Age</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='cycling_age'
                                      disabled='disabled'
                                      value={this.state.cycling_age}
                                      required
                                    />
                                  </div>
                                  {/* <div class='from-group from-group1 col-md-3'>
                                    <label>Other Venues</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='othervenues_age'
                                      disabled='disabled'
                                      value={this.state.oagediv}
                                      required
                                    />
                                  </div> */}
                                </div>
                                <div class='form-row'>
                                  <div class='form-group col-md-4'>
                                    <label for='inputCity'>Head Coach's Name</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='headcoach_name'
                                      placeholder="Head coach's name"
                                      // value={
                                      //   this.state.allregdatas.headcoach_name
                                      // }
                                      onChange={(e) =>
                                        this.setState({
                                          headcoach_name: e.target.value,
                                        })
                                      }
                                      // required
                                    />
                                  </div>
                                  <div class='form-group col-md-4'>
                                    <label for='inputCity'>Coach's Home Phone</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='coach_homephone'
                                      placeholder="Coach's home phone"
                                      // value={
                                      //   this.state.allregdatas.coach_homephone
                                      // }
                                      onChange={(e) =>
                                        this.setState({
                                          coach_homephone: e.target.value,
                                        })
                                      }
                                      // required
                                    />
                                  </div>
                                  <div class='form-group col-md-4'> </div>
                                </div>
                                <div class='checkalign'>
                                  <button
                                    type='button'
                                    rel='tooltip'
                                    class='btn btn-success btn-round'
                                    onClick={this.save}
                                  >
                                    Save
                                  </button>
                                  <button
                                    type='button'
                                    rel='tooltip'
                                    class='btn btn-danger btn-round'
                                    value='Save'
                                    onClick={(e) => this.managevenue(this.state.searchdata.id)}
                                  >
                                    Venues
                                  </button>
                                </div>
                              </form>
                              <Pagination
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.itemsCountPerPage}
                                totalItemsCount={this.state.totalItemsCount}
                                pageRangeDisplayed={this.state.pageRangeDisplayed}
                                onChange={(pageNumber) => this.handlesearch(pageNumber)}
                                itemClass='page-item'
                                linkClass='page-link'
                              />
                            </div>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
              {/* </div> */}
              {/* </div> */}
              {/* </div> */}
            </>
          )}
        </>
      );
    }
  }
}
export default Registeredsearch;
