import React, { useCallback } from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import { Empty } from 'antd';
import './compound.css';

import Swal from 'sweetalert2';
import e from 'cors';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import Loader from 'react-loader-spinner';
//import Iconsreload from './Meetload.js'
import Paratriathlonset from './Paratriathlonsset.js';
import Paratriathlontwoset from './Paratwoset.js';
const url = window.globalConfig;
class Otherset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      airgunsetdata: [{}],
      airgunsets: '',
      venuenamedata: '',
      venuelocationdata: '',
      numberdata: '',
      currentdata: '',
      perrecorddata: '',
      venuedata: '',
      ag1data: '',
      ag2data: '',
      ag3data: '',
      ag4data: '',
      eventdata: '',
      typedata: '',
      teamdata: '',
      paraset: false,
      paraset1: false,
      venuedatas: [{}],
      venuespecfic: [{}],
      venueevents: '',
      othereventsdata: '',
      venueid: '',
      airgunlocationset: [{}],
      airgunlocset: {},
      loader: true,
      Otherseteventname: '',
      airguneventdata: [{}],
      neweventdata: [{}],
      geteventnameid: '',
      geteventname: '',
      othersetdata: {},
    };

    this.createairgunset = this.createairgunset.bind(this);
    this.editairgunset = this.editairgunset.bind(this);
    this.optionchange = this.optionchange.bind(this);
    this.dropdownchange1 = this.dropdownchange1.bind(this);
    this.dropdownchange2 = this.dropdownchange2.bind(this);
    this.dropdownchange3 = this.dropdownchange3.bind(this);
    this.updateairgunset = this.updateairgunset.bind(this);
    this.optionchange1 = this.optionchange1.bind(this);
    this.optionchange2 = this.optionchange2.bind(this);
    this.optionchange3 = this.optionchange3.bind(this);
    this.optionchange4 = this.optionchange4.bind(this);
    this.back = this.back.bind(this);
    this.createairgunlocationset = this.createairgunlocationset.bind(this);
    this.editlocationset = this.editlocationset.bind(this);
    this.updateairgunlocationset = this.updateairgunlocationset.bind(this);
    this.changeparatriathlonset = this.changeparatriathlonset.bind(this);
    this.changeparatriathlontwoset = this.changeparatriathlontwoset.bind(this);
    // this.createevent = this.createevent.bind(this);
    this.createeventmodel = this.createeventmodel.bind(this);
    this.deleteairgunset = this.deleteairgunset.bind(this);
  }

  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    var self = this;
    var count = 0;
    let textval = sessionStorage.getItem('x');
    axios
      .get(`${url.url}/api/venues`, {})
      .then(function (response) {
        self.setState({
          venuedatas: response.data.data,
          venuespecfic: response.data.data[textval],
          venueevents: response.data.data[textval].events,
        });
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {});
    let meetid = sessionStorage.getItem('ids');
    axios
      .get(`${url.url}/api/getothermeetid/${meetid}`, {})
      .then(function (response) {
        self.setState({
          airgunsetdata: response.data,
        });
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
    axios
      .get(`${url.url}/api/airgunlocationmeetid/${meetid}`, {})
      .then(function (response) {
        self.setState({
          airgunlocationset: response.data,
          airgunlocset: response.data[0],
        });
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
    //Hide and show manager
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    // document.getElementById("venueesss").style.display = "block";

    axios
      .get(`${url.url}/api/getallairgunsetrecords`, {})
      .then(function (response) {
        self.setState({
          airguneventdata: response.data,
        });
      })
      .catch(function (error) {});

    axios
      .get(`${url.url}/api/geteventmeetid/${meetid}`, {})
      .then(function (response) {
        self.setState({
          othersetdata: response.data,
        });
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
  }

  changeparatriathlonset() {
    this.setState({
      paraset: true,
    });
  }

  changeparatriathlontwoset() {
    this.setState({
      paraset1: true,
    });
  }
  //dropdown values

  dropdownchange1(e) {
    this.setState({
      teamdata: e.target.value,
    });
    this.setState({
      teamdata: e.target.value,
    });
  }

  dropdownchange2(e) {
    this.setState({
      typedata: e.target.value,
    });
  }

  dropdownchange3(e) {
    this.setState({
      selectdata: e.target.value,
    });
  }

  optionchange(e) {
    this.setState({
      eventdata: e.target.value,
    });
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  optionchange1(e) {
    this.setState({
      venuenamedata: e.target.value,
    });
  }

  optionchange2(e) {
    this.setState({
      venuelocationdata: e.target.value,
    });
  }

  optionchange3(e) {
    this.setState({
      numberdata: e.target.value,
    });
  }

  optionchange4(e) {
    this.setState({
      currentdata: e.target.value,
    });
  }

  //Edit trackset
  editairgunset(e, i) {
    var self = this;
    self.setState({
      othereventsdata: self.state.othersetdata[e][i].event_name,
      // othereventsdata: self.state.airgunsetdata[e].event_name,
    });
    var loop = true;
    let textval = sessionStorage.getItem('x');
    self.state.othersetdata[e].forEach((question, index) => {
      if (question.event_name == self.state.othersetdata[e][i].event_name) {
        self.setState({
          airgunsets: question,
          selected_id: question.id,
          eventdata: question.event_name,
          typedata: question.type_of_event,
          teamdata: question.team_scoring,
          btncheck: false,
        });
        loop = false;
      } else if (loop) {
        self.setState({
          airgunsets: '',
          selected_id: '',
          eventdata: self.state.othersetdata[e][i].event_name,
          typedata: '',
          teamdata: 'Yes',
          btncheck: true,
        });
        loop = true;
      }
    });
    if (self.state.airgunsetdata.length == 0) {
      self.setState({
        airgunsets: '',
        selected_id: '',
        eventdata: self.state.othersetdata[e][i].event_name,
        typedata: '',
        teamdata: 'Yes',
        btncheck: true,
      });
    }
  }
  editlocationset(e) {
    var self = this;
    let textval = sessionStorage.getItem('x');
    self.setState({
      othereventsdata: self.state.venuespecfic.events[e].name,
    });
    var loop = true;
    self.state.airgunlocationset.forEach((question, index) => {
      if (self.state.venuespecfic.events[e].name == question.venue_name) {
        self.setState({
          venuedata: self.state.venuespecfic.events[e].name,
          venueid: question.id,
          venuelocationdata: question.venue_location,
          numberdata: question.number_of_events,
          currentdata: question.current_record,
          btncheck1: false,
        });
        loop = false;
      } else if (loop) {
        self.setState({
          venuedata: self.state.venuespecfic.events[e].name,
          venuelocationdata: '',
          numberdata: '1',
          currentdata: '',
          btncheck1: true,
        });
      }
    });
    if (self.state.airgunlocationset.length == 0) {
      self.setState({
        venuedata: self.state.venuespecfic.events[e].name,
        venuelocationdata: '',
        numberdata: '1',
        currentdata: '',
        btncheck1: true,
      });
    }
  }
  createairgunlocationset() {
    var self = this;
    const setvalue1 = (q, index) => {
      if (self.state.airgunlocationset.length > index) {
        self.setState({
          venuedata: self.state.airgunlocationset[index].venue_name,
          venuelocationdata: self.state.airgunlocationset[index].venue_location,
          numberdata: self.state.airgunlocationset[index].number_of_events,
          currentdata: self.state.airgunlocationset[index].current_record,
        });
      }
    };
    self.state.airgunlocationset.map((question, index) => {
      setvalue1(question, index);
    });
    const datatosend = {
      venue_name: this.state.venuedata,
      venue_location: this.state.venuelocationdata,
      number_of_events: this.state.numberdata,
      current_record: this.state.currentdata,
      created_by: 1,
      meet_id: parseInt(sessionStorage.getItem('ids')),
    };

    axios
      .post(`${url.url}/api/createairgunlocationset`, datatosend, {})
      .then(function (response) {
        if (response.statusText == 'Created' || response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'OtherVenue Location Set Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  updateairgunlocationset(e) {
    var self = this;
    //   e.preventDefault()
    const datatosend = {
      venue_name: this.state.venuedata,
      venue_location: this.state.venuelocationdata,
      number_of_events: this.state.numberdata,
      current_record: this.state.currentdata,
    };

    axios
      .put(`${url.url}/api/updateairgunlocationset/${this.state.venueid}`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'OtherVenue Location Set Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  //Create Track set
  createairgunset() {
    var self = this;
    const setvalue = (q, index) => {
      if (self.state.airgunsetdata.length > index) {
        self.setState({
          tablesets: self.state.airgunsetdata[index],
          selected_id: self.state.airgunsetdata[index].id,
          eventdata: self.state.airgunsetdata[index].event_name,
          typedata: self.state.airgunsetdata[index].type_of_event,
          teamdata: self.state.airgunsetdata[index].team_scoring,
        });
      }
    };
    self.state.airgunsetdata.map((question, index) => {
      setvalue(question, index);
    });
    const datatosend = {
      event_name: this.state.eventdata,
      type_of_event: this.state.typedata,
      team_scoring: this.state.teamdata,
      created_by: 1,
      meet_id: parseInt(sessionStorage.getItem('ids')),
      venue_name: this.state.geteventname,
    };
    axios
      .post(`${url.url}/api/createairgunsetrecords`, datatosend, {})
      .then(function (response) {
        if (response.statusText == 'Created' || response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'Otherset Created successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  deleteairgunset(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        //document.getElementById("myTable").deleteRow(e);
        axios
          .delete(`${url.url}/api/deleteairgunsetrecords/${e}`, {})
          .then(function (response) {
            if (response.status) {
              Swal.fire('Deleted!', 'Record has been deleted.', 'success').then((result) => {
                window.location.reload();
              });
            }
          })
          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
      }
      // } else if (result.dismiss === Swal.DismissReason.cancel) {
      //   Swal.fire("Cancelled", "Cancelled :)", "error");
      // }
    });
  }

  //Update trackset
  updateairgunset(e) {
    var self = this;
    //   e.preventDefault()
    const datatosend = {
      event_name: this.state.eventdata,
      type_of_event: this.state.typedata,
      team_scoring: this.state.teamdata,
    };

    axios
      .put(`${url.url}/api/updateairgunsetrecords/${this.state.airgunsets.id}`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Otherset Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  //Delete Trackset
  //

  //back Button
  back() {
    window.location.replace('/eventpreference');
  }

  createeventmodel(e) {
    var self = this;
    self.setState({
      neweventdata: this.state.venueevents[e],
      geteventnameid: this.state.venueevents[e].id,
      geteventname: this.state.venueevents[e].name,
    });
    self.setState({
      eventdata: '',
      typedata: '',
      teamdata: 'No',
      btncheck: true,
    });
  }

  // createevent(){
  //   const datatosend = {
  //     event_name: this.state.Otherseteventname,
  //     created_by: 1,
  //     meet_id: parseInt(sessionStorage.getItem("ids")),
  //     venue_name: this.state.geteventname,
  //     type_of_event: this.state.typedata,
  //     team_scoring: this.state.teamdata,
  //     // otherset_event_id: this.state.geteventnameid
  //   };

  //   axios
  //     .post(`${url.url}/api/createAirgunsetevent`, datatosend, {})
  //     .then(function (response) {
  //       if (response.statusText) {
  //         Swal.fire({
  //           title: "Success",
  //           text: "event added successfully",
  //           icon: "warning",
  //           showCancelButton: false,
  //           confirmButtonColor: "#3085d6",
  //           cancelButtonColor: "#d33",
  //           confirmButtonText: "Ok!",
  //         }).then(function (response) {
  //           window.location.reload();
  //         });
  //       }
  //     })

  //     .catch(function (error) {
  //       Swal.fire({
  //         title: "Failed",
  //         text: "Something went wrong",
  //         icon: "warning",
  //         showCancelButton: false,
  //         confirmButtonColor: "#3085d6",
  //         cancelButtonColor: "#d33",
  //         confirmButtonText: "Ok!",
  //       });
  //     });
  // }

  render() {
    let textval = sessionStorage.getItem('x');
    if (this.state.paraset) {
      return <Redirect to='/paratriathlonsetpreference' />;
    }

    //Paratriathlon 200 set
    if (this.state.paraset1) {
      return <Redirect to='/paratriathlon200setpreference' />;
    }
    var self = this;
    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader
                type='ThreeDots'
                color='#00BFFF'
                height={70}
                width={70}
                // timeout={3000} //3 secs
              />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            {/* AirGun set Preferences*/}
            <div className='content othersetcontent'>
              <Row>
                <Col md='8'>
                  <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                    Back
                  </button>

                  <Card>
                    <CardHeader>
                      <h5 className='title'>OtherVenues Set Preferences</h5>
                    </CardHeader>

                    <CardBody>
                      {Object.keys(this.state.venuespecfic).length == 1 ? (
                        <Empty />
                      ) : (
                        <>
                          {this.state.venuespecfic.events.map((question, index) => (
                            <Form>
                              <div class='form-group row'>
                                <div class='col-xs-5'>
                                  <CardHeader id='deatils_heading'>
                                    <tr>
                                      <th>
                                        <p>
                                          Venue Name:
                                          <span>{question.name}</span>
                                        </p>
                                      </th>
                                      <th>
                                        <p>
                                          Venue Location:
                                          <span>
                                            {this.state.airgunlocationset.map((question1, index1) =>
                                              question.name === question1.venue_name ? question1.venue_location : ''
                                            )}
                                          </span>
                                        </p>
                                      </th>
                                      {/* <th>
                                          <p>
                                            Number of Events:
                                            <span>{this.state.airgunsetdata.length}</span>
                                          </p>

                                        </th> */}
                                      <th>
                                        <p>
                                          Current Record Level:
                                          <span>
                                            {this.state.airgunlocationset.map((question1, index1) =>
                                              question.name === question1.venue_name ? question1.current_record : ''
                                            )}
                                          </span>
                                        </p>
                                      </th>
                                      <th>
                                        <p>
                                          Select Performance Record:
                                          <span>
                                            {this.state.airgunlocationset.map((question1, index1) =>
                                              question.name === question1.venue_name ? question1.current_record : ''
                                            )}
                                          </span>
                                        </p>
                                      </th>
                                      <th>
                                        <button
                                          type='button'
                                          rel='tooltip'
                                          data-toggle='modal'
                                          data-target='#newmeet'
                                          class='btn btn-warning btn-sm btn-icon'
                                          onClick={() => this.editlocationset(index)}
                                        >
                                          <i class='now-ui-icons ui-2_settings-90'></i>
                                        </button>
                                        {` `}
                                        <button
                                          type='button'
                                          class='btn btn-success btn-sm btn-icon'
                                          rel='tooltip'
                                          data-toggle='modal'
                                          data-target='#meetedit'
                                          onClick={() => this.createeventmodel(index)}
                                        >
                                          <i class='now-ui-icons gestures_tap-01'></i>
                                        </button>
                                      </th>
                                    </tr>
                                  </CardHeader>
                                </div>
                              </div>
                              {Object.keys(this.state.venuespecfic).length == 1 ? (
                                <Empty />
                              ) : (
                                <>
                                  <Table id='my' responsive>
                                    <thead>
                                      <tr>
                                        <th className='text-center'>#</th>
                                        <th>Event Name</th>
                                        <th>Type of Event</th>
                                        <th>Team Scoring</th>

                                        <th class='disabled-sorting text-right'>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody id='myTable'>
                                      {Object.keys(this.state.othersetdata).map((item, i) => (
                                        <>
                                          {this.state.othersetdata[item].map((element, ele_index) =>
                                            question.name == element.venue_name ? (
                                              <tr>
                                                <td>{ele_index + 1}</td>
                                                <td>{element.event_name}</td>
                                                <td>{element.type_of_event}</td>
                                                <td>{element.team_scoring}</td>
                                                <td class='td-actions text-right'>
                                                  <button
                                                    type='button'
                                                    rel='tooltip'
                                                    class='btn btn-success btn-sm btn-icon'
                                                    onClick={() => this.editairgunset(element.venue_name, ele_index)}
                                                    data-toggle='modal'
                                                    data-target='#meetedit'
                                                  >
                                                    <i class='now-ui-icons ui-2_settings-90'></i>
                                                  </button>
                                                  {` `}
                                                  <button
                                                    type='button'
                                                    rel='tooltip'
                                                    class='btn btn-danger btn-sm btn-icon'
                                                    onClick={(e) => this.deleteairgunset(element.id)}
                                                  >
                                                    <i class='now-ui-icons ui-1_simple-remove'></i>
                                                  </button>
                                                </td>
                                              </tr>
                                            ) : (
                                              ''
                                            )
                                          )}
                                        </>
                                      ))}
                                    </tbody>
                                  </Table>
                                </>
                              )}
                              {/* table --------------------------------------------------- */}
                            </Form>
                          ))}
                        </>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* edit meet modal ----------------------------------------------------------------------------------------------------------------- */}
              <div class='modal fade' id='meetedit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        {' '}
                        {/* {this.state.othereventsdata + " " + "Set"}{" "} */}
                        New Othervenue Event
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='inputAddress'>Event name</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='evntname'
                            value={this.state.eventdata}
                            onChange={this.optionchange}
                            // disabled="disabled"
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Type of Event</label>
                          <select
                            class='form-control'
                            id='exampleFormControlSelect1'
                            value={this.state.typedata}
                            onChange={this.dropdownchange2}
                            required
                          >
                            <option value=' '>Please Select</option>
                            <option value='Horizontal'>Horizontal</option>
                            <option value='Vertical'>Vertical</option>
                            <option value='Timed'>Timed</option>
                          </select>
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Team Scoring</label>
                          <select
                            class='form-control'
                            id='exampleFormControlSelect1'
                            value={this.state.teamdata}
                            onChange={this.dropdownchange1}
                            required
                          >
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                          </select>
                        </div>

                        {this.state.btncheck == true ? (
                          <center>
                            <button
                              type='button'
                              onClick={(e) => this.createairgunset()}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Create
                            </button>
                          </center>
                        ) : (
                          <>
                            <center>
                              <button type='button' class='btn btn-danger' onClick={(e) => this.updateairgunset()}>
                                Update
                              </button>
                            </center>
                          </>
                        )}
                        {/* <center><button type="submit" onClick={this.updateairgunset} data-dismiss="modal" class="btn btn-danger">Update</button></center> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class='modal fade' id='newmeet' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        {' '}
                        {this.state.othereventsdata + ' ' + 'Location Set'}
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='inputAddress'>Venue Name</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='venuename'
                            value={this.state.venuedata}
                            onChange={this.optionchange1}
                            placeholder='Enter venue name'
                            disabled='disabled'
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Venue location</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='venuelocation'
                            value={this.state.venuelocationdata}
                            onChange={this.optionchange2}
                            placeholder='Enter Venue location'
                            required
                          />
                        </div>

                        {/* <div class="form-group">
                          <label for="inputAddress">Number of events</label>
                          <input
                            type="text"
                            class="form-control"
                            id="inputAddress"
                            name="numevents"
                            value={this.state.numberdata}
                            onChange={this.optionchange3}
                            placeholder="Enter Number of events"
                            disabled="disabled"
                            required
                          />
                        </div> */}

                        <div class='form-group'>
                          <label for='inputAddress'>Current Record Level</label>
                          <select
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='currecord'
                            value={this.state.currentdata}
                            onChange={this.optionchange4}
                            placeholder='Enter current record'
                            required
                          >
                            <option>Please Select</option>
                            <option value='National'>National</option>
                            <option value='American IPC'>American IPC</option>
                            <option value='Americas IPC'>Americas IPC</option>
                            <option value='NJDC'>NJDC</option>
                          </select>
                        </div>

                        {this.state.btncheck1 == true ? (
                          <center>
                            <button
                              type='button'
                              onClick={(e) => this.createairgunlocationset()}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Create
                            </button>
                          </center>
                        ) : (
                          <>
                            <center>
                              <button
                                type='button'
                                class='btn btn-danger'
                                onClick={(e) => this.updateairgunlocationset()}
                              >
                                Update
                              </button>
                            </center>
                          </>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div
                class="modal fade"
                id="newaddmeet"
                tabindex="-1"
                role="dialog"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header ">
                      <h5 class="modal-title " id="loginmodalLabel">
                        {" "}
                        New Event
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <form>

                        <div class="form-group">
                          <label for="inputAddress">Event Name</label>
                          <input
                            type="text"
                            class="form-control"
                            id="inputAddress"
                            name="eventname"
                            onChange={(e) =>
                              this.setState({ Otherseteventname: e.target.value })
                            }
                            placeholder="Enter New Event"
                            required
                          />
                        </div>

                        <div class="form-group">
                          <label for="inputAddress">Type of Event</label>
                          <select
                            class="form-control"
                            id="exampleFormControlSelect1"
                            value={this.state.typedata}
                            onChange={this.dropdownchange2}
                            required
                          >
                            <option value="Horizontal">Horizontal</option>
                            <option value="Vertical">Vertical</option>
                            <option value="Timed">Timed</option>
                          </select>
                        </div>

                        <div class="form-group">
                          <label for="inputAddress">Team Scoring</label>
                          <select
                            class="form-control"
                            id="exampleFormControlSelect1"
                            value={this.state.teamdata}
                            onChange={this.dropdownchange1}
                            required
                          >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>

                          <center>
                            <button
                              type="button"
                              onClick={(e) => this.createairgunset()}
                              data-dismiss="modal"
                              class="btn btn-danger"
                            >
                              Create
                            </button>
                          </center>

                      </form>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </>
        )}
      </>
    );
  }
}

export default Otherset;
