import React from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';

import Swal from 'sweetalert2';
//import Agereload from './Agedivisionload.js'

import { Select, Input } from 'antd';
import 'antd/dist/antd.css';
import { Empty } from 'antd';
import { withRouter } from 'react-router-dom';
const url = window.globalConfig;
var x;

class Regiswim extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      venues: [{}],
      venueid: [],
      tname: [],
      swimeventdata: [],
      swimsdata: [],
      swimevents: [],
      swimdatas: [{}],
      relay_points: '',
      qualifying_standard: '',
      qualifying_time: '',
      points: '',
      gender1: '',
      realy_team_name: '',
      relay_letter: '',
      relay_points: '',
      swimeventsdata: '',
      swimeventdata: [],
    };
    this.check = this.check.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.edit = this.edit.bind(this);
    this.tinput = this.tinput.bind(this);
    // this.save = this.save.bind(this);
    this.createswimreg = this.createswimreg.bind(this);
    this.updateswimreg = this.updateswimreg.bind(this);
  }
  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    let itemindex = sessionStorage.getItem('itemindex');
    var self = this;

    let indx = sessionStorage.getItem('swimindex');
    let ids = sessionStorage.getItem('ids');
    const swimeventdata = (index) => {
      if (index.swim_events != 'null') {
        var joined = this.state.swimeventdata.concat(index);
        this.setState({
          swimeventdata: joined,
        });
      }
    };
    //Get Particular user register meet data
    axios.get(`${url.url}/api/getcreateregisters/${ids}`, {}).then(function (response) {
      self.setState({
        allregdatas: response.data,
      });
      {
        response.data.map((index) => swimeventdata(index));
      }
      var valdata = self.state.swimeventdata[parseInt(indx)].swim_events.split(',');
      self.setState({
        swimsdata: self.state.swimeventdata[parseInt(indx)],
        swimevents: valdata,
      });
    });

    let regid = sessionStorage.getItem('regid');
    axios
      .get(`${url.url}/api/getsregisterid/${regid}`, {})
      .then(function (response) {
        self.setState({
          swimdatas: response.data,
        });
      })
      .catch(function (error) {});

    //Hide and show manager
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('resultp').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    document.getElementById('venueesss').style.display = 'block';
  }
  check = (e) => {
    var temp = [];
    if (Object.keys(this.state.tname).length != 0) {
      temp = this.state.tname;
    }
    if (!this.state.tname.some((item) => e.target.value === item.name)) {
      temp.push({ name: e.target.value, id: e.target.id });
    }
    this.setState({ tname: temp });
  };
  handlechange() {
    window.location.replace('/swimregister');
  }

  tinput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  edit(e) {
    var self = this;
    self.setState({
      swimeventsdata: self.state.swimevents[e],
    });
    var loop = true;

    self.state.swimdatas.forEach((question, index) => {
      if (question.selected_events == self.state.swimevents[e]) {
        self.setState({
          selected_id: question.id,
          selected_events: question.selected_events,
          qualifying_time: question.qualifying_time,
          qualifying_standard: question.qualifying_standard,
          points: question.points,
          gender1: question.gender1,
          realy_team_name: question.realy_team_name,
          relay_letter: question.relay_letter,
          relay_points: question.relay_points,
          btncheck: false,
        });
        loop = false;
      } else if (loop) {
        self.setState({
          selected_id: '',
          selectedevents: self.state.swimevents[e],
          qualifying_time: '',
          qualifying_standard: '',
          points: '',
          gender1: '',
          relay_team_name: '',
          relay_letter: '',
          relay_points: '',
          btncheck: true,
        });
        loop = true;
      }
    });
    console.log(this.state.selected_id);

    if (self.state.swimdatas.length == 0) {
      self.setState({
        selected_id: '',
        selectedevents: self.state.swimevents[e],
        qualifying_time: '',
        qualifying_standard: '',
        points: '',
        gender1: '',
        relay_team_name: '',
        relay_letter: '',
        relay_points: '',
        btncheck: true,
      });
    }
  }
  createswimreg(e) {
    var self = this;
    const setvalue = (q, index) => {
      if (self.state.swimdatas.length > index) {
        self.setState({
          selected_events: self.state.swimdatas[index].selected_events,
          qualifying_time: self.state.swimdatas[index].qualifying_time,
          qualifying_standard: self.state.swimdatas[index].qualifying_standard,
          points: self.state.swimdatas[index].points,
          gender1: self.state.swimdatas[index].gender1,
          realy_team_name: self.state.swimdatas[index].realy_team_name,
          relay_letter: self.state.swimdatas[index].relay_letter,
          relay_points: self.state.swimdatas[index].relay_points,
        });
      }
    };
    self.state.swimevents.map((question, index) => {
      setvalue(question, index);
    });
    const datatosend = {
      first_name: this.state.swimsdata.firstname,
      last_name: this.state.swimsdata.lastname,
      age_division: this.state.swimsdata.swim_age,
      gender: this.state.swimsdata.gender,
      selected_events: this.state.selectedevents,
      qualifying_time: this.state.qualifying_time,
      qualifying_standard: this.state.qualifying_standard,
      points: this.state.points,
      breaststroke: this.state.swimsdata.swim_breast_value ? this.state.swimsdata.swim_breast_value : 'null',
      medley: this.state.swimsdata.swim_medley_value ? this.state.swimsdata.swim_medley_value : 'null',
      gender1: this.state.gender1,
      realy_team_name: this.state.realy_team_name,
      relay_letter: this.state.relay_letter,
      created_by: 1,
      swim_class_value: this.state.swimsdata.swim_class_value,
      relay_points: this.state.relay_points,
      register_id: parseInt(sessionStorage.getItem('regid')),
    };
    axios
      .post(`${url.url}/api/createswimreg/`, datatosend, {})
      .then(function (response) {
        if (response.status == 201) {
          Swal.fire({
            title: 'Success',
            text: 'Status updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  updateswimreg(e) {
    var self = this;

    const datatosend = {
      first_name: this.state.swimsdata.firstname,
      last_name: this.state.swimsdata.lastname,
      age_division: this.state.swimsdata.swim_age,
      gender: this.state.swimsdata.gender,
      selected_events: this.state.selected_events,
      qualifying_time: this.state.qualifying_time,
      qualifying_standard: this.state.qualifying_standard,
      points: this.state.points,
      breaststroke: this.state.swimsdata.swim_breast_value ? this.state.swimsdata.swim_breast_value : 'null',
      medley: this.state.swimsdata.swim_medley_value ? this.state.swimsdata.swim_medley_value : 'null',
      gender1: this.state.gender1,
      realy_team_name: this.state.realy_team_name,
      relay_letter: this.state.relay_letter,
      created_by: 1,
      swim_class_value: this.state.swimsdata.swim_class_value,
      relay_points: this.state.relay_points,
      created_by: 1,
    };
    axios
      .put(`${url.url}/api/updateswimreg/${this.state.selected_id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            title: 'Success',
            text: 'Status updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  render() {
    const { selectedItems } = this.state;

    return (
      <>
        <div className='content'>
          <Row>
            <Col md='8'>
              <button
                type='button'
                data-toggle='modal'
                data-target='#selectswim'
                class='btn btn-danger btn-round'
                onClick={this.handlechange}
              >
                Back
              </button>
              <Card>
                <CardHeader>
                  <h5 className='title'>Swim Events</h5>
                </CardHeader>

                <CardBody>
                  <Form>
                    <div class='form-group row'>
                      <div class='col-xs-5'>
                        <CardHeader id='deatils_heading'>
                          <p>
                            First Name:<span>{this.state.swimsdata.firstname}</span>
                          </p>
                          <p>
                            Last Name:<span>{this.state.swimsdata.lastname}</span>
                          </p>
                          <p>
                            Swim Class's
                            <p>
                              Swim Class:<span>{this.state.swimsdata.swim_class_value}</span>
                            </p>
                            <p>
                              Breaststroke:
                              <span>
                                {this.state.swimsdata.swim_breast_value
                                  ? this.state.swimsdata.swim_breast_value
                                  : 'null'}
                              </span>
                            </p>
                            <p>Medley</p>
                            <span>
                              {this.state.swimsdata.swim_medley_value ? this.state.swimsdata.swim_medley_value : 'null'}
                            </span>
                          </p>
                          <p>
                            Age Division:<span>{this.state.swimsdata.swim_age}</span>
                          </p>
                          <p>
                            Gender:<span>{this.state.swimsdata.gender}</span>
                          </p>
                        </CardHeader>
                      </div>
                    </div>
                    {Object.keys(this.state.swimsdata).length == 0 ? (
                      <Empty />
                    ) : (
                      <>
                        <Table id='my' responsive>
                          <thead>
                            <tr>
                              <th>Athletes Events</th>
                              <th>Qualifying Time</th>
                              <th>Qualifying Standard</th>
                              <th>Points</th>
                              <th>Event Gender</th>
                              <th>Relay Team Name</th>
                              <th>Relay Letter</th>
                              <th>Relay Points</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody id='myTable'>
                            {this.state.swimevents.map((question, index) => (
                              <tr>
                                <td>{question}</td>
                                {/* <td><input class="input detail_txt" id={"qualifying_time"+index} disabled={this.state.editview} onChange={e => this.setState({ qualifying_time: e.target.value })}  name="quatime"/></td>
       <td><input class="input detail_txt" id={"qualifying_standard"+index} disabled={this.state.editview} onChange={e => this.setState({ qualifying_standard: e.target.value })}  name="quastd"/></td>
       <td><input class="input detail_txt" id={"points"+index} disabled={this.state.editview} onChange={e => this.setState({ points: e.target.value })}  name="points"/></td>
       <td><input class="input detail_txt" id={"gender1"+index} disabled={this.state.editview} onChange={e => this.setState({ gender1: e.target.value })}  name="evtgender"/></td>
       <td><input class="input detail_txt" id={"realy_team_name"+index} disabled={this.state.editview} onChange={e => this.setState({ realy_team_name: e.target.value })}  name="relayteam"/></td>
       <td><input class="input detail_txt" id={"relay_letter"+index} disabled={this.state.editview} onChange={e => this.setState({ relay_letter: e.target.value })}  name="relayletter"/></td>
       <td><input class="input detail_txt" id={"relay_points"+index} disabled={this.state.editview} onChange={e => this.setState({ relay_points: e.target.value })} name="relaypoint"/></td> */}

                                <td>
                                  {this.state.swimdatas.map((question1, index) =>
                                    question === question1.selected_events ? question1.qualifying_time : ''
                                  )}
                                </td>

                                <td>
                                  {this.state.swimdatas.map((question1, index) =>
                                    question === question1.selected_events ? question1.qualifying_standard : ''
                                  )}
                                </td>

                                <td>
                                  {this.state.swimdatas.map((question1, index) =>
                                    // console.log(question1.qualifying_time),
                                    question === question1.selected_events ? question1.points : ''
                                  )}
                                </td>

                                <td>
                                  {this.state.swimdatas.map((question1, index) =>
                                    // console.log(question1.qualifying_time),
                                    question === question1.selected_events ? question1.gender1 : ''
                                  )}
                                </td>

                                <td>
                                  {this.state.swimdatas.map((question1, index) =>
                                    // console.log(question1.qualifying_time),
                                    question === question1.selected_events ? question1.realy_team_name : ''
                                  )}
                                </td>

                                <td>
                                  {this.state.swimdatas.map((question1, index) =>
                                    // console.log(question1.qualifying_time),
                                    question === question1.selected_events ? question1.relay_letter : ''
                                  )}
                                </td>

                                <td>
                                  {this.state.swimdatas.map((question1, index) =>
                                    // console.log(question1.qualifying_time),
                                    question === question1.selected_events ? question1.relay_points : ''
                                  )}
                                </td>

                                <td>
                                  <button
                                    type='button'
                                    rel='tooltip'
                                    class='btn btn-success btn-sm btn-icon'
                                    onClick={() => this.edit(index)}
                                    data-toggle='modal'
                                    data-target='#useredit'
                                  >
                                    <i class='now-ui-icons ui-2_settings-90'></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
            {/* User table with edit delete------------------------------------------------------------------------------ */}
          </Row>

          <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
            <div class='modal-dialog'>
              <div class='modal-content'>
                <div class='modal-header '>
                  <h5 class='modal-title ' id='loginmodalLabel'>
                    {this.state.swimeventsdata + ' ' + 'Swimming'}{' '}
                  </h5>
                  <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <div class='modal-body'>
                  <form>
                    <div class='form-group'>
                      <label for='exampleFormControlSelect1'>Qualifying Marks</label>
                      <input
                        class='form-control'
                        value={this.state.qualifying_time}
                        onChange={(e) => this.setState({ qualifying_time: e.target.value })}
                        name='quatime'
                      />
                    </div>

                    <div class='form-row'>
                      <div class='form-group'>
                        <label for='inputEmail4'>Qualifying Standard</label>
                        <input
                          class='form-control'
                          value={this.state.qualifying_standard}
                          onChange={(e) => this.setState({ qualifying_standard: e.target.value })}
                          name='quastd'
                        />
                      </div>

                      <div class='form-group'>
                        <label for='inputPassword4'>Points</label>
                        <input
                          class='form-control'
                          value={this.state.points}
                          onChange={(e) => this.setState({ points: e.target.value })}
                          name='points'
                        />
                      </div>

                      <div class='form-group'>
                        <label for='inputPassword4'>Event Gender</label>
                        <input
                          class='form-control'
                          value={this.state.gender1}
                          onChange={(e) => this.setState({ gender1: e.target.value })}
                          name='gender1'
                        />
                      </div>

                      <div class='form-group'>
                        <label for='inputPassword4'>Relay Team Name</label>
                        <input
                          class='form-control'
                          value={this.state.realy_team_name}
                          onChange={(e) => this.setState({ realy_team_name: e.target.value })}
                          name='relayteam name'
                        />
                      </div>

                      <div class='form-group'>
                        <label for='inputPassword4'>Relay Letter</label>
                        <input
                          class='form-control'
                          value={this.state.relay_letter}
                          onChange={(e) => this.setState({ relay_letter: e.target.value })}
                          name='relaytletter'
                        />
                      </div>

                      <div class='form-group'>
                        <label for='inputPassword4'>Relay Points</label>
                        <input
                          class='form-control'
                          value={this.state.relay_points}
                          onChange={(e) => this.setState({ relay_points: e.target.value })}
                          name='rpoints'
                        />
                      </div>
                    </div>

                    {this.state.btncheck == true ? (
                      <center>
                        <button
                          type='button'
                          onClick={(e) => this.createswimreg()}
                          data-dismiss='modal'
                          class='btn btn-danger'
                        >
                          Create
                        </button>
                      </center>
                    ) : (
                      <>
                        <center>
                          <button type='button' class='btn btn-danger' onClick={(e) => this.updateswimreg()}>
                            Update
                          </button>
                        </center>
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Regiswim;
