import React from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';

import Swal from 'sweetalert2';

import { Select, Input } from 'antd';
import 'antd/dist/antd.css';

import { Empty } from 'antd';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
var x;

class Athletearc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      venues: [{}],
      venueid: [],
      tname: [],
      tnamesid: '',
      archerysets: [{}],
      archeryeventdata: [],
      archerysdata: [],
      archeryevents: [],
      arcdatas: [{}],
      qualifying_events: '',
      qualifying_standard: '',
      target: '',
      archeryeventsdata: '',
      allregdatas: [{}],
      allregdatass: [{}],
      classdata: [{}],
      qstddatas: {},
      loader: true,
      selectedevents: '',
      selected_events: '',
      groups: '',
      btncheck: true,
      typebow: '',
      bowstand: '',
    };
    this.check = this.check.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.edit = this.edit.bind(this);
    this.tinput = this.tinput.bind(this);
    this.createarcreg = this.createarcreg.bind(this);
    this.updatearcreg = this.updatearcreg.bind(this);
    this.editclassage = this.editclassage.bind(this);
    this.onchange1 = this.onchange1.bind(this);
    this.onchange2 = this.onchange2.bind(this);
    this.updateclassage = this.updateclassage.bind(this);
    this.deletearcreg = this.deletearcreg.bind(this);
  }
  async componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    let itemindex = sessionStorage.getItem('itemindex');
    let ids = sessionStorage.getItem('ids');
    var self = this;
    var count = 0;
    axios.get(`${url.url}/api/getarcmeetid/${ids}`, {}).then(function (response) {
      self.setState({
        archerysets: response.data,
      });
    });
    let indx = sessionStorage.getItem('archeryindex');
    const archeryeventdata = (index) => {
      if (index.archery_events != 'null') {
        var joined = this.state.archeryeventdata.concat(index);
        this.setState({
          archeryeventdata: joined,
          archerysdata: joined[0],
        });
      } else {
        self.setState({
          archerysdata: index,
        });
      }
    };
    let athleteid = sessionStorage.getItem('athleteid');
    await axios
      .get(`${url.url}/api/getathleteregisterid/${athleteid}`, {})
      .then(function (response) {
        self.setState({
          allregdatas: response.data,
        });
        {
          response.data.map((index) => archeryeventdata(index));
        }
        var valdata = self.state.archeryeventdata[0].archery_events.split(',');

        self.setState({
          archerysdata: self.state.archeryeventdata[0],
          archeryevents: valdata,
        });
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });

    let regid = sessionStorage.getItem('regid');
    axios
      .get(`${url.url}/api/getaregisterid/${athleteid}`, {})
      .then(function (response) {
        self.setState({
          arcdatas: response.data,
          arcdatas1: response.data[0],
        });
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      });

    let venid = sessionStorage.getItem('venuesid');
    axios
      .get(`${url.url}/api/getclassvalue/${venid}`, {})
      .then(function (response) {
        self.setState({
          classdata: response.data,
        });
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      });

    //Hide and show manager
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
  }
  check = (e) => {
    var temp = [];
    if (Object.keys(this.state.tname).length != 0) {
      temp = this.state.tname;
    }
    if (!this.state.tname.some((item) => e.target.value === item.name)) {
      temp.push({ name: e.target.value, id: e.target.id });
    }
    this.setState({ tname: temp, tnamesid: e.target.value });
    this.edit(e.target.id);
  };
  handlechange() {
    window.location.replace('/athletevenues');
  }

  tinput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  async edit(e) {
    var self = this;

    var qstd = {
      venue_name: 'Archery',
      event_name: this.state.archerysets[e].event_name,
      Class_values: this.state.allregdatas[0].archery_class_value,
      Age_division: this.state.allregdatas[0].archery_age,
      Gender: this.state.allregdatas[0].gender,
    };
    await axios
      .post(`${url.url}/api/getparticularqualstd`, qstd, {})
      .then(function (response) {
        self.setState({
          qstddatas: response.data.data,
        });
      })
      .catch((err) => {});
    self.setState({
      archeryeventsdata: self.state.archeryevents[e],
    });
    var loop = true;

    self.state.arcdatas.forEach((question, index) => {
      if (question.selected_events == self.state.archerysets[e].event_name) {
        self.setState({
          selected_id: question.id,
          selected_events: question.selected_events,
          qualifying_events: question.qualifying_events,
          qualifying_standard: question.qualifying_standard,
          target: question.target,
          groups: question.groups,
          btncheck: false,
        });
        loop = false;
      } else if (loop) {
        self.setState({
          selected_id: '',
          selectedevents: self.state.archerysets[e].event_name,
          qualifying_events: '',
          qualifying_standard: this.state.qstddatas[0] ? self.state.qstddatas[0].qualifying_standards : '',
          target: '',
          groups: '',
          btncheck: true,
        });
        loop = true;
      }
    });
    if (self.state.arcdatas.length == 0) {
      self.setState({
        selected_id: '',
        selectedevents: self.state.archerysets[e].event_name,
        qualifying_events: '',
        qualifying_standard: this.state.qstddatas[0] ? self.state.qstddatas[0].qualifying_standards : '',
        target: '',
        groups: '',
        btncheck: true,
      });
    }
  }

  editclassage() {
    var self = this;
    self.setState({
      archeryclass: self.state.allregdatas[0].archery_class_value,
      archeryage: self.state.allregdatas[0].archery_age,
      typebow: self.state.allregdatas[0].typebow,
      bowstand: self.state.allregdatas[0].bowstand,
    });
  }
  onchange1(e) {
    this.setState({ archeryclass: e.target.value });
  }
  onchange2(e) {
    this.setState({ archeryage: e.target.value });
  }
  updateclassage() {
    const data = {
      archery_age: this.state.archeryage,
      archery_class_value: this.state.archeryclass,
      typebow: this.state.typebow,
      bowstand: this.state.bowstand,
    };
    let athleteid = sessionStorage.getItem('athleteid');
    axios
      .put(`${url.url}/api/updateclassagevalue/${athleteid}`, data, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  createarcreg(e) {
    var self = this;
    const setvalue = (q, index) => {
      if (self.state.arcdatas.length > index) {
        self.setState({
          qualifying_events: self.state.arcdatas[index].qualifying_events,
          qualifying_standard: self.state.arcdatas[index].qualifying_standard,
          target: self.state.arcdatas[index].target,
        });
      }
    };

    self.state.arcdatas.map((question, index) => {
      setvalue(question, index);
    });

    const datatosend = {
      first_name: this.state.archerysdata.firstname,
      last_name: this.state.archerysdata.lastname,
      age_division: this.state.archerysdata.archery_age,
      gender: this.state.archerysdata.gender,
      selected_events: this.state.selectedevents,
      qualifying_events: this.state.qualifying_events,
      qualifying_standard: this.state.qualifying_standard,
      target: this.state.target,
      groups: this.state.groups,
      archery_class_value: this.state.archerysdata.archery_class_value,
      created_by: 1,
      register_id: parseInt(sessionStorage.getItem('athleteid')),
      meet_id: parseInt(sessionStorage.getItem('ids')),
      bib_no: this.state.allregdatas[0].bib_no,
      country: this.state.allregdatas[0].country,
      team_name: this.state.allregdatas[0].team_name,
      register_num: this.state.allregdatas[0].register_num,
      typebow: this.state.allregdatas[0].typebow,
      bowstand: this.state.allregdatas[0].bowstand,
    };
    axios
      .post(`${url.url}/api/createarcheryreg/`, datatosend, {})
      .then(function (response) {
        if (response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  updatearcreg(e) {
    var self = this;

    const datatosend = {
      first_name: this.state.archerysdata.firstname,
      last_name: this.state.archerysdata.lastname,
      age_division: this.state.archerysdata.archery_age,
      gender: this.state.archerysdata.gender,
      selected_events: this.state.selected_events,
      qualifying_events: this.state.qualifying_events,
      qualifying_standard: this.state.qualifying_standard,
      target: this.state.target,
      archery_class_value: this.state.archerysdata.archery_class_value,
      groups: this.state.groups,
      bib_no: this.state.allregdatas[0].bib_no,
    };
    axios
      .put(`${url.url}/api/updatearcheryreg/${this.state.selected_id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  deletearcreg(e) {
    const datatosend = {
      selected_events: '',
      qualifying_events: '',
      qualifying_standard: '',
      target: '',
      groups: '',
    };
    axios
      .delete(`${url.url}/api/deletearcreg/${this.state.selected_id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 202) {
          Swal.fire({
            icon: 'success',
            title: 'Status Deleted successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  render() {
    const { selectedItems } = this.state;

    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div className='content'>
              <Row>
                <Col md='8'>
                  <button
                    type='button'
                    data-toggle='modal'
                    data-target='#selecttrack'
                    class='btn btn-danger btn-round'
                    onClick={this.handlechange}
                  >
                    Back
                  </button>
                  <Card>
                    <CardHeader>
                      <h5 className='title'>Archery Events</h5>
                    </CardHeader>

                    <CardBody>
                      <Form>
                        <div class='form-group row'>
                          <div class='col-xs-5'>
                            <CardHeader className='athlete_archerytb' id='deatils_heading'>
                              <div>
                                <th>
                                  <tr>
                                    <div>
                                      <label>Registration no:</label>
                                      <span>{this.state.archerysdata.register_num}</span>
                                    </div>

                                    <div>
                                      <label>First Name:</label>
                                      <span>{this.state.archerysdata.firstname}</span>
                                    </div>

                                    <div>
                                      <label>Last Name:</label>
                                      <span>{this.state.archerysdata.lastname}</span>
                                    </div>
                                  </tr>
                                  <tr>
                                    <div>
                                      <label>Archery Class:</label>
                                      <span>
                                        {this.state.archerysdata.archery_class_value
                                          ? this.state.archerysdata.archery_class_value
                                          : 'n/a'}
                                      </span>
                                    </div>

                                    <div>
                                      <label>BIB no:</label>
                                      <span>{this.state.archerysdata.bib_no}</span>
                                    </div>

                                    <div>
                                      <label>Age Division:</label>
                                      <span>{this.state.archerysdata.archery_age}</span>
                                    </div>

                                    <div>
                                      Gender:
                                      <span>{this.state.archerysdata.gender}</span>
                                    </div>
                                  </tr>

                                  <tr>
                                    <div>
                                      Typebow:
                                      <span>{this.state.archerysdata.typebow}</span>
                                    </div>

                                    <div>
                                      Bowstand:
                                      <span>{this.state.archerysdata.bowstand}</span>
                                    </div>
                                  </tr>
                                </th>
                              </div>

                              <button
                                type='button'
                                rel='tooltip'
                                data-toggle='modal'
                                data-target='#meetedit'
                                class='btn btn-success btn-sm btn-icon'
                                onClick={this.editclassage}
                              >
                                <i class='now-ui-icons ui-2_settings-90'></i>
                              </button>
                            </CardHeader>
                          </div>
                        </div>
                        {Object.keys(this.state.archerysdata).length == 0 ? (
                          <Empty />
                        ) : (
                          <>
                            <Table id='my' responsive>
                              <thead>
                                <tr>
                                  <th>Athletes Events</th>
                                  <th></th>
                                  <th>Selected Events</th>
                                  <th>Qualifying Marks</th>
                                  <th>Qualifying Standard</th>
                                  <th>Target</th>
                                  <th>Group</th>
                                </tr>
                              </thead>
                              <tbody id='myTable'>
                                {this.state.archerysets.map((question, index) => (
                                  <tr>
                                    <td>
                                      <button
                                        type='button'
                                        class='btn btn-success btn-sm'
                                        data-toggle='modal'
                                        data-target='#useredit'
                                        id={index}
                                        value={question.event_name}
                                        onClick={(e) => this.check(e)}
                                      >
                                        {question.event_name}
                                      </button>
                                    </td>

                                    <td style={{ backgroundColor: '#ffff6a' }}></td>

                                    <td>
                                      {this.state.arcdatas.map((question1, index) =>
                                        question.event_name === question1.selected_events
                                          ? question1.selected_events
                                          : ''
                                      )}
                                    </td>

                                    <td>
                                      {this.state.arcdatas.map((question1, index) =>
                                        question.event_name === question1.selected_events
                                          ? question1.qualifying_events
                                          : ''
                                      )}
                                    </td>

                                    <td>
                                      {this.state.arcdatas.map((question1, index) =>
                                        question.event_name === question1.selected_events
                                          ? question1.qualifying_standard
                                          : ''
                                      )}
                                    </td>

                                    <td>
                                      {this.state.arcdatas.map((question1, index) =>
                                        question.event_name === question1.selected_events ? question1.target : ''
                                      )}
                                    </td>
                                    <td>
                                      {this.state.arcdatas.map((question1, index) =>
                                        question.event_name === question1.selected_events ? question1.groups : ''
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </>
                        )}
                        <p class='athletefootertxt'>
                          <span class='athletefootertxtstar'>*</span>Indicates a Possible Invalid or Non standard Event
                          for this Class, Age and Gender
                        </p>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                {/* User table with edit delete------------------------------------------------------------------------------ */}
              </Row>
              <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        {this.state.tnamesid + ' ' + 'Archery'}{' '}
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='exampleFormControlSelect1'>Qualifying Marks</label>
                          <input
                            class='form-control'
                            value={this.state.qualifying_events}
                            onChange={(e) =>
                              this.setState({
                                qualifying_events: e.target.value,
                              })
                            }
                            name='quatime'
                          />
                        </div>

                        <div class='form-row'>
                          <div class='form-group'>
                            <label for='inputEmail4'>Qualifying Standard</label>
                            <input
                              class='form-control'
                              value={this.state.qualifying_standard}
                              onChange={(e) =>
                                this.setState({
                                  qualifying_standard: e.target.value,
                                })
                              }
                              name='quastd'
                            />
                          </div>

                          <div class='form-group'>
                            <label for='inputPassword4'>Target</label>
                            <input
                              class='form-control'
                              value={this.state.target}
                              onChange={(e) => this.setState({ target: e.target.value })}
                              name='points'
                            />
                          </div>
                          <div class='form-group'>
                            <label for='inputPassword5'>Group</label>
                            <input
                              class='form-control'
                              value={this.state.groups}
                              onChange={(e) => this.setState({ groups: e.target.value })}
                              name='group'
                            />
                          </div>
                        </div>

                        {this.state.btncheck == true ? (
                          <center>
                            <button
                              type='button'
                              onClick={(e) => this.createarcreg()}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Create
                            </button>
                          </center>
                        ) : (
                          <>
                            <center>
                              <button type='button' class='btn btn-danger' onClick={(e) => this.updatearcreg()}>
                                Update
                              </button>
                              {` `}
                              <button
                                type='button'
                                class='btn btn-danger'
                                data-dismiss='modal'
                                onClick={(e) => this.deletearcreg()}
                              >
                                Delete
                              </button>
                            </center>
                          </>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class='modal fade' id='meetedit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Archery
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='exampleFormControlSelect1'>Archery Class</label>
                          <input
                            class='form-control'
                            value={this.state.archeryclass}
                            onChange={this.onchange1}
                            name='quastd'
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputEmail4'>Age Division</label>
                          <input
                            class='form-control'
                            value={this.state.archeryage}
                            onChange={this.onchange2}
                            name='quastd'
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputEmail4'>Typebow</label>
                          <select
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='currecord'
                            value={this.state.typebow}
                            onChange={(e) => this.setState({ typebow: e.target.value })}
                            required
                          >
                            <option>Please Select</option>
                            <option value='Compound'>Compound</option>
                            <option value='Recurve'>Recurve</option>
                          </select>
                        </div>

                        <div class='form-group'>
                          <label for='inputEmail4'>Bowstand</label>
                          <select
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='currecord'
                            value={this.state.bowstand}
                            onChange={(e) => this.setState({ bowstand: e.target.value })}
                            required
                          >
                            <option>Please Select</option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                          </select>
                        </div>
                        <center>
                          <button type='button' class='btn btn-danger' onClick={(e) => this.updateclassage()}>
                            Update
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
export default Athletearc;
