import React from 'react';

import '../App.css';
import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import { Checkbox, Empty } from 'antd';
import 'datatables.net';
import 'datatables.net-bs4';
import Loader from 'react-loader-spinner';
import e from 'cors';
var bestRecord;
var totalLiftKG;
const url = window.globalConfig;
const queryParams = new URLSearchParams(window.location.search);
class Weightliftbenchresult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      event: queryParams.get('event'),
      event_data: [{}],
      loader: true,
      sortdata: '',
      groupdata: '',
      lift1: '',
      lift2: '',
      lift3: '',
      recordlift: '',
      draw: '',
      place: '',
      ppclass: '',
      tgplace: '',
      performancedatas: [{}],
      setweight: '',
      lbsweight: '',
      checkcolor1: '',
      checkcolor2: '',
      checkcolor3: '',
      checkcolor4: '',
      barheight: '',
    };
    this.back = this.back.bind(this);
    this.edit = this.edit.bind(this);
    this.update = this.update.bind(this);
    // this.teampoints = this.teampoints.bind(this);
  }

  async componentDidMount() {
    var self = this;
    var count = 0;
    const data = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
      bench: queryParams.get('bench') || null,
      group: queryParams.get('group') || null,
      sortby: queryParams.get('sortby') || null,
    };
    await axios
      .post(`${url.url}/api/getregisterweightliftevent/`, data, {})
      .then(function (response) {
        self.setState({
          event_data: response.data,
          loader: false,
        });
        count++;
        if (count == 2) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });

    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    // document.getElementById("venueesss").style.display = "block";

    axios
      .get(`${url.url}/api/getmeetdetail/${sessionStorage.getItem('ids')}`, {})
      .then(function (response) {
        self.setState({
          meetdatas: response.data,
        });
        count++;
        if (count == 2) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {});

    //Performance Record Data
    const performancerecorddata = {
      Sport_Venue: 'Weightlifting',
      Event: this.state.event + 'ing',
      Classification: this.state.event_data[0].weight_class_value,
      Age_Group: this.state.event_data[0].age_division,
      Gender: this.state.event_data[0].gender,
    };
    await axios
      .post(`${url.url}/api/getperformancerecord/`, performancerecorddata, {})
      .then(function (response) {
        self.setState({ performancedatas: response.data.data[0] });
      })
      .catch(function (error) {});
  }
  back() {
    window.location.replace('/venuemainpage?event=' + sessionStorage.getItem('event'));
  }

  edit(e) {
    var self = this;
    self.setState({
      eventdatas: this.state.event_data[e],
      sortdata: this.state.event_data[e].sort,
      lift1: this.state.event_data[e].lift1 ? this.state.event_data[e].lift1 : '',
      lift2: this.state.event_data[e].lift2 ? this.state.event_data[e].lift2 : '',
      lift3: this.state.event_data[e].lift3 ? this.state.event_data[e].lift3 : '',
      recordlift: this.state.event_data[e].recordlift ? this.state.event_data[e].recordlift : '',
      draw: this.state.event_data[e].draw,
      groupdata: this.state.event_data[e].group,
      ppclass: this.state.event_data[e].weight_class_value ? this.state.event_data[e].weight_class_value : '',
      place: this.state.event_data[e].final_place ? this.state.event_data[e].final_place : '',
      tgplace: this.state.event_data[e].tgplace ? this.state.event_data[e].tgplace : '',
      setweight: this.state.event_data[e].set_weight_lbs ? this.state.event_data[e].set_weight_lbs : '',
      barheight: this.state.event_data[e].barheight ? this.state.event_data[e].barheight : '',
      checkcolor1: this.state.event_data[e].check_color1 ? this.state.event_data[e].check_color1 : '',
      checkcolor2: this.state.event_data[e].check_color2 ? this.state.event_data[e].check_color2 : '',
      checkcolor3: this.state.event_data[e].check_color3 ? this.state.event_data[e].check_color3 : '',
      checkcolor4: this.state.event_data[e].check_color4 ? this.state.event_data[e].check_color4 : '',
    });

    if (this.state.event_data[e].check_color1 === 'good') {
      $('#good').prop('checked', true);
      $('#fail').prop('checked', false);
    } else if (this.state.event_data[e].check_color1 === 'failed') {
      $('#good').prop('checked', false);
      $('#fail').prop('checked', true);
    } else if (this.state.event_data[e].check_color1 === null) {
      $('#good').prop('checked', false);
      $('#fail').prop('checked', false);
    }

    if (this.state.event_data[e].check_color2 === 'good') {
      $('#good1').prop('checked', true);
      $('#fail1').prop('checked', false);
    } else if (this.state.event_data[e].check_color2 === 'failed') {
      $('#good1').prop('checked', false);
      $('#fail1').prop('checked', true);
    } else if (this.state.event_data[e].check_color2 === null) {
      $('#good1').prop('checked', false);
      $('#fail1').prop('checked', false);
    }
    if (this.state.event_data[e].check_color3 === 'good') {
      $('#good2').prop('checked', true);
      $('#fail2').prop('checked', false);
    } else if (this.state.event_data[e].check_color3 === 'failed') {
      $('#good2').prop('checked', false);
      $('#fail2').prop('checked', true);
    } else if (this.state.event_data[e].check_color3 === null) {
      $('#good2').prop('checked', false);
      $('#fail2').prop('checked', false);
    }
    if (this.state.event_data[e].check_color4 === 'good') {
      $('#good4').prop('checked', true);
      $('#fail4').prop('checked', false);
    } else if (this.state.event_data[e].check_color4 === 'failed') {
      $('#good4').prop('checked', false);
      $('#fail4').prop('checked', true);
    } else if (this.state.event_data[e].check_color4 === null) {
      $('#good4').prop('checked', false);
      $('#fail4').prop('checked', false);
    }
  }
  clear = () => {
    axios
      .delete(`${url.url}/api/deleteliftsandplace/${this.state.eventdatas.id}`, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Deleted successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  };

  update(e) {
    const datatosend = {
      sort: this.state.sortdata,
      lift1: this.state.lift1,
      lift2: this.state.lift2,
      lift3: this.state.lift3,
      recordlift: this.state.recordlift,
      draw: this.state.draw,
      group: this.state.groupdata,
      weight_class_value: this.state.ppclass,
      final_place: this.state.place,
      tgplace: this.state.tgplace,
      check_color1: this.state.checkcolor1,
      check_color2: this.state.checkcolor2,
      check_color3: this.state.checkcolor3,
      check_color4: this.state.checkcolor4,
      set_weight_lbs: this.state.setweight,
      barheight: this.state.barheight,
    };
    axios
      .put(`${url.url}/api/updatebenchresult/${this.state.eventdatas.id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  checkBox = (e) => {
    var checkboxes = document.getElementsByName(e.target.name);
    checkboxes.forEach((item) => {
      if (item !== e.target) item.checked = false;
    });
  };
  checkValue = (e) => {
    if (e.target.checked) {
      this.setState({
        checkcolor1: e.target.value,
      });
    } else {
      this.setState({
        checkcolor1: '',
      });
    }
  };
  checkValue2 = (e) => {
    if (e.target.checked) {
      this.setState({
        checkcolor2: e.target.value,
      });
    } else {
      this.setState({
        checkcolor2: '',
      });
    }
  };
  checkValue3 = (e) => {
    if (e.target.checked) {
      this.setState({
        checkcolor3: e.target.value,
      });
    } else {
      this.setState({
        checkcolor3: '',
      });
    }
  };

  checkValue4 = (e) => {
    if (e.target.checked) {
      this.setState({
        checkcolor4: e.target.value,
      });
    } else {
      this.setState({
        checkcolor4: '',
      });
    }
  };
  // liftAddition = () => {
  //   return Object.values(userWalletIncomes).reduce((total, value) => total + value, 0)
  // }
  handlecolor = (e) => {
    var x = 0;
    for (let i = 1; i <= 3; i++) {
      if (e['check_color' + `${i}`] === 'good') {
        x += parseInt(e['lift' + `${i}`]);
      }
    }
    x += e.check_color4 === 'good' ? parseInt(e.recordlift) : '';

    return x === 0 ? null : x;
  };

  render() {
    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader
                type='ThreeDots'
                color='#00BFFF'
                height={70}
                width={70}
                // timeout={3000} //3 secs
              />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div>
              <div>
                <h1> </h1>
                <Row>
                  <Col>
                    <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                      Back
                    </button>
                    <Card className='scrollcard'>
                      <CardHeader>
                        <h5 className='title'>
                          {this.state.event} <br></br> Results Entry By Bench
                        </h5>
                      </CardHeader>
                      <CardBody>
                        <Form>
                          {Object.keys(this.state.event_data).length == 0 ? (
                            <Empty />
                          ) : (
                            <>
                              <table class='worksheet_table'>
                                <thead>
                                  <tr class='worksheet_tr'>
                                    {/* <th>sort</th> */}
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Country</th>
                                    <th>Age</th>
                                    <th>Weighin KG</th>
                                    <th>Class</th>
                                    <th>Bar Height</th>
                                    <th>Lift1</th>
                                    <th>Lift2</th>
                                    <th>Lift3</th>
                                    <th>Record lift</th>
                                    <th>Best Lift KG</th>
                                    <th>Lifts 1-3 Place</th>
                                    <th>Total Lift KG</th>
                                    <th>Total Lift Place</th>
                                    <th>Best Lift Rec KG</th>
                                    <th>Break BL Rec</th>
                                    <th>Total Lift Rec KG</th>
                                    <th>Break TL Rec</th>
                                    <th>Gender</th>
                                    <th>Bench</th>
                                    <th>Groups</th>
                                    {/* <th>Draw</th> */}
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.event_data.map(
                                    (question, index) => (
                                      (bestRecord =
                                        question.check_color1 === 'failed' &&
                                        question.check_color2 === 'failed' &&
                                        question.check_color3 === 'failed'
                                          ? 0
                                          : question.check_color3 == 'good'
                                          ? question.lift3
                                          : question.check_color2 == 'good'
                                          ? question.lift2
                                          : question.lift1),
                                      (totalLiftKG =
                                        question.check_color1 === 'failed' &&
                                        question.check_color2 === 'failed' &&
                                        question.check_color3 === 'failed'
                                          ? 0
                                          : this.handlecolor(question)),
                                      (
                                        <tr class='worksheet_tr'>
                                          {/* <td>{question.sort}</td> */}
                                          <td>{question.first_name}</td>
                                          <td>{question.last_name}</td>
                                          <td>{question.country}</td>
                                          <td>{question.age_division}</td>
                                          <td>{question.set_weight_lbs}</td>
                                          <td>{question.weight_class_value}</td>
                                          <td>{question.barheight}</td>
                                          {}
                                          <td
                                            class={
                                              question.check_color1 == 'good'
                                                ? 'clrgreen'
                                                : question.check_color1 == null
                                                ? 'clrwhite'
                                                : 'clrred'
                                            }
                                          >
                                            {question.lift1}
                                          </td>
                                          <td
                                            class={
                                              question.check_color2 == 'good'
                                                ? 'clrgreen'
                                                : question.check_color2 == null
                                                ? 'clrwhite'
                                                : 'clrred'
                                            }
                                          >
                                            {question.lift2}
                                          </td>
                                          <td
                                            class={
                                              question.check_color3 == 'good'
                                                ? 'clrgreen'
                                                : question.check_color3 == null
                                                ? 'clrwhite'
                                                : 'clrred'
                                            }
                                          >
                                            {question.lift3}
                                          </td>
                                          <td
                                            class={
                                              question.check_color4 == 'good'
                                                ? 'clrgreen'
                                                : question.check_color4 == null
                                                ? 'clrwhite'
                                                : 'clrred'
                                            }
                                          >
                                            {question.recordlift}
                                          </td>
                                          <td>
                                            {question.check_color1 === 'failed' &&
                                            question.check_color2 === 'failed' &&
                                            question.check_color3 === 'failed'
                                              ? 0
                                              : question.check_color3 == 'good'
                                              ? question.lift3
                                              : question.check_color2 == 'good'
                                              ? question.lift2
                                              : question.lift1}
                                          </td>
                                          <td>{question.final_place}</td>
                                          <td>{totalLiftKG}</td>
                                          <td>{question.tgplace}</td>
                                          <td>
                                            {question.best_lift_record && question.best_lift_record.replace('kg', '')}
                                          </td>
                                          <td>
                                            {Number(bestRecord) >=
                                            (question.best_lift_record &&
                                              Number(question.best_lift_record.replace('kg', '')))
                                              ? !question.total_lift_record
                                                ? 'NRF'
                                                : 'Yes'
                                              : 'No'}
                                          </td>
                                          <td>
                                            {question.total_lift_record && question.total_lift_record.replace('kg', '')}
                                          </td>
                                          <td>
                                            {Number(totalLiftKG) >=
                                            (question.total_lift_record &&
                                              Number(question.total_lift_record.replace('kg', '')))
                                              ? !question.total_lift_record
                                                ? 'NRF'
                                                : 'Yes'
                                              : 'No'}
                                          </td>
                                          <td>{question.gender}</td>
                                          <td>{question.bench}</td>
                                          <td>{question.group}</td>
                                          {/* <td>{question.draw}</td> */}
                                          <td>
                                            <button
                                              type='button'
                                              rel='tooltip'
                                              class='btn btn-success btn-sm btn-icon'
                                              onClick={() => this.edit(index)}
                                              data-toggle='modal'
                                              data-target='#useredit'
                                            >
                                              <i class='now-ui-icons ui-2_settings-90'></i>
                                            </button>
                                          </td>
                                        </tr>
                                      )
                                    )
                                  )}
                                </tbody>
                              </table>
                            </>
                          )}
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
              <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header'>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Results Entry By Bench
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div style={{ display: 'flex', justifyContent: 'center' }} class='form-row'>
                          <div class='form-group col-md-4'>
                            <label for='inputCity'>Lift1</label>
                            <input
                              type='checkbox'
                              id='good'
                              name='chklift1'
                              value='good'
                              onChange={(e) => this.checkBox(e)}
                              onClick={this.checkValue}
                            />
                            &nbsp;Good &nbsp;
                            <input
                              type='checkbox'
                              id='fail'
                              name='chklift1'
                              value='failed'
                              onChange={(e) => this.checkBox(e)}
                              onClick={this.checkValue}
                            />
                            &nbsp;Failed
                            <input
                              class='form-control'
                              value={this.state.lift1}
                              onChange={(e) => this.setState({ lift1: e.target.value })}
                              name='relayletter'
                            />
                          </div>
                          <div class='form-group col-md-4'>
                            <label for='inputCity'>Lift2</label>
                            <input
                              type='checkbox'
                              id='good1'
                              name='chklift2'
                              value='good'
                              onChange={(e) => this.checkBox(e)}
                              onClick={this.checkValue2}
                            />
                            &nbsp;Good &nbsp;
                            <input
                              type='checkbox'
                              id='fail1'
                              name='chklift2'
                              value='failed'
                              onChange={(e) => this.checkBox(e)}
                              onClick={this.checkValue2}
                            />
                            &nbsp;Failed
                            <input
                              class='form-control'
                              value={this.state.lift2}
                              onChange={(e) => this.setState({ lift2: e.target.value })}
                              name='relayletter'
                            />
                          </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center' }} class='form-row'>
                          <div class='form-group col-md-4'>
                            <label for='inputCity'>Lift3</label>
                            <input
                              type='checkbox'
                              id='good2'
                              name='chklift3'
                              value='good'
                              onChange={(e) => this.checkBox(e)}
                              onClick={this.checkValue3}
                            />
                            &nbsp;Good &nbsp;
                            <input
                              type='checkbox'
                              id='fail2'
                              name='chklift3'
                              value='failed'
                              onChange={(e) => this.checkBox(e)}
                              onClick={this.checkValue3}
                            />
                            &nbsp;Failed
                            <input
                              class='form-control'
                              value={this.state.lift3}
                              onChange={(e) => this.setState({ lift3: e.target.value })}
                              name='relayletter'
                            />
                          </div>
                          <div class='form-group col-md-4'>
                            <label for='inputCity'>RecordLift</label>
                            <input
                              type='checkbox'
                              id='good4'
                              name='chklift4'
                              value='good'
                              onChange={(e) => this.checkBox(e)}
                              onClick={this.checkValue4}
                            />
                            &nbsp;Good &nbsp;
                            <input
                              type='checkbox'
                              id='fail4'
                              name='chklift4'
                              value='failed'
                              onChange={(e) => this.checkBox(e)}
                              onClick={this.checkValue4}
                            />
                            &nbsp;Failed
                            <input
                              class='form-control'
                              value={this.state.recordlift}
                              onChange={(e) => this.setState({ recordlift: e.target.value })}
                              name='relayletter'
                            />
                          </div>
                        </div>

                        <div class='form-row'>
                          <div class='form-group col-md-4'>
                            <label for='inputCity'>Class</label>
                            <input
                              class='form-control'
                              value={this.state.ppclass}
                              onChange={(e) => this.setState({ ppclass: e.target.value })}
                              name='ppclass'
                            />
                          </div>
                          <div class='form-group col-md-4'>
                            <label for='inputCity'>Lifts 1-3 Place</label>
                            <input
                              class='form-control'
                              value={this.state.place}
                              onChange={(e) => this.setState({ place: e.target.value })}
                              name='relayletter'
                            />
                          </div>

                          <div class='form-group col-md-4'>
                            <label for='inputCity'>Weighin KG</label>
                            <input
                              class='form-control'
                              value={this.state.setweight}
                              onChange={(e) => this.setState({ setweight: e.target.value })}
                              name='relayletter'
                            />
                          </div>

                          <div class='form-group col-md-4'>
                            <label for='inputCity'>Barheight</label>
                            <input
                              class='form-control'
                              value={this.state.barheight}
                              onChange={(e) => this.setState({ barheight: e.target.value })}
                              name='relayletter'
                            />
                          </div>
                          <div class='form-group col-md-4'>
                            <label for='inputCity'>Total Lift Place</label>
                            <input
                              class='form-control'
                              value={this.state.tgplace}
                              onChange={(e) => this.setState({ tgplace: e.target.value })}
                              name='relayletter'
                            />
                          </div>
                        </div>

                        <center>
                          <button type='button' class='btn btn-danger' onClick={(e) => this.update()}>
                            Update
                          </button>
                          <button type='button' class='btn btn-danger' onClick={(e) => this.clear()}>
                            Clear
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Weightliftbenchresult;
