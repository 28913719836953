import React from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';

import Swal from 'sweetalert2';
//import Agereload from './Agedivisionload.js'

import { Select, Input } from 'antd';
import 'antd/dist/antd.css';
import { Empty } from 'antd';
import { withRouter } from 'react-router-dom';
import Regiswim from './Regiswim';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
var x;
const url = window.globalConfig;
class Regswim extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      venues: [{}],
      venueid: [],
      tname: [],
      swimeventdata: [],
      swimtable: false,
      editswim: [{}],
    };
    this.check = this.check.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.editswim = this.editswim.bind(this);
  }
  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    let itemindex = sessionStorage.getItem('itemindex');
    var self = this;

    let ids = sessionStorage.getItem('ids');
    const swimeventdata = (index) => {
      if (index.swim_events != 'null') {
        var joined = this.state.swimeventdata.concat(index);
        this.setState({
          swimeventdata: joined,
        });
      }
    };
    //Get Particular user register meet data
    axios.get(`${url.url}/api/getcreateregisters/${ids}`, {}).then(function (response) {
      self.setState({
        allregdatas: response.data.data,
      });
      {
        response.data.data.map((index) => swimeventdata(index));
      }
    });

    var hidenav = document.getElementById('eventset');
    hidenav.style.display = 'block';

    //Hide and show manager
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('resultp').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    document.getElementById('venueesss').style.display = 'block';
  }
  check = (e) => {
    var temp = [];
    if (Object.keys(this.state.tname).length != 0) {
      temp = this.state.tname;
    }
    if (!this.state.tname.some((item) => e.target.value === item.name)) {
      temp.push({ name: e.target.value, id: e.target.id });
    }
    this.setState({ tname: temp });
  };
  handlechange() {
    window.location.replace('/managevenues');
  }
  editswim(e) {
    sessionStorage.setItem('swimindex', e);
    sessionStorage.setItem('regid', this.state.swimeventdata[e].id);
    this.setState({ swimtable: true, editswim: this.state.swimeventdata[e] });
    this.setState({
      swimtable: true,
    });
  }
  render() {
    const { selectedItems } = this.state;
    if (this.state.swimtable) {
      return <Redirect to='/swimeventregister' />;
    }
    return (
      <>
        <div className='content'>
          <Row>
            <Col md='8'>
              <button
                type='button'
                data-toggle='modal'
                data-target='#selectswim'
                class='btn btn-danger btn-round'
                onClick={this.handlechange}
              >
                Back
              </button>
              <Card>
                <CardHeader>
                  <h5 className='title'>Swimming Events</h5>
                </CardHeader>

                <CardBody>
                  <Form>
                    <div class='form-group row'>
                      <div class='col-xs-5'>
                        {/* <CardHeader>
        <p>Search</p>
        <input class="form-control" id="inputsm" placeholder="Search.." type="text"/>
        </CardHeader> */}
                      </div>
                    </div>
                    {Object.keys(this.state.swimeventdata).length == 0 ? (
                      <Empty />
                    ) : (
                      <>
                        <Table id='my' responsive>
                          <thead>
                            <tr>
                              <th className='text-center'>#</th>
                              <th>First Name</th>
                              <th>Last Name</th>
                              {/* <th>BIB Number</th> */}
                              <th>Venue Class</th>
                              <th>Age Division</th>
                              <th>Gender</th>
                              <th>
                                Swimming Class's<th>Swim Class</th>
                                <th>Breaststroke</th>
                                <th>Medley</th>
                              </th>
                              <th>Athletes Events</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody id='myTable'>
                            {this.state.swimeventdata.map((question, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{question.firstname}</td>
                                <td>{question.lastname}</td>
                                <td>{question.swim_class_value}</td>
                                <td>{question.swim_age}</td>
                                <td>{question.gender}</td>
                                <td>
                                  <input class='input' value={question.swim_class_value} onChange={this.tinput} />
                                  <input class='input' onChange={this.tinput} />
                                  <input class='input' onChange={this.tinput} />
                                </td>
                                <td>{question.swim_events.split(',').join(', ')}</td>
                                <td class='td-actions text-right'>
                                  <button
                                    type='button'
                                    rel='tooltip'
                                    class='btn btn-success btn-sm btn-icon'
                                    onClick={() => this.editswim(index)}
                                    data-toggle='modal'
                                    data-target='#meetedit'
                                  >
                                    {/* <i class="now-ui-icons ui-2_settings-90"></i> */}
                                    <i class='now-ui-icons gestures_tap-01'></i>
                                  </button>
                                  {` `}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
            {/* User table with edit delete------------------------------------------------------------------------------ */}
          </Row>
        </div>
      </>
    );
  }
}
export default Regswim;
