import React from 'react';

import '../App.css';
import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import { Empty } from 'antd';
import 'datatables.net';
import 'datatables.net-bs4';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
class Tennisassigngroupdoubles extends React.Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    this.state = {
      event: queryParams.get('event'),
      event_data: [{}],
      loader: true,
      groupdata: '',
      partnerdata: '',
    };
    this.back = this.back.bind(this);
    this.edit = this.edit.bind(this);
    this.update = this.update.bind(this);
  }
  async componentDidMount() {
    var self = this;
    const data = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    await axios
      .post(`${url.url}/api/getregistertennisevent/`, data, {})
      .then(function (response) {
        self.setState({ event_data: response.data, loader: false });
        // console.log(response.data);
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    // document.getElementById("venueesss").style.display = "block";
  }

  back() {
    window.location.replace('/venuemainpage?event=' + sessionStorage.getItem('event'));
  }

  edit(e) {
    // console.log(e);

    var self = this;
    self.setState({
      eventdatas: this.state.event_data[e],
      groupdata: this.state.event_data[e].group,
      partnerdata: this.state.event_data[e].partner,
    });
  }

  update(e) {
    const datatosend = {
      group: this.state.groupdata,
      partner: this.state.partnerdata,
    };
    // console.log(datatosend);
    axios
      .put(`${url.url}/api/getregistertennisevent/${this.state.eventdatas.id}`, datatosend, {})
      .then(function (response) {
        // console.log(response);
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  render() {
    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader
                type='ThreeDots'
                color='#00BFFF'
                height={70}
                width={70}
                // timeout={3000} //3 secs
              />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div>
              <div>
                <h1> </h1>
                <Row>
                  <Col>
                    <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                      Back
                    </button>
                    <Card>
                      <CardHeader>
                        <h5 className='title'>
                          {this.state.event} <br></br> Tennis AssignGroup Doubles
                        </h5>
                      </CardHeader>
                      <CardBody>
                        <Form>
                          <div class='form-group row'>
                            <div class='col-xs-5'>
                              <CardHeader>
                                <p>Search</p>
                                <input class='form-control' id='inputsm' placeholder='Search..' type='text' />
                              </CardHeader>
                            </div>
                          </div>
                          {Object.keys(this.state.event_data).length == 0 ? (
                            <Empty />
                          ) : (
                            <>
                              <Table class='worksheet_table'>
                                <thead>
                                  <tr class='worksheet_tr'>
                                    <th>Groups</th>
                                    <th>Partners</th>
                                    <th>Class</th>
                                    <th>Age</th>
                                    <th>Gender</th>
                                    <th>Last Name</th>
                                    <th>First Name</th>
                                    <th>Country</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.event_data.map((question, index) => (
                                    <tr class='worksheet_tr'>
                                      <td>{}</td>
                                      <td>{}</td>
                                      <td>{question.table_class_value}</td>
                                      <td>{question.age_division}</td>
                                      <td>{question.gender}</td>
                                      <td>{question.last_name}</td>
                                      <td>{question.first_name}</td>
                                      <td>{question.country}</td>
                                      <td>
                                        <button
                                          type='button'
                                          rel='tooltip'
                                          class='btn btn-success btn-sm btn-icon'
                                          onClick={() => this.edit(index)}
                                          data-toggle='modal'
                                          data-target='#useredit'
                                        >
                                          <i class='now-ui-icons ui-2_settings-90'></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </>
                          )}
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
              <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Tennis AssignGroup Doubles
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-row'>
                          <div class='form-group'>
                            <label for='inputCity'>Group</label>
                            <input
                              class='form-control'
                              value={this.state.groupdata}
                              onChange={(e) => this.setState({ groupdata: e.target.value })}
                              name='relayletter'
                            />
                          </div>
                        </div>

                        <div class='form-row'>
                          <div class='form-group'>
                            <label for='inputCity'>Partners</label>
                            <input
                              class='form-control'
                              value={this.state.partnerdata}
                              onChange={(e) => this.setState({ partnerdata: e.target.value })}
                              name='relayletter'
                            />
                          </div>
                        </div>

                        <center>
                          <button type='button' class='btn btn-danger' onClick={(e) => this.update()}>
                            Update
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Tennisassigngroupdoubles;
