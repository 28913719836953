import React from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';

import Swal from 'sweetalert2';
//import Agereload from './Agedivisionload.js'

import { Select, Input } from 'antd';
import 'antd/dist/antd.css';
import { Empty } from 'antd';
import { withRouter } from 'react-router-dom';
const url = window.globalConfig;
var x;

class Regifence extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      venues: [{}],
      venueid: [],
      tname: [],
      fenceeventdata: [],
      fencesdata: [],
      fenceevents: [],
      fencedatas: [{}],
      ranking: '',
      fenceeventsdata: '',
    };
    this.check = this.check.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.edit = this.edit.bind(this);
    this.tinput = this.tinput.bind(this);
    // this.save = this.save.bind(this)
    this.createfencereg = this.createfencereg.bind(this);
    this.updatefencereg = this.updatefencereg.bind(this);
  }
  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    var self = this;

    let indx = sessionStorage.getItem('fenceindex');
    let ids = sessionStorage.getItem('ids');
    const fenceeventdata = (index) => {
      if (index.fence_events != 'null') {
        var joined = this.state.fenceeventdata.concat(index);
        this.setState({
          fenceeventdata: joined,
        });
      }
    };
    //Get Particular user register meet data
    axios.get(`${url.url}/api/getcreateregisters/${ids}`, {}).then(function (response) {
      self.setState({
        allregdatas: response.data,
      });
      {
        response.data.map((index) => fenceeventdata(index));
      }
      var valdata = self.state.fenceeventdata[parseInt(indx)].fencing_events.split(',');

      self.setState({
        fencesdata: self.state.fenceeventdata[parseInt(indx)],
        fenceevents: valdata,
      });
    });

    let regid = sessionStorage.getItem('regid');
    axios
      .get(`${url.url}/api/getfregisterid/${regid}`, {})
      .then(function (response) {
        self.setState({
          fencedatas: response.data,
        });
      })
      .catch(function (error) {});

    //Hide and show manager
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('resultp').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    document.getElementById('venueesss').style.display = 'block';
  }
  check = (e) => {
    var temp = [];
    if (Object.keys(this.state.tname).length != 0) {
      temp = this.state.tname;
    }
    if (!this.state.tname.some((item) => e.target.value === item.name)) {
      temp.push({ name: e.target.value, id: e.target.id });
    }
    this.setState({ tname: temp });
  };
  handlechange() {
    window.location.replace('/fencingregister');
  }

  tinput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  edit(e) {
    var self = this;
    self.setState({
      fenceeventsdata: self.state.fenceevents[e],
    });
    var loop = true;

    self.state.fencedatas.forEach((question, index) => {
      if (question.selected_events == self.state.fenceevents[e]) {
        self.setState({
          selected_id: question.id,
          selected_events: question.selected_events,
          ranking: question.ranking,

          btncheck: false,
        });
        loop = false;
      } else if (loop) {
        self.setState({
          selected_id: '',
          selectedevents: self.state.fenceevents[e],
          ranking: '',

          btncheck: true,
        });
        loop = true;
      }
    });

    if (self.state.fencedatas.length == 0) {
      self.setState({
        selected_id: '',
        selectedevents: self.state.fenceevents[e],
        ranking: '',
        btncheck: true,
      });
    }
  }

  createfencereg(e) {
    var self = this;
    const setvalue = (q, index) => {
      if (self.state.fencedatas.length > index) {
        self.setState({
          selected_events: self.state.fencedatas[index].selected_events,
          ranking: self.state.fencedatas[index].ranking,
        });
      }
    };
    self.state.fenceevents.map((question, index) => {
      setvalue(question, index);
    });
    const datatosend = {
      first_name: this.state.fencesdata.firstname,
      last_name: this.state.fencesdata.lastname,
      age_division: this.state.fencesdata.fencing_age,
      gender: this.state.fencesdata.gender,
      selected_events: this.state.selectedevents,

      ranking: this.state.ranking,

      fence_class_value: this.state.fencesdata.fence_class_value,
      created_by: 1,
      register_id: parseInt(sessionStorage.getItem('regid')),
    };
    axios
      .post(`${url.url}/api/createfencereg/`, datatosend, {})
      .then(function (response) {
        if (response.status == 201) {
          Swal.fire({
            title: 'Success',
            text: 'Status updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  updatefencereg(e) {
    var self = this;

    const datatosend = {
      first_name: this.state.fencesdata.firstname,
      last_name: this.state.fencesdata.lastname,
      age_division: this.state.fencesdata.fencing_age,
      gender: this.state.fencesdata.gender,
      selected_events: this.state.selected_events,

      ranking: this.state.ranking,

      fence_class_value: this.state.fencesdata.fence_class_value,
      created_by: 1,
    };
    axios
      .put(`${url.url}/api/updatefencereg/${this.state.selected_id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            title: 'Success',
            text: 'Status updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  render() {
    const { selectedItems } = this.state;

    return (
      <>
        <div className='content'>
          <Row>
            <Col md='8'>
              <button
                type='button'
                data-toggle='modal'
                data-target='#selectfence'
                class='btn btn-danger btn-round'
                onClick={this.handlechange}
              >
                Back
              </button>
              <Card>
                <CardHeader>
                  <h5 className='title'>fence Events</h5>
                </CardHeader>

                <CardBody>
                  <Form>
                    <div class='form-group row'>
                      <div class='col-xs-5'>
                        <CardHeader id='deatils_heading'>
                          <p>
                            First Name:<span>{this.state.fencesdata.firstname}</span>
                          </p>
                          <p>
                            Last Name:<span>{this.state.fencesdata.lastname}</span>
                          </p>
                          <p>
                            fence Class:<span>{this.state.fencesdata.fence_class_value}</span>
                          </p>
                          <p>
                            Age Division:<span>{this.state.fencesdata.fencing_age}</span>
                          </p>
                          <p>
                            Gender:<span>{this.state.fencesdata.gender}</span>
                          </p>
                        </CardHeader>
                      </div>
                    </div>
                    {Object.keys(this.state.fencesdata).length == 0 ? (
                      <Empty />
                    ) : (
                      <>
                        <Table id='my' responsive>
                          <thead>
                            <tr>
                              <th>Athletes Events</th>
                              <th>Ranking</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody id='myTable'>
                            {this.state.fenceevents.map((question, index) => (
                              <tr>
                                <td>{question}</td>
                                {/* <td><input class="input detail_txt" id={"ranking"+index}  disabled={this.state.editview} onChange={e => this.setState({ ranking: e.target.value })} name="rank"/></td> */}
                                <td>
                                  {this.state.fencedatas.map((question1, index) =>
                                    question === question1.selected_events ? question1.ranking : ''
                                  )}
                                </td>
                                <td>
                                  <button
                                    type='button'
                                    rel='tooltip'
                                    class='btn btn-success btn-sm btn-icon'
                                    onClick={() => this.edit(index)}
                                    data-toggle='modal'
                                    data-target='#useredit'
                                  >
                                    <i class='now-ui-icons ui-2_settings-90'></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
            {/* User table with edit delete------------------------------------------------------------------------------ */}
          </Row>
          <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
            <div class='modal-dialog'>
              <div class='modal-content'>
                <div class='modal-header '>
                  <h5 class='modal-title ' id='loginmodalLabel'>
                    {this.state.fenceeventsdata + ' ' + 'Fencing'}{' '}
                  </h5>
                  <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <div class='modal-body'>
                  <form>
                    <div class='form-group'>
                      <label for='exampleFormControlSelect1'>Ranking</label>
                      <input
                        class='form-control'
                        value={this.state.ranking}
                        onChange={(e) => this.setState({ ranking: e.target.value })}
                        name='quatime'
                      />
                    </div>

                    {this.state.btncheck == true ? (
                      <center>
                        <button
                          type='button'
                          onClick={(e) => this.createfencereg()}
                          data-dismiss='modal'
                          class='btn btn-danger'
                        >
                          Create
                        </button>
                      </center>
                    ) : (
                      <>
                        <center>
                          <button type='button' class='btn btn-danger' onClick={(e) => this.updatefencereg()}>
                            Update
                          </button>
                        </center>
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Regifence;
