import React from 'react';
import '../App.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';

import Swal from 'sweetalert2';

import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import { Select, Input } from 'antd';
import 'antd/dist/antd.css';
import 'datatables.net';
import 'datatables.net-bs4';

import { Empty } from 'antd';

import Detailpage from './Detailpage.js';
import Viewall1 from './Viewall.js';
import Registered from './Registered.js';
import Register from '../register';
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
const { Option } = Select;
var formatdate = '';
var changeformatdate = '';
class Atheleteregister extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      meetdatas: [{}],
      regdetails: [{}],
      detaillists: [{}],
      datacheck: false,
      alldata: true,
      bibno: '',
      feedback: '',
      loader2: false,
      adetail: false,
      adetails: false,
      detaillist: [{}],
      disable: false,
      Rejected: '',
      Rejecteddata: [{}],
      mname: '',
      allregdatas: [],
      allsearchregdatas: [{}],
      activePage: 1,
      itemsCountPerPage: 1,
      totalItemsCount: 1,
      pageRangeDisplayed: 5,
      loader: true,
      issearchalldata: false,
      isathelete: true,
    };

    this.statusupdate = this.statusupdate.bind(this);
    this.viewall = this.viewall.bind(this);
    this.detailview = this.detailview.bind(this);
    this.Rejected = this.Rejected.bind(this);
    this.statusreject = this.statusreject.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  handlePageChange(pageNumber) {
    let ids = sessionStorage.getItem('ids');
    this.setState({ activePage: pageNumber });

    var self = this;
    axios
      .get(`${url.url}/api/getcreateregisters/${ids}?page=` + pageNumber, {})
      .then(function (response) {
        self.setState({
          allregdatas: response.data.data,
          itemsCountPerPage: response.data.per_page,
          totalItemsCount: response.data.total,
          activePage: response.data.current_page,
          loader: false,
        });
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
  }

  componentWillMount() {
    let ids = sessionStorage.getItem('ids');
    var self = this;
    axios
      .get(`${url.url}/api/getcreateregisters/${ids}`, {})
      .then(function (response) {
        self.setState({
          allregdatas: response.data.data,
          loader: false,
        });
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
  }

  componentDidMount() {
    let ids = sessionStorage.getItem('ids');

    this.handlePageChange();

    var self = this;
    axios.get(`${url.url}/api/getallathlete`, {}).then(function (response) {
      self.setState({
        allsearchregdatas: response.data,
        loader: false,
      });
    });

    //Hide and show manager
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
  }
  //Detail page View---------------------------------------------------
  onSearch = (e) => {
    var value = e.target.value.toLowerCase();
    if (!value) {
      this.setState({
        isathelete: true,
        issearchalldata: false,
      });
      return null;
    }
    $('#myTable tr').filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
    this.setState({
      isathelete: false,
      issearchalldata: true,
    });
  };
  detailview(index, athleteid) {
    var self = this;
    sessionStorage.setItem('index', index);
    sessionStorage.setItem('athleteid', athleteid);
    sessionStorage.getItem('index');
    self.setState({
      adetail: true,
      detaillist: athleteid,
    });
    window.location.replace('/details');
  }

  detailviewall(e) {
    var self = this;
    sessionStorage.setItem('index', e);
    sessionStorage.getItem('index');
    self.setState({
      adetails: true,
      detaillists: this.state.regdetails[e],
    });
  }

  //
  // manager status approval -------------------------------------------------start------------------------------
  Rejected(e) {
    var i = parseInt(e.split('-')[0]);
    if (e.split('-')[1] == 'rejected') {
      var j = 0;
    }

    var k = e.split('-')[1];
    this.setState({ rejecteddata: k, inter: i });
  }

  statusreject(event) {
    event.preventDefault();
    var j = 0;
    const datatosend = {
      status: this.state.rejecteddata,
      bib_no: 0,
      reason: this.state.feedback,
    };
    axios
      .put(`${url.url}/api/updateRegstatus/${this.state.regdetails[this.state.inter].id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            title: 'Sucess',
            text: 'Status updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  statusupdate(e) {
    var i = parseInt(e.split('-')[0]);

    if (e.split('-')[1] == 'approved') {
      var j = parseInt(this.state.bibno) + 1;
    } else if (e.split('-')[1] == 'rejected') {
      var j = 0;
    }

    const datatosend = {
      status: e.split('-')[1],
      bib_no: j,
      reason: 'null',
    };
    axios
      .put(`${url.url}/api/updateRegstatus/${this.state.regdetails[0].id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            title: 'Success',
            text: 'Status updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  // manager status approval end-----------------------------------------------------------------end----------------------

  // pending approval list----------------------------------------------------------
  pending() {
    var self = this;
    axios
      .get(`${url.url}/api/getPendingapproval/pending`, {})
      .then(function (response) {
        self.setState({
          regdetails: response.data,
          alldata: true,
        });
      })
      .catch(function (error) {
        self.setState({
          regdetails: [{}],
        });
      });
  }

  // pending approal list end----------------------------------------------

  // get all the registration---------------------------------------------
  viewall(e) {
    var self = this;
    self.setState({
      adetails: true,
    });
  }
  // get all the registration-------------------------------------------

  back() {
    var self = this;
    window.location.replace('/meets');
    sessionStorage.removeItem('curnavitem');
  }

  deleteAllData = () => {
    var self = this;
    let ids = sessionStorage.getItem('ids');
    self.setState({
      loader: true,
    });
    axios
      .delete(`${url.url}/api/getalldatadelete/${ids}`, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            title: 'Success',
            text: 'All Data will be deleted!',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            self.setState({
              loader: false,
            });
            window.location.reload();
          });
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Record Not Found',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        }).then(function (error) {
          self.setState({
            loader: false,
          });
        });
      });
  };

  render() {
    if (this.state.isathelete) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='content content_1'>
                  <Row>
                    <Col md='8'>
                      <div>
                        <div class='back'>
                          <button
                            type='button'
                            data-toggle='modal'
                            data-target='#newmeet'
                            class='btn btn-danger btn-round'
                            onClick={this.back}
                          >
                            Back
                          </button>
                          {` `}
                          <button
                            type='button'
                            class='btn btn-danger btn-round'
                            onClick={() => window.location.replace('/athletesearch')}
                          >
                            Athlete Search
                          </button>
                          {` `}
                          <button
                            type='button'
                            class='btn btn-danger btn-round'
                            onClick={() => window.location.replace('/register')}
                          >
                            New Registration
                          </button>
                          {` `}
                          <button type='button' class='btn btn-danger btn-round' onClick={this.deleteAllData}>
                            All Clear/Delete
                          </button>
                        </div>
                      </div>
                      <Card>
                        <CardHeader>
                          <h5 className='title'>Athlete Registration</h5>
                        </CardHeader>
                        <CardBody>
                          <Form>
                            <div class='form-group row'>
                              <div class='col-xs-5'>
                                <CardHeader>
                                  <p>Search</p>
                                  <input
                                    class='form-control'
                                    id='inputsm'
                                    onChange={(e) => this.onSearch(e)}
                                    placeholder='Search..'
                                    type='text'
                                  />
                                  <div class='athletebtn'>
                                    <Registered />
                                  </div>
                                </CardHeader>
                              </div>
                            </div>
                            {/* table --------------------------------------------------- */}

                            <Table id='my' responsive>
                              <thead>
                                <tr>
                                  <th className='text-center'>#</th>
                                  <th>Reg #</th>
                                  <th>Firstname</th>
                                  <th>Lastname</th>
                                  <th>DOB</th>
                                  <th>Age</th>
                                  <th>Gender</th>
                                  <th>Bib No</th>
                                  {/* <th>Email</th> */}
                                  <th>Team name</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody id='myTable'>
                                {this.state.allregdatas.map((question, index) => {
                                  let DOB = '';
                                  DOB = question.dob;
                                  formatdate = DOB.split('-');

                                  changeformatdate = formatdate[1] + '-' + formatdate[2] + '-' + formatdate[0];
                                  return (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{'R' + (index + 1)}</td>
                                      <td>{question.firstname}</td>
                                      <td>{question.lastname}</td>
                                      <td>{changeformatdate}</td>
                                      <td>{question.age} </td>
                                      <td>{question.gender}</td>
                                      <td>{question.bib_no}</td>
                                      <td>{question.team_name}</td>
                                      <td class='td-actions text-right'>
                                        <button
                                          type='button'
                                          rel='tooltip'
                                          class='btn btn-success btn-grid'
                                          onClick={() => this.detailview(index, question.id)}
                                        >
                                          <i class='now-ui-icons gestures_tap-01'></i>
                                        </button>
                                        {` `}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                            <Pagination
                              activePage={this.state.activePage}
                              itemsCountPerPage={this.state.itemsCountPerPage}
                              totalItemsCount={this.state.totalItemsCount}
                              pageRangeDisplayed={this.state.pageRangeDisplayed}
                              onChange={(pageNumber) => this.handlePageChange(pageNumber)}
                              itemClass='page-item'
                              linkClass='page-link'
                            />

                            {/* table --------------------------------------------------- */}
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (this.state.issearchalldata) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='content content_1'>
                  <Row>
                    <Col md='8'>
                      <div>
                        <div class='back'>
                          <button
                            type='button'
                            data-toggle='modal'
                            data-target='#newmeet'
                            class='btn btn-danger btn-round'
                            onClick={this.back}
                          >
                            Back
                          </button>
                          {` `}
                          <button
                            type='button'
                            class='btn btn-danger btn-round'
                            onClick={() => window.location.replace('/athletesearch')}
                          >
                            Athlete Search
                          </button>
                          {` `}
                          <button
                            type='button'
                            class='btn btn-danger btn-round'
                            onClick={() => window.location.replace('/register')}
                          >
                            New Registration
                          </button>
                          {` `}
                          <button type='button' class='btn btn-danger btn-round' onClick={this.deleteAllData}>
                            All Clear/Delete
                          </button>
                        </div>
                      </div>
                      <Card>
                        <CardHeader>
                          <h5 className='title'>Athlete Registration</h5>
                        </CardHeader>
                        <CardBody>
                          <Form>
                            <div class='form-group row'>
                              <div class='col-xs-5'>
                                <CardHeader>
                                  <p>Search</p>
                                  <input
                                    class='form-control'
                                    id='inputsm'
                                    onChange={(e) => this.onSearch(e)}
                                    placeholder='Search..'
                                    type='text'
                                  />
                                  <div class='athletebtn'>
                                    <Registered />
                                  </div>
                                </CardHeader>
                              </div>
                            </div>
                            {/* table --------------------------------------------------- */}

                            <Table id='my' responsive>
                              <thead>
                                <tr>
                                  <th className='text-center'>#</th>
                                  <th>Reg #</th>
                                  <th>Firstname</th>
                                  <th>Lastname</th>
                                  <th>DOB</th>
                                  <th>Age</th>
                                  <th>Gender</th>
                                  <th>Bib No</th>
                                  <th>Team name</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody id='myTable'>
                                {this.state.allsearchregdatas.map(
                                  (question, index) => (
                                    (formatdate = question.dob.split('-')),
                                    (changeformatdate = formatdate[1] + '-' + formatdate[2] + '-' + formatdate[0]),
                                    (
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>{question.register_num}</td>
                                        <td>{question.firstname}</td>
                                        <td>{question.lastname}</td>
                                        <td>{question.dob}</td>
                                        <td>{question.age} </td>
                                        <td>{question.gender}</td>
                                        <td>{question.bib_no}</td>
                                        <td>{question.team_name}</td>
                                        <td class='td-actions text-right'>
                                          <button
                                            type='button'
                                            rel='tooltip'
                                            class='btn btn-success btn-grid'
                                            onClick={() => this.detailview(index, question.id)}
                                          >
                                            <i class='now-ui-icons gestures_tap-01'></i>
                                          </button>
                                          {` `}
                                        </td>
                                      </tr>
                                    )
                                  )
                                )}
                              </tbody>
                            </Table>

                            {/* table --------------------------------------------------- */}
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
  }
}

export default Atheleteregister;
