import React from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import { CountryDropdown } from 'react-country-region-selector';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';

import Swal from 'sweetalert2';
//import Iconsreload from './Meetload.js'
import { Select, Input } from 'antd';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
class Icons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meetdatas: [{}],
      managers: [{}],
      meetname: [{}],
      assignedmanager: '',
      name: '',
      defaultoptions: '',
      managerid: '',
      locationname: '',
      meetdatename: '',
      meetenddatename: '',
      selectedItems: [],
      managernames: [],
      userdetails: JSON.parse(sessionStorage.getItem('userdetails')),
      meetid: '',
      managerkey: [],
      username: '',
      eusername: '',
      loader: true,
      emeetcountry: '',
    };
    this.editmeet = this.editmeet.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.optionchange = this.optionchange.bind(this);
    this.updatemeet = this.updatemeet.bind(this);
    this.meetdelete = this.meetdelete.bind(this);
    this.addmeet = this.addmeet.bind(this);
    this.optionchange1 = this.optionchange1.bind(this);
    this.optionchange2 = this.optionchange2.bind(this);
    this.optionchange3 = this.optionchange3.bind(this);
    this.selectCountry = this.selectCountry.bind(this);
    this.handlechange1 = this.handlechange1.bind(this);
    this.handlechange2 = this.handlechange2.bind(this);
  }
  componentDidMount() {
    // $(document).ready(function () {
    //   $("#inputsm").on("keyup", function () {
    //     var value = $(this).val().toLowerCase();
    //     $("#myTable tr").filter(function () {
    //       $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    //     });
    //   });
    // });

    var self = this;
    var count = 0;
    axios
      .get(`${url.url}/api/users`, {})
      .then(function (response) {
        self.setState({ datas: response.data.data });
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {});

    var self = this;
    axios
      .get(`${url.url}/api/meets`, {})
      .then(function (response) {
        self.setState({
          meetdatas: response.data.data,
        });
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {});
    axios
      .get(`${url.url}/api/managers`, {})
      .then(function (response) {
        self.setState({
          managers: response.data.data,
        });
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {});
    sessionStorage.removeItem('venueid');
    sessionStorage.removeItem('cvenues');
    sessionStorage.removeItem('classv');
  }

  onSearch = (e) => {
    var value = e.target.value.toLowerCase();
    $('#myTable tr').filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
  };

  // ----------------------------------------edit meet--------------------------------
  handleChange(event) {
    event.persist();
    let value = event.target.value;
    this.setState((prevState) => ({
      meetname: { ...prevState.meetname, [event.target.name]: value },
    }));
  }

  editmeet(e) {
    var self = this;
    self.setState({
      meetname: this.state.meetdatas[e],
      defaultoptions: this.state.meetdatas[e].manager == '' ? '' : this.state.meetdatas[e].manager,
      emeetcountry: this.state.meetdatas[e].Location,
      // eusername: this.state.meetdatas[e].user_name,
      meetdatename: this.state.meetdatas[e].meet_date || ' ',
      meetenddatename: this.state.meetdatas[e].meet_end_date || ' ',
      meetid: this.state.meetdatas[e].id,
    });
  }

  optionchange(e) {
    this.setState({ defaultoption: e.target.value });
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  optionchange4 = (e) => {
    this.setState({
      username: e.target.value,
      [e.target.name]: e.target.value,
    });
  };

  handlechange1 = (e, key) => {
    this.setState({
      selectedItems: e,
      managerkey: key,
    });
  };

  handlechange2(e, key) {
    var self = this;
    self.setState({
      selectedItems: e,
      defaultoptions: e,
      managerkey: key,
    });
  }

  optionchange1(e) {
    this.setState({
      emeetcountry: e.target.value,
      [e.target.name]: e.target.value,
    });
  }

  optionchange2(e) {
    // const date = new Date(e.target.value);
    // date.setMonth(date.getMonth() + 3);
    // const currDate = date.toISOString().slice(0, 10);
    this.setState({
      meetdatename: e.target.value,
      meetenddatename: e.target.value,
      [e.target.name]: e.target.value,
    });
  }

  optionchange3(e) {
    this.setState({
      meetenddatename: e.target.value,
    });
  }

  updatemeet() {
    var self = this;
    const managerid = (e) => {
      axios.get(`${url.url}/api/getmanageriddetails/${e}`, {}).then(function (response) {
        var meetid = self.state.meetid;
        var managers_meet = response.data.data[0].manager_name.split('_');
        const found = managers_meet.find((element) => element == meetid);

        var test = '';
        if (found) {
          test = response.data.data[0].manager_name;
        } else {
          test = response.data.data[0].manager_name == '' ? meetid : response.data.data[0].manager_name + '_' + meetid;
        }
        const data = {
          manager_name: test,
        };
        axios.put(`${url.url}/api/updatemanager/${e}`, data, {});
      });
    };
    self.state.managerkey.map((ques) => managerid(ques.key));
    const datatosend = {
      name: this.state.meetname.name,
      manager: this.state.defaultoptions.toString(),
      Location: this.state.emeetcountry,
      meet_date: this.state.meetdatename,
      meet_end_date: this.state.meetenddatename,
    };

    axios
      .put(`${url.url}/api/updatemeet/${this.state.meetname.id}`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Meet Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  // delete meet------------------------------------------------------------------------------------------------

  meetdelete(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deletemeet/${this.state.meetdatas[e].id}`, {})
          .then(function (response) {})

          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });
  }
  // ---------------------------------------------------------Add meet--------------------------------------------------

  addmeet(e) {
    var self = this;
    const managerid = (e) => {
      axios.get(`${url.url}/api/getmanageriddetails/${e}`, {}).then(function (response) {
        const data = {
          manager_name:
            response.data.data[0].manager_name == ''
              ? self.state.meetid
              : response.data.data[0].manager_name + '_' + self.state.meetid,
        };
        axios.put(`${url.url}/api/updatemanager/${e}`, data, {});
      });
    };
    const datatosend = {
      name: this.state.name,
      // user_name:this.state.username,
      manager: this.state.selectedItems.toString(),
      Location: this.state.location,
      managersid: this.state.managerkey,
      meet_date: this.state.meetdate,
      meet_end_date: this.state.meetdate,
      created_by: 1,
    };
    axios
      .post(`${url.url}/api/createmeet`, datatosend, {})
      .then(function (response) {
        if (response.statusText == 'OK' || response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Meet Created successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  selectCountry(val) {
    this.setState({ selectCountry: val });
    this.setState({
      emeetcountry: val,
    });
  }

  render() {
    //sessionStorage.setItem("path","/meets");
    const { selectedItems } = this.state;
    const { managernames } = this.state;
    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader
                type='ThreeDots'
                color='#00BFFF'
                height={70}
                width={70}
                // timeout={3000} //3 secs
              />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div className='content'>
              <Row>
                <Col md='8'>
                  <button type='button' data-toggle='modal' data-target='#newmeet' class='btn btn-danger btn-round'>
                    New Meet
                  </button>
                  <Card>
                    <CardHeader>
                      <h5 className='title'>Meets</h5>
                    </CardHeader>

                    <CardBody>
                      <Form>
                        <div class='form-group row'>
                          <div class='col-xs-5'>
                            <CardHeader>
                              <p>Search</p>
                              <input
                                class='form-control'
                                id='inputsm'
                                onChange={(e) => this.onSearch(e)}
                                placeholder='Search..'
                                type='text'
                              />
                            </CardHeader>
                          </div>
                        </div>

                        {/* table --------------------------------------------------- */}
                        <Table id='my' responsive>
                          <thead>
                            <tr>
                              <th className='text-center'>#</th>
                              <th>Name</th>
                              {/* <th>User Name</th> */}
                              <th>Meet Managers</th>
                              <th>Location</th>
                              <th>Meet Start Date</th>
                              <th>Meet End Date</th>

                              <th class='disabled-sorting text-right'>Actions</th>
                            </tr>
                          </thead>
                          <tbody id='myTable'>
                            {this.state.meetdatas.map((question, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{question.name}</td>
                                {/* <td>{question.user_name}</td> */}
                                <td>{question.manager}</td>
                                <td>{question.Location}</td>
                                <td>{question.meet_date}</td>
                                <td>{question.meet_end_date}</td>
                                <td class='td-actions text-right'>
                                  <button
                                    type='button'
                                    rel='tooltip'
                                    class='btn btn-success btn-sm btn-icon'
                                    onClick={() => this.editmeet(index)}
                                    data-toggle='modal'
                                    data-target='#meetedit'
                                  >
                                    <i class='now-ui-icons ui-2_settings-90'></i>
                                  </button>
                                  {` `}
                                  <button
                                    type='button'
                                    rel='tooltip'
                                    class='btn btn-danger btn-sm btn-icon'
                                    onClick={() => this.meetdelete(index)}
                                  >
                                    <i class='now-ui-icons ui-1_simple-remove'></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>

                        {/* table --------------------------------------------------- */}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* edit meet modal ----------------------------------------------------------------------------------------------------------------- */}
              <div class='modal fade' id='meetedit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Edit Meet{' '}
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='inputAddress'>Name</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            placeholder='Please Enter Name'
                            name='name'
                            value={this.state.meetname.name}
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        {/* <div class="form-group">
                          <label for="inputAddress">User Name</label>
                          <input
                          type="text"
                          class="form-control"
                          id="inputAddress"
                          placeholder="Please Enter UserName"
                          name="username"
                          value={this.state.eusername}
                          onChange={this.handlechange4}
                          required />
                        </div> */}

                        <div class='form-group'>
                          <label for='exampleFormControlSelect1'>Manager</label>
                          <Select
                            mode='multiple'
                            placeholder='Please select'
                            value={this.state.defaultoptions}
                            onChange={this.handlechange2}
                            style={{ width: '410px' }}
                          >
                            {Object.keys(this.state.managers).length == 0 ? (
                              <Select.Option key='' value=''>
                                Please select
                              </Select.Option>
                            ) : (
                              <>
                                {this.state.managers.map((edrop, index) => (
                                  <Select.Option key={edrop.id} value={edrop.firstname}>
                                    {edrop.firstname}
                                  </Select.Option>
                                ))}
                              </>
                            )}
                          </Select>
                        </div>

                        <div class='form-group'>
                          <label>Meet Location (City/State)</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            placeholder='1234 Main St'
                            name='location'
                            value={this.state.emeetcountry}
                            onChange={this.optionchange1}
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Meet Start Date</label>
                          <input
                            type='date'
                            class='form-control'
                            id='inputAddress'
                            placeholder='1234 Main St'
                            name='meetdate'
                            value={this.state.meetdatename}
                            onChange={this.optionchange2}
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Meet End Date</label>
                          <input
                            type='date'
                            class='form-control'
                            id='inputAddress'
                            placeholder='1234 Main St'
                            name='meetenddate'
                            value={this.state.meetenddatename}
                            onChange={this.optionchange3}
                            required
                          />
                        </div>

                        <center>
                          <button type='submit' onClick={this.updatemeet} data-dismiss='modal' class='btn btn-danger'>
                            Update
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              {/* add meet------------------------------------------------------------------------------------------------- */}
              <div class='modal fade' id='newmeet' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        New Meet
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='inputAddress'>Name</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='name'
                            onChange={this.optionchange}
                            placeholder='Enter meet name'
                            required
                          />
                        </div>
                        <div class='form-group'>
                          <label for='inputAddress'>User Name</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            placeholder='Please Enter Username'
                            name='username'
                            onChange={this.optionchange4}
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='exampleFormControlSelect1'>Manager</label>
                          <Select
                            mode='multiple'
                            placeholder='Please select'
                            value={this.state.selectedItems}
                            onChange={this.handlechange1}
                            style={{ width: '410px' }}
                          >
                            {Object.keys(this.state.managers).length == 0 ? (
                              <Select.Option key='' value=''>
                                Please select
                              </Select.Option>
                            ) : (
                              <>
                                {this.state.managers.map((edrop, index) => (
                                  <Select.Option key={edrop.id} value={edrop.firstname}>
                                    {edrop.firstname}
                                  </Select.Option>
                                ))}
                              </>
                            )}
                          </Select>
                        </div>

                        <div class='form-group'>
                          <label>Meet Location (City/State)</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='location'
                            onChange={this.optionchange1}
                            placeholder='Enter location'
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Meet start Date</label>
                          <input
                            type='date'
                            class='form-control'
                            id='inputAddress'
                            name='meetdate'
                            value={this.state.meetdatename}
                            onChange={this.optionchange2}
                            placeholder='Enter Meet date'
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Meet End Date</label>
                          <input
                            type='date'
                            class='form-control'
                            id='inputAddress1'
                            name='meetenddate'
                            value={this.state.meetenddatename}
                            onChange={this.optionchange3}
                            placeholder='Enter Meet end date'
                            required
                          />
                        </div>

                        <center>
                          <button type='submit' onClick={this.addmeet} data-dismiss='modal' class='btn btn-danger'>
                            Create meet
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Icons;
