import React from 'react';

import '../App.css';

import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import 'datatables.net';
import 'datatables.net-bs4';
import { faWindows } from '@fortawesome/free-brands-svg-icons';

import FullScreenMap2 from './Venueclasses';
import FullScreenMap from './Venuesload.js';

import { Empty } from 'antd';
import Loader from 'react-loader-spinner';
import Gender from './Gender';
const url = window.globalConfig;
let gen;
var receivedStateValue;
class Classvalue extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      venuedatas: [{}],
      newvenue: '',
      vname: '',
      vvalue: '',
      venuehome: true,
      eventhome: false,
      venueclasshome: false,
      venuespecfic: [{}],
      editename: '',
      editeid: '',
      newevents: '',
      newvenueclass: '',
      evenueclassname: '',
      evenueclassid: '',
      venuebyid: [{}],
      newclassvalue: '',
      vid: [{}],
      newclsvalue: '',
      newclsid: '',
      rerend: false,
      active1: false,
      loader: false,
      venuespecficid: '',
      classvaluedatas: [{}],
      classages: false,
      preloader: true,
      powerliftshow: true,
      womenpowerliftshow: false,
      genderselect: 'Men',
    };
    var urlParams = new URLSearchParams(window.location.search);
    receivedStateValue = urlParams.get('state');

    this.handleChange = this.handleChange.bind(this);
    this.createclassvalue = this.createclassvalue.bind(this);
    this.updateclassvalue = this.updateclassvalue.bind(this);
    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);
    this.back = this.back.bind(this);
    this.classage = this.classage.bind(this);
    this.womenpowerlift = this.womenpowerlift.bind(this);
  }

  componentDidMount() {
    if (receivedStateValue === 'Women Powerlifting') {
      this.setState({
        powerliftshow: false,
        womenpowerliftshow: true,
        genderselect: 'Women',
      });
      this.getclassvalues('Women');
    }
    var self = this;
    self.setState({ venuespecfic: this.props.did });
    sessionStorage.getItem('venue_name');
    gen = sessionStorage.getItem('gen');
    let eventname = sessionStorage.getItem('eventname');
    let genderselect = '';
    if (sessionStorage.getItem('venue_name') == 'Para Powerlifting') {
      genderselect = this.state.genderselect;
    } else {
      genderselect = '';
      self.setState({
        genderselect: '',
      });
    }
    self.getclassvalues(genderselect);
  }

  getclassvalues = (genderselect) => {
    var self = this;
    let eventid = sessionStorage.getItem('eventid');
    const params = {
      gender: genderselect,
    };
    axios
      .get(`${url.url}/api/geteventid/${eventid}`, { params })
      .then((response) => {
        self.setState({
          classvaluedatas: response.data,
          preloader: false,
        });
      })
      .catch(function (error) {
        self.setState({
          preloader: false,
        });
      });
  };
  onSearch = (e) => {
    var value = e.target.value.toLowerCase();
    $('#myTable tr').filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
  };

  handleChange(e) {
    var self = this;
    self.setState({
      newclassvalue: e.target.value,
    });
    self.setState({
      newclsvalue: e.target.value,
    });
  }
  createclassvalue(e) {
    var self = this;
    e.preventDefault();
    document.getElementById('newcreateclassvalue').value = '';
    let eventid = sessionStorage.getItem('eventid');
    const datatosend = {
      name: this.state.newclassvalue,
      event_id: parseInt(eventid),
      gender: this.state.genderselect,
    };
    axios
      .post(`${url.url}/api/createeventclass/`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'Created' || response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'Class Value Created successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then((response) => {
            self.getclassvalues(self.state.genderselect);
          });
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong Please check the field name',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  edit(e) {
    var self = this;
    self.setState({
      newclsvalue: this.state.classvaluedatas[e].name,
      newclsid: this.state.classvaluedatas[e].id,
    });
  }

  updateclassvalue(e) {
    var self = this;
    e.preventDefault();
    const datatosend = {
      name: this.state.newclsvalue,
    };
    axios
      .put(`${url.url}/api/updateeventclassvalue/${this.state.newclsid}`, datatosend, {})

      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Class Value Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then((response) => {
            self.getclassvalues(self.state.genderselect);
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  delete(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deleteeventclassvalue/${this.state.classvaluedatas[e].id}`, {})
          .then(function (response) {})

          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });
  }

  classage(e) {
    var self = this;
    sessionStorage.setItem('classvalueindex', e);
    sessionStorage.setItem('classvalueid', this.state.classvaluedatas[e].id);
    sessionStorage.setItem('classvaluename', this.state.classvaluedatas[e].name);
    self.setState({
      classages: true,
      newclsvalue: this.state.classvaluedatas[e].name,
      newclsid: this.state.classvaluedatas[e].id,
    });
  }

  back() {
    window.location.replace('/venuesevent');
  }

  back1 = () => {
    window.location.replace('/classvalues');
  };
  womenpowerlift() {
    this.setState({
      powerliftshow: false,
      womenpowerliftshow: true,
      genderselect: 'Women',
    });
    this.getclassvalues('Women');
  }
  render() {
    let eventname = sessionStorage.getItem('eventname');

    // venue home ----------------------------------------------------------------------------------------------------
    if (this.state.classages) {
      return <Redirect to='/classagegroups' />;
    }
    if (this.state.powerliftshow) {
      return (
        <>
          {this.state.preloader ? (
            <>
              <div className='svg-wrapper'>
                <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div className='content'>
                <Row>
                  <Col md='8'>
                    <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                      Back
                    </button>
                    <button type='button' data-toggle='modal' data-target='#addvenue' class='btn btn-danger btn-round'>
                      New Class Value
                    </button>
                    {sessionStorage.getItem('venue_name') == 'Para Powerlifting' ? (
                      <button type='button' class='btn btn-danger btn-round' onClick={this.womenpowerlift}>
                        Women Para Powerlift
                      </button>
                    ) : (
                      <></>
                    )}
                    <Card>
                      <CardHeader>
                        <h5 className='title'>
                          {sessionStorage.getItem('venue_name') == 'Para Powerlifting'
                            ? 'Men ' + eventname
                            : eventname + '-' + 'ClassValues'}
                        </h5>
                      </CardHeader>
                      <CardBody>
                        <Form>
                          <div class='form-group row'>
                            <div class='col-xs-5'>
                              <CardHeader>
                                <p>Search</p>
                                <input
                                  class='form-control'
                                  id='inputsm'
                                  onChange={(e) => this.onSearch(e)}
                                  placeholder='Search..'
                                  type='text'
                                />
                              </CardHeader>
                            </div>
                          </div>
                          {/* Table -------------------------------------------- */}

                          {Object.keys(this.state.classvaluedatas).length == 0 ? (
                            <Empty />
                          ) : (
                            <>
                              <Table id='my' responsive>
                                <thead>
                                  <tr>
                                    <th className='text-center'>#</th>
                                    <th>Name</th>

                                    <th class='disabled-sorting text-right'>Actions</th>
                                  </tr>
                                </thead>

                                <tbody id='myTable'>
                                  {this.state.classvaluedatas.map((question, index) => (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{question.name}</td>
                                      <td class='td-actions text-right'>
                                        <button
                                          type='button'
                                          rel='tooltip'
                                          class='btn btn-success btn-sm btn-icon'
                                          onClick={() => this.classage(index)}
                                        >
                                          <i class='now-ui-icons gestures_tap-01'></i>
                                        </button>

                                        {` `}

                                        <button
                                          type='button'
                                          rel='tooltip'
                                          class='btn btn-warning btn-sm btn-icon'
                                          onClick={() => this.edit(index)}
                                          data-toggle='modal'
                                          data-target='#venueedit'
                                        >
                                          <i class='now-ui-icons ui-2_settings-90'></i>
                                        </button>
                                        {` `}
                                        <button
                                          type='button'
                                          rel='tooltip'
                                          class='btn btn-danger btn-sm btn-icon'
                                          onClick={() => this.delete(index)}
                                        >
                                          <i class='now-ui-icons ui-1_simple-remove'></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </>
                          )}
                          {/* Table -------------------------------------------- */}
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <div class='modal fade' id='addvenue' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog '>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Add Classvalue{' '}
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <form>
                        <div class='modal-body'></div>

                        <div class='form-group col-md-8'>
                          <label for='inputEmail4'>Name *</label>
                          <input
                            type='text'
                            id='newcreateclassvalue'
                            class='form-control'
                            name='newclassvalue'
                            onChange={this.handleChange}
                          />
                        </div>

                        <center>
                          <button
                            type='submit'
                            onClick={this.createclassvalue}
                            data-dismiss='modal'
                            class='btn btn-danger'
                          >
                            Create
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>

                {/* edit */}
                <div class='modal fade' id='venueedit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog '>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Edit Classvalue{' '}
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <form>
                        <div class='modal-body'></div>
                        <div class='form-group col-md-8'>
                          <label for='inputEmail4'>Name *</label>
                          <input
                            type='text'
                            class='form-control'
                            name='vname'
                            value={this.state.newclsvalue}
                            onChange={this.handleChange}
                          />
                        </div>
                        <center>
                          <button
                            type='submit'
                            onClick={this.updateclassvalue}
                            data-dismiss='modal'
                            class='btn btn-danger'
                          >
                            Update
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (this.state.womenpowerliftshow) {
      return (
        <>
          {this.state.preloader ? (
            <>
              <div className='svg-wrapper'>
                <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div className='content'>
                <Row>
                  <Col md='8'>
                    <button type='button' class='btn btn-danger btn-round' onClick={this.back1}>
                      Back
                    </button>
                    <button type='button' data-toggle='modal' data-target='#addvenue' class='btn btn-danger btn-round'>
                      New Class Value
                    </button>
                    <Card>
                      <CardHeader>
                        <h5 className='title'>
                          {sessionStorage.getItem('venue_name') == 'Para Powerlifting'
                            ? 'Women ' + eventname
                            : eventname + '-' + 'ClassValues'}
                        </h5>
                      </CardHeader>
                      <CardBody>
                        <Form>
                          <div class='form-group row'>
                            <div class='col-xs-5'>
                              <CardHeader>
                                <p>Search</p>
                                <input
                                  class='form-control'
                                  id='inputsm'
                                  onChange={(e) => this.onSearch(e)}
                                  placeholder='Search..'
                                  type='text'
                                />
                              </CardHeader>
                            </div>
                          </div>
                          {/* Table -------------------------------------------- */}
                          {Object.keys(this.state.classvaluedatas).length == 0 ? (
                            <Empty />
                          ) : (
                            <>
                              <Table id='my' responsive>
                                <thead>
                                  <tr>
                                    <th className='text-center'>#</th>
                                    <th>Name</th>

                                    <th class='disabled-sorting text-right'>Actions</th>
                                  </tr>
                                </thead>

                                <tbody id='myTable'>
                                  {this.state.classvaluedatas.map((question, index) => (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{question.name}</td>
                                      <td class='td-actions text-right'>
                                        <button
                                          type='button'
                                          rel='tooltip'
                                          class='btn btn-success btn-sm btn-icon'
                                          onClick={() => this.classage(index)}
                                        >
                                          <i class='now-ui-icons gestures_tap-01'></i>
                                        </button>
                                        {` `}
                                        <button
                                          type='button'
                                          rel='tooltip'
                                          class='btn btn-warning btn-sm btn-icon'
                                          onClick={() => this.edit(index)}
                                          data-toggle='modal'
                                          data-target='#venueedit'
                                        >
                                          <i class='now-ui-icons ui-2_settings-90'></i>
                                        </button>
                                        {` `}
                                        <button
                                          type='button'
                                          rel='tooltip'
                                          class='btn btn-danger btn-sm btn-icon'
                                          onClick={() => this.delete(index)}
                                        >
                                          <i class='now-ui-icons ui-1_simple-remove'></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </>
                          )}
                          {/* Table -------------------------------------------- */}
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <div class='modal fade' id='addvenue' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog '>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Add Classvalue{' '}
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <form>
                        <div class='modal-body'></div>

                        <div class='form-group col-md-8'>
                          <label for='inputEmail4'>Name *</label>
                          <input
                            type='text'
                            id='newcreateclassvalue'
                            class='form-control'
                            name='newclassvalue'
                            onChange={this.handleChange}
                          />
                        </div>

                        <center>
                          <button
                            type='submit'
                            onClick={this.createclassvalue}
                            data-dismiss='modal'
                            class='btn btn-danger'
                          >
                            Create
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>

                {/* edit */}
                <div class='modal fade' id='venueedit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog '>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Edit Classvalue{' '}
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <form>
                        <div class='modal-body'></div>
                        <div class='form-group col-md-8'>
                          <label for='inputEmail4'>Name *</label>
                          <input
                            type='text'
                            class='form-control'
                            name='vname'
                            value={this.state.newclsvalue}
                            onChange={this.handleChange}
                          />
                        </div>
                        <center>
                          <button
                            type='submit'
                            onClick={this.updateclassvalue}
                            data-dismiss='modal'
                            class='btn btn-danger'
                          >
                            Update
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
  }
}

export default Classvalue;
