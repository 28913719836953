import React from 'react';

import '../App.css';
import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import { Empty } from 'antd';
import 'datatables.net';
import 'datatables.net-bs4';
import Loader from 'react-loader-spinner';
var x;
var y;
var arr = [];
const url = window.globalConfig;
class Teamsportsinitialsetup extends React.Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    this.state = {
      event: queryParams.get('event'),
      event_data: [],
      relay: false,
      notrelay: false,
      btncheck: false,
      prelimresultdata: '',
      prelimplacedata: '',
      finalresultdata: '',
      finalplacedata: '',
      eventdatas: '',
      loader: true,
    };
    this.back = this.back.bind(this);
    // this.create = this.create.bind(this);
    this.update = this.update.bind(this);
    this.edit = this.edit.bind(this);
    this.relaypoints = this.relaypoints.bind(this);
  }
  async componentDidMount() {
    var self = this;
    const data = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    // console.log(data);
    await axios
      .post(`${url.url}/api/getinitialteamevent/`, data, {})
      .then(function (response) {
        self.setState({ event_data: response.data, loader: false });
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    // document.getElementById("venueesss").style.display = "block";
  }
  back() {
    window.location.replace('/venuemainpage?event=' + sessionStorage.getItem('event'));
  }

  edit(e) {
    // console.log(e);

    var self = this;
    self.setState({
      eventdatas: e.replace(/,/gi, '_'),
      prelimresultdata: this.state.event_data[e.replace(/,/gi, '_')][0].prelim_result,
      finalresultdata: this.state.event_data[e.replace(/,/gi, '_')][0].final_result,
      prelimplacedata: this.state.event_data[e.replace(/,/gi, '_')][0].prelim_place,
      finalplacedata: this.state.event_data[e.replace(/,/gi, '_')][0].final_place,
    });
    // console.log(this.state.event_data[e.replace(/,/gi, "_")][0].prelim_result)
  }

  relaypoints(e, pts) {
    var self = this;
    var relay_points = 0;

    Object.keys(this.state.event_data).map(function (key) {
      if (key == e) {
        self.state.event_data[e].map((question, index) => {
          relay_points = relay_points + parseInt(question.relay_points);
        });
      }
    });

    return (
      <p class='total_points'>
        {relay_points > pts ? relay_points + ' ' + 'Relay Points Total > Relay Points ' : relay_points}
      </p>
    );
  }

  update(e) {
    var self = this;
    const datatosend = {
      prelim_result: this.state.prelimresultdata,
      final_result: this.state.finalresultdata,
      prelim_place: this.state.prelimplacedata,
      final_place: this.state.finalplacedata,
    };

    Object.keys(this.state.event_data).map(function (key) {
      if (key == self.state.eventdatas) {
        self.state.event_data[self.state.eventdatas].map((question, index) => {
          axios
            .put(`${url.url}/api/updatetrackrelaysetup/${question.id}`, datatosend, {})
            .then(function (response) {
              // console.log(response);
              if (response.status == 200) {
                Swal.fire({
                  icon: 'success',
                  title: 'Status Updated successfully',
                  showConfirmButton: false,
                  timer: 1000,
                }).then(function (response) {
                  window.location.reload();
                });
              }
            })

            .catch(function (error) {
              Swal.fire({
                title: 'Failed',
                text: 'Something went wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok!',
              });
            });
        });
      }
    });
  }

  render() {
    var self = this;
    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader
                type='ThreeDots'
                color='#00BFFF'
                height={70}
                width={70}
                // timeout={3000} //3 secs
              />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div>
              <div>
                <h1> </h1>
                <Row>
                  <Col>
                    <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                      Back
                    </button>
                    <Card>
                      <CardHeader>
                        <h5 className='title'>
                          {this.state.event} <br></br> Initial Setup
                        </h5>
                      </CardHeader>
                      <CardBody>
                        <Form>
                          {Object.keys(this.state.event_data).map(function (key) {
                            x = key.split('_');

                            return (
                              <CardBody>
                                <div class='realy_main'>
                                  <div>
                                    <p>
                                      <label class='relay_label'>Team Name</label>
                                      {x[0] + ' ' + x[2]}
                                    </p>
                                    <p>
                                      <label class='relay_label'>Team Gender</label>
                                      {x[1]}
                                    </p>
                                  </div>
                                  <div class='realy_main1'>
                                    <label class='relay_label'>Team Members</label>

                                    {self.state.event_data[key][0] ? (
                                      <p>
                                        {self.state.event_data[key][0].first_name}{' '}
                                        {self.state.event_data[key][0].last_name}
                                      </p>
                                    ) : (
                                      <p></p>
                                    )}
                                    {self.state.event_data[key][1] ? (
                                      <p>
                                        {self.state.event_data[key][1].first_name}{' '}
                                        {self.state.event_data[key][1].last_name}
                                      </p>
                                    ) : (
                                      <p></p>
                                    )}
                                    {self.state.event_data[key][2] ? (
                                      <p>
                                        {self.state.event_data[key][2].first_name}{' '}
                                        {self.state.event_data[key][2].last_name}
                                      </p>
                                    ) : (
                                      <p></p>
                                    )}
                                    {self.state.event_data[key][3] ? (
                                      <p>
                                        {self.state.event_data[key][3].first_name}{' '}
                                        {self.state.event_data[key][3].last_name}
                                      </p>
                                    ) : (
                                      <p></p>
                                    )}
                                  </div>
                                </div>
                              </CardBody>
                            );
                          })}
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Teamsportsinitialsetup;
