import React from 'react';

import '../App.css';

import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';

import { faWindows } from '@fortawesome/free-brands-svg-icons';

const url = window.globalConfig;
class FullScreenMap2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      venuedatas: [{}],
      newvenue: '',
      vname: '',
      vvalue: '',
      venuespecfic: [{}],
      editename: '',
      editeid: '',
      newevents: '',
      newvenueclass: '',
      evenueclassname: '',
      evenueclassid: '',
      venuebyid: [{}],
      newclassvalue: '',
      vid: [{}],
      newclsvalue: '',
      newclsid: '',
      rerend: false,
      active1: false,
      loader: false,
    };
    this.handleChange = this.handleChange.bind(this);
    //this.createvenue=this.createvenue.bind(this);
    // this.delete=this.delete.bind(this);
    // this.updatevenue=this.updatevenue.bind(this);
    // this.eventd=this.eventd.bind(this);
    // this.eventedit=this.eventedit.bind(this);
    // this.updateevent=this.updateevent.bind(this);
    // this.eventdelete=this.eventdelete.bind(this);
    //this.createevent=this.createevent.bind(this);
    //this.createvenueclass=this.createvenueclass.bind(this);
    // this.venueclassedit=this.venueclassedit.bind(this);
    //this.updatevenueclass=this.updatevenueclass.bind(this);
    //this.venueclassdelete=this.venueclassdelete.bind(this);
    // this.vhome=this.vhome.bind(this);
    this.createclassvalue = this.createclassvalue.bind(this);
    this.updateclassvalue = this.updateclassvalue.bind(this);
    this.deleteclassvalue = this.deleteclassvalue.bind(this);
    // this.backhome=this.backhome.bind(this);
    this.backhome1 = this.backhome1.bind(this);
  }
  componentWillMount() {
    let id = sessionStorage.getItem('venue_id');
    if (!this.state.loader) {
      var self = this;
      self.setState({ venue_id: id });
      const datatosend = {
        venue_id: id,
      };
      // console.log(self.state.id)
      axios.post(`${url.url}/api/classesbyvenueclassid/`, datatosend, {}).then(function (response) {
        self.setState({
          venuebyid: response.data.data,
        });
        self.setState({ loader: true });
        //console.log(response.data.data)
        //console.log(this.state.venuebyid)
      });
    }
  }
  componentDidMount() {
    //console.log("hello")
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });
    var self = this;
    let id = sessionStorage.getItem('venue_id');
    const datatosend = {
      venue_id: id,
    };
    //console.log(this.props.vide)
    axios.post(`${url.url}/api/classesbyvenueclassid/`, datatosend, {}).then(function (response) {
      self.setState({
        venuebyid: response.data.data,
      });
      self.setState({ loader: true });
      //console.log(response.data.data)
      //console.log(this.state.venuebyid)
    });
  }

  handleChange(e) {
    var self = this;
    self.setState({
      [e.target.name]: e.target.value,
    });
  }

  createclassvalue(e) {
    var self = this;
    e.preventDefault();
    let id = sessionStorage.getItem('venue_id');
    const datatosend = {
      name: this.state.newclassvalue,
      created_by: 1,
      venue_class_id: parseInt(id),
    };
    axios
      .post(`${url.url}/api/createclass/`, datatosend, {})
      .then(function (response) {
        //console.log(response)
        if (response.statusText === 'OK') {
          Swal.fire({
            title: 'Sucess',
            text: 'Venue Class value Created Successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong Please check the field name',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  venueclassvalueedit(e) {
    var self = this;
    self.setState({
      newclsvalue: this.state.venuebyid[e].name,
      newclsid: this.state.venuebyid[e].id,
    });
  }

  updateclassvalue(e) {
    var self = this;
    e.preventDefault();
    const datatosend = {
      name: this.state.newclsvalue,
    };
    axios
      .put(`${url.url}/api/updateclass/${this.state.newclsid}`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK') {
          Swal.fire({
            title: 'Sucess',
            text: 'Venue class value updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  deleteclassvalue(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deleteclass/${this.state.venuebyid[e].id}`, {})
          .then(function (response) {})

          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });
  }
  backhome1() {
    var self = this;
    //sessionStorage.removeItem("cvenues")
    //sessionStorage.setItem("path","/venues");
    window.location.replace('/venuesevent');
  }

  render() {
    //sessionStorage.setItem("path","/venueclasses");

    return (
      <div>
        <div className='content'>
          <Row>
            <Col md='8'>
              <button type='button' data-toggle='modal' data-target='#addclassvalues' class='btn btn-danger btn-round'>
                New Class value
              </button>{' '}
              <button
                type='button'
                data-toggle='modal'
                data-target='#addvenueclass'
                class='btn btn-danger btn-round'
                onClick={this.backhome1}
              >
                Back
              </button>
              <Card>
                <CardHeader>
                  <h5 className='title'>Class Values</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div class='form-group row'>
                      <div class='col-xs-5'>
                        <CardHeader>
                          <p>Search</p>
                          <input class='form-control' id='inputsm' placeholder='Search..' type='text' />
                        </CardHeader>
                      </div>
                    </div>
                    <Table id='my' responsive>
                      <thead>
                        <tr>
                          <th className='text-center'>#</th>
                          <th>Name</th>

                          <th class='disabled-sorting text-right'>Actions</th>
                        </tr>
                      </thead>
                      <tbody id='myTable'>
                        {this.state.venuebyid.map((question, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{question.name}</td>
                            <td class='td-actions text-right'>
                              <button
                                type='button'
                                rel='tooltip'
                                class='btn btn-success btn-sm btn-icon'
                                onClick={() => this.venueclassvalueedit(index)}
                                data-toggle='modal'
                                data-target='#editclassvalues'
                              >
                                <i class='now-ui-icons ui-2_settings-90'></i>
                              </button>
                              {` `}
                              <button
                                type='button'
                                rel='tooltip'
                                class='btn btn-danger btn-sm btn-icon'
                                onClick={() => this.deleteclassvalue(index)}
                              >
                                <i class='now-ui-icons ui-1_simple-remove'></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        {/* venue class value add */}
        <div class='modal fade' id='addclassvalues' tabindex='-1' role='dialog' aria-hidden='true'>
          <div class='modal-dialog '>
            <div class='modal-content'>
              <div class='modal-header '>
                <h5 class='modal-title ' id='loginmodalLabel'>
                  Add Venue class{' '}
                </h5>
                <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <form>
                <div class='modal-body'></div>
                <div class='form-group col-md-8'>
                  <label for='inputEmail4'>Name *</label>
                  <input
                    type='text'
                    class='form-control'
                    name='newclassvalue'
                    placeholder='please enter name'
                    onChange={this.handleChange}
                  />
                </div>
                <center>
                  <button type='submit' onClick={this.createclassvalue} data-dismiss='modal' class='btn btn-danger'>
                    Create class value
                  </button>
                </center>
              </form>
            </div>
          </div>
        </div>

        {/* edit class value */}
        <div class='modal fade' id='editclassvalues' tabindex='-1' role='dialog' aria-hidden='true'>
          <div class='modal-dialog '>
            <div class='modal-content'>
              <div class='modal-header '>
                <h5 class='modal-title ' id='loginmodalLabel'>
                  Edit Class Value
                </h5>
                <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <form>
                <div class='modal-body'></div>
                <div class='form-group col-md-8'>
                  <label for='inputEmail4'>Name *</label>
                  <input
                    type='text'
                    class='form-control'
                    name='newclsvalue'
                    value={this.state.newclsvalue}
                    placeholder='please enter name'
                    onChange={this.handleChange}
                  />
                </div>
                <center>
                  <button type='submit' onClick={this.updateclassvalue} data-dismiss='modal' class='btn btn-danger'>
                    Update class value
                  </button>
                </center>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FullScreenMap2;
