import React from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';

import Swal from 'sweetalert2';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import Pagination from 'react-js-pagination';

import * as XLSX from 'xlsx';
import Papa from 'papaparse';

import Loader from 'react-loader-spinner';
const url = window.globalConfig;
class Implementfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      relaydatas: [{}],
      managers: [{}],
      meetname: [{}],
      assignedmanager: '',
      name: '',
      defaultoption: '',
      managerid: '',
      agename: '',
      pointname: '',
      relayname: '',
      relaypointdata: [{}],
      classvaluedata: [{}],
      offset: 0,
      tableData: [],
      activePage: 1,
      itemsCountPerPage: 1,
      totalItemsCount: 1,
      pageRangeDisplayed: 5,
      loader: false,
      implementdatas: [{}],
      implementpointdata: [{}],
      data: [],
      file: null,
    };
    this.back = this.back.bind(this);
    this.editrecord = this.editrecord.bind(this);
    //  this.handleChange=this.handleChange.bind(this);
    this.optionchange = this.optionchange.bind(this);
    this.updaterecord = this.updaterecord.bind(this);
    this.deleterecord = this.deleterecord.bind(this);
    this.addrecord = this.addrecord.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }

  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    var self = this;
    axios.get(`${url.url}/api/getAllimplements`, {}).then(function (response) {
      self.setState({
        implementdatas: response.data,
      });
    });

    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    document.getElementById('homelink').style.display = 'block';
  }

  optionchange(e) {
    var self = this;
    self.setState({
      [e.target.name]: e.target.value,
    });
  }

  //Add Implement
  addrecord(e) {
    const datatosend = {
      implement_number: this.state.implementnumber,
      implement_weight: this.state.implementweight,
      exact_weight: this.state.exactweight,
      diameter: this.state.diameter != null ? this.state.diameter : '',
      length: this.state.length != null ? this.state.length : '',
    };
    axios
      .post(`${url.url}/api/createimplementrecord`, datatosend, {})
      .then(function (response) {
        if (response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'Implement Created successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  editrecord(e) {
    var self = this;
    self.setState({
      implementpointdata: this.state.implementdatas[e],
      implementnumberdata: this.state.implementdatas[e].implement_number,
      implementweightdata: this.state.implementdatas[e].implement_weight,
      exactweightdata: this.state.implementdatas[e].exact_weight,
      diameterdata: this.state.implementdatas[e].diameter,
      lengthdata: this.state.implementdatas[e].length,
    });
  }

  updaterecord() {
    const datatosend = {
      implement_number: this.state.implementnumberdata,
      implement_weight: this.state.implementweightdata,
      exact_weight: this.state.exactweightdata,
      diameter: this.state.diameterdata,
      length: this.state.lengthdata,
    };

    axios
      .put(`${url.url}/api/updateimplement/${this.state.implementpointdata.id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Implement Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  // edit meet------------------------------------------------------------------------------------------------

  deleterecord(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deleteimplement/${this.state.implementdatas[e].id}`, {})
          .then(function (response) {})

          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });
  }

  back() {
    window.location.replace('/venuemainpage?event=' + sessionStorage.getItem('event'));
  }

  clearallrecords = () => {
    axios.delete(`${url.url}/api/deleteallimplement`, {}).then(function (response) {
      if (response.status) {
        Swal.fire({
          title: 'Success',
          text: 'All Data will be deleted!',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        }).then(function (response) {
          window.location.reload();
        });
      }
    });
  };

  processData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }
    // prepare columns list from headers
    const columns = headers.map((c) => ({
      name: c,
      selector: c,
    }));
    this.setState({ data: list, columns: columns });
  };

  handleFileUpload = (e) => {
    const file = e.target.files[0];
    this.setState({ file: file });

    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      this.processData(data);
    };
    reader.readAsBinaryString(file);
  };

  async handleUpload() {
    var temp = [];
    const resgister = async (data) => {
      const new_data = {
        implement_number: data.implement_number,
        implement_weight: data.implement_weight,
        exact_weight: data.exact_weight,
        diameter: data.diameter,
        length: data.length,
      };

      temp.push(new_data);
    };
    let file = this.state.file;
    Papa.parse(file, {
      header: true,
      complete: function (results) {
        let datas = results.data;
        datas.map((data) => resgister(data));
      },
    });

    setTimeout(async function () {
      temp.pop();
      await axios
        .post(`${url.url}/api/createimplementimport`, temp, {
          method: 'POST',
          mode: 'cors',
          headers: { 'Access-Control-Allow-Origin': true },
        })
        .then(function (response) {
          if (response.status == 201) {
            Swal.fire({
              title: 'Success',
              text: 'Status updated successfully',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            }).then(function (response) {
              window.location.reload();
            });
          }
        })
        .catch((err) => console.log('err', err));
    }, 3000);
  }

  render() {
    //sessionStorage.setItem("path","/meets");
    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader
                type='ThreeDots'
                color='#00BFFF'
                height={70}
                width={70}
                // timeout={3000} //3 secs
              />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div className='content'>
              <Row>
                <Col md='8'>
                  <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                    Back
                  </button>
                  <button type='button' data-toggle='modal' data-target='#newmeet' class='btn btn-danger btn-round'>
                    Add Implement
                  </button>
                  <button type='button' class='btn btn-danger btn-round' onClick={this.clearallrecords}>
                    Clear All Records
                  </button>
                  <Card>
                    <CardHeader>
                      <h5 className='title'>Implements</h5>
                    </CardHeader>

                    <CardBody>
                      <Form>
                        <div class='form-group row'>
                          <div class='col-xs-5'>
                            <CardHeader>
                              <p>Search</p>
                              <input class='form-control' id='inputsm' placeholder='Search..' type='text' />
                              <div class='records records_1s records1'>
                                <input
                                  type='file'
                                  name='file'
                                  onChange={(e) => this.handleFileUpload(e)}
                                  style={{ display: 'block' }}
                                  // data-toggle="modal"
                                  // data-target="#meetedit"
                                />
                                <button class='btn btn-success' type='button' onClick={this.handleUpload}>
                                  Import
                                </button>
                              </div>
                            </CardHeader>
                          </div>
                        </div>

                        {/* table --------------------------------------------------- */}
                        <Table id='my' responsive>
                          <thead>
                            <tr>
                              <th className='text-center'>#</th>
                              <th>Implement Number</th>
                              <th>Implement Weight</th>
                              <th>Exact Weight</th>
                              <th>Diameter</th>
                              <th>Length</th>
                              <th class='disabled-sorting text-right'>Actions</th>
                            </tr>
                          </thead>
                          <tbody id='myTable'>
                            {this.state.implementdatas.map((question, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{question.implement_number}</td>
                                <td>{question.implement_weight}</td>
                                <td>{question.exact_weight}</td>
                                <td>{question.diameter}</td>
                                <td>{question.length}</td>
                                <td class='td-actions text-right'>
                                  <button
                                    type='button'
                                    rel='tooltip'
                                    class='btn btn-success btn-sm btn-icon'
                                    onClick={() => this.editrecord(index)}
                                    data-toggle='modal'
                                    data-target='#meetedit'
                                  >
                                    <i class='now-ui-icons ui-2_settings-90'></i>
                                  </button>
                                  {` `}
                                  <button
                                    type='button'
                                    rel='tooltip'
                                    class='btn btn-danger btn-sm btn-icon'
                                    onClick={() => this.deleterecord(index)}
                                  >
                                    <i class='now-ui-icons ui-1_simple-remove'></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>

                        {/* table --------------------------------------------------- */}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* edit meet modal ----------------------------------------------------------------------------------------------------------------- */}
              <div class='modal fade' id='meetedit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Edit Implements Record{' '}
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='inputAddress'>Implement Number</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            placeholder='1234 Main St'
                            name='implementnumber'
                            value={this.state.implementnumberdata}
                            onChange={(e) => this.setState({ implementnumberdata: e.target.value })}
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Implement Weight</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            placeholder='1234 Main St'
                            name='implementweight'
                            value={this.state.implementweightdata}
                            onChange={(e) => this.setState({ implementweightdata: e.target.value })}
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Exact Weight</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            placeholder='1234 Main St'
                            name='exactweight'
                            value={this.state.exactweightdata}
                            onChange={(e) => this.setState({ exactweightdata: e.target.value })}
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Diameter</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            placeholder='1234 Main St'
                            name='diameter'
                            value={this.state.diameterdata}
                            onChange={(e) => this.setState({ diameterdata: e.target.value })}
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Length</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            placeholder='1234 Main St'
                            name='Length'
                            value={this.state.lengthdata}
                            onChange={(e) => this.setState({ lengthdata: e.target.value })}
                            required
                          />
                        </div>

                        <center>
                          <button type='submit' onClick={this.updaterecord} data-dismiss='modal' class='btn btn-danger'>
                            Update
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              {/* add meet------------------------------------------------------------------------------------------------- */}
              <div class='modal fade' id='newmeet' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        New Implement Record
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='inputAddress'>Implement Number</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            // placeholder="1234 Main St"
                            name='implementnumber'
                            onChange={this.optionchange}
                            placeholder='Enter Implement Number'
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Implement Weight</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            // placeholder="1234 Main St"
                            name='implementweight'
                            onChange={this.optionchange}
                            placeholder='Enter Implement Weight'
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Exact Weight</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            // placeholder="1234 Main St"
                            name='exactweight'
                            onChange={this.optionchange}
                            placeholder='Enter Exact Weight'
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Diameter</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            // placeholder="1234 Main St"
                            name='diameter'
                            onChange={this.optionchange}
                            placeholder='Enter Diameter'
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Length</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            // placeholder="1234 Main St"
                            name='length'
                            onChange={this.optionchange}
                            placeholder='Enter Length'
                            required
                          />
                        </div>

                        <center>
                          <button type='submit' onClick={this.addrecord} data-dismiss='modal' class='btn btn-danger'>
                            Create
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Implementfile;
