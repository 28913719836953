import React from 'react';

// reactstrap components
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
// core components

import axios from 'axios';
import Swal from 'sweetalert2';
// import { Redirect } from "react-router";
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';

import { Empty } from 'antd';
const url = window.globalConfig;
var c;
var d;
var f;
var g;
var x;
var qualclassname;
class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qdatas: [{}],
      qualdatas: [{}],
      eventsdropdown: [{}],
      classdropdown: [{}],
      venueclassid: [{}],
      agediv: [{}],
      venue: '',
      gender: '',
      qb: '',
      implement: '',
      qclass: '',
      qstd: '',
      adivision: '',
      event: '',
      vclass: '',
      clsvalue: '',
      agechange: '',
      classvalue: [{}],
      venueid: '',
      vclassid: '',
      defaultgender: '',
      defaultvenuename: '',
      defaulteventname: '',
      defqb: '',
      defqstd: '',
      defimpl: '',
      defqcls: '',
      defagebreak: '',
      defagediv: '',
      defid: '',
      defaultvenueclass: '',
      defaultclassvalue: '',
      classvaluedata: [],
      trackview: false,
      qstddata: {},
      showallstd: false,
      agedata: [],
      geteventids: '',
    };
    this.optionchange = this.optionchange.bind(this);
    this.optionchange1 = this.optionchange1.bind(this);
    this.optionchange2 = this.optionchange2.bind(this);
    this.optionchange3 = this.optionchange3.bind(this);
    this.optionchange4 = this.optionchange4.bind(this);
    this.createqstandard = this.createqstandard.bind(this);
    this.venueclasschange = this.venueclasschange.bind(this);
    this.delete = this.delete.bind(this);
    this.edit = this.edit.bind(this);
    this.update = this.update.bind(this);
    //this.classdata = this.classdata.bind(this);
    this.trackstd = this.trackstd.bind(this);
    this.findstandard = this.findstandard.bind(this);
    this.showallstd = this.showallstd.bind(this);
  }
  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    var self = this;
    axios.get(`${url.url}/api/getallqstd`, {}).then(function (response) {
      self.setState({
        qdatas: response.data,
      });
    });
    axios.get(`${url.url}/api/venues`, {}).then(function (response) {
      self.setState({
        qualdatas: response.data.data,
      });
    });
    axios.get(`${url.url}/api/agedivisions`, {}).then(function (response) {
      self.setState({
        agediv: response.data.data,
      });
    });
  }

  createqstandard(e) {
    e.preventDefault();
    x =
      this.state.evntd +
      this.state.defaultclassvalue.split('-')[0] +
      this.state.dagediv +
      this.state.gender.split('')[0] +
      this.state.gender.split('')[1] +
      this.state.evnt;
    const datatosend = {
      venue_name: this.state.venueid,
      Class_value: this.state.defaultclassvalue.split('-')[1],
      Age_division: parseInt(this.state.defagediv),
      Gender: this.state.gender,
      event_name: parseInt(this.state.defaulteventname.split('-')[1]),
      B_standard: this.state.qb,
      Qualifying_standard: this.state.qstd,
      Implement: this.state.implement,
      qualclass: x,
      Age_group: this.state.adivision,
      DSO: this.state.dso,
      Type_pool: this.state.typepool,
    };
    axios
      .post(`${url.url}/api/createqualifyingstandard/`, datatosend, {})
      .then(function (response) {
        if (response.status == 201) {
          Swal.fire({
            title: 'Success',
            text: 'Qualifying standard created successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong Please check the fields',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  delete(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deletequalifyingstandard/${this.state.qdatas[e].id}`, {})
          .then(function (response) {})

          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });

    // delete-----------------------------------------------------------------------------------------
  }

  edit(e) {
    var elid;
    var xy;
    var yx;
    var classid = [];
    var self = this;
    var array = this.state.qualdatas;
    this.state.qualdatas.map((key, index) => {
      if (key.name == this.state.qdatas[e].venue.name) {
        var et = key.name + '-' + index + '-' + key.id;
        self.setState({
          defaultvenuename: et,
        });
        xy = et.split('-');
        yx = xy[2];
        this.optionchange(et);
        key.events.map((element, index2) => {
          if (element.id == this.state.qdatas[e].event.id) {
            var te = element.name + '-' + element.id + '-' + element.venue_id;
            self.setState({
              defaulteventname: te,
            });
            this.optionchange1(te);
            var d = te.split('-')[0].split(' ').join('');
            self.setState({
              evnt: d,
            });
          }
        });
        this.state.agediv.map((keys, indx) => {
          if (keys.id == this.state.qdatas[e].age_division_id) {
            var fen = keys.id + '-' + indx;
            self.setState({
              defagediv: fen,
            });
            var t = fen.split('-')[1];
            self.setState({ dagediv: this.state.agediv[t].name });
          }
        });
        var class_value = this.state.qdatas[e].class.name;
        axios
          .get(`${url.url}/api/getclassvalue/${yx}`, {})
          .then(function (response) {
            self.setState({
              classvaluedata: response.data,
            });
            classid = response.data;
            classid.map((element2, index4) => {
              if (element2.name == class_value) {
                var len = element2.name + '-' + element2.id;
                self.setState({
                  defaultclassvalue: len,
                });
              }
            });
          })
          .catch(function (error) {
            self.setState({
              venueclassid: [{}],
            });
          });
        return;
      }
    });
    self.setState({
      defaultgender: this.state.qdatas[e].gender,
      defqb: this.state.qdatas[e].qual_b_standard,
      defqstd: this.state.qdatas[e].qual_standard,
      defimpl: this.state.qdatas[e].implement,
      defqcls: this.state.qdatas[e].qualclass,
      defagebreak: this.state.qdatas[e].age_division_break,
      defid: parseInt(this.state.qdatas[e].id),
    });
  }
  optionchange(e) {
    var i = this.state.qualdatas[parseInt(e.split('-')[1])];
    var j = this.state.qualdatas[parseInt(e.split('-')[1])];
    var k = e.split('-')[0];
    var self = this;
    self.setState({
      defaultvenuename: e,
      eventsdropdown: i,
      classdropdown: j,
      venueid: i.id,
    });
    var c = e.split('-')[0].toString().split('');
    var g = c[0] + c[1];

    var x = e.split('-');
    var m = x[2];
    self.setState({
      evntd: g,
    });
  }

  // classdata(geteventids) {

  // }

  optionchange1(e) {
    var self = this;
    var d = e.split('-')[0].split(' ').join('');
    var geteventids = e.split('-')[1];
    self.setState({
      evnt: d,
      geteventids: geteventids,
    });
    self.setState({
      defaulteventname: e,
      //[e.target.name]: e
    });
    const dummy = (data) => {
      this.setState({
        classvaluedata: data,
      });
    };
    axios.get(`${url.url}/api/geteventid/${geteventids}`, {}).then(function (response) {
      dummy(response.data);
    });
  }

  optionchanges(e) {
    var self = this;
    self.setState({
      defaulteventnames: e,
    });
  }
  optionchange3(e) {
    var self = this;
    self.setState({
      defagediv: e,
    });
  }

  optionchange4(e) {
    var self = this;
    self.setState({
      defaultgender: e.target.value,
      [e.target.name]: e.target.value,
    });
  }

  venueclasschange(e) {
    var i = e.split('-');
    var l = parseInt(i[0]);
    var k = parseInt(i[1]);
    var self = this;
    self.setState({
      defaultvenueclass: e,
    });

    self.setState({
      clasven: this.state.classdropdown.venueclasses[k],
      venclid: this.state.classdropdown.venueclasses[k].id,
    });
    axios
      .get(`${url.url}/api/getvenueclassid/${this.state.classdropdown.venueclasses[k].id}`, {})
      .then(function (response) {
        self.setState({
          venueclassid: response.data,
        });
      })
      .catch(function (error) {
        self.setState({
          venueclassid: [{}],
        });
      });
  }
  optionchange2(e) {
    var self = this;
    var classsplit = e.split('-');
    var classindex = classsplit[1];
    self.setState({
      defaultclassvalue: e,
      classindex: classindex,
    });
    const dummy = (data) => {
      this.setState({ agedata: data });
    };
    axios.get(`${url.url}/api/getclasseventid/${this.state.geteventids}/${classindex}`, {}).then(function (response) {
      dummy(response.data);
    });
  }

  update(e) {
    e.preventDefault();
    x =
      this.state.evntd +
      this.state.defaultclassvalue.split('-')[0] +
      this.state.dagediv +
      this.state.defaultgender.split('')[0] +
      this.state.defaultgender.split('')[1] +
      this.state.evnt;
    const datatosend = {
      venue_name: this.state.venueid,
      Class_value: this.state.defaultclassvalue.split('-')[1],
      Age_division: this.state.defagediv,
      Gender: this.state.defaultgender,
      event_name: parseInt(this.state.defaulteventname.split('-')[1]),
      B_standard: this.state.defqb,
      Qualifying_standard: this.state.defqstd,
      Implement: this.state.defimpl,
      qualclass: x,
      Age_group: 'null',
      DSO: this.state.dso,
      Type_pool: this.state.typepool,
    };
    axios
      .put(`${url.url}/api/updatequalifyingstandard/${this.state.defid}`, datatosend, {})

      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            title: 'Success',
            text: 'Updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  findstandard() {
    var self = this;
    const datatosend = {
      venue_name: this.state.defaultvenuename.split('-')[0],
      event_name: this.state.defaulteventname.split('-')[0],
      Class_values: this.state.defaultclassvalue.split('-')[0],
      Age_division: this.state.defagediv,
      Gender: this.state.gender,
    };
    axios.post(`${url.url}/api/getparticularqualstd/`, datatosend, {}).then(function (response) {
      self.setState({
        qstddata: response.data.data[0],
        eventsdropdown: [{}],
        classdropdown: [{}],
        venueclassid: [{}],
        venue: '',
        gender: '',
        qb: '',
        implement: '',
        qclass: '',
        qstd: '',
        adivision: '',
        event: '',
        vclass: '',
        clsvalue: '',
        agechange: '',
        classvalue: [{}],
        venueid: '',
        vclassid: '',
        defaultgender: 'Please Select',
        defaultvenuename: '',
        defaulteventname: '',
        defqb: '',
        defqstd: '',
        defimpl: '',
        defqcls: '',
        defagebreak: '',
        defagediv: '',
        defid: '',
        defaultvenueclass: '',
        defaultclassvalue: '',
        classvaluedata: [],
        trackview: false,
        showallstd: false,
        agedata: [],
        geteventids: '',
      });
    });
  }

  trackstd() {
    var self = this;

    self.setState({
      trackview: true,
    });
  }

  showallstd() {
    var self = this;

    self.setState({
      showallstd: true,
    });
  }

  render() {
    var qualclassname =
      this.state.evntd +
      this.state.defaultclassvalue.split('-')[0] +
      this.state.dagediv +
      this.state.gender.split('')[0] +
      this.state.gender.split('')[1] +
      this.state.evnt;

    if (this.state.trackview) {
      return <Redirect to='/trackstandard' />;
    }

    if (this.state.showallstd) {
      return <Redirect to='/showallQualifyingstandard' />;
    }

    return (
      <>
        <div className='content'>
          <Row>
            <Col md='8'>
              {/* <button type="button" data-toggle="modal" data-target="#addqual" class="btn btn-danger btn-round">New Qualifying standards</button> */}
              <button type='button' class='btn btn-danger btn-round' onClick={this.showallstd}>
                Show as List
              </button>
              {/* <button type="button"  class="btn btn-danger btn-round" onClick={this.trackstd}>Track Standards</button>
<button type="button"  class="btn btn-danger btn-round">Field Standards</button>
<button type="button"  class="btn btn-danger btn-round">Swimming Standards</button>
<button type="button"  class="btn btn-danger btn-round">Archery Standards</button> */}
              <Card>
                <CardHeader>
                  <h5 className='title'>Qualifying Standards</h5>
                </CardHeader>

                <div class='modal-body'>
                  <form>
                    <div class='form-row'>
                      <div class='form-group col-md-6'>
                        <label for='inputCity'>Venue *</label>
                        <select
                          class='form-control'
                          id='exampleFormControlSelect1'
                          name='venue'
                          onChange={(e) => this.optionchange(e.target.value)}
                          required
                        >
                          <option>Please Select</option>
                          {this.state.qualdatas.map((qevent, index) => (
                            <option value={qevent.name + '-' + index + '-' + qevent.id}>{qevent.name}</option>
                          ))}
                        </select>
                      </div>

                      <div class='form-group col-md-6'>
                        <label>Event *</label>
                        <select
                          class='form-control'
                          id='exampleFormControlSelect1'
                          name='event'
                          onChange={(e) => this.optionchange1(e.target.value)}
                          required
                        >
                          <option>Please Select</option>
                          {Object.keys(this.state.eventsdropdown).length == 1 ? (
                            <option></option>
                          ) : (
                            <>
                              {this.state.eventsdropdown.events.map((edrop, index) => (
                                <option value={edrop.name + '-' + edrop.id + '-' + edrop.venue_id}>{edrop.name}</option>
                              ))}
                            </>
                          )}
                        </select>
                      </div>
                    </div>

                    <div class='form-row'>
                      <div class='form-group col-md-4'>
                        <label for='inputCity'>Class Value *</label>
                        <select
                          class='form-control'
                          id='exampleFormControlSelect1'
                          name='clsvalue'
                          onChange={(e) => this.optionchange2(e.target.value)}
                          required
                        >
                          <option>Please Select</option>
                          {Object.keys(this.state.classvaluedata).length == 0 ? (
                            <option></option>
                          ) : (
                            <>
                              {this.state.classvaluedata.map((cvalue, index) => (
                                <option value={cvalue.name + '-' + cvalue.id}>{cvalue.name}</option>
                              ))}
                            </>
                          )}
                        </select>
                      </div>
                      <div class='form-group col-md-4'>
                        <label>Age Division *</label>
                        <select
                          class='form-control'
                          id='exampleFormControlSelect1'
                          name='agechange'
                          onChange={(e) => this.optionchange3(e.target.value)}
                          required
                        >
                          <option>Please Select</option>
                          {Object.keys(this.state.agedata).length == 1 ? (
                            <option></option>
                          ) : (
                            <>
                              {this.state.agedata.map((agedivision, index) => (
                                <option value={agedivision.name}>{agedivision.name}</option>
                              ))}
                            </>
                          )}
                        </select>
                      </div>
                      <div class='form-group col-md-4'>
                        <label>Gender *</label>
                        <select
                          class='form-control'
                          id='exampleFormControlSelect1'
                          name='gender'
                          value={this.state.defaultgender}
                          onChange={this.optionchange4}
                          required
                        >
                          <option>Please Select</option>
                          <option value='Male'>Male</option>
                          <option value='Female'>Female</option>
                        </select>
                      </div>
                    </div>

                    <div class='form-group findbttn'>
                      {/* <center><button type="submit" onClick={this.createqstandard} data-dismiss="modal" class="btn btn-danger" >Create Qualifying Standards</button></center> */}
                      <button type='button' id='findbtn' onClick={this.findstandard} class='btn btn-success'>
                        Find
                      </button>
                    </div>
                  </form>
                  {Object.keys(this.state.qstddata).length == 0 ? (
                    ''
                  ) : (
                    <>
                      <Table id='my mytable2' className='mytable1' responsive>
                        {Object.keys(this.state.qstddata).length == 0 ? (
                          <Empty />
                        ) : (
                          <>
                            <thead>
                              <tr>
                                {/* <th className="text-center">#</th> */}
                                <th>Venues</th>
                                <th>Event</th>
                                <th>Qualifying Standard</th>
                                <th>B Standard</th>
                                <th>Class Values</th>
                                <th>Age Division</th>
                                <th>Gender</th>
                                <th>DSO</th>
                                <th>Implement</th>
                                <th>Type Pool</th>
                                {/* <th>Age Group</th>
                                <th>QualClass</th> */}
                                {/* <th class="disabled-sorting text-right">Actions</th> */}
                              </tr>
                            </thead>
                            <tbody id='myTable'>
                              <tr>
                                {/* <td>{index+1}</td> */}
                                <td>{this.state.qstddata.venue_name}</td>
                                <td>{this.state.qstddata.event_name}</td>
                                <td>{this.state.qstddata.qualifying_standards}</td>
                                <td>{this.state.qstddata.B_standard}</td>
                                <td>{this.state.qstddata.Class_values}</td>
                                <td>{this.state.qstddata.Age_division}</td>
                                <td>{this.state.qstddata.Gender}</td>
                                <td>{this.state.qstddata.DSO}</td>
                                <td>{this.state.qstddata.Implement}</td>
                                <td>{this.state.qstddata.Type_pool}</td>
                                {/* <td>{this.state.qstddata.Age_group}</td>
                                <td>{this.state.qstddata.Qual_class}</td> */}
                              </tr>
                            </tbody>
                          </>
                        )}
                      </Table>
                    </>
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Notifications;
