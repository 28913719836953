import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import axios from 'axios';
import Papa from 'papaparse';
import './compound.css';
import Swal from 'sweetalert2';
import DataTable from 'react-data-table-component';
import * as XLSX from 'xlsx';
import $ from 'jquery';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
let testEvents = [];
class Registereds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      csvfile: undefined,
      file: null,
      ruser: true,
      view: [],
      columns: [],
      showprint: false,
      loading: true,
      preloader: 'none',
      adaptivetennis_age: '',
      teamsports_age: '',
    };
    this.csvLinkEl = React.createRef();
    this.handleFile = this.handleFile.bind(this);
    this.updateData = this.updateData.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.calculate_age = this.calculate_age.bind(this);
  }

  processData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }

    // prepare columns list from headers
    const columns = headers.map((c) => ({
      name: c,
      selector: c,
    }));
    this.setState({ data: list, columns: columns });
  };

  // handle file upload
  handleFileUpload = (e) => {
    const file = e.target.files[0];
    this.setState({ file: file });
    $('#meetedit').addClass('show');
    $('#meetedit').css('display', 'block');
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      this.processData(data);
    };
    reader.readAsBinaryString(file);
  };
  handleFile(e) {
    let file = e.target.files[0];
    this.setState({ file: file });
  }
  calculate_age = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = 12 - birthDate.getMonth();
    if (m < 0 || (m === 0 && 31 < birthDate.getDate())) {
      age_now--;
    }
    return age_now;
  };
  async handleUpload() {
    this.setState({
      preloader: 'block',
    });

    var temp = [];
    const resgister = async (data) => {
      var age_latest = { age_latest: this.calculate_age(data.DOB) };
      var x = JSON.stringify(age_latest);
      var y = x.split(':');
      var z = y[1].split('}');
      var track = [];
      var field = [];
      var swim = [];
      var other = [];
      var otherclass = [];
      var swimclass = [];
      var trackeventmarks = [];
      var fieldeventmarks = [];
      var swimeventmarks = [];
      var tabletenniseventmarks = [];
      var adaptivetenniseventmarks = [];
      var othereventeventmarks = [];
      var weightlifteventmarks = [];
      var otherevent = [];
      var tabletennis = [];
      var teamsports = [];
      var adaptivetennis = [];
      var powerlifting = [];
      let track_age = [];
      let field_age = [];
      let swim_age = [];
      let othervenues_age = [];
      let tabletennis_age = [];
      let adaptivetennis_age = [];
      let archery_age = [];
      let teamsports_age = [];
      let otherven_age = [];
      let Boccia_age = [];
      let Cycling_age = [];
      let AirRifle_age = [];
      var airrifle_age = [];
      let boccia_age = [];
      let cycling_age = [];
      let paratriathlon100_age = [];
      let paratriathlon200_age = [];
      let Paratriathlon100_age = [];
      let Paratriathlon200_age = [];
      let paratriathlon500_age = [];
      let Paratriathlon500_age = [];

      if (data.swbaseclass != '') {
        swimclass.push(data.swbaseclass);
      }
      if (data.swbreastclass != '') {
        swimclass.push(data.swbreastclass);
      }
      if (data.swmedleyclass != '') {
        swimclass.push(data.swmedleyclass);
      }
      for (var i = 1; i <= 7; i++) {
        if (data['trevent' + i]) {
          track.push(data['trevent' + i]);
        }
      }
      for (var j = 1; j <= 4; j++) {
        if (data['fievent' + j]) {
          field.push(data['fievent' + j]);
        }
      }
      for (var k = 1; k <= 10; k++) {
        if (data['swevent' + k]) {
          swim.push(data['swevent' + k]);
        }
      }
      for (var pp = 1; pp <= 2; pp++) {
        if (data['ppevent' + pp]) {
          powerlifting.push(data['ppevent' + pp]);
        }
      }
      for (var tt = 1; tt <= 2; tt++) {
        if (data['ttevent' + tt]) {
          tabletennis.push(data['ttevent' + tt]);
        }
      }
      for (var tse = 1; tse <= 3; tse++) {
        if (data['tsevent' + tse]) {
          teamsports.push(data['tsevent' + tse]);
        }
      }
      for (var adt = 1; adt <= 2; adt++) {
        if (data['atevent' + adt]) {
          adaptivetennis.push(data['atevent' + adt]);
        }
      }
      for (var l = 1; l <= 3; l++) {
        if (data['otvenue' + l]) {
          other.push(data['otvenue' + l]);
        }
      }
      for (var m = 1; m <= 7; m++) {
        if (data['trev' + m + 'mark']) {
          trackeventmarks.push(data['trev' + m + 'mark']);
        }
      }
      for (var n = 1; n <= 6; n++) {
        if (data['fiev' + n + 'mark']) {
          fieldeventmarks.push(data['fiev' + n + 'mark']);
        }
      }
      for (var o = 1; o <= 10; o++) {
        if (data['swev' + o + 'mark']) {
          swimeventmarks.push(data['swev' + o + 'mark']);
        }
      }
      for (var p = 1; p <= 10; p++) {
        if (data['ppev' + p + 'mark']) {
          weightlifteventmarks.push(data['ppev' + p + 'mark']);
        }
      }
      for (var t = 1; t <= 10; t++) {
        if (data['ttev' + t + 'mark']) {
          tabletenniseventmarks.push(data['ttev' + t + 'mark']);
        }
      }
      for (var a = 1; a <= 10; a++) {
        if (data['atev' + a + 'mark']) {
          adaptivetenniseventmarks.push(data['atev' + a + 'mark']);
        }
      }
      for (var oe = 1; oe <= 5; oe++) {
        for (var oes = 1; oes <= 5; oes++) {
          if (data['otv' + oe + 'ev' + oes + 'mark'] != '') {
            othereventeventmarks.push(data['otv' + oe + 'ev' + oes + 'mark']);
          }
        }
      }
      if (tabletennis.length > 0) {
        if (z[0] >= 60) {
          tabletennis_age = 'M60';
        }
        if (z[0] >= 50 && z[0] <= 59) {
          tabletennis_age = 'M50';
        }
        if (z[0] >= 35 && z[0] <= 49) {
          tabletennis_age = 'M35';
        }
        if (z[0] >= 23 && z[0] <= 34) {
          tabletennis_age = 'Adults';
        }
        if (z[0] >= 20 && z[0] <= 22) {
          tabletennis_age = 'U23';
        }
        if (z[0] >= 17 && z[0] <= 19) {
          tabletennis_age = 'U20';
        }
        if (z[0] >= 14 && z[0] <= 16) {
          tabletennis_age = 'U17';
        }
        if (z[0] >= 11 && z[0] <= 13) {
          tabletennis_age = 'U14';
        }
        if (z[0] >= 7 && z[0] <= 10) {
          tabletennis_age = 'U11';
        }
      }

      if (z[0] >= 7 && teamsports.length > 0) {
        teamsports_age = 'Open';
      }
      if (adaptivetennis.length > 0) {
        if (z[0] >= 7 && z[0] >= 8) {
          adaptivetennis_age = '8s';
        }
        if (z[0] >= 9 && z[0] >= 10) {
          adaptivetennis_age = '10s';
        }
        if (z[0] >= 11 && z[0] >= 12) {
          adaptivetennis_age = '12s';
        }
        if (z[0] >= 13 && z[0] >= 14) {
          adaptivetennis_age = '14s';
        }
        if (z[0] >= 15 && z[0] >= 16) {
          adaptivetennis_age = '16s';
        }
        if (z[0] >= 17 && z[0] >= 18) {
          adaptivetennis_age = '18s';
        }
        if (z[0] >= 19) {
          adaptivetennis_age = 'Open';
        }
      }

      if (z[0] >= 12 && z[0] <= 14) {
        AirRifle_age = 'Sub Junior';
      }
      if (z[0] >= 15 && z[0] <= 17) {
        AirRifle_age = 'Junior';
      }
      if (z[0] >= 18 && z[0] <= 20) {
        AirRifle_age = 'Intermediate';
      }
      if (z[0] >= 21 && z[0] <= 64) {
        AirRifle_age = 'Adult';
      }
      if (z[0] >= 65) {
        AirRifle_age = 'Senior';
      }
      if (z[0] >= 7 && z[0] <= 15) {
        Boccia_age = 'Youth';
        Cycling_age = 'Youth';
        Paratriathlon100_age = 'Youth';
        Paratriathlon200_age = 'Youth';
        Paratriathlon500_age = 'Youth';
      }
      if (z[0] >= 16 && z[0] <= 19) {
        Boccia_age = 'Junior ';
        Cycling_age = 'Junior';
        Paratriathlon100_age = 'Junior';
        Paratriathlon200_age = 'Junior';
        Paratriathlon500_age = 'Junior';
      }
      if (z[0] >= 20) {
        Boccia_age = 'Adult ';
        Cycling_age = 'Adult';
        Paratriathlon100_age = 'Adult';
        Paratriathlon200_age = 'Adult';
        Paratriathlon500_age = 'Adult';
      }

      for (var q = 1; q <= 5; q++) {
        for (var es = 1; es <= 5; es++) {
          if (data['otv' + q + 'event' + es]?.trim()) {
            otherevent.push(data['otv' + q + 'event' + es]);
          }
        }
      }
      for (var qqq = 1; qqq <= 3; qqq++) {
        for (var esss = 1; esss <= 3; esss++) {
          if (data['otv' + qqq + 'event' + esss] != '') {
            otherclass.push(data['otclass' + qqq]);
          }
        }
      }
      var xyz;
      for (var qq = 1; qq <= 3; qq++) {
        for (var os = 1; os <= 3; os++) {
          if (data['otv' + qq + 'event' + os]) {
            xyz = data['otv' + qq + 'event' + os].replace(/\s+/g, '');
            var tim = eval(xyz + '_age');
            if (data['otv' + qq + 'event' + os] === 'Air Rifle') {
              airrifle_age = tim;
            }
            if (data['otv' + qq + 'event' + os] === 'Boccia') {
              boccia_age = tim;
            }
            if (data['otv' + qq + 'event' + os] === 'Cycling') {
              cycling_age = tim;
            }
            if (data['otv' + qq + 'event' + os] === 'Paratriathlon 100') {
              paratriathlon100_age = tim;
            }
            if (data['otv' + qq + 'event' + os] === 'Paratriathlon 200') {
              paratriathlon200_age = tim;
            }
            if (data['otv' + qq + 'event' + os] === 'Paratriathlon 500') {
              paratriathlon500_age = tim;
            }
          }
        }
      }

      const new_data = {
        firstname: data.firstname,
        lastname: data.lastname,
        gender: data.gender,
        email: data.email,
        dob: data.DOB,
        age: z[0],
        address: data.address1,
        address2: data.address2,
        city: data.city,
        state: data.state,
        zipcode: data.zipcode,
        country: data.country,
        homephone: data.homephone == null ? data.cellphone : data.homephone,
        cellphone: data.homephone == null ? data.cellphone : data.homephone,
        designation: data.designation,
        track_age: track && track.length > 0 ? data.agetrkfld : null,
        field_age: field.length > 0 ? data.agetrkfld : null,
        archery_age: data.agearchery,
        swim_age: data.ageswim,
        weightlifting_age: data.ageweightlifting,
        tabletennis_age: tabletennis_age.toString(),
        team_age: teamsports_age.toString(),
        fencing_age: adaptivetennis_age.toString(),
        airrifle_age: airrifle_age.length === 0 ? null : airrifle_age.toString(),
        boccia_age: boccia_age.length === 0 ? null : boccia_age.toString(),
        cycling_age: cycling_age.length === 0 ? null : cycling_age.toString(),
        paratriathlon100_age: paratriathlon100_age.length === 0 ? null : paratriathlon100_age.toString(),
        paratriathlon200_age: paratriathlon200_age.length === 0 ? null : paratriathlon200_age.toString(),
        paratriathlon500_age: paratriathlon500_age.length === 0 ? null : paratriathlon500_age.toString(),
        team_name: data.teamname,
        headcoach_name: ' ',
        coach_email: ' ',
        coach_homephone: ' ',
        coach_workphone: ' ',
        need_classified: ' ',
        coach_come_meet: ' ',
        classification_site: ' ',
        classified_whom: ' ',
        sanctioned_meet: ' ',
        comments: ' ',
        track_events: track.toString() ? track.toString() : null,
        field_events: field.toString() ? field.toString() : null,
        archery_events: data.arevent ? data.arevent : null,
        swim_events: swim.toString() ? swim.toString() : null,
        weightlift_events: powerlifting.toString() ? powerlifting.toString() : null,
        tabletennis_events: tabletennis.toString() ? tabletennis.toString() : null,
        team_events: teamsports.toString() ? teamsports.toString() : null,
        fencing_events: adaptivetennis.toString() ? adaptivetennis.toString() : null,
        other_events: otherevent.toString() ? otherevent.toString() : null,
        meet_id: sessionStorage.getItem('ids'),
        archery_class_value: data.arclass ? data.arclass : null,
        track_class_value: data.trclass ? data.trclass : null,
        field_class_value: data.fifieldclass ? data.fifieldclass : null,
        swim_class_value: data.swbaseclass ? data.swbaseclass : null,
        swim_breast_class: data.swbreastclass ? data.swbreastclass : null,
        swim_medley_class: data.swmedleyclass ? data.swmedleyclass : null,
        weightlift_class_value: data.ppclass ? data.ppclass : null,
        table_class_value: data.ttclass ? data.ttclass : null,
        team_class_value: ' ',
        fence_class_value: data.atclass ? data.atclass : null,
        other_class_value: otherclass.toString(),
        ipc: data.ipcnumber,
        citizenship: data.citizenship,
        bib_no: ' ',
        track_event_marks: trackeventmarks.toString(),
        field_event_marks: fieldeventmarks.toString(),
        swim_event_marks: swimeventmarks.toString(),
        tabletennis_event_marks: tabletenniseventmarks.toString(),
        adaptivetennis_event_marks: adaptivetenniseventmarks.toString(),
        weightlift_event_marks: weightlifteventmarks.toString(),
        other_event_marks: othereventeventmarks.toString(),
        archery_event_marks: data.arqualmark,
        typebow: data.artypebow,
        bowstand: data.arbowstand,
        team_letter: data.teamletter ? data.teamletter : 'null',
      };
      temp.push(new_data);
    };
    var self = this;
    let file = this.state.file;
    Papa.parse(file, {
      header: true,
      complete: function (results) {
        let datas = results.data;
        datas.map((data) => resgister(data));
      },
    });
    setTimeout(async function () {
      temp.pop();
      await axios
        .post(`${url.url}/api/createusersimport`, temp, {
          method: 'POST',
          mode: 'cors',
          headers: { 'Access-Control-Allow-Origin': true },
        })

        .then(function (response) {
          self.setState({
            preloader: 'none',
          });
          if (response.status == 201) {
            Swal.fire({
              icon: 'success',
              title: 'Status Updated successfully',
              showConfirmButton: false,
              timer: 1000,
            }).then(function (response) {
              window.location.reload();
            });
          }
        })
        .catch((err) => {
          self.setState({
            preloader: 'none',
          });
        });
    }, 3000);
  }
  updateData(result) {
    var data = result.data;
  }
  getUserList = () => {
    return axios
      .get(`${url.url}/api/getregisterusers`, {})
      .then((res) => {
        this.setState({ fdata: res.data });
      })
      .catch((err) => console.log('err', err));
  };
  downloadReport = async () => {
    await this.getUserList();
    this.setState({ datas: this.state.fdata }, () => {
      setTimeout(() => {
        this.csvLinkEl.current.link.click();
      });
    });
  };

  close = () => {
    $('#meetedit').removeClass('show');
    $('#meetedit').css('display', 'none');
  };
  render() {
    const { data } = this.state;

    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div class='record-main'>
              <div class='records records_1s'>
                <input
                  type='file'
                  name='file'
                  onChange={(e) => this.handleFileUpload(e)}
                  style={{ display: 'block' }}
                />
                <button class='btn btn-success' type='button' onClick={this.handleUpload}>
                  Import
                </button>
                <CSVLink filename='Athlete-Registration.csv' data={data} ref={this.csvLinkEl}></CSVLink>
                <>
                  <div
                    className='svg-wrapper'
                    style={{ display: this.state.preloader, minHeight: 'unset', padding: 0 }}
                  >
                    <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
                  </div>
                </>
              </div>
              <div class='export-btn'>
                <button
                  class='btn btn-danger'
                  type='button'
                  value='Export to CSV (Async)'
                  onClick={this.downloadReport}
                >
                  Export
                </button>
              </div>

              <div class='modal fade' id='meetedit' tabindex='-1' role='dialog' aria-hidden='false'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Registration Data
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={this.close}>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <DataTable pagination highlightOnHover columns={this.state.columns} data={data} />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Registereds;
