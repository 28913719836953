import React from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';

import Swal from 'sweetalert2';

import { Select, Input } from 'antd';
import 'antd/dist/antd.css';

import { Empty } from 'antd';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
var x;

class Athletefence extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      venues: [{}],
      venueid: [],
      tname: [],
      tnamesid: '',
      fenceeventdata: [],
      fencesdata: [],
      fenceevents: [],
      fencedatas: [{}],
      fencesets: [{}],
      ranking: '',
      groups: '',
      fenceeventsdata: '',
      allregdatas: [{}],
      classdata: [{}],
      loader: true,
    };
    this.check = this.check.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.edit = this.edit.bind(this);
    this.tinput = this.tinput.bind(this);
    this.createfencereg = this.createfencereg.bind(this);
    this.updatefencereg = this.updatefencereg.bind(this);
    this.editclassage = this.editclassage.bind(this);
    this.onchange1 = this.onchange1.bind(this);
    this.onchange2 = this.onchange2.bind(this);
    this.updateclassage = this.updateclassage.bind(this);
    this.deletefencereg = this.deletefencereg.bind(this);
  }
  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    var self = this;
    var count = 0;
    let indx = sessionStorage.getItem('fenceindex');
    let ids = sessionStorage.getItem('ids');
    axios.get(`${url.url}/api/getfencemeetid/${ids}`, {}).then(function (response) {
      self.setState({
        fencesets: response.data,
      });
    });
    const fenceeventdata = (index) => {
      if (index.fence_events != 'null') {
        var joined = this.state.fenceeventdata.concat(index);
        this.setState({
          fenceeventdata: joined,
          fencesdata: joined[0],
        });
      } else {
        self.setState({
          fencesdata: index,
        });
      }
    };
    //Get Particular user register meet data
    let athleteid = sessionStorage.getItem('athleteid');
    axios
      .get(`${url.url}/api/getathleteregisterid/${athleteid}`, {})
      .then(function (response) {
        self.setState({
          allregdatas: response.data,
        });
        {
          response.data.map((index) => fenceeventdata(index));
        }
        var valdata = self.state.fenceeventdata[0].fencing_events.split(',');
        self.setState({
          fencesdata: self.state.fenceeventdata[0],
          fenceevents: valdata,
        });

        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });

    let venid = sessionStorage.getItem('venuesid');
    axios.get(`${url.url}/api/getclassvalue/${venid}`, {}).then(function (response) {
      self.setState({
        classdata: response.data,
      });
      count++;
      if (count == 3) {
        self.setState({
          loader: false,
        });
      }
    });

    let regid = sessionStorage.getItem('athleteid');
    axios
      .get(`${url.url}/api/getfregisterid/${athleteid}`, {})
      .then(function (response) {
        self.setState({
          fencedatas: response.data,
          fencedatas1: response.data[0],
        });
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });

    //Hide and show manager
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
  }
  check = (e) => {
    var temp = [];
    if (Object.keys(this.state.tname).length != 0) {
      temp = this.state.tname;
    }
    if (!this.state.tname.some((item) => e.target.value === item.name)) {
      temp.push({ name: e.target.value, id: e.target.id });
    }
    this.setState({ tname: temp, tnamesid: e.target.value });
    this.edit(e.target.id);
  };
  handlechange() {
    window.location.replace('/Athletevenues');
  }

  tinput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  edit(e) {
    var self = this;
    self.setState({
      fenceeventsdata: self.state.fenceevents[e],
    });
    var loop = true;

    self.state.fencedatas.forEach((question, index) => {
      if (question.selected_events == self.state.fencesets[e].event_name) {
        self.setState({
          selected_id: question.id,
          selected_events: question.selected_events,
          ranking: question.ranking,
          groups: question.groups,
          btncheck: false,
        });
        loop = false;
      } else if (loop) {
        self.setState({
          selected_id: '',
          selectedevents: self.state.fencesets[e].event_name,
          ranking: '',
          groups: '',
          btncheck: true,
        });
        loop = true;
      }
    });

    if (self.state.fencedatas.length == 0) {
      self.setState({
        selected_id: '',
        selectedevents: self.state.fencesets[e].event_name,
        ranking: '',
        groups: '',
        btncheck: true,
      });
    }
  }

  editclassage() {
    var self = this;
    self.setState({
      fenceclass: self.state.allregdatas[0].fence_class_value,
      fenceage: self.state.allregdatas[0].fencing_age,
    });
  }
  onchange1(e) {
    this.setState({ fenceclass: e.target.value });
  }
  onchange2(e) {
    this.setState({ fenceage: e.target.value });
  }
  updateclassage() {
    const data = {
      fencing_age: this.state.fenceage,
      fence_class_value: this.state.fenceclass,
    };
    let athleteid = sessionStorage.getItem('athleteid');
    axios
      .put(`${url.url}/api/updateclassagevalue/${athleteid}`, data, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  createfencereg(e) {
    var self = this;
    const setvalue = (q, index) => {
      if (self.state.fencedatas.length > index) {
        self.setState({
          selected_events: self.state.fencedatas[index].selected_events,
          ranking: self.state.fencedatas[index].ranking,
        });
      }
    };
    self.state.fenceevents.map((question, index) => {
      setvalue(question, index);
    });
    const datatosend = {
      first_name: this.state.fencesdata.firstname,
      last_name: this.state.fencesdata.lastname,
      age_division: this.state.fencesdata.fencing_age,
      gender: this.state.fencesdata.gender,
      selected_events: this.state.selectedevents,
      ranking: this.state.ranking,
      groups: this.state.groups,
      fence_class_value: this.state.fencesdata.fence_class_value,
      created_by: 1,
      register_id: parseInt(sessionStorage.getItem('athleteid')),
      meet_id: parseInt(sessionStorage.getItem('ids')),
      bib_no: this.state.allregdatas[0].bib_no,
      register_num: this.state.allregdatas[0].register_num,
    };
    axios
      .post(`${url.url}/api/createfencereg/`, datatosend, {})
      .then(function (response) {
        if (response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  updatefencereg(e) {
    var self = this;

    const datatosend = {
      first_name: this.state.fencesdata.firstname,
      last_name: this.state.fencesdata.lastname,
      age_division: this.state.fencesdata.fencing_age,
      gender: this.state.fencesdata.gender,
      selected_events: this.state.selected_events,
      ranking: this.state.ranking,
      groups: this.state.groups,
      fence_class_value: this.state.fencesdata.fence_class_value,
      bib_no: this.state.allregdatas[0].bib_no,
    };
    axios
      .put(`${url.url}/api/updatefencereg/${this.state.selected_id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  deletefencereg(e) {
    const datatosend = {
      selected_events: '',
      ranking: '',
      groups: '',
    };
    axios
      .put(`${url.url}/api/deletefencereg/${this.state.selected_id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Deleted successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  render() {
    const { selectedItems } = this.state;

    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div className='content'>
              <Row>
                <Col md='8'>
                  <button
                    type='button'
                    data-toggle='modal'
                    data-target='#selectfence'
                    class='btn btn-danger btn-round'
                    onClick={this.handlechange}
                  >
                    Back
                  </button>
                  <Card>
                    <CardHeader>
                      <h5 className='title'>Adaptive Tennis Events</h5>
                    </CardHeader>

                    <CardBody>
                      <Form>
                        <div class='form-group row'>
                          <div class='col-xs-5'>
                            <CardHeader className='athlete_fencetb' id='deatils_heading'>
                              <div>
                                <th>
                                  <tr>
                                    <div>
                                      <label>Registration no:</label>
                                      <span>{this.state.fencesdata.register_num}</span>
                                    </div>

                                    <div>
                                      <label> First Name:</label>
                                      <span>{this.state.fencesdata.firstname}</span>
                                    </div>

                                    <div>
                                      <label> Last Name:</label>
                                      <span>{this.state.fencesdata.lastname}</span>
                                    </div>
                                  </tr>
                                  <tr>
                                    <div>
                                      <label>Adaptive Tennis Class:</label>
                                      <span>
                                        {this.state.fencesdata.fence_class_value
                                          ? this.state.fencesdata.fence_class_value
                                          : 'n/a'}
                                      </span>
                                    </div>

                                    <div>
                                      <label>BIB no:</label>
                                      <span>{this.state.fencesdata.bib_no}</span>
                                    </div>

                                    <div>
                                      <label>Age Division:</label>
                                      <span>{this.state.fencesdata.fencing_age}</span>
                                    </div>

                                    <div>
                                      <label> Gender:</label>
                                      <span>{this.state.fencesdata.gender}</span>
                                    </div>
                                  </tr>
                                </th>
                              </div>
                              <button
                                type='button'
                                rel='tooltip'
                                data-toggle='modal'
                                data-target='#meetedit'
                                class='btn btn-success btn-sm btn-icon'
                                onClick={this.editclassage}
                              >
                                <i class='now-ui-icons ui-2_settings-90'></i>
                              </button>
                            </CardHeader>
                          </div>
                        </div>
                        {Object.keys(this.state.fencesdata).length == 0 ? (
                          <Empty />
                        ) : (
                          <>
                            <Table id='my' responsive>
                              <thead>
                                <tr>
                                  <th>Athletes Events</th>
                                  <th>Selected Events</th>
                                  <th>Ranking</th>
                                  <th>Group</th>
                                </tr>
                              </thead>
                              <tbody id='myTable'>
                                {this.state.fencesets.map((question, index) => (
                                  <tr>
                                    <td>
                                      <button
                                        type='button'
                                        class='btn btn-success btn-sm'
                                        data-toggle='modal'
                                        data-target='#useredit'
                                        id={index}
                                        value={question.event_name}
                                        onClick={(e) => this.check(e)}
                                      >
                                        {question.event_name}
                                      </button>
                                    </td>

                                    <td>
                                      {this.state.fencedatas.map((question1, index) =>
                                        question.event_name === question1.selected_events
                                          ? question1.selected_events
                                          : ''
                                      )}
                                    </td>
                                    <td>
                                      {this.state.fencedatas.map((question1, index) =>
                                        question.event_name === question1.selected_events ? question1.ranking : ''
                                      )}
                                    </td>
                                    <td>
                                      {this.state.fencedatas.map((question1, index) =>
                                        question.event_name === question1.selected_events ? question1.groups : ''
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </>
                        )}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                {/* User table with edit delete------------------------------------------------------------------------------ */}
              </Row>
              <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        {this.state.tnamesid + ' ' + 'Fencing'}{' '}
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='exampleFormControlSelect1'>Ranking</label>
                          <input
                            class='form-control'
                            value={this.state.ranking}
                            onChange={(e) => this.setState({ ranking: e.target.value })}
                            name='quatime'
                          />
                        </div>
                        <div class='form-group'>
                          <label for='exampleFormControlSelect1'>Group</label>
                          <input
                            class='form-control'
                            value={this.state.groups}
                            onChange={(e) => this.setState({ groups: e.target.value })}
                            name='quatime1'
                          />
                        </div>

                        {this.state.btncheck == true ? (
                          <center>
                            <button
                              type='button'
                              onClick={(e) => this.createfencereg()}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Create
                            </button>
                          </center>
                        ) : (
                          <>
                            <center>
                              <button type='button' class='btn btn-danger' onClick={(e) => this.updatefencereg()}>
                                Update
                              </button>{' '}
                              {` `}
                              <button
                                type='button'
                                class='btn btn-danger'
                                data-dismiss='modal'
                                onClick={(e) => this.deletefencereg()}
                              >
                                Delete
                              </button>
                            </center>
                          </>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class='modal fade' id='meetedit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Adaptive Tennis
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='exampleFormControlSelect1'>Adaptive Tennis Class</label>
                          <input
                            class='form-control'
                            value={this.state.fenceclass}
                            onChange={this.onchange1}
                            name='quastd'
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputEmail4'>Age Division</label>
                          <input
                            class='form-control'
                            value={this.state.fenceage}
                            onChange={this.onchange2}
                            name='quastd'
                          />
                        </div>
                        <center>
                          <button type='button' class='btn btn-danger' onClick={(e) => this.updateclassage()}>
                            Update
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
export default Athletefence;
