import React from 'react';

import '../App.css';
import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import { Empty } from 'antd';
import 'datatables.net';
import 'datatables.net-bs4';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
class Printtrackworksheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event_data: [{}],
      relay: false,
      notrelay: false,
      finalresultdata: '',
      finalplacedata: '',
      winddata: '',
      implementdata: '',
      wtfinaldata: '',
      breakdata: '',
      njdcrecorddata: '',
      nationaldata: '',
      americandata: '',
      americasdata: '',
      njdcdata: '',
      pointdata: '',
      bestdata: '',
      performancedata: '',
      eventdatedata: '',
      qualifydata: '',
      teampointdata: '',
      meetdatas: [{}],
      loader: true,
      sortbyvaluesdata: [{}],
      issortdata: false,
      ispitdata: true,
      fieldnjdcdatas: [{}],
    };
  }
  async componentDidMount() {
    var self = this;
    const data = {
      meet_id: sessionStorage.getItem('ids'),
      selected_events: this.props.event,
    };
    await axios
      .post(`${url.url}/api/getregistertrackevent/`, data, {})
      .then(function (response) {
        self.setState({
          event_data: response.data,
          loader: false,
        });
      })
      .catch(function (error) {});
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    document.getElementById('homelink').style.display = 'block';

    axios
      .get(`${url.url}/api/getmeetdetail/${sessionStorage.getItem('ids')}`, {})
      .then(function (response) {
        self.setState({
          meetdatas: response.data,
        });
      })
      .catch(function (error) {});
  }

  teampoints(e) {
    if (e == 1) {
      return <td>{this.state.meetdatas[0].place1}</td>;
    } else if (e == 2) {
      return <td>{this.state.meetdatas[0].place2} </td>;
    } else if (e == 3) {
      return <td>{this.state.meetdatas[0].place3} </td>;
    } else if (e == 4) {
      return <td>{this.state.meetdatas[0].place4} </td>;
    } else if (e == 5) {
      return <td>{this.state.meetdatas[0].place5} </td>;
    } else if (e == 6) {
      return <td>{this.state.meetdatas[0].place6} </td>;
    } else if (e == 7) {
      return <td>{this.state.meetdatas[0].place7} </td>;
    } else if (e == 8) {
      return <td>{this.state.meetdatas[0].place8} </td>;
    } else if (e == 9) {
      return <td>{this.state.meetdatas[0].place9} </td>;
    } else {
      return <td>{this.state.meetdatas[0].place10} </td>;
    }
  }

  render() {
    return (
      <Form>
        <CardHeader>
          <center>
            <h5 className='title'>Track Results Worksheet</h5>
          </center>
        </CardHeader>
        {Object.keys(this.state.event_data).length == 0 ? (
          <Empty />
        ) : (
          <>
            <table class='worksheet_table'>
              <thead>
                <tr class='worksheet_tr'>
                  <th>Heat</th>
                  <th>Lane</th>
                  <th>Class</th>
                  <th>Qualtime</th>
                  <th>Age</th>
                  <th>Gender</th>
                  <th>Bib</th>
                  <th>Last Name</th>
                  <th>First Name</th>
                  <th>Country</th>
                  <th>Final Result</th>
                  <th>Final Place</th>
                  <th>Qual Stand</th>
                  <th>EventName</th>
                </tr>
              </thead>
              <tbody>
                {this.state.event_data.map((question, index) => (
                  <tr class='worksheet_tr'>
                    <td>{question.heat}</td>
                    <td>{question.lane}</td>
                    <td>{question.track_class_value}</td>
                    <td>{question.qualifying_time}</td>
                    <td>{question.age_division}</td>
                    <td>{question.gender}</td>
                    <td>{question.bib_no}</td>
                    <td>{question.last_name}</td>
                    <td>{question.first_name}</td>
                    <td>{question.country}</td>
                    <td>{question.final_result}</td>
                    <td>{question.final_place}</td>
                    <td>{question.qualifying_standard}</td>
                    <td>{question.selected_events}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </Form>
    );
  }
}

export default Printtrackworksheet;
