import React from 'react';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className='content'></div>
      </>
    );
  }
}

export default Dashboard;
