import React from 'react';
import '../App.css';

import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';

import Swal from 'sweetalert2';
//import Userreload from './UserPageload.js';
import Loader from 'react-loader-spinner';
import emailjs from 'emailjs-com';
const url = window.globalConfig;
class User extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [{}],
      editdata: [{}],
      editmodal: false,
      role: '',
      defaultoption: '',
      desg: '',
      roleid: '',
      fname: '',
      lname: '',
      newemail: '',
      pwd: '',
      cpwd: '',
      newdesg: '2-manager',
      rerend: false,
      loader: true,
      cellphone: '',
      organization: '',
      mess: '',
    };
    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateuser = this.updateuser.bind(this);
    this.optionChange = this.optionChange.bind(this);
    this.createuser = this.createuser.bind(this);
    this.dropdownchange = this.dropdownchange.bind(this);
    this.handlechange1 = this.handlechange1.bind(this);
  }
  dropdownchange(e) {
    this.setState({ newdesg: e.target.value });
  }
  componentDidMount() {
    // if (window.performance) {
    //   if (performance.navigation.type == 1) {
    //     window.location.href='/samsreact'
    //     window.reload(true);
    //   }
    // }

    // $(document).ready(function () {
    //   $("#inputsm").on("keyup", function () {
    //     var value = $(this).val().toLowerCase();
    //     $("#myTable tr").filter(function () {
    //       $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    //     });
    //   });
    // });

    var self = this;
    axios.get(`${url.url}/api/users`, {}).then(function (response) {
      // console.log(response.data.data);

      self.setState({
        datas: response.data.data,
        loader: false,
      });
    });
    sessionStorage.removeItem('venueid');
    sessionStorage.removeItem('cvenues');
    sessionStorage.removeItem('classv');
  }

  onSearch = (e) => {
    //console.log($("#inputsm"))
    var value = e.target.value.toLowerCase();
    $('#myTable tr').filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
  };

  // edit update-----------------------------------------------------------------
  optionChange(event) {
    this.setState({ defaultoption: event.target.value });
    this.setState({
      desg: event.target.value.split('-')[1],
      roleid: event.target.value.split('-')[0],
    });
  }
  handleChange(event) {
    event.persist();
    let value = event.target.value;
    this.setState((prevState) => ({
      editdata: { ...prevState.editdata, [event.target.name]: value },
    }));
  }
  edit(e) {
    var i = this.state.datas[e].role_id.toString().concat('-' + this.state.datas[e].designation);
    // console.log(i);
    var self = this;
    self.setState({
      editdata: this.state.datas[e],
      editmodal: true,
      defaultoption: i,
      desg: this.state.datas[e].designation,
      roleid: this.state.datas[e].role_id,
      cellphone: this.state.datas[e].cellphone,
      organization: this.state.datas[e].team_name,
    });
  }
  updateuser(e) {
    var self = this;
    e.preventDefault();
    const datatosend = {
      firstname: this.state.editdata.firstname,
      lastname: this.state.editdata.lastname,
      email: this.state.editdata.email,
      designation: this.state.desg,
      role_id: parseInt(this.state.roleid),
      cellphone: this.state.cellphone,
      team_name: this.state.organization,
    };
    axios
      .put(`${url.url}/api/updateuser/${this.state.editdata.id}`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'User Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  // update----------------------------------------------------------------------------------------------------------------

  // delete-----------------------------------------------------------------------------------------
  delete(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deleteuser/${this.state.datas[e].id}`, {})
          .then(function (response) {})

          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });

    // delete-----------------------------------------------------------------------------------------
  }

  handlechange1() {
    const data = { email: this.state.newemail };
    const otp = (otp) => {
      this.setState({
        mess: otp,
      });
    };
    const sendmail = (retVal) => {
      // this.setState({ newpass: true })
      // axios.get(`${url.url}/api/sendMail`, templateParams);
      // axios
      //   .get(`${url.url}/api/sendMail`, { params: templateParams })
      //   .then(function (response) {
      //     // Handle the response here
      //     if (response) {
      //       Swal.fire({
      //         icon: 'success',
      //         title: 'User Created successfully',
      //         showConfirmButton: false,
      //         timer: 1000,
      //       });
      //     }
      //   })
      //   .catch(function (error) {
      //     // Handle errors here
      //   });
      // emailjs.send('service_3zm0qsj', 'template_d2ce8no', templateParams, 'user_EVR0vOEkWZCA4wY6MNnKp').then(
      //   function (res) {
      //     console.log(res);
      //   },
      //   function (error) {
      //     console.log(error);
      //   }
      // );
      // return retVal;
    };
    // axios.post(`${url.url}/api/checkemail1`, data, {
    // })

    //   .then(function (response) {
    //     console.log(response)
    if (this.state.newemail != '') {
      var length = 8,
        charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
        retVal = '';
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
        otp(retVal);
      }
      sendmail(retVal);
      this.createuser(retVal);
    }
    //   else if (response.status === 404) {

    //   }
    // }).catch(function (error) {
    //   Swal.fire({
    //     title: 'Failed',
    //     text: 'Email id does not exist',
    //     icon: 'warning',
    //     showCancelButton: false,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Ok!'
    //   })
    // })
  }

  createuser(retVal) {
    var self = this;
    // console.log(retVal)
    const datatosend = {
      designation: this.state.newdesg.split('-')[1],
      to_name: this.state.fname + ' ' + this.state.lname,
      firstname: this.state.fname,
      email: this.state.newemail,
      password: this.state.pwd,
      lastname: this.state.lname,
      first_time_user: 'true',
      dob: '',
      age: '',
      address: '',
      address2: '',
      city: '',
      zipcode: '',
      country: '',
      homephone: '',
      cellphone: this.state.cellphone,
      team_name: this.state.organization,
      gender: 'male',
      role_id: parseInt(this.state.newdesg.split('-')[0]),
      created_by: 1,
      manager_name: '',
    };
    if (this.state.pwd === this.state.cpwd) {
      axios
        .post(`${url.url}/api/register/`, datatosend, {})
        .then(function (response) {
          if (response.statusText === 'OK' || response.status == 200) {
            Swal.fire({
              icon: 'success',
              title: 'User Created successfully',
              showConfirmButton: false,
              timer: 1000,
            }).then(function (response) {
              window.location.reload();
            });
          }
        })
        .catch(function (error) {
          Swal.fire('opps!', 'Something went wrong please check the fields or email id may already exist', 'warning');
        });
    } else {
      Swal.fire('Please check your password!', 'Password does not match', 'warning');
    }
  }
  render() {
    //sessionStorage.setItem("path","/users");
    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader
                type='ThreeDots'
                color='#00BFFF'
                height={70}
                width={70}
                // timeout={3000} //3 secs
              />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div className='content'>
              <Row>
                <Col md='8'>
                  <button type='button' data-toggle='modal' data-target='#adduser' class='btn btn-danger btn-round'>
                    Add User
                  </button>
                  <Card>
                    <CardHeader>
                      <h5 className='title'>User Profile</h5>
                    </CardHeader>

                    <CardBody>
                      <Form>
                        <div class='form-group row'>
                          <div class='col-xs-5'>
                            <CardHeader>
                              <p>Search</p>
                              <input
                                class='form-control'
                                id='inputsm'
                                onChange={(e) => this.onSearch(e)}
                                placeholder='Search..'
                                type='text'
                              />
                            </CardHeader>
                          </div>
                        </div>
                        <Table id='my' responsive>
                          <thead>
                            <tr>
                              <th className='text-center'>#</th>
                              <th>FirstName</th>
                              <th>LastName</th>
                              <th className='text-center'>Email</th>
                              <th>Role</th>

                              <th class='disabled-sorting text-right'>Actions</th>
                            </tr>
                          </thead>
                          <tbody id='myTable'>
                            {this.state.datas.map((question, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{question.firstname}</td>
                                <td>{question.lastname}</td>
                                <td>{question.email}</td>
                                <td>{question.designation}</td>
                                <td class='td-actions text-right'>
                                  <button
                                    type='button'
                                    rel='tooltip'
                                    class='btn btn-success btn-sm btn-icon'
                                    onClick={() => this.edit(index)}
                                    data-toggle='modal'
                                    data-target='#useredit'
                                  >
                                    <i class='now-ui-icons ui-2_settings-90'></i>
                                  </button>
                                  {` `}
                                  <button
                                    type='button'
                                    rel='tooltip'
                                    class='btn btn-danger btn-sm btn-icon'
                                    onClick={() => this.delete(index)}
                                  >
                                    <i class='now-ui-icons ui-1_simple-remove'></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                {/* User table with edit delete------------------------------------------------------------------------------ */}
              </Row>

              <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Edit User{' '}
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-row'>
                          <div class='form-group col-md-6'>
                            <label for='inputEmail4'>FirstName *</label>
                            <input
                              type='text'
                              class='form-control'
                              name='firstname'
                              value={this.state.editdata.firstname}
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputPassword4'>LastName *</label>
                            <input
                              type='text'
                              class='form-control'
                              name='lastname'
                              value={this.state.editdata.lastname}
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div class='form-row'>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Email *</label>
                            <input
                              type='text'
                              class='form-control'
                              name='email'
                              value={this.state.editdata.email}
                              onChange={this.handleChange}
                              required
                            />
                          </div>

                          <div class='form-group col-md-6'>
                            <label for='exampleFormControlSelect1'>Role *</label>
                            <select
                              class='form-control'
                              id='exampleFormControlSelect1'
                              value={this.state.defaultoption}
                              onChange={this.optionChange}
                              required
                            >
                              <option value='1-admin'>Admin</option>
                              <option value='2-manager'>Manager</option>
                            </select>
                          </div>
                        </div>

                        {/**/}
                        <div class='form-row'>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Cellphone</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Cellphone'
                              value={this.state.cellphone}
                              onChange={(e) => this.setState({ cellphone: e.target.value })}
                            />
                          </div>

                          <div class='form-group col-md-6'>
                            <label>Organization</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Organization'
                              value={this.state.organization}
                              onChange={(e) => this.setState({ organization: e.target.value })}
                            />
                          </div>
                        </div>
                        {/**/}
                        <center>
                          <button type='submit' onClick={this.updateuser} data-dismiss='modal' class='btn btn-danger'>
                            Update
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              {/* add user---------------------------------------------------------------------------------------------------- */}
              <div class='modal fade' id='adduser' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Add User
                      </h5>
                      <button
                        type='button'
                        class='close'
                        data-dismiss='modal'
                        aria-label='Close'
                        style={{ display: 'block' }}
                      >
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-row'>
                          <div class='form-group col-md-4'>
                            <label for='inputCity'>FirstName *</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter firstname'
                              onChange={(e) => this.setState({ fname: e.target.value })}
                              required
                            />
                          </div>

                          <div class='form-group col-md-4'>
                            <label>Lastname *</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter lastname'
                              onChange={(e) => this.setState({ lname: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-4'>
                            <label>Email *</label>
                            <input
                              type='email'
                              class='form-control'
                              placeholder='Enter email'
                              onChange={(e) => this.setState({ newemail: e.target.value })}
                              required
                            />
                          </div>
                        </div>

                        <div class='form-row'>
                          <div class='form-group col-md-6'>
                            <label>Password *</label>
                            <input
                              type='password'
                              class='form-control'
                              placeholder='Enter Password'
                              onChange={(e) => this.setState({ pwd: e.target.value })}
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputPassword4'>Confirm Password *</label>
                            <input
                              type='password'
                              class='form-control'
                              placeholder='Confirm Password'
                              onChange={(e) => this.setState({ cpwd: e.target.value })}
                            />
                          </div>
                        </div>

                        {/**/}
                        <div class='form-row'>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Cellphone</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Cellphone'
                              onChange={(e) => this.setState({ cellphone: e.target.value })}
                            />
                          </div>

                          <div class='form-group col-md-6'>
                            <label>Organization</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Organization'
                              onChange={(e) => this.setState({ organization: e.target.value })}
                            />
                          </div>
                        </div>
                        {/**/}

                        <div class='form-group'>
                          <label for='exampleFormControlSelect1'>Role *</label>
                          <select
                            class='form-control'
                            id='exampleFormControlSelect1'
                            onChange={this.dropdownchange}
                            required
                          >
                            {/* <option>Please select</option> */}
                            <option value='1-admin'>Admin</option>
                            <option value='2-manager' selected='true'>
                              Manager
                            </option>
                          </select>

                          <center>
                            <button
                              type='button'
                              onClick={this.handlechange1}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Create user
                            </button>
                          </center>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default User;
