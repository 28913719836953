import React from 'react';

import '../App.css';
import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import { Empty } from 'antd';
import 'datatables.net';
import 'datatables.net-bs4';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
var x;
class Printpitboximplement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event_data: [],
      relay: false,
      notrelay: false,
      finalresultdata: '',
      finalplacedata: '',
      winddata: '',
      implementdata: '',
      wtfinaldata: '',
      breakdata: '',
      njdcrecorddata: '',
      nationaldata: '',
      americandata: '',
      americasdata: '',
      njdcdata: '',
      pointdata: '',
      bestdata: '',
      performancedata: '',
      eventdatedata: '',
      qualifydata: '',
      teampointdata: '',
      meetdatas: [{}],
      loader: true,
      sortbyvaluesdata: [{}],
      issortdata: false,
      ispitdata: true,
      fieldnjdcdatas: [{}],
    };
  }
  async componentDidMount() {
    var self = this;
    const data = {
      meet_id: sessionStorage.getItem('ids'),
    };
    await axios
      .post(`${url.url}/api/getfieldimplementevent/`, data, {})
      .then(function (response) {
        self.setState({
          event_data: response.data,
          loader: false,
        });
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    document.getElementById('homelink').style.display = 'block';
  }

  render() {
    var self = this;
    return (
      <>
        <div>
          <div>
            <h1> </h1>
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <h5 className='title'>
                      {this.state.event} <br></br> Field Pit Box Implements
                    </h5>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      {Object.keys(this.state.event_data).map(function (key) {
                        x = key.split('_');
                        return (
                          <CardBody>
                            <div class='realy_main'>
                              <div>
                                <p>
                                  <label class='relay_label'>Pits</label>
                                  {x[0]}
                                  {` `}
                                  <label class='relay_label'>Group</label>
                                  {x[1]}
                                </p>

                                {/* <p>
                                  <label class='relay_label'>Implements</label>
                                  {self.state.event_data[key].map((question, index) => (
                                    <p>
                                      {question.wtfinal} {question.selected_events}
                                    </p>
                                  ))}
                                </p> */}

                                <p>
                                  <label className='relay_label'>Implements</label>
                                  {self.state.event_data[key].map((question, index) => {
                                    const wtfinalArray = question.wtfinal ? question.wtfinal.split(',') : [];

                                    return wtfinalArray.map((wtfinalValue, wtfinalIndex) => (
                                      <p key={`${index}-${wtfinalIndex}`}>
                                        {wtfinalValue.trim()} {''}
                                        {wtfinalValue.trim() &&
                                          question.selected_events &&
                                          `${question.selected_events.trim()}`}
                                      </p>
                                    ));
                                  })}
                                </p>
                              </div>
                            </div>
                          </CardBody>
                        );
                      })}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}

export default Printpitboximplement;
