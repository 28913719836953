import React, { useCallback } from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import { Empty } from 'antd';
import './compound.css';
import Swal from 'sweetalert2';
import Loader from 'react-loader-spinner';
import e from 'cors';
const url = window.globalConfig;
let tempDis = '';
class Trackset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tracksetdata: [{}],
      tracksets: '',
      maxheat: '',
      evntdate: '',
      eventno: '',
      timeddata: '',
      promotedata: '',
      seeddata: '',
      relaydata: '',
      teamdata: '',
      eventdata: '',
      iaffrules: '',
      selected_id: '',
      venuedatas: [{}],
      venuespecfic: [{}],
      trackeventsdata: '',
      tracklocationset: {},
      tracklocset: {},
      preloader: true,
      meetdatas: [{}],
      meetstartdata: '',
      geteventid: '',
      isrelay: '',
    };

    this.createtrackset = this.createtrackset.bind(this);
    this.edittrackset = this.edittrackset.bind(this);
    this.optionchange = this.optionchange.bind(this);
    this.dropdownchange = this.dropdownchange.bind(this);
    this.dropdownchange1 = this.dropdownchange1.bind(this);
    this.dropdownchange2 = this.dropdownchange2.bind(this);
    this.dropdownchange3 = this.dropdownchange3.bind(this);
    this.dropdownchange4 = this.dropdownchange4.bind(this);
    this.updatetrackset = this.updatetrackset.bind(this);
    this.eventnochange = this.eventnochange.bind(this);
    this.optionchange1 = this.optionchange1.bind(this);
    this.optionchange2 = this.optionchange2.bind(this);
    this.optionchange3 = this.optionchange3.bind(this);
    this.optionchange4 = this.optionchange4.bind(this);
    this.optionchange5 = this.optionchange5.bind(this);
    this.optionchange6 = this.optionchange6.bind(this);
    this.optionchange7 = this.optionchange7.bind(this);
    this.optionchange8 = this.optionchange8.bind(this);
    this.editlocationset = this.editlocationset.bind(this);
    this.createtracklocationset = this.createtracklocationset.bind(this);
    this.updatetracklocationset = this.updatetracklocationset.bind(this);
    // this.deletetrackset = this.deletetrackset.bind(this);
    this.back = this.back.bind(this);
    this.newset = this.newset.bind(this);
  }

  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    var self = this;
    var count = 0;
    let e = sessionStorage.getItem('e');
    let textval = sessionStorage.getItem('x');
    axios.get(`${url.url}/api/venues`, {}).then(function (response) {
      self.setState({
        venuedatas: response.data.data,
        venuespecfic: response.data.data[textval],
        venueevents: response.data.data[textval].events,
      });
      count++;
      if (count == 3) {
        self.setState({
          preloader: false,
        });
      }
    });
    //Track set preference
    let meetid = sessionStorage.getItem('ids');
    axios
      .get(`${url.url}/api/gettrackmeetid/${meetid}`, {})
      .then(function (response) {
        self.setState({
          tracksetdata: response.data,
        });

        count++;
        if (count == 3) {
          self.setState({
            preloader: false,
          });
        }
      })
      .catch(function (error) {
        self.setState({
          preloader: false,
        });
      });
    axios
      .get(`${url.url}/api/tracklocationmeetid/${meetid}`, {})
      .then(function (response) {
        self.setState({
          tracklocationset: response.data,
          tracklocset: response.data[0],
        });
        count++;
        if (count == 3) {
          self.setState({
            preloader: false,
          });
        }
      })
      .catch(function (error) {
        self.setState({
          preloader: false,
        });
      });
    //Hide and show manager
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    // document.getElementById("venueesss").style.display = "block";

    var self = this;
    axios.get(`${url.url}/api/meets`, {}).then(function (response) {
      self.setState({
        meetdatas: response.data.data,
        meetstartdata: response.data.data[e].meet_date,
      });
    });
  }

  //dropdown values
  dropdownchange(e) {
    this.setState({
      timeddata: e.target.value,
    });
    this.setState({
      selectval: e.target.value,
    });
  }

  dropdownchange1(e) {
    this.setState({
      promotedata: e.target.value,
    });
    this.setState({
      selectval1: e.target.value,
    });
  }

  dropdownchange2(e) {
    this.setState({
      seeddata: e.target.value,
    });
    this.setState({
      selectval2: e.target.value,
    });
  }

  dropdownchange3(e) {
    this.setState({
      relaydata: e.target.value,
    });
    this.setState({
      selectval3: e.target.value,
    });
  }

  dropdownchange4(e) {
    this.setState({
      teamdata: e.target.value,
    });
    this.setState({
      selectval4: e.target.value,
    });
  }

  optionchange(e) {
    this.state.venuespecfic.events.map((question, index) => {
      if (question.name === e.target.value) {
        this.setState({
          eventdata: e.target.value,
          geteventid: question.id,
        });
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    });
  }
  eventnochange(e) {
    this.setState({
      eventno: e.target.value,
    });
  }
  optionchange1(e) {
    this.setState({
      maxheat: e.target.value,
    });
  }

  optionchange2(e) {
    this.setState({
      meetstartdata: e.target.value,
    });
  }

  optionchange3(e) {
    this.setState({
      venuedata: e.target.data,
    });
  }

  optionchange4(e) {
    this.setState({
      venuelocationdata: e.target.value,
    });
  }

  optionchange5(e) {
    this.setState({
      numberdata: e.target.value,
    });
  }

  optionchange6(e) {
    this.setState({
      lanedata: e.target.value,
    });
  }

  optionchange7(e) {
    this.setState({
      currentdata: e.target.value,
    });
  }
  optionchange8(e) {
    this.setState({
      iaffrules: e.target.value,
    });
  }
  //Edit trackset
  edittrackset(e) {
    var self = this;
    self.setState({
      trackeventsdata: self.state.venuespecfic.events[e].name,
    });
    var loop = true;
    let textval = sessionStorage.getItem('x');
    self.state.tracksetdata.forEach((question, index) => {
      if (question.event_name == self.state.venuespecfic.events[e].name) {
        self.setState({
          tracksets: question,
          selected_id: question.id,
          eventno: question.event_no,
          eventdata: question.event_name,
          maxheat: question.max_per_heat,
          timeddata: question.timed_finals,
          promotedata: question.promote_time_only,
          seeddata: question.seed_method,
          relaydata: question.relay,
          teamdata: question.team_scoring,
          meetstartdata: question.event_date,
          // meetstartdata: question.event_date,
          btncheck: false,
        });
        loop = false;
      } else if (loop) {
        self.setState({
          tracksets: '',
          selected_id: '',
          eventno: '',
          eventdata: '',
          maxheat: '4',
          timeddata: 'No',
          promotedata: 'No',
          seeddata: this.state.venueevents[e].is_relay == 1 ? 'Lanes/2' : 'Lanes',
          relaydata: this.state.venueevents[e].is_relay == 1 ? 'Yes' : 'No',
          teamdata: 'Yes',
          // evntdate: "",
          meetstartdata: this.state.meetstartdata,
          btncheck: true,
        });
        loop = true;
      }
    });
    if (self.state.tracksetdata.length == 0) {
      self.setState({
        tracksets: '',
        selected_id: '',
        eventno: '',
        eventdata: '',
        maxheat: '',
        timeddata: 'No',
        promotedata: 'No',
        seeddata: '',
        relaydata: this.state.venueevents[e].is_relay == 1 ? 'Yes' : 'No',
        teamdata: 'Yes',
        // evntdate: "",
        meetstartdata: this.state.meetstartdata,
        btncheck: true,
      });
    }
  }
  editlocationset() {
    let textval = sessionStorage.getItem('x');
    if (this.state.venuedatas[textval].name == this.state.tracklocset.venue_name) {
      this.setState({
        venuedata: this.state.tracklocationset[0].venue_name,
        venuelocationdata: this.state.tracklocationset[0].venue_location,
        numberdata: this.state.tracklocationset[0].number_of_events,
        lanedata: this.state.tracklocationset[0].number_of_lanes,
        currentdata: this.state.tracklocationset[0].current_record,
        iaffrules: this.state.tracklocationset[0].IAFF_rules,
        btncheck1: false,
      });
    } else {
      this.setState({
        venuedata: this.state.venuedatas[textval].name,
        venuelocationdata: '',
        numberdata: this.state.venuespecfic.events.length,
        lanedata: '',
        currentdata: '',
        iaffrules: 'Yes',
        btncheck1: true,
      });
    }
  }

  createtracklocationset() {
    const datatosend = {
      venue_name: this.state.venuedata,
      venue_location: this.state.venuelocationdata,
      number_of_events: this.state.numberdata,
      number_of_lanes: this.state.lanedata,
      current_record: this.state.currentdata,
      IAFF_rules: this.state.iaffrules,
      meet_id: parseInt(sessionStorage.getItem('ids')),
    };
    axios
      .post(`${url.url}/api/createtracklocationset`, datatosend, {})
      .then(function (response) {
        if (response.statusText == 'Created' || response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'TrackLocation Set Created successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  updatetracklocationset() {
    var self = this;
    //   e.preventDefault()
    const datatosend = {
      venue_name: this.state.venuedata,
      venue_location: this.state.venuelocationdata,
      number_of_events: this.state.numberdata,
      number_of_lanes: this.state.lanedata,
      current_record: this.state.currentdata,
      IAFF_rules: this.state.iaffrules,
    };
    axios
      .put(`${url.url}/api/updatetracklocationset/${this.state.tracklocationset[0].id}`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'TrackLocation Set Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  //Create Track set
  createtrackset = (e) => {
    e.preventDefault();
    var self = this;
    const setvalue = (q, index) => {
      if (self.state.tracksetdata.length > index) {
        self.setState({
          tracksets: self.state.tracksetdata[index],

          selected_id: self.state.tracksetdata[index].id,
          eventno: self.state.tracksetdata[index].event_no,
          eventdata: self.state.tracksetdata[index].event_name,
          maxheat: self.state.tracksetdata[index].max_per_heat,
          timeddata: self.state.tracksetdata[index].timed_finals,
          promotedata: self.state.tracksetdata[index].promote_time_only,
          seeddata: self.state.tracksetdata[index].seed_method,
          relaydata: self.state.tracksetdata[index].relay,
          teamdata: self.state.tracksetdata[index].team_scoring,
          meetstartdata: self.state.tracksetdata[index].event_date,
        });
      }
    };
    self.state.tracksetdata.map((question, index) => {
      setvalue(question, index);
    });
    const datatosend = {
      event_no: this.state.eventno,
      event_name: this.state.trackeventsdata,
      max_per_heat: this.state.maxheat,
      timed_finals: this.state.timeddata,
      promote_time_only: this.state.promotedata,
      seed_method: this.state.seeddata,
      relay: this.state.relaydata,
      team_scoring: this.state.teamdata,
      event_date: this.state.meetstartdata,
      created_by: 1,
      meet_id: parseInt(sessionStorage.getItem('ids')),
      event_id: this.state.geteventid,
    };
    axios
      .post(`${url.url}/api/createtracksetrecords`, datatosend, {})
      .then(function (response) {
        if (response.statusText == 'Created' || response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'Trackset Created successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Event no Already Exists',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  };
  cleartrackset = (e) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        //document.getElementById("myTable").deleteRow(e);
        axios
          .delete(`${url.url}/api/deletetrackset/${this.state.tracksets.id}`, {})
          .then(function (response) {
            if (response.status) {
              Swal.fire('Deleted!', 'Record has been deleted.', 'success').then((result) => {
                window.location.reload();
              });
            }
          })
          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
      }
      // } else if (result.dismiss === Swal.DismissReason.cancel) {
      //   Swal.fire("Cancelled", "Cancelled :)", "error");
      // }
    });
  };
  meetdelete(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deletetrack/${this.state.meetdatas[e].id}`, {})
          .then(function (response) {})

          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });
  }
  //Update trackset
  updatetrackset(e) {
    var self = this;
    //   e.preventDefault()
    const datatosend = {
      event_no: this.state.eventno,
      event_name: this.state.tracksets.event_name,
      max_per_heat: this.state.maxheat,
      timed_finals: this.state.timeddata,
      promote_time_only: this.state.promotedata,
      seed_method: this.state.seeddata,
      relay: this.state.relaydata,
      team_scoring: this.state.teamdata,
      event_date: this.state.meetstartdata,
      meet_id: parseInt(sessionStorage.getItem('ids')),
    };
    axios
      .put(`${url.url}/api/updatetracksetrecords/${this.state.tracksets.id}`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Trackset Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something Went Wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  //Delete Trackset

  //back Button
  back() {
    window.location.replace('/eventpreference');
  }

  delete = (e) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deletetracksetrecords/${this.state.tracksetdata[e].id}`, {})
          .then(function (response) {})

          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });
  };
  newset() {
    this.setState({
      // tracksets: "",
      // selected_id: "",
      // eventdata: "",
      maxheat: '8',
      timeddata: 'No',
      promotedata: 'No',
      // seeddata: "",
      // relaydata: "",
      teamdata: 'Yes',
      // evntdate: "",
      btncheck: true,
    });
  }

  testFunction = (e) => {
    var x = e.split('-');
    var name = x[0];
    var id = x[1];
    tempDis = '';
    this.state.tracksetdata.map((element, index) => {
      if (name === element.event_name && id == element.event_id) {
        tempDis = 'disabled';
        return null;
      }
    });
    return tempDis;
  };

  onTodoChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    let textval = sessionStorage.getItem('x');
    return (
      <>
        {this.state.preloader ? (
          <>
            <div className='svg-wrapper'>
              <Loader
                type='ThreeDots'
                color='#00BFFF'
                height={70}
                width={70}
                // timeout={3000} //3 secs
              />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div className='content'>
              <Row>
                <Col md='8'>
                  <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                    Back
                  </button>
                  {''}
                  {/* <button
                    type="button"
                    class="btn btn-danger btn-round"
                    rel="tooltip"
                    data-toggle="modal"
                    data-target="#newaddmeet"
                    onClick={this.newset}
                  >
                    New Set
                  </button>
                  {""} */}
                  <Card>
                    <CardHeader>
                      <h5 className='title'>Track Set Preferences</h5>
                    </CardHeader>

                    <CardBody>
                      <Form>
                        <div class='form-group row'>
                          <div class='col-xs-5'>
                            {Object.keys(this.state.venuespecfic).length == 1 ? (
                              <Empty />
                            ) : (
                              <CardHeader id='deatils_heading'>
                                <tr>
                                  <th>
                                    <p>
                                      Venue Name:
                                      <span>{this.state.venuedatas[textval].name}</span>
                                    </p>
                                  </th>
                                  {''}
                                  <th>
                                    <p>
                                      Venue Location:
                                      <span>
                                        {this.state.tracklocationset[0]
                                          ? this.state.tracklocationset[0].venue_location
                                          : ''}
                                      </span>
                                    </p>
                                  </th>
                                  {''}
                                  {/* <th>
                                    <p>
                                      Number of Events:
                                      <span>
                                        {this.state.venuespecfic.events.length}
                                      </span>
                                    </p>
                                  </th>
                                  {""} */}
                                  <th>
                                    <p>
                                      Number of Lanes:
                                      <span>
                                        {this.state.tracklocationset[0]
                                          ? this.state.tracklocationset[0].number_of_lanes
                                          : ''}
                                      </span>
                                    </p>
                                  </th>
                                  {''}
                                  <th>
                                    <p>
                                      Current Record:
                                      <span>
                                        {this.state.tracklocationset[0]
                                          ? this.state.tracklocationset[0].current_record
                                          : ''}
                                      </span>
                                    </p>
                                  </th>
                                  {''}
                                  <th>
                                    <p>
                                      WA Rules:
                                      <span>
                                        {this.state.tracklocationset[0]
                                          ? this.state.tracklocationset[0].IAFF_rules
                                          : ''}
                                      </span>
                                    </p>
                                  </th>
                                  {''}
                                  <th>
                                    <button
                                      type='button'
                                      rel='tooltip'
                                      data-toggle='modal'
                                      data-target='#newmeet'
                                      class='btn btn-success btn-sm btn-icon'
                                      onClick={this.editlocationset}
                                    >
                                      <i class='now-ui-icons ui-2_settings-90'></i>
                                    </button>
                                  </th>
                                </tr>
                              </CardHeader>
                            )}
                          </div>
                        </div>

                        {/* table --------------------------------------------------- */}
                        {Object.keys(this.state.venuespecfic).length == 1 ? (
                          <Empty />
                        ) : (
                          <>
                            <Table id='my' responsive>
                              <thead>
                                <tr>
                                  <th className='text-center'>Event #</th>
                                  <th>Event Name</th>
                                  <th>Max per Heat</th>
                                  <th>Seed Method</th>
                                  <th>Relay</th>
                                  <th>Team Scoring</th>
                                  <th>Event date</th>
                                  <th class='disabled-sorting text-right'>Actions</th>
                                </tr>
                              </thead>
                              <tbody id='myTable'>
                                {this.state.venuespecfic.events.map((question, index) => (
                                  <tr>
                                    <td>
                                      {this.state.tracksetdata.map((question1, index1) =>
                                        question.name === question1.event_name ? question1.event_no : ''
                                      )}
                                    </td>
                                    <td>{question.name}</td>
                                    <td>
                                      {this.state.tracksetdata.map((question1, index1) =>
                                        question.name === question1.event_name ? question1.max_per_heat : ''
                                      )}
                                    </td>
                                    <td>
                                      {this.state.tracksetdata.map((question1, index1) =>
                                        question.name === question1.event_name ? question1.seed_method : ''
                                      )}
                                    </td>
                                    <td>{question.is_relay == '1' ? 'Yes' : 'No'}</td>
                                    <td>
                                      {this.state.tracksetdata.map((question1, index1) =>
                                        question.name === question1.event_name ? question1.team_scoring : ''
                                      )}
                                    </td>
                                    <td>
                                      {this.state.tracksetdata.map((question1, index1) =>
                                        question.name === question1.event_name ? question1.event_date : ''
                                      )}
                                    </td>
                                    <td class='td-actions text-right'>
                                      <button
                                        type='button'
                                        rel='tooltip'
                                        class='btn btn-success btn-sm btn-icon'
                                        onClick={() => this.edittrackset(index)}
                                        data-toggle='modal'
                                        data-target='#meetedit'
                                      >
                                        <i class='now-ui-icons ui-2_settings-90'></i>
                                      </button>
                                      {` `}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </>
                        )}
                        {/* table --------------------------------------------------- */}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* edit meet modal ----------------------------------------------------------------------------------------------------------------- */}
              <div class='modal fade' id='meetedit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        {' '}
                        {this.state.trackeventsdata + ' ' + 'Trackset'}{' '}
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='inputAddress'>Event #</label>
                          <input
                            class='form-control'
                            id='inputAddress'
                            // placeholder="Event Name"
                            name='evnt'
                            value={this.state.eventno}
                            onChange={this.eventnochange}
                            required
                          ></input>
                        </div>
                        <div class='form-group'>
                          <label for='inputAddress'>Event Name</label>
                          <input
                            class='form-control'
                            id='inputAddress'
                            placeholder='Event Name'
                            name='evntname'
                            value={this.state.trackeventsdata}
                            onChange={this.optionchange}
                            // required
                            // disabled
                          ></input>
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>max Per Heat</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            placeholder='max per heat'
                            name='mfh'
                            value={this.state.maxheat}
                            onChange={this.optionchange1}
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Seed Method</label>
                          <select
                            class='form-control'
                            id='exampleFormControlSelect1'
                            value={this.state.seeddata}
                            onChange={this.dropdownchange2}
                            required
                          >
                            <option>Please select</option>
                            <option value='Lanes'>Lanes</option>
                            <option value='Waterfall'>Waterfall</option>
                            <option value='Lanes/2'>Lanes/2</option>
                            <option value='Open/Lanes'>Open/Lanes</option>
                            <option value='Open/Waterfall'>Open/Waterfall</option>
                          </select>
                        </div>

                        {/* <div class="form-group">
                          <label for="inputAddress">Relay *</label>
                          <select
                            class="form-control"
                            id="exampleFormControlSelect1"
                            value={this.state.relaydata}
                            onChange={this.dropdownchange3}
                            required
                          >
                            <option value="">Please select</option>
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                        </div> */}

                        <div class='form-group'>
                          <label for='inputAddress'>Team Scoring</label>
                          <select
                            class='form-control'
                            id='exampleFormControlSelect1'
                            value={this.state.teamdata}
                            onChange={this.dropdownchange4}
                            required
                          >
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                          </select>
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Event Date</label>
                          <input
                            type='date'
                            class='form-control'
                            id='inputAddress'
                            placeholder='Event Date'
                            name='evntdate'
                            value={this.state.meetstartdata}
                            onChange={this.optionchange2}
                            required
                          />
                        </div>

                        {this.state.btncheck == true ? (
                          <center>
                            <button
                              type='Submit'
                              onClick={(e) => this.createtrackset(e)}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Create
                            </button>
                          </center>
                        ) : (
                          <>
                            <center>
                              <button type='button' class='btn btn-danger' onClick={(e) => this.updatetrackset()}>
                                Update
                              </button>

                              <button type='button' class='btn btn-danger' onClick={(e) => this.cleartrackset(e)}>
                                Clear
                              </button>
                            </center>
                          </>
                        )}
                        {/* <center><button type="submit" onClick={this.updatetrackset} data-dismiss="modal" class="btn btn-danger">Update</button></center> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class='modal fade' id='newaddmeet' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        {' '}
                        {this.state.trackeventsdata + ' ' + 'Trackset'}{' '}
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='inputAddress'>Event Name</label>
                          <input
                            class='form-control'
                            id='inputAddress'
                            placeholder='Event Name'
                            name='evntname'
                            value={this.state.trackeventsdata}
                            onChange={this.optionchange}
                            // required
                            disabled
                          ></input>
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>max Per Heat</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            placeholder='max per heat'
                            name='mfh'
                            value={this.state.maxheat}
                            onChange={this.optionchange1}
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Seed Method</label>
                          <select
                            class='form-control'
                            id='exampleFormControlSelect1'
                            value={this.state.seeddata}
                            onChange={this.dropdownchange2}
                            required
                          >
                            <option>Please select</option>
                            <option value='Lanes'>Lanes</option>
                            <option value='Waterlanes'>Waterlanes</option>
                            <option value='Lanes/2'>Lanes/2</option>
                            <option value='Open/Lanes'>Open/Lanes</option>
                            <option value='Open/Waterfall'>Open/Waterfall</option>
                          </select>
                        </div>

                        {/*
                         */}

                        <div class='form-group'>
                          <label for='inputAddress'>Team Scoring</label>
                          <select
                            class='form-control'
                            id='exampleFormControlSelect1'
                            value={this.state.teamdata}
                            onChange={this.dropdownchange4}
                            required
                          >
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                          </select>
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Event Date</label>
                          <input
                            type='date'
                            class='form-control'
                            id='inputAddress'
                            placeholder='1234 Main St'
                            name='evntdate'
                            value={this.state.meetstartdata}
                            onChange={this.optionchange2}
                            required
                          />
                        </div>

                        {this.state.btncheck == true ? (
                          <center>
                            <button
                              type='Submit'
                              onClick={(e) => this.createtrackset(e)}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Create
                            </button>
                          </center>
                        ) : (
                          <>
                            <center>
                              <button type='button' class='btn btn-danger' onClick={(e) => this.updatetrackset()}>
                                Update
                              </button>
                            </center>
                          </>
                        )}
                        {/* <center><button type="submit" onClick={this.updatetrackset} data-dismiss="modal" class="btn btn-danger">Update</button></center> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class='modal fade' id='newmeet' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Track Location set
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='inputAddress'>Venue Name</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='venuename'
                            value={this.state.venuedata}
                            onChange={this.optionchange3}
                            placeholder='Enter venue name'
                            disabled='disabled'
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Venue location</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='venuelocation'
                            value={this.state.venuelocationdata}
                            onChange={this.optionchange4}
                            placeholder='Enter Venue location'
                            required
                          />
                        </div>

                        {/* <div class="form-group">
                          <label for="inputAddress">Number of events</label>
                          <input
                            type="text"
                            class="form-control"
                            id="inputAddress"
                            name="numevents"
                            value={this.state.numberdata}
                            onChange={this.optionchange5}
                            placeholder="Enter Number of events"
                            disabled="disabled"
                            required
                          />
                        </div> */}

                        <div class='form-group'>
                          <label for='inputAddress'>Number of Lanes</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='numevents'
                            value={this.state.lanedata}
                            onChange={this.optionchange6}
                            placeholder='Enter Number of events'
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Current Record Level</label>
                          <select
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='currecord'
                            value={this.state.currentdata}
                            onChange={this.optionchange7}
                            placeholder='Enter Current record'
                            required
                          >
                            <option>Please Select</option>
                            <option value='National' selected='true'>
                              National
                            </option>
                            <option value='American IPC'>American IPC</option>
                            <option value='Americas IPC'>Americas IPC</option>
                            <option value='NJDC'>NJDC</option>
                          </select>
                        </div>
                        <div class='form-group'>
                          <label for='inputAddress'>WA Rules</label>
                          <select
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='currecord'
                            value={this.state.iaffrules}
                            onChange={this.optionchange8}
                            placeholder='Enter Current record'
                            required
                          >
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                          </select>
                        </div>

                        {this.state.btncheck1 == true ? (
                          <center>
                            <button
                              type='button'
                              onClick={(e) => this.createtracklocationset()}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Create
                            </button>
                          </center>
                        ) : (
                          <>
                            <center>
                              <button
                                type='button'
                                class='btn btn-danger'
                                onClick={(e) => this.updatetracklocationset()}
                              >
                                Update
                              </button>
                            </center>
                          </>
                        )}
                        {/* <center><button type="submit" onClick={this.updatetrackset} data-dismiss="modal" class="btn btn-danger">Update</button></center> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Trackset;
