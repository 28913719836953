import React from 'react';
import { Transfer, Switch } from 'antd';

const mockData = [];
// for (let i = 0; i < 20; i++) {
mockData.push(
  {
    key: 'age',
    title: 'Age',
    description: `description of age`,
    //   disabled: i % 3 < 1,
  },
  {
    key: 'dob',
    title: 'DOB',
    description: `description of DOB`,
    //   disabled: i % 3 < 1,
  },
  {
    key: 'gender',
    title: 'Gender',
    description: `description of gender`,
    //   disabled: i % 3 < 1,
  }
);
//}

class Sorttransfer extends React.Component {
  state = {
    targetKeys: [],
    selectedKeys: [],
    sortOrderArray: [],
    finalSortOrderArray: [],
    sortOrderBy: 'ASC',
    disabled: false,
  };
  handleChange = (nextTargetKeys, direction, moveKeys) => {
    let nextTarget = nextTargetKeys.reverse();
    this.setState({ targetKeys: nextTarget });
    let sortOrderArray = this.state.sortOrderArray;
    let finalSortOrderArray = this.state.finalSortOrderArray;
    if (direction == 'right') {
      nextTarget.forEach((element, index) => {
        if (!sortOrderArray.includes(element)) {
          finalSortOrderArray.push(element + ' ' + this.state.sortOrderBy);
          sortOrderArray.push(element);
        }
      });
    } else if (direction == 'left') {
      sortOrderArray = [];
      finalSortOrderArray = [];
      nextTarget.forEach((element, index) => {
        finalSortOrderArray.push(element + ' ' + this.state.sortOrderBy);
        sortOrderArray.push(element);
      });
    }
    this.setState({ sortOrderArray });
    this.setState({ finalSortOrderArray });
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    });
  };

  sortOrder = (e) => {
    this.setState({
      sortOrderBy: e.target.value,
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { targetKeys, selectedKeys, disabled } = this.state;
    return (
      <>
        <div class='trans'>
          <Transfer
            dataSource={mockData}
            titles={['Source', 'Target']}
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            onChange={this.handleChange}
            onSelectChange={this.handleSelectChange}
            onScroll={this.handleScroll}
            render={(item) => item.title}
            disabled={disabled}
            oneWay
            style={{ marginBottom: 16 }}
          />
        </div>
        <div class='radiobib'>
          <input id='asc' type='radio' name='select' value='ASC' onChange={this.sortOrder} defaultChecked />
          {''}
          Ascending Order
          <input id='desc' type='radio' name='select' value='DESC' onChange={this.sortOrder} />
          {''}Descending Order
        </div>
        <div>
          <center>
            <button
              type='button'
              class='btn btn-danger'
              onClick={(e) => this.props.update(this.state.finalSortOrderArray)}
            >
              Update
            </button>
          </center>
        </div>
      </>
    );
  }
}

export default Sorttransfer;
