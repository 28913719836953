import React from 'react';

import '../App.css';

import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import 'datatables.net';
import 'datatables.net-bs4';
import { faWindows } from '@fortawesome/free-brands-svg-icons';

import FullScreenMap2 from './Venueclasses';
import FullScreenMap from './Venuesload.js';

import { Empty } from 'antd';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
class Classagegroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      venuedatas: [{}],
      newvenue: '',
      vname: '',
      vvalue: '',
      venuehome: true,
      eventhome: false,
      venueclasshome: false,
      venuespecfic: [{}],
      editename: '',
      editeid: '',
      newevents: '',
      newvenueclass: '',
      evenueclassname: '',
      evenueclassid: '',
      venuebyid: [{}],
      newclassvalue: '',
      vid: [{}],
      newclsvalue: '',
      newclsid: '',
      rerend: false,
      active1: false,
      loader: false,
      venuespecficid: '',
      classvaluedatas: [{}],
      classagedatas: [{}],
      preloader: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.createagegroup = this.createagegroup.bind(this);
    this.updateagegroup = this.updateagegroup.bind(this);
    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);
    this.back = this.back.bind(this);
  }

  componentDidMount() {
    var self = this;
    self.setState({ venuespecfic: this.props.did });

    let eventname = sessionStorage.getItem('eventname');
    let classvaluename = sessionStorage.getItem('classvaluename');

    let classvalueid = sessionStorage.getItem('classvalueid');
    let eventid = sessionStorage.getItem('eventid');
    axios
      .get(`${url.url}/api/getclasseventid/${eventid}/${classvalueid}`, {})
      .then(function (response) {
        self.setState({
          classagedatas: response.data,
          preloader: false,
        });
      })
      .catch(function (error) {
        self.setState({
          preloader: false,
        });
      });
  }

  onSearch = (e) => {
    var value = e.target.value.toLowerCase();
    $('#myTable tr').filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
  };

  handleChange(e) {
    var self = this;
    self.setState({
      [e.target.name]: e.target.value,
    });
    self.setState({
      newclsvalue: e.target.value,
    });
  }

  createagegroup(e) {
    var self = this;
    e.preventDefault();
    let classvalueid = sessionStorage.getItem('classvalueid');
    let eventid = sessionStorage.getItem('eventid');
    const datatosend = {
      name: this.state.newclassvalue,
      event_id: parseInt(eventid),
      classvalue_id: classvalueid,
    };
    axios
      .post(`${url.url}/api/createclassage/`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'Created' || response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'AgeGroup Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong Please check the field name',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  edit(e) {
    var self = this;
    self.setState({
      newclsvalue: this.state.classagedatas[e].name,
      newclsid: this.state.classagedatas[e].id,
    });
  }

  updateagegroup(e) {
    var self = this;
    e.preventDefault();
    const datatosend = {
      name: this.state.newclsvalue,
    };
    axios
      .put(`${url.url}/api/updateclassage/${this.state.newclsid}`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'AgeGroup Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  delete(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deleteclassage/${this.state.classagedatas[e].id}`, {})
          .then(function (response) {})

          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });
  }

  back() {
    if (
      sessionStorage.getItem('eventname') == 'paratriathlon 100' ||
      sessionStorage.getItem('eventname') == 'paratriathlon 200' ||
      sessionStorage.getItem('eventname') == '3x3 basket ball div1' ||
      sessionStorage.getItem('eventname') == '3x3 basket ball div2' ||
      sessionStorage.getItem('eventname') == '3X3 Basketball Women' ||
      sessionStorage.getItem('eventname') == 'women'
    ) {
      window.location.replace('/venuesevent');
    } else {
      var back = 'Women Powerlifting';
      window.location.replace('/classvalues?state=' + encodeURIComponent(back));
    }
  }

  render() {
    let eventname = sessionStorage.getItem('eventname');
    let classvaluename = sessionStorage.getItem('classvaluename');
    // venue home ----------------------------------------------------------------------------------------------------

    return (
      <>
        {this.state.preloader ? (
          <>
            <div className='svg-wrapper'>
              <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div className='content'>
              <Row>
                <Col md='8'>
                  <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                    Back
                  </button>
                  <button type='button' data-toggle='modal' data-target='#addvenue' class='btn btn-danger btn-round'>
                    New Age Group
                  </button>
                  <Card>
                    <CardHeader>
                      <h5 className='title'>
                        {eventname +
                          (eventname == 'paratriathlon 100' ||
                          eventname == 'paratriathlon 200' ||
                          eventname == '3x3 basket ball div1' ||
                          eventname == '3x3 basket ball div2' ||
                          eventname == '3X3 Basketball Women' ||
                          // eventname == "Yeoman"||
                          // eventname == "Bowmen"||
                          // eventname == "Cub"||
                          // eventname == "Cadet"||
                          // eventname == "Junior"||
                          // eventname == "Young Adult"||
                          eventname == 'women'
                            ? ''
                            : '-' + classvaluename + 'Class') +
                          '-' +
                          'AgeGroup'}
                      </h5>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <div class='form-group row'>
                          <div class='col-xs-5'>
                            <CardHeader>
                              <p>Search</p>
                              <input
                                class='form-control'
                                id='inputsm'
                                onChange={(e) => this.onSearch(e)}
                                placeholder='Search..'
                                type='text'
                              />
                            </CardHeader>
                          </div>
                        </div>
                        {/* Table -------------------------------------------- */}
                        {Object.keys(this.state.classagedatas).length == 0 ? (
                          <Empty />
                        ) : (
                          <>
                            <Table id='my' responsive>
                              <thead>
                                <tr>
                                  <th className='text-center'>#</th>
                                  <th>Name</th>

                                  <th class='disabled-sorting text-right'>Actions</th>
                                </tr>
                              </thead>
                              <tbody id='myTable'>
                                {this.state.classagedatas.map((question, index) => (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{question.name}</td>
                                    <td class='td-actions text-right'>
                                      <button
                                        type='button'
                                        rel='tooltip'
                                        class='btn btn-warning btn-sm btn-icon'
                                        onClick={() => this.edit(index)}
                                        data-toggle='modal'
                                        data-target='#venueedit'
                                      >
                                        <i class='now-ui-icons ui-2_settings-90'></i>
                                      </button>
                                      {` `}
                                      <button
                                        type='button'
                                        rel='tooltip'
                                        class='btn btn-danger btn-sm btn-icon'
                                        onClick={() => this.delete(index)}
                                      >
                                        <i class='now-ui-icons ui-1_simple-remove'></i>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </>
                        )}
                        {/* Table -------------------------------------------- */}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <div class='modal fade' id='addvenue' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog '>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Add Agegroup{' '}
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <form>
                      <div class='modal-body'></div>
                      <div class='form-group col-md-8'>
                        <label for='inputEmail4'>Name *</label>
                        <input type='text' class='form-control' name='newclassvalue' onChange={this.handleChange} />
                      </div>

                      <center>
                        <button type='submit' onClick={this.createagegroup} data-dismiss='modal' class='btn btn-danger'>
                          Create
                        </button>
                      </center>
                    </form>
                  </div>
                </div>
              </div>

              {/* edit */}
              <div class='modal fade' id='venueedit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog '>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Edit Agegroup{' '}
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <form>
                      <div class='modal-body'></div>
                      <div class='form-group col-md-8'>
                        <label for='inputEmail4'>Name *</label>
                        <input
                          type='text'
                          class='form-control'
                          name='vname'
                          value={this.state.newclsvalue}
                          onChange={this.handleChange}
                        />
                      </div>
                      <center>
                        <button type='submit' onClick={this.updateagegroup} data-dismiss='modal' class='btn btn-danger'>
                          Update
                        </button>
                      </center>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Classagegroup;
