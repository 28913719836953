import React from 'react';

import '../App.css';
import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';

import { Empty } from 'antd';
import 'datatables.net';
import 'datatables.net-bs4';
import Loader from 'react-loader-spinner';
import ReactToPrint from 'react-to-print';
import Printfieldworksheet from './Printfieldworksheet';
var temps;
const url = window.globalConfig;
class Fieldshowresult extends React.Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    this.state = {
      event: queryParams.get('event'),
      sort: queryParams.get('sort'),
      event_data: [{}],
      relay: false,
      notrelay: false,
      implementdata: '',
      finalplacedata: '',
      finalresultdata: '',
      qualdata: '',
      qualifyingmarksdata: '',
      fielddatas: [{}],
      meetdatas: [{}],
      teampoindata: '',
      loader: true,
    };
    this.back = this.back.bind(this);
    this.edit = this.edit.bind(this);
    this.update = this.update.bind(this);
    this.edit = this.edit.bind(this);
    this.update = this.update.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.teampoints = this.teampoints.bind(this);
  }
  async componentDidMount() {
    var self = this;
    var count = 0;
    const data = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };

    await axios
      .post(`${url.url}/api/getregisterfieldevent/`, data, {})
      .then(function (response) {
        self.setState({ event_data: response.data });
        count++;
        if (count == 2) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {});
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';

    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    // document.getElementById("venueesss").style.display = "block";

    axios
      .get(`${url.url}/api/getmeetdetail/${sessionStorage.getItem('ids')}`, {})
      .then(function (response) {
        self.setState({
          meetdatas: response.data,
        });
        count++;
        if (count == 2) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {});
  }

  handlechange(e) {
    var self = this;
    self.setState({
      qualdata: e.target.value,
    });
  }

  back() {
    window.location.replace('/venuemainpage?event=' + sessionStorage.getItem('event'));
  }
  edit(e) {
    var self = this;
    self.setState({
      eventdatas: this.state.event_data[e],
      implementdata: this.state.event_data[e].implements,
      finalresultdata: this.state.event_data[e].final_results,
      finalplacedata: this.state.event_data[e].final_place,
      qualdata: this.state.event_data[e].qualmarks,
      qualifyingmarksdata: this.state.event_data[e].Qualifying_marks,
      teampoindata: this.state.event_data[0].final_place,
    });
  }

  update(e) {
    const datatosend = {
      points: this.state.teampoindata,
      implements: this.state.implementdata,
      final_results: this.state.finalresultdata,
      final_place: this.state.finalplacedata,
      qualmarks: this.state.qualifyingmarksdata,
    };
    axios
      .put(`${url.url}/api/updatefieldshowresult/${this.state.eventdatas.id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  teampoints(e) {
    if (e == 1) {
      return <td>{this.state.meetdatas[0].place1}</td>;
    } else if (e == 2) {
      return <td>{this.state.meetdatas[0].place2} </td>;
    } else if (e == 3) {
      return <td>{this.state.meetdatas[0].place3} </td>;
    } else if (e == 4) {
      return <td>{this.state.meetdatas[0].place4} </td>;
    } else if (e == 5) {
      return <td>{this.state.meetdatas[0].place5} </td>;
    } else if (e == 6) {
      return <td>{this.state.meetdatas[0].place6} </td>;
    } else if (e == 7) {
      return <td>{this.state.meetdatas[0].place7} </td>;
    } else if (e == 8) {
      return <td>{this.state.meetdatas[0].place8} </td>;
    } else if (e == 9) {
      return <td>{this.state.meetdatas[0].place9} </td>;
    } else {
      return <td>{this.state.meetdatas[0].place10} </td>;
    }
  }

  render() {
    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader
                type='ThreeDots'
                color='#00BFFF'
                height={70}
                width={70}
                // timeout={3000} //3 secs
              />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div>
              <div>
                <h1> </h1>
                <Row>
                  <Col>
                    <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                      Back
                    </button>
                    <Card className='scrollcard'>
                      <CardHeader>
                        <h5 className='title'>
                          {this.state.event} <br></br> Show Result Worksheet
                        </h5>
                      </CardHeader>
                      <CardBody>
                        <div class='form-group row printrow'>
                          <div class='col-xs-5'>
                            <CardHeader>
                              <center>
                                <ReactToPrint
                                  trigger={() => (
                                    <button type='button' class='btn btn-success printbtn'>
                                      Print Show Result Worksheet
                                    </button>
                                  )}
                                  content={() => this.componentRef2}
                                />
                                <div class='textnone' style={{ display: 'none' }}>
                                  <Printfieldworksheet
                                    ref={(el) => (this.componentRef2 = el)}
                                    event={this.state.event}
                                  />
                                </div>
                              </center>
                            </CardHeader>
                          </div>
                        </div>
                        <Form>
                          {Object.keys(this.state.event_data).length == 0 ? (
                            <Empty />
                          ) : (
                            <>
                              <table class='worksheet_table'>
                                <thead>
                                  <tr class='worksheet_tr'>
                                    <th>Pit</th>
                                    <th>Group</th>
                                    <th>Class</th>
                                    <th>Age</th>
                                    <th>Gender</th>
                                    <th>Qual Mark</th>
                                    <th>Bib</th>
                                    <th>Last Name</th>
                                    <th>First Name</th>
                                    <th>Final Results</th>
                                    <th>Final Place</th>
                                    <th>Wind</th>
                                    <th>Implements</th>
                                    <th>Wtfinal</th>
                                    <th>Qual Std</th>
                                    <th>EventName</th>
                                    <th>Team Points</th>
                                    <th>Qualifies</th>
                                    {/* <th>Age as of December</th> */}
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.event_data.map((question, index) => (
                                    <tr class='worksheet_tr'>
                                      <td>{question.pits}</td>
                                      <td>{question.groups}</td>
                                      <td>{question.field_class_value}</td>
                                      <td>{question.age_division}</td>
                                      <td>{question.gender}</td>
                                      <td>{question.Qualifying_marks}</td>
                                      <td>{question.bib_no}</td>
                                      <td>{question.last_name}</td>
                                      <td>{question.first_name}</td>
                                      <td>{question.final_results}</td>
                                      <td>{question.final_place}</td>
                                      <td>{question.wind}</td>
                                      <td>{question.implements}</td>
                                      <td>{question.wtfinal}</td>
                                      <td>{question.qualifying_standard}</td>

                                      <td>{question.selected_events}</td>
                                      {this.teampoints(question.final_place)}
                                      <td>
                                        {question.qualifying_standard == null
                                          ? 'NRF'
                                          : question.final_result <= question.qualifying_standard
                                          ? 'Yes'
                                          : 'No'}
                                      </td>
                                      {/* <td>{question.age}</td> */}
                                      <td>
                                        <button
                                          type='button'
                                          rel='tooltip'
                                          class='btn btn-success btn-sm btn-icon'
                                          onClick={() => this.edit(index)}
                                          data-toggle='modal'
                                          data-target='#useredit'
                                        >
                                          <i class='now-ui-icons ui-2_settings-90'></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </>
                          )}
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
              <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Field Result WorkSheet
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        {/* <div class="form-row">
                    <div class="form-group">
                      <label for="inputCity">Qual Mark</label>
                      <input class="form-control" value={this.state.qualifyingmarksdata} onChange={e => this.setState({ qualifyingmarksdata: e.target.value })} name="relaypoint" />
                    </div>
                  </div> */}

                        <div class='form-row'>
                          <div class='form-group'>
                            <label for='inputCity'>Final Result</label>
                            <input
                              class='form-control'
                              value={this.state.finalresultdata}
                              onChange={(e) =>
                                this.setState({
                                  finalresultdata: e.target.value,
                                })
                              }
                              name='relaypoint'
                            />
                          </div>
                        </div>

                        <div class='form-row'>
                          <div class='form-group'>
                            <label for='inputCity'>Final Place</label>
                            <input
                              class='form-control'
                              value={this.state.finalplacedata}
                              onChange={(e) =>
                                this.setState({
                                  finalplacedata: e.target.value,
                                })
                              }
                              name='relaypoint'
                            />
                          </div>
                        </div>

                        <div class='form-row'>
                          <div class='form-group'>
                            <label for='inputCity'>Implements</label>
                            <input
                              class='form-control'
                              value={this.state.implementdata}
                              onChange={(e) => this.setState({ implementdata: e.target.value })}
                              name='relaypoint'
                            />
                          </div>
                        </div>

                        {/* <div class="form-row">
                    <div class="form-group">
                      <label for="inputCity">Team Points</label>
                      <input class="form-control" value={this.state.teampoindata} name="relaypoint" />
                    </div>
                  </div> */}

                        {/* {this.state.btncheck == true ?
                    <center><button type="button" onClick={(e) => this.create()} data-dismiss="modal" class="btn btn-danger">Create</button></center>
                    :
                    <> */}
                        <center>
                          <button type='button' class='btn btn-danger' onClick={(e) => this.update()}>
                            Update
                          </button>
                        </center>
                        {/* </>
                  } */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Fieldshowresult;
