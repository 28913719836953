import React from 'react';

import '../App.css';
import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import { Empty } from 'antd';
import 'datatables.net';
import 'datatables.net-bs4';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
class Printtrackperformancerecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event_data: [{}],
      loader: true,
      performancedatas: [{}],
      trackatfusasamsnjdcdatas: [{}],
      tracknjdcdatas: [{}],
    };
  }

  async componentDidMount() {
    var self = this;
    const data = {
      meet_id: sessionStorage.getItem('ids'),
    };

    await axios
      .post(`${url.url}/api/getprinttracksummary/`, data, {})
      .then(function (response) {
        self.setState({ event_data: response.data, loader: false });
      })
      .catch(function (error) {});
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    document.getElementById('homelink').style.display = 'block';
  }

  render() {
    return (
      <>
        <Form>
          <CardHeader>
            <center>
              <h5 className='title'>Track Performance Records</h5>
            </center>
          </CardHeader>
          {Object.keys(this.state.event_data).length == 0 ? (
            <Empty />
          ) : (
            <>
              <table class='worksheet_table wtable'>
                <thead>
                  <tr class='worksheet_tr'>
                    <th>Bib</th>
                    <th>Last Name</th>
                    <th>First Name</th>
                    <th>Class</th>
                    <th>Age</th>
                    <th>Gender</th>
                    <th>Prelim Results</th>
                    <th>Final Results</th>
                    <th>Wind</th>
                    <th>EventName</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.event_data.map(
                    (question, index) =>
                      question.result != null && (
                        <>
                          <tr className='worksheet_tr'>
                            <td>{question.bib_no}</td>
                            <td>{question.last_name}</td>
                            <td>{question.first_name}</td>
                            <td>{question.track_class_value}</td>
                            <td>{question.age_division}</td>
                            <td>{question.gender}</td>
                            <td>{question.prelim_result}</td>
                            <td>{question.final_result}</td>
                            <td>{question.wind}</td>
                            <td>{question.selected_events}</td>
                          </tr>
                          <tr>
                            <th></th>
                            <th>National</th>
                            <td>
                              {question.result}
                              {question.result != null ? 'Yes' : 'No'}
                            </td>
                            <th>American</th>
                            <td>{'No'}</td>
                            <th>Americas</th>
                            <td>{'No'}</td>
                            <th>Junior National</th>
                            <td>
                              {question.result}
                              {question.result != null ? 'Yes' : 'No'}
                            </td>
                          </tr>
                        </>
                      )
                  )}
                </tbody>
              </table>
            </>
          )}
        </Form>
      </>
    );
  }
}

export default Printtrackperformancerecord;
