import React from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';
import Swal from 'sweetalert2';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import Pagination from 'react-js-pagination';
import { Select, Input } from 'antd';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
var calage = '';
var caluatedage = '';
class Classification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classificationdata: [{}],
      classificationdatas: [],
      offset: 0,
      tableData: [],
      activePage: 1,
      Yob1: '',
      itemsCountPerPage: 1,
      totalItemsCount: 1,
      pageRangeDisplayed: 5,
      loader: true,
      selectgender: '',
      age_division: '',
      editclassificationdata: [{}],
      editclassificationdatas: [{}],
      ifclassification: true,
      ifallclassification: false,
      value: '',
      yob: '',
      age1: '',
      ipcswimcodes: '',
    };

    this.handlePageChange = this.handlePageChange.bind(this);
    this.optionchange = this.optionchange.bind(this);
    this.addclassification = this.addclassification.bind(this);
    this.genderchange = this.genderchange.bind(this);
    this.editclassificationrecord = this.editclassificationrecord.bind(this);
    this.updateclassificationrecord = this.updateclassificationrecord.bind(this);
    this.deleteclassificationrecord = this.deleteclassificationrecord.bind(this);
  }

  //
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });

    var self = this;
    axios.get(`${url.url}/api/getallclassificationrecord?page=` + pageNumber, {}).then(function (response) {
      self.setState({
        classificationdata: response.data.data,
        itemsCountPerPage: response.data.per_page,
        totalItemsCount: response.data.total,
        activePage: response.data.current_page,
        loader: false,
      });
    });
  }

  componentDidMount() {
    this.handlePageChange();

    var self = this;
    axios.get(`${url.url}/api/getAllclassificationdatas`, {}).then(function (response) {
      self.setState({
        classificationdatas: response.data,
        loader: false,
      });
    });
  }

  onSearch = (e) => {
    var value = e.target.value.toLowerCase();
    if (!value) {
      this.setState({
        ifclassification: true,
        ifallclassification: false,
      });
      return null;
    }
    this.setState({
      value: e.target.value,
      ifclassification: false,
      ifallclassification: true,
    });
  };

  optionchange(e) {
    var self = this;
    self.setState({
      [e.target.name]: e.target.value,
    });
  }

  genderchange(e) {
    this.setState({
      selectgender: e.target.value,
    });
  }

  addclassification() {
    const datatosend = {
      first_name: this.state.firstname,
      last_name: this.state.lastname,
      sex: this.state.selectgender,
      yob: this.state.yob,
      state: this.state.states,
      year_classified: this.state.year_classified,
      track_class: this.state.trackclass,
      field_class: this.state.fieldclass,
      fencing_class: this.state.fencing_class,
      tt_class: this.state.ttclass,
      archery_class: this.state.archeryclass,
      swim_base: this.state.swimbase,
      swim_breast: this.state.swimbreast,
      swim_medley: this.state.swimmedley,
      track_class_year: this.state.track_class_year,
      fieldclass_base_year: this.state.fieldclass_base_year,
      swimclass_base_year: this.state.swimclass_base_year,
      swimclass_breast_year: this.state.swimclass_breast_year,
      swimclass_medley_year: this.state.swimclass_medley_year,
      tt_class_year: this.state.tt_class_year,
      archery_class_year: this.state.archery_class_year,
      fnc_class_year: this.state.fnc_class_year,
      ipcfieldcodes: this.state.ipcfieldcodes,
      ent: this.state.ent,
      flag: this.state.flag,
      ipcsdmsid: this.state.ipcsdmsid,
      ipcswimcodes: this.state.ipcswimcodes,
      disability_code: this.state.disabilitycode,
      dso: this.state.dso,
      comments: this.state.comments,
    };

    axios
      .post(`${url.url}/api/createclassificationrecord/`, datatosend, {})
      .then(function (response) {
        if (response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'Classification Created successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  editclassificationrecord(e) {
    var self = this;
    self.setState({
      editclassificationdata: this.state.classificationdata[e],
      firstname: this.state.classificationdata[e].first_name,
      lastname: this.state.classificationdata[e].last_name,
      selectgender: this.state.classificationdata[e].sex,
      yob: this.state.classificationdata[e].yob,
      states: this.state.classificationdata[e].state,
      year_classified: this.state.classificationdata[e].year_classified,
      trackclass: this.state.classificationdata[e].track_class,
      fieldclass: this.state.classificationdata[e].field_class,
      fencing_class: this.state.classificationdata[e].fencing_class,
      ttclass: this.state.classificationdata[e].tt_class,
      archeryclass: this.state.classificationdata[e].archery_class,
      swimbase: this.state.classificationdata[e].swim_base,
      swimbreast: this.state.classificationdata[e].swim_breast,
      swimmedley: this.state.classificationdata[e].swim_medley,
      track_class_year: this.state.classificationdata[e].track_class_year,
      fieldclass_base_year: this.state.classificationdata[e].fieldclass_base_year,
      swimclass_base_year: this.state.classificationdata[e].swimclass_base_year,
      swimclass_breast_year: this.state.classificationdata[e].swimclass_breast_year,
      swimclass_medley_year: this.state.classificationdata[e].swimclass_medley_year,
      tt_class_year: this.state.classificationdata[e].tt_class_year,
      archery_class_year: this.state.classificationdata[e].archery_class_year,
      fnc_class_year: this.state.classificationdata[e].fnc_class_year,
      ipcfieldcodes: this.state.classificationdata[e].ipcfieldcodes,
      ent: this.state.classificationdata[e].ent,
      flag: this.state.classificationdata[e].flag,
      ipcsdmsid: this.state.classificationdata[e].ipcsdmsid,
      ipcswimcodes: this.state.classificationdata[e].ipcswimcodes,
      disabilitycode: this.state.classificationdata[e].disability_code,
      dso: this.state.classificationdata[e].dso,
      comments: this.state.classificationdata[e].comments,
    });
  }
  editclassificationrecordAfterSearch = (e) => {
    var self = this;
    var found = this.state.classificationdatas.find((question) => question.id == e);
    self.setState({
      editclassificationdatas: found,
      firstname: found.first_name,
      lastname: found.last_name,
      selectgender: found.sex,
      yob: found.yob,
      states: found.state,
      year_classified: found.year_classified,
      trackclass: found.track_class,
      fieldclass: found.field_class,
      fencing_class: found.fencing_class,
      ttclass: found.tt_class,
      archeryclass: found.archery_class,
      swimbase: found.swim_base,
      swimbreast: found.swim_breast,
      swimmedley: found.swim_medley,
      track_class_year: found.track_class_year,
      fieldclass_base_year: found.fieldclass_base_year,
      swimclass_base_year: found.swimclass_base_year,
      swimclass_breast_year: found.swimclass_breast_year,
      swimclass_medley_year: found.swimclass_medley_year,
      tt_class_year: found.tt_class_year,
      archery_class_year: found.archery_class_year,
      fnc_class_year: found.fnc_class_year,
      ipcfieldcodes: found.ipcfieldcodes,
      ent: found.ent,
      flag: found.flag,
      ipcsdmsid: found.ipcsdmsid,
      ipcswimcodes: found.ipcswimcodes,
      disabilitycode: found.disability_code,
      dso: found.dso,
      comments: found.comments,
    });
  };

  updateclassificationrecord = (e) => {
    const datatosend = {
      first_name: this.state.firstname,
      last_name: this.state.lastname,
      sex: this.state.selectgender,
      yob: this.state.yob,
      state: this.state.states,
      year_classified: this.state.year_classified,
      track_class: this.state.trackclass,
      field_class: this.state.fieldclass,
      fencing_class: this.state.fencing_class,
      tt_class: this.state.ttclass,
      archery_class: this.state.archeryclass,
      swim_base: this.state.swimbase,
      swim_breast: this.state.swimbreast,
      swim_medley: this.state.swimmedley,
      track_class_year: this.state.track_class_year,
      fieldclass_base_year: this.state.fieldclass_base_year,
      swimclass_base_year: this.state.swimclass_base_year,
      swimclass_breast_year: this.state.swimclass_breast_year,
      swimclass_medley_year: this.state.swimclass_medley_year,
      tt_class_year: this.state.tt_class_year,
      archery_class_year: this.state.archery_class_year,
      fnc_class_year: this.state.fnc_class_year,
      ipcfieldcodes: this.state.ipcfieldcodes,
      ent: this.state.ent,
      flag: this.state.flag,
      ipcsdmsid: this.state.ipcsdmsid,
      ipcswimcodes: this.state.ipcswimcodes,
      disability_code: this.state.disabilitycode,
      dso: this.state.dso,
      comments: this.state.comments,
    };
    axios
      .put(`${url.url}/api/updateclassificationrecord/${this.state.editclassificationdata.id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Classification Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  };
  updateclassificationrecordaftersearch = (e) => {
    const datatosend = {
      first_name: this.state.firstname,
      last_name: this.state.lastname,
      sex: this.state.selectgender,
      yob: this.state.yob,
      state: this.state.states,
      year_classified: this.state.year_classified,
      track_class: this.state.trackclass,
      field_class: this.state.fieldclass,
      fencing_class: this.state.fencing_class,
      tt_class: this.state.ttclass,
      archery_class: this.state.archeryclass,
      swim_base: this.state.swimbase,
      swim_breast: this.state.swimbreast,
      swim_medley: this.state.swimmedley,
      track_class_year: this.state.track_class_year,
      fieldclass_base_year: this.state.fieldclass_base_year,
      swimclass_base_year: this.state.swimclass_base_year,
      swimclass_breast_year: this.state.swimclass_breast_year,
      swimclass_medley_year: this.state.swimclass_medley_year,
      tt_class_year: this.state.tt_class_year,
      archery_class_year: this.state.archery_class_year,
      fnc_class_year: this.state.fnc_class_year,
      ipcfieldcodes: this.state.ipcfieldcodes,
      ent: this.state.ent,
      flag: this.state.flag,
      ipcsdmsid: this.state.ipcsdmsid,
      ipcswimcodes: this.state.ipcswimcodes,
      disability_code: this.state.disabilitycode,
      dso: this.state.dso,
      comments: this.state.comments,
    };

    axios
      .put(
        `${url.url}/api/updateclassificationrecordaftersearch/${this.state.editclassificationdatas.id}`,
        datatosend,
        {}
      )
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Classification Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  };

  deleteclassificationrecord(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deleteclassificationrecord/${this.state.classificationdata[e].id}`, {})
          .then(function (response) {})

          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });
  }
  calculate_age = (Yob1) => {
    var today = new Date();
    var birthDate = new Date(Yob1); // create a date object directly from `Yob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    return age_now;
  };

  handlechange1 = (event) => {
    this.setState({
      yob: event.target.value,
    });

    var currentdate = new Date().getFullYear();
    calage = currentdate - event.target.value;

    if (event.target.value.length == '4') {
      this.setState({
        age1: calage,
      });
    } else {
      this.setState({
        age1: 0,
      });
    }
    if (calage >= 60) {
      this.setState({
        age_division: 'M60',
      });
    }
    if (calage >= 50 && calage <= 59) {
      this.setState({
        age_division: 'M50',
      });
    }
    if (calage >= 35 && calage <= 49) {
      this.setState({
        age_division: 'M35',
      });
    }
    if (calage >= 23 && calage <= 34) {
      this.setState({
        age_division: 'Adults',
      });
    }
    if (calage >= 20 && calage <= 22) {
      this.setState({
        age_division: 'U23',
      });
    }
    if (calage >= 17 && calage <= 19) {
      this.setState({
        age_division: 'U20',
      });
    }
    if (calage >= 14 && calage <= 16) {
      this.setState({
        age_division: 'U17',
      });
    }
    if (calage >= 11 && calage <= 13) {
      this.setState({
        age_division: 'U14',
      });
    }
    if (calage >= 7 && calage <= 10) {
      this.setState({
        age_division: 'U11',
      });
    }
    if (calage <= 6) {
      this.setState({
        age_division: 'Futures',
      });
    }
  };

  handlechange2 = (event) => {
    this.setState({
      yob: event.target.value,
    });
    var currentdate = new Date().getFullYear();
    calage = currentdate - event.target.value;
    if (event.target.value.length == '4') {
      this.setState({
        age1: calage,
      });
    } else {
      this.setState({
        age1: 0,
      });
    }
    if (calage >= 60) {
      this.setState({
        age_division: 'M60',
      });
    }
    if (calage >= 50 && calage <= 59) {
      this.setState({
        age_division: 'M50',
      });
    }
    if (calage >= 35 && calage <= 49) {
      this.setState({
        age_division: 'M35',
      });
    }
    if (calage >= 23 && calage <= 34) {
      this.setState({
        age_division: 'Adults',
      });
    }
    if (calage >= 20 && calage <= 22) {
      this.setState({
        age_division: 'U23',
      });
    }
    if (calage >= 17 && calage <= 19) {
      this.setState({
        age_division: 'U20',
      });
    }
    if (calage >= 14 && calage <= 16) {
      this.setState({
        age_division: 'U17',
      });
    }
    if (calage >= 11 && calage <= 13) {
      this.setState({
        age_division: 'U14',
      });
    }
    if (calage >= 7 && calage <= 10) {
      this.setState({
        age_division: 'U11',
      });
    }
    if (calage <= 6) {
      this.setState({
        age_division: 'Futures',
      });
    }
  };
  render() {
    let filterclassificationdatas;
    if (this.state.classificationdatas.length != 0) {
      filterclassificationdatas = this.state.classificationdatas.filter((classificationdata) => {
        return (
          classificationdata.first_name.toLowerCase().includes(this.state.value.toLowerCase()) ||
          classificationdata.last_name.toLowerCase().includes(this.state.value.toLowerCase())
        );
      });
    }
    if (this.state.ifclassification) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div className='content'>
                <Row>
                  <Col md='8'>
                    <button type='button' data-toggle='modal' data-target='#newmeet' class='btn btn-danger btn-round'>
                      Add Classification
                    </button>
                    <Card>
                      <CardHeader>
                        <h5 className='title'>Classification</h5>
                      </CardHeader>

                      <CardBody>
                        <Form>
                          <div class='form-group row'>
                            <div class='col-xs-5'>
                              <CardHeader>
                                <p>Search</p>
                                <input
                                  class='form-control'
                                  id='inputsm'
                                  onChange={(e) => this.onSearch(e)}
                                  placeholder='Search..'
                                  type='text'
                                />
                              </CardHeader>
                            </div>
                          </div>

                          {/* table --------------------------------------------------- */}
                          <Table id='myclasstable' responsive>
                            <thead>
                              <tr>
                                <th className='text-center'>#</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Gender</th>
                                <th>State</th>
                                <th>Yob</th>
                                {/* <th>AgeDivision</th> */}
                                <th class='disabled-sorting text-right'>Actions</th>
                              </tr>
                            </thead>
                            <tbody id='myTable'>
                              {this.state.classificationdata.map((question, index) => (
                                <tr>
                                  <td>{question.id}</td>
                                  <td>{question.first_name}</td>
                                  <td>{question.last_name}</td>
                                  <td>{question.sex}</td>
                                  <td>{question.state}</td>
                                  <td>{question.yob}</td>
                                  {/* <td>{question.age_division}</td> */}
                                  <td class='td-actions text-right'>
                                    <button
                                      type='button'
                                      rel='tooltip'
                                      class='btn btn-success btn-sm btn-icon'
                                      onClick={() => this.editclassificationrecord(index)}
                                      data-toggle='modal'
                                      data-target='#meetedit'
                                    >
                                      <i class='now-ui-icons ui-2_settings-90'></i>
                                    </button>
                                    {` `}
                                    <button
                                      type='button'
                                      rel='tooltip'
                                      class='btn btn-danger btn-sm btn-icon'
                                      onClick={() => this.deleteclassificationrecord(index)}
                                    >
                                      <i class='now-ui-icons ui-1_simple-remove'></i>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.itemsCountPerPage}
                            totalItemsCount={this.state.totalItemsCount}
                            pageRangeDisplayed={this.state.pageRangeDisplayed}
                            onChange={(pageNumber) => this.handlePageChange(pageNumber)}
                            itemClass='page-item'
                            linkClass='page-link'
                          />
                          {/* table --------------------------------------------------- */}
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <div class='modal fade' id='meetedit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Edit Classification{' '}
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-row'>
                            <div class='form-group col-md-6'>
                              <label for='inputAddress'>First Name</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='firstname'
                                value={this.state.firstname}
                                onChange={(e) => this.setState({ firstname: e.target.value })}
                                placeholder='Enter First Name'
                                required
                              />
                            </div>
                            <div class='form-group col-md-6'>
                              <label for='inputAddress'>Last Name</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='lastname'
                                value={this.state.lastname}
                                onChange={(e) => this.setState({ lastname: e.target.value })}
                                placeholder='Enter Last Name'
                                required
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>Gender</label>
                              <select
                                id='inputState'
                                class='form-control'
                                name='gender'
                                value={this.state.selectgender}
                                onChange={this.genderchange}
                              >
                                <option selected>Choose...</option>
                                <option value='M'>Male</option>
                                <option value='F'>Female</option>
                              </select>
                            </div>
                            <div class='form-group col-md-4'>
                              <label for='YOB'>Yob</label>
                              <input
                                type='text'
                                class='form-control'
                                id='YOB'
                                value={this.state.yob}
                                onChange={this.handlechange2}
                                placeholder='Enter Yob'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>State</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='states'
                                value={this.state.states}
                                onChange={(e) => this.setState({ states: e.target.value })}
                                placeholder='Enter State'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Year Classified</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='year_classified'
                                value={this.state.year_classified}
                                onChange={(e) => this.setState({ year_classified: e.target.value })}
                                placeholder='Enter Year Classified'
                                required
                              />
                            </div>

                            {/* <div class='form-row'>
                            <div class='form-group col-md-6'>
                              <label for='age'>Age</label>
                              <input
                                type='text'
                                class='form-control'
                                id='age'
                                placeholder='Enter your age'
                                value={this.state.age1}
                              />
                            </div>
                            <div class="form-group col-md-6">
                              <label>Age Division</label>
                              <input
                                type="text"
                                id="age_division"
                                class="form-control"
                                placeholder="Age Division"
                                value={this.state.age_division}
                                onChange={(e) =>
                                  this.setState({
                                    age_division: e.target.value,
                                  })
                                }
                              ></input>
                            </div>
                          </div> */}
                            {/* <div class='form-group col-md-6'>
                              <label for='exampleFormControlSelect1'>Ipc ID</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='ipcid'
                                value={this.state.ipcid}
                                onChange={(e) => this.setState({ ipcid: e.target.value })}
                                placeholder='Enter Ipc Id'
                                required
                              />
                            </div> */}
                            <div class='form-group col-md-4'>
                              <label>Track Class</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='trackclass'
                                value={this.state.trackclass}
                                onChange={(e) => this.setState({ trackclass: e.target.value })}
                                placeholder='Enter Track Class'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>Field Class</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='fieldclass'
                                value={this.state.fieldclass}
                                onChange={(e) => this.setState({ fieldclass: e.target.value })}
                                placeholder='Enter Field Class'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>Fencing Class</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='fencing_class'
                                value={this.state.fencing_class}
                                onChange={(e) => this.setState({ fencing_class: e.target.value })}
                                placeholder='Enter Fencing Class'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>TableTennis Class</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='ttclass'
                                value={this.state.ttclass}
                                onChange={(e) => this.setState({ ttclass: e.target.value })}
                                placeholder='Enter Tennis Class'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>Archery Class</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='archeryclass'
                                value={this.state.archeryclass}
                                onChange={(e) => this.setState({ archeryclass: e.target.value })}
                                placeholder='Enter Archery Class'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Swim Base</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='swimbase'
                                value={this.state.swimbase}
                                onChange={(e) => this.setState({ swimbase: e.target.value })}
                                placeholder='Enter Swim base'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>Swim Breast</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='swimbreast'
                                value={this.state.swimbreast}
                                onChange={(e) => this.setState({ swimbreast: e.target.value })}
                                placeholder='Enter Swim Breast'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Swim Medley</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='swimmedley'
                                value={this.state.swimmedley}
                                onChange={(e) => this.setState({ swimmedley: e.target.value })}
                                placeholder='Enter Swim Medley'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Track Class Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='track_class_year'
                                value={this.state.track_class_year}
                                onChange={(e) => this.setState({ track_class_year: e.target.value })}
                                placeholder='Enter Track Class Year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Field Class Base Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='fieldclass_base_year'
                                value={this.state.fieldclass_base_year}
                                onChange={(e) => this.setState({ fieldclass_base_year: e.target.value })}
                                placeholder='Enter Field Class Base Year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Swim Class Base Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='swimclass_base_year'
                                value={this.state.swimclass_base_year}
                                onChange={(e) => this.setState({ swimclass_base_year: e.target.value })}
                                placeholder='Enter Swim class base year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Swim Class Breast Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='swimclass_breast_year'
                                value={this.state.swimclass_breast_year}
                                onChange={(e) => this.setState({ swimclass_breast_year: e.target.value })}
                                placeholder='Enter Swim Class Breast Year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Swim Class Medly Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='swimclass_medley_year'
                                value={this.state.swimclass_medley_year}
                                onChange={(e) => this.setState({ swimclass_medley_year: e.target.value })}
                                placeholder='Enter Swim Class Medly Year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Table Tennis Class Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='tt_class_year'
                                value={this.state.tt_class_year}
                                onChange={(e) => this.setState({ tt_class_year: e.target.value })}
                                placeholder='Enter Table Tennis Class Year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Archery Class Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='archery_class_year'
                                value={this.state.archery_class_year}
                                onChange={(e) => this.setState({ archery_class_year: e.target.value })}
                                placeholder='Enter Archery Class Year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>FNC Class Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='fnc_class_year'
                                value={this.state.fnc_class_year}
                                onChange={(e) => this.setState({ fnc_class_year: e.target.value })}
                                placeholder='Enter FNC Class Year'
                                required
                              />
                            </div>
                            {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>Ipc Swim Codes</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='ipcswimcodes'
                                value={this.state.ipcswimcodes}
                                onChange={(e) => this.setState({ ipcswimcodes: e.target.value })}
                                placeholder='Enter Ipc Swim Codes'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>IPC Fieldcodes</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='ipcfieldcodes'
                                value={this.state.ipcfieldcodes}
                                onChange={(e) => this.setState({ ipcfieldcodes: e.target.value })}
                                placeholder='Enter IPC Fieldcodes'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Disability Code</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='disabilitycode'
                                value={this.state.disabilitycode}
                                onChange={(e) => this.setState({ disabilitycode: e.target.value })}
                                placeholder='Enter Disability Code'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>DSO</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='dso'
                                value={this.state.dso}
                                onChange={(e) => this.setState({ dso: e.target.value })}
                                placeholder='Enter DSO'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Comments</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='comments'
                                value={this.state.comments}
                                onChange={(e) => this.setState({ comments: e.target.value })}
                                placeholder='Enter Comments'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Ent</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='ent'
                                value={this.state.ent}
                                onChange={(e) => this.setState({ ent: e.target.value })}
                                placeholder='Enter Ent'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Flag</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='flag'
                                value={this.state.flag}
                                onChange={(e) => this.setState({ flag: e.target.value })}
                                placeholder='Enter Flag'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Ipcsdms ID</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='ipcsdmsid'
                                value={this.state.ipcsdmsid}
                                onChange={(e) => this.setState({ ipcsdmsid: e.target.value })}
                                placeholder='Enter Ipcsdms ID'
                                required
                              />
                            </div>
                          </div>

                          <center>
                            <button
                              type='submit'
                              onClick={this.updateclassificationrecord}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='modal fade' id='newmeet' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          New Classification{' '}
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-row'>
                            <div class='form-group col-md-6'>
                              <label for='inputAddress'>First Name</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='firstname'
                                onChange={this.optionchange}
                                placeholder='Enter First Name'
                                required
                              />
                            </div>
                            <div class='form-group col-md-6'>
                              <label for='inputAddress'>Last Name</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='lastname'
                                onChange={this.optionchange}
                                placeholder='Enter Last Name'
                                required
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>Gender</label>
                              <select id='inputState' class='form-control' name='gender' onChange={this.genderchange}>
                                <option selected>Choose...</option>
                                <option value='M'>Male</option>
                                <option value='F'>Female</option>
                              </select>
                            </div>
                            <div class='form-group col-md-4'>
                              <label for='YOB'>Yob</label>
                              <input
                                type='text'
                                class='form-control'
                                id='YOB'
                                onChange={this.handlechange2}
                                placeholder='Enter Yob'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>State</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='states'
                                onChange={(e) => this.setState({ states: e.target.value })}
                                placeholder='Enter State'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Year Classified</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='year_classified'
                                onChange={(e) => this.setState({ year_classified: e.target.value })}
                                placeholder='Enter Year Classified'
                                required
                              />
                            </div>

                            {/* <div class='form-row'>
                            <div class='form-group col-md-6'>
                              <label for='age'>Age</label>
                              <input
                                type='text'
                                class='form-control'
                                id='age'
                                placeholder='Enter your age'
                                value={this.state.age1}
                              />
                            </div>
                            <div class="form-group col-md-6">
                              <label>Age Division</label>
                              <input
                                type="text"
                                id="age_division"
                                class="form-control"
                                placeholder="Age Division"
                                value={this.state.age_division}
                                onChange={(e) =>
                                  this.setState({
                                    age_division: e.target.value,
                                  })
                                }
                              ></input>
                            </div>
                          </div> */}
                            {/* <div class='form-group col-md-6'>
                              <label for='exampleFormControlSelect1'>Ipc ID</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='ipcid'
                                value={this.state.ipcid}
                                onChange={(e) => this.setState({ ipcid: e.target.value })}
                                placeholder='Enter Ipc Id'
                                required
                              />
                            </div> */}
                            <div class='form-group col-md-4'>
                              <label>Track Class</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='trackclass'
                                onChange={(e) => this.setState({ trackclass: e.target.value })}
                                placeholder='Enter Track Class'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>Field Class</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='fieldclass'
                                onChange={(e) => this.setState({ fieldclass: e.target.value })}
                                placeholder='Enter Field Class'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>Fencing Class</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='fencing_class'
                                onChange={(e) => this.setState({ fencing_class: e.target.value })}
                                placeholder='Enter Fencing Class'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>TableTennis Class</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='ttclass'
                                onChange={(e) => this.setState({ ttclass: e.target.value })}
                                placeholder='Enter Tennis Class'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>Archery Class</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='archeryclass'
                                onChange={(e) => this.setState({ archeryclass: e.target.value })}
                                placeholder='Enter Archery Class'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Swim Base</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='swimbase'
                                onChange={(e) => this.setState({ swimbase: e.target.value })}
                                placeholder='Enter Swim base'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>Swim Breast</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='swimbreast'
                                onChange={(e) => this.setState({ swimbreast: e.target.value })}
                                placeholder='Enter Swim Breast'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Swim Medley</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='swimmedley'
                                onChange={(e) => this.setState({ swimmedley: e.target.value })}
                                placeholder='Enter Swim Medley'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Track Class Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='track_class_year'
                                onChange={(e) => this.setState({ track_class_year: e.target.value })}
                                placeholder='Enter Track Class Year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Field Class Base Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='fieldclass_base_year'
                                onChange={(e) => this.setState({ fieldclass_base_year: e.target.value })}
                                placeholder='Enter Field Class Base Year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Swim Class Base Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='swimclass_base_year'
                                onChange={(e) => this.setState({ swimclass_base_year: e.target.value })}
                                placeholder='Enter Swim class base year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Swim Class Breast Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='swimclass_breast_year'
                                onChange={(e) => this.setState({ swimclass_breast_year: e.target.value })}
                                placeholder='Enter Swim Class Breast Year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Swim Class Medly Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='swimclass_medley_year'
                                onChange={(e) => this.setState({ swimclass_medley_year: e.target.value })}
                                placeholder='Enter Swim Class Medly Year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Table Tennis Class Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='tt_class_year'
                                onChange={(e) => this.setState({ tt_class_year: e.target.value })}
                                placeholder='Enter Table Tennis Class Year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Archery Class Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='archery_class_year'
                                onChange={(e) => this.setState({ archery_class_year: e.target.value })}
                                placeholder='Enter Archery Class Year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>FNC Class Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='fnc_class_year'
                                onChange={(e) => this.setState({ fnc_class_year: e.target.value })}
                                placeholder='Enter FNC Class Year Year'
                                required
                              />
                            </div>
                            {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>Ipc Swim Codes</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='ipcswimcodes'
                                onChange={(e) => this.setState({ ipcswimcodes: e.target.value })}
                                placeholder='Enter Ipc Swim Codes'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>IPC Fieldcodes</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='ipcfieldcodes'
                                onChange={(e) => this.setState({ ipcfieldcodes: e.target.value })}
                                placeholder='Enter IPC Fieldcodes'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Disability Code</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='disabilitycode'
                                onChange={(e) => this.setState({ disabilitycode: e.target.value })}
                                placeholder='Enter Disability Code'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>DSO</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='dso'
                                onChange={(e) => this.setState({ dso: e.target.value })}
                                placeholder='Enter DSO'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Comments</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='comments'
                                onChange={(e) => this.setState({ comments: e.target.value })}
                                placeholder='Enter Comments'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Ent</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='ent'
                                onChange={(e) => this.setState({ ent: e.target.value })}
                                placeholder='Enter Ent'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Flag</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='flag'
                                onChange={(e) => this.setState({ flag: e.target.value })}
                                placeholder='Enter Flag'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Ipcsdms ID</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='ipcsdmsid'
                                onChange={(e) => this.setState({ ipcsdmsid: e.target.value })}
                                placeholder='Enter Ipcsdms ID'
                                required
                              />
                            </div>
                          </div>

                          <center>
                            <button
                              type='submit'
                              onClick={this.addclassification}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Create
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }

    if (this.state.ifallclassification) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div className='content'>
                <Row>
                  <Col md='8'>
                    <button type='button' data-toggle='modal' data-target='#newmeet' class='btn btn-danger btn-round'>
                      Add Classification
                    </button>
                    <Card>
                      <CardHeader>
                        <h5 className='title'>Classification</h5>
                      </CardHeader>

                      <CardBody>
                        <Form>
                          <div class='form-group row'>
                            <div class='col-xs-5'>
                              <CardHeader>
                                <p>Search</p>
                                <input
                                  class='form-control'
                                  id='inputsm'
                                  onChange={(e) => this.onSearch(e)}
                                  placeholder='Search..'
                                  type='text'
                                />
                              </CardHeader>
                            </div>
                          </div>

                          {/* table --------------------------------------------------- */}
                          <Table id='myclasstable' responsive>
                            <thead>
                              <tr>
                                <th className='text-center'>#</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Gender</th>
                                <th>State</th>
                                <th>Yob</th>
                                {/* <th>AgeDivision</th> */}
                                <th class='disabled-sorting text-right'>Actions</th>
                              </tr>
                            </thead>
                            <tbody id='myTable'>
                              {this.state.classificationdatas.length != 0
                                ? filterclassificationdatas.map((question, id) => (
                                    <tr>
                                      <td>{question.id}</td>
                                      <td>{question.first_name}</td>
                                      <td>{question.last_name}</td>
                                      <td>{question.sex}</td>
                                      <td>{question.state}</td>
                                      <td>{question.yob}</td>
                                      {/* <td>{question.age_division}</td> */}
                                      <td class='td-actions text-right'>
                                        <button
                                          type='button'
                                          rel='tooltip'
                                          class='btn btn-success btn-sm btn-icon'
                                          onClick={() => this.editclassificationrecordAfterSearch(question.id)}
                                          data-toggle='modal'
                                          data-target='#meetedit'
                                        >
                                          <i class='now-ui-icons ui-2_settings-90'></i>
                                        </button>
                                        {` `}
                                        <button
                                          type='button'
                                          rel='tooltip'
                                          class='btn btn-danger btn-sm btn-icon'
                                          onClick={() => this.deleteclassificationrecord(question.id)}
                                        >
                                          <i class='now-ui-icons ui-1_simple-remove'></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                                : ''}
                            </tbody>
                          </Table>
                          <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.itemsCountPerPage}
                            totalItemsCount={this.state.totalItemsCount}
                            pageRangeDisplayed={this.state.pageRangeDisplayed}
                            onChange={(pageNumber) => this.handlePageChange(pageNumber)}
                            itemClass='page-item'
                            linkClass='page-link'
                          />
                          {/* table --------------------------------------------------- */}
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <div class='modal fade' id='meetedit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Edit Classification{' '}
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-row'>
                            <div class='form-group col-md-6'>
                              <label for='inputAddress'>First Name</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='firstname'
                                value={this.state.firstname}
                                onChange={(e) => this.setState({ firstname: e.target.value })}
                                placeholder='Enter First Name'
                                required
                              />
                            </div>
                            <div class='form-group col-md-6'>
                              <label for='inputAddress'>Last Name</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='lastname'
                                value={this.state.lastname}
                                onChange={(e) => this.setState({ lastname: e.target.value })}
                                placeholder='Enter Last Name'
                                required
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>Gender</label>
                              <select
                                id='inputState'
                                class='form-control'
                                name='gender'
                                value={this.state.selectgender}
                                onChange={this.genderchange}
                              >
                                <option selected>Choose...</option>
                                <option value='M'>Male</option>
                                <option value='F'>Female</option>
                              </select>
                            </div>
                            <div class='form-group col-md-4'>
                              <label for='YOB'>Yob</label>
                              <input
                                type='text'
                                class='form-control'
                                id='YOB'
                                value={this.state.yob}
                                onChange={this.handlechange2}
                                placeholder='Enter Yob'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>State</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='states'
                                value={this.state.states}
                                onChange={(e) => this.setState({ states: e.target.value })}
                                placeholder='Enter State'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Year Classified</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='year_classified'
                                value={this.state.year_classified}
                                onChange={(e) => this.setState({ year_classified: e.target.value })}
                                placeholder='Enter Year Classified'
                                required
                              />
                            </div>

                            {/* <div class='form-row'>
                            <div class='form-group col-md-6'>
                              <label for='age'>Age</label>
                              <input
                                type='text'
                                class='form-control'
                                id='age'
                                placeholder='Enter your age'
                                value={this.state.age1}
                              />
                            </div>
                            <div class="form-group col-md-6">
                              <label>Age Division</label>
                              <input
                                type="text"
                                id="age_division"
                                class="form-control"
                                placeholder="Age Division"
                                value={this.state.age_division}
                                onChange={(e) =>
                                  this.setState({
                                    age_division: e.target.value,
                                  })
                                }
                              ></input>
                            </div>
                          </div> */}
                            {/* <div class='form-group col-md-6'>
                              <label for='exampleFormControlSelect1'>Ipc ID</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='ipcid'
                                value={this.state.ipcid}
                                onChange={(e) => this.setState({ ipcid: e.target.value })}
                                placeholder='Enter Ipc Id'
                                required
                              />
                            </div> */}
                            <div class='form-group col-md-4'>
                              <label>Track Class</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='trackclass'
                                value={this.state.trackclass}
                                onChange={(e) => this.setState({ trackclass: e.target.value })}
                                placeholder='Enter Track Class'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>Field Class</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='fieldclass'
                                value={this.state.fieldclass}
                                onChange={(e) => this.setState({ fieldclass: e.target.value })}
                                placeholder='Enter Field Class'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>Fencing Class</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='fencing_class'
                                value={this.state.fencing_class}
                                onChange={(e) => this.setState({ fencing_class: e.target.value })}
                                placeholder='Enter Fencing Class'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>TableTennis Class</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='ttclass'
                                value={this.state.ttclass}
                                onChange={(e) => this.setState({ ttclass: e.target.value })}
                                placeholder='Enter Tennis Class'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>Archery Class</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='archeryclass'
                                value={this.state.archeryclass}
                                onChange={(e) => this.setState({ archeryclass: e.target.value })}
                                placeholder='Enter Archery Class'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Swim Base</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='swimbase'
                                value={this.state.swimbase}
                                onChange={(e) => this.setState({ swimbase: e.target.value })}
                                placeholder='Enter Swim base'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>Swim Breast</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='swimbreast'
                                value={this.state.swimbreast}
                                onChange={(e) => this.setState({ swimbreast: e.target.value })}
                                placeholder='Enter Swim Breast'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Swim Medley</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='swimmedley'
                                value={this.state.swimmedley}
                                onChange={(e) => this.setState({ swimmedley: e.target.value })}
                                placeholder='Enter Swim Medley'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Track Class Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='track_class_year'
                                value={this.state.track_class_year}
                                onChange={(e) => this.setState({ track_class_year: e.target.value })}
                                placeholder='Enter Track Class Year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Field Class Base Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='fieldclass_base_year'
                                value={this.state.fieldclass_base_year}
                                onChange={(e) => this.setState({ fieldclass_base_year: e.target.value })}
                                placeholder='Enter Field Class Base Year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Swim Class Base Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='swimclass_base_year'
                                value={this.state.swimclass_base_year}
                                onChange={(e) => this.setState({ swimclass_base_year: e.target.value })}
                                placeholder='Enter Swim class base year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Swim Class Breast Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='swimclass_breast_year'
                                value={this.state.swimclass_breast_year}
                                onChange={(e) => this.setState({ swimclass_breast_year: e.target.value })}
                                placeholder='Enter Swim Class Breast Year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Swim Class Medly Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='swimclass_medley_year'
                                value={this.state.swimclass_medley_year}
                                onChange={(e) => this.setState({ swimclass_medley_year: e.target.value })}
                                placeholder='Enter Swim Class Medly Year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Table Tennis Class Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='tt_class_year'
                                value={this.state.tt_class_year}
                                onChange={(e) => this.setState({ tt_class_year: e.target.value })}
                                placeholder='Enter Table Tennis Class Year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Archery Class Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='archery_class_year'
                                value={this.state.archery_class_year}
                                onChange={(e) => this.setState({ archery_class_year: e.target.value })}
                                placeholder='Enter Archery Class Year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>FNC Class Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='fnc_class_year'
                                value={this.state.fnc_class_year}
                                onChange={(e) => this.setState({ fnc_class_year: e.target.value })}
                                placeholder='Enter FNC Class Year'
                                required
                              />
                            </div>
                            {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>Ipc Swim Codes</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='ipcswimcodes'
                                value={this.state.ipcswimcodes}
                                onChange={(e) => this.setState({ ipcswimcodes: e.target.value })}
                                placeholder='Enter Ipc Swim Codes'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>IPC Fieldcodes</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='ipcfieldcodes'
                                value={this.state.flag}
                                onChange={(e) => this.setState({ ipcfieldcodes: e.target.value })}
                                placeholder='Enter IPC Fieldcodes'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Disability Code</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='disabilitycode'
                                value={this.state.disabilitycode}
                                onChange={(e) => this.setState({ disabilitycode: e.target.value })}
                                placeholder='Enter Disability Code'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>DSO</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='dso'
                                value={this.state.dso}
                                onChange={(e) => this.setState({ dso: e.target.value })}
                                placeholder='Enter DSO'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Comments</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='comments'
                                value={this.state.comments}
                                onChange={(e) => this.setState({ comments: e.target.value })}
                                placeholder='Enter Comments'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Ent</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='ent'
                                value={this.state.ent}
                                onChange={(e) => this.setState({ ent: e.target.value })}
                                placeholder='Enter Ent'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Flag</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='flag'
                                value={this.state.flag}
                                onChange={(e) => this.setState({ flag: e.target.value })}
                                placeholder='Enter Flag'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Ipcsdms ID</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='ipcsdmsid'
                                value={this.state.ipcsdmsid}
                                onChange={(e) => this.setState({ ipcsdmsid: e.target.value })}
                                placeholder='Enter Ipcsdms ID'
                                required
                              />
                            </div>
                          </div>

                          <center>
                            <button
                              type='submit'
                              onClick={this.updateclassificationrecordaftersearch}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='modal fade' id='newmeet' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          New Classification{' '}
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-row'>
                            <div class='form-group col-md-6'>
                              <label for='inputAddress'>First Name</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='firstname'
                                onChange={this.optionchange}
                                placeholder='Enter First Name'
                                required
                              />
                            </div>
                            <div class='form-group col-md-6'>
                              <label for='inputAddress'>Last Name</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='lastname'
                                onChange={this.optionchange}
                                placeholder='Enter Last Name'
                                required
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>Gender</label>
                              <select id='inputState' class='form-control' name='gender' onChange={this.genderchange}>
                                <option selected>Choose...</option>
                                <option value='M'>Male</option>
                                <option value='F'>Female</option>
                              </select>
                            </div>
                            <div class='form-group col-md-4'>
                              <label for='YOB'>Yob</label>
                              <input
                                type='text'
                                class='form-control'
                                id='YOB'
                                onChange={this.handlechange2}
                                placeholder='Enter Yob'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>State</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='states'
                                onChange={(e) => this.setState({ states: e.target.value })}
                                placeholder='Enter State'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Year Classified</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='year_classified'
                                onChange={(e) => this.setState({ year_classified: e.target.value })}
                                placeholder='Enter Year Classified'
                                required
                              />
                            </div>

                            {/* <div class='form-row'>
                            <div class='form-group col-md-6'>
                              <label for='age'>Age</label>
                              <input
                                type='text'
                                class='form-control'
                                id='age'
                                placeholder='Enter your age'
                                value={this.state.age1}
                              />
                            </div>
                            <div class="form-group col-md-6">
                              <label>Age Division</label>
                              <input
                                type="text"
                                id="age_division"
                                class="form-control"
                                placeholder="Age Division"
                                value={this.state.age_division}
                                onChange={(e) =>
                                  this.setState({
                                    age_division: e.target.value,
                                  })
                                }
                              ></input>
                            </div>
                          </div> */}
                            {/* <div class='form-group col-md-6'>
                              <label for='exampleFormControlSelect1'>Ipc ID</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='ipcid'
                                value={this.state.ipcid}
                                onChange={(e) => this.setState({ ipcid: e.target.value })}
                                placeholder='Enter Ipc Id'
                                required
                              />
                            </div> */}
                            <div class='form-group col-md-4'>
                              <label>Track Class</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='trackclass'
                                onChange={(e) => this.setState({ trackclass: e.target.value })}
                                placeholder='Enter Track Class'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>Field Class</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='fieldclass'
                                onChange={(e) => this.setState({ fieldclass: e.target.value })}
                                placeholder='Enter Field Class'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>Fencing Class</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='fencing_class'
                                onChange={(e) => this.setState({ fencing_class: e.target.value })}
                                placeholder='Enter Fencing Class'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>TableTennis Class</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='ttclass'
                                onChange={(e) => this.setState({ ttclass: e.target.value })}
                                placeholder='Enter Tennis Class'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>Archery Class</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='archeryclass'
                                onChange={(e) => this.setState({ archeryclass: e.target.value })}
                                placeholder='Enter Archery Class'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Swim Base</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='swimbase'
                                onChange={(e) => this.setState({ swimbase: e.target.value })}
                                placeholder='Enter Swim base'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>Swim Breast</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='swimbreast'
                                onChange={(e) => this.setState({ swimbreast: e.target.value })}
                                placeholder='Enter Swim Breast'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Swim Medley</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='swimmedley'
                                onChange={(e) => this.setState({ swimmedley: e.target.value })}
                                placeholder='Enter Swim Medley'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Track Class Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='track_class_year'
                                onChange={(e) => this.setState({ track_class_year: e.target.value })}
                                placeholder='Enter Track Class Year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Field Class Base Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='fieldclass_base_year'
                                onChange={(e) => this.setState({ fieldclass_base_year: e.target.value })}
                                placeholder='Enter Field Class Base Year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Swim Class Base Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='swimclass_base_year'
                                onChange={(e) => this.setState({ swimclass_base_year: e.target.value })}
                                placeholder='Enter Swim class base year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Swim Class Breast Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='swimclass_breast_year'
                                onChange={(e) => this.setState({ swimclass_breast_year: e.target.value })}
                                placeholder='Enter Swim Class Breast Year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Swim Class Medly Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='swimclass_medley_year'
                                onChange={(e) => this.setState({ swimclass_medley_year: e.target.value })}
                                placeholder='Enter Swim Class Medly Year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Table Tennis Class Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='tt_class_year'
                                onChange={(e) => this.setState({ tt_class_year: e.target.value })}
                                placeholder='Enter Table Tennis Class Year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Archery Class Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='archery_class_year'
                                onChange={(e) => this.setState({ archery_class_year: e.target.value })}
                                placeholder='Enter Archery Class Year'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>FNC Class Year</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='fnc_class_year'
                                onChange={(e) => this.setState({ fnc_class_year: e.target.value })}
                                placeholder='Enter FNC Class Year'
                                required
                              />
                            </div>
                            {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>Ipc Swim Codes</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='ipcswimcodes'
                                onChange={(e) => this.setState({ ipcswimcodes: e.target.value })}
                                placeholder='Enter Ipc Swim Codes'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>IPC Fieldcodes</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='ipcfieldcodes'
                                onChange={(e) => this.setState({ ipcfieldcodes: e.target.value })}
                                placeholder='Enter IPC Fieldcodes'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Disability Code</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='disabilitycode'
                                onChange={(e) => this.setState({ disabilitycode: e.target.value })}
                                placeholder='Enter Disability Code'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label for='exampleFormControlSelect1'>DSO</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='dso'
                                onChange={(e) => this.setState({ dso: e.target.value })}
                                placeholder='Enter DSO'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Comments</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='comments'
                                onChange={(e) => this.setState({ comments: e.target.value })}
                                placeholder='Enter Comments'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Ent</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='ent'
                                onChange={(e) => this.setState({ ent: e.target.value })}
                                placeholder='Enter Ent'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Flag</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='flag'
                                onChange={(e) => this.setState({ flag: e.target.value })}
                                placeholder='Enter Flag'
                                required
                              />
                            </div>
                            <div class='form-group col-md-4'>
                              <label>Ipcsdms ID</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='ipcsdmsid'
                                onChange={(e) => this.setState({ ipcsdmsid: e.target.value })}
                                placeholder='Enter Ipcsdms ID'
                                required
                              />
                            </div>
                          </div>

                          <center>
                            <button
                              type='submit'
                              onClick={this.addclassification}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Create
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
  }
}

export default Classification;
