import React from 'react';

import '../App.css';
import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';

import { Empty } from 'antd';
import 'datatables.net';
import 'datatables.net-bs4';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
var x;
class Printarcheryscoresheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      archeryeventdata: [],
      relay: false,
      notrelay: false,
      finalresultdata: '',
      finalplacedata: '',
      winddata: '',
      implementdata: '',
      wtfinaldata: '',
      breakdata: '',
      njdcrecorddata: '',
      nationaldata: '',
      americandata: '',
      americasdata: '',
      njdcdata: '',
      pointdata: '',
      bestdata: '',
      performancedata: '',
      eventdatedata: '',
      qualifydata: '',
      teampointdata: '',
      meetdatas: [{}],
      loader: true,
      sortbyvaluesdata: [{}],
      issortdata: false,
      ispitdata: true,
      fieldnjdcdatas: [{}],
    };
  }
  async componentDidMount() {
    var self = this;
    const data = {
      meet_id: sessionStorage.getItem('ids'),
    };
    await axios
      .post(`${url.url}/api/getassigntarget/`, data, {})
      .then(function (response) {
        self.setState({
          archeryeventdata: response.data,
          loader: false,
        });
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    document.getElementById('homelink').style.display = 'block';
  }

  render() {
    var self = this;
    return (
      <>
        <div>
          <div>
            <h1> </h1>
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <h5 className='title'>
                      {this.state.event} <br></br> Archery Score Sheet
                    </h5>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      {Object.keys(this.state.archeryeventdata).map((key, index) => (
                        <div
                          key={key}
                          className={`realy_main arcassign arcscoresheet ${index % 2 === 1 ? 'page-break' : ''}`}
                          style={
                            {
                              /*  display: 'flex', justifyContent: 'space-between',  */
                              /* height: '50%' */
                            }
                          }
                        >
                          {/* <tr> */}
                          <div style={{ marginTop: '10%' }}>
                            <div
                              style={{
                                float: 'left',
                                width: '30%',
                                // marginBottom: '5%',
                              }}
                            >
                              <p>
                                <label class='relay_label'>Athlete's Name</label>
                                {self.state.archeryeventdata[key][0].first_name}
                                {self.state.archeryeventdata[key][0].last_name}
                              </p>
                              <p>
                                <span>
                                  <label class='relay_label'>Bib</label>
                                  {self.state.archeryeventdata[key][0].bib_no}
                                </span>

                                <span class='relay_label1'>
                                  <label class='relay_label'>Group</label>
                                </span>
                              </p>

                              <p>
                                <span>
                                  {' '}
                                  <label class='relay_label'>Class</label>
                                  {self.state.archeryeventdata[key][0].archery_class_value}
                                </span>
                                <span class='relay_label1'>
                                  <label class='relay_label'>Age</label>
                                  {self.state.archeryeventdata[key][0].age_division}
                                </span>
                              </p>

                              <p>
                                <span>
                                  {' '}
                                  <label class='relay_label'>Gender</label>
                                  {self.state.archeryeventdata[key][0].gender}
                                </span>
                                <span class='relay_label1'>
                                  <label class='relay_label'>Qualifying Standard</label>
                                  {self.state.archeryeventdata[key][0].qualifying_standard}
                                </span>
                              </p>

                              <p>
                                <span>
                                  <label class='relay_label'>Typebow</label>
                                  {self.state.archeryeventdata[key][0].typebow}
                                </span>
                                <span class='relay_label1'>
                                  <label class='relay_label'>Bowstand</label>
                                  {self.state.archeryeventdata[key][0].bowstand}
                                </span>
                              </p>
                              <p>
                                <span>
                                  <label class='relay_label'>Events</label>
                                  {` `}
                                  {` `}
                                  {` `}
                                  <label class='relay_label'>Target</label>
                                  {}
                                </span>
                              </p>
                            </div>
                            <div class='boxmodel' style={{ float: 'left', width: '70%' }}>
                              <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li>Result</li>
                              </ul>
                              <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li>Place</li>
                              </ul>
                              <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>
                              <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>
                              <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>
                              <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>
                            </div>
                          </div>
                          <div></div>
                          <div style={{ height: '1500px', marginTop: '11%' }}>
                            <div style={{ float: 'left', width: '30%' }}>
                              <p>
                                <label class='relay_label'>Athlete's Name</label>
                                {self.state.archeryeventdata[key][0].first_name}{' '}
                                {self.state.archeryeventdata[key][0].last_name}
                              </p>
                              <p>
                                <span>
                                  <label class='relay_label'>Bib</label>
                                  {self.state.archeryeventdata[key][0].bib_no}
                                </span>

                                <span class='relay_label1'>
                                  <label class='relay_label'>Group</label>
                                </span>
                              </p>

                              <p>
                                <span>
                                  {' '}
                                  <label class='relay_label'>Class</label>
                                  {self.state.archeryeventdata[key][0].archery_class_value}
                                </span>
                                <span class='relay_label1'>
                                  <label class='relay_label'>Age</label>
                                  {self.state.archeryeventdata[key][0].age_division}
                                </span>
                              </p>

                              <p>
                                <span>
                                  {' '}
                                  <label class='relay_label'>Gender</label>
                                  {self.state.archeryeventdata[key][0].gender}
                                </span>
                                <span class='relay_label1'>
                                  <label class='relay_label'>Qualifying Standard</label>
                                  {self.state.archeryeventdata[key][0].qualifying_standard}
                                </span>
                              </p>

                              <p>
                                <span>
                                  <label class='relay_label'>Typebow</label>
                                  {self.state.archeryeventdata[key][0].typebow}
                                </span>
                                <span class='relay_label1'>
                                  <label class='relay_label'>Bowstand</label>
                                  {self.state.archeryeventdata[key][0].bowstand}
                                </span>
                              </p>
                              <p>
                                <span>
                                  <label class='relay_label'>Events</label>
                                  {` `}
                                  {` `}
                                  {` `}
                                  <label class='relay_label'>Target</label>
                                  {}
                                </span>
                              </p>
                            </div>
                            <div class='boxmodel' style={{ float: 'left', width: '70%' }}>
                              <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li>Result</li>
                              </ul>
                              <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li>Place</li>
                              </ul>
                              <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>
                              <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>
                              <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>
                              <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>
                            </div>
                            <p style={{ float: 'right', width: '70%', alignItems: 'center' }}>
                              Signature :______________________
                            </p>
                          </div>

                          <div></div>
                        </div>
                      ))}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}

export default Printarcheryscoresheet;
