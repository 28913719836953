import React from 'react';

import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';

import Swal from 'sweetalert2';

import { Select, Input, Checkbox, Empty } from 'antd';
import 'antd/dist/antd.css';
import { faDiceThree } from '@fortawesome/free-solid-svg-icons';
const { Option } = Select;
const url = window.globalConfig;
var selectdata = [];
var eventname = [];
var eventid = [];
var test = false;
var agedivision = [];
var gendervariation = [];
var a, b;
var y;
var z, z1;
var filterarray, filterarray1;

class Meetmanager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      meetdatas: [{}],
      vdatas: [{}],
      ahome: true,
      vhome: false,
      edropdown: [{}],
      selectedItems: [],
      meetname: '',
      meetid: '',
      meetd: [{}],
      venuename: '',
      venueid: '',
      eventname: [],
      agedivision: [],
      gendervariation: [],
      eventid: [],
      paymentrecpt: '',
      eventDrop: [{}],
      sdata: [{}],
      neweventname: '',
      neweventid: '',
      applydata: [{}],
      getenrolstatus: [{}],
      btncheck: false,
      eids: '',
      mname: '',
      emeetid: '',
      emeetvname: '',
      emeetvid: '',
      emeetename: '',
      emeeteid: '',
      uid: '',
      statusresult: '',
      usernam: '',
      pay: '',
      bib: '',
      specficmeet: [{}],
      alldata: [{}],
      agedata: [{}],
      mname: '',
      agedata: [{}],
    };

    this.meetevents = this.meetevents.bind(this);
    this.trackevents = this.trackevents.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.back = this.back.bind(this);
    this.meetregister = this.meetregister.bind(this);
    this.check = this.check.bind(this);
  }

  handleChange2(selectedItems) {
    var self = this;
    self.setState({
      selectedItems,
    });
  }

  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });
    var self = this;
    axios.get(`${url.url}/api/getallregistermeet`, {}).then(function (response) {
      self.setState({
        meetdatas: response.data.data,
      });
    });
    var self = this;
    axios.get(`${url.url}/api/meets`, {}).then(function (response) {
      self.setState({
        meetd: response.data.data,
      });
    });

    var test = sessionStorage.getItem('userdetails');
    var user = JSON.parse(test);
    axios.get(`${url.url}/api/getregisterdetail/${user.id}`, {}).then(function (response) {
      self.setState({
        getenrolstatus: response.data,
      });
    });

    axios.get(`${url.url}/api/venues`, {}).then(function (response) {
      self.setState({
        vdatas: response.data.data,
      });
    });

    //
    axios.get(`${url.url}/api/getallage`, {}).then(function (response) {
      self.setState({
        agedata: response.data.data,
      });
    });
  }

  meetevents(e) {
    var res = [];
    axios
      .get(`${url.url}/api/getmanagervenues/${this.state.meetdatas[e].meet_id}`, {})
      .then(function (response) {
        self.setState({
          sdata: response.data,
        });
        for (let j = 0; j < response.data.length; j++) {
          var i = response.data[j].event_name.trim().split(',');

          {
            response.data[j].event_id
              .trim()
              .split(',')
              .map((a, index) => res.push({ id: a, name: i[index] }));
          }
        }
      })
      .catch(function (error) {
        self.setState({
          edropdown: [{}],
        });
      });

    var self = this;
    self.setState({
      vhome: true,
      ahome: false,
      meetname: this.state.meetdatas[e].meet_name,
      meetid: this.state.meetdatas[e].meet_id,
    });
  }

  trackevents(e) {
    var self = this;

    var res = [];
    var i;

    axios
      .get(`${url.url}/api/getmanagervenues/${this.state.meetid}`, {})
      .then(function (response) {
        var i = response.data[0].event_name.trim().split(',');
        {
          response.data[0].event_id
            .trim()
            .split(',')
            .map((a, index) => res.push({ id: a, name: i[index] }));
        }

        self.setState({
          edropdown: res,
        });
      })
      .catch(function (error) {
        self.setState({
          edropdown: [{}],
        });
      });
  }

  back() {
    var self = this;
    self.setState({
      vhome: false,
      ahome: true,
      sdata: [{}],
    });
  }

  // apply for new meett-----------------------------------------------------------
  meetregister1() {
    var self = this;
    var n;

    try {
      for (n = 0; n < 1; n++) {
        if (this.state.applydata[n].apply_event) {
          const datatosend = {
            venuename: this.state.applydata[n].name,
            venueid: this.state.applydata[n].venue_id,
            eventname: this.state.applydata[n].apply_event.toString(),
            eventid: this.state.applydata[n].apply_eventid.toString(),
            status: 'pending',
            payment_no: this.state.paymentrecpt,
            bib_no: 0,
          };

          axios
            .post(`${url.url}/api/registermeet`, datatosend, {})
            .then(function (response) {
              if (response.status == 201) {
                Swal.fire({
                  title: 'Success',
                  text: 'Event Registered successfully',
                  icon: 'warning',
                  confirmButtonText: 'OK',
                }).then((result) => {
                  if (result.value) {
                    self.setState({
                      meethome: false,
                      viewmeet: true,
                      eventsponser: false,
                      lorg: false,
                      venueview: false,
                    });
                  }
                });
              }
            })

            .catch(function (error) {
              Swal.fire({
                title: 'Failed',
                text: 'Something went wrong Please check the fields',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok!',
              });
            });
          self.setState({
            eventname: [],
            eventid: [],
            selectedItems: [],
            edropdown: [{}],
            paymentrecpt: '',
          });
        }
      }
    } catch (err) {
      Swal.fire({
        title: 'Failed',
        text: 'Something went wrong Please check the fields',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok!',
      });
    }
  }

  meetregister() {
    var self = this;
    var n;
    try {
      for (n = 0; n < this.state.applydata.length; n++) {
        if (this.state.applydata[n].apply_event && this.state.applydata[n].apply_agedivision) {
          const datatosend = {
            meetname: this.state.applydata[n].meet_name,
            meetid: this.state.applydata[n].meet_id,
            venuename: this.state.applydata[n].name,
            venueid: this.state.applydata[n].venue_id,
            eventname: this.state.applydata[n].apply_event.toString(),
            eventid: this.state.applydata[n].apply_eventid.toString(),
            user_id: this.props.logindata.user.id,
            status: 'pending',
            user_name: this.props.logindata.user.firstname,
            payment_no: this.state.paymentrecpt,
            bib_no: 0,
            agedivision: this.state.applydata[n].apply_agedivision.toString(),
            gendervariation: this.state.applydata[n].apply_gendervariation.toString(),
          };

          axios
            .post(`${url.url}/api/registermeet`, datatosend, {})
            .then(function (response) {
              if (response.status == 201) {
                Swal.fire({
                  title: 'Success',
                  text: 'Event Registered successfully',
                  icon: 'warning',
                  confirmButtonText: 'OK',
                })
                  .then((result) => {
                    if (result.value) {
                      self.setState({
                        meethome: false,
                        viewmeet: true,
                        eventsponser: false,
                        lorg: false,
                        venueview: false,
                      });
                    }
                  })
                  .then(function (response) {
                    window.location.reload();
                  });
              }
            })

            .catch(function (error) {
              Swal.fire({
                title: 'Failed',
                text: 'Something went wrong Please check the fields',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok!',
              });
            });
          self.setState({
            eventname: [],
            eventid: [],
            selectedItems: [],
            edropdown: [{}],
            paymentrecpt: '',
          });
        }
      }
    } catch (err) {
      Swal.fire({
        title: 'Failed',
        text: 'Something went wrong Please check the fields',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok!',
      });
    }
  }

  ischecked(e, v, indxs) {
    var self = this;
    var indx, indx1;
    var payno = [];
    var filterapplyitem = [];
    var filterapplyeventid = [];

    $(document).ready(function () {
      $('.' + indxs).click(function () {
        $('input[name=' + indxs + ']')
          .not(this)
          .prop('checked', false);
      });
    });

    try {
      var test = this.state.sdata[e];
      if (!test.includes(v)) {
        this.state.sdata[e].push(v);
      } else {
        var filterapplyitem = this.state.sdata[e]['apply_gendervariation'].filter((item) => item !== v);

        this.state.sdata[e]['apply_gendervariation'] = [];
        this.state.sdata[e]['apply_gendervariation'] = filterapplyitem;
      }
    } catch (err) {
      this.state.sdata[e]['apply_gendervariation'] = [];
      this.state.sdata[e]['apply_gendervariation'].push(v);
    }

    self.setState({
      applydata: this.state.sdata,
    });
  }

  ischeck(e, v, indxs) {
    var self = this;

    var indx, indx1;
    var payno = [];
    var filterapplyitem = [];
    var filterapplyeventid = [];

    $(document).ready(function () {
      $('.' + indxs).click(function () {
        $('input[name=' + indxs + ']')
          .not(this)
          .prop('checked', false);
      });
    });

    try {
      var test = this.state.sdata[e];

      if (!test.includes(v)) {
        this.state.sdata[e].push(v);
      } else {
        var filterapplyitem = this.state.sdata[e]['apply_agedivision'].filter((item) => item !== v);

        this.state.sdata[e]['apply_agedivision'] = [];
        this.state.sdata[e]['apply_agedivision'] = filterapplyitem;
      }
    } catch (err) {
      this.state.sdata[e]['apply_agedivision'] = [];
      this.state.sdata[e]['apply_agedivision'].push(v);
    }

    self.setState({
      applydata: this.state.sdata,
    });
  }
  check(e, v, indxs) {
    var self = this;

    var indx, indx1;
    var payno = [];
    var filterapplyitem = [];
    var filterapplyeventid = [];
    try {
      var test = this.state.sdata[e]['apply_event'];
      if (!test.includes(v)) {
        var eids = this.state.sdata[e].event_id.split(',')[indxs];
        this.state.sdata[e]['apply_event'].push(v);
        this.state.sdata[e]['apply_eventid'].push(eids);
      } else {
        var eids = this.state.sdata[e].event_id.split(',')[indxs];

        var filterapplyitem = this.state.sdata[e]['apply_event'].filter((item) => item !== v);
        this.state.sdata[e]['apply_event'] = [];
        this.state.sdata[e]['apply_event'] = filterapplyitem;
        var filterapplyeventid = this.state.sdata[e]['apply_eventid'].filter((item) => item !== eids);
        this.state.sdata[e]['apply_eventid'] = [];
        this.state.sdata[e]['apply_eventid'] = filterapplyeventid;
      }
    } catch (err) {
      var eids = this.state.sdata[e].event_id.split(',')[indxs];

      this.state.sdata[e]['apply_event'] = [];
      this.state.sdata[e]['apply_event'].push(v);
      this.state.sdata[e]['apply_eventid'] = [];
      this.state.sdata[e]['apply_eventid'].push(eids);
    }

    self.setState({
      applydata: this.state.sdata,
    });
  }

  // Apply for new meet end------------------------------end--------------------------------------
  render() {
    const { selectedItems } = this.state;

    if (this.state.ahome) {
      return (
        <div>
          <div class='modal fade' id='loginmodal' tabindex='-1' role='dialog' aria-hidden='true'>
            <div class='modal-dialog' id='loginmodal'>
              <div class='modal-content'>
                <div class='modal-header '>
                  <h5 class='modal-title ' id='loginmodalLabel'>
                    Log in
                  </h5>
                  <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <div class='modal-body'>
                  <form onSubmit={this.submitlogin}>
                    <div class='form-group'>
                      <label for='exampleInputEmail1'>Email address</label>
                      <input
                        type='email'
                        class='form-control'
                        id='exampleInputEmail1'
                        name='username'
                        onChange={(e) => this.setState({ username: e.target.value })}
                        aria-describedby='emailHelp'
                        placeholder='Enter email'
                        required
                      />
                    </div>
                    <div class='form-group'>
                      <label for='exampleInputPassword1'>Password</label>
                      <input
                        type='password'
                        class='form-control'
                        id='exampleInputPassword1'
                        name='password'
                        onChange={(e) => this.setState({ password: e.target.value })}
                        placeholder='Password'
                        required
                      />
                    </div>
                    <button type='submit' class='btn btn-danger'>
                      Log in
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className='content'>
            <Row>
              <Col md='8'>
                <Card>
                  <CardHeader>
                    <h5 className='title'>Meets</h5>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <div class='form-group row'>
                        <div class='col-xs-5'>
                          <CardHeader>
                            <p>Search</p>
                            <input class='form-control' id='inputsm' placeholder='Search..' type='text' />
                          </CardHeader>
                        </div>
                      </div>
                      {/* table --------------------------------------------------- */}
                      <Table id='my' responsive>
                        <thead>
                          <tr>
                            <th className='text-center'>#</th>
                            <th>Meet Name</th>
                            <th>Country</th>
                            <th>City</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Status</th>
                            <th class='disabled-sorting text-right'>Actions</th>
                          </tr>
                        </thead>
                        <tbody id='myTable'>
                          {Object.keys(this.state.meetdatas).length == 0 ? (
                            <Empty />
                          ) : (
                            <>
                              {this.state.meetdatas.map(
                                (question, index) => (
                                  (test = false),
                                  (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{question.meet_name}</td>
                                      <td>{question.meet_country}</td>
                                      <td>{question.meet_city}</td>
                                      <td>{question.start_date}</td>
                                      <td>{question.end_date}</td>

                                      <td class='td-status '>
                                        {this.state.getenrolstatus.filter(
                                          (getenrolstatus) => getenrolstatus.meetid == question.meet_id
                                        ).length != 0
                                          ? this.state.getenrolstatus.filter(
                                              (getenrolstatus1) =>
                                                getenrolstatus1.meetid == question.meet_id &&
                                                getenrolstatus1.status != 'Rejected'
                                            ).length != 0
                                            ? this.state.getenrolstatus.filter(
                                                (getenrolstatus2) =>
                                                  getenrolstatus2.meetid == question.meet_id &&
                                                  getenrolstatus2.status != 'pending'
                                              ).length != 0
                                              ? 'Approved'
                                              : 'Pending'
                                            : 'Rejected'
                                          : 'Nill'}
                                      </td>

                                      <td class='td-actions '>
                                        {this.state.getenrolstatus.filter(
                                          (getenrolstatus) =>
                                            getenrolstatus.meetid == question.meet_id &&
                                            getenrolstatus.status != 'Rejected'
                                        ).length != 0 ? (
                                          <button
                                            type='button'
                                            rel='tooltip'
                                            id='enrolbtn'
                                            disabled='true'
                                            class='btn btn-success'
                                            onClick={() => this.meetevents(index)}
                                          >
                                            {' '}
                                            Enrol{' '}
                                          </button>
                                        ) : (
                                          <button
                                            type='button'
                                            rel='tooltip'
                                            id='enrolbtn'
                                            class='btn btn-success'
                                            onClick={() => this.meetevents(index)}
                                          >
                                            {' '}
                                            Enrol{' '}
                                          </button>
                                        )}
                                      </td>
                                    </tr>
                                  )
                                )
                              )}
                            </>
                          )}

                          {/* </>
} */}
                        </tbody>
                      </Table>
                      {/* table --------------------------------------------------- */}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      );
    }

    // -------------------------------------------------------------------------home page-------------------------------------------------------------
    if (this.state.vhome) {
      return (
        <div>
          <div className='content'>
            <Row>
              <Col md='8'>
                <button
                  type='button'
                  data-toggle='modal'
                  data-target='#newmeet'
                  class='btn btn-danger btn-round'
                  onClick={this.back}
                >
                  Back
                </button>
                {''}
                <Card>
                  <CardHeader>
                    <h5 className='title'>Meets Venues </h5>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <Table id='registertable' responsive>
                        <thead>
                          <tr>
                            <th>Venue</th>
                            <th>Event</th>
                            <th>AgeDivision</th>
                            <th>GenderVariation</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(this.state.sdata[0]).length == 0 ? (
                            <Empty />
                          ) : (
                            <>
                              {this.state.sdata.map(
                                (question, index) => (
                                  (y = question.Age_Division.split('name').toString().split(')').toString().split('(')),
                                  (z = y[1].split(',')),
                                  (filterarray = z.filter((e) => String(e).trim())),
                                  (a = question.Gender_Variation.split('name')
                                    .toString()
                                    .split(')')
                                    .toString()
                                    .split('(')),
                                  (b = a[1].split(',')),
                                  (filterarray1 = b.filter((e) => String(e).trim())),
                                  (
                                    <>
                                      <tr>
                                        <td>{question.name}</td>
                                        <td>
                                          {question.event_name.split(',').map((question1, indx) => (
                                            <Checkbox
                                              name='ischeck'
                                              onChange={() => this.check(index, question1, indx)}
                                            >
                                              {question1}
                                            </Checkbox>
                                          ))}
                                        </td>
                                        <td>
                                          {filterarray.map((question2, indx) => (
                                            <>
                                              {' '}
                                              <input
                                                type='checkbox'
                                                class={question.name}
                                                name={question.name}
                                                value={question.name}
                                                onChange={() => this.ischeck(index, question2, question.name)}
                                              />
                                              &nbsp;&nbsp;{question2}&nbsp;
                                            </>
                                          ))}
                                        </td>
                                        <td>
                                          {filterarray1.map((question3, indx) => (
                                            <>
                                              <input
                                                type='checkbox'
                                                class={question.name + 'g'}
                                                name={question.name + 'g'}
                                                value={question.name}
                                                onChange={() => this.ischecked(index, question3, question.name + 'g')}
                                              />
                                              &nbsp;&nbsp;{question3}&nbsp;
                                            </>
                                          ))}
                                        </td>
                                      </tr>
                                    </>
                                  )
                                )
                              )}
                            </>
                          )}
                        </tbody>
                      </Table>
                      <div class='paybtn'>
                        <div class='pyrecipt'>
                          <label>
                            <b>Payment Reciept No*</b>
                          </label>{' '}
                          <Input
                            style={{ width: '410px' }}
                            name='paymentrecpt'
                            onChange={(e) => this.setState({ paymentrecpt: e.target.value })}
                            placeholder='Enter payment reciept number'
                          />
                        </div>
                        <div class='enrolbtn'>
                          <button
                            type='button'
                            rel='tooltip'
                            class='btn btn-success enrol'
                            onClick={() => this.meetregister()}
                          >
                            Enrol
                          </button>
                        </div>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>

          <div id='regmeet' class='modal fade' tabindex='-1' role='dialog' aria-hidden='true'>
            <div class='modal-dialog'>
              <div class='modal-content'>
                <div class='modal-header'>
                  <button type='button' class='close' data-dismiss='modal'>
                    &times;
                  </button>
                  <h4 class='modal-title'>Please Select your events</h4>
                </div>
                <div class='modal-body'>
                  <div class='form-group col-md-6'>
                    <label for='inputCity'>Events name *</label>

                    <Select
                      mode='multiple'
                      placeholder='Please select'
                      value={selectedItems}
                      onChange={this.handleChange2}
                      style={{ width: '410px' }}
                    >
                      {Object.keys(this.state.edropdown[0]).length == 0 ? (
                        <Select.Option key='' value=''>
                          Please select
                        </Select.Option>
                      ) : (
                        <>
                          {this.state.edropdown.map((edrop, index) => (
                            <Select.Option key={edrop.name} value={edrop.name + '-' + edrop.id}>
                              {edrop.name}
                            </Select.Option>
                          ))}
                        </>
                      )}
                    </Select>
                    <label>Payment Reciept No*</label>
                    <Input
                      style={{ width: '410px' }}
                      name='paymentrecpt'
                      onChange={(e) => this.setState({ paymentrecpt: e.target.value })}
                      placeholder='Enter payment reciept number'
                    />
                  </div>
                </div>
                <div>
                  <center>
                    <button type='button' class='btn btn-danger' onClick={this.meetregister}>
                      Register
                    </button>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Meetmanager;
