import React from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';

import Swal from 'sweetalert2';

import { Select, Input } from 'antd';
import 'antd/dist/antd.css';
import { Empty } from 'antd';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner';

var x;
const url = window.globalConfig;
class Athleteswim extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      venues: [{}],
      venueid: [],
      tname: [],
      tnamesid: '',
      swimeventdata: [],
      swimsdata: [],
      swimevents: [],
      swimdatas: [{}],
      swimsets: [{}],
      relay_points: '',
      qualifying_standard: '',
      qualifying_time: '',
      points: '',
      gender1: '',
      realy_team_name: '',
      relay_letter: '',
      relay_points: '',
      swimeventsdata: '',
      allregdatas: [{}],
      classdata: [{}],
      qstddatas: [{}],
      relaypointdatas: [],
      loader: true,
      relay: '',
    };
    this.check = this.check.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.edit = this.edit.bind(this);
    this.tinput = this.tinput.bind(this);
    this.createswimreg = this.createswimreg.bind(this);
    this.updateswimreg = this.updateswimreg.bind(this);
    this.editclassage = this.editclassage.bind(this);
    this.onchange1 = this.onchange1.bind(this);
    this.onchange2 = this.onchange2.bind(this);
    this.updateclassage = this.updateclassage.bind(this);
    this.deleteswimreg = this.deleteswimreg.bind(this);
  }
  async componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    let itemindex = sessionStorage.getItem('itemindex');
    var self = this;
    var count = 0;
    let indx = sessionStorage.getItem('swimindex');
    let ids = sessionStorage.getItem('ids');
    axios.get(`${url.url}/api/getswimmeetid/${ids}`, {}).then(function (response) {
      self.setState({
        swimsets: response.data,
      });
    });
    const swimeventdata = (index) => {
      if (index.swim_events != 'null') {
        var joined = this.state.swimeventdata.concat(index);
        this.setState({
          swimeventdata: joined,
          swimsdata: joined[0],
        });
      } else {
        self.setState({
          swimsdata: index,
        });
      }
    };
    let athleteid = sessionStorage.getItem('athleteid');
    await axios
      .get(`${url.url}/api/getathleteregisterid/${athleteid}`, {})
      .then(function (response) {
        self.setState({
          allregdatas: response.data,
        });
        {
          response.data.map((index) => swimeventdata(index));
        }
        var valdata = self.state.swimeventdata[0].swim_events.split(',');
        self.setState({
          swimsdata: self.state.swimeventdata[0],
          swimevents: valdata,
        });
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });

    let venid = sessionStorage.getItem('venuesid');
    axios.get(`${url.url}/api/getclassvalue/${venid}`, {}).then(function (response) {
      self.setState({
        classdata: response.data,
      });
      count++;
      if (count == 3) {
        self.setState({
          loader: false,
        });
      }
    });

    let regid = sessionStorage.getItem('regid');
    axios
      .get(`${url.url}/api/getsregisterid/${athleteid}`, {})
      .then(function (response) {
        self.setState({
          swimdatas: response.data,
          swimdatas1: response.data[0],
        });

        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      });

    //Hide and show manager
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    const data = {
      class: this.state.allregdatas[0].swim_class_value,
      age: this.state.allregdatas[0].swim_age,
    };
    await axios
      .post(`${url.url}/api/getpoints`, data, {})
      .then(function (response) {
        self.setState({
          relaypointdatas: response.data.data[0].points,
        });
      })
      .catch(function (error) {});
  }
  check(e) {
    var x = e.target.value.split('-');
    var name = x[0];
    var eventid = x[1];
    var relay = x[2];
    var temp = [];
    if (Object.keys(this.state.tname).length != 0) {
      temp = this.state.tname;
    }
    if (!this.state.tname.some((item) => name === item.name)) {
      temp.push({ name: name, id: e.target.id, eventid: eventid, relay: relay });
    }
    this.setState({ tname: temp, tnamesid: name, event_id: eventid, relay: relay });
    this.edit(e.target.id);
  }
  handlechange() {
    window.location.replace('/athletevenues');
  }

  tinput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  async edit(e, eventname) {
    var self = this;
    var qstd = {
      venue_name: 'Swimming',
      event_name: this.state.swimsets[e].event_name,
      Class_values: this.state.allregdatas[0].swim_class_value,
      Age_division: this.state.allregdatas[0].swim_age,
      Gender: this.state.allregdatas[0].gender,
    };
    await axios
      .post(`${url.url}/api/getqualstd`, qstd, {})
      .then(function (response) {
        self.setState({
          qstddatas: response.data.data,
        });
      })
      .catch(function (error) {});
    self.setState({
      swimeventsdata: self.state.tnamesid,
    });
    var loop = true;

    self.state.swimdatas.forEach((question, index) => {
      if (
        question.selected_events?.toLowerCase() == self.state.tnamesid?.toLowerCase() &&
        self.state.relay == question.event_id
      ) {
        self.setState({
          selected_id: question.id,
          selected_events: question.selected_events,
          qualifying_time: question.qualifying_time,
          qualifying_standard:
            question.qualifying_standard == null
              ? self.state.qstddatas[0].qualifying_standards
              : question.qualifying_standard,
          points: question.points,
          gender1: question.gender1,
          realy_team_name: question.realy_team_name == '' ? this.state.realy_team_name : question.realy_team_name,
          relay_letter: question.relay_letter,
          btncheck: false,
        });
        loop = false;
      } else if (loop) {
        self.setState({
          selected_id: '',
          selectedevents: this.state.swimeventsdata,
          qualifying_time: '',
          qualifying_standard:
            self.state.qstddatas[0].qualifying_standards == '' ? '' : self.state.qstddatas[0].qualifying_standards,
          points: '',
          gender1: '',
          realy_team_name: '',
          relay_letter: '',
          btncheck: true,
        });
        loop = true;
      }
    });

    if (self.state.swimdatas.length == 0) {
      self.setState({
        selected_id: '',
        selectedevents: self.state.swimsets[e].event_name,
        qualifying_time: '',
        qualifying_standard: self.state.qstddatas[0].qualifying_standards,
        points: '',
        gender1: '',
        realy_team_name: '',
        relay_letter: '',
        btncheck: true,
      });
    }
  }
  editclassage() {
    var self = this;
    self.setState({
      swimclass: self.state.allregdatas[0].swim_class_value,
      swimbreastclass:
        self.state.allregdatas[0].swim_breast_class == '' ? ' ' : self.state.allregdatas[0].swim_breast_class,
      swimmedleyclass:
        self.state.allregdatas[0].swim_medley_class == '' ? ' ' : self.state.allregdatas[0].swim_medley_class,
      swimage: self.state.allregdatas[0].swim_age,
    });
  }
  onchange1(e) {
    this.setState({ swimclass: e.target.value });
  }

  onchange3 = (e) => {
    this.setState({ swimbreastclass: e.target.value });
  };

  onchange4 = (e) => {
    this.setState({ swimmedleyclass: e.target.value });
  };

  onchange2(e) {
    this.setState({ swimage: e.target.value });
  }
  updateclassage() {
    const data = {
      swim_age: this.state.swimage,
      swim_breast_class: this.state.swimbreastclass,
      swim_medley_class: this.state.swimmedleyclass,
      swim_class_value: this.state.swimclass,
    };
    let athleteid = sessionStorage.getItem('athleteid');
    axios
      .put(`${url.url}/api/updateclassagevalue/${athleteid}`, data, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  createswimreg(e) {
    var self = this;
    const setvalue = (q, index) => {
      if (self.state.swimdatas.length > index) {
        self.setState({
          selected_events: self.state.swimdatas[index].selected_events,
          qualifying_time: self.state.swimdatas[index].qualifying_time,
          qualifying_standard: self.state.swimdatas[index].qualifying_standard,
          points: self.state.swimdatas[index].points,
          gender1: self.state.swimdatas[index].gender1,
          realy_team_name: self.state.swimdatas[index].realy_team_name,
          relay_letter: self.state.swimdatas[index].relay_letter,
          relay_points: self.state.swimdatas[index].relay_points,
        });
      }
    };
    self.state.swimevents.map((question, index) => {
      setvalue(question, index);
    });
    const datatosend = {
      first_name: this.state.swimsdata.firstname,
      last_name: this.state.swimsdata.lastname,
      age_division: this.state.swimsdata.swim_age,
      gender: this.state.swimsdata.gender,
      selected_events: this.state.selectedevents,
      qualifying_time: this.state.qualifying_time == '' ? '' : this.state.qualifying_time,
      qualifying_standard: this.state.qualifying_standard,
      points: this.state.points == '' ? '' : this.state.points,
      swim_breast_class: this.state.swimsdata.swim_breast_class != null ? this.state.swimsdata.swim_breast_class : '',
      swim_medley_class: this.state.swimsdata.swim_medley_class != null ? this.state.swimsdata.swim_medley_class : '',
      gender1: this.state.gender1 == '' ? '' : this.state.gender1,
      realy_team_name: this.state.realy_team_name,
      relay_letter: this.state.relay_letter == '' ? '' : this.state.relay_letter,
      created_by: 1,
      swim_class_value: this.state.swimsdata.swim_class_value,
      relay_points: this.state.relaypointdatas,
      register_id: parseInt(sessionStorage.getItem('athleteid')),
      meet_id: parseInt(sessionStorage.getItem('ids')),
      bib_no: this.state.allregdatas[0].bib_no,
      country: this.state.allregdatas[0].country,
      team_name: this.state.allregdatas[0].team_name,
      event_id: this.state.relay,
      register_num: this.state.allregdatas[0].register_num,
    };
    axios
      .post(`${url.url}/api/createswimreg/`, datatosend, {})
      .then(function (response) {
        if (response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  updateswimreg(e) {
    var self = this;

    const datatosend = {
      first_name: this.state.swimsdata.firstname,
      last_name: this.state.swimsdata.lastname,
      age_division: this.state.swimsdata.swim_age,
      gender: this.state.swimsdata.gender,
      selected_events: this.state.selected_events,
      qualifying_time: this.state.qualifying_time,
      qualifying_standard: this.state.qualifying_standard,
      points: this.state.points == '' ? '' : this.state.points,
      swim_breast_class: this.state.swimsdata.swim_breast_class !== null ? this.state.swimsdata.swim_breast_class : '',
      swim_medley_class: this.state.swimsdata.swim_medley_class !== null ? this.state.swimsdata.swim_medley_class : '',
      gender1: this.state.gender1 == '' ? '' : this.state.gender1,
      realy_team_name: this.state.realy_team_name == '' ? '' : this.state.realy_team_name,
      relay_letter: this.state.relay_letter == '' ? '' : this.state.relay_letter,
      created_by: 1,
      swim_class_value: this.state.swimsdata.swim_class_value,
      relay_points: this.state.relay_points,
      bib_no: this.state.allregdatas[0].bib_no,
    };
    axios
      .put(`${url.url}/api/updateswimreg/${this.state.selected_id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  deleteswimreg(e) {
    const datatosend = {
      selected_events: '',
      qualifying_time: '',
      qualifying_standard: '',
      points: '',
      gender1: '',
      realy_team_name: '',
      relay_letter: '',
    };
    axios
      .put(`${url.url}/api/deleteswimreg/${this.state.selected_id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Deleted successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  render() {
    const { selectedItems } = this.state;

    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div className='content'>
              <Row>
                <Col md='8'>
                  <button
                    type='button'
                    data-toggle='modal'
                    data-target='#selectswim'
                    class='btn btn-danger btn-round'
                    onClick={this.handlechange}
                  >
                    Back
                  </button>
                  <Card>
                    <CardHeader>
                      <h5 className='title'>Swim Events</h5>
                    </CardHeader>

                    <CardBody>
                      <Form>
                        <div class='form-group row'>
                          <div class='col-xs-5'>
                            <CardHeader className='athlete_swimtb' id='deatils_heading'>
                              <div>
                                <th>
                                  <tr>
                                    <div>
                                      <label>Registration no:</label>
                                      <span>{this.state.swimsdata.register_num}</span>
                                    </div>

                                    <div>
                                      <label>First Name:</label>
                                      <span>{this.state.swimsdata.firstname}</span>
                                    </div>

                                    <div>
                                      <label>Last Name:</label>
                                      <span>{this.state.swimsdata.lastname}</span>
                                    </div>
                                    <div>
                                      <label>Swim Class:</label>
                                      <span>
                                        {this.state.swimsdata.swim_class_value
                                          ? this.state.swimsdata.swim_class_value
                                          : 'n/a'}
                                      </span>
                                    </div>
                                    <div>
                                      <label>BIB no:</label>
                                      <span>{this.state.swimsdata.bib_no}</span>
                                    </div>
                                  </tr>

                                  <tr>
                                    <div>
                                      <label> Age Division:</label>
                                      <span>{this.state.swimsdata.swim_age}</span>
                                    </div>

                                    <div>
                                      <label>Gender:</label>
                                      <span>{this.state.swimsdata.gender}</span>
                                    </div>

                                    <div>
                                      <label>Breaststroke:</label>
                                      <span>
                                        {this.state.swimsdata.swim_breast_class == null
                                          ? '-'
                                          : this.state.swimsdata.swim_breast_class}
                                      </span>
                                    </div>

                                    <div>
                                      <label>Medley:</label>
                                      <span>
                                        {this.state.swimsdata.swim_medley_class == null
                                          ? '-'
                                          : this.state.swimsdata.swim_medley_class}
                                      </span>
                                    </div>

                                    <div>
                                      <label>Relay Points:</label>
                                      <span>{this.state.relaypointdatas}</span>
                                    </div>
                                  </tr>
                                </th>
                              </div>

                              <button
                                type='button'
                                rel='tooltip'
                                data-toggle='modal'
                                data-target='#meetedit'
                                class='btn btn-success btn-sm btn-icon'
                                onClick={this.editclassage}
                              >
                                <i class='now-ui-icons ui-2_settings-90'></i>
                              </button>
                            </CardHeader>
                          </div>
                        </div>
                        {Object.keys(this.state.swimsdata).length == 0 ? (
                          <Empty />
                        ) : (
                          <>
                            <Table id='my' responsive>
                              <thead>
                                <tr>
                                  <th>Athletes Events</th>
                                  <th></th>
                                  <th>Selected Events</th>
                                  <th>Qualifying Time</th>
                                  <th>Qualifying Standard</th>
                                  <th>Relay Event Points</th>
                                  <th>Relay Event Gender</th>
                                  <th>Relay Team Name</th>
                                  <th>Relay Letter</th>
                                </tr>
                              </thead>
                              <tbody id='myTable'>
                                {this.state.swimsets.map((question, index) =>
                                  question.relay == 'Yes' ? (
                                    <>
                                      <tr>
                                        <td>
                                          <button
                                            type='button'
                                            class='btn btn-success btn-sm'
                                            data-toggle='modal'
                                            data-target='#useredit'
                                            id={index}
                                            value={question.event_name + '-' + question.event_id + '-' + 'relay1'}
                                            onClick={(e) => this.check(e)}
                                          >
                                            {question.event_name}
                                          </button>
                                        </td>

                                        <td style={{ backgroundColor: '#ffff6a' }}></td>

                                        <td>
                                          {this.state.swimdatas.map((question1, index) =>
                                            question.event_name?.toLowerCase() ==
                                              question1.selected_events?.toLowerCase() && 'relay1' == question1.event_id
                                              ? question1.selected_events
                                              : ''
                                          )}
                                        </td>

                                        <td>
                                          {this.state.swimdatas.map((question1, index) =>
                                            question.event_name?.toLowerCase() ==
                                              question1.selected_events?.toLowerCase() && 'relay1' == question1.event_id
                                              ? question1.qualifying_time
                                              : ''
                                          )}
                                        </td>

                                        <td>
                                          {this.state.swimdatas.map((question1, index) =>
                                            question.event_name?.toLowerCase() ==
                                              question1.selected_events?.toLowerCase() && 'relay1' == question1.event_id
                                              ? question1.qualifying_standard
                                              : ''
                                          )}
                                        </td>

                                        <td>
                                          {this.state.swimdatas.map((question1, index) =>
                                            question.event_name?.toLowerCase() ==
                                              question1.selected_events?.toLowerCase() && 'relay1' == question1.event_id
                                              ? question1.points
                                              : ''
                                          )}
                                        </td>

                                        <td>
                                          {this.state.swimdatas.map((question1, index) =>
                                            question.event_name?.toLowerCase() ==
                                              question1.selected_events?.toLowerCase() && 'relay1' == question1.event_id
                                              ? question1.gender1
                                              : ''
                                          )}
                                        </td>

                                        <td>
                                          {this.state.swimdatas.map((question1, index) =>
                                            question.event_name?.toLowerCase() ==
                                              question1.selected_events?.toLowerCase() && 'relay1' == question1.event_id
                                              ? question1.realy_team_name
                                              : ''
                                          )}
                                        </td>

                                        <td>
                                          {this.state.swimdatas.map((question1, index) =>
                                            question.event_name?.toLowerCase() ==
                                              question1.selected_events?.toLowerCase() && 'relay1' == question1.event_id
                                              ? question1.relay_letter
                                              : ''
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <button
                                            type='button'
                                            class='btn btn-success btn-sm'
                                            data-toggle='modal'
                                            data-target='#useredit'
                                            id={index}
                                            value={question.event_name + '-' + question.event_id + '-' + 'relay2'}
                                            onClick={(e) => this.check(e)}
                                          >
                                            {question.event_name}
                                          </button>
                                        </td>

                                        <td style={{ backgroundColor: '#ffff6a' }}></td>

                                        <td>
                                          {this.state.swimdatas.map((question1, index) =>
                                            question.event_name?.toLowerCase() ==
                                              question1.selected_events?.toLowerCase() && 'relay2' == question1.event_id
                                              ? question1.selected_events
                                              : ''
                                          )}
                                        </td>

                                        <td>
                                          {this.state.swimdatas.map((question1, index) =>
                                            question.event_name?.toLowerCase() ==
                                              question1.selected_events?.toLowerCase() && 'relay2' == question1.event_id
                                              ? question1.qualifying_time
                                              : ''
                                          )}
                                        </td>

                                        <td>
                                          {this.state.swimdatas.map((question1, index) =>
                                            question.event_name?.toLowerCase() ==
                                              question1.selected_events?.toLowerCase() && 'relay2' == question1.event_id
                                              ? question1.qualifying_standard
                                              : ''
                                          )}
                                        </td>

                                        <td>
                                          {this.state.swimdatas.map((question1, index) =>
                                            question.event_name?.toLowerCase() ==
                                              question1.selected_events?.toLowerCase() && 'relay2' == question1.event_id
                                              ? question1.points
                                              : ''
                                          )}
                                        </td>

                                        <td>
                                          {this.state.swimdatas.map((question1, index) =>
                                            question.event_name?.toLowerCase() ==
                                              question1.selected_events?.toLowerCase() && 'relay2' == question1.event_id
                                              ? question1.gender1
                                              : ''
                                          )}
                                        </td>

                                        <td>
                                          {this.state.swimdatas.map((question1, index) =>
                                            question.event_name?.toLowerCase() ==
                                              question1.selected_events?.toLowerCase() && 'relay2' == question1.event_id
                                              ? question1.realy_team_name
                                              : ''
                                          )}
                                        </td>

                                        <td>
                                          {this.state.swimdatas.map((question1, index) =>
                                            question.event_name?.toLowerCase() ==
                                              question1.selected_events?.toLowerCase() && 'relay2' == question1.event_id
                                              ? question1.relay_letter
                                              : ''
                                          )}
                                        </td>
                                      </tr>
                                    </>
                                  ) : (
                                    <tr>
                                      <td>
                                        <button
                                          type='button'
                                          class='btn btn-success btn-sm'
                                          data-toggle='modal'
                                          data-target='#useredit'
                                          id={index}
                                          value={question.event_name + '-' + question.event_id + '-' + 'notrelay'}
                                          onClick={(e) => this.check(e)}
                                        >
                                          {question.event_name}
                                        </button>
                                      </td>

                                      <td style={{ backgroundColor: '#ffff6a' }}></td>

                                      <td>
                                        {this.state.swimdatas.map((question1, index) =>
                                          question.event_name?.toLowerCase() ==
                                            question1.selected_events?.toLowerCase() && 'notrelay' == question1.event_id
                                            ? question1.selected_events
                                            : ''
                                        )}
                                      </td>

                                      <td>
                                        {this.state.swimdatas.map((question1, index) =>
                                          question.event_name?.toLowerCase() ==
                                            question1.selected_events?.toLowerCase() && 'notrelay' == question1.event_id
                                            ? question1.qualifying_time
                                            : ''
                                        )}
                                      </td>

                                      <td>
                                        {this.state.swimdatas.map((question1, index) =>
                                          question.event_name?.toLowerCase() ==
                                            question1.selected_events?.toLowerCase() && 'notrelay' == question1.event_id
                                            ? question1.qualifying_standard
                                            : ''
                                        )}
                                      </td>

                                      <td>
                                        {this.state.swimdatas.map((question1, index) =>
                                          question.event_name?.toLowerCase() ==
                                            question1.selected_events?.toLowerCase() && 'notrelay' == question1.event_id
                                            ? question1.points
                                            : ''
                                        )}
                                      </td>

                                      <td>
                                        {this.state.swimdatas.map((question1, index) =>
                                          question.event_name?.toLowerCase() ==
                                            question1.selected_events?.toLowerCase() && 'notrelay' == question1.event_id
                                            ? question1.gender1
                                            : ''
                                        )}
                                      </td>

                                      <td>
                                        {this.state.swimdatas.map((question1, index) =>
                                          question.event_name?.toLowerCase() ==
                                            question1.selected_events?.toLowerCase() && 'notrelay' == question1.event_id
                                            ? question1.realy_team_name
                                            : ''
                                        )}
                                      </td>

                                      <td>
                                        {this.state.swimdatas.map((question1, index) =>
                                          question.event_name?.toLowerCase() ==
                                            question1.selected_events?.toLowerCase() && 'notrelay' == question1.event_id
                                            ? question1.relay_letter
                                            : ''
                                        )}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </>
                        )}
                        <p class='athletefootertxt'>
                          <span class='athletefootertxtstar'>*</span>Indicates a Possible Invalid or Non standard Event
                          for this Class, Age and Gender
                        </p>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                {/* User table with edit delete------------------------------------------------------------------------------ */}
              </Row>

              <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        {this.state.tnamesid + ' ' + 'Swimming'}{' '}
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-row'>
                          <div class='form-group'>
                            <label for='exampleFormControlSelect1'>Qualifying Time</label>
                            <input
                              class='form-control'
                              value={this.state.qualifying_time}
                              onChange={(e) =>
                                this.setState({
                                  qualifying_time: e.target.value,
                                })
                              }
                              name='quatime'
                            />
                          </div>

                          <div class='form-group'>
                            <label for='inputEmail4'>Qualifying Standard</label>
                            <input
                              class='form-control'
                              value={this.state.qualifying_standard}
                              name='quastd'
                              type='text'
                              disabled='disabled'
                            />
                          </div>
                          <div class='form-group'>
                            <label for='inputPassword4'>Relay Event Points</label>
                            <input
                              class='form-control'
                              value={this.state.points}
                              onChange={(e) => this.setState({ points: e.target.value })}
                              name='points'
                            />
                          </div>

                          <div class='form-group'>
                            <label for='inputCity'>Relay Event Gender</label>
                            <select
                              class='form-control'
                              value={this.state.gender1}
                              onChange={(e) => this.setState({ gender1: e.target.value })}
                              name='entgender'
                            >
                              <option>Please Select</option>
                              <option>Male</option>
                              <option>Female</option>
                              <option>Mixed</option>
                            </select>
                          </div>

                          <div class='form-group'>
                            <label for='inputCity'>Relay Team Name</label>
                            <select
                              class='form-control'
                              value={this.state.realy_team_name}
                              onChange={(e) =>
                                this.setState({
                                  realy_team_name: e.target.value,
                                })
                              }
                              name='relayteamname'
                            >
                              <option>Please Select</option>
                              <option value='Adaptive Sports IOWA'>Adaptive Sports IOWA</option>
                              <option value='Adaptive Sports Program Of Ohio'>Adaptive Sports Program Of OHIO</option>
                              <option value='Arizona Heat'>Arizona Heat</option>
                              <option value='Blazesports'>Blaze Sports</option>
                              <option value="Children's Lightning Wheels Psc">Children's Lightning Wheels PSC</option>
                              <option value='Columbus Paralympic Race Club'>Columbus Paralympic Race Club</option>
                              <option value='Courage Kenny Blizards'>Courage Kenny Blizards</option>
                              <option value='Dairyland Sports'>Dairyland Sports</option>
                              <option value='DASA Dynamites'>DASA Dynamites</option>
                              <option value='Dreams In Motion'>Dreams In Motion</option>
                              <option value='HSC Cruisers'>HSC Cruisers</option>
                              <option value='Independant'>Independant</option>
                              <option value='Lakeshore Foundation'>Lakeshore Foundation</option>
                              <option value='Louisiana TeamGumbo'>Louisiana Team Gumbo</option>
                              <option value='North Jersey Navigators Psc'>North Jersey Navigators Psc</option>
                              <option value='Oregon Reign'>Oregon Reign</option>
                              <option value='Paralympic Sports TampaBay'>Paralympic Sports Tampa Bay</option>
                              <option value='Pennsylvania Center For Adaptive Sports Pcas'>
                                Pennsylvania Center For Adaptive Sports PCAS
                              </option>
                              <option value='Rochester Rookies'>Rochester Rookies</option>
                              <option value='Rolling Thunder'>Rolling Thunder</option>
                              <option value='Team Glasa'>Team GLASA</option>
                              <option value='Texas Regional Paralympic Sport'>Texas Regional Paralympic Sport</option>
                              <option value='Turn Stone'>Turn Stone</option>
                              <option value='WheelsOfFire'>WheelsOfFire</option>
                              /* <option>{this.state.allregdatas[0].team_name}</option> */
                            </select>
                          </div>

                          <div class='form-group'>
                            <label for='inputCity'>Relay Letter</label>
                            <select
                              class='form-control'
                              value={this.state.relay_letter}
                              onChange={(e) =>
                                this.setState({
                                  relay_letter: e.target.value,
                                })
                              }
                              name='relayletter'
                            >
                              <option>Please Select</option>
                              <option>A</option>
                              <option>B</option>
                              <option>C</option>
                              <option>D</option>
                              <option>E</option>
                              <option>F</option>
                              <option>G</option>
                              <option>H</option>
                              <option>I</option>
                              <option>J</option>
                              <option>K</option>
                              <option>L</option>
                              <option>M</option>
                              <option>N</option>
                              <option>O</option>
                              <option>P</option>
                              <option>Q</option>
                              <option>R</option>
                              <option>S</option>
                              <option>T</option>
                              <option>U</option>
                              <option>V</option>
                              <option>W</option>
                              <option>X</option>
                              <option>Y</option>
                              <option>Z</option>
                            </select>
                          </div>
                        </div>

                        {this.state.btncheck == true ? (
                          <center>
                            <button
                              type='button'
                              onClick={(e) => this.createswimreg()}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Create
                            </button>
                          </center>
                        ) : (
                          <>
                            <center>
                              <button type='button' class='btn btn-danger' onClick={(e) => this.updateswimreg()}>
                                Update
                              </button>
                              {` `}
                              <button
                                type='button'
                                class='btn btn-danger'
                                data-dismiss='modal'
                                onClick={(e) => this.deleteswimreg()}
                              >
                                Delete
                              </button>
                            </center>
                          </>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class='modal fade' id='meetedit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Swimmming
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='exampleFormControlSelect1'>Swim Class</label>
                          <input
                            class='form-control'
                            value={this.state.swimclass}
                            onChange={this.onchange1}
                            name='quastd'
                          />
                        </div>
                        <div class='form-group'>
                          <label for='exampleFormControlSelect1'>Breaststroke</label>
                          <input
                            class='form-control'
                            value={this.state.swimbreastclass}
                            onChange={this.onchange3}
                            name='quastd'
                          />
                        </div>
                        <div class='form-group'>
                          <label for='exampleFormControlSelect1'>Medley</label>
                          <input
                            class='form-control'
                            value={this.state.swimmedleyclass}
                            onChange={this.onchange4}
                            name='quastd'
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputEmail4'>Age Division</label>
                          <input
                            class='form-control'
                            value={this.state.swimage}
                            onChange={this.onchange2}
                            name='quastd'
                          />
                        </div>
                        <center>
                          <button type='button' class='btn btn-danger' onClick={(e) => this.updateclassage()}>
                            Update
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
export default Athleteswim;
