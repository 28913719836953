import React from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';

import Swal from 'sweetalert2';
//import Agereload from './Agedivisionload.js'

import { Select, Input } from 'antd';
import 'antd/dist/antd.css';
import { Empty } from 'antd';
import { withRouter } from 'react-router-dom';
import Regiother from './Regiother';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
var x;
const url = window.globalConfig;
class Regother extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      venues: [{}],
      venueid: [],
      tname: [],
      othereventdata: [],
      editother: [{}],
      othertable: false,
    };
    this.check = this.check.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.editother = this.editother.bind(this);
  }
  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    let itemindex = sessionStorage.getItem('itemindex');
    var self = this;

    let ids = sessionStorage.getItem('ids');
    const othereventdata = (index) => {
      if (index.other_events != 'null') {
        var joined = this.state.othereventdata.concat(index);
        this.setState({
          othereventdata: joined,
        });
      }
    };
    //Get Particular user register meet data
    axios.get(`${url.url}/api/getcreateregisters/${ids}`, {}).then(function (response) {
      self.setState({
        allregdatas: response.data.data,
      });
      {
        response.data.data.map((index) => othereventdata(index));
      }
    });

    //Hide and show manager
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('resultp').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    document.getElementById('venueesss').style.display = 'block';
  }
  check = (e) => {
    var temp = [];
    if (Object.keys(this.state.tname).length != 0) {
      temp = this.state.tname;
    }
    if (!this.state.tname.some((item) => e.target.value === item.name)) {
      temp.push({ name: e.target.value, id: e.target.id });
    }
    this.setState({ tname: temp });
  };
  editother(e) {
    sessionStorage.setItem('otherindex', e);
    sessionStorage.setItem('regid', this.state.othereventdata[e].id);
    this.setState({
      othertable: true,
      editother: this.state.othereventdata[e],
    });
    this.setState({
      // trackreg: false,
      othertable: true,
    });
  }
  handlechange() {
    window.location.replace('/managevenues');
  }
  render() {
    const { selectedItems } = this.state;
    if (this.state.othertable) {
      return <Redirect to='/othereventregister' />;
    }

    return (
      <div className='content'>
        <Row>
          <Col md='8'>
            <button
              type='button'
              data-toggle='modal'
              data-target='#selectother'
              class='btn btn-danger btn-round'
              onClick={this.handlechange}
            >
              Back
            </button>
            <Card>
              <CardHeader>
                <h5 className='title'>Other Events</h5>
              </CardHeader>

              <CardBody>
                <Form>
                  <div class='form-group row'>
                    <div class='col-xs-5'>
                      {/* <CardHeader>
        <p>Search</p>
        <input class="form-control" id="inputsm" placeholder="Search.." type="text"/>
        </CardHeader> */}
                    </div>
                  </div>
                  {Object.keys(this.state.othereventdata).length == 0 ? (
                    <Empty />
                  ) : (
                    <>
                      <Table id='my' responsive>
                        <thead>
                          <tr>
                            <th className='text-center'>#</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            {/* <th>BIB Number</th> */}
                            <th>Class</th>
                            <th>Age Division</th>
                            <th>Gender</th>
                            <th>Athletes Events</th>
                            <th class='disabled-sorting text-right'>Actions</th>
                          </tr>
                        </thead>
                        <tbody id='myTable'>
                          {this.state.othereventdata.map((question, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{question.firstname}</td>
                              <td>{question.lastname}</td>
                              <td>{question.other_class_value}</td>
                              <td>{question.othervenues_age}</td>
                              <td>{question.gender}</td>
                              <td>{question.other_events.split(',').join(', ')}</td>
                              <td class='td-actions text-right'>
                                <button
                                  type='button'
                                  rel='tooltip'
                                  class='btn btn-success btn-sm btn-icon'
                                  onClick={() => this.editother(index)}
                                  data-toggle='modal'
                                  data-target='#meetedit'
                                >
                                  {/* <i class="now-ui-icons ui-2_settings-90"></i> */}
                                  <i class='now-ui-icons gestures_tap-01'></i>
                                </button>
                                {` `}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </>
                  )}
                </Form>
              </CardBody>
            </Card>
          </Col>
          {/* User table with edit delete------------------------------------------------------------------------------ */}
        </Row>
      </div>
    );
  }
}
export default Regother;
