import React from 'react';
import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import 'antd/dist/antd.css';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';
import Swal from 'sweetalert2';

import { Empty } from 'antd';
import 'antd/dist/antd.css';
import Results from './Results.js';
const url = window.globalConfig;
var mdata = sessionStorage.getItem('index');
let ids = sessionStorage.getItem('ids');
class Atheleteresult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      meetdatas: [{}],
      vdatas: [{}],
      ahome: false,
      vhome: true,
      adetail: false,
      venuename: '',
      mid: '',
      vid: '',
      resultvalues: [{}],
      eventname: '',
      arcshow: false,
      fieldshow: false,
      ttennis: false,
      powerliftshow: false,
      trackshow: false,
      showswim: false,
      resultshow: false,
      active1: false,
      entervalue: [{}],
    };
    this.meetvenues = this.meetvenues.bind(this);
    this.back = this.back.bind(this);
    this.back1 = this.back1.bind(this);
    this.back2 = this.back2.bind(this);
    this.atheleteview = this.atheleteview.bind(this);
    this.saveresult = this.saveresult.bind(this);
    this.rpages = this.rpages.bind(this);
    this.fpages = this.fpages.bind(this);
    this.tabletennispage = this.tabletennispage.bind(this);
    this.powerlift = this.powerlift.bind(this);
    this.track = this.track.bind(this);
    this.swimming = this.swimming.bind(this);
    this.arcresult = this.arcresult.bind(this);
    this.trackresult = this.trackresult.bind(this);
    this.tennisresult = this.tennisresult.bind(this);
    this.swimresult = this.swimresult.bind(this);
    this.powerresult = this.powerresult.bind(this);
    this.fieldresult = this.fieldresult.bind(this);
  }

  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });
    var self = this;
    axios.get(`${url.url}/api/venues`, {}).then(function (response) {
      self.setState({
        vdatas: response.data.data,
      });
    });
    axios.get(`${url.url}/api/meets`, {}).then(function (response) {
      self.setState({
        meetdatas: response.data.data,
      });
    });

    //Hide and show manager
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('resultp').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
  }

  meetvenues(e) {
    var self = this;
    self.setState({
      ahome: false,
      vhome: true,
      mid: parseInt(this.state.meetdatas[e].id),
    });
  }

  atheleteview(e) {
    var v1 = parseInt(this.state.vdatas[e].id);
    let ids = sessionStorage.getItem('ids');
    axios
      .get(`${url.url}/api/getresultvenue/${v1}/${ids}`, {})
      .then(function (response) {
        self.setState({
          resultvalues: response.data,
        });
      })

      .catch(function (error) {
        self.setState({
          resultvalues: [{}],
        });
      });
    var self = this;

    self.setState({
      venuename: this.state.vdatas[e].name,
      ahome: false,
      vhome: false,
      adetail: true,
      vid: this.state.vdatas[e].id,
    });
  }

  back() {
    window.location.replace('/meets');
    sessionStorage.removeItem('curnavitem');
  }
  back2() {
    window.location.replace('/atheleteresult');
  }
  back1() {
    window.location.replace('/meets');
  }

  saveresult() {
    Swal.fire({
      title: 'Success',
      text: 'Status updated successfully',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok!',
    });
  }

  // Result Page
  //Track
  trackresult() {
    const datatosend = {
      Work: this.state.twork,
      final_groups: this.state.tfgroups,
      heat: this.state.theat,
      lane: this.state.tlane,
      preliminary_result: this.state.tpresult,
      preliminary_place: this.state.tpplace,
      wind: this.state.twind,
      final_result: this.state.tfresult,
      windf: this.state.twind,
      final_result: this.state.tfresult,
      final_place: this.state.tfplace,
      windf: this.state.twindf,
      bib: this.state.tbib,
      last_name: this.state.tlname,
      first_name: this.state.tfname,
      class: this.state.tclass,
      class_placing: this.state.tcplace,
      age: this.state.tage,
      gender: this.state.tgender,
      heatf: this.state.theatf,
      lanef: this.state.tlanef,
      qualifies: this.state.tqualify,
      break_record: this.state.tbrecord,
      njdc_record: this.state.tnrecord,
      t1: this.state.t1,
      t2: this.state.t2,
      t3: this.state.t3,
      t4: this.state.t4,
      relay_points: this.state.trpoints,
      relay_gender: this.state.trgender,
      relay_name: this.state.trname,
      relay_alt: this.state.trnalt,
      event_name: this.state.teventname,
      points: this.state.tpoint,
      created_by: 1,
    };

    axios.post(`${url.url}/api/createtrackresult`, datatosend, {}).then(function (response) {
      if (response.status == 201) {
        Swal.fire({
          title: 'Success',
          text: 'successfully Updated',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        }).then(function (response) {
          window.location.reload();
        });
      }
    });
  }

  //field
  fieldresult() {
    const datatosend = {
      pit: this.state.Fpit,
      groups: this.state.Fgroups,
      bib: this.state.Fbib,
      lastname: this.state.Flname,
      firstname: this.state.Ffname,
      class: this.state.Fclass,
      placing_class: this.state.Fpclass,
      age: this.state.Fage,
      gender: this.state.Fgender,
      final_results: this.state.Ffresults,
      final_place: this.state.Ffplace,
      wind: this.state.Fwind,
      implement_num: this.state.Fimpnum,
      qual_std: this.state.FQstd,
      qualifies: this.state.FQualifies,
      break_record: this.state.Fbrecord,
      njdc_record: this.state.Fnjdcrecord,
      f1: this.state.Ff1,
      f2: this.state.Ff2,
      f3: this.state.Ff3,
      f4: this.state.Ff4,
      event_name: this.state.Feventname,
      points: this.state.Fpoints,
      best_mark: this.state.Fbmarks,
      performance_points: this.state.Fperpoints,
      prelim_results: this.state.Fpreresults,
      prelim_place: this.state.Fpreplace,
      windf: this.state.Fwindf,
      datetime: this.state.Fdatetime,
      created_by: 1,
    };

    axios.post(`${url.url}/api/createfieldresult`, datatosend, {}).then(function (response) {
      if (response.status == 201) {
        Swal.fire({
          title: 'Success',
          text: 'successfully Updated',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        }).then(function (response) {
          window.location.reload();
        });
      }
    });
  }

  //Swimming
  swimresult() {
    const datatosend = {
      work: this.state.swork,
      finals_group: this.state.sfgroup,
      heat: this.state.sheat,
      lane: this.state.slane,
      preliminary_results: this.state.spreresults,
      preliminary_place: this.state.spreplace,
      final_results: this.state.sfresults,
      final_place: this.state.sfplace,
      bib: this.state.sbib,
      lastname: this.state.slname,
      firstname: this.state.sfname,
      class: this.state.sclass,
      age: this.state.sage,
      gender: this.state.sgender,
      heatf: this.state.sheatf,
      lanef: this.state.slanef,
      qualifies: this.state.squalifies,
      qtime: this.state.sqtime,
      break_record: this.state.sbrecord,
      s1: this.state.ss1,
      s2: this.state.ss2,
      s3: this.state.ss3,
      relay_points: this.state.srelaypoint,
      relay_gender: this.state.sgender,
      relay_name: this.state.srelayname,
      points: this.state.spoints,
      record: this.state.srecord,
      event: this.state.sevent,
      created_by: 1,
    };

    axios.post(`${url.url}/api/createswimresult`, datatosend, {}).then(function (response) {
      if (response.status == 201) {
        Swal.fire({
          title: 'Success',
          text: 'successfully Updated',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        }).then(function (response) {
          window.location.reload();
        });
      }
    });
  }

  //Archery
  arcresult() {
    const datatosend = {
      target: this.state.Artarget,
      groups: this.state.Argroup,
      bib: this.state.Arbib,
      lastname: this.state.Arlname,
      firstname: this.state.Arfname,
      country: this.state.Arcountry,
      class: this.state.Arclass,
      age: this.state.Arage,
      gender: this.state.Argender,
      preliminary_results: this.state.Arpresults,
      preliminary_place: this.state.Arpplace,
      final_results: this.state.Arfresults,
      final_place: this.state.Arfplace,
      qual_std: this.state.Arqstd,
      qualifies: this.state.Arqualifies,
      break_record: this.state.Arbrecord,
      njdc_record: this.state.Arnjdcrecord,
      a1: this.state.Ar1,
      a2: this.state.Ar2,
      a3: this.state.Ar3,
      a4: this.state.Ar4,
      event_name: this.state.Areventname,
      type_bow: this.state.Artypebow,
      bow_stand: this.state.Arbow,
      eventteam_scoring: this.state.Arscoring,
      created_by: 1,
    };

    var self = this;
    axios.post(`${url.url}/api/createarcheryresult`, datatosend, {}).then(function (response) {
      if (response.status == 201) {
        Swal.fire({
          title: 'Success',
          text: 'successfully Updated',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        }).then(function (response) {
          window.location.reload();
        });
      }
    });
  }

  //powerlift
  powerresult() {
    const datatosend = {
      firstname: this.state.Plfname,
      lastname: this.state.Pllname,
      country: this.state.Plcountry,
      age: this.state.Plage,
      set_weight_kg: this.state.Plsetwght,
      class: this.state.Plclass,
      bar_height: this.state.Plbarheight,
      lif1: this.state.Pllift1,
      lift2: this.state.Pllift2,
      lift3: this.state.Pllift3,
      record_lift: this.state.Plrecordlift,
      results_kg: this.state.Plresults,
      cur_record_kg: this.state.Plcrntrecord,
      break_record: this.state.Plbrecord,
      place: this.state.Plplace,
      gender: this.state.Plgender,
      bench: this.state.Plbench,
      groups: this.state.Plgroup,
      draw: this.state.Pldraw,
      created_by: 1,
    };

    axios.post(`${url.url}/api/createpowerresult`, datatosend, {}).then(function (response) {
      if (response.status == 201) {
        Swal.fire({
          title: 'Success',
          text: 'successfully Updated',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        }).then(function (response) {
          window.location.reload();
        });
      }
    });
  }

  //table tennis
  tennisresult() {
    const datatosend = {
      groups: this.state.TTgroups,
      bib: this.state.TTbib,
      lastname: this.state.TTlname,
      firstname: this.state.TTfname,
      class: this.state.TTclass,
      age: this.state.TTage,
      gender: this.state.TTgender,
      final_results: this.state.TTfresults,
      final_place: this.state.TTfplace,
      qualifies: this.state.TTqualifies,
      event_name: this.state.TTeventname,
      created_by: 1,
    };

    axios.post(`${url.url}/api/createtennisresult`, datatosend, {}).then(function (response) {
      if (response.status == 201) {
        Swal.fire({
          title: 'Success',
          text: 'successfully Updated',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        }).then(function (response) {
          window.location.reload();
        });
      }
    });
  }
  //End of Result Page

  rpages(e) {
    var self = this;

    self.setState({
      arcshow: true,
      entervalue: this.state.resultvalues[e],
    });
  }

  fpages(e) {
    var self = this;
    self.setState({
      fieldshow: true,
      entervalue: this.state.resultvalues[e],
    });
  }

  tabletennispage(e) {
    var self = this;
    self.setState({
      ttennis: true,
      entervalue: this.state.resultvalues[e],
    });
  }
  powerlift(e) {
    var self = this;
    self.setState({
      powerliftshow: true,
      entervalue: this.state.resultvalues[e],
    });
  }

  track(e) {
    var self = this;
    self.setState({
      trackshow: true,
      entervalue: this.state.resultvalues[e],
    });
  }
  swimming(e) {
    var self = this;
    self.setState({
      showswim: true,
      entervalue: this.state.resultvalues[e],
    });
  }

  render() {
    //sessionStorage.setItem("path","/atheleteresult");
    // home -----------------------------------------------------------------
    // venue page -------------------------------------------------------------------------------------------
    return <div>{/* <h1>Working on it</h1> */}</div>;
  }
}

export default Atheleteresult;
