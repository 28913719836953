import React from 'react';
import '../App.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';

import Swal from 'sweetalert2';

import { Select, Input } from 'antd';
import 'antd/dist/antd.css';
import 'datatables.net';
import 'datatables.net-bs4';

import Loader from 'react-loader-spinner';

import { Empty } from 'antd';
import Viewall from './Viewall';
import e from 'cors';

const { Option } = Select;
const url = window.globalConfig;
var mdata = sessionStorage.getItem('index');
let ids = sessionStorage.getItem('ids');
var x = '';
var y;

class Detailpage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      meetdatas: [{}],
      regdetails: [{}],
      datacheck: false,
      alldata: false,
      bibno: '',
      feedback: '',
      datas: {},
      viewall: false,
      allregdatas: {},
      editview: true,
      fieldname: true,
      firstname: '',
      lastname: '',
      bib_no: '',
      age: '',
      email: '',
      dob: '',
      gender: '',
      designation: '',
      team_name: '',
      coach_email: '',
      address: '',
      address2: '',
      city: '',
      zipcode: '',
      ipc: '',
      country: '',
      cellphone: '',
      classification_site: '',
      classified_whom: '',
      coach_come_meet: '',
      weightlifting_age: '',
      weightlift_events: '',
      team_age: '',
      track_events: '',
      track_age: '',
      team_events: '',
      tabletennis_events: '',
      swim_events: '',
      swim_age: '',
      tabletennis_age: '',
      adaptivetennis_age: '',
      state: '',
      sanctioned_meet: '',
      othervenues_age: '',
      other_events: '',
      need_classified: '',
      coach_homephone: '',
      homephone: '',
      archery_age: '',
      field_events: '',
      field_age: '',
      fencing_events: '',
      comments: '',
      archery_events: '',
      headcoach_name: '',
      coach_workphone: '',
      id: '',
      loader: true,
      register_num: '',
      airrifle_age: '',
      paratriathlon100_age: '',
      paratriathlon200_age: '',
      paratriathlon500_age: '',
      boccia_age: '',
      cycling_age: '',
      otherdatas: [],
      otherven: [],
      x: '',
    };

    this.statusreject = this.statusreject.bind(this);
    this.statusupdate = this.statusupdate.bind(this);
    // this.Rejected = this.Rejected.bind(this);
    this.edit = this.edit.bind(this);
    this.save = this.save.bind(this);
    this.managevenue = this.managevenue.bind(this);
    this.handlechange1 = this.handlechange1.bind(this);
  }

  genderchange = (e) => {
    this.setState({
      gender: e.target.value,
    });
  };
  componentWillMount() {
    let ids = sessionStorage.getItem('ids');
    var self = this;
    axios.get(`${url.url}/api/getregmeet/${ids}`, {}).then(function (response) {
      var output = [];

      response.data.forEach(function (item, index) {
        var existing = output.filter(function (v, i) {
          return v.user_id == item.user_id;
        });
        if (item.status == 'pending') {
          if (existing.length) {
            var existingIndex = output.indexOf(existing[0]);
            output[existingIndex].venuename = output[existingIndex].venuename.concat(item.venuename) + ',';
            output[existingIndex].eventname = output[existingIndex].eventname.concat(item.eventname) + ',';
            output[existingIndex].venueid = output[existingIndex].venueid.concat(item.venueid) + ',';
            output[existingIndex].eventid = output[existingIndex].eventid.concat(item.eventid) + ',';
            output[existingIndex].id = output[existingIndex].id.concat(item.id) + ',';
          } else {
            item.venuename = [item.venuename];
            item.eventname = [item.eventname];
            item.venueid = [item.venueid];
            item.eventid = [item.eventid];
            item.id = [item.id];
            //   }

            output.push(item);
          }
        }
      });
      self.setState({
        regdetails: output,
      });
    });

    //allregisterusers
    var self = this;

    //Get Particular user register meet data
    let athleteid = sessionStorage.getItem('athleteid');
    axios.get(`${url.url}/api/getathleteid/${athleteid}`, {}).then(function (response) {
      self.setState({
        allregdatas: response.data,
      });
    });
  }

  async componentDidMount() {
    let ids = sessionStorage.getItem('ids');
    var self = this;
    axios.get(`${url.url}/api/getregmeet/${ids}`, {}).then(function (response) {
      var output = [];

      response.data.forEach(function (item, index) {
        var existing = output.filter(function (v, i) {
          return v.user_id == item.user_id;
        });
        if (item.status == 'pending') {
          if (existing.length) {
            var existingIndex = output.indexOf(existing[0]);
            output[existingIndex].venuename = output[existingIndex].venuename + ','.concat(item.venuename) + ',';
            output[existingIndex].eventname = output[existingIndex].eventname.concat(item.eventname) + ',';
            output[existingIndex].agedivision = output[existingIndex].agedivision.concat(item.agedivision) + ',';
            output[existingIndex].gendervariation =
              output[existingIndex].gendervariation.concat(item.gendervariation) + ',';
            output[existingIndex].venueid = output[existingIndex].venueid.concat(item.venueid) + ',';
            output[existingIndex].eventid = output[existingIndex].eventid.concat(item.eventid) + ',';
            output[existingIndex].id = output[existingIndex].id.concat(item.id) + ',';
          } else {
            item.venuename = [item.venuename] + ', ';
            item.eventname = [item.eventname] + ', ';
            item.agedivision = [item.agedivision] + ', ';
            item.gendervariation = [item.gendervariation] + ', ';
            item.venueid = [item.venueid];
            item.eventid = [item.eventid];
            item.id = [item.id];
            //   }

            output.push(item);
          }
        }
      });
      self.setState({
        regdetails: output,
      });
    });
    let idofathlete = sessionStorage.getItem('athleteid');
    const data = {
      selected_events: this.state.tname,
      meet_id: sessionStorage.getItem('ids'),
    };
    axios
      .get(`${url.url}/api/getoregisterid/${idofathlete}`, data, {})
      .then(function (response) {
        self.setState({
          otherdatas: response.data,
        });
      })
      .catch(function (error) {});
    var self = this;
    axios.get(`${url.url}/api/meets`, {}).then(function (response) {
      self.setState({
        meetdatas: response.data.data,
      });
    });

    //Hide and show manager
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    // document.getElementById("venueesss").style.display = "block";

    //Get Particular user register meet data
    let athleteid = sessionStorage.getItem('athleteid');
    await axios.get(`${url.url}/api/getathleteid/${athleteid}`, {}).then(function (response) {
      self.setState({
        allregdatas: response.data,
        loader: false,
      });
    });
    setTimeout(() => {
      self.setState({
        firstname: this.state.allregdatas[0].firstname,
        lastname: this.state.allregdatas[0].lastname,
        bib_no: this.state.allregdatas[0].bib_no,
        citizenship: this.state.allregdatas[0].country,
        ipc: this.state.allregdatas[0].ipc,
        register_num: this.state.allregdatas[0].register_num,
        email: this.state.allregdatas[0].email,
        age: this.state.allregdatas[0].age,
        dob: this.state.allregdatas[0].dob,
        gender: this.state.allregdatas[0].gender,
        designation: this.state.allregdatas[0].designation,
        team_name: this.state.allregdatas[0].team_name,
        coach_email: this.state.allregdatas[0].coach_email,
        address: this.state.allregdatas[0].address,
        address2: this.state.allregdatas[0].address2,
        city: this.state.allregdatas[0].city,
        zipcode: this.state.allregdatas[0].zipcode,
        country: this.state.allregdatas[0].country,
        cellphone: this.state.allregdatas[0].cellphone,
        classification_site: this.state.allregdatas[0].classification_site,
        classified_whom: this.state.allregdatas[0].classified_whom,
        coach_come_meet: this.state.allregdatas[0].coach_come_meet,
        weightlifting_age: this.state.allregdatas[0].weightlifting_age,
        weightlift_events: this.state.allregdatas[0].weightlift_events,
        track_events: this.state.allregdatas[0].track_events,
        track_age: this.state.allregdatas[0].track_age,
        team_events: this.state.allregdatas[0].team_events,
        tabletennis_events: this.state.allregdatas[0].tabletennis_events,
        swim_events: this.state.allregdatas[0].swim_events,
        swim_age: this.state.allregdatas[0].swim_age,
        tabletennis_age: this.state.allregdatas[0].tabletennis_age,
        adaptivetennis_age: this.state.allregdatas[0].fencing_age,
        team_age: this.state.allregdatas[0].team_age,
        state: this.state.allregdatas[0].state,
        sanctioned_meet: this.state.allregdatas[0].sanctioned_meet,
        airrifle_age: this.state.allregdatas[0].airrifle_age,
        paratriathlon100_age: this.state.allregdatas[0].paratriathlon100_age,
        paratriathlon200_age: this.state.allregdatas[0].paratriathlon200_age,
        paratriathlon500_age: this.state.allregdatas[0].paratriathlon500_age,
        boccia_age: this.state.allregdatas[0].boccia_age,
        cycling_age: this.state.allregdatas[0].cycling_age,
        other_events: this.state.allregdatas[0].other_events,
        need_classified: this.state.allregdatas[0].need_classified,
        coach_homephone: this.state.allregdatas[0].coach_homephone,
        homephone: this.state.allregdatas[0].homephone,
        archery_age: this.state.allregdatas[0].archery_age,
        field_events: this.state.allregdatas[0].field_events,
        field_age: this.state.allregdatas[0].field_age,
        fencing_events: this.state.allregdatas[0].fencing_events,
        comments: this.state.allregdatas[0].comments,
        archery_events: this.state.allregdatas[0].archery_events,
        headcoach_name: this.state.allregdatas[0].headcoach_name,
        coach_workphone: this.state.allregdatas[0].coach_workphone,
        id: this.state.allregdatas[0].id,
      });
      $('#name').val(this.state.firstname);
      $('#name1').val(this.state.lastname);
      $('#bibno').val(this.state.bib_no);
      $('#regino').val(this.state.register_num);
      $('#citizenship').val(this.state.citizenship);
      $('#ipcno').val(this.state.ipc);
      $('#email').val(this.state.email);
      $('#age').val(this.state.age);
      $('#dob').val(this.state.dob);
      $('#gender').val(this.state.gender);
      $('#designate').val(this.state.designation);
      $('#team').val(this.state.team_name);
      $('#cemail').val(this.state.coach_email);
      $('#Address').val(this.state.address);
      $('#Address2').val(this.state.address2);
      $('#City').val(this.state.city);
      $('#zipcode').val(this.state.zipcode);
      $('#country').val(this.state.country);
      $('#homephone').val(this.state.homephone);
      $('#cellphone').val(this.state.cellphone);
      $('#classification_site').val(this.state.classification_site);
      $('#classified_whom').val(this.state.classified_whom);
      $('#coach_come_meet').val(this.state.coach_come_meet);
      $('#weightlift_events').val(this.state.weightlift_events);
      $('#track_events').val(this.state.track_events);
      $('#team_events').val(this.state.team_events);
      $('#tabletennis_events').val(this.state.tabletennis_events);
      $('#swim_events').val(this.state.swim_events);
      $('#state').val(this.state.state);
      $('#sanctioned_meet').val(this.state.sanctioned_meet);
      $('#other_events').val(this.state.other_events);
      $('#need_classified').val(this.state.need_classified);
      $('#homephone').val(this.state.homephone);
      $('#field_events').val(this.state.field_events);
      $('#fencing_events').val(this.state.fencing_events);
      $('#comments').val(this.state.comments);
      $('#archery_events').val(this.state.archery_events);
      $('#coach_workphone').val(this.state.coach_workphone);

      if (self.state.need_classified == 'Ambulatory') {
        document.getElementById('track-radio1').checked = true;
        document.getElementById('track-radio2').checked = false;
        document.getElementById('track-radio3').checked = false;
      }
      if (self.state.need_classified == 'Wheelchair') {
        document.getElementById('track-radio1').checked = false;
        document.getElementById('track-radio2').checked = true;
        document.getElementById('track-radio3').checked = false;
      }
      if (self.state.need_classified == 'No') {
        document.getElementById('track-radio1').checked = false;
        document.getElementById('track-radio2').checked = false;
        document.getElementById('track-radio3').checked = true;
      }
    }, 750);
  }
  edit() {
    this.setState({ editview: false });

    var ele = document.getElementsByClassName('detail_text');
    for (var i = 0; i < ele.length; i++) {
      ele[i].style.border = '1px solid #000';
    }
  }
  save() {
    const data = {
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      bib_no: this.state.bib_no,
      citizenship: this.state.citizenship,
      ipc: this.state.ipc,
      email: this.state.email,
      age: this.state.age,
      dob: this.state.dob,
      gender: this.state.gender,
      designation: this.state.designation,
      team_name: this.state.team_name,
      coach_email: this.state.coach_email,
      address: this.state.address,
      address2: this.state.address2,
      city: this.state.city,
      zipcode: this.state.zipcode,
      country: this.state.country,
      cellphone: this.state.cellphone,
      classification_site: this.state.classification_site,
      classified_whom: this.state.classified_whom,
      coach_come_meet: this.state.coach_come_meet,
      weightlifting_age: this.state.weightlifting_age,
      weightlift_events: this.state.weightlift_events,
      track_events: this.state.track_events,
      track_age: this.state.track_age,
      team_events: this.state.team_events,
      tabletennis_events: this.state.tabletennis_events,
      swim_events: this.state.swim_events,
      swim_age: this.state.swim_age,
      tabletennis_age: this.state.tabletennis_age,
      fencing_age: this.state.adaptivetennis_age,
      team_age: this.state.teamsports_age,
      airrifle_age: this.state.airrifle_age === '' ? 'n/a' : this.state.airrifle_age,
      paratriathlon100_age: this.state.paratriathlon100_age === '' ? 'n/a' : this.state.paratriathlon100_age,
      paratriathlon200_age: this.state.paratriathlon200_age === '' ? 'n/a' : this.state.paratriathlon200_age,
      paratriathlon500_age: this.state.paratriathlon500_age === '' ? 'n/a' : this.state.paratriathlon500_age,
      boccia_age: this.state.boccia_age === '' ? 'n/a' : this.state.boccia_age,
      cycling_age: this.state.cycling_age === '' ? 'n/a' : this.state.cycling_age,
      state: this.state.state,
      sanctioned_meet: this.state.sanctioned_meet,
      othervenues_age: this.state.othervenues_age,
      other_events: this.state.other_events,
      need_classified: this.state.need_classified,
      coach_homephone: this.state.coach_homephone,
      homephone: this.state.homephone,
      archery_age: this.state.archery_age,
      field_events: this.state.field_events,
      field_age: this.state.field_age,
      fencing_events: this.state.fencing_events,
      comments: this.state.comments,
      archery_events: this.state.archery_events,
      headcoach_name: this.state.headcoach_name,
      coach_workphone: this.state.coach_workphone,
      register_num: this.state.register_num,
    };
    axios
      .put(`${url.url}/api/athletainfoupdate/${this.state.id}`, data, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  managevenue() {
    sessionStorage.setItem('athleteid', this.state.allregdatas[0].id);
    window.location.replace('/athletevenues');
  }
  statusreject(event) {
    event.preventDefault();
    const datatosend = {
      status: this.state.rejecteddata,
      bib_no: 0,
      reason: this.state.feedback,
    };
    axios
      .put(`${url.url}/api/updateRegstatus/${this.state.regdetails[this.state.inter].id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  statusupdate(e) {
    var i = parseInt(e.split('-')[0]);

    if (e.split('-')[1] == 'approved') {
      var j = parseInt(this.state.bibno) + 1;
    } else if (e.split('-')[1] == 'rejected') {
      var j = 0;
    }

    const datatosend = {
      status: e.split('-')[1],
      bib_no: j,
      reason: 'null',
    };
    axios
      .put(`${url.url}/api/updateRegstatus/${this.state.regdetails[0].id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  calculate_age = (dob) => {
    var today = new Date();
    var birthDate = new Date(dob); // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = 12 - birthDate.getMonth();
    if (m < 0 || (m === 0 && 31 < birthDate.getDate())) {
      age_now--;
    }
    return age_now;
  };
  calculate_age = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    return age_now;
  };
  handlechange1(event) {
    var self = this;
    self.setState({ dob: event.target.value }, () => {});
    var age_latest = { age_latest: this.calculate_age(event.target.value) };
    var x = JSON.stringify(age_latest);
    var y = x.split(':');
    var z = y[1].split('}');
    self.setState({ age: z[0] }, () => {
      if (z[0] >= 60) {
        self.setState({
          track_age: 'M60',
          field_age: 'M60',
          swim_age: 'Masters',
          othervenues_age: 'M60',
          tabletennis_age: 'U60',
        });
      }
      if (z[0] >= 50 && z[0] <= 59) {
        self.setState({
          track_age: 'M50',
          field_age: 'M50',
          swim_age: 'Masters',
          othervenues_age: 'M50',
          tabletennis_age: 'U50',
        });
      }
      if (z[0] >= 35 && z[0] <= 49) {
        self.setState({
          track_age: 'M35',
          field_age: 'M35',
          swim_age: 'Masters',
          othervenues_age: 'M35',
          tabletennis_age: 'U35',
        });
      }
      if (z[0] >= 23 && z[0] <= 34) {
        self.setState({
          track_age: 'Adults',
          field_age: 'Adults',
          swim_age: 'Senior',
          weightlifting_age: 'Adults',
          othervenues_age: 'Adults',
          tabletennis_age: 'Adults',
        });
      }
      if (z[0] >= 20 && z[0] <= 22) {
        self.setState({
          track_age: 'U23',
          field_age: 'U23',
          swim_age: 'U23',
          weightlifting_age: 'U23',
          othervenues_age: 'U23',
          tabletennis_age: 'U23',
        });
      }
      if (z[0] >= 17 && z[0] <= 19) {
        self.setState({
          track_age: 'U20',
          field_age: 'U20',
          swim_age: 'U20',
          othervenues_age: 'U20',
          tabletennis_age: 'U20',
        });
      }
      if (z[0] >= 14 && z[0] <= 16) {
        self.setState({
          track_age: 'U17',
          field_age: 'U17',
          swim_age: 'U17',
          othervenues_age: 'U17',
          tabletennis_age: 'U17',
        });
      }
      if (z[0] >= 11 && z[0] <= 13) {
        self.setState({
          track_age: 'U14',
          field_age: 'U14',
          swim_age: 'U14',
          othervenues_age: 'U14',
        });
      }
      if (z[0] >= 7 && z[0] <= 10) {
        self.setState({
          track_age: 'U11',
          field_age: 'U11',
          swim_age: 'U11',
          othervenues_age: 'U11',
          tabletennis_age: 'U11',
        });
      }
      if (z[0] <= 6) {
        self.setState({
          track_age: 'Futures',
          field_age: 'Futures',
          swim_age: 'Futures',
          othervenues_age: 'Futures',
          tabletennis_age: 'Futures',
        });
      }
      if (z[0] >= 23) {
        self.setState({ archery_age: 'Adults' });
      }
      if (z[0] >= 21 && z[0] <= 22) {
        self.setState({ archery_age: 'YoungAdult' });
      }
      if (z[0] >= 18 && z[0] <= 20) {
        self.setState({ archery_age: 'Junior' });
      }
      if (z[0] >= 15 && z[0] <= 17) {
        self.setState({ archery_age: 'Cadet' });
      }
      if (z[0] >= 13 && z[0] <= 14) {
        self.setState({ archery_age: 'Cub' });
      }
      if (z[0] >= 10 && z[0] <= 12) {
        self.setState({ archery_age: 'Bowmen' });
      }
      if (z[0] >= 7 && z[0] <= 9) {
        self.setState({ archery_age: 'Yeomen' });
      }
      if (z[0] < 7) {
        self.setState({ archery_age: 'Invalid' });
      }
      if (z[0] >= 14 && z[0] <= 19) {
        self.setState({ weightlifting_age: 'Junior' });
      }
      if (z[0] >= 23) {
        self.setState({ weightlifting_age: 'Adults' });
      }
      if (z[0] < 14) {
        self.setState({ weightlifting_age: 'Not Eligible' });
      }
      if (z[0] >= 7 && z[0] >= 9) {
        self.setState({ archery_age: 'Yeoman' });
      }
      if (z[0] >= 13 && z[0] >= 14) {
        self.setState({ archery_age: 'U15' });
      }
      if (z[0] >= 15 && z[0] >= 17) {
        self.setState({ archery_age: 'U18' });
      }
      if (z[0] >= 18 && z[0] >= 20) {
        self.setState({ archery_age: 'U21' });
      }
      if (z[0] >= 21 && z[0] >= 49) {
        self.setState({ archery_age: 'Senior' });
      }
      if (z[0] >= 50 && z[0] >= 59) {
        self.setState({ archery_age: 'M50' });
      }
      if (z[0] >= 60 && z[0] >= 69) {
        self.setState({ archery_age: 'M60' });
      }
      if (z[0] >= 70) {
        self.setState({ archery_age: 'M70' });
      }
      if (z[0] >= 7) {
        self.setState({ teamsports_age: 'Open' });
      }
      if (z[0] >= 7 && z[0] >= 8) {
        self.setState({ adaptivetennis_age: '8s' });
      }
      if (z[0] >= 9 && z[0] >= 10) {
        self.setState({ adaptivetennis_age: '10s' });
      }
      if (z[0] >= 11 && z[0] >= 12) {
        self.setState({ adaptivetennis_age: '12s' });
      }
      if (z[0] >= 13 && z[0] >= 14) {
        self.setState({ adaptivetennis_age: '14s' });
      }
      if (z[0] >= 15 && z[0] >= 16) {
        self.setState({ adaptivetennis_age: '16s' });
      }
      if (z[0] >= 17 && z[0] >= 18) {
        self.setState({ adaptivetennis_age: '18s' });
      }
      if (z[0] >= 19) {
        self.setState({ adaptivetennis_age: 'Open' });
      }
      if (z[0] >= 12 && z[0] >= 14) {
        self.setState({ airrifle_age: 'Sub Junior' });
      }
      if (z[0] >= 15 && z[0] >= 17) {
        self.setState({ airrifle_age: 'Junior' });
      }
      if (z[0] >= 18 && z[0] >= 20) {
        self.setState({ airrifle_age: 'Intermediate' });
      }
      if (z[0] >= 21 && z[0] >= 64) {
        self.setState({ airrifle_age: 'Adult' });
      }
      if (z[0] >= 64) {
        self.setState({ airrifle_age: 'Senior ' });
      }
      if (z[0] >= 7 && z[0] >= 15) {
        self.setState({
          paratriathlon100_age: 'Youth',
          paratriathlon200_age: 'Youth',
          paratriathlon500_age: 'Youth',
          boccia_age: 'Youth',
          cycling_age: 'Youth',
        });
      }
      if (z[0] >= 16 && z[0] >= 19) {
        self.setState({
          paratriathlon100_age: 'Junior',
          paratriathlon200_age: 'Junior',
          paratriathlon500_age: 'Junior',
          boccia_age: 'Junior',
          cycling_age: 'Junior',
        });
      }
      if (z[0] >= 20) {
        self.setState({
          paratriathlon100_age: 'Adult',
          paratriathlon200_age: 'Adult',
          paratriathlon500_age: 'Adult',
          boccia_age: 'Adult',
          cycling_age: 'Adult',
        });
      }
      if (z[0] >= 7) {
        self.setState({ teamsports_age: 'Open ' });
      }
    });
  }

  back() {
    window.location.replace('/athleteregister');
  }
  cleardata = () => {
    const datatosend = {
      track_events: '',
      field_events: '',
      swim_events: '',
      archery_events: '',
      weightlift_events: '',
      tabletennis_events: '',
      team_events: '',
      fencing_events: '',
      other_events: '',
    };
    axios
      .put(`${url.url}/api/deleteathletevenue/${sessionStorage.getItem('athleteid')}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  };

  render() {
    var mdata = sessionStorage.getItem('index');
    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div>
              <div class='modal fade' id='feedback' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog' id='feedback'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Feedback
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form onSubmit={this.submitlogin}>
                        <div class='form-group'>
                          <label for='exampleInputPassword1'>Reason for Rejection</label>
                          <input
                            type='text'
                            class='form-control'
                            id='rejecttxt'
                            onChange={(e) => this.setState({ feedback: e.target.value })}
                            placeholder='Please enter the reason'
                            required
                          />
                        </div>
                        <button type='submit' class='btn btn-danger' onClick={(event) => this.statusreject(event)}>
                          Send
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div className='content detailpage'>
                <Row>
                  <Col md='8'>
                    <button
                      type='button'
                      data-toggle='modal'
                      data-target='#newmeet'
                      class='btn btn-danger btn-round'
                      onClick={this.back}
                    >
                      Back
                    </button>

                    {''}
                    <Card>
                      <CardHeader>
                        <h5 className='title'>Details</h5>
                      </CardHeader>
                      <CardBody>
                        <Form>
                          {this.state.allregdatas ? (
                            <div class='container container_01'>
                              <div class='form-row form-row_01'>
                                <div class='form-group form-group_01 col-md-12'>
                                  <label class='input' for='inputEmail4'>
                                    FirstName
                                  </label>
                                  <input
                                    type='text'
                                    id='name'
                                    class='detail_text detail_addres'
                                    disabled={this.state.editview}
                                    onChange={(e) =>
                                      this.setState({
                                        firstname: e.target.value,
                                      })
                                    }
                                  ></input>
                                </div>

                                <div class='form-group form-group_01 col-md-12'>
                                  <label class='input' for='inputEmail4'>
                                    LastName
                                  </label>
                                  <input
                                    type='text'
                                    id='name1'
                                    class='detail_text detail_addres'
                                    disabled={this.state.editview}
                                    onChange={(e) =>
                                      this.setState({
                                        lastname: e.target.value,
                                      })
                                    }
                                  ></input>
                                </div>
                                <div class='form-group form-group_01 col-md-12'>
                                  <label class='input' for='inputEmail4'>
                                    Bib no
                                  </label>
                                  <input
                                    type='text'
                                    id='bibno'
                                    class='detail_text detail_addres'
                                    disabled={this.state.editview}
                                    onChange={(e) =>
                                      this.setState({
                                        bib_no: e.target.value,
                                      })
                                    }
                                  ></input>
                                </div>
                                <div class='form-group form-group_01 col-md-12'>
                                  <label class='input' for='inputEmail4'>
                                    Citizenship
                                  </label>
                                  <input
                                    type='text'
                                    id='citizenship'
                                    class='detail_text detail_addres'
                                    value={this.state.country}
                                    disabled={this.state.editview}
                                    onChange={(e) =>
                                      this.setState({
                                        citizenship: e.target.value,
                                      })
                                    }
                                  ></input>
                                </div>
                                <div class='form-group form-group_01 col-md-12'>
                                  <label class='input' for='inputEmail4'>
                                    IPC no
                                  </label>
                                  <input
                                    type='text'
                                    id='ipcno'
                                    class='detail_text detail_addres'
                                    value={this.state.ipc}
                                    disabled={this.state.editview}
                                    onChange={(e) =>
                                      this.setState({
                                        ipc: e.target.value,
                                      })
                                    }
                                  ></input>
                                </div>
                                <div class='form-group form-group_01 col-md-12'>
                                  <label class='input' for='inputEmail4'>
                                    Registration no
                                  </label>
                                  <input
                                    type='text'
                                    id='regino'
                                    class='detail_text detail_addres'
                                    disabled={this.state.editview}
                                    onChange={(e) =>
                                      this.setState({
                                        register_num: e.target.value,
                                      })
                                    }
                                  ></input>
                                </div>
                                <div class='form-group form-group_01 col-md-12'>
                                  <label class='input' for='inputEmail4'>
                                    Email
                                  </label>
                                  <input
                                    type='email'
                                    id='email'
                                    class='detail_text detail_addres'
                                    value={this.state.email}
                                    disabled={this.state.editview}
                                    onChange={(e) => this.setState({ email: e.target.value })}
                                  ></input>
                                </div>

                                <div class='form-group form-group_01 col-md-12'>
                                  <label class='input' for='inputEmail4'>
                                    Age
                                  </label>
                                  <input
                                    type='text'
                                    id='age'
                                    class='detail_text detail_addres'
                                    disabled
                                    value={this.state.age}
                                  ></input>
                                </div>

                                <div class='form-group form-group_01 col-md-12'>
                                  <label class='input' for='inputEmail4'>
                                    DOB
                                  </label>
                                  <input
                                    type='date'
                                    id='dob'
                                    class='detail_text detail_addres'
                                    onChange={this.handlechange1}
                                  ></input>
                                </div>

                                <div class='form-group form-group_01 col-md-12'>
                                  <label class='input' for='inputEmail4'>
                                    Gender
                                  </label>
                                  <select
                                    type='text'
                                    id='gender'
                                    value={this.state.gender}
                                    class='detail_text detail_addres'
                                    disabled={this.state.editview}
                                    onChange={this.genderchange}
                                  >
                                    <option value='Male'>Male</option>
                                    <option value='Female'>Female</option>
                                  </select>
                                </div>

                                <div class='form-group form-group_01 col-md-12'>
                                  <label class='input' for='inputEmail4'>
                                    Designation
                                  </label>
                                  <input
                                    type='text'
                                    id='designate'
                                    class='detail_text detail_addres'
                                    disabled={this.state.editview}
                                    onChange={(e) =>
                                      this.setState({
                                        designation: e.target.value,
                                      })
                                    }
                                  ></input>
                                </div>

                                <div class='form-group form-group_01 col-md-12'>
                                  <label class='input' for='inputEmail4'>
                                    Team Name
                                  </label>
                                  <input
                                    type='text'
                                    id='team'
                                    class='detail_text detail_addres'
                                    disabled={this.state.editview}
                                    onChange={(e) =>
                                      this.setState({
                                        team_name: e.target.value,
                                      })
                                    }
                                  ></input>
                                </div>
                                <div class='form-group form-group_01 col-md-12'>
                                  <label class='input' for='inputEmail4'>
                                    Address
                                  </label>
                                  <input
                                    type='text'
                                    id='Address'
                                    class='detail_text detail_addres '
                                    disabled={this.state.editview}
                                    onChange={(e) => this.setState({ address: e.target.value })}
                                  ></input>
                                </div>

                                <div class='form-group form-group_01 col-md-12'>
                                  <label class='input' for='inputEmail4'>
                                    Address2
                                  </label>
                                  <input
                                    type='text'
                                    id='Address2'
                                    class='detail_text detail_addres'
                                    disabled={this.state.editview}
                                    onChange={(e) =>
                                      this.setState({
                                        address2: e.target.value,
                                      })
                                    }
                                  ></input>
                                </div>

                                <div class='form-group form-group_01 col-md-12'>
                                  <label class='input' for='inputEmail4'>
                                    City
                                  </label>
                                  <input
                                    type='text'
                                    id='City'
                                    class='detail_text detail_addres'
                                    disabled={this.state.editview}
                                    onChange={(e) => this.setState({ city: e.target.value })}
                                  ></input>
                                </div>

                                <div class='form-group form-group_01 col-md-12'>
                                  <label class='input' for='inputEmail4'>
                                    State
                                  </label>
                                  <input
                                    type='text'
                                    id='state'
                                    class='detail_text detail_addres'
                                    disabled={this.state.editview}
                                    onChange={(e) => this.setState({ state: e.target.value })}
                                  ></input>
                                </div>

                                <div class='form-group form-group_01 col-md-12'>
                                  <label class='input' for='inputEmail4'>
                                    Zipcode
                                  </label>
                                  <input
                                    type='text'
                                    id='zipcode'
                                    class='detail_text detail_addres'
                                    disabled={this.state.editview}
                                    onChange={(e) => this.setState({ zipcode: e.target.value })}
                                  ></input>
                                </div>

                                <div class='form-group form-group_01  col-md-12'>
                                  <label class='input' for='inputEmail4'>
                                    Country
                                  </label>
                                  <input
                                    type='text'
                                    id='country'
                                    class='detail_text detail_addres'
                                    value={this.state.country}
                                    disabled={this.state.editview}
                                    onChange={(e) => this.setState({ country: e.target.value })}
                                  ></input>
                                </div>

                                <div class='form-group form-group_01 col-md-12'>
                                  <label class='input' for='inputEmail4'>
                                    Cellphone
                                  </label>
                                  <input
                                    type='text'
                                    id='cellphone'
                                    class='detail_text detail_addres'
                                    disabled={this.state.editview}
                                    onChange={(e) =>
                                      this.setState({
                                        cellphone: e.target.value,
                                      })
                                    }
                                  ></input>
                                </div>

                                <div class='form-row form-row_01'>
                                  <div class='form-group form-group_01 col-md-12'>
                                    <label class='input' for='inputEmail4'>
                                      Track Age
                                    </label>
                                    <input
                                      style={{ 'font-weight': 'bold' }}
                                      type='text'
                                      id='track_age'
                                      class='detail_text detail_addres'
                                      disabled={this.state.editview}
                                      value={this.state.track_age ? this.state.track_age : 'n/a'}
                                      onChange={(e) =>
                                        this.setState({
                                          track_age: e.target.value,
                                        })
                                      }
                                    ></input>
                                  </div>

                                  <div class='form-group form-group_01 col-md-12'>
                                    <label class='input' for='inputEmail4'>
                                      Field Age
                                    </label>
                                    <input
                                      style={{ 'font-weight': 'bold' }}
                                      type='text'
                                      id='field_age'
                                      class='detail_text detail_addres'
                                      disabled={this.state.editview}
                                      value={this.state.field_age ? this.state.field_age : 'n/a'}
                                      onChange={(e) =>
                                        this.setState({
                                          field_age: e.target.value,
                                        })
                                      }
                                    ></input>
                                  </div>

                                  <div class='form-group form-group_01 col-md-12'>
                                    <label class='input' for='inputEmail4'>
                                      Archery Age
                                    </label>
                                    <input
                                      style={{ 'font-weight': 'bold' }}
                                      type='text'
                                      id='archery_age'
                                      class='detail_text detail_addres'
                                      disabled={this.state.editview}
                                      value={this.state.archery_age ? this.state.archery_age : 'n/a'}
                                      onChange={(e) =>
                                        this.setState({
                                          archery_age: e.target.value,
                                        })
                                      }
                                    ></input>
                                  </div>

                                  <div class='form-group form-group_01 col-md-12'>
                                    <label class='input' for='inputEmail4'>
                                      Swim age
                                    </label>
                                    <input
                                      style={{ 'font-weight': 'bold' }}
                                      type='text'
                                      id='swim_age'
                                      class='detail_text detail_addres'
                                      disabled={this.state.editview}
                                      value={this.state.swim_age ? this.state.swim_age : 'n/a'}
                                      onChange={(e) =>
                                        this.setState({
                                          swim_age: e.target.value,
                                        })
                                      }
                                    ></input>
                                  </div>

                                  <div class='form-group form-group_01 col-md-12'>
                                    <label class='input' for='inputEmail4'>
                                      Table Tennis Age
                                    </label>
                                    <input
                                      style={{ 'font-weight': 'bold' }}
                                      type='text'
                                      id='tabletennis_age'
                                      class='detail_text detail_addres'
                                      disabled={this.state.editview}
                                      value={this.state.tabletennis_age ? this.state.tabletennis_age : 'n/a'}
                                      onChange={(e) =>
                                        this.setState({
                                          tabletennis_age: e.target.value,
                                        })
                                      }
                                    ></input>
                                  </div>

                                  <div class='form-group form-group_01 col-md-12'>
                                    <label class='input' for='inputEmail4'>
                                      Adaptive Tennis Age
                                    </label>
                                    <input
                                      style={{ 'font-weight': 'bold' }}
                                      type='text'
                                      id='adaptivetennis_age'
                                      class='detail_text detail_addres'
                                      disabled={this.state.editview}
                                      value={!this.state.adaptivetennis_age ? 'n/a' : this.state.adaptivetennis_age}
                                      onChange={(e) =>
                                        this.setState({
                                          adaptivetennis_age: e.target.value,
                                        })
                                      }
                                    ></input>
                                  </div>

                                  <div class='form-group form-group_01 col-md-12'>
                                    <label class='input' for='inputEmail4'>
                                      Para Powerlifting Age
                                    </label>
                                    <input
                                      style={{ 'font-weight': 'bold' }}
                                      type='text'
                                      id='weightlifting_age'
                                      class='detail_text detail_addres'
                                      disabled={this.state.editview}
                                      value={this.state.weightlifting_age ? this.state.weightlifting_age : 'n/a'}
                                      onChange={(e) =>
                                        this.setState({
                                          weightlifting_age: e.target.value,
                                        })
                                      }
                                    ></input>
                                  </div>

                                  <div class='form-group form-group_01 col-md-12'>
                                    <label class='input' for='inputEmail4'>
                                      Team Sports Age
                                    </label>
                                    <input
                                      style={{ 'font-weight': 'bold' }}
                                      type='text'
                                      id='team_age'
                                      class='detail_text detail_addres'
                                      disabled={this.state.editview}
                                      value={this.state.team_age ? this.state.team_age : 'n/a'}
                                      onChange={(e) =>
                                        this.setState({
                                          team_age: e.target.value,
                                        })
                                      }
                                    ></input>
                                  </div>
                                  <div class='form-group form-group_01 col-md-12'>
                                    <label class='input' for='inputEmail4'>
                                      Air Rifle Age
                                    </label>
                                    <input
                                      style={{ 'font-weight': 'bold' }}
                                      type='text'
                                      id='airrifle_age'
                                      class='detail_text detail_addres'
                                      disabled={this.state.editview}
                                      value={this.state.airrifle_age != null ? this.state.airrifle_age : 'n/a'}
                                      onChange={(e) =>
                                        this.setState({
                                          airrifle_age: e.target.value,
                                        })
                                      }
                                    ></input>
                                  </div>

                                  <div class='form-group form-group_01 col-md-12'>
                                    <label class='input' for='inputEmail4'>
                                      Paratriathlon 100 Age
                                    </label>
                                    <input
                                      style={{ 'font-weight': 'bold' }}
                                      type='text'
                                      id='paratriathlon100_age'
                                      class='detail_text detail_addres'
                                      disabled={this.state.editview}
                                      value={
                                        this.state.paratriathlon100_age != null
                                          ? this.state.paratriathlon100_age
                                          : 'n/a'
                                      }
                                      onChange={(e) =>
                                        this.setState({
                                          paratriathlon100_age: e.target.value,
                                        })
                                      }
                                    ></input>
                                  </div>

                                  <div class='form-group form-group_01 col-md-12'>
                                    <label class='input' for='inputEmail4'>
                                      Paratriathlon 200 Age
                                    </label>
                                    <input
                                      style={{ 'font-weight': 'bold' }}
                                      type='text'
                                      id='paratriathlon200_age'
                                      class='detail_text detail_addres'
                                      disabled={this.state.editview}
                                      value={
                                        this.state.paratriathlon200_age != null
                                          ? this.state.paratriathlon200_age
                                          : 'n/a'
                                      }
                                      onChange={(e) =>
                                        this.setState({
                                          paratriathlon200_age: e.target.value,
                                        })
                                      }
                                    ></input>
                                  </div>
                                  <div class='form-group form-group_01 col-md-12'>
                                    <label class='input' for='inputEmail4'>
                                      Paratriathlon 500 Age
                                    </label>
                                    <input
                                      style={{ 'font-weight': 'bold' }}
                                      type='text'
                                      id='paratriathlon500_age'
                                      class='detail_text detail_addres'
                                      disabled={this.state.editview}
                                      value={
                                        this.state.paratriathlon500_age != null
                                          ? this.state.paratriathlon500_age
                                          : 'n/a'
                                      }
                                      onChange={(e) =>
                                        this.setState({
                                          paratriathlon500_age: e.target.value,
                                        })
                                      }
                                    ></input>
                                  </div>

                                  <div class='form-group form-group_01 col-md-12'>
                                    <label class='input' for='inputEmail4'>
                                      Boccia Age
                                    </label>
                                    <input
                                      style={{ 'font-weight': 'bold' }}
                                      type='text'
                                      id='boccia_age'
                                      class='detail_text detail_addres'
                                      disabled={this.state.editview}
                                      value={this.state.boccia_age != null ? this.state.boccia_age : 'n/a'}
                                      onChange={(e) =>
                                        this.setState({
                                          boccia_age: e.target.value,
                                        })
                                      }
                                    ></input>
                                  </div>

                                  <div class='form-group form-group_01 col-md-12'>
                                    <label class='input' for='inputEmail4'>
                                      Cycling Age
                                    </label>
                                    <input
                                      style={{ 'font-weight': 'bold' }}
                                      type='text'
                                      id='cycling_age'
                                      class='detail_text detail_addres'
                                      disabled={this.state.editview}
                                      value={this.state.cycling_age != null ? this.state.cycling_age : 'n/a'}
                                      onChange={(e) =>
                                        this.setState({
                                          cycling_age: e.target.value,
                                        })
                                      }
                                    ></input>
                                  </div>
                                </div>
                                <div class='form-group form-group_01 col-md-12'>
                                  <label class='input' for='inputEmail4'>
                                    Headcoach's Name
                                  </label>
                                  <input
                                    type='text'
                                    id='headcoach_name'
                                    class='detail_text detail_addres'
                                    disabled={this.state.editview}
                                    value={this.state.headcoach_name ? this.state.headcoach_name : 'n/a'}
                                    onChange={(e) =>
                                      this.setState({
                                        headcoach_name: e.target.value,
                                      })
                                    }
                                  ></input>
                                </div>

                                <div class='form-group form-group_01 col-md-12'>
                                  <label class='input' for='inputEmail4'>
                                    Coach's Cellphone
                                  </label>
                                  <input
                                    type='text'
                                    id='coach_homephone'
                                    class='detail_text detail_addres'
                                    disabled={this.state.editview}
                                    value={this.state.coach_homephone ? this.state.coach_homephone : 'n/a'}
                                    onChange={(e) =>
                                      this.setState({
                                        coach_homephone: e.target.value,
                                      })
                                    }
                                  ></input>
                                </div>
                                <div class='detailbtn'>
                                  <button
                                    type='button'
                                    rel='tooltip'
                                    class='btn btn-warning btn-round'
                                    value='Edit'
                                    onClick={this.edit}
                                  >
                                    Edit
                                  </button>
                                  {` `}
                                  <button
                                    type='button'
                                    rel='tooltip'
                                    class='btn btn-success btn-round'
                                    value='Save'
                                    onClick={this.save}
                                  >
                                    Save
                                  </button>
                                  {` `}
                                  <button
                                    type='button'
                                    rel='tooltip'
                                    class='btn btn-danger btn-round'
                                    value='Save'
                                    onClick={this.managevenue}
                                  >
                                    Venues
                                  </button>
                                  {` `}
                                </div>
                                <div style={{ width: '0%' }}>
                                  <button type='button' class='btn btn-danger btn-round' onClick={this.cleardata}>
                                    Clear
                                  </button>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {/* table --------------------------------------------------- */}
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </>
        )}
      </>
    );

    // All approal ---------------------------------------------end--------------------------------------------------
  }
}

export default Detailpage;
