import React from 'react';

import '../App.css';
import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';

import { Empty } from 'antd';
import 'datatables.net';
import 'datatables.net-bs4';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
class Archerytargetresult extends React.Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    this.state = {
      event: queryParams.get('event'),
      event_data: [{}],
      loader: true,
      prelimresultdata: '',
      prelimplacedata: '',
      finalresultdata: '',
      finalplacedata: '',
      typebowdata: '',
      bowstanddata: '',
      performancedatas: [{}],
    };
    this.back = this.back.bind(this);
    this.edit = this.edit.bind(this);
    this.update = this.update.bind(this);
  }
  async componentDidMount() {
    var self = this;
    const data = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    await axios
      .post(`${url.url}/api/getregisterarcheryevent/`, data, {})
      .then(function (response) {
        self.setState({ event_data: response.data, loader: false });
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    const performancerecorddata = {
      Sport_Venue: 'Archery',
      Event: this.state.event,
      Classification: this.state.event_data[0].archery_class_value,
      Age_Group: this.state.event_data[0].age_division,
      Gender: this.state.event_data[0].gender,
    };
    axios
      .post(`${url.url}/api/getperformancerecord/`, performancerecorddata, {})
      .then(function (response) {
        self.setState({ performancedatas: response.data.data[0] });
      })
      .catch(function (error) {});
  }
  back() {
    window.location.replace('/venuemainpage?event=' + sessionStorage.getItem('event'));
  }

  edit(e) {
    var self = this;
    self.setState({
      eventdatas: this.state.event_data[e],
      prelimresultdata: this.state.event_data[e].prelim_result,
      prelimplacedata: this.state.event_data[e].prelim_place,
      finalresultdata: this.state.event_data[e].final_result,
      finalplacedata: this.state.event_data[e].final_place,
      typebowdata: this.state.event_data[e].typebow,
      bowstanddata: this.state.event_data[e].bowstand,
    });
  }

  update(e) {
    const datatosend = {
      prelim_result: this.state.prelimresultdata,
      prelim_place: this.state.prelimplacedata,
      final_result: this.state.finalresultdata,
      final_place: this.state.finalplacedata,
      typebow: this.state.typebowdata,
      bowstand: this.state.bowstanddata,
    };
    axios
      .put(`${url.url}/api/updatearcherytargetresult/${this.state.eventdatas.id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  render() {
    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div>
              <div>
                <h1> </h1>
                <Row>
                  <Col>
                    <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                      Back
                    </button>
                    <Card className='scrollcard'>
                      <CardHeader>
                        <h5 className='title'>
                          {this.state.event} <br></br> Results Entry By Target
                        </h5>
                      </CardHeader>
                      <CardBody>
                        <Form>
                          {Object.keys(this.state.event_data).length == 0 ? (
                            <Empty />
                          ) : (
                            <>
                              <table class='worksheet_table'>
                                <thead>
                                  <tr class='worksheet_tr'>
                                    <th>Target</th>
                                    <th>Groups</th>
                                    <th>Bib</th>
                                    <th>Last Name</th>
                                    <th>First Name</th>
                                    <th>Country</th>
                                    <th>Class</th>
                                    <th>Age</th>
                                    <th>Gender</th>
                                    <th>Final Results</th>
                                    <th>Final Place</th>
                                    <th>Qual Std</th>
                                    <th>Qualifies</th>
                                    <th>Break Record</th>
                                    <th>Record</th>
                                    <th>EventName</th>
                                    <th>Typebow</th>
                                    <th>Bowstand</th>
                                    <th>Event Team Scoring</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.event_data.map((question, index) => (
                                    <tr class='worksheet_tr'>
                                      <td>{question.target}</td>
                                      <td>{question.groups}</td>
                                      <td>{question.bib_no}</td>
                                      <td>{question.last_name}</td>
                                      <td>{question.first_name}</td>
                                      <td>{question.country}</td>
                                      <td>{question.archery_class_value}</td>
                                      <td>{question.age_division}</td>
                                      <td>{question.gender}</td>
                                      <td>{question.final_result}</td>
                                      <td>{question.final_place}</td>
                                      <td>{}</td>
                                      <td>{question.final_result != null ? 'Yes' : 'NRF'}</td>
                                      <td>
                                        {question.final_result <= this.state.performancedatas.result
                                          ? this.state.performancedatas.result == null
                                            ? 'NRF'
                                            : 'Yes'
                                          : 'No'}
                                      </td>
                                      <td>{this.state.performancedatas.Record}</td>
                                      <td>{question.selected_events}</td>
                                      <td>{question.typebow}</td>
                                      <td>{question.bowstand}</td>
                                      <td>{'Yes'}</td>
                                      <td>
                                        <button
                                          type='button'
                                          rel='tooltip'
                                          class='btn btn-success btn-sm btn-icon'
                                          onClick={() => this.edit(index)}
                                          data-toggle='modal'
                                          data-target='#useredit'
                                        >
                                          <i class='now-ui-icons ui-2_settings-90'></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </>
                          )}
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
              <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Results Entry By Target
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-row'>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Final Result</label>
                            <input
                              class='form-control'
                              value={this.state.finalresultdata}
                              onChange={(e) => this.setState({ finalresultdata: e.target.value })}
                              name='relayletter'
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Final Place</label>
                            <input
                              class='form-control'
                              value={this.state.finalplacedata}
                              onChange={(e) => this.setState({ finalplacedata: e.target.value })}
                              name='relayletter'
                            />
                          </div>
                        </div>
                        <div class='form-row'>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Type Bow</label>
                            <input
                              class='form-control'
                              value={this.state.typebowdata}
                              onChange={(e) => this.setState({ typebowdata: e.target.value })}
                              name='relayletter'
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Bow Stand</label>
                            <input
                              class='form-control'
                              value={this.state.bowstanddata}
                              onChange={(e) => this.setState({ bowstanddata: e.target.value })}
                              name='relayletter'
                            />
                          </div>
                        </div>

                        <center>
                          <button type='button' class='btn btn-danger' onClick={(e) => this.update()}>
                            Update
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Archerytargetresult;
