import React from 'react';

import '../App.css';
import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import { Empty } from 'antd';
import 'datatables.net';
import 'datatables.net-bs4';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
class Weightliftbenchclass extends React.Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    this.state = {
      event: queryParams.get('event'),
      event_data: [{}],
      loader: true,
      sortdata: '',
      groupdata: '',
      lift1: '',
      lift2: '',
      lift3: '',
      recordlift: '',
      draw: '',
    };
    this.back = this.back.bind(this);
    this.edit = this.edit.bind(this);
    this.update = this.update.bind(this);
  }
  async componentDidMount() {
    var self = this;
    const data = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    await axios
      .post(`${url.url}/api/getregisterweightliftevent/`, data, {})
      .then(function (response) {
        self.setState({ event_data: response.data, loader: false });
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    // document.getElementById("venueesss").style.display = "block";
  }
  back() {
    window.location.replace('/venuemainpage?event=' + sessionStorage.getItem('event'));
  }

  edit(e) {
    // console.log(e);

    var self = this;
    self.setState({
      eventdatas: this.state.event_data[e],
      sortdata: this.state.event_data[e].sort,
      lift1: this.state.event_data[e].lift1,
      lift2: this.state.event_data[e].lift2,
      lift3: this.state.event_data[e].lift3,
      draw: this.state.event_data[e].draw,
      groupdata: this.state.event_data[e].group,
    });
  }

  update(e) {
    const datatosend = {
      sort: this.state.sortdata,
      lift1: this.state.lift1,
      lift2: this.state.lift2,
      lift3: this.state.lift3,
      draw: this.state.draw,
      group: this.state.groupdata,
    };
    axios
      .put(`${url.url}/api/updatebenchresult/${this.state.eventdatas.id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            title: 'Success',
            text: 'Status updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  render() {
    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader
                type='ThreeDots'
                color='#00BFFF'
                height={70}
                width={70}
                // timeout={3000} //3 secs
              />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div>
              <div>
                <h1> </h1>
                <Row>
                  <Col>
                    <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                      Back
                    </button>
                    <Card>
                      <CardHeader>
                        <h5 className='title'>
                          {this.state.event} <br></br> Results Entry By Class
                        </h5>
                      </CardHeader>
                      <CardBody>
                        <Form>
                          {Object.keys(this.state.event_data).length == 0 ? (
                            <Empty />
                          ) : (
                            <>
                              <Table class='worksheet_table'>
                                <thead>
                                  <tr class='worksheet_tr'>
                                    <th>sort</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Country</th>
                                    <th>Age</th>
                                    <th>Set Weight LBS</th>
                                    <th>Class</th>
                                    <th>Bar Height</th>
                                    <th>Lift1</th>
                                    <th>Lift2</th>
                                    <th>Lift3</th>
                                    <th>Records lift</th>
                                    <th>Results kg </th>
                                    <th>current Record</th>
                                    <th>Break Record</th>
                                    <th>Place</th>
                                    <th>Gender</th>
                                    <th>Bench</th>
                                    <th>Groups</th>
                                    <th>Draw</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.event_data.map((question, index) => (
                                    <tr class='worksheet_tr'>
                                      <td>{}</td>
                                      <td>{question.first_name}</td>
                                      <td>{question.last_name}</td>
                                      <td>{question.country}</td>
                                      <td>{question.age_division}</td>
                                      <td>{}</td>
                                      <td>{question.weight_class_value}</td>
                                      <td>{}</td>
                                      <td>{question.lift1}</td>
                                      <td>{question.lift2}</td>
                                      <td>{question.lift3}</td>
                                      <td>{}</td>
                                      <td>{}</td>
                                      <td>{}</td>
                                      <td>{}</td>
                                      <td>{}</td>
                                      <td>{question.gender}</td>
                                      <td>{}</td>
                                      <td>{question.group}</td>
                                      <td>{question.draw}</td>
                                      <td>
                                        <button
                                          type='button'
                                          rel='tooltip'
                                          class='btn btn-success btn-sm btn-icon'
                                          onClick={() => this.edit(index)}
                                          data-toggle='modal'
                                          data-target='#useredit'
                                        >
                                          <i class='now-ui-icons ui-2_settings-90'></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </>
                          )}
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
              <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Results Entry By Class
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-row'>
                          <div class='form-group col-md-4'>
                            <label for='inputCity'>Sort</label>
                            <input
                              class='form-control'
                              value={this.state.sortdata}
                              onChange={(e) => this.setState({ sortdata: e.target.value })}
                              name='relayletter'
                            />
                          </div>
                          <div class='form-group col-md-4'>
                            <label for='inputCity'>Lift1</label>
                            <input
                              class='form-control'
                              value={this.state.lift1}
                              onChange={(e) => this.setState({ lift1: e.target.value })}
                              name='relayletter'
                            />
                          </div>
                          <div class='form-group col-md-4'>
                            <label for='inputCity'>Lift2</label>
                            <input
                              class='form-control'
                              value={this.state.lift2}
                              onChange={(e) => this.setState({ lift2: e.target.value })}
                              name='relayletter'
                            />
                          </div>
                        </div>

                        <div class='form-row'>
                          <div class='form-group col-md-4'>
                            <label for='inputCity'>Lift3</label>
                            <input
                              class='form-control'
                              value={this.state.lift3}
                              onChange={(e) => this.setState({ lift3: e.target.value })}
                              name='relayletter'
                            />
                          </div>
                          <div class='form-group col-md-4'>
                            <label for='inputCity'>Groups</label>
                            <input
                              class='form-control'
                              value={this.state.groupdata}
                              onChange={(e) => this.setState({ groupdata: e.target.value })}
                              name='relayletter'
                            />
                          </div>
                          <div class='form-group col-md-4'>
                            <label for='inputCity'>Draw</label>
                            <input
                              class='form-control'
                              value={this.state.draw}
                              onChange={(e) => this.setState({ draw: e.target.value })}
                              name='relayletter'
                            />
                          </div>
                        </div>

                        <center>
                          <button type='button' class='btn btn-danger' onClick={(e) => this.update()}>
                            Update
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Weightliftbenchclass;
