import React from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';

import Swal from 'sweetalert2';
//import Agereload from './Agedivisionload.js'

import { Select, Input } from 'antd';
import 'antd/dist/antd.css';
import { Empty } from 'antd';
import { withRouter } from 'react-router-dom';

var x;
const url = window.globalConfig;
class Regiarchery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      venues: [{}],
      venueid: [],
      tname: [],
      archeryeventdata: [],
      archerysdata: [],
      archeryevents: [],
      arcdatas: [{}],
      qualifying_events: '',
      qualifying_standard: '',
      target: '',
      archeryeventsdata: '',
      // archeryeventdata: ''
    };
    this.check = this.check.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.edit = this.edit.bind(this);
    this.tinput = this.tinput.bind(this);
    // this.save = this.save.bind(this);
    this.createarcreg = this.createarcreg.bind(this);
    this.updatearcreg = this.updatearcreg.bind(this);
  }
  async componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    let itemindex = sessionStorage.getItem('itemindex');
    var self = this;

    let indx = sessionStorage.getItem('archeryindex');
    let ids = sessionStorage.getItem('ids');
    const archeryeventdata = (index) => {
      if (index.archery_events != 'null') {
        var joined = this.state.archeryeventdata.concat(index);
        this.setState({
          archeryeventdata: joined,
        });
      }
    };
    //Get Particular user register meet data
    await axios.get(`${url.url}/api/getcreateregisters/${ids}`, {}).then(function (response) {
      self.setState({
        allregdatas: response.data,
      });
      {
        response.data.map((index) => archeryeventdata(index));
      }
      var valdata = self.state.archeryeventdata[parseInt(indx)].archery_events.split(',');

      self.setState({
        archerysdata: self.state.archeryeventdata[parseInt(indx)],
        archeryevents: valdata,
      });
    });

    let regid = sessionStorage.getItem('regid');
    axios
      .get(`${url.url}/api/getaregisterid/${regid}`, {})
      .then(function (response) {
        self.setState({
          arcdatas: response.data,
        });
      })
      .catch(function (error) {});

    //Hide and show manager
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('resultp').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    document.getElementById('venueesss').style.display = 'block';
  }
  check = (e) => {
    var temp = [];
    if (Object.keys(this.state.tname).length != 0) {
      temp = this.state.tname;
    }
    if (!this.state.tname.some((item) => e.target.value === item.name)) {
      temp.push({ name: e.target.value, id: e.target.id });
    }
    this.setState({ tname: temp });
  };
  handlechange() {
    window.location.replace('/archeryregister');
  }

  tinput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  edit(e) {
    var self = this;
    self.setState({
      archeryeventsdata: self.state.archeryevents[e],
    });
    var loop = true;

    self.state.arcdatas.forEach((question, index) => {
      if (question.selected_events == self.state.archeryevents[e]) {
        self.setState({
          selected_id: question.id,
          selected_events: question.selected_events,
          qualifying_events: question.qualifying_events,
          qualifying_standard: question.qualifying_standard,
          target: question.target,

          btncheck: false,
        });
        loop = false;
      } else if (loop) {
        self.setState({
          selected_id: '',
          selectedevents: self.state.archeryevents[e],
          qualifying_events: '',
          qualifying_standard: '',
          target: '',
          btncheck: true,
        });
        loop = true;
      }
    });

    if (self.state.arcdatas.length == 0) {
      self.setState({
        selected_id: '',
        selectedevents: self.state.archeryevents[e],
        qualifying_events: '',
        qualifying_standard: '',
        target: '',
        btncheck: true,
      });
    }
  }

  createarcreg(e) {
    var self = this;
    const setvalue = (q, index) => {
      if (self.state.arcdatas.length > index) {
        self.setState({
          qualifying_events: self.state.arcdatas[index].qualifying_events,
          qualifying_standard: self.state.arcdatas[index].qualifying_standard,
          target: self.state.arcdatas[index].target,
        });
      }
    };

    self.state.arcdatas.map((question, index) => {
      setvalue(question, index);
    });
    const datatosend = {
      first_name: this.state.archerysdata.firstname,
      last_name: this.state.archerysdata.lastname,
      age_division: this.state.archerysdata.archery_age,
      gender: this.state.archerysdata.gender,
      selected_events: this.state.selectedevents,
      qualifying_events: this.state.qualifying_events,
      qualifying_standard: this.state.qualifying_standard,
      target: this.state.target,
      archery_class_value: this.state.archerysdata.archery_class_value,
      created_by: 1,
      register_id: parseInt(sessionStorage.getItem('regid')),
    };
    axios
      .post(`${url.url}/api/createarcheryreg/`, datatosend, {})
      .then(function (response) {
        if (response.status == 201) {
          Swal.fire({
            title: 'Success',
            text: 'Status updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  updatearcreg(e) {
    var self = this;

    const datatosend = {
      first_name: this.state.archerysdata.firstname,
      last_name: this.state.archerysdata.lastname,
      age_division: this.state.archerysdata.archery_age,
      gender: this.state.archerysdata.gender,
      selected_events: this.state.selected_events,
      qualifying_events: this.state.qualifying_events,
      qualifying_standard: this.state.qualifying_standard,
      target: this.state.target,
      archery_class_value: this.state.archerysdata.archery_class_value,
      created_by: 1,
    };
    axios
      .put(`${url.url}/api/updatearcheryreg/${this.state.selected_id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            title: 'Success',
            text: 'Status updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  render() {
    const { selectedItems } = this.state;

    return (
      <>
        <div className='content'>
          <Row>
            <Col md='8'>
              <button
                type='button'
                data-toggle='modal'
                data-target='#selecttrack'
                class='btn btn-danger btn-round'
                onClick={this.handlechange}
              >
                Back
              </button>
              <Card>
                <CardHeader>
                  <h5 className='title'>Archery Events</h5>
                </CardHeader>

                <CardBody>
                  <Form>
                    <div class='form-group row'>
                      <div class='col-xs-5'>
                        <CardHeader id='deatils_heading'>
                          <p>
                            First Name:<span>{this.state.archerysdata.firstname}</span>
                          </p>
                          <p>
                            Last Name:<span>{this.state.archerysdata.lastname}</span>
                          </p>
                          <p>
                            Track Class:<span>{this.state.archerysdata.archery_class_value}</span>
                          </p>
                          <p>
                            Age Division:<span>{this.state.archerysdata.archery_age}</span>
                          </p>
                          <p>
                            Gender:<span>{this.state.archerysdata.gender}</span>
                          </p>
                        </CardHeader>
                      </div>
                    </div>
                    {Object.keys(this.state.archerysdata).length == 0 ? (
                      <Empty />
                    ) : (
                      <>
                        <Table id='my' responsive>
                          <thead>
                            <tr>
                              <th>Athletes Events</th>
                              <th>Qualifying Marks</th>
                              <th>Qualifying Standard</th>
                              <th>Target</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody id='myTable'>
                            {this.state.archeryevents.map((question, index) => (
                              <tr>
                                <td>{question}</td>
                                <td>
                                  {this.state.arcdatas.map((question1, index) =>
                                    question === question1.selected_events ? question1.qualifying_events : ''
                                  )}
                                </td>

                                <td>
                                  {this.state.arcdatas.map((question1, index) =>
                                    question === question1.selected_events ? question1.qualifying_standard : ''
                                  )}
                                </td>

                                <td>
                                  {this.state.arcdatas.map((question1, index) =>
                                    question === question1.selected_events ? question1.target : ''
                                  )}
                                </td>
                                <td>
                                  <button
                                    type='button'
                                    rel='tooltip'
                                    class='btn btn-success btn-sm btn-icon'
                                    onClick={() => this.edit(index)}
                                    data-toggle='modal'
                                    data-target='#useredit'
                                  >
                                    <i class='now-ui-icons ui-2_settings-90'></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
            {/* User table with edit delete------------------------------------------------------------------------------ */}
          </Row>
          <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
            <div class='modal-dialog'>
              <div class='modal-content'>
                <div class='modal-header '>
                  <h5 class='modal-title ' id='loginmodalLabel'>
                    {this.state.archeryeventsdata + ' ' + 'Archery'}{' '}
                  </h5>
                  <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <div class='modal-body'>
                  <form>
                    <div class='form-group'>
                      <label for='exampleFormControlSelect1'>Qualifying Marks</label>
                      <input
                        class='form-control'
                        value={this.state.qualifying_events}
                        onChange={(e) => this.setState({ qualifying_events: e.target.value })}
                        name='quatime'
                      />
                    </div>

                    <div class='form-row'>
                      <div class='form-group'>
                        <label for='inputEmail4'>Qualifying Standard</label>
                        <input
                          class='form-control'
                          value={this.state.qualifying_standard}
                          onChange={(e) => this.setState({ qualifying_standard: e.target.value })}
                          name='quastd'
                        />
                      </div>

                      <div class='form-group'>
                        <label for='inputPassword4'>Target</label>
                        <input
                          class='form-control'
                          value={this.state.target}
                          onChange={(e) => this.setState({ target: e.target.value })}
                          name='points'
                        />
                      </div>
                    </div>

                    {this.state.btncheck == true ? (
                      <center>
                        <button
                          type='button'
                          onClick={(e) => this.createarcreg()}
                          data-dismiss='modal'
                          class='btn btn-danger'
                        >
                          Create
                        </button>
                      </center>
                    ) : (
                      <>
                        <center>
                          <button type='button' class='btn btn-danger' onClick={(e) => this.updatearcreg()}>
                            Update
                          </button>
                        </center>
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Regiarchery;
