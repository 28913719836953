import React from 'react';

// reactstrap components
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $, { data } from 'jquery';
// core components

import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';

import { Empty } from 'antd';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
var c;
var d;
var f;
var g;
var x;
var qualclassname;
var question;
var typepoolarr = ['Yards', 'Meters LC', 'Meters SC'];
class Performancerecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qdatas: [{}],
      qualdatas: [{}],
      eventsdropdown: [{}],
      classdropdown: [{}],
      venueclassid: [{}],
      agediv: [{}],
      venue: '',
      gender: '',
      qb: '',
      implement: '',
      qclass: '',
      qstd: '',
      adivision: '',
      event: '',
      vclass: '',
      clsvalue: '',
      agechange: '',
      classvalue: [{}],
      venueid: '',
      vclassid: '',
      defaultgender: 'Both',
      defaulttypepool: '',
      defaultvenuename: '',
      defaulteventname: '',
      geteventids: '',
      defqb: '',
      defqstd: '',
      defimpl: '',
      defqcls: '',
      defagebreak: '',
      defagediv: '',
      defid: '',
      classindex: '',
      defaultvenueclass: '',
      defaultclassvalue: '',
      classvaluedata: [],
      trackview: false,
      performancedata: [],
      showallstd: false,
      agedata: [],
      loader: true,
      reset: false,
      showtable: false,
      recordholder: '',
      performance: '',
      year: '',
      wind: '',
      gender: '',
      classification: '',
      age: '',
      meet: '',
      country: '',
      typepool: '',
      editperformancedata: {},
      editperformindex: '',
      editperformindex1: '',
      editperformancedata1: {},
      athper: [],
      weightNationalsId: 0,
      weightClass: '',
      ageGroup: '',
      paraGender: '',
      bestLiftRecordHolder: '',
      yearBLR: '',
      bestLiftRecord: '',
      totalLiftRecordHolder: '',
      yearTLR: '',
      totalLiftRecord: '',
      record: '',
    };

    this.optionchange = this.optionchange.bind(this);
    this.optionchange1 = this.optionchange1.bind(this);
    this.optionchange2 = this.optionchange2.bind(this);
    this.optionchange3 = this.optionchange3.bind(this);
    this.optionchange4 = this.optionchange4.bind(this);
    this.edit1 = this.edit1.bind(this);
    this.edit2 = this.edit2.bind(this);
    this.edit3 = this.edit3.bind(this);
    this.edit4 = this.edit4.bind(this);

    this.update = this.update.bind(this);
    this.update2 = this.update2.bind(this);
    this.update3 = this.update3.bind(this);
    this.update4 = this.update4.bind(this);
    this.venueclasschange = this.venueclasschange.bind(this);
    this.findstandard = this.findstandard.bind(this);
    this.classdata = this.classdata.bind(this);
    this.classagedata = this.classagedata.bind(this);
  }
  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    var self = this;
    var count = 0;
    axios.get(`${url.url}/api/getallqstd`, {}).then(function (response) {
      self.setState({
        qdatas: response.data,
      });
      count++;
      if (count == 2) {
        self.setState({ loader: false });
      }
    });
    axios.get(`${url.url}/api/venues`, {}).then(function (response) {
      self.setState({
        qualdatas: response.data.data,
      });
      count++;
      if (count == 2) {
        self.setState({ loader: false });
      }
    });
    axios.get(`${url.url}/api/agedivisions`, {}).then(function (response) {
      self.setState({
        agediv: response.data.data,
      });
    });
  }
  clear = () => {
    this.setState({
      qdatas: [{}],
      eventsdropdown: [{}],
      classdropdown: [{}],
      venueclassid: [{}],
      agediv: [{}],
      venue: '',
      gender: '',
      qb: '',
      implement: '',
      qclass: '',
      qstd: '',
      adivision: '',
      event: '',
      vclass: '',
      clsvalue: '',
      agechange: '',
      classvalue: [{}],
      venueid: '',
      vclassid: '',
      defaultgender: '',
      defaultvenuename: '',
      defaulteventname: '',
      geteventids: '',
      defqb: '',
      defqstd: '',
      defimpl: '',
      defqcls: '',
      defagebreak: '',
      defagediv: '',
      defid: '',
      classindex: '',
      defaultvenueclass: '',
      defaultclassvalue: '',
      classvaluedata: [],
      trackview: false,
      performancedata: {},
      showallstd: false,
      agedata: [],
      reset: false,
      showtable: false,
    });
  };
  optionchange(e) {
    var i = this.state.qualdatas[parseInt(e.split('-')[1])];
    var j = this.state.qualdatas[parseInt(e.split('-')[1])];
    var k = e.split('-')[0];
    let athper = this.state.athper;
    athper[0] = 'v';
    athper.length = 1;
    var self = this;
    this.setState({
      athper,
      defaultvenuename: e,
      eventsdropdown: i,
      classdropdown: j,
      venueid: i.id,
      venueclassid: [{}],
      venue: '',
      gender: '',
      qb: '',
      implement: '',
      qclass: '',
      qstd: '',
      adivision: '',
      event: '',
      vclass: '',
      clsvalue: '',
      agechange: '',
      classvalue: [{}],
      vclassid: '',
      defaultgender: 'Both',
      defaulttypepool: '',
      defaulteventname: '',
      geteventids: '',
      defqb: '',
      defqstd: '',
      defimpl: '',
      defqcls: '',
      defagebreak: '',
      defagediv: '',
      defid: '',
      classindex: '',
      defaultvenueclass: '',
      defaultclassvalue: '',
      classvaluedata: [],
      trackview: false,
      showallstd: false,
      agedata: [],
      showtable: false,
    });

    var c = e.split('-')[0].toString().split('');
    var g = c[0] + c[1];

    var x = e.split('-');
    var m = x[2];
    this.setState({
      evntd: g,
    });
  }

  classdata(geteventids) {
    const dummy = (data) => {
      this.setState({
        classvaluedata: data,
      });
    };

    axios.get(`${url.url}/api/getalleventid/${geteventids}`, {}).then(function (response) {
      dummy(response.data);
    });
  }

  optionchange1(e) {
    var self = this;
    var d = e.split('-')[0].split(' ').join('');
    var geteventids = e.split('-')[1];
    let athper = [...this.state.athper];
    athper[1] = 'e';
    athper.length = 2;
    self.setState({
      athper,
      evnt: d,
      geteventids: geteventids,

      defaulteventname: e,
      showtable: false,
      performancedata: [{}],
    });
    this.classdata(geteventids);
  }

  optionchanges(e) {
    var self = this;
    self.setState({
      defaulteventnames: e,
    });
  }

  optionchange3(e) {
    let athper = [...this.state.athper];
    athper[3] = 'a';
    athper.length = 4;
    var self = this;
    self.setState({
      athper,
      defagediv: e,
      performancedata: [{}],
      showtable: false,
    });
  }

  optionchange4(e) {
    var self = this;
    self.setState({
      defaultgender: e.target.value,
      [e.target.name]: e.target.value,
      showtable: false,
    });
  }

  optionchange5 = (e) => {
    var self = this;
    self.setState({
      defaulttypepool: e.target.value,
      [e.target.name]: e.target.value,
      showtable: false,
    });
  };

  venueclasschange(e) {
    var i = e.split('-');
    var l = parseInt(i[0]);
    var k = parseInt(i[1]);
    var self = this;
    self.setState({
      defaultvenueclass: e,
    });

    self.setState({
      clasven: this.state.classdropdown.venueclasses[k],
      venclid: this.state.classdropdown.venueclasses[k].id,
    });
    axios
      .get(`${url.url}/api/getvenueclassid/${this.state.classdropdown.venueclasses[k].id}`, {})
      .then(function (response) {
        self.setState({
          venueclassid: response.data,
        });
      })
      .catch(function (error) {
        self.setState({
          venueclassid: [{}],
        });
      });
  }
  classagedata(classindex) {
    const dummy = (data) => {
      this.setState({ agedata: data });
    };
    axios.get(`${url.url}/api/getclasseventid/${this.state.geteventids}/${classindex}`, {}).then(function (response) {
      dummy(response.data);
    });
  }
  optionchange2(e) {
    var self = this;
    let athper = [...this.state.athper];
    athper[2] = 'c';
    athper.length = 3;
    var classsplit = e.split('-');
    var classindex = classsplit[1];
    self.setState({
      athper,
      defaultclassvalue: e,
      defagediv: '',
      classindex: classindex,
      agedata: [],
      performancedata: [{}],
      showtable: false,
    });
    this.classagedata(classindex);
  }

  findstandard() {
    var self = this;
    let athper = this.state.athper;
    var qstds = athper.join('');
    var typepooldata = '';
    if (this.state.defaultvenuename.split('-')[0] == 'Swimming') {
      if (this.state.defaultvenuename.split('-')[3] == 'Yards') {
        typepooldata = 'YD';
      } else if (this.state.defaultvenuename.split('-')[3] == 'Meters LC') {
        typepooldata = 'LC';
      } else if (this.state.defaultvenuename.split('-')[3] == 'Meters SC') {
        typepooldata = 'SC';
      }
    } else {
      typepooldata = '';
    }
    const datatosend = {
      qstds,
      sport:
        this.state.defaultvenuename.split('-')[0] == 'Para Powerlifting'
          ? 'Para Powerlifting'
          : this.state.defaultvenuename.split('-')[0],
      event:
        this.state.defaulteventname.split('-')[0] == 'powerlift'
          ? 'Powerlifting'
          : this.state.defaulteventname.split('-')[0],
      class: this.state.defaultclassvalue.split('-')[0],
      AG: this.state.defagediv,
      sex: this.state.defaultgender,
      typepool: typepooldata,
    };

    axios
      .post(`${url.url}/api/gettracknjdcvalues/`, datatosend, {})
      .then(function (response) {
        self.setState({
          performancedata: response.data,
          reset: true,
          showtable: true,
        });
      })
      .catch(function (error) {
        self.setState({
          performancedata: [{}],
          reset: true,
          showtable: true,
        });
      });
  }

  //New----------------------------------------------

  edit1(e) {
    var self = this;

    self.setState({
      editperformindex: self.state.performancedata['Nationals'],
      editperformancedata: self.state.performancedata['Nationals'][e],
      recordholder: self.state.performancedata['Nationals'][e].name,
      performance: self.state.performancedata['Nationals'][e].result,
      meet: self.state.performancedata['Nationals'][e].location,
      year: self.state.performancedata['Nationals'][e].year,
      wind: self.state.performancedata['Nationals'][e].wind,
      gender: self.state.performancedata['Nationals'][e].sex,
      classification: self.state.performancedata['Nationals'][e].class,
      age: self.state.performancedata['Nationals'][e].AG,
      country: self.state.performancedata['Nationals'][e].country,
      typepool: self.state.performancedata['Nationals'][e].typepool,
    });
  }

  weightedit = (e, rec) => {
    var self = this;
    self.setState({
      record: rec,
      weightNationalsId: self.state.performancedata[rec][e].id,
      weightClass: self.state.performancedata[rec][e].class,
      ageGroup: self.state.performancedata[rec][e].AG,
      paraGender: self.state.performancedata[rec][e].sex,
      bestLiftRecordHolder: self.state.performancedata[rec][e].record_holder_brr,
      yearBLR: self.state.performancedata[rec][e].year_blr,
      bestLiftRecord: self.state.performancedata[rec][e].best_lift_record,
      totalLiftRecordHolder: self.state.performancedata[rec][e].record_holder_tlr,
      yearTLR: self.state.performancedata[rec][e].year_tlr,
      totalLiftRecord: self.state.performancedata[rec][e].total_lift_record,
    });
  };
  // ======================================================================
  //update=---------
  update(e) {
    var self = this;

    if (e == 'Track') {
      const datatosend = {
        name: this.state.recordholder,
        result: this.state.performance,
        year: this.state.year,
        location: this.state.meet,
        wind: this.state.wind,
        sex: this.state.gender,
        class: this.state.classification,
        AG: this.state.age,
        country: this.state.country,
      };
      axios
        .put(`${url.url}/api/updatetrackatfusasamsrecord/${self.state.editperformancedata.id}`, datatosend, {})

        .then(function (response) {
          if (response.status == 200) {
            Swal.fire({
              title: 'Success',
              text: 'Updated successfully',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            }).then((response) => {
              self.findstandard();
            });
          }
        })

        .catch(function (error) {
          Swal.fire({
            title: 'Failed',
            text: 'Something went wrong',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          });
        });
    }

    if (e == 'Field') {
      const datatosend = {
        name: this.state.recordholder,
        result: this.state.performance,
        year: this.state.year,
        location: this.state.meet,
        wind: this.state.wind,
        sex: this.state.gender,
        class: this.state.classification,
        AG: this.state.age,
        country: this.state.country,
      };
      axios
        .put(`${url.url}/api/updateFieldatfusasamsrecord/${self.state.editperformancedata.id}`, datatosend, {})

        .then(function (response) {
          if (response.status == 200) {
            Swal.fire({
              title: 'Success',
              text: 'Updated successfully',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            }).then((response) => {
              self.findstandard();
            });
          }
        })

        .catch(function (error) {
          Swal.fire({
            title: 'Failed',
            text: 'Something went wrong',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          });
        });
    }

    if (e == 'Swimming') {
      const datatosend = {
        name: this.state.recordholder,
        result: this.state.performance,
        year: this.state.year,
        location: this.state.meet,
        wind: this.state.wind,
        sex: this.state.gender,
        class: this.state.classification,
        AG: this.state.age,
        country: this.state.country,
        typepool: this.state.typepool,
      };
      axios
        .put(`${url.url}/api/updateSwimatfusasamsrecord/${self.state.editperformancedata.id}`, datatosend, {})

        .then(function (response) {
          if (response.status == 200) {
            Swal.fire({
              title: 'Success',
              text: 'Updated successfully',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            }).then((response) => {
              self.findstandard();
            });
          }
        })

        .catch(function (error) {
          Swal.fire({
            title: 'Failed',
            text: 'Something went wrong',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          });
        });
    }
    if (e == 'Para Powerlifting') {
      const datatosend = {
        record: this.state.record,
        class: this.state.weightClass,
        AG: this.state.ageGroup,
        sex: this.state.paraGender,
        record_holder_brr: this.state.bestLiftRecordHolder,
        year_blr: this.state.yearBLR,
        best_lift_record: this.state.bestLiftRecord,
        record_holder_tlr: this.state.totalLiftRecordHolder,
        year_tlr: this.state.yearTLR,
        total_lift_record: this.state.totalLiftRecord,
      };
      axios
        .put(`${url.url}/api/updatepowerliftatfusasams/${self.state.weightNationalsId}`, datatosend, {})

        .then(function (response) {
          if (response.status == 200) {
            Swal.fire({
              title: 'Success',
              text: 'Updated successfully',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            }).then((response) => {
              self.findstandard();
            });
          }
        })

        .catch(function (error) {
          Swal.fire({
            title: 'Failed',
            text: 'Something went wrong',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          });
        });
    }
  }
  // ==============

  edit2(e) {
    var self = this;
    self.setState({
      // record: 'National',
      editperformancedata: this.state.performancedata[e].id,
      recordholder2: this.state.performancedata[e].Record_Holder,
      performance2: this.state.performancedata[e].record2,
      meet2: this.state.performancedata[e].Meet2,
      year2: this.state.performancedata[e].Year2,
      wind2: this.state.performancedata[e].wind2,
      sex: this.state.performancedata[e].gender,
      class: this.state.performancedata[e].classification,
      AG: this.state.performancedata[e].age,
      country2: this.state.performancedata[e].country2,
    });
  }

  //update=---------
  update2() {
    var self = this;
    const datatosend = {
      Record_Holder2: this.state.recordholder2,
      record2: this.state.performance2,
      Year2: this.state.year2,
      Meet2: this.state.meet2,
      wind2: this.state.wind2,
      sex: this.state.gender,
      class: this.state.classification,
      AG: this.state.age,
      country2: this.state.country2,
    };

    axios
      .put(`${url.url}/api/updateperformancerecord/${this.state.editperformancedata}`, datatosend, {})

      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            title: 'Success',
            text: 'Updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then((response) => {
            self.findstandard();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  // ----------

  edit3(e) {
    var self = this;
    self.setState({
      editperformancedata: this.state.performancedata[e].id,
      recordholder3: this.state.performancedata[e].Record_Holder3,
      performance3: this.state.performancedata[e].record3,
      meet3: this.state.performancedata[e].Meet3,
      year3: this.state.performancedata[e].Year3,
      wind3: this.state.performancedata[e].wind3,
      gender3: this.state.performancedata[e].gender3,
      classification3: this.state.performancedata[e].classification3,
      age3: this.state.performancedata[e].age3,
      country3: this.state.performancedata[e].country3,
    });
  }

  //update=---------
  update3() {
    var self = this;
    const datatosend = {
      Record_Holder3: this.state.recordholder3,
      record3: this.state.performance3,
      Year3: this.state.year3,
      Meet3: this.state.meet3,
      wind3: this.state.wind3,
      gender3: this.state.state.gender3,
      classification3: this.state.state.classification3,
      age3: this.state.state.age3,
      country3: this.state.country3,
    };
    axios
      .put(`${url.url}/api/updateperformancerecord/${this.state.editperformancedata}`, datatosend, {})

      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            title: 'Success',
            text: 'Updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then((response) => {
            self.findstandard();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  // -------------

  edit4(e) {
    var self = this;
    self.setState({
      editperformindex1: self.state.performancedata['Njdc'],
      editperformancedata1: self.state.performancedata['Njdc'][e],
      recordholder4: self.state.performancedata['Njdc'][e].name,
      performance4: self.state.performancedata['Njdc'][e].result,
      meet4: self.state.performancedata['Njdc'][e].location,
      year4: self.state.performancedata['Njdc'][e].year,
      wind4: self.state.performancedata['Njdc'][e].wind,
      gender4: self.state.performancedata['Njdc'][e].sex,
      classification4: self.state.performancedata['Njdc'][e].class,
      age4: self.state.performancedata['Njdc'][e].AG,
      country4: self.state.performancedata['Njdc'][e].country,
      typepool4: self.state.performancedata['Njdc'][e].typepool,
    });
  }

  //update=---------
  update4(e) {
    var self = this;
    if (e == 'Track') {
      const datatosend = {
        name: this.state.recordholder4,
        result: this.state.performance4,
        year: this.state.year4,
        location: this.state.meet4,
        wind: this.state.wind4,
        sex: this.state.gender4,
        class: this.state.classification4,
        AG: this.state.age4,
        country: this.state.country4,
        typepool: this.state.typepool4,
      };
      axios
        .put(`${url.url}/api/updatetracknjdcrecord/${self.state.editperformancedata1.id}`, datatosend, {})

        .then(function (response) {
          if (response.status == 200) {
            Swal.fire({
              title: 'Success',
              text: 'Updated successfully',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            }).then((response) => {
              // var self = this;
              self.findstandard();
            });
          }
        })

        .catch(function (error) {
          Swal.fire({
            title: 'Failed',
            text: 'Something went wrong',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          });
        });
    }

    if (e == 'Field') {
      const datatosend = {
        name: this.state.recordholder4,
        result: this.state.performance4,
        year: this.state.year4,
        location: this.state.meet4,
        wind: this.state.wind4,
        sex: this.state.gender4,
        class: this.state.classification4,
        AG: this.state.age4,
        country: this.state.country4,
      };
      axios
        .put(`${url.url}/api/updateFieldnjdcrecord/${self.state.editperformancedata1.id}`, datatosend, {})

        .then(function (response) {
          if (response.status == 200) {
            Swal.fire({
              title: 'Success',
              text: 'Updated successfully',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            }).then((response) => {
              self.findstandard();
            });
          }
        })

        .catch(function (error) {
          Swal.fire({
            title: 'Failed',
            text: 'Something went wrong',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          });
        });
    }

    if (e == 'Swimming') {
      const datatosend = {
        name: this.state.recordholder4,
        result: this.state.performance4,
        year: this.state.year4,
        location: this.state.meet4,
        wind: this.state.wind4,
        sex: this.state.gender4,
        class: this.state.classification4,
        AG: this.state.age4,
        typepool: this.state.typepool4,
        country: this.state.country4,
      };
      axios
        .put(`${url.url}/api/updateSwimnjdcrecord/${self.state.editperformancedata1.id}`, datatosend, {})

        .then(function (response) {
          if (response.status == 200) {
            Swal.fire({
              title: 'Success',
              text: 'Updated successfully',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            }).then((response) => {
              self.findstandard();
            });
          }
        })

        .catch(function (error) {
          Swal.fire({
            title: 'Failed',
            text: 'Something went wrong',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          });
        });
    }
  }

  // ========================================
  render() {
    var self = this;
    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div className='content'>
              <Row>
                <Col md='8'>
                  <Card>
                    <CardHeader>
                      <h5 className='title'>Athlete Performance Record</h5>
                    </CardHeader>

                    <div class='modal-body'>
                      <form>
                        <div class='form-row'>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Venue *</label>
                            <select
                              class='form-control'
                              id='exampleFormControlSelect1'
                              name='venue'
                              value={this.state.defaultvenuename == 'Please Select' ? '' : this.state.defaultvenuename}
                              onChange={(e) => this.optionchange(e.target.value)}
                              required
                            >
                              <option>Please Select</option>
                              {this.state.qualdatas.map((qevent, index) =>
                                qevent.name == 'Track' ||
                                qevent.name == 'Field' ||
                                qevent.name == 'Swimming' ||
                                qevent.name == 'Para Powerlifting' ? (
                                  qevent.name == 'Swimming' ? (
                                    typepoolarr.map((item) => (
                                      <option value={qevent.name + '-' + index + '-' + qevent.id + '-' + item}>
                                        {qevent.name + '-' + item}
                                      </option>
                                    ))
                                  ) : (
                                    <option value={qevent.name + '-' + index + '-' + qevent.id}> {qevent.name} </option>
                                  )
                                ) : (
                                  <></>
                                )
                              )}
                            </select>
                          </div>

                          <div class='form-group col-md-6'>
                            <label>Event *</label>
                            <select
                              class='form-control'
                              id='exampleFormControlSelect2'
                              name='event'
                              value={this.state.defaulteventname == 'Please Select' ? '' : this.state.defaulteventname}
                              onChange={(e) => this.optionchange1(e.target.value)}
                              required
                            >
                              <option>Please Select</option>
                              {Object.keys(this.state.eventsdropdown).length == 1 ? (
                                <option>Please Select</option>
                              ) : (
                                <>
                                  {this.state.eventsdropdown.events.map((edrop, index) => (
                                    <option value={edrop.name + '-' + edrop.id + '-' + edrop.venue_id}>
                                      {edrop.name}
                                    </option>
                                  ))}
                                </>
                              )}
                            </select>
                          </div>
                        </div>

                        <div class='form-row'>
                          <div class='form-group col-md-4'>
                            <label for='inputCity'>Class</label>
                            <select
                              class='form-control'
                              id='exampleFormControlSelect3'
                              name='clsvalue'
                              value={
                                this.state.defaultclassvalue == 'Please Select' ? '' : this.state.defaultclassvalue
                              }
                              onChange={(e) => this.optionchange2(e.target.value)}
                              required
                            >
                              <option>Please Select</option>
                              {Object.keys(this.state.classvaluedata).length == 0 ? (
                                <option>Please Select</option>
                              ) : (
                                <>
                                  {this.state.classvaluedata.map((cvalue, index) => (
                                    <option value={cvalue.name + '-' + cvalue.id}>
                                      {this.state.defaultvenuename.split('-')[0] == 'Para Powerlifting'
                                        ? cvalue.name + '-' + cvalue.gender
                                        : cvalue.name}
                                    </option>
                                  ))}
                                </>
                              )}
                            </select>
                          </div>
                          <div class='form-group col-md-4'>
                            <label>Age Group</label>
                            <select
                              class='form-control'
                              id='exampleFormControlSelect4'
                              name='agechange'
                              value={this.state.defagediv == 'Please Select' ? '' : this.state.defagediv}
                              onChange={(e) => this.optionchange3(e.target.value)}
                              required
                            >
                              <option>Please Select</option>
                              {Object.keys(this.state.agedata).length == 0 ? (
                                <option>Please Select</option>
                              ) : (
                                <>
                                  {this.state.agedata.map((agedivision, index) => (
                                    <option value={agedivision.name}>{agedivision.name}</option>
                                  ))}
                                </>
                              )}
                            </select>
                          </div>
                          <div class='form-group col-md-4'>
                            <label>Gender </label>
                            <select
                              class='form-control'
                              id='exampleFormControlSelect5'
                              name='gender'
                              value={this.state.defaultgender}
                              onChange={this.optionchange4}
                            >
                              <option value='Both'>Both</option>
                              <option value='Female'>Female</option>
                              <option value='Male'>Male</option>
                            </select>
                          </div>
                        </div>
                        <div class='form-group findbttn'>
                          <button type='button' id='findbtn' onClick={this.findstandard} class='btn btn-success'>
                            Find
                          </button>{' '}
                          <button type='button' id='clearbtn' onClick={this.clear} class='btn btn-danger'>
                            CLear
                          </button>
                        </div>
                      </form>
                      {this.state.showtable == false ? (
                        ''
                      ) : (
                        <>
                          <CardHeader>
                            <h5 className='title'>National</h5>
                          </CardHeader>

                          {this.state.defaultvenuename.split('-')[0] != 'Para Powerlifting' ? (
                            <Table id='my mytable2' className='mytable1' responsive>
                              <thead>
                                <tr>
                                  <th>Record Holder</th>
                                  <th>Performance</th>
                                  <th>Meet</th>
                                  <th>Year</th>
                                  <th>wind</th>
                                  <th>Gender</th>
                                  <th>Class</th>
                                  {this.state.defaultvenuename.split('-')[0] != 'Archery' ? <th>Age</th> : ''}
                                  {this.state.defaultvenuename.split('-')[0] == 'Swimming' ? <th>Type Pool</th> : ''}
                                  <th>Country</th>
                                  <th>Action</th>
                                </tr>
                              </thead>

                              {Object.keys(self.state.performancedata).length == 1 ? (
                                <tr>
                                  <td colSpan='11'>
                                    <Empty />
                                  </td>
                                </tr>
                              ) : (
                                <>
                                  <tbody id='myTable'>
                                    <>
                                      {self.state.performancedata['Nationals'].map((ques, index) => (
                                        <>
                                          <tr>
                                            <>
                                              <td>{ques.name}</td>
                                              <td>{ques.result}</td>
                                              <td>{ques.location}</td>
                                              <td>{ques.year}</td>
                                              <td>{ques.wind}</td>
                                              <td>{ques.sex}</td>
                                              <td>{ques.class}</td>
                                              <td>{ques.AG}</td>
                                              {this.state.defaultvenuename.split('-')[0] == 'Swimming' ? (
                                                <td>{ques.typepool}</td>
                                              ) : (
                                                ''
                                              )}
                                              <td>{ques.country}</td>

                                              <td class='td-actions text-right'>
                                                <button
                                                  type='button'
                                                  rel='tooltip'
                                                  class='btn btn-success btn-sm btn-icon'
                                                  onClick={() => this.edit1(index)}
                                                  data-toggle='modal'
                                                  data-target='#deletequal1'
                                                >
                                                  <i class='now-ui-icons ui-2_settings-90'></i>
                                                </button>
                                                {` `}
                                              </td>
                                            </>
                                          </tr>
                                        </>
                                      ))}
                                    </>
                                  </tbody>
                                </>
                              )}
                            </Table>
                          ) : (
                            <Table id='my mytable2' className='mytable1' responsive>
                              <thead>
                                <tr>
                                  <th>Weight Class</th>
                                  <th>Age Group</th>
                                  <th>Gender</th>
                                  <th>Best Lift Record Holder</th>
                                  <th>Year BLR</th>
                                  <th>Best Lift Record</th>
                                  <th>Total Lift Record Holder</th>
                                  <th>Year TLR</th>
                                  <th>Total Lift Record</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              {Object.keys(self.state.performancedata).length == 1 ? (
                                <tr>
                                  <td colSpan='11'>
                                    <Empty />
                                  </td>
                                </tr>
                              ) : (
                                <>
                                  <tbody id='myTable'>
                                    <>
                                      {self.state.performancedata['Nationals'].map((ques, index) => (
                                        <>
                                          <tr>
                                            <>
                                              <td>{ques.class}</td>
                                              <td>{ques.AG}</td>
                                              <td>{ques.sex}</td>
                                              <td>{ques.record_holder_brr}</td>
                                              <td>{ques.year_blr}</td>
                                              <td>{ques.best_lift_record}</td>
                                              <td>{ques.record_holder_tlr}</td>
                                              <td>{ques.year_tlr}</td>
                                              <td>{ques.total_lift_record}</td>
                                              <td class='td-actions text-right'>
                                                <button
                                                  type='button'
                                                  rel='tooltip'
                                                  class='btn btn-success btn-sm btn-icon'
                                                  onClick={() => this.weightedit(index, 'Nationals')}
                                                  data-toggle='modal'
                                                  data-target='#weight'
                                                >
                                                  <i class='now-ui-icons ui-2_settings-90'></i>
                                                </button>
                                                {` `}
                                              </td>
                                            </>
                                          </tr>
                                        </>
                                      ))}
                                    </>
                                  </tbody>
                                </>
                              )}
                            </Table>
                          )}

                          {this.state.defaultvenuename.split('-')[0] == 'Track' ||
                          this.state.defaultvenuename.split('-')[0] == 'Field' ? (
                            <div class='perrecorddata'>
                              <CardHeader>
                                <h5 className='title'>American IPC</h5>
                              </CardHeader>
                              <Table id='my mytable2' className='mytable1' responsive>
                                <thead>
                                  <tr>
                                    <th>Record Holder</th>
                                    <th>Performance</th>
                                    <th>Meet</th>
                                    <th>Year</th>
                                    <th>wind</th>
                                    <th>Gender</th>
                                    <th>Class</th>
                                    <th>Age</th>
                                    <th>Country</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>

                                <tbody id='myTable'>
                                  <>
                                    <tr>
                                      <td colSpan='10'>
                                        <Empty />
                                      </td>
                                    </tr>
                                  </>
                                </tbody>
                              </Table>
                            </div>
                          ) : (
                            ''
                          )}
                          {this.state.defaultvenuename.split('-')[0] == 'Track' ||
                          this.state.defaultvenuename.split('-')[0] == 'Field' ? (
                            <div class='perrecorddata'>
                              <CardHeader>
                                <h5 className='title'>Americas IPC</h5>
                              </CardHeader>
                              <Table id='my mytable2' className='mytable1' responsive>
                                <thead>
                                  <tr>
                                    <th>Record Holder</th>
                                    <th>Performance</th>
                                    <th>Meet</th>
                                    <th>Year</th>
                                    <th>wind</th>
                                    <th>Gender</th>
                                    <th>Class</th>
                                    <th>Age</th>
                                    <th>Country</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>

                                <>
                                  <tbody id='myTable'>
                                    <>
                                      <tr>
                                        <td colSpan='10'>
                                          <Empty />
                                        </td>
                                      </tr>
                                    </>
                                  </tbody>
                                </>
                              </Table>
                            </div>
                          ) : (
                            ''
                          )}

                          <div class='perrecorddata'>
                            <CardHeader>
                              <h5 className='title'>Junior Nationals</h5>
                            </CardHeader>
                            {this.state.defaultvenuename.split('-')[0] != 'Para Powerlifting' ? (
                              <Table id='my mytable2' className='mytable1' responsive>
                                <thead>
                                  <tr>
                                    <th>Record Holder</th>
                                    <th>Performance</th>
                                    <th>Meet</th>
                                    <th>Year</th>
                                    <th>wind</th>
                                    <th>Gender</th>
                                    <th>Class</th>
                                    {this.state.defaultvenuename.split('-')[0] != 'Archery' ? <th>Age</th> : ''}
                                    {this.state.defaultvenuename.split('-')[0] == 'Swimming' ? <th>Type Pool</th> : ''}
                                    {/* <th>DOB4</th> */}
                                    <th>Country</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                {Object.keys(self.state.performancedata).length == 1 ? (
                                  <tr>
                                    <td colSpan='11'>
                                      <Empty />
                                    </td>
                                  </tr>
                                ) : (
                                  <>
                                    <tbody id='myTable'>
                                      <>
                                        {self.state.performancedata['Njdc'].map((ques, index) => (
                                          <tr>
                                            <>
                                              <td>{ques.name}</td>
                                              <td>{ques.result}</td>
                                              <td>{ques.location}</td>
                                              <td>{ques.year}</td>
                                              <td>{ques.wind}</td>
                                              <td>{ques.sex}</td>
                                              <td>{ques.class}</td>
                                              <td>{ques.AG}</td>
                                              {this.state.defaultvenuename.split('-')[0] == 'Swimming' ? (
                                                <td>{ques.typepool}</td>
                                              ) : (
                                                ''
                                              )}
                                              <td>{ques.country}</td>
                                              <td class='td-actions text-right'>
                                                <button
                                                  type='button'
                                                  rel='tooltip'
                                                  class='btn btn-success btn-sm btn-icon'
                                                  onClick={() => this.edit4(index)}
                                                  data-toggle='modal'
                                                  data-target='#deletequal4'
                                                >
                                                  <i class='now-ui-icons ui-2_settings-90'></i>
                                                </button>
                                                {` `}
                                              </td>
                                            </>
                                          </tr>
                                        ))}
                                      </>
                                    </tbody>
                                  </>
                                )}
                              </Table>
                            ) : (
                              <Table id='my mytable2' className='mytable1' responsive>
                                <thead>
                                  <tr>
                                    <th>Weight Class</th>
                                    <th>Age Group</th>
                                    <th>Gender</th>
                                    <th>Best Lift Record Holder</th>
                                    <th>Year BLR</th>
                                    <th>Best Lift Record</th>
                                    <th>Total Lift Record Holder</th>
                                    <th>Year TLR</th>
                                    <th>Total Lift Record</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                {Object.keys(self.state.performancedata).length == 1 ? (
                                  <tr>
                                    <td colSpan='11'>
                                      <Empty />
                                    </td>
                                  </tr>
                                ) : (
                                  <>
                                    <tbody id='myTable'>
                                      <>
                                        {self.state.performancedata['Njdc'].map((ques, index) => (
                                          <>
                                            <tr>
                                              <>
                                                <td>{ques.class}</td>
                                                <td>{ques.AG}</td>
                                                <td>{ques.sex}</td>
                                                <td>{ques.record_holder_brr}</td>
                                                <td>{ques.year_blr}</td>
                                                <td>{ques.best_lift_record}</td>
                                                <td>{ques.record_holder_tlr}</td>
                                                <td>{ques.year_tlr}</td>
                                                <td>{ques.total_lift_record}</td>
                                                <td class='td-actions text-right'>
                                                  <button
                                                    type='button'
                                                    rel='tooltip'
                                                    class='btn btn-success btn-sm btn-icon'
                                                    onClick={() => this.weightedit(index, 'Njdc')}
                                                    data-toggle='modal'
                                                    data-target='#weight'
                                                  >
                                                    <i class='now-ui-icons ui-2_settings-90'></i>
                                                  </button>
                                                  {` `}
                                                </td>
                                              </>
                                            </tr>
                                          </>
                                        ))}
                                      </>
                                    </tbody>
                                  </>
                                )}
                              </Table>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </Card>
                </Col>
              </Row>
              <div class='modal fade' id='weight' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog modal-lg'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        {'Edit' + ' ' + this.state.record + ' ' + 'Record'}
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form id='myform'>
                        <div class='form-row'>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Weight Class</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Weight Class'
                              name='weightClass'
                              value={this.state.weightClass}
                              onChange={(e) => this.setState({ weightClass: e.target.value })}
                              required
                            />
                          </div>

                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Age Group</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Age Group'
                              name='ageGroup'
                              value={this.state.ageGroup}
                              onChange={(e) => this.setState({ ageGroup: e.target.value })}
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Gender</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Gender'
                              name='paraGender'
                              value={this.state.paraGender}
                              onChange={(e) => this.setState({ paraGender: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Best Lift Record Holder</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Best Lift Record Holder'
                              name='bestLiftRecordHolder'
                              value={this.state.bestLiftRecordHolder}
                              onChange={(e) => this.setState({ bestLiftRecordHolder: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Year BLR</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Year BLR'
                              name='yearBLR'
                              value={this.state.yearBLR}
                              onChange={(e) => this.setState({ yearBLR: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Best Lift Record</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Best Lift Record'
                              name='bestLiftRecord'
                              value={this.state.bestLiftRecord}
                              onChange={(e) => this.setState({ bestLiftRecord: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Total Lift Record Holder</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Total Lift Record Holder'
                              name='totalLiftRecordHolder'
                              value={this.state.totalLiftRecordHolder}
                              onChange={(e) => this.setState({ totalLiftRecordHolder: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Year TLR</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Year TLR'
                              name='yearTLR'
                              value={this.state.yearTLR}
                              onChange={(e) => this.setState({ yearTLR: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Total Lift Record</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Total Lift Record'
                              name='totalLiftRecord'
                              value={this.state.totalLiftRecord}
                              onChange={(e) => this.setState({ totalLiftRecord: e.target.value })}
                              required
                            />
                          </div>
                        </div>
                        <div class='form-group'>
                          <center>
                            <button
                              type='button'
                              onClick={(e) => self.update(this.state.defaultvenuename.split('-')[0])}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Update{' '}
                            </button>
                          </center>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div class='modal fade' id='deletequal1' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog modal-lg'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Edit National Record
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form id='myform'>
                        <div class='form-row'>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Record Holder</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Record Holder'
                              name='recordholder'
                              value={this.state.recordholder}
                              onChange={(e) => this.setState({ recordholder: e.target.value })}
                              required
                            />
                          </div>

                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Performance</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Performance'
                              name='defagebreak'
                              value={this.state.performance}
                              onChange={(e) => this.setState({ performance: e.target.value })}
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Meet</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Meet1'
                              name='defagebreak'
                              value={this.state.meet}
                              onChange={(e) => this.setState({ meet: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Year</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Year'
                              name='defagebreak'
                              value={this.state.year}
                              onChange={(e) => this.setState({ year: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Wind</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Wind'
                              name='defagebreak'
                              value={this.state.wind}
                              onChange={(e) => this.setState({ wind: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Gender</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Wind'
                              name='defagebreak'
                              value={this.state.gender}
                              onChange={(e) => this.setState({ gender: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Class</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Wind'
                              name='defagebreak'
                              value={this.state.classification}
                              onChange={(e) => this.setState({ classification: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Age</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Wind'
                              name='defagebreak'
                              value={this.state.age}
                              onChange={(e) => this.setState({ age: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Country</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Country'
                              name='defagebreak'
                              value={this.state.country}
                              onChange={(e) => this.setState({ country: e.target.value })}
                              required
                            />
                          </div>
                          {this.state.defaultvenuename.split('-')[0] == 'Swimming' ? (
                            <div class='form-group col-md-6'>
                              <label>TypePool</label>
                              <input
                                class='form-control'
                                id='exampleFormControlSelect1'
                                name='typepool'
                                value={this.state.typepool}
                                onChange={(e) => this.setState({ typepool: e.target.value })}
                                required
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>

                        <div class='form-group'>
                          <center>
                            <button
                              type='button'
                              onClick={(e) => self.update(this.state.defaultvenuename.split('-')[0])}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Update{' '}
                            </button>
                          </center>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* //// */}
              <div class='modal fade' id='deletequal2' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog modal-lg'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Edit American IPC Record
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form id='myform'>
                        <div class='form-row'>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Record Holder2</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Record Holder'
                              name='recordholder'
                              value={this.state.recordholder2}
                              onChange={(e) => this.setState({ recordholder2: e.target.value })}
                              required
                            />
                          </div>

                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Performance2</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Performance2'
                              name='defagebreak'
                              value={this.state.performance2}
                              onChange={(e) => this.setState({ performance2: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Meet2</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Meet2'
                              name='defagebreak'
                              value={this.state.meet2}
                              onChange={(e) => this.setState({ meet2: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Year2</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Year2'
                              name='defagebreak'
                              value={this.state.year2}
                              onChange={(e) => this.setState({ year2: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Wind2</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Wind2'
                              name='defagebreak'
                              value={this.state.wind2}
                              onChange={(e) => this.setState({ wind2: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Gender2</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter gender2'
                              name='defagebreak'
                              value={this.state.gender2}
                              onChange={(e) => this.setState({ gender2: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Class2</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter class2'
                              name='defagebreak'
                              value={this.state.classification2}
                              onChange={(e) => this.setState({ classification2: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Age2</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter age2'
                              name='defagebreak'
                              value={this.state.age2}
                              onChange={(e) => this.setState({ age2: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Country2</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Country2'
                              name='defagebreak'
                              value={this.state.country2}
                              onChange={(e) => this.setState({ country2: e.target.value })}
                              required
                            />
                          </div>
                        </div>
                        <div class='form-group'>
                          <center>
                            <button type='submit' onClick={this.update2} data-dismiss='modal' class='btn btn-danger'>
                              Update{' '}
                            </button>
                          </center>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* ////// */}
              <div class='modal fade' id='deletequal3' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog modal-lg'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Edit Americas IPC Record
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form id='myform'>
                        <div class='form-row'>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Record Holder3</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Record Holder3'
                              name='recordholder'
                              value={this.state.recordholder3}
                              onChange={(e) => this.setState({ recordholder3: e.target.value })}
                              required
                            />
                          </div>

                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Performance3</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Performance3'
                              name='defagebreak'
                              value={this.state.performance3}
                              onChange={(e) => this.setState({ performance3: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Meet3</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Meet3'
                              name='defagebreak'
                              value={this.state.meet3}
                              onChange={(e) => this.setState({ meet3: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Year3</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Year3'
                              name='defagebreak'
                              value={this.state.year3}
                              onChange={(e) => this.setState({ year3: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Wind3</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Wind3'
                              name='defagebreak'
                              value={this.state.wind3}
                              onChange={(e) => this.setState({ wind3: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Gender3</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter gender3'
                              name='defagebreak'
                              value={this.state.gender3}
                              onChange={(e) => this.setState({ gender3: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Class3</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter class3'
                              name='defagebreak'
                              value={this.state.classification3}
                              onChange={(e) => this.setState({ classification3: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Age3</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter age3'
                              name='defagebreak'
                              value={this.state.age3}
                              onChange={(e) => this.setState({ age3: e.target.value })}
                              required
                            />
                          </div>

                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Country3</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Country3'
                              name='defagebreak'
                              value={this.state.country3}
                              onChange={(e) => this.setState({ country3: e.target.value })}
                              required
                            />
                          </div>
                        </div>
                        <div class='form-group'>
                          <center>
                            <button type='submit' onClick={this.update3} data-dismiss='modal' class='btn btn-danger'>
                              Update{' '}
                            </button>
                          </center>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* // */}
              <div class='modal fade' id='deletequal4' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog modal-lg'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Edit Junior Nationals Record
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form id='myform'>
                        <div class='form-row'>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Record Holder4</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Record Holder4'
                              name='recordholder'
                              value={this.state.recordholder4}
                              onChange={(e) => this.setState({ recordholder4: e.target.value })}
                              required
                            />
                          </div>

                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Performance4</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Performance4'
                              name='defagebreak'
                              value={this.state.performance4}
                              onChange={(e) => this.setState({ performance4: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Meet4</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Meet4'
                              name='defagebreak'
                              value={this.state.meet4}
                              onChange={(e) => this.setState({ meet4: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Year4</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Year4'
                              name='defagebreak'
                              value={this.state.year4}
                              onChange={(e) => this.setState({ year4: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Wind4</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Wind4'
                              name='defagebreak'
                              value={this.state.wind4}
                              onChange={(e) => this.setState({ wind4: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Gender4</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter gender4'
                              name='defagebreak'
                              value={this.state.gender4}
                              onChange={(e) => this.setState({ gender4: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Class4</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter class4'
                              name='defagebreak'
                              value={this.state.classification4}
                              onChange={(e) => this.setState({ classification4: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Age4</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter age4'
                              name='defagebreak'
                              value={this.state.age4}
                              onChange={(e) => this.setState({ age4: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Country4</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Country4'
                              name='defagebreak'
                              value={this.state.country4}
                              onChange={(e) => this.setState({ country4: e.target.value })}
                              required
                            />
                          </div>
                          {this.state.defaultvenuename.split('-')[0] == 'Swimming' ? (
                            <div class='form-group col-md-6'>
                              <label>TypePool4</label>
                              <input
                                class='form-control'
                                id='exampleFormControlSelect1'
                                placeholder='Enter Typepool4'
                                name='typepool'
                                value={this.state.typepool4}
                                onChange={(e) => this.setState({ typepool4: e.target.value })}
                                required
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>

                        <div class='form-group'>
                          <center>
                            <button
                              type='button'
                              onClick={(e) => self.update4(this.state.defaultvenuename.split('-')[0])}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Update{' '}
                            </button>
                          </center>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Performancerecord;
