import React from 'react';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from 'reactstrap';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
// import 'font-awesome/css/font-awesome.min.css';
// import '@fortawesome/fontawesome-free/js/all.js';
import './compound.css';
import Swal from 'sweetalert2';
import axios from 'axios';
import $ from 'jquery';
import Meetmanager from './Meetmanagerhome.js';
import Meet from './Meet.js';
import Dashboard from './Dashboard.js';
import Notifications from './Qstandards.js';
import User from './UserPage.js';
import Venues from './Venues.js';
import logo from '../logo.png';
import AtheletHome from './atheletehome.js';
import Rstatus from './registrationstatus.js';
import Aregister from './atheleteregister.js';
import Agedivision from './Agedivision.js';
import Points from './Points.js';
import Gender from './Gender.js';
// import Teammaster from "./Teammaster.js"
import Homes from '../App.js';
import adaptive from '../adaptive.png';

import { act } from 'react-dom/test-utils';
import FullScreenMap2 from './Venueclasses';
import FullScreenMap from './Venuesload.js';
import Eventsponser from './Eventsponser.js';
import LocalCommit from './Localcommittee.js';
import ManagerVenue from './Managervenues.js';
import Atheleteregister from './atheleteregister.js';
import Meetviewmanager from './Meetsview';
import Atheleteresult from './atheleteresult';
import Detailpage from './Detailpage';
import Viewall1 from './Viewall';

import Relay from './Relaypoints.js';
import Pentathlon from './Pentathlon.js';
import Venueset from './Venuesetup.js';
import Eventset from './Eventsetup.js';
import Trackset from './Trackset.js';
import Fieldset from './Fieldset';
import Arcset from './Arcset.js';
import Swimset from './Swimset.js';
import Tabletennisset from './Tableset.js';
import Weightset from './Weightlifting.js';
import Teamset from './Teamset.js';
import Fenceset from './Fencingset';
import Otherset from './Otherset.js';
import Paratriathlonset from './Paratriathlonsset.js';
import Paratriathlontwoset from './Paratwoset.js';
// import Manager from './Manager';
import Regtrack from './Regtrack';
import Regfield from './Regfield';
import Regarchery from './Regarchery';
import Regswim from './Regswim';
import Regtennis from './Regtennis';
import Regweightlift from './Regweightlift';
import Regteam from './Regteam';
import Regfencing from './Regfence';
import Regother from './Regother';

import Regitrack from './Regitrack.js';
import Regifield from './Regifield.js';
import Regiarchery from './Regiarchery.js';
import Regiswim from './Regiswim.js';
import Regitennis from './Regitennis.js';
import Regiweightlift from './Regiweightlift.js';
import Regiteam from './Regiteam.js';
import Regifence from './Regifence.js';
import Regiother from './Regiother.js';

import Trackstandard from './Trackstandard.js';
import Showallqstd from './Showallqstd';

import Classvalue from './Classvalue.js';
import Classagegroup from './Classagegroup.js';

import Performancerecord from './AthletePerformancerecord.js';

import Athletevenues from './Athletevenues';
import Bibassign from './Bibassignment';
import Trackworksheet from './Trackworksheet';
import Venuesetups from './Venuesetups';
import Venue_Mainpage from './Venue_Mainpage.js';
import Fieldpitassign from './Fieldpitassignment.js';
import Relaysetup from './Relaysetup.js';
import Archerypitassign from './Archeryassignment';
import Benchassign from './Benchassignment';
import Otherassign from './Otherassignment';
import PreliminaryTrackresult from './Preliminarytrackresult';
import Specialheat from './Specialheat';
import Fieldresultentry from './Filedresultsentry';
import Fieldshowresult from './Fieldshowresults';
// import Jsondownload from "./Fieldjsondownload";
import Assignpits from './Assignpits';
import Athletetrack from './Athletetrack';
import Athletefield from './Athletefield';
import Athletearc from './Athletearchery';
import Athleteweight from './Athleteweightlift';
import Athletetennis from './Athletetabletennis';
import Athleteteamsport from './Athleteteam.js';
import Athletefence from './Athletefence';
import Athleteother from './Athleteother';
import Athleteswim from './Athleteswim';
import Register from '../register';

import Trackfinalworksheet from './Trackfinalworksheet.js';
import Trackfinalresult from './Trackfinalresult';
import Archerytargetresult from './Archerytargetresult';
import Archeryclassresult from './Archeryclassresult';
import Archeryworksheet from './Archeryworksheet';
import Weightliftbenchresult from './Weightliftbenchresults';
import Weightliftbenchclass from './Weightliftbenchclass';
import Weightliftworksheet from './Weightliftworksheet';
import Tennisgroupentry from './Tennisgroupentry';
import Tennisclassentry from './Tennisclassentry';
import Tennisworksheet from './Tennisworksheet';
import Tennisassigngroup from './Tennisassigngroups';
import Tennissetupopponents from './Tennissetupopponents';
import Tennisassigngroupdoubles from './Tennisassigngroupdouble';
import Tennissetupdoubles from './Tennissetupdoubles';
import Fencegroupentry from './Fencegroupentry';
import Fenceclassentry from './Fenceclassentry';
import Fenceworksheet from './Fenceworksheet';
import Fenceassigngroups from './Fenceassigngroups';
import Fencesetupopponents from './Fencesetupopponents';
import Fencesetupfinalists from './Fencesetupfinalists';
import Airgunpitentry from './Airgunpitentry';
import Airgunclassentry from './Airgunclassentry';
import Airgunworksheet from './Airgunworksheet';
import CyclingWorksheet from './Cyclingworksheet';
import AirRifleworksheet from './AirRifleworksheet';
import Paratriathlon100worksheet from './Paratriathlon100worksheet';
import Paratriathlon100heatsheet from './Paratriathlon100heatsheet';
import Paratriathlon200worksheet from './Paratriathlon200worksheet';
import Paratriathlon200heatsheet from './Paratriathlon200heatsheet';
import AirRifleheatsheet from './AirRifleheatsheet';
import Bocciaheatsheet from './Bocciaheatsheet';
import Cyclingheatsheet from './Cyclingheatsheet';
import Swimprelimheatsetup from './Swimprelimheatsetup';
import Swimfinalheatsetup from './Swimfinalheatsetup';
import Swimprelimresult from './Swimprelimresult';
import Swimfinalresult from './Swimfinalresult';
import Swimrelaysetup from './Swimrelaysetup';
import Swimresultworksheet from './Swimresultworksheet';
import Classification from './Classification';
import Archeryassigntarget from './Archerytargetassign';
import Implementfile from './Implementfile';
import Assignbench from './Assignbench';
import Trackresultworksheet from './Trackresultworksheet';
import Registeredsearch from './Athletesearch';
import DispalyallFieldresultentry from './Displayallfieldresult';
import Bocciaworksheet from './Bocciaworksheet';
import Tennisprint from './tennisprint';
import Teamsportsworksheet from './Teamsportsworksheet';
import Teamsportsheatsheet from './Teamsportsheatsheet';
import Teamsportsinitialsetup from './TeamsportsInitialsetup';
import Fenceheatsheet from './Fenceheatsheet';
import Paratriathlon500worksheet from './Paratriathlon500worksheet';
import Paratriathlon500heatsheet from './Paratriathlon500heatsheet';
const url = window.globalConfig;
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      active0: '',
      active1: 'active active-pro',
      active2: '',
      active3: '',
      active4: '',
      active5: '',
      active6: '',
      active7: '',
      active8: '',
      active9: '',
      active10: '',
      // active11: "",
      // curnavitem:'',

      edituserdata: false,
      firstname: '',
      lastname: '',
      email: '',
      dob: '',
      gender: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      homephone: '',
      cellphone: '',
      zipcode: '',
      designation: '',
      pwd: '',
      cpwd: '',
      teamname: '',
    };
    this.openSidebar = this.openSidebar.bind(this);
    this.active = this.active.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
    this.edituser = this.edituser.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateuser = this.updateuser.bind(this);
  }

  componentDidMount() {
    var self = this;
    self.setState({
      firstname: this.props.logindata.user.firstname,
    });

    var curnavitem = sessionStorage.getItem('curnavitem');
    this.active(curnavitem);
  }

  refreshPage() {
    sessionStorage.removeItem('curnavitem');
    sessionStorage.clear('userdetails');
    window.location.href = '/';
  }

  sidebarToggle = React.createRef();
  toggle = () => {
    if (this.state.isOpen) {
      this.setState({
        color: 'transparent',
      });
    } else {
      this.setState({
        color: 'white',
      });
    }
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  openSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
    this.sidebarToggle.current.classList.toggle('toggled');
  };

  active(e) {
    sessionStorage.setItem('curnavitem', e);

    var self = this;
    if (e == 0) {
      self.setState({
        active0: 'active active-pro',
        active1: 'active-pro',
        active2: 'active-pro',
        active3: 'active-pro',
        active4: 'active-pro',
        active5: 'active-pro',
        active6: 'active-pro',
        active7: 'active-pro',
        active8: 'active-pro',
        active9: 'active-pro',
        active10: 'active-pro',
        // active11: "active-pro",
      });
    }
    if (e == 1) {
      self.setState({
        active0: 'active-pro',
        active1: 'active active-pro',
        active2: 'active-pro',
        active3: 'active-pro',
        active4: 'active-pro',
        active5: 'active-pro',
        active6: 'active-pro',
        active7: 'active-pro',
        active8: 'active-pro',
        active9: 'active-pro',
        active10: 'active-pro',
        // active11: "active-pro",
      });
    }
    if (e == 2) {
      self.setState({
        active0: 'active-pro',
        active1: 'active-pro',
        active2: 'active active-pro',
        active3: 'active-pro',
        active4: 'active-pro',
        active5: 'active-pro',
        active6: 'active-pro',
        active7: 'active-pro',
        active8: 'active-pro',
        active9: 'active-pro',
        active10: 'active-pro',
        // active11: "active-pro",
      });
    }
    if (e == 3) {
      self.setState({
        active0: 'active-pro',
        active1: 'active-pro',
        active2: 'active-pro',
        active3: 'active active-pro',
        active4: 'active-pro',
        active5: 'active-pro',
        active6: 'active-pro',
        active7: 'active-pro',
        active8: 'active-pro',
        active9: 'active-pro',
        active10: 'active-pro',
        // active11: "active-pro",
      });
    }
    if (e == 4) {
      self.setState({
        active0: 'active-pro',
        active1: 'active-pro',
        active2: 'active-pro',
        active3: 'active-pro',
        active4: 'active active-pro',
        active5: 'active-pro',
        active6: 'active-pro',
        active7: 'active-pro',
        active8: 'active-pro',
        active9: 'active-pro',
        active10: 'active-pro',
        // active11: "active-pro",
      });
    }

    if (e == 5) {
      self.setState({
        active0: 'active-pro',
        active1: 'active-pro',
        active2: 'active-pro',
        active3: 'active-pro',
        active4: 'active-pro',
        active5: 'active active-pro',
        active6: 'active-pro',
        active7: 'active-pro',
        active8: 'active-pro',
        active9: 'active-pro',
        active10: 'active-pro',
        // active11: "active-pro",
      });
    }
    if (e == 6) {
      self.setState({
        active0: 'active-pro',
        active1: 'active-pro',
        active2: 'active-pro',
        active3: 'active-pro',
        active4: 'active-pro',
        active5: 'active-pro',
        active6: 'active active-pro',
        active7: 'active-pro',
        active8: 'active-pro',
        active9: 'active-pro',
        active10: 'active-pro',
        // active11: "active-pro",
      });
    }
    if (e == 7) {
      self.setState({
        active0: 'active-pro',
        active1: 'active-pro',
        active2: 'active-pro',
        active3: 'active-pro',
        active4: 'active-pro',
        active5: 'active-pro',
        active6: 'active-pro',
        active7: 'active active-pro',
        active8: 'active-pro',
        active9: 'active-pro',
        active10: 'active-pro',
        // active11: "active-pro",
      });
    }
    if (e == 8) {
      self.setState({
        active0: 'active-pro',
        active1: 'active-pro',
        active2: 'active-pro',
        active3: 'active-pro',
        active4: 'active-pro',
        active5: 'active-pro',
        active6: 'active-pro',
        active7: 'active-pro',
        active8: 'active active-pro',
        active9: 'active-pro',
        active10: 'active-pro',
        // active11: "active-pro",
      });
    }
    if (e == 9) {
      self.setState({
        active0: 'active-pro',
        active1: 'active-pro',
        active2: 'active-pro',
        active3: 'active-pro',
        active4: 'active-pro',
        active5: 'active-pro',
        active6: 'active-pro',
        active7: 'active-pro',
        active8: 'active-pro',
        active9: 'active active-pro',
        active10: 'active-pro',
        // active11: "active-pro",
      });
    }
    if (e == 10) {
      self.setState({
        active0: 'active-pro',
        active1: 'active-pro',
        active2: 'active-pro',
        active3: 'active-pro',
        active4: 'active-pro',
        active5: 'active-pro',
        active6: 'active-pro',
        active7: 'active-pro',
        active8: 'active-pro',
        active9: 'active-pro',
        active10: 'active active-pro',
        // active11: "active-pro",
      });
    }
    // if (e == 11) {
    //   self.setState({
    //     active0: "active-pro",
    //     active1: "active-pro",
    //     active2: "active-pro",
    //     active3: "active-pro",
    //     active4: "active-pro",
    //     active5: "active-pro",
    //     active6: "active-pro",
    //     active7: "active-pro",
    //     active8: "active-pro",
    //     active9: "active-pro",
    //     active10: "active-pro",
    //     active11: "active active-pro",
    //   });
    //   document.getElementById("eventset").style.display = "none";
    // document.getElementById("bib").style.display = "none";
    // document.getElementById("venuessetup").style.display = "none";
    // document.getElementById("aregis").style.display = "none";
    // document.getElementById("homelink").style.display = "none";
    // document.getElementById("meetview").style.display = "none";
    // document.getElementById("meets").style.display = "block";
    // }
  }

  active11() {
    document.getElementById('eventset').style.display = 'none';
    document.getElementById('bib').style.display = 'none';
    document.getElementById('venuessetup').style.display = 'none';
    document.getElementById('aregis').style.display = 'none';
    document.getElementById('homelink').style.display = 'none';
    document.getElementById('meetview').style.display = 'none';
    // document.getElementById("meets").style.display = "block";
    window.location.reload('/meets');
    sessionStorage.removeItem('curnavitem');
  }

  dropdownToggle = (e) => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  edituser() {
    var self = this;

    self.setState({
      edituserdata: true,
      firstname: this.props.logindata.user.firstname,
      lastname: this.props.logindata.user.lastname,
      email: this.props.logindata.user.email,
      dob: this.props.logindata.user.dob,
      gender: this.props.logindata.user.gender,
      address: this.props.logindata.user.address,
      address2: this.props.logindata.user.address2,
      city: this.props.logindata.user.city,
      country: this.props.logindata.user.country,
      homephone: this.props.logindata.user.homephone,
      cellphone: this.props.logindata.user.cellphone,
      zipcode: this.props.logindata.user.zipcode,
      designation: this.props.logindata.user.designation,
      teamname: this.props.logindata.user.team_name,
      pwd: this.props.logindata.user.password,
      cpwd: this.props.logindata.user.confirm_password,
    });
  }

  updateuser(e) {
    e.preventDefault();

    const datatosend = {
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      gender: this.state.gender,
      email: this.state.email,
      // dob: this.state.dob,
      age: '1',
      // address: this.state.address,
      // address2: this.state.address2,
      // city: this.state.city,
      // "zipcode": this.state.zipcode,
      // country: this.state.country,
      // "homephone":  this.state.homephone,
      cellphone: this.state.cellphone,
      role_id: this.props.logindata.user.role_id,
      designation: this.state.designation,
      created_by: this.props.logindata.user.created_by,
      password: this.state.pwd,
      team_name: this.state.teamname,
    };
    if (this.state.pwd === this.state.cpwd) {
      axios
        .put(`${url.url}/api/updateuserdata/${this.props.logindata.user.id}`, datatosend, {})
        .then(function (response) {
          if (response.status == 200) {
            Swal.fire({
              title: 'Success',
              text: 'Data updated successfully',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          }
        })

        .catch(function (error) {
          Swal.fire({
            title: 'Failed',
            text: 'Something went wrong',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          });
        });
    } else {
      Swal.fire('Please check your password!', 'Password does not match', 'warning');
    }
  }
  handleChange(e) {
    var self = this;
    self.setState({
      [e.target.name]: e.target.value,
    });
  }

  title() {
    try {
      return JSON.parse(sessionStorage.getItem('spec')).name;
    } catch {
      return '';
    }
  }
  render() {
    // let path;
    // if(sessionStorage.getItem("path")){
    //   path = sessionStorage.getItem("path");
    // }else if((!sessionStorage.getItem("path")) && (this.props.logindata.user.role_id === 1) || (this.props.logindata.user.role_id === 2)){
    //   path = "/meets"
    // }else if((!sessionStorage.getItem("path")) && this.props.logindata.user.role_id === 3){
    //   path = "/Atheletehome"
    // }
    // admin view ------------------------------------------------------------------------------------------------------------
    if (this.props.logindata.user.role_id === 1) {
      return (
        <div>
          {/* <Router  basename="/samsreact/"> */}
          <Router>
            <div className='sidebar' data-color='red'>
              <div className='sidebar-wrapper' ref='sidebar'>
                <div className='logo'>
                  <a href='#'>
                    <div class='sidenavimg'>
                      <div class='row'>
                        <div class='column'>
                          <img src={logo} width='70' height='40'></img>
                          <img src={adaptive} width='70' height='40'></img>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <Nav>
                  {/* <li className={this.state.active0} onClick={() => this.active(0)}>
<Link to="/dashboard"><i className="now-ui-icons design_app"></i>Dashboard</Link>

</li>  */}

                  <li className={this.state.active1} onClick={() => this.active(1)}>
                    <Link exact to='/meets'>
                      <i className='now-ui-icons design_image'></i>Meets
                    </Link>
                  </li>

                  <li className={this.state.active2} onClick={() => this.active(2)}>
                    <Link exact to='/venues'>
                      <i className='now-ui-icons location_map-big'></i>Venues
                    </Link>
                  </li>

                  <li className={this.state.active5} onClick={() => this.active(5)}>
                    <Link to='/performancerecord'>
                      <i className='fas fa-bar-chart team'></i>Athlete Performance Record
                    </Link>
                  </li>

                  <li className={this.state.active3} onClick={() => this.active(3)}>
                    <Link to='/Qualifyingstandard'>
                      <i className='now-ui-icons ui-1_bell-53'></i>Qualifiying Standards
                    </Link>
                  </li>

                  <li className={this.state.active9} onClick={() => this.active(9)}>
                    <Link exact to='/relaypoints'>
                      <i className='now-ui-icons files_paper'></i>Set Relay Points
                    </Link>
                  </li>

                  <li className={this.state.active10} onClick={() => this.active(10)}>
                    <Link exact to='/Pentathlon' className='ppoints'>
                      <i className='now-ui-icons files_paper '></i>Pentathlon / Performance Points
                    </Link>
                  </li>

                  <li className={this.state.active6} onClick={() => this.active(6)}>
                    <Link exact to='/classification' className='ppoints'>
                      <i className='now-ui-icons files_paper '></i> Classification
                    </Link>
                  </li>

                  <li className={this.state.active4} onClick={() => this.active(4)}>
                    <Link exact to='/users'>
                      <i className='now-ui-icons users_single-02'></i>Users
                    </Link>
                  </li>
                </Nav>
              </div>
            </div>

            {/* side nav bar------------------------------------------------------------------ */}

            {/* panel header ---------------------------------------------------------------------------------------------------- */}
            <div className='main-panel'>
              <Navbar expand='lg' color='primary'>
                <Container fluid>
                  <p class='headertxt'>MU Meet Management Application</p>
                  <div className='navbar-wrapper'>
                    <div className='navbar-toggle'>
                      <button
                        type='button'
                        ref={this.sidebarToggle}
                        className='navbar-toggler'
                        onClick={() => this.openSidebar()}
                      >
                        <span className='navbar-toggler-bar bar1' />
                        <span className='navbar-toggler-bar bar2' />
                        <span className='navbar-toggler-bar bar3' />
                      </button>
                    </div>
                  </div>

                  <NavbarToggler onClick={this.toggle}>
                    <span className='navbar-toggler-bar navbar-kebab'></span>
                    <span className='navbar-toggler-bar navbar-kebab'></span>
                    <span className='navbar-toggler-bar navbar-kebab'></span>
                  </NavbarToggler>

                  <Collapse isOpen={this.state.isOpen} navbar className='justify-content-end'>
                    <Nav navbar>
                      <NavItem>
                        <li className='nav-link'>
                          <i className='now-ui-icons media-2_sound-wave' id='usericon' onClick={this.refreshPage} />
                          <p onClick={this.refreshPage} id='ptag'>
                            Logout
                            <span className='d-lg-none d-md-block'></span>
                          </p>
                        </li>
                      </NavItem>
                      <Dropdown nav isOpen={this.state.dropdownOpen} toggle={(e) => this.dropdownToggle(e)}>
                        <DropdownToggle caret nav>
                          <i className='now-ui-icons users_single-02' id='usericon' />
                          <p>
                            <span className='d-lg-none d-md-block'>Account</span>
                          </p>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem tag='a' onClick={this.edituser} data-toggle='modal' data-target='#useredits'>
                            Edit Profile
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </Nav>
                    <p id='ptag'>User: {this.props.logindata.user.firstname}</p>
                  </Collapse>
                </Container>
              </Navbar>

              {/* panel header ---------------------------------------------------------------------------------------------------- */}
              <div className='wrapper svg'>
                <Switch>
                  {/* <Route path="/dashboard" >
<Dashboard />
</Route> */}
                  <Redirect exact from='/' to='/meets' />
                  <Route exact path='/meets' component={Meet}>
                    <Meet />
                  </Route>
                  <Route exact path='/venues' component={Venues}>
                    <Venues />
                  </Route>
                  <Route exact path='/performancerecord' component={Performancerecord}>
                    <Performancerecord />
                  </Route>
                  <Route exact path='/Qualifyingstandard' Component={Showallqstd}>
                    <Showallqstd />
                  </Route>
                  {/* <Route exact path="/showallQualifyingstandard" Component={Showallqstd}>
                    <Showallqstd />
                  </Route> */}
                  <Route exact path='/trackstandard' component={Trackstandard}>
                    <Trackstandard />
                  </Route>
                  <Route exact path='/users' component={User}>
                    <User />
                  </Route>
                  <Route exact path='/Agedivision' component={Agedivision}>
                    <Agedivision />
                  </Route>
                  <Route exact path='/Points' component={Points}>
                    <Points />
                  </Route>
                  <Route exact path='/relaypoints' component={Relay}>
                    <Relay />
                  </Route>
                  <Route exact path='/Pentathlon' component={Pentathlon}>
                    <Pentathlon />
                  </Route>
                  <Route exact path='/classification' component={Classification}>
                    <Classification />
                  </Route>
                  <Route exact path='/Gender' component={Gender}>
                    <Gender />
                  </Route>
                  <Route exact path='/venuesevent' component={FullScreenMap}>
                    <FullScreenMap />
                  </Route>
                  <Route exact path='/venueclasses' component={FullScreenMap2}>
                    <FullScreenMap2 />
                  </Route>
                  <Route exact path='/classvalues' component={Classvalue}>
                    <Classvalue />
                  </Route>
                  <Route exact path='/classagegroups' component={Classagegroup}>
                    <Classagegroup />
                  </Route>
                </Switch>
              </div>

              {/* edit user model ------------------------------------------------------------ */}
              <div id='useredits' class='modal fade' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog  modal-lg' role='document'>
                  <div class='modal-content'>
                    <div class='modal-header'>
                      <h5 class='modal-title' id='registermodalLabel'>
                        Edit your Profile
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form onSubmit={this.submitregister}>
                        <div class='form-row'>
                          <div class='form-group col-md-6'>
                            <label for='inputEmail4'>FirstName</label>
                            <input
                              type='text'
                              class='form-control'
                              id='inputEmail4'
                              placeholder='firstname'
                              name='firstname'
                              value={this.state.firstname}
                              onChange={(e) => this.setState({ firstname: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputPassword4'>LastName</label>
                            <input
                              type='text'
                              class='form-control'
                              id='inputPassword4'
                              placeholder='lastname'
                              value={this.state.lastname}
                              onChange={(e) => this.setState({ lastname: e.target.value })}
                              required
                            />
                          </div>
                        </div>

                        <div class='form-row'>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Email</label>
                            <input
                              type='email'
                              class='form-control'
                              id='inputCity'
                              placeholder='email'
                              value={this.state.email}
                              onChange={(e) => this.setState({ email: e.target.value })}
                              required
                            />
                          </div>

                          <div class='form-group col-md-6'>
                            <label for='inputZip'>Change your Password</label>
                            <input
                              type='password'
                              class='form-control'
                              id='inputZip'
                              placeholder='password'
                              value={this.state.pwd}
                              onChange={(e) => this.setState({ pwd: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputZip'>Confirm changed Password</label>
                            <input
                              type='password'
                              class='form-control'
                              id='inputZip'
                              placeholder='confirm_password'
                              onChange={(e) => this.setState({ cpwd: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputZip'>Cellphone</label>
                            <input
                              type='text'
                              class='form-control'
                              id='inputZip'
                              name='cellphone'
                              value={this.state.cellphone}
                              placeholder='Enter cellphone'
                              onChange={(e) => this.setState({ cellphone: e.target.value })}
                              required
                            />
                          </div>
                          {/* <div class="form-group col-md-6">
                            <label for="inputState">Gender</label>
                            <select
                              id="inputState"
                              class="form-control"
                              name="gender"
                              value={this.state.gender}
                              onChange={this.handlechange}
                            >
                              <option selected>Choose...</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                          </div> */}
                        </div>

                        <div class='form-row'>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Organization</label>
                            <input
                              type='text'
                              class='form-control'
                              id='inputCity'
                              name='dob'
                              value={this.state.teamname}
                              onChange={(e) => this.setState({ teamname: e.target.value })}
                              required
                            />
                          </div>
                        </div>

                        <center>
                          <button type='submit' class='btn btn-danger' onClick={this.updateuser} data-dismiss='modal'>
                            Update
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Router>
        </div>
      );
    }
    // admin view ------------------------------------------------------------------------------------------------------------

    // manager view---------------------------------------------------------------------------------------------------------
    if (this.props.logindata.user.role_id === 2) {
      return (
        <div>
          {/* <Router basename="/samsreact/"> */}
          <Router>
            <div className='sidebar' data-color='red'>
              <div className='sidebar-wrapper' ref='sidebar'>
                <div className='logo'>
                  <a href='#'>
                    <div class='sidenavimg'>
                      <div class='row'>
                        <div class='column'>
                          <img src={logo} width='70' height='40'></img>
                          <img src={adaptive} width='70' height='40'></img>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <Nav>
                  <li id='meets' className={this.state.active1} onClick={() => this.active(1)}>
                    <Link exact to='/meets'>
                      <i className='now-ui-icons design_image'></i>Meets
                    </Link>
                  </li>

                  <li id='homelink' className={this.state.active11} onClick={() => this.active11()}>
                    <Link exact to='/meets'>
                      <i class='fas fa-home team'></i>Home
                    </Link>
                  </li>

                  <li id='meetview' className={this.state.active1} onClick={() => this.active(1)}>
                    <Link exact to='/meetviewmanager'>
                      <i className='now-ui-icons design_image'></i>Meet Setup
                    </Link>
                  </li>

                  <li id='eventset' className={this.state.active8} onClick={() => this.active(8)}>
                    <Link exact to='/eventpreference'>
                      <i className='now-ui-icons design_image'></i>Event Preferences
                    </Link>
                  </li>

                  <li id='aregis' className={this.state.active5} onClick={() => this.active(5)}>
                    <Link exact to='/athleteregister'>
                      <i class='fas fa-registered team'></i>Athlete Registration/Search
                    </Link>
                  </li>

                  {/* <li id="venueesss" className={this.state.active7} onClick={() => this.active(7)}>
                    <Link exact to="/managevenues"><i className="now-ui-icons location_map-big"></i>Venues</Link>
                  </li> */}

                  <li id='bib' className={this.state.active2} onClick={() => this.active(2)}>
                    <Link exact to='/bibassignment'>
                      <i class='fa fa-list-ol team'></i>Bib Assignment
                    </Link>
                  </li>

                  <li id='venuessetup' className={this.state.active4} onClick={() => this.active(4)}>
                    <Link exact to='/venuesetups'>
                      <i className='now-ui-icons location_map-big'></i>Venues SetUp / Results
                    </Link>
                  </li>

                  {/* <li id="resultp" className={this.state.active6} onClick={() => this.active(6)}>
                    <Link exact to="/athleteresult"><i className="fas fa-file-alt team" ></i>Results</Link>
                  </li> */}
                </Nav>
              </div>
            </div>

            {/*
side nav bar------------------------------------------------------------------ */}

            {/* panel header ---------------------------------------------------------------------------------------------------- */}
            <div className='main-panel '>
              <Navbar expand='lg' color='primary'>
                <Container fluid>
                  <p class='headertxt'>MU Meet Management Application</p>
                  <div className='navbar-wrapper'>
                    <div className='navbar-toggle'>
                      <button
                        type='button'
                        ref={this.sidebarToggle}
                        className='navbar-toggler'
                        onClick={() => this.openSidebar()}
                      >
                        <span className='navbar-toggler-bar bar1' />
                        <span className='navbar-toggler-bar bar2' />
                        <span className='navbar-toggler-bar bar3' />
                      </button>
                    </div>
                  </div>

                  <div id='titleclass' class='titleclass'>
                    <h2>{this.title()}</h2>
                  </div>

                  <NavbarToggler onClick={this.toggle}>
                    <span className='navbar-toggler-bar navbar-kebab'></span>
                    <span className='navbar-toggler-bar navbar-kebab'></span>
                    <span className='navbar-toggler-bar navbar-kebab'></span>
                  </NavbarToggler>

                  <Collapse isOpen={this.state.isOpen} navbar className='justify-content-end'>
                    <Nav navbar>
                      <NavItem>
                        <li className='nav-link'>
                          <i className='now-ui-icons media-2_sound-wave' id='usericon' onClick={this.refreshPage} />
                          <p onClick={this.refreshPage} id='ptag'>
                            Logout
                            <span className='d-lg-none d-md-block'></span>
                          </p>
                        </li>
                      </NavItem>
                      <Dropdown nav isOpen={this.state.dropdownOpen} toggle={(e) => this.dropdownToggle(e)}>
                        <DropdownToggle caret nav>
                          <i className='now-ui-icons users_single-02' id='usericon' />
                          <p>
                            <span className='d-lg-none d-md-block'>Account</span>
                          </p>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem
                            tag='a'
                            onClick={() => this.edituser()}
                            data-toggle='modal'
                            data-target='#useredits'
                          >
                            Edit Profile
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </Nav>
                    <p id='ptag'>User: {this.props.logindata.user.firstname}</p>
                  </Collapse>
                </Container>
              </Navbar>

              {/* panel header ---------------------------------------------------------------------------------------------------- */}
              <div className='wrapper'>
                <Switch>
                  {/* <Route path="/dashboard">
<Dashboard />
</Route> */}
                  <Redirect exact from='/' to='/meets' />
                  <Route exact path='/meets' component={Meetmanager}>
                    <Meetmanager />
                  </Route>
                  <Route exact path='/meetviewmanager' component={Meetviewmanager}>
                    <Meetviewmanager />
                  </Route>
                  <Route exact path='/eventsponser' component={Eventsponser}>
                    <Eventsponser />
                  </Route>
                  <Route exact path='/localcommittee' component={LocalCommit}>
                    <LocalCommit />
                  </Route>
                  <Route exact path='/managervenues' component={ManagerVenue}>
                    <ManagerVenue />
                  </Route>
                  <Route exact path='/athleteregister' component={Atheleteregister}>
                    <Atheleteregister />
                  </Route>
                  <Route exact path='/register' component={Register}>
                    <Register />
                  </Route>
                  <Route exact path='/bibassignment' component={Bibassign}>
                    <Bibassign />
                  </Route>
                  <Route exact path='/details' component={Detailpage}>
                    <Detailpage />
                  </Route>
                  <Route exact path='/viewall' component={Viewall1}>
                    <Viewall1 />
                  </Route>
                  <Route exact path='/athleteresult' component={Atheleteresult}>
                    <Atheleteresult />
                  </Route>
                  <Route exact path='/eventpreference' component={Venueset}>
                    <Venueset />
                  </Route>
                  {/* <Route exact path="/eventpreference" component={Eventset}>
<Eventset />
</Route> */}
                  <Route exact path='/tracksetpreference' component={Trackset}>
                    <Trackset />
                  </Route>
                  <Route exact path='/fieldsetpreference' component={Fieldset}>
                    <Fieldset />
                  </Route>
                  <Route exact path='/archerysetpreference' component={Arcset}>
                    <Arcset />
                  </Route>
                  <Route exact path='/swimsetpreference' component={Swimset}>
                    <Swimset />
                  </Route>
                  <Route exact path='/tabletennissetpreference' component={Tabletennisset}>
                    <Tabletennisset />
                  </Route>
                  <Route exact path='/Weightliftingsetpreference' component={Weightset}>
                    <Weightset />
                  </Route>
                  <Route exact path='/teamsportsetpreference' component={Teamset}>
                    <Teamset />
                  </Route>
                  <Route exact path='/fencingsetpreference' component={Fenceset}>
                    <Fenceset />
                  </Route>
                  <Route exact path='/airgunsetpreference' component={Otherset}>
                    <Otherset />
                  </Route>
                  <Route exact path='/paratriathlonsetpreference' component={Paratriathlonset}>
                    <Paratriathlonset />
                  </Route>
                  <Route exact path='/paratriathlon200setpreference' component={Paratriathlontwoset}>
                    <Paratriathlontwoset />
                  </Route>
                  <Route exact path='/trackregister' component={Regtrack}>
                    <Regtrack />
                  </Route>
                  <Route exact path='/fieldregister' component={Regfield}>
                    <Regfield />
                  </Route>
                  <Route exact path='/swimregister' component={Regswim}>
                    <Regswim />
                  </Route>
                  <Route exact path='/archeryregister' component={Regarchery}>
                    <Regarchery />
                  </Route>
                  <Route exact path='/tabletennisregister' component={Regtennis}>
                    <Regtennis />
                  </Route>
                  <Route exact path='/weightliftregister' component={Regweightlift}>
                    <Regweightlift />
                  </Route>
                  <Route exact path='/teamregister' component={Regteam}>
                    <Regteam />
                  </Route>
                  <Route exact path='/fencingregister' component={Regfencing}>
                    <Regfencing />
                  </Route>
                  <Route exact path='/otherregister' component={Regother}>
                    <Regother />
                  </Route>
                  {/* <Route exact path="/managevenues" component={Manager}>
                    <Manager />
                  </Route> */}
                  <Route exact path='/trackeventregister' component={Regitrack}>
                    <Regitrack />
                  </Route>
                  <Route exact path='/fieldeventregister' component={Regifield}>
                    <Regifield />
                  </Route>
                  <Route exact path='/archeryeventregister' component={Regiarchery}>
                    <Regiarchery />
                  </Route>
                  <Route exact path='/swimeventregister' component={Regiswim}>
                    <Regiswim />
                  </Route>
                  <Route exact path='/tenniseventregister' component={Regitennis}>
                    <Regitennis />
                  </Route>
                  <Route exact path='/weightlifteventregister' component={Regiweightlift}>
                    <Regiweightlift />
                  </Route>
                  <Route exact path='/teameventregister' component={Regiteam}>
                    <Regiteam />
                  </Route>
                  <Route exact path='/fenceeventregister' component={Regifence}>
                    <Regifence />
                  </Route>
                  <Route exact path='/othereventregister' component={Regiother}>
                    <Regiother />
                  </Route>
                  <Route exact path='/athletevenues' component={Athletevenues}>
                    <Athletevenues />
                  </Route>
                  <Route exact path='/Athletetrack' component={Athletetrack}>
                    <Athletetrack />
                  </Route>
                  <Route exact path='/Athletefield' component={Athletefield}>
                    <Athletefield />
                  </Route>
                  <Route exact path='/Athletearchery' component={Athletearc}>
                    <Athletearc />
                  </Route>
                  <Route exact path='/Athleteswimming' component={Athleteswim}>
                    <Athleteswim />
                  </Route>
                  <Route exact path='/AthleteWeightlifting' component={Athleteweight}>
                    <Athleteweight />
                  </Route>
                  <Route exact path='/Athletetennis' component={Athletetennis}>
                    <Athletetennis />
                  </Route>
                  <Route exact path='/Athleteteamsports' component={Athleteteamsport}>
                    <Athleteteamsport />
                  </Route>
                  <Route exact path='/Athletefencing' component={Athletefence}>
                    <Athletefence />
                  </Route>
                  <Route exact path='/AthleteOthers' component={Athleteother}>
                    <Athleteother />
                  </Route>
                  <Route exact path='/venuesetups' component={Venuesetups}>
                    <Venuesetups />
                  </Route>
                  <Route exact path='/venuemainpage' component={Venue_Mainpage}>
                    <Venue_Mainpage />
                  </Route>
                  <Route exact path='/Trackworksheet' component={Trackworksheet}>
                    <Trackworksheet />
                  </Route>
                  <Route exact path='/Trackfinalworksheet' component={Trackfinalworksheet}>
                    <Trackfinalworksheet />
                  </Route>
                  <Route exact path='/Trackfinalresult' component={Trackfinalresult}>
                    <Trackfinalresult />
                  </Route>
                  <Route exact path='/Trackresultworksheet' component={Trackresultworksheet}>
                    <Trackresultworksheet />
                  </Route>
                  <Route exact path='/field_pit_assignment' component={Fieldpitassign}>
                    <Fieldpitassign />
                  </Route>
                  <Route exact path='/Display_all_pits' component={DispalyallFieldresultentry}>
                    <DispalyallFieldresultentry />
                  </Route>

                  <Route exact path='/relaysetup' component={Relaysetup}>
                    <Relaysetup />
                  </Route>
                  <Route exact path='/preliminary_track_results' component={PreliminaryTrackresult}>
                    <PreliminaryTrackresult />
                  </Route>
                  <Route exact path='/special_heat' component={Specialheat}>
                    <Specialheat />
                  </Route>
                  <Route exact path='/field_results' component={Fieldresultentry}>
                    <Fieldresultentry />
                  </Route>
                  <Route exact path='/field_show_results' component={Fieldshowresult}>
                    <Fieldshowresult />
                  </Route>
                  <Route exact path='/implement_file' component={Implementfile}>
                    <Implementfile />
                  </Route>
                  <Route exact path='/Prelim_heatsetup' component={Swimprelimheatsetup}>
                    <Swimprelimheatsetup />
                  </Route>

                  <Route exact path='/final_heatsetup' component={Swimfinalheatsetup}>
                    <Swimfinalheatsetup />
                  </Route>
                  <Route exact path='/Prelim_results' component={Swimprelimresult}>
                    <Swimprelimresult />
                  </Route>
                  <Route exact path='/final_results' component={Swimfinalresult}>
                    <Swimfinalresult />
                  </Route>
                  <Route exact path='/relay_initial_setup' component={Swimrelaysetup}>
                    <Swimrelaysetup />
                  </Route>
                  <Route exact path='/show_result_worksheet' component={Swimresultworksheet}>
                    <Swimresultworksheet />
                  </Route>
                  <Route exact path='/assignpits' component={Assignpits}>
                    <Assignpits />
                  </Route>

                  <Route exact path='/archerytargetassignment' component={Archerypitassign}>
                    <Archerypitassign />
                  </Route>
                  <Route exact path='/archerytargetresult' component={Archerytargetresult}>
                    <Archerytargetresult />
                  </Route>
                  <Route exact path='/archeryclassresult' component={Archeryclassresult}>
                    <Archeryclassresult />
                  </Route>
                  <Route exact path='/archeryworksheet' component={Archeryworksheet}>
                    <Archeryworksheet />
                  </Route>
                  <Route exact path='/assigntarget' component={Archeryassigntarget}>
                    <Archeryassigntarget />
                  </Route>
                  <Route exact path='/Assignbench' component={Assignbench}>
                    <Assignbench />
                  </Route>
                  <Route exact path='/benchassignment' component={Benchassign}>
                    <Benchassign />
                  </Route>
                  <Route exact path='/weightliftbenchresult' component={Weightliftbenchresult}>
                    <Weightliftbenchresult />
                  </Route>
                  <Route exact path='/weightliftbenchclass' component={Weightliftbenchclass}>
                    <Weightliftbenchclass />
                  </Route>
                  <Route exact path='/weightliftworksheet' component={Weightliftworksheet}>
                    <Weightliftworksheet />
                  </Route>
                  <Route exact path='/tennisgroupentry' component={Tennisgroupentry}>
                    <Tennisgroupentry />
                  </Route>
                  <Route exact path='/tennisclassentry' component={Tennisclassentry}>
                    <Tennisclassentry />
                  </Route>
                  <Route exact path='/tennisworksheet' component={Tennisworksheet}>
                    <Tennisworksheet />
                  </Route>
                  <Route exact path='/tennisassigngroups' component={Tennisassigngroup}>
                    <Tennisassigngroup />
                  </Route>
                  <Route exact path='/tennis_setupopponents' component={Tennissetupopponents}>
                    <Tennissetupopponents />
                  </Route>
                  <Route exact path='/tennis_assigngroup_double' component={Tennisassigngroupdoubles}>
                    <Tennisassigngroupdoubles />
                  </Route>
                  <Route exact path='/tennis_setup_opponents_double' component={Tennissetupdoubles}>
                    <Tennissetupdoubles />
                  </Route>
                  <Route exact path='/fence_group_entry' component={Fencegroupentry}>
                    <Fencegroupentry />
                  </Route>
                  <Route exact path='/fence_class_entry' component={Fenceclassentry}>
                    <Fenceclassentry />
                  </Route>
                  <Route exact path='/fence_Worksheet' component={Fenceworksheet}>
                    <Fenceworksheet />
                  </Route>
                  <Route exact path='/fence_assign_competitor_groups' component={Fenceassigngroups}>
                    <Fenceassigngroups />
                  </Route>
                  <Route exact path='/fence_setup_Opponents' component={Fencesetupopponents}>
                    <Fencesetupopponents />
                  </Route>
                  <Route exact path='/fence_select_and_setup_finalists' component={Fencesetupfinalists}>
                    <Fencesetupfinalists />
                  </Route>
                  <Route exact path='/otherspitassignment' component={Otherassign}>
                    <Otherassign />
                  </Route>
                  <Route exact path='/result_Entry_by_pit' component={Airgunpitentry}>
                    <Airgunpitentry />
                  </Route>
                  <Route exact path='/result_Entry_by_class' component={Airgunclassentry}>
                    <Airgunclassentry />
                  </Route>
                  <Route exact path='/show_results_worksheet' component={Airgunworksheet}>
                    <Airgunworksheet />
                  </Route>
                  <Route exact path='/cycling_worksheet' component={CyclingWorksheet}>
                    <CyclingWorksheet />
                  </Route>
                  <Route exact path='/airrifle_worksheet' component={AirRifleworksheet}>
                    <AirRifleworksheet />
                  </Route>
                  <Route exact path='/boccia_worksheet' component={Bocciaworksheet}>
                    <Bocciaworksheet />
                  </Route>
                  <Route exact path='/paratriathlon100_worksheet' component={Paratriathlon100worksheet}>
                    <Paratriathlon100worksheet />
                  </Route>
                  <Route exact path='/paratriathlon100_heatsheet' component={Paratriathlon100heatsheet}>
                    <Paratriathlon100heatsheet />
                  </Route>
                  <Route exact path='/paratriathlon200_worksheet' component={Paratriathlon200worksheet}>
                    <Paratriathlon200worksheet />
                  </Route>
                  <Route exact path='/paratriathlon200_heatsheet' component={Paratriathlon200heatsheet}>
                    <Paratriathlon200heatsheet />
                  </Route>
                  <Route exact path='/paratriathlon500_worksheet' component={Paratriathlon500worksheet}>
                    <Paratriathlon500worksheet />
                  </Route>
                  <Route exact path='/paratriathlon500_heatsheet' component={Paratriathlon500heatsheet}>
                    <Paratriathlon500heatsheet />
                  </Route>
                  <Route exact path='/airrifle_result_heatsheet' component={AirRifleheatsheet}>
                    <AirRifleheatsheet />
                  </Route>
                  <Route exact path='/boccia_result_heatsheet' component={Bocciaheatsheet}>
                    <Bocciaheatsheet />
                  </Route>
                  <Route exact path='/cycling_result_heatsheet' component={Cyclingheatsheet}>
                    <Cyclingheatsheet />
                  </Route>
                  <Route exact path='/fence_result_heatsheet' component={Fenceheatsheet}>
                    <Fenceheatsheet />
                  </Route>
                  <Route exact path='/athletesearch' component={Registeredsearch}>
                    <Registeredsearch />
                  </Route>
                  <Route exact path='/tennisprint' component={Tennisprint}>
                    <Tennisprint />
                  </Route>
                  <Route exact path='/teamsportsworksheet' component={Teamsportsworksheet}>
                    <Teamsportsworksheet />
                  </Route>
                  <Route exact path='/teamsportswheatsheet' component={Teamsportsheatsheet}>
                    <Teamsportsheatsheet />
                  </Route>
                  <Route exact path='/Teamsportsinitialsetup' component={Teamsportsinitialsetup}>
                    <Teamsportsinitialsetup />
                  </Route>
                </Switch>
              </div>
            </div>

            {/* edit user model ----------------------------------------------------------------------------------------- */}
            <div id='useredits' class='modal fade' tabindex='-1' role='dialog' aria-hidden='true'>
              <div class='modal-dialog  modal-lg' role='document'>
                <div class='modal-content'>
                  <div class='modal-header'>
                    <h5 class='modal-title' id='registermodalLabel'>
                      Edit your Profile
                    </h5>
                    <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                      <span aria-hidden='true'>&times;</span>
                    </button>
                  </div>
                  <div class='modal-body'>
                    <form onSubmit={this.submitregister}>
                      <div class='form-row'>
                        <div class='form-group col-md-6'>
                          <label for='inputEmail4'>FirstName</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputEmail4'
                            placeholder='firstname'
                            name='firstname'
                            value={this.state.firstname}
                            onChange={(e) => this.setState({ firstname: e.target.value })}
                            required
                          />
                        </div>
                        <div class='form-group col-md-6'>
                          <label for='inputPassword4'>LastName</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputPassword4'
                            placeholder='lastname'
                            value={this.state.lastname}
                            onChange={(e) => this.setState({ lastname: e.target.value })}
                            required
                          />
                        </div>
                      </div>

                      <div class='form-row'>
                        <div class='form-group col-md-6'>
                          <label for='inputCity'>Email</label>
                          <input
                            type='email'
                            class='form-control'
                            id='inputCity'
                            placeholder='email'
                            value={this.state.email}
                            onChange={(e) => this.setState({ email: e.target.value })}
                            required
                          />
                        </div>

                        <div class='form-group col-md-6'>
                          <label for='inputZip'>Change your Password</label>
                          <input
                            type='password'
                            class='form-control'
                            id='inputZip'
                            placeholder='password'
                            value={this.state.pwd}
                            onChange={(e) => this.setState({ pwd: e.target.value })}
                            required
                          />
                        </div>
                        <div class='form-group col-md-6'>
                          <label for='inputZip'>Confirm changed Password</label>
                          <input
                            type='password'
                            class='form-control'
                            id='inputZip'
                            placeholder='confirm_password'
                            onChange={(e) => this.setState({ cpwd: e.target.value })}
                            required
                          />
                        </div>
                        <div class='form-group col-md-6'>
                          <label for='inputCity'>Organization</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputCity'
                            name='dob'
                            value={this.state.teamname}
                            onChange={(e) => this.setState({ teamname: e.target.value })}
                          />
                        </div>
                        {/* <div class="form-group col-md-6">
                          <label for="inputState">Gender</label>
                          <select
                            id="inputState"
                            class="form-control"
                            name="gender"
                            value={this.state.gender}
                            onChange={this.handlechange}
                          >
                            <option selected>Choose...</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>
                        </div> */}
                      </div>
                      <div class='form-row'>
                        {/* <div class="form-group col-md-6">
                          <label for="inputCity">DOB</label>
                          <input
                            type="date"
                            class="form-control"
                            id="inputCity"
                            name="dob"
                            value={this.state.dob}
                            onChange={(e) =>
                              this.setState({ dob: e.target.value })
                            }
                            required
                          />
                        </div> */}

                        <div class='form-group col-md-6'>
                          <label for='inputZip'>Cellphone</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputZip'
                            name='cellphone'
                            value={this.state.cellphone}
                            placeholder='Enter cellphone'
                            onChange={(e) => this.setState({ cellphone: e.target.value })}
                          />
                        </div>
                      </div>
                      <center>
                        <button type='submit' class='btn btn-danger' onClick={this.updateuser} data-dismiss='modal'>
                          Update
                        </button>
                      </center>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Router>
        </div>
      );
    }

    // athlete view------------------------------------------------------------------------------------
    if (this.props.logindata.user.role_id === 3) {
      return (
        <div>
          {/* <Router  basename="/samsreact/"> */}
          <Router>
            <div className='sidebar' data-color='red'>
              <div className='sidebar-wrapper' ref='sidebar'>
                <div className='logo'>
                  <a href='#'>
                    <div class='sidenavimg'>
                      <div class='row'>
                        <div class='column'>
                          <img src={logo} width='70' height='40'></img>
                          <img src={adaptive} width='70' height='40'></img>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <Nav>
                  {/* <li className={this.state.active0} onClick={() => this.active(0)}>
<Link to="/dashboard"><i className="now-ui-icons design_app"></i>Dashboard</Link>

</li> */}

                  <li className={this.state.active1} onClick={() => this.active(1)}>
                    <Link to='/atheletehome'>
                      <i className='now-ui-icons design_image'></i>Meets Register
                    </Link>
                  </li>
                  {/* <li className={this.state.active2} onClick={() => this.active(2)}>
<Link to="/regstatus" ><i className="now-ui-icons business_badge"></i>Registration status</Link>

</li> */}
                </Nav>
              </div>
            </div>

            {/*
side nav bar------------------------------------------------------------------ */}

            {/* panel header ---------------------------------------------------------------------------------------------------- */}
            <div className='main-panel'>
              <Navbar expand='lg' color='primary'>
                <Container fluid>
                  <p class='headertxt'>SAMS Meet Management Application</p>
                  <div className='navbar-wrapper'>
                    <div className='navbar-toggle'>
                      <button
                        type='button'
                        ref={this.sidebarToggle}
                        className='navbar-toggler'
                        onClick={() => this.openSidebar()}
                      >
                        <span className='navbar-toggler-bar bar1' />
                        <span className='navbar-toggler-bar bar2' />
                        <span className='navbar-toggler-bar bar3' />
                      </button>
                    </div>
                  </div>

                  <NavbarToggler onClick={this.toggle}>
                    <span className='navbar-toggler-bar navbar-kebab'></span>
                    <span className='navbar-toggler-bar navbar-kebab'></span>
                    <span className='navbar-toggler-bar navbar-kebab'></span>
                  </NavbarToggler>

                  <Collapse isOpen={this.state.isOpen} navbar className='justify-content-end'>
                    <Nav navbar>
                      <NavItem>
                        <li className='nav-link'>
                          <i className='now-ui-icons media-2_sound-wave' id='usericon' onClick={this.refreshPage} />
                          <p onClick={this.refreshPage} id='ptag'>
                            Logout
                            <span className='d-lg-none d-md-block'></span>
                          </p>
                        </li>
                      </NavItem>
                      <Dropdown nav isOpen={this.state.dropdownOpen} toggle={(e) => this.dropdownToggle(e)}>
                        <DropdownToggle caret nav>
                          <i className='now-ui-icons users_single-02' id='usericon' />
                          <p>
                            <span className='d-lg-none d-md-block'>Account</span>
                          </p>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem
                            tag='a'
                            onClick={() => this.edituser()}
                            data-toggle='modal'
                            data-target='#useredits'
                          >
                            Edit Profile
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </Nav>
                    <p id='ptag'>User: {this.props.logindata.user.firstname}</p>
                  </Collapse>
                </Container>
              </Navbar>

              {/* panel header ---------------------------------------------------------------------------------------------------- */}
              <div className='wrapper'>
                <Switch>
                  {/* <Route path="/dashboard">
<Dashboard />
</Route> */}
                  <Redirect exact from='/' to='/atheletehome' />
                  <Route exact path='/atheletehome' component={AtheletHome}>
                    <AtheletHome logindata={this.props.logindata} />
                  </Route>

                  {/* <Route exact path="/regstatus">
<Rstatus logindata={this.props.logindata} />
</Route> */}
                </Switch>
              </div>

              <div id='useredits' class='modal fade' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog  modal-lg' role='document'>
                  <div class='modal-content'>
                    <div class='modal-header'>
                      <h5 class='modal-title' id='registermodalLabel'>
                        Edit your Profile
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form onSubmit={this.submitregister}>
                        <div class='form-row'>
                          <div class='form-group col-md-6'>
                            <label for='inputEmail4'>FirstName</label>
                            <input
                              type='text'
                              class='form-control'
                              id='inputEmail4'
                              placeholder='firstname'
                              name='firstname'
                              value={this.state.firstname}
                              onChange={(e) => this.setState({ firstname: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputPassword4'>LastName</label>
                            <input
                              type='text'
                              class='form-control'
                              id='inputPassword4'
                              placeholder='lastname'
                              value={this.state.lastname}
                              onChange={(e) => this.setState({ lastname: e.target.value })}
                              required
                            />
                          </div>
                        </div>

                        <div class='form-row'>
                          <div class='form-group col-md-4'>
                            <label for='inputCity'>Email</label>
                            <input
                              type='email'
                              class='form-control'
                              id='inputCity'
                              placeholder='email'
                              value={this.state.email}
                              onChange={(e) => this.setState({ email: e.target.value })}
                              required
                            />
                          </div>

                          <div class='form-group col-md-4'>
                            <label for='inputZip'>Change your Password</label>
                            <input
                              type='password'
                              class='form-control'
                              id='inputZip'
                              placeholder='password'
                              onChange={(e) => this.setState({ pwd: e.target.value })}
                              required
                            />
                          </div>
                        </div>
                        <div class='form-row'>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>DOB</label>
                            <input
                              type='date'
                              class='form-control'
                              id='inputCity'
                              name='dob'
                              value={this.state.dob}
                              onChange={(e) => this.setState({ dob: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputState'>Gender</label>
                            <select
                              id='inputState'
                              class='form-control'
                              name='gender'
                              value={this.state.gender}
                              onChange={this.handlechange}
                            >
                              <option selected>Choose...</option>
                              <option value='male'>Male</option>
                              <option value='female'>Female</option>
                            </select>
                          </div>
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Address</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='address'
                            value={this.state.address}
                            placeholder='1234 Main St'
                            onChange={(e) => this.setState({ address: e.target.value })}
                            required
                          />
                        </div>
                        <div class='form-group'>
                          <label for='inputAddress2'>Address 2</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress2'
                            name='address2'
                            value={this.state.address2}
                            placeholder='Apartment, studio, or floor'
                            onChange={(e) => this.setState({ address2: e.target.value })}
                            required
                          />
                        </div>

                        <div class='form-row'>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>City</label>
                            <input
                              type='text'
                              class='form-control'
                              id='inputCity'
                              name='city'
                              value={this.state.city}
                              placeholder='Enter city'
                              onChange={(e) => this.setState({ city: e.target.value })}
                              required
                            />
                          </div>

                          <div class='form-group col-md-6'>
                            <label for='inputZip'>State/Province</label>
                            <input
                              type='text'
                              class='form-control'
                              id='inputZip'
                              placeholder='Enter State'
                              onChange={(e) => this.setState({ state: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputZip'>Zip/Mail code</label>
                            <input
                              type='text'
                              class='form-control'
                              id='inputZip'
                              name='zipcode'
                              value={this.state.zipcode}
                              placeholder='Enter Zip code'
                              onChange={(e) => this.setState({ zipcode: e.target.value })}
                              required
                            />
                          </div>
                        </div>
                        <div class='form-row'>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Country</label>
                            <input
                              type='text'
                              class='form-control'
                              name='country'
                              value={this.state.country}
                              placeholder='Enter country'
                              onChange={(e) => this.setState({ country: e.target.value })}
                              required
                            />
                          </div>

                          <div class='form-group col-md-6'>
                            <label for='inputZip'>Homephone</label>
                            <input
                              type='text'
                              class='form-control'
                              id='inputZip'
                              name='homephone'
                              value={this.state.homephone}
                              placeholder='Enter homephone'
                              onChange={(e) => this.setState({ homephone: e.target.value })}
                              required
                            />
                          </div>
                          <div class='form-group col-md-4'>
                            <label for='inputZip'>Cellphone</label>
                            <input
                              type='text'
                              class='form-control'
                              id='inputZip'
                              name='cellphone'
                              value={this.state.cellphone}
                              placeholder='Enter cellphone'
                              onChange={(e) => this.setState({ cellphone: e.target.value })}
                              required
                            />
                          </div>
                        </div>
                        <center>
                          <button type='submit' class='btn btn-danger' onClick={this.updateuser} data-dismiss='modal'>
                            Update
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* edit user model ----------------------------------------------------------------------------------------- */}
          </Router>
        </div>
      );
    }
  }
}

export default Home;
