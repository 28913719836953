import React from 'react';

import '../App.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import 'datatables.net';
import 'datatables.net-bs4';
import Loader from 'react-loader-spinner';
import Sorttransfer from './transfer';
const url = window.globalConfig;
class Bibassign extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      meet_data: [{}],
      assign_for: '0',
      inputvalue: '',
      user_data: [],
      finalSortOrderArray: [],
      loader: true,
      test: false,
      lastbibvalue: '',
    };
    this.setvalue = this.setvalue.bind(this);
    this.inputvalue = this.inputvalue.bind(this);
    this.assignbib = this.assignbib.bind(this);
    this.back = this.back.bind(this);
    this.close = this.close.bind(this);
  }

  async componentDidMount() {
    var self = this;
    let j = sessionStorage.getItem('ids');
    await axios
      .get(`${url.url}/api/getmeetdetail/` + j, {})
      .then(function (response) {
        self.setState({
          meet_data: response.data,
          loader: false,
        });
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
    axios
      .get(`${url.url}/api/notassignforall/`, {})
      .then(function (response) {
        self.setState({
          lastbibvalue: response.data,
        });
      })
      .catch(function (error) {});
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
  }
  async setvalue(e) {
    this.setState({
      assign_for: e,
    });
    var self = this;
    let j = sessionStorage.getItem('ids');
    await axios.get(`${url.url}/api/getcreateregisters/${j}`, {}).then(function (response) {
      self.setState({
        user_data: response.data.data,
      });
    });
  }
  inputvalue(e) {
    this.setState({
      inputvalue: e,
    });
  }
  assignbib(e) {
    e.preventDefault();
    let j = sessionStorage.getItem('ids');
    switch (this.state.assign_for) {
      case '0': {
        const data = { meet_id: j };
        axios
          .put(`${url.url}/api/athletabibupdate/${parseInt(this.state.inputvalue)}`, data, {})
          .then(function (response) {
            if (response.status === 200) {
              Swal.fire({
                icon: 'success',
                title: 'Status Updated successfully',
                showConfirmButton: false,
                timer: 1000,
              }).then(function (response) {
                window.location.reload();
              });
            }
          });

        return null;
      }
      case '1': {
        const data = { meet_id: j };
        let bibno = this.state.lastbibvalue + 1;
        axios
          .put(`${url.url}/api/notassignupdatebib/${parseInt(this.state.inputvalue)}`, data, {})
          .then(function (response) {
            if (response.status === 200) {
              Swal.fire({
                icon: 'success',
                title: 'Status Updated successfully',
                showConfirmButton: false,
                timer: 1000,
              }).then(function (response) {
                window.location.reload();
              });
            }
          });
        return null;
      }
      case '2': {
        const data = {
          meet_id: j,
        };
        axios
          .put(`${url.url}/api/updatejuniorsbib/${parseInt(this.state.inputvalue)}`, data, {})
          .then(function (response) {
            if (response.status === 200) {
              Swal.fire({
                icon: 'success',
                title: 'Status Updated successfully',
                showConfirmButton: false,
                timer: 1000,
              }).then(function (response) {
                window.location.reload();
              });
            }
          });
        return null;
      }
      default:
        return null;
    }
  }
  update = (finalSortOrderArray) => {
    this.setState({
      finalSortOrderArray,
    });
    this.close();
  };
  close() {
    document.getElementById('useredit').style.display = 'none';
  }
  back() {
    window.location.replace('/meets');
    sessionStorage.removeItem('curnavitem');
  }

  render() {
    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div>
              <div className='content bibassign'>
                <Row>
                  <Col md='8'>
                    <Card>
                      <CardHeader>
                        <h5 className='title'>Bib Assignments</h5>
                      </CardHeader>
                      <CardBody>
                        <Form>
                          <div class='form-group row'>
                            <div class='col-xs-5'>
                              <CardHeader>
                                <form onSubmit={this.assignbib}>
                                  <p>
                                    <label>Bib Starting Number</label>
                                    <input
                                      type='text'
                                      onChange={(e) => this.inputvalue(e.target.value)}
                                      required={this.state.assign_for === '1' ? false : true}
                                    ></input>
                                  </p>

                                  <div class='bib'>
                                    <div>
                                      <label>Select Which Athletes</label>
                                      <input
                                        type='radio'
                                        name='select'
                                        value='0'
                                        onChange={(e) => this.setvalue(e.target.value)}
                                        checked={this.state.assign_for === '0'}
                                      ></input>
                                      All
                                      <input
                                        type='radio'
                                        name='select'
                                        value='2'
                                        /*data-toggle='modal'
                                        data-target='#useredit' */
                                        onChange={(e) => this.setvalue(e.target.value)}
                                        checked={this.state.assign_for === '2'}
                                      ></input>
                                      Juniors Only
                                      <input
                                        type='radio'
                                        name='select'
                                        value='1'
                                        onChange={(e) => this.setvalue(e.target.value)}
                                        checked={this.state.assign_for === '1'}
                                      ></input>
                                      All not assigned
                                    </div>
                                  </div>
                                  <p>
                                    <button class='btn btn-success'>Assign</button>
                                  </p>
                                </form>
                              </CardHeader>
                            </div>
                          </div>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
              <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Sort Records
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={this.close}>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <Sorttransfer update={this.update} />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Bibassign;
