import React, { useCallback } from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';

import Swal from 'sweetalert2';
import e from 'cors';
const url = window.globalConfig;

class Paratriathlontwoset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paratwosetdata: [{}],
      paratwosets: '',
      venuenamedata: '',
      venuelocationdata: '',
      numberdata: '',
      currentdata: '',
      perrecorddata: '',
      pp1data: '',
      pp2data: '',
      pp3data: '',
      pp4data: '',
      eventdata: '',
      typedata: '',
      teamdata: '',
    };

    this.createparatwoset = this.createparatwoset.bind(this);
    this.editparatwoset = this.editparatwoset.bind(this);
    this.optionchange = this.optionchange.bind(this);
    this.dropdownchange1 = this.dropdownchange1.bind(this);
    this.dropdownchange2 = this.dropdownchange2.bind(this);
    this.dropdownchange3 = this.dropdownchange3.bind(this);
    this.deleteparatwoset = this.deleteparatwoset.bind(this);
    this.updateparatwoset = this.updateparatwoset.bind(this);
    this.optionchange1 = this.optionchange1.bind(this);
    this.optionchange2 = this.optionchange2.bind(this);
    this.optionchange3 = this.optionchange3.bind(this);
    this.optionchange4 = this.optionchange4.bind(this);
    this.optionchange5 = this.optionchange5.bind(this);
    this.optionchange6 = this.optionchange6.bind(this);
    this.optionchange7 = this.optionchange7.bind(this);
    this.optionchange8 = this.optionchange8.bind(this);
    this.back = this.back.bind(this);
  }

  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    var self = this;
    //Track set preference
    axios.get(`${url.url}/api/getallparatriathlononesetrecords`, {}).then(function (response) {
      self.setState({
        paratwosetdata: response.data,
      });
    });
  }

  //dropdown values

  dropdownchange1(e) {
    this.setState({
      teamdata: e.target.value,
    });
    this.setState({
      selectval1: e.target.value,
    });
  }

  dropdownchange2(e) {
    this.setState({
      typedata: e.target.value,
    });
    this.setState({
      selectval2: e.target.value,
    });
  }

  dropdownchange3(e) {
    this.setState({
      perrecorddata: e.target.value,
    });
    this.setState({
      selectval3: e.target.value,
    });
  }

  optionchange(e) {
    this.setState({
      eventdata: e.target.value,
    });
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  optionchange1(e) {
    this.setState({
      venuenamedata: e.target.value,
    });
  }

  optionchange2(e) {
    this.setState({
      venuelocationdata: e.target.value,
    });
  }

  optionchange3(e) {
    this.setState({
      numberdata: e.target.value,
    });
  }

  optionchange4(e) {
    this.setState({
      currentdata: e.target.value,
    });
  }

  optionchange5(e) {
    this.setState({
      pp1data: e.target.value,
    });
  }

  optionchange6(e) {
    this.setState({
      pp2data: e.taget.value,
    });
  }

  optionchange7(e) {
    this.setState({
      pp3data: e.target.value,
    });
  }

  optionchange8(e) {
    this.setState({
      pp4data: e.target.value,
    });
  }

  //Edit trackset
  editparatwoset(e) {
    var self = this;

    self.setState({
      paratwosets: this.state.paratwosetdata[e],
      venuenamedata: this.state.paratwosetdata[e].venue_name,
      venuelocationdata: this.state.paratwosetdata[e].venue_location,
      numberdata: this.state.paratwosetdata[e].number_of_events,
      currentdata: this.state.paratwosetdata[e].current_record,
      perrecorddata: this.state.paratwosetdata[e].performance_record,
      pp1data: this.state.paratwosetdata[e].pp1,
      pp2data: this.state.paratwosetdata[e].pp2,
      pp3data: this.state.paratwosetdata[e].pp3,
      pp4data: this.state.paratwosetdata[e].pp4,
      eventdata: this.state.paratwosetdata[e].event_name,
      typedata: this.state.paratwosetdata[e].type_of_record,
      teamdata: this.state.paratwosetdata[e].team_scoring,
    });
  }

  //Create Track set
  createparatwoset() {
    const datatosend = {
      venue_name: this.state.venuename,
      venue_location: this.state.venuelocation,
      number_of_events: this.state.numevents,
      current_record: this.state.currentrecord,
      performance_record: this.state.selectval3,
      pp1: this.state.p1,
      pp2: this.state.p2,
      pp3: this.state.p3,
      pp4: this.state.p4,
      event_name: this.state.eventname,
      type_of_record: this.state.selectval2,
      team_scoring: this.state.selectval1,
      created_by: 1,
    };
    axios
      .post(`${url.url}/api/createparatriathlononesetrecords`, datatosend, {})
      .then(function (response) {
        if (response.status == 201) {
          Swal.fire({
            title: 'Success',
            text: 'Paratriathlon 200 Created successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  //Update trackset
  updateparatwoset(e) {
    var self = this;
    e.preventDefault();
    const datatosend = {
      venue_name: this.state.venuenamedata,
      venue_location: this.state.venuelocationdata,
      number_of_events: this.state.numberdata,
      current_record: this.state.currentdata,
      performance_record: this.state.perrecorddata,
      pp1: this.state.pp1data,
      pp2: this.state.pp2data,
      pp3: this.state.pp3data,
      pp4: this.state.pp4data,
      event_name: this.state.eventdata,
      type_of_record: this.state.typedata,
      team_scoring: this.state.selectval1,
    };
    axios
      .put(`${url.url}/api/updateparatriathlononesetrecords/${this.state.paratwosets.id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            title: 'Success',
            text: 'Paratriathlon 200 updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  //Delete Trackset
  deleteparatwoset(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deleteparatriathlononesetrecords/${this.state.paratwosetdata[e].id}`, {})
          .then(function (response) {})

          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });
  }

  //back Button
  back() {
    window.location.replace('/airgunsetpreference');
  }

  render() {
    return (
      <>
        {/* AirGun set Preferences*/}
        <div className='content'>
          <Row>
            <Col md='8'>
              <button type='button' data-toggle='modal' data-target='#newmeet' class='btn btn-danger btn-round'>
                New Paratriathlon 200 set
              </button>
              <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                Back
              </button>
              {''}
              <Card>
                <CardHeader>
                  <h5 className='title'>Paratriathlon200 Set Preferences</h5>
                </CardHeader>

                <CardBody>
                  <Form>
                    <div class='form-group row'>
                      <div class='col-xs-5'>
                        <CardHeader>
                          <p>Search</p>
                          <input class='form-control' id='inputsm' placeholder='Search..' type='text' />
                        </CardHeader>
                      </div>
                    </div>

                    {/* table --------------------------------------------------- */}
                    <Table id='my' responsive>
                      <thead>
                        <tr>
                          <th className='text-center'>#</th>
                          <th>Venue Name</th>
                          <th>Venue location</th>
                          <th>Number of Events</th>
                          <th>Current Record Level</th>
                          <th>Select Performance Record</th>
                          <th>1</th>
                          <th>2</th>
                          <th>3</th>
                          <th>4</th>
                          <th>Event name</th>
                          <th>Type of Record</th>
                          <th>Team Scoring</th>

                          <th class='disabled-sorting text-right'>Actions</th>
                        </tr>
                      </thead>
                      <tbody id='myTable'>
                        {this.state.paratwosetdata.map((question, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{question.venue_name}</td>
                            <td>{question.venue_location}</td>
                            <td>{question.number_of_events}</td>
                            <td>{question.current_record}</td>
                            <td>{question.performance_record}</td>
                            <td>{question.pp1}</td>
                            <td>{question.pp2}</td>
                            <td>{question.pp3}</td>
                            <td>{question.pp4}</td>
                            <td>{question.event_name}</td>
                            <td>{question.type_of_record}</td>
                            <td>{question.team_scoring}</td>

                            <td class='td-actions text-right'>
                              <button
                                type='button'
                                rel='tooltip'
                                class='btn btn-success btn-sm btn-icon'
                                onClick={() => this.editparatwoset(index)}
                                data-toggle='modal'
                                data-target='#meetedit'
                              >
                                <i class='now-ui-icons ui-2_settings-90'></i>
                              </button>
                              {` `}
                              <button
                                type='button'
                                rel='tooltip'
                                class='btn btn-danger btn-sm btn-icon'
                                onClick={() => this.deleteparatwoset(index)}
                              >
                                <i class='now-ui-icons ui-1_simple-remove'></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    {/* table --------------------------------------------------- */}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* edit meet modal ----------------------------------------------------------------------------------------------------------------- */}
          <div class='modal fade' id='meetedit' tabindex='-1' role='dialog' aria-hidden='true'>
            <div class='modal-dialog'>
              <div class='modal-content'>
                <div class='modal-header '>
                  <h5 class='modal-title ' id='loginmodalLabel'>
                    Edit Paratriathlon200 Set{' '}
                  </h5>
                  <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <div class='modal-body'>
                  <form>
                    <div class='form-group'>
                      <label for='inputAddress'>Venue Name</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputAddress'
                        name='venuename'
                        value={this.state.venuenamedata}
                        onChange={this.optionchange1}
                        placeholder='Enter venue name'
                        required
                      />
                    </div>

                    <div class='form-group'>
                      <label for='inputAddress'>Venue location</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputAddress'
                        name='venuelocation'
                        value={this.state.venuelocationdata}
                        onChange={this.optionchange2}
                        placeholder='Enter Venue location'
                        required
                      />
                    </div>

                    <div class='form-group'>
                      <label for='inputAddress'>Number of events</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputAddress'
                        name='numevents'
                        value={this.state.numberdata}
                        onChange={this.optionchange3}
                        placeholder='Enter Number of events'
                        required
                      />
                    </div>

                    <div class='form-group'>
                      <label for='inputAddress'>Current Record Level</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputAddress'
                        name='currecord'
                        value={this.state.currentdata}
                        onChange={this.optionchange4}
                        placeholder='Enter current record'
                        required
                      />
                    </div>

                    <div class='form-group'>
                      <label for='inputAddress'>Select Performance Record</label>
                      <select
                        class='form-control'
                        id='exampleFormControlSelect1'
                        value={this.state.perrecorddata}
                        onChange={this.dropdownchange3}
                        required
                      >
                        <option>Please select</option>
                        <option value='1'>1</option>
                        <option value='2'>2</option>
                        <option value='3'>3</option>
                        <option value='4'>4</option>
                      </select>
                    </div>

                    <div class='form-group'>
                      <label for='inputAddress'>1</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputAddress'
                        placeholder='1234 Main St'
                        name='p1'
                        value={this.state.pp1data}
                        onChange={this.optionchange5}
                        placeholder='Enter 1'
                        required
                      />
                    </div>

                    <div class='form-group'>
                      <label for='inputAddress'>2</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputAddress'
                        placeholder='1234 Main St'
                        name='p2'
                        value={this.state.pp2data}
                        onChange={this.optionchange6}
                        placeholder='Enter 2'
                        required
                      />
                    </div>

                    <div class='form-group'>
                      <label for='inputAddress'>3</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputAddress'
                        placeholder='1234 Main St'
                        name='p3'
                        value={this.state.pp3data}
                        onChange={this.optionchange7}
                        placeholder='Enter 3'
                        required
                      />
                    </div>

                    <div class='form-group'>
                      <label for='inputAddress'>4</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputAddress'
                        placeholder='1234 Main St'
                        name='p4'
                        value={this.state.pp4data}
                        onChange={this.optionchange8}
                        placeholder='Enter 4'
                        required
                      />
                    </div>

                    <div class='form-group'>
                      <label for='inputAddress'>Event name</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputAddress'
                        name='evntname'
                        value={this.state.eventdata}
                        onChange={this.optionchange}
                        required
                      />
                    </div>

                    <div class='form-group'>
                      <label for='inputAddress'>Type of Event</label>
                      <select
                        class='form-control'
                        id='exampleFormControlSelect1'
                        value={this.state.typedata}
                        onChange={this.dropdownchange2}
                        required
                      >
                        <option>Please select</option>
                        <option value='horizontal'>Horizontal</option>
                        <option value='vertical'>Vertical</option>
                        <option value='timed'>Timed</option>
                      </select>
                    </div>

                    <div class='form-group'>
                      <label for='inputAddress'>Team Scoring</label>
                      <select
                        class='form-control'
                        id='exampleFormControlSelect1'
                        value={this.state.teamdata}
                        onChange={this.dropdownchange1}
                        required
                      >
                        <option>Please select</option>
                        <option value='no'>No</option>
                        <option value='yes'>Yes</option>
                      </select>
                    </div>

                    <center>
                      <button type='submit' onClick={this.updateparatwoset} data-dismiss='modal' class='btn btn-danger'>
                        Update
                      </button>
                    </center>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* add meet------------------------------------------------------------------------------------------------- */}
          <div class='modal fade' id='newmeet' tabindex='-1' role='dialog' aria-hidden='true'>
            <div class='modal-dialog'>
              <div class='modal-content'>
                <div class='modal-header '>
                  <h5 class='modal-title ' id='loginmodalLabel'>
                    New Paratriathlon200 Set{' '}
                  </h5>
                  <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <div class='modal-body'>
                  <form>
                    <div class='form-group'>
                      <label for='inputAddress'>Venue Name</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputAddress'
                        placeholder='1234 Main St'
                        name='venuename'
                        onChange={this.optionchange}
                        placeholder='Enter venue name'
                        required
                      />
                    </div>

                    <div class='form-group'>
                      <label for='inputAddress'>Venue location</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputAddress'
                        placeholder='1234 Main St'
                        name='venuelocation'
                        onChange={this.optionchange}
                        placeholder='Enter venue location'
                        required
                      />
                    </div>

                    <div class='form-group'>
                      <label for='inputAddress'>Number of events</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputAddress'
                        placeholder='1234 Main St'
                        name='numevents'
                        onChange={this.optionchange}
                        placeholder='Enter number of events'
                        required
                      />
                    </div>

                    <div class='form-group'>
                      <label for='inputAddress'>Current Record Level</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputAddress'
                        placeholder='1234 Main St'
                        name='currentrecord'
                        onChange={this.optionchange}
                        placeholder='Enter current record'
                        required
                      />
                    </div>

                    <div class='form-group'>
                      <label for='inputAddress'>Select Performance Record</label>
                      <select
                        class='form-control'
                        id='exampleFormControlSelect1'
                        onChange={this.dropdownchange3}
                        required
                      >
                        <option>Please select</option>
                        <option value='1'>1</option>
                        <option value='2'>2</option>
                        <option value='3'>3</option>
                        <option value='4'>4</option>
                      </select>
                    </div>

                    <div class='form-group'>
                      <label for='inputAddress'>1</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputAddress'
                        placeholder='1234 Main St'
                        name='p1'
                        onChange={this.optionchange}
                        placeholder='Enter 1'
                        required
                      />
                    </div>

                    <div class='form-group'>
                      <label for='inputAddress'>2</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputAddress'
                        placeholder='1234 Main St'
                        name='p2'
                        onChange={this.optionchange}
                        placeholder='Enter 2'
                        required
                      />
                    </div>

                    <div class='form-group'>
                      <label for='inputAddress'>3</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputAddress'
                        placeholder='1234 Main St'
                        name='p3'
                        onChange={this.optionchange}
                        placeholder='Enter 3'
                        required
                      />
                    </div>

                    <div class='form-group'>
                      <label for='inputAddress'>4</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputAddress'
                        placeholder='1234 Main St'
                        name='p4'
                        onChange={this.optionchange}
                        placeholder='Enter 4'
                        required
                      />
                    </div>

                    <div class='form-group'>
                      <label for='inputAddress'>Event Name</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputAddress'
                        placeholder='1234 Main St'
                        name='eventname'
                        onChange={this.optionchange}
                        placeholder='Enter event name'
                        required
                      />
                    </div>

                    <div class='form-group'>
                      <label for='inputAddress'>Type of Event</label>
                      <select
                        class='form-control'
                        id='exampleFormControlSelect1'
                        onChange={this.dropdownchange2}
                        required
                      >
                        <option>Please select</option>
                        <option value='horizontal'>Horizontal</option>
                        <option value='vertical'>Vertical</option>
                        <option value='timed'>Timed</option>
                      </select>
                    </div>

                    <div class='form-group'>
                      <label for='inputAddress'>Team Scoring</label>
                      <select
                        class='form-control'
                        id='exampleFormControlSelect1'
                        onChange={this.dropdownchange1}
                        required
                      >
                        <option>Please select</option>
                        <option value='no'>No</option>
                        <option value='yes'>Yes</option>
                      </select>
                    </div>

                    <center>
                      <button type='submit' onClick={this.createparatwoset} data-dismiss='modal' class='btn btn-danger'>
                        Create
                      </button>
                    </center>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Paratriathlontwoset;
