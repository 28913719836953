import React from 'react';

import axios from 'axios';

import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/bootstrap.min.css';
import './assets/css/now-ui-dashboard.css?v=1.5.0';
import './assets/demo/demo.css';
import Login from './login.js';
import Register from './register.js';
import Page1 from './firstpage.js';
import Home from './components/home.js';
import Meet from './components/Meet.js';
import Notifications from './components/Qstandards.js';
import User from './components/UserPage.js';
import Venues from './components/Venues.js';
import Agedivision from './components/Agedivision.js';
import Points from './components/Points.js';
import Gender from './components/Gender.js';
import FullScreenMap from './components/Venuesload.js';
import FullScreenMap2 from './components/Venueclasses.js';
import Teammaster from './components/Teammaster.js';
import Meetmanager from './components/Meetmanagerhome.js';
import Eventsponser from './components/Eventsponser.js';
import LocalCommit from './components/Localcommittee.js';
import ManagerVenue from './components/Managervenues.js';
import Atheleteregister from './components/atheleteregister.js';
import Meetviewmanager from './components/Meetsview.js';
import Atheleteresult from './components/atheleteresult';
import Detailpage from './components/Detailpage';
import Viewall1 from './components/Viewall';
import AtheletHome from './components/atheletehome.js';
import $ from 'jquery';
import logo from './logo.png';
import emailjs from 'emailjs-com';
import adaptive from './adaptive.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDribbble, faFacebook, faTwitter, faWindows } from '@fortawesome/free-brands-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import Resultpage from './components/Dashboard.js';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from 'reactstrap';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import Swal from 'sweetalert2';
const url = window.globalConfig;
class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      homepage: false,
      loginid: [{}],
      backgroundColor: 'white',
      desg: '',
      fname: '',
      lname: '',
      pwd: '',
      cpwd: '',
      email: '',
      addr1: '',
      addr2: '',
      dob: '',
      age: '',
      gender: '',
      city: '',
      state: '',
      zipcode: '',
      country: '',
      homephone: '',
      cellphone: '',
      refresh: false,
      exporthome: false,
      loggedIn: false,
      forpass: false,
      log: false,
      login: false,
      reg: false,
    };
    this.submitlogin = this.submitlogin.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.submitregister = this.submitregister.bind(this);
    this.rChange = this.rChange.bind(this);
    this.download = this.download.bind(this);
    this.handlechange1 = this.handlechange1.bind(this);
    this.forpass = this.forpass.bind(this);
    this.login = this.login.bind(this);
    this.logChange = this.logChange.bind(this);
    this.regChange = this.regChange.bind(this);
  }

  download() {
    const link = document.createElement('a');
    link.href = `your_link.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  sidebarToggle = React.createRef();
  toggle = () => {
    if (this.state.isOpen) {
      this.setState({
        color: 'transparent',
      });
    } else {
      this.setState({
        color: 'white',
      });
    }
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  openSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
    this.sidebarToggle.current.classList.toggle('toggled');
  };

  // componentDidMount(){

  // if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
  // window.location.href='/samsreact/'
  // window.reload(true);
  // }
  // }

  componentWillMount() {
    let test = sessionStorage.getItem('userdetails');
    let user = JSON.parse(test);
    this.setState({ user: JSON.parse(test) });
    if (user) {
      this.setState({ loggedIn: true, homepage: true, loginid: user.holedata.data });
    }
  }

  handlechange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  calculate_age = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    return age_now;
  };

  handlechange1(event) {
    this.setState({ dob1: event.target.value }, () => {
      // example of setState callback
      // this will have the latest this.state.dob1
    });

    // call calculate_age with event.target.value
    var age_latest = { age_latest: this.calculate_age(event.target.value) };
    var x = JSON.stringify(age_latest);

    var y = x.split(':');
    var z = y[1].split('}');
    this.setState({ age1: z[0] }, () => {
      // this will have the latest this.state.age1
    });
  }

  handleColorClick = (color) => {
    this.setState({ backgroundColor: color });
  };

  submitlogin(e) {
    e.preventDefault();

    const datatosend = {
      email: this.state.username,
      password: this.state.password,
    };

    var self = this;
    self.setState({ loggedIn: true });
    axios
      .post(`${url.url}/api/login`, datatosend, {})
      .then(function (response) {
        if (response.status) {
          let userdetails = {
            token: response.data.data.token,
            id: response.data.data.user.id,
            holedata: response.data,
          };
          sessionStorage.setItem('userdetails', JSON.stringify(userdetails));

          self.setState({ loggedIn: true, homepage: true, loginid: response.data.data });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Log in Failed',
          text: 'Please check your credential',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  // Register---------------------------------------------------------------------------------------------------------
  submitregister(e) {
    e.preventDefault();

    const datatosend = {
      designation: this.state.desg.split('-')[2],
      firstname: this.state.fname,
      email: this.state.email,
      password: this.state.pwd,
      lastname: this.state.lname,
      dob: this.state.dob1,
      age: this.state.age1,
      address: this.state.addr1,
      address2: this.state.addr2,
      city: this.state.city,
      zipcode: this.state.zipcode,
      country: this.state.country,
      homephone: this.state.homephone,
      cellphone: this.state.cellphone,
      gender: this.state.gender,
      role_id: parseInt(this.state.desg.split('-')[0]),
      created_by: 1,
    };
    if (this.state.pwd === this.state.cpwd) {
      var templateParams = {
        from_name: 'MU Meet Management Application',
        to_name: this.state.fname,
        name: this.state.email,
        password: this.state.pwd,
        to_email: this.state.email,
      };

      emailjs.send('service_xqn2c0e', 'template_l1wjkjj', templateParams, 'user_s43S1h6IUGOeVy9IuO8s2').then(
        function (response) {},
        function (error) {}
      );

      axios
        .post(`${url.url}/api/register/`, datatosend, {})
        .then(function (response) {
          if (response.statusText === 'OK') {
            Swal.fire('Success', 'User Registered Successfully', 'success');
            setTimeout(function () {
              if (window.location.hash !== '#r') {
                window.location.hash = 'r';
                window.location.reload(1);
              }
            }, 2000);
          }
        })

        .catch(function (error) {
          Swal.fire('opps!', 'Something went wrong please check the fields or email id already exist', 'warning');
        });
    } else {
      Swal.fire('Please check your password!', 'Password does not match', 'warning');
    }
  }

  rChange() {
    var self = this;

    self.setState({
      exporthome: true,
    });
  }
  login() {
    this.setState({
      forpass: false,
      exporthome: false,
      homepage: false,
    });
  }
  forpass() {
    this.setState({
      forpass: true,
      exporthome: true,
      homepage: false,
    });
  }
  logChange() {
    this.setState({ login: true, active: true });
  }
  regChange() {
    this.setState({ reg: true });
  }

  render() {
    if (this.state.homepage) {
      return <Home logindata={this.state.loginid} />;
    }
    if (this.state.login) {
      return <Login />;
    }
    // if (this.state.reg) {
    //   return (
    //     <Register />
    //   )
    // }
    return (
      <>
        <div>
          {/* top nav and image */}
          <nav class='navbar navbar-expand-lg bg-white'>
            <div class='container-fluid'>
              <div class='navbar-wrapper'>
                <img src={logo} alt='' width='100px' height='50px' />
                <img src={adaptive} alt='' width='100px' height='50px' />
              </div>

              <button
                class='navbar-toggler'
                type='button'
                data-toggle='collapse'
                data-target='#navigation'
                aria-controls='navigation-index'
                aria-expanded='false'
                aria-label='Toggle navigation'
              >
                <span class='navbar-toggler-bar navbar-kebab'></span>
                <span class='navbar-toggler-bar navbar-kebab'></span>
                <span class='navbar-toggler-bar navbar-kebab'></span>
              </button>
              <div class='collapse navbar-collapse justify-content-end' id='navigation'>
                <ul class='navbar-nav'>
                  <li class='nav-item '>
                    <a href='https://atfusa.org/REPORTS/REPORT1.htm' class='nav-link nav-link1' target='_blank'>
                      <i class='now-ui-icons arrows-1_cloud-download-93'></i>
                      Results
                    </a>
                  </li>

                  <li class='nav-item ' data-toggle='modal' data-target='#loginmodal'>
                    <button href='/login' class='nav-link nav-link1' onClick={this.logChange}>
                      <i class='now-ui-icons users_circle-08'></i>
                      Login
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div class='wrapper wrapper-full-page wrapper1 '>
            <div class='full-page login-page section-image' filter-color='black'></div>
          </div>

          <div id='icons' class=' container-fluid  '>
            <nav></nav>
          </div>
          <div class='col-md-4' id='social'>
            <ul>
              <a href='#'>
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a href='#'>
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a href='#'>
                <FontAwesomeIcon icon={faDribbble} />
              </a>
              <a href='#'>
                <FontAwesomeIcon icon={faGithub} />
              </a>
            </ul>
          </div>
          <div class='col-md-4'>
            <h4 class='text-primary font-weight-light mb-2'>Thank you for supporting us!</h4>
          </div>
          <footer>
            <hr></hr>
            <p id='footer' class='col-md-4'>
              © 2020 MU Meet Management Application.{' '}
            </p>
          </footer>
          <div
            class='modal fade bd-example-modal-lg'
            id='exportresult'
            tabindex='-1'
            role='dialog'
            aria-labelledby='exampleModalLabel'
            aria-hidden='true'
          >
            <div class='modal-dialog  modal-lg' role='document' id='exportresult'>
              <div class='modal-content'>
                <div class='modal-header'>
                  <h5 class='modal-title' id='registermodalLabel'>
                    Export Result
                  </h5>
                  <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <div class='modal-body'>
                  <form>
                    <div class='form-group'>
                      <label for='exampleFormControlSelect1'>Report Type *</label>
                      <select class='form-control' id='exampleFormControlSelect1' name='desg' required>
                        <option>Please Select</option>
                        <option value='3-user-athelete'>Standards</option>
                        <option value='3-user-headcoach'>Standards USATF</option>
                        <option value='3-user-coach'>Record</option>
                        <option value='3-user-assitant coach'>PNR list</option>
                        <option value='3-user-parent'>USparastid</option>
                      </select>
                    </div>

                    <div class='form-group'>
                      <label for='exampleFormControlSelect1'>Athletics *</label>
                      <select class='form-control' id='exampleFormControlSelect1' name='desg' required>
                        <option>Please Select</option>
                        <option value='3-user-athelete'>Track</option>
                        <option value='3-user-headcoach'>Field</option>
                      </select>
                    </div>

                    <div class='form-group'>
                      <label for='exampleFormControlSelect1'>Year *</label>
                      <select class='form-control' id='exampleFormControlSelect1' name='desg' required>
                        <option>Please Select</option>
                      </select>
                    </div>

                    <div class='form-row'>
                      <div class='form-group col-md-6'>
                        <label for='inputEmail4'>FirstName</label>
                        <input type='text' class='form-control' id='inputEmail4' placeholder='firstname' required />
                      </div>
                      <div class='form-group col-md-6'>
                        <label for='inputPassword4'>LastName</label>
                        <input type='text' class='form-control' id='inputPassword4' placeholder='lastname' required />
                      </div>

                      <div class='form-group col-md-6'>
                        <label for='inputState'>Gender</label>
                        <select id='inputState' class='form-control' name='gender'>
                          <option selected>Choose...</option>
                          <option value='male'>Male</option>
                          <option value='female'>Female</option>
                        </select>
                      </div>

                      <div class='form-group col-md-6'>
                        <label for='inputState'>Track</label>
                        <select id='inputState' class='form-control' name='gender'>
                          <option selected>Choose...</option>
                        </select>
                      </div>

                      <div class='form-group col-md-6'>
                        <label for='inputState'>Field</label>
                        <select id='inputState' class='form-control' name='gender'>
                          <option selected>Choose...</option>
                        </select>
                      </div>
                    </div>
                    <div class='form-group'>
                      <label for='exampleFormControlSelect1'>Age *</label>
                      <select class='form-control' id='exampleFormControlSelect1' name='desg' required>
                        <option>Please Select</option>
                        <option value='3-user-athelete'>U18</option>
                        <option value='3-user-headcoach'>U19</option>
                      </select>
                    </div>

                    <div class='form-row'>
                      <div class='form-group col-md-6'>
                        <label for='exampleFormControlSelect1'>Visual Impairment *</label>
                        <select class='form-control' id='exampleFormControlSelect1' name='desg' required>
                          <option>Please Select</option>
                        </select>
                      </div>
                      <div class='form-group col-md-6'>
                        <label for='exampleFormControlSelect1'>Intellectual Impairment *</label>
                        <select class='form-control' id='exampleFormControlSelect1' name='desg' required>
                          <option>Please Select</option>
                        </select>
                      </div>
                    </div>

                    <div id='outer'>
                      <div class='inner'>
                        <button type='submit' class='btn btn-danger'>
                          PDF
                        </button>
                      </div>
                      <div class='inner'>
                        <button type='submit' class='btn btn-danger'>
                          HTML
                        </button>
                      </div>
                      <div class='inner'>
                        <button class='btn btn-danger'>Excel</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div
            class='modal fade bd-example-modal-lg'
            id='registermodal'
            tabindex='-1'
            role='dialog'
            aria-labelledby='exampleModalLabel'
            aria-hidden='true'
          >
            <div class='modal-dialog  modal-lg' role='document' id='registermodal'>
              <div class='modal-content'>
                <div class='modal-header'>
                  <h5 class='modal-title' id='registermodalLabel'>
                    Register
                  </h5>
                  <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <div class='modal-body'>
                  <form onSubmit={this.submitregister}>
                    <div class='form-group'>
                      <label for='exampleFormControlSelect1'>Desigination</label>
                      <select
                        class='form-control'
                        id='exampleFormControlSelect1'
                        name='desg'
                        onChange={this.handlechange}
                        required
                      >
                        <option>Please Select</option>
                        <option value='3-user-athelete'>Athelete</option>
                        <option value='3-user-headcoach'>Head Coach</option>
                        <option value='3-user-coach'>Coach</option>
                        <option value='3-user-assitant coach'>Assitant Coach</option>
                        <option value='3-user-parent'>Parent</option>
                        <option value='3-user-staff'>Support Staff</option>
                        <option value='3-user-offical'>Official</option>
                      </select>
                    </div>

                    <div class='form-row'>
                      <div class='form-group col-md-6'>
                        <label for='inputEmail4'>FirstName</label>
                        <input
                          type='text'
                          class='form-control'
                          id='inputEmail4'
                          placeholder='firstname'
                          onChange={(e) => this.setState({ fname: e.target.value })}
                          required
                        />
                      </div>
                      <div class='form-group col-md-6'>
                        <label for='inputPassword4'>LastName</label>
                        <input
                          type='text'
                          class='form-control'
                          id='inputPassword4'
                          placeholder='lastname'
                          onChange={(e) => this.setState({ lname: e.target.value })}
                          required
                        />
                      </div>
                    </div>

                    <div class='form-row'>
                      <div class='form-group col-md-4'>
                        <label for='inputCity'>Email</label>
                        <input
                          type='email'
                          class='form-control'
                          id='inputCity'
                          placeholder='email'
                          onChange={(e) => this.setState({ email: e.target.value })}
                          required
                        />
                      </div>

                      <div class='form-group col-md-4'>
                        <label for='inputZip'>Password</label>
                        <input
                          type='password'
                          class='form-control'
                          id='inputZip'
                          placeholder='password'
                          onChange={(e) => this.setState({ pwd: e.target.value })}
                          required
                        />
                      </div>
                      <div class='form-group col-md-4 confirm'>
                        <label for='inputZip'>Confirm password</label>
                        <input
                          type='password'
                          class='form-control'
                          id='inputZip'
                          placeholder='confirm password'
                          onChange={(e) => this.setState({ cpwd: e.target.value })}
                          required
                        />
                      </div>
                    </div>
                    <div class='form-row'>
                      <div class='form-group col-md-6'>
                        <label for='inputCity'>DOB</label>
                        <input type='date' class='form-control' id='DOB ' onChange={this.handlechange1} required />
                      </div>
                      <div class='form-group col-md-6'>
                        <label for='inputState'>Gender</label>
                        <select id='inputState' class='form-control' name='gender' onChange={this.handlechange}>
                          <option selected>Choose...</option>
                          <option value='male'>Male</option>
                          <option value='female'>Female</option>
                        </select>
                      </div>
                    </div>
                    <div class='form-group'>
                      <label for='inputAddress'>Age</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputAddress'
                        placeholder='Enter your age'
                        value={this.state.age1}
                        disabled
                        required
                      />
                    </div>
                    <div class='form-group'>
                      <label for='inputAddress'>Address</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputAddress'
                        placeholder='Enter your address'
                        onChange={(e) => this.setState({ addr1: e.target.value })}
                        required
                      />
                    </div>
                    <div class='form-group'>
                      <label for='inputAddress2'>Address 2</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputAddress2'
                        placeholder='Enter your address'
                        onChange={(e) => this.setState({ addr2: e.target.value })}
                      />
                    </div>

                    <div class='form-row'>
                      <div class='form-group col-md-4'>
                        <label for='inputCity'>City</label>
                        <input
                          type='text'
                          class='form-control'
                          id='inputCity'
                          placeholder='Enter city'
                          onChange={(e) => this.setState({ city: e.target.value })}
                          required
                        />
                      </div>

                      <div class='form-group col-md-4'>
                        <label for='inputZip'>State/Province</label>
                        <input
                          type='text'
                          class='form-control'
                          id='inputZip'
                          placeholder='Enter State'
                          onChange={(e) => this.setState({ state: e.target.value })}
                          required
                        />
                      </div>
                      <div class='form-group col-md-4'>
                        <label for='inputZip'>Zip/Mail code</label>
                        <input
                          type='text'
                          class='form-control'
                          id='inputZip'
                          placeholder='Enter Zip code'
                          onChange={(e) => this.setState({ zipcode: e.target.value })}
                          required
                        />
                      </div>
                    </div>
                    <div class='form-row'>
                      <div class='form-group col-md-4'>
                        <label for='inputCity'>Country</label>
                        <input
                          type='text'
                          class='form-control'
                          id='inputCity'
                          placeholder='Enter country'
                          onChange={(e) => this.setState({ country: e.target.value })}
                          required
                        />
                      </div>

                      <div class='form-group col-md-4'>
                        <label for='inputZip'>Homephone</label>
                        <input
                          type='number'
                          class='form-control'
                          id='inputZip'
                          placeholder='Enter homephone'
                          onChange={(e) => this.setState({ homephone: e.target.value })}
                          required
                        />
                      </div>
                      <div class='form-group col-md-4'>
                        <label for='inputZip'>Cellphone</label>
                        <input
                          type='number'
                          class='form-control'
                          id='inputZip'
                          placeholder='Enter cellphone'
                          onChange={(e) => this.setState({ cellphone: e.target.value })}
                          required
                        />
                      </div>
                    </div>
                    <center>
                      <button type='submit' class='btn btn-danger'>
                        Register
                      </button>
                    </center>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );

    if (this.state.exporthome) {
      return (
        <div>
          <Router basename='/samsdev/'>
            <div className='sidebar' data-color='red'>
              <div
                style={{ height: '100vh', overflowX: 'hidden', overflowY: 'hidden' }}
                className='sidebar-wrapper'
                ref='sidebar'
              >
                <div className='logo'>
                  <a href='#'>
                    <div class='sidenavimg'>
                      <div class='row'>
                        <div class='column'>
                          <img src={logo} width='70' height='40'></img>
                          <img src={adaptive} width='70' height='40'></img>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <Nav>
                  <li className={this.state.active0}>
                    <Link to='#'>
                      <i className='now-ui-icons design_app'></i>Home
                    </Link>
                  </li>

                  <li className={this.state.active1}>
                    <Link to='#'>
                      <i className='now-ui-icons design-2_ruler-pencil'></i>About
                    </Link>
                  </li>

                  <li className={this.state.active1}>
                    <Link to='#'>
                      <i className='now-ui-icons files_single-copy-04'></i>News
                    </Link>
                  </li>

                  <li className={this.state.active1}>
                    <Link to='#'>
                      <i className='now-ui-icons media-1_album'></i>Calendar
                    </Link>
                  </li>

                  <li className={this.state.active1}>
                    <Link to='#'>
                      <i className='now-ui-icons education_paper'></i>Rules
                    </Link>
                  </li>

                  <li className={this.state.active1}>
                    <Link to='#'>
                      <i className='now-ui-icons sport_trophy'></i>USTAF youth para championship
                    </Link>
                  </li>

                  <li className={this.state.active1}>
                    <Link to='#'>
                      <i className='now-ui-icons files_paper'></i>Forms
                    </Link>
                  </li>

                  <li className={this.state.active1}>
                    <Link to='#'>
                      <i className='now-ui-icons location_bookmark'></i>Standards
                    </Link>
                  </li>

                  <li className={this.state.active1}>
                    <Link to='#'>
                      <i className='now-ui-icons education_paper'></i>Records
                    </Link>
                  </li>

                  <li className={this.state.active1}>
                    <Link to='#'>
                      <i className='now-ui-icons ui-1_calendar-60'></i>Meet Results
                    </Link>
                  </li>

                  <li className={this.state.active1}>
                    <Link to='#'>
                      <i className='now-ui-icons media-1_album'></i>Photo gallery
                    </Link>
                  </li>

                  <li className={this.state.active1}>
                    <Link to='#'>
                      <i className='now-ui-icons tech_watch-time'></i>Coach/Athelete resources
                    </Link>
                  </li>

                  <li className={this.state.active1}>
                    <Link to='#'>
                      <i className='now-ui-icons education_agenda-bookmark'></i>FAQ
                    </Link>
                  </li>

                  <li className={this.state.active1}>
                    <Link to='#'>
                      <i className='now-ui-icons education_hat'></i>High school
                    </Link>
                  </li>

                  <li className={this.state.active1}>
                    <Link to='#'>
                      <i className='now-ui-icons tech_mobile'></i>Contact
                    </Link>
                  </li>

                  <li className={this.state.active1}>
                    <Link to='#'>
                      <i className='now-ui-icons ui-1_email-85'></i>Official corner
                    </Link>
                  </li>

                  <li className={this.state.active1}>
                    <Link to='#'>
                      <i className='now-ui-icons shopping_tag-content'></i>The store
                    </Link>
                  </li>

                  <li className={this.state.active1}>
                    <Link to='#'>
                      <i className='now-ui-icons gestures_tap-01'></i>Governance
                    </Link>
                  </li>
                </Nav>
              </div>
            </div>

            {/*
side nav bar------------------------------------------------------------------ */}

            {/* panel header ---------------------------------------------------------------------------------------------------- */}
            <div className='main-panel'>
              <Navbar expand='lg' color='primary'>
                <Container fluid>
                  <div className='navbar-wrapper'>
                    <div className='navbar-toggle'>
                      <button
                        type='button'
                        ref={this.sidebarToggle}
                        className='navbar-toggler'
                        onClick={() => this.openSidebar()}
                      >
                        <span className='navbar-toggler-bar bar1' />
                        <span className='navbar-toggler-bar bar2' />
                        <span className='navbar-toggler-bar bar3' />
                      </button>
                    </div>
                  </div>

                  <NavbarToggler onClick={this.toggle}>
                    <span className='navbar-toggler-bar navbar-kebab'></span>
                    <span className='navbar-toggler-bar navbar-kebab'></span>
                    <span className='navbar-toggler-bar navbar-kebab'></span>
                  </NavbarToggler>

                  <Collapse isOpen={this.state.isOpen} navbar className='justify-content-end'>
                    <Nav navbar>
                      <NavItem></NavItem>
                    </Nav>
                  </Collapse>
                </Container>
              </Navbar>

              {/* panel header ---------------------------------------------------------------------------------------------------- */}
              <div className='wrapper'>
                <div class='wrapper_1'>
                  <div class='form-row'>
                    <div class='form-group col-md-6'>
                      <label>First Name: </label>
                      <input class='input_value' text='text' />
                    </div>
                    <div class='form-group col-md-6'>
                      <label>Last name:</label>
                      <input class='input_value' text='text' />
                    </div>
                    <div class='form-group col-md-6'>
                      <label for='inputState'>Gender:</label>
                      <select name='gender'>
                        <option value='Male'>Male</option>
                        <option value='Female'>Female</option>
                        <option value='%male'>BOTH</option>
                      </select>
                    </div>
                    <div class='form-group col-md-6'>
                      <label for='inputState'>Age:</label>
                      <select name='age'>
                        <option value='%'></option>
                        <option value='%Futures'>Futures (&lt;7)</option>
                        <option value='%U11'>U11 (7,8,9,10)</option>
                        <option value='%U14'>U14 (11,12,13)</option>
                        <option value='%U16'>U16 (14,15)</option>
                        <option value='%U17'>**New** U17 (14,15,16)</option>
                        <option value='%U18'>U18 (16,17)</option>
                        <option value='%U20'>U20 (18,19)</option>
                        <option value='%U20'>**New** U20 (17,18,19)</option>
                        <option value='%U23'>U23 (20,21,22)</option>
                        <option value='%Adults'>Adults</option>
                        <option value='%Adults'>Open (&gt;14)</option>
                        <option value='%M35'>M35 (35-49)</option>
                        <option value='%M50'>M50 (50-59</option>
                        <option value='%M60'>M60 (60+)</option>
                        <option value='%A'>A (pre2010 - 7,8,9,10)</option>
                        <option value='%B'>B (pre2010 - 11,12,13)</option>
                        <option value='%C'>C (pre2010 - 14,15,16)</option>
                        <option value='%D'>D (pre2010 - 17,18,19)</option>
                        <option value='%E'>E (pre2010 - 20,21,22)</option>
                      </select>
                    </div>
                  </div>
                  <div class='form-row'>
                    <div class='form-group col-md-6'>
                      <b>*</b>
                      <label for='inputEmail4'>Report Type:</label>
                      <select name='reporttype'>
                        <option value='Resultsjn'>Results w/JN Stds</option>
                        <option value='Resultspara'>Results w/Para Stds</option>
                        <option value='Standards'>Standards</option>
                        <option value='StdUSATF'>Standards USATF</option>
                        <option value='Records'>Records</option>
                        <option value='PNR'>PNR List</option>
                        <option value='USParaStd'>USParaStd</option>
                      </select>
                    </div>
                    <div class='form-group col-md-6'>
                      <b>*</b>
                      <label for='inputPassword4'>Athletics:</label>
                      <select name='athleticstype'>
                        <option value='Track'>Track</option>
                        <option value='Field'>Field</option>
                      </select>
                    </div>
                  </div>
                  <div class='form-row'>
                    <div class='form-group col-md-6'>
                      <b>*</b>
                      <label for='inputAddress'>Year:</label>
                      <select name='year'>
                        <option value='2020'>2020 - Only One Meet</option>
                        <option value='2019'>2019 - ALL Results</option>
                        <option value='2018'>2018 - ALL Results</option>
                        <option value='2017'>2017 - ALL Results</option>
                        <option value='2016'>2016 - ALL Results</option>
                        <option value='2015'>2015 - JN Only</option>
                        <option value='2014'>2014 - JN Only</option>
                        <option value='2013'>2013 - JN Only</option>
                        <option value='2012'>2012 - JN Only</option>
                        <option value='2011'>2011 - JN Only</option>
                        <option value='2009'>2009 - JN Only</option>
                        <option value='2004'>2004 - JN Only</option>
                        <option value='2003'>2003 - JN Track Only</option>
                      </select>
                    </div>
                    <div class='form-group col-md-6'>
                      <label for='inputAddress2'>Meet:</label>
                      <select name='meet'>
                        <option value='%'>ALL</option>
                        <option value='5280 Challenge'>5280 Challenge</option>
                        <option value='Adult National Open &amp; Great Lakes Regional Games'>
                          Adult National Open &amp; Great Lakes Regional Games
                        </option>
                        <option value='Angel City Games'>Angel City Games</option>
                        <option value='Blaze Olympic Day'>Blaze Olympic Day</option>
                        <option value='Challenge Games'>Challenge Games</option>
                        <option value='CLW Invitational'>CLW Invitational</option>
                        <option value='CSH Invitational Meet'>CSH Invitational Meet</option>
                        <option value='Dairyland Games'>Dairyland Games</option>
                        <option value='Dakota ParaGames'>Dakota ParaGames</option>
                        <option value='Desert Challenge Games'>Desert Challenge Games</option>
                        <option value='DIXIE GAMES'>DIXIE GAMES</option>
                        <option value='Endeavor Games'>Endeavor Games</option>
                        <option value='Fast Cow'>Fast Cow</option>
                        <option value='Gateway Games'>Gateway Games</option>
                        <option value='Gumbo Acadiana'>Gumbo Acadiana</option>
                        <option value='IWAS Youth World Games'>IWAS Youth World Games</option>
                        <option value='Junior Nationals'>Junior Nationals</option>
                        <option value='Lone Star Para Invitational'>Lone Star Para Invitational</option>
                        <option value='Masonic Regional Junior Meet'>Masonic Regional Junior Meet</option>
                        <option value='Meet in the Heat'>Meet in the Heat</option>
                        <option value='Rolling Thunder Regional'>Rolling Thunder Regional</option>
                        <option value='SEWSA Regional Games'>SEWSA Regional Games</option>
                        <option value='Texas Regional Games'>Texas Regional Games</option>
                        <option value='Thunder in the Valley'>Thunder in the Valley</option>
                        <option value='Tri-State Games'>Tri-State Games</option>
                        <option value='Tri-State Open'>Tri-State Open</option>
                        <option value='Turnstone Games'>Turnstone Games</option>
                        <option value='TX vs the World'>TX vs the World</option>
                        <option value='US Paralympics National Championship'>
                          US Paralympics National Championship
                        </option>
                        <option value='World Para Athletics Junior Championships'>
                          World Para Athletics Junior Championships
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class='text'>
                    <div class='text_1'>
                      <h3>Event</h3>
                    </div>
                    <div>
                      <h3>Class</h3>
                    </div>
                  </div>
                  <div class='form-row'>
                    <div class='form-group col-md-6'>
                      <label for='inputAddress'>Track:</label>
                      <select name='top'>
                        <option value='%'></option>
                        <option value='20 Meters'>20 Meters</option>
                        <option value='60 Meters'>60 Meters</option>
                        <option value='60 Meters Weave'>60 Meters Weave</option>
                        <option value='100 Meters'>100 Meters</option>
                        <option value='100 Meters Power Chair'>100 Meters Power Chair</option>
                        <option value='200 Meters'>200 Meters</option>
                        <option value='400 Meters'>400 Meters</option>
                        <option value='800 Meters'>800 Meters</option>
                        <option value='1500 Meters'>1500 Meters</option>
                        <option value='3000 Meters'>3000 Meters</option>
                        <option value='5000 Meters'>5000 Meters</option>
                        <option value='10000 Meters'>10000 Meters</option>
                        <option value='4 X 100 Meters'>4 X 100 Meters</option>
                        <option value='4 X 400 Meters'>4 X 400 Meters</option>
                        <option value='800 Medley'>800 Medley</option>
                        <option value='Half Marathon'>Half Marathon</option>
                        <option value='Marathon Meters'>Marathon Meters</option>
                        <option value='Mile'>Mile</option>
                      </select>
                    </div>
                    <div class='form-group col-md-6'>
                      <label for='inputAddress2'>Visual Impairment:</label>
                      <select name='vi'>
                        <option value=' '></option>
                        <option value='11'>11 (T&amp;F)</option>
                        <option value='12'>12 (T&amp;F)</option>
                        <option value='13'>13 (T&amp;F)</option>
                      </select>
                    </div>
                  </div>
                  <div class='form-row'>
                    <div class='form-group col-md-6'>
                      <label for='inputAddress'>Field:</label>
                      <select name='fop' disabled=''>
                        <option value='%'></option>
                        <option value='Club'>Club</option>
                        <option value='Discus'>Discus</option>
                        <option value='High Jump'>High Jump</option>
                        <option value='High Toss'>High Toss</option>
                        <option value='Javelin'>Javelin</option>
                        <option value='Kick'>Kick</option>
                        <option value='Long Jump'>Long Jump</option>
                        <option value='Med Ball Thrust'>Med Ball Thrust</option>
                        <option value='Pentathlon'>Pentathlon</option>
                        <option value='Precision'>Precision</option>
                        <option value='Shotput'>Shotput</option>
                        <option value='Softball'>Softball</option>
                        <option value='Thrust'>Thrust</option>
                        <option value='Triple Jump'>Triple Jump</option>
                      </select>
                    </div>
                    <div class='form-group col-md-6'>
                      <label for='inputAddress2'>Intellectual Impairment:</label>
                      <select name='ci'>
                        <option value=' '></option>
                        <option value='20'>20 (T&amp;F)</option>
                      </select>
                    </div>
                  </div>
                  <div class='form-row'>
                    <div class='form-group col-md-6'></div>
                    <div class='form-group col-md-6'>
                      <label for='inputAddress2'>Hypertonia, Ataxia, Athetosis (CP):</label>
                      <select name='cp'>
                        <option value=' '></option>
                        <option value='31'>31 (T&amp;F)</option>
                        <option value='32'>32 (T&amp;F)</option>
                        <option value='33'>33 (T&amp;F)</option>
                        <option value='34'>34 (T&amp;F)</option>
                        <option value='35'>35 (T&amp;F)</option>
                        <option value='35a'>35a (T&amp;F)</option>
                        <option value='36'>36 (T&amp;F)</option>
                        <option value='37'>37 (T&amp;F)</option>
                        <option value='38'>38 (T&amp;F)</option>
                      </select>
                    </div>
                  </div>
                  <div class='form-row'>
                    <div class='form-group col-md-6'></div>
                    <div class='form-group col-md-6'>
                      <label for='inputAddress2'>Short Stature:</label>
                      <select name='da'>
                        <option value=' '></option>
                        <option value='40'>40 (T&amp;F)</option>
                        <option value='41'>41 (T&amp;F)</option>
                      </select>
                    </div>
                  </div>
                  <div class='form-row'>
                    <div class='form-group col-md-6'></div>
                    <div class='form-group col-md-6'>
                      <label for='inputAddress2'>Leg Diff. / Impaired Muscle Power No Prosthesis:</label>
                      <select name='ds1'>
                        <option value=' '></option>
                        <option value='42'>42 (T&amp;F)</option>
                        <option value='43'>43 (T&amp;F)</option>
                        <option value='44'>44 (T&amp;F)</option>
                      </select>
                    </div>
                  </div>
                  <div class='form-row'>
                    <div class='form-group col-md-6'></div>
                    <div class='form-group col-md-6'>
                      <label for='inputAddress2'>Limb Deficiency Upper Limb:</label>
                      <select name='ds2'>
                        <option value=' '></option>
                        <option value='45'>45 (T&amp;F)</option>
                        <option value='46'>46 (T&amp;F)</option>
                        <option value='47'>47 (T&amp;F)</option>
                      </select>
                    </div>
                  </div>
                  <div class='form-row'>
                    <div class='form-group col-md-6'></div>
                    <div class='form-group col-md-6'>
                      <label for='inputAddress2'>Impaired Muscle Power or Passive Range of Movement (PROM) (WC):</label>
                      <select name='wc'>
                        <option value=' '></option>
                        <option value='51'>51 (T&amp;F)</option>
                        <option value='52'>52 (T&amp;F)</option>
                        <option value='53'>53 (T&amp;F)</option>
                        <option value='54'>54 (T&amp;F)</option>
                        <option value='55' disabled=''>
                          55 (Field)
                        </option>
                        <option value='56' disabled=''>
                          56 (Field)
                        </option>
                        <option value='57' disabled=''>
                          57 (Field)
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class='form-row'>
                    <div class='form-group col-md-6'></div>
                    <div class='form-group col-md-6'>
                      <label for='inputAddress2'>Limb Deficiency - Lower Limb W/Prosthesis:</label>
                      <select name='ds3'>
                        <option value=' '></option>
                        <option value='61'>61 (T&amp;F)</option>
                        <option value='62'>62 (T&amp;F)</option>
                        <option value='63'>63 (T&amp;F)</option>
                        <option value='64'>64 (T&amp;F)</option>
                      </select>
                    </div>
                  </div>
                  <div class='form-row'>
                    <div class='form-group col-md-6'></div>
                    <div class='form-group col-md-6'>
                      <label for='inputAddress2'>USATF:</label>
                      <select name='jo'>
                        <option value=' '></option>
                        <option value='A'>Ambulatory (Track)</option>
                        <option value='W'>Wheelchair (Track)</option>
                        <option value='A' disabled=''>
                          Ambulatory (Field)
                        </option>
                        <option value='WP' disabled=''>
                          WC Para (Field)
                        </option>
                        <option value='WQ' disabled=''>
                          WC Quad (Field)
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class='form-row'>
                    <div class='form-group col-md-6'></div>
                    <div class='form-group col-md-6'>
                      <label for='inputAddress2'>Pentathlon:</label>
                      <select name='pent' disabled=''>
                        <option value=' '></option>
                        <option value='33'>33</option>
                        <option value='34'>34</option>
                        <option value='51'>51</option>
                        <option value='52'>52</option>
                        <option value='53'>53</option>
                        <option value='54'>54</option>
                        <option value='55'>55</option>
                        <option value='56'>56</option>
                        <option value='57'>57</option>
                      </select>
                    </div>
                  </div>
                  <div class=' btn_1'>
                    <button class='btn btn-primary'>Start Over</button>
                  </div>
                  <div class='boder'></div>
                  <div class='report'>
                    <label for='inputAddress'>Select report type: </label>
                    <a href='#' target='_blank' rel='file.pdf' download>
                      <button class='btn btn-primary'>HTML</button>
                    </a>
                    <a href='#' target='_blank' rel='file.pdf' download>
                      <button class='btn btn-primary'>PDF</button>
                    </a>
                    <a href='#' target='_blank' rel='file.pdf' download>
                      <button class='btn btn-primary'>EXCEL</button>
                    </a>
                  </div>
                </div>
                <Switch></Switch>
              </div>
            </div>
          </Router>
        </div>
      );
    }
    return (
      <>
        <Router basename='/samsdev/'>
          <Switch>
            {/* <Route path="/dashboard" >
<Dashboard />
</Route> */}
            {/* <Redirect exact from="/" to={path}  />  */}
            <Route exact path='/' component={Meet}>
              <Meet />
            </Route>
            <Route exact path='/venues' component={Venues}>
              <Venues />
            </Route>
            <Route exact path='/Qualifyingstandard'>
              <Notifications />
            </Route>
            <Route exact path='/users' component={User}>
              <User />
            </Route>
            <Route exact path='/Agedivision' component={Agedivision}>
              <Agedivision />
            </Route>
            <Route exact path='/Points' component={Points}>
              <Points />
            </Route>
            <Route exact path='/Gender' component={Gender}>
              <Gender />
            </Route>
            <Route exact path='/venuesevent' component={FullScreenMap}>
              <FullScreenMap />
            </Route>
            <Route exact path='/venueclasses' component={FullScreenMap2}>
              <FullScreenMap2 />
            </Route>
            <Route exact path='/teammaster' component={Teammaster}>
              <Teammaster />
            </Route>
            <Route exact path='/meets' component={Meetmanager}>
              <Meetmanager />
            </Route>
            <Route exact path='/meetviewmanager' component={Meetviewmanager}>
              <Meetviewmanager />
            </Route>
            <Route exact path='/eventsponser' component={Eventsponser}>
              <Eventsponser />
            </Route>
            <Route exact path='/localcommittee' component={LocalCommit}>
              <LocalCommit />
            </Route>
            <Route exact path='/managervenues' component={ManagerVenue}>
              <ManagerVenue />
            </Route>
            <Route exact path='/atheleteregister' component={Atheleteregister}>
              <Atheleteregister />
            </Route>
            <Route exact path='/details' component={Detailpage}>
              <Detailpage />
            </Route>
            <Route exact path='/viewall' component={Viewall1}>
              <Viewall1 />
            </Route>
            <Route exact path='/atheleteresult' component={Atheleteresult}>
              <Atheleteresult />
            </Route>
            {/* <Route path="/dashboard">
<Dashboard />
</Route> */}
            {/* <Redirect exact from="/" to={path}  /> */}
            <Route exact path='/atheletehome'>
              <AtheletHome />
            </Route>

            {/* <Route exact path="/regstatus">
<Rstatus logindata={this.props.logindata} />
</Route> */}
          </Switch>
        </Router>
      </>
    );
  }
}
export default Dashboard;
