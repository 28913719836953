import React from 'react';

import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import 'antd/dist/antd.css';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';

import Swal from 'sweetalert2';
import { Empty } from 'antd';

import 'antd/dist/antd.css';
const url = window.globalConfig;

class Results extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      meetdatas: [{}],
      vdatas: [{}],
      ahome: false,
      vhome: true,
      adetail: false,
      venuename: '',
      mid: '',
      vid: '',
      resultvalues: [{}],
      arcshow: false,
      fieldshow: false,
      ttennis: false,
      powerliftshow: false,
      trackshow: false,
      showswim: false,
      resultshow: false,
    };
    this.meetvenues = this.meetvenues.bind(this);
    this.back = this.back.bind(this);
    this.atheleteview = this.atheleteview.bind(this);
    this.saveresult = this.saveresult.bind(this);
    this.rpages = this.rpages.bind(this);
    this.fpages = this.fpages.bind(this);
    this.tabletennispage = this.tabletennispage.bind(this);
    this.powerlift = this.powerlift.bind(this);
    this.track = this.track.bind(this);
    this.swimming = this.swimming.bind(this);
    this.arcresult = this.arcresult.bind(this);
    this.trackresult = this.trackresult.bind(this);
    this.tennisresult = this.tennisresult.bind(this);
    this.swimresult = this.swimresult.bind(this);
    this.powerresult = this.powerresult.bind(this);
    this.fieldresult = this.fieldresult.bind(this);
  }

  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    var self = this;
    axios.get(`${url.url}/api/meets`, {}).then(function (response) {
      self.setState({
        meetdatas: response.data.data,
      });
    });

    axios.get(`${url.url}/api/venues`, {}).then(function (response) {
      self.setState({
        vdatas: response.data.data,
      });
    });
  }

  meetvenues(e) {
    var self = this;
    self.setState({
      ahome: false,
      vhome: true,
      mid: parseInt(this.state.meetdatas[e].id),
    });
  }

  atheleteview(e) {
    var v1 = parseInt(this.state.vdatas[e].id);
    axios
      .get(`${url.url}/api/getresultvenue/${v1}/${this.props.Mid}`, {})
      .then(function (response) {
        self.setState({
          resultvalues: response.data,
        });
      })
      .catch(function (error) {
        self.setState({
          resultvalues: [{}],
        });
      });

    var self = this;

    self.setState({
      venuename: this.state.vdatas[e].name,
      ahome: false,
      vhome: false,
      adetail: true,
      vid: this.state.vdatas[e].id,
    });
  }

  back() {
    var self = this;
    self.setState({
      ahome: false,
      vhome: true,
    });
  }

  saveresult() {
    Swal.fire({
      title: 'Sucess',
      text: 'Status updated successfully',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok!',
    });
  }

  // Result Page
  //Track
  trackresult() {
    const datatosend = {
      venue: this.state.tvenue,
      event: this.state.tevents,
      preliminary_results_point: this.state.tprepoint,
      preliminary_results_place: this.state.tpreplace,
      final_results_point: this.state.tfinalpoint,
      final_results_place: this.state.tfinalplace,
      created_by: 1,
    };

    // var self=this;
    axios.post(`${url.url}/api/results`, datatosend, {}).then(function (response) {
      if (response.status == 200) {
        Swal.fire({
          title: 'Success',
          text: 'successfully Updated',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      }
    });
  }

  //field
  fieldresult() {
    const datatosend = {
      venue: this.state.fvenue,
      event: this.state.fevents,
      preliminary_results_point: this.state.fprepoint,
      preliminary_results_place: this.state.fpreplace,
      final_results_point: this.state.ffinalpoint,
      final_results_place: this.state.ffinalplace,
      created_by: 1,
    };

    var self = this;
    axios.post(`${url.url}/api/results`, datatosend, {}).then(function (response) {
      if (response.status == 200) {
        Swal.fire({
          title: 'Success',
          text: 'successfully Updated',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      }
    });
  }

  //Swimming
  swimresult() {
    const datatosend = {
      venue: this.state.svenue,
      event: this.state.sevents,
      preliminary_results_point: this.state.sprepoint,
      preliminary_results_place: this.state.spreplace,
      final_results_point: this.state.sfinalpoint,
      final_results_place: this.state.sfinalplace,
      created_by: 1,
    };

    var self = this;
    axios.post(`${url.url}/api/results`, datatosend, {}).then(function (response) {
      if (response.status == 200) {
        Swal.fire({
          title: 'Success',
          text: 'successfully Updated',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      }
    });
  }
  //Archery
  arcresult() {
    const datatosend = {
      venue: this.state.Avenue,
      event: this.state.Aevents,
      preliminary_results_point: this.state.Aprepoint,
      preliminary_results_place: this.state.Apreplace,
      final_results_point: this.state.Afinalpoint,
      final_results_place: this.state.Afinalplace,
      created_by: 1,
    };

    var self = this;
    axios.post(`${url.url}/api/results`, datatosend, {}).then(function (response) {
      if (response.status == 200) {
        Swal.fire({
          title: 'Success',
          text: 'successfully Updated',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      }
    });
  }

  //powerlift
  powerresult() {
    const datatosend = {
      venue: this.state.pvenue,
      event: this.state.pevents,
      preliminary_results_point: this.state.pprepoint,
      preliminary_results_place: this.state.ppreplace,
      final_results_point: this.state.pfinalpoint,
      final_results_place: this.state.pfinalplace,
      created_by: 1,
    };

    var self = this;
    axios.post(`${url.url}/api/results`, datatosend, {}).then(function (response) {
      if (response.status == 200) {
        Swal.fire({
          title: 'Success',
          text: 'successfully Updated',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      }
    });
  }

  //table tennis
  tennisresult() {
    const datatosend = {
      venue: this.state.Tvenue,
      event: this.state.Tevents,
      preliminary_results_point: this.state.Tprepoint,
      preliminary_results_place: this.state.Tpreplace,
      final_results_point: this.state.Tfinalpoint,
      final_results_place: this.state.Tfinalplace,
      created_by: 1,
    };

    var self = this;
    axios.post(`${url.url}/api/results`, datatosend, {}).then(function (response) {
      if (response.status == 200) {
        Swal.fire({
          title: 'Success',
          text: 'successfully Updated',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      }
    });
  }
  //End of Result Page

  rpages(e) {
    var self = this;

    self.setState({
      arcshow: true,
    });
  }

  fpages() {
    var self = this;
    self.setState({
      fieldshow: true,
    });
  }

  tabletennispage() {
    var self = this;
    self.setState({
      ttennis: true,
    });
  }
  powerlift() {
    var self = this;
    self.setState({
      powerliftshow: true,
    });
  }
  track() {
    var self = this;
    self.setState({
      trackshow: true,
    });
  }
  swimming() {
    var self = this;
    self.setState({
      showswim: true,
    });
  }

  render() {
    // home ---------------------------------------------------------------
    // venue page -------------------------------------------------------------------------------------------

    if (this.state.arcshow) {
      return (
        <div>
          <div className='content'>
            <Row>
              <Col md='8'>
                <button
                  type='button'
                  data-toggle='modal'
                  data-target='#newmeet'
                  class='btn btn-danger btn-round'
                  onClick={this.props.back}
                >
                  Back
                </button>
                {''}
                <Card>
                  <CardHeader>
                    <h5 className='title'>Enter Meets Results </h5>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      {/* table --------------------------------------------------- */}
                      <Table id='my' responsive>
                        <thead>
                          <tr>
                            <th className='text-center'>#</th>
                            {/* <th>bib</th>
<th>firstname</th>
<th>lastname</th> */}
                            <th>Venue</th>
                            <th>Events</th>

                            <th>Preliminary Results point</th>
                            <th>Preliminary Results place</th>

                            <th>final Results point</th>
                            <th>final Results place</th>

                            <th class='disabled-sorting text-right'>Actions</th>
                          </tr>
                        </thead>
                        <tbody id='myTable'>
                          {this.state.resultvalues.map((question, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              {/* <td >{question.bib_no}</td>
<td >{question.user_name}</td>
<td >{question.user_name}</td> */}

                              <td>
                                <input
                                  name='Avenue'
                                  onChange={(e) => this.setState({ Avenue: e.target.value })}
                                  type='text'
                                ></input>
                              </td>
                              <td>
                                <input
                                  name='Aevents'
                                  onChange={(e) => this.setState({ Aevents: e.target.value })}
                                  type='text'
                                ></input>
                              </td>
                              <td>
                                <input
                                  name='Aprepoint'
                                  onChange={(e) => this.setState({ Aprepoint: e.target.value })}
                                  type='text'
                                ></input>
                              </td>
                              <td>
                                <input
                                  name='Apreplace'
                                  onChange={(e) => this.setState({ Apreplace: e.target.value })}
                                  type='text'
                                ></input>
                              </td>
                              <td>
                                <input
                                  name='Afinalpoint'
                                  onChange={(e) => this.setState({ Afinalpoint: e.target.value })}
                                  type='text'
                                ></input>
                              </td>
                              <td>
                                <input
                                  name='Afinalplace'
                                  onChange={(e) => this.setState({ Afinalplace: e.target.value })}
                                  type='text'
                                ></input>
                              </td>

                              <td class='td-actions text-right'>
                                {/* <button type="button" rel="tooltip" class="btn btn-success"  >Register */}
                                <button
                                  type='button'
                                  rel='tooltip'
                                  class='btn btn-success '
                                  onClick={this.arcresult}
                                  data-toggle='modal'
                                  data-target='#regmeet'
                                >
                                  Save
                                </button>
                                {` `}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      {/* <div class="form-row">
            <div class="form-group col-md-4">
                <label>bib</label>
                <input type="text" class="form-control"/>
            </div>

            <div class="form-group col-md-4">
                <label>firstname</label>
                <input type="text" class="form-control"/>
            </div>

            <div class="form-group col-md-4">
                <label>lastname</label>
                <input type="text" class="form-control"/>
            </div>

            <div class="form-group col-md-6">
                <label>Pit</label>
                <input type="text" class="form-control"/>
            </div>

            <div class="form-group col-md-6">
                <label>groups</label>
                <input type="text" class="form-control"/>
            </div>

            <div class="form-group col-md-4">
                <label>class</label>
                <input type="text" class="form-control"/>
            </div>

            <div class="form-group col-md-4">
                <label>gender</label>
                <input type="text" class="form-control"/>
            </div>

            <div class="form-group col-md-4">
                <label>age</label>
                <input type="text" class="form-control"/>
            </div>

            <div class="form-group col-md-6">
                <label>preliminary result</label>
                <input type="text" class="form-control"/>
            </div>
            <div class="form-group col-md-6">
                <label>preliminary place</label>
                <input type="text" class="form-control"/>
            </div>
            <div class="form-group col-md-4">
                <label>final result</label>
                <input type="text" class="form-control"/>
            </div>
            <div class="form-group col-md-4">
                <label>final place</label>
                <input type="text" class="form-control"/>
            </div>

            <div class="form-group col-md-4">
                <label>qualifystandard</label>
                <input type="text" class="form-control"/>
            </div>
            <div class="form-group col-md-6">
                <label>qualify</label>
                <input type="text" class="form-control"/>
            </div>
            <div class="form-group col-md-6">
                <label>Break record</label>
                <input type="text" class="form-control"/>
            </div>
            <div class="form-group col-md-4">
                <label>NDJC record</label>
                <input type="text" class="form-control"/>
            </div>
            <div class="form-group col-md-4">
                <label>1</label>
                <input type="text" class="form-control"/>
            </div>
            <div class="form-group col-md-4">
                <label>2</label>
                <input type="text" class="form-control"/>
            </div>
            <div class="form-group col-md-6">
                <label>3</label>
                <input type="text" class="form-control"/>
            </div>
            <div class="form-group col-md-6">
                <label>4</label>
                <input type="text" class="form-control"/>
            </div>
            <div class="form-group col-md-4">
                <label>Event name</label>
                <input type="text" class="form-control"/>
            </div>
            <div class="form-group col-md-4">
                <label>Type bow</label>
                <input type="text" class="form-control"/>
            </div>
            <div class="form-group col-md-4">
                <label>bow stand</label>
                <input type="text" class="form-control"/>
            </div>

            <div class="form-group col-md-6">
                <label>event team scoring</label>
                <input type="text" class="form-control"/>
            </div>

            <div class="form-group col-md-6">
                <label>Actions</label>
                <input type="text" class="form-control"/>
            </div>

            <div class="savebtn">
            <button type="button" class="btn btn-danger" onClick={this.saveresult}>Save</button>
            </div>
    </div> */}

                      {/* table --------------------------------------------------- */}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      );
    }

    if (this.state.fieldshow) {
      return (
        <div>
          <div className='content'>
            <Row>
              <Col md='8'>
                <button
                  type='button'
                  data-toggle='modal'
                  data-target='#newmeet'
                  class='btn btn-danger btn-round'
                  onClick={this.props.back}
                >
                  Back
                </button>
                {''}
                <Card>
                  <CardHeader>
                    <h5 className='title'>Enter Meets Results </h5>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <div class='form-group row'></div>
                      {/* table --------------------------------------------------- */}
                      <Table id='my' responsive>
                        <thead>
                          <tr>
                            <th className='text-center'>#</th>
                            {/* <th>Bib no</th>
<th>firstname</th>
<th>lastname</th> */}

                            <th>Venue</th>
                            <th>Events</th>

                            <th>Preliminary Results Point</th>
                            <th>Preliminary Results Place</th>

                            <th>final Results Point</th>
                            <th>final Results place </th>

                            <th class='disabled-sorting text-right'>Actions</th>
                          </tr>
                        </thead>
                        <tbody id='myTable'>
                          {this.state.resultvalues.map((question, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              {/* <td >{question.bib_no}</td>
<td >{question.user_name}</td>
<td >{question.user_name}</td> */}
                              <td>
                                <input
                                  name='Fvenue'
                                  onChange={(e) => this.setState({ Fvenue: e.target.value })}
                                  type='text'
                                ></input>
                              </td>
                              <td>
                                <input
                                  name='Fevents'
                                  onChange={(e) => this.setState({ Fevents: e.target.value })}
                                  type='text'
                                ></input>
                              </td>
                              <td>
                                <input
                                  name='Fprepoint'
                                  onChange={(e) => this.setState({ Fprepoint: e.target.value })}
                                  type='text'
                                ></input>
                              </td>
                              <td>
                                <input
                                  name='Fpreplace'
                                  onChange={(e) => this.setState({ Fpreplace: e.target.value })}
                                  type='text'
                                ></input>
                              </td>
                              <td>
                                <input
                                  name='Ffinalpoint'
                                  onChange={(e) => this.setState({ Ffinalpoint: e.target.value })}
                                  type='text'
                                ></input>
                              </td>
                              <td>
                                <input
                                  name='Ffinalplace'
                                  onChange={(e) => this.setState({ Ffinalplace: e.target.value })}
                                  type='text'
                                ></input>
                              </td>
                              {/* <td><input name='Ffinalplace'  onChange={e => this.setState({ Ffinalplace: e.target.value })}  type="text"></input></td>
<td><input name='Fwind'  onChange={e => this.setState({ Fwind: e.target.value })} type="text"></input></td>
<td><input name='Fimplno'  onChange={e => this.setState({ Fimplno: e.target.value })}  type="text"></input></td>
<td><input name='Fqtsd'  onChange={e => this.setState({ Fqtsd: e.target.value })} type="text"></input></td>
<td><input name='Fqual'   onChange={e => this.setState({ Fqual: e.target.value })} type="text"></input></td>
<td><input name='Fbreakrecord'  onChange={e => this.setState({ Fbreakrecord: e.target.value })} type="text"></input></td>
<td><input name='Fndjc'   onChange={e => this.setState({ Fndjc: e.target.value })}  type="text"></input></td>
<td><input name='F1'  onChange={e => this.setState({ F1: e.target.value })} type="text"></input></td>
<td><input name='F2'  onChange={e => this.setState({ F2: e.target.value })} type="text"></input></td>
<td><input name='F3'  onChange={e => this.setState({ F3: e.target.value })} type="text"></input></td>
<td><input name='F4'  onChange={e => this.setState({ F4: e.target.value })} type="text"></input></td>
<td><input name='Feventname'  onChange={e => this.setState({ twork: e.target.value })} type="text"></input></td>
<td><input name='Fpoints'  onChange={e => this.setState({ Fpoints: e.target.value })} type="text"></input></td>
<td><input name='Fbestmark'  onChange={e => this.setState({ Fbestmark: e.target.value })} type="text"></input></td>
<td><input name='Fperfmpoint'  onChange={e => this.setState({ Fperfmpoint: e.target.value })} type="text"></input></td>
<td><input name='Fperfmresult'  onChange={e => this.setState({ Fperfmresult: e.target.value })} type="text"></input></td>
<td><input name='Fplace'  onChange={e => this.setState({ Fplace: e.target.value })} type="text"></input></td>
<td><input name='Fwind'  onChange={e => this.setState({ Fwind: e.target.value })} type="text"></input></td>
<td><input name='Fdatetime'  onChange={e => this.setState({ Fdatetime: e.target.value })} type="text"></input></td> */}
                              <td class='td-actions text-right'>
                                {/* <button type="button" rel="tooltip" class="btn btn-success"  >Register */}
                                <button
                                  type='button'
                                  rel='tooltip'
                                  class='btn btn-success '
                                  onClick={this.fieldresult}
                                  data-toggle='modal'
                                  data-target='#regmeet'
                                >
                                  Save
                                </button>
                                {` `}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      {/* <div class="form-row">
                <div class="form-group col-md-4">
                    <label>Bib no</label>
                    <input type="text" class="form-control"/>
                </div>

                <div class="form-group col-md-4">
                    <label>firstname</label>
                    <input type="text" class="form-control"/>
                </div>

                <div class="form-group col-md-4">
                    <label>lastname</label>
                    <input type="text" class="form-control"/>
                </div>

                <div class="form-group col-md-6">
                    <label>pit</label>
                    <input type="text" class="form-control"/>
                </div>

                <div class="form-group col-md-6">
                    <label>groups</label>
                    <input type="text" class="form-control"/>
                </div>

                <div class="form-group col-md-4">
                    <label>class</label>
                    <input type="text" class="form-control"/>
                </div>

                <div class="form-group col-md-4">
                    <label>age</label>
                    <input type="text" class="form-control"/>
                </div>

                <div class="form-group col-md-4">
                    <label>gender</label>
                    <input type="text" class="form-control"/>
                </div>

                <div class="form-group col-md-6">
                    <label>final results</label>
                    <input type="text" class="form-control"/>
                </div>
                <div class="form-group col-md-6">
                    <label>final place</label>
                    <input type="text" class="form-control"/>
                </div>
                <div class="form-group col-md-4">
                    <label>wind</label>
                    <input type="text" class="form-control"/>
                </div>
                <div class="form-group col-md-4">
                    <label>implement no</label>
                    <input type="text" class="form-control"/>
                </div>

                <div class="form-group col-md-4">
                    <label>Qualify standard</label>
                    <input type="text" class="form-control"/>
                </div>
                <div class="form-group col-md-6">
                    <label>Qualifies</label>
                    <input type="text" class="form-control"/>
                </div>
                <div class="form-group col-md-6">
                    <label>break record</label>
                    <input type="text" class="form-control"/>
                </div>
                <div class="form-group col-md-4">
                    <label>Ndjc record</label>
                    <input type="text" class="form-control"/>
                </div>
                <div class="form-group col-md-4">
                    <label>1</label>
                    <input type="text" class="form-control"/>
                </div>
                <div class="form-group col-md-4">
                    <label>2</label>
                    <input type="text" class="form-control"/>
                </div>
                <div class="form-group col-md-6">
                    <label>3</label>
                    <input type="text" class="form-control"/>
                </div>
                <div class="form-group col-md-6">
                    <label>4</label>
                    <input type="text" class="form-control"/>
                </div>
                <div class="form-group col-md-4">
                    <label>Event name</label>
                    <input type="text" class="form-control"/>
                </div>
                <div class="form-group col-md-4">
                    <label>Points</label>
                    <input type="text" class="form-control"/>
                </div>
                <div class="form-group col-md-4">
                    <label>bestmark</label>
                    <input type="text" class="form-control"/>
                </div>
                <div class="form-group col-md-6">
                    <label>perfomance point</label>
                    <input type="text" class="form-control"/>
                </div>
                <div class="form-group col-md-6">
                    <label>preliminary result</label>
                    <input type="text" class="form-control"/>
                </div>
                <div class="form-group col-md-6">
                    <label>preliminary place</label>
                    <input type="text" class="form-control"/>
                </div>

                <div class="form-group col-md-6">
                    <label>wind</label>
                    <input type="text" class="form-control"/>
                </div>
                <div class="form-group col-md-6">
                    <label>datetime</label>
                    <input type="text" class="form-control"/>
                </div>
                <div class="form-group col-md-6">
                    <label>Actions</label>
                    <input type="text" class="form-control"/>
                </div>
                    <div class="savebtn">
                    <button type="button" class="btn btn-danger" onClick={this.saveresult}>Save</button>
                    </div>
        </div> */}

                      {/* table --------------------------------------------------- */}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      );
    }

    if (this.state.ttennis) {
      return (
        <div>
          <div className='content'>
            <Row>
              <Col md='8'>
                <button
                  type='button'
                  data-toggle='modal'
                  data-target='#newmeet'
                  class='btn btn-danger btn-round'
                  onClick={this.props.back}
                >
                  Back
                </button>
                {''}
                <Card>
                  <CardHeader>
                    <h5 className='title'>Enter Meets Results </h5>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <div class='form-group row'></div>
                      {/* table --------------------------------------------------- */}
                      <Table id='my' responsive>
                        <thead>
                          <tr>
                            <th className='text-center'>#</th>
                            {/* <th>bib</th>
            <th>firstname</th>
            <th>lastname</th> */}
                            <th>Venue</th>
                            <th>Events</th>

                            <th>Preliminary Results Point</th>
                            <th>Preliminary Results Place</th>

                            <th>final Results Point</th>
                            <th>final Results Place </th>

                            <th class='disabled-sorting text-right'>Actions</th>
                          </tr>
                        </thead>
                        <tbody id='myTable'>
                          {this.state.resultvalues.map((question, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              {/* <td >{question.bib_no}</td>
            <td >{question.user_name}</td>
            <td >{question.user_name}</td> */}
                              <td>
                                <input
                                  name='Tvenue'
                                  onChange={(e) => this.setState({ Tvenue: e.target.value })}
                                  type='text'
                                ></input>
                              </td>
                              <td>
                                <input
                                  name='Tevents'
                                  onChange={(e) => this.setState({ Tevents: e.target.value })}
                                  type='text'
                                ></input>
                              </td>
                              <td>
                                <input
                                  name='Tprepoint'
                                  onChange={(e) => this.setState({ Tprepoint: e.target.value })}
                                  type='text'
                                ></input>
                              </td>
                              <td>
                                <input
                                  name='Tpreplace'
                                  onChange={(e) => this.setState({ Tpreplace: e.target.value })}
                                  type='text'
                                ></input>
                              </td>
                              <td>
                                <input
                                  name='Tfinalpoint'
                                  onChange={(e) => this.setState({ Tfinalpoint: e.target.value })}
                                  type='text'
                                ></input>
                              </td>
                              <td>
                                <input
                                  name='Tfinalplace'
                                  onChange={(e) => this.setState({ Tfinalplace: e.target.value })}
                                  type='text'
                                ></input>
                              </td>

                              <td class='td-actions text-right'>
                                {/* <button type="button" rel="tooltip" class="btn btn-success"  >Register */}
                                <button
                                  type='button'
                                  rel='tooltip'
                                  class='btn btn-success '
                                  onClick={this.tennisresult}
                                  data-toggle='modal'
                                  data-target='#regmeet'
                                >
                                  Save
                                </button>
                                {` `}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      {/* <div class="form-row">
                    <div class="form-group col-md-4">
                        <label>bib</label>
                        <input type="text" class="form-control"/>
                    </div>
                    <div class="form-group col-md-4">
                        <label>firstname</label>
                        <input type="text" class="form-control"/>
                    </div>

                    <div class="form-group col-md-4">
                        <label>lastname</label>
                        <input type="text" class="form-control"/>
                    </div>

                    <div class="form-group col-md-6">
                        <label>Group</label>
                        <input type="text" class="form-control"/>
                    </div>

                    <div class="form-group col-md-6">
                        <label>class</label>
                        <input type="text" class="form-control"/>
                    </div>

                    <div class="form-group col-md-4">
                        <label>age</label>
                        <input type="text" class="form-control"/>
                    </div>

                    <div class="form-group col-md-4">
                        <label>gender</label>
                        <input type="text" class="form-control"/>
                    </div>

                    <div class="form-group col-md-4">
                        <label>final results</label>
                        <input type="text" class="form-control"/>
                    </div>

                    <div class="form-group col-md-6">
                        <label>final place</label>
                        <input type="text" class="form-control"/>
                    </div>
                    <div class="form-group col-md-6">
                        <label>qualifies</label>
                        <input type="text" class="form-control"/>
                    </div>
                    <div class="form-group col-md-6">
                        <label>event name</label>
                        <input type="text" class="form-control"/>
                    </div>

                    <div class="form-group col-md-6">
                        <label>Actions</label>
                        <input type="text" class="form-control"/>
                    </div>

                        <div class="savebtn">
                        <button type="button" class="btn btn-danger" onClick={this.saveresult}>Save</button>
                        </div>
            </div> */}

                      {/* table --------------------------------------------------- */}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      );
    }

    if (this.state.powerliftshow) {
      return (
        <div>
          <div className='content'>
            <Row>
              <Col md='8'>
                <button
                  type='button'
                  data-toggle='modal'
                  data-target='#newmeet'
                  class='btn btn-danger btn-round'
                  onClick={this.props.back}
                >
                  Back
                </button>
                {''}
                <Card>
                  <CardHeader>
                    <h5 className='title'>Enter Meets Results </h5>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <div class='form-group row'></div>
                      {/* table --------------------------------------------------- */}
                      <Table id='my' responsive>
                        <thead>
                          <tr>
                            <th className='text-center'>#</th>
                            {/* <th>Firstname</th>
        <th>lastname</th> */}
                            <th>Venue</th>
                            <th>Events</th>
                            <th>Preliminary Results Point </th>
                            <th>Preliminary Results Place</th>
                            <th>Final Results Point</th>
                            <th>Final Results Place </th>

                            <th class='disabled-sorting text-right'>Actions</th>
                          </tr>
                        </thead>
                        <tbody id='myTable'>
                          {this.state.resultvalues.map((question, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              {/* <td >{question.bib_no}</td>
        <td >{question.user_name}</td>
        <td >{question.user_name}</td> */}
                              <td>
                                <input
                                  type='text'
                                  name=''
                                  onChange={(e) => this.setState({ pvenue: e.target.value })}
                                ></input>
                              </td>
                              <td>
                                <input
                                  type='text'
                                  name=''
                                  onChange={(e) => this.setState({ pevents: e.target.value })}
                                ></input>
                              </td>
                              <td>
                                <input
                                  type='text'
                                  name=''
                                  onChange={(e) => this.setState({ pprepoint: e.target.value })}
                                ></input>
                              </td>
                              <td>
                                <input
                                  type='text'
                                  name=''
                                  onChange={(e) => this.setState({ ppreplace: e.target.value })}
                                ></input>
                              </td>
                              <td>
                                <input
                                  type='text'
                                  name=''
                                  onChange={(e) => this.setState({ pfinalpoint: e.target.value })}
                                ></input>
                              </td>
                              <td>
                                <input
                                  type='text'
                                  name=''
                                  onChange={(e) => this.setState({ pfinalplace: e.target.value })}
                                ></input>
                              </td>
                              {/* <td><input type="text" name=""   onChange={e => this.setState({ twork: e.target.value })}></input></td>
        <td><input type="text" name="" onChange={e => this.setState({ twork: e.target.value })}></input></td>
        <td><input type="text" name="" onChange={e => this.setState({ twork: e.target.value })}></input></td>
        <td><input type="text" name="" onChange={e => this.setState({ twork: e.target.value })}></input></td>
        <td><input type="text" name="" onChange={e => this.setState({ twork: e.target.value })}></input></td>
        <td><input type="text"name=""  onChange={e => this.setState({ twork: e.target.value })}></input></td>
        <td><input type="text" name="" onChange={e => this.setState({ twork: e.target.value })}></input></td> */}
                              <td class='td-actions text-right'>
                                {/* <button type="button" rel="tooltip" class="btn btn-success"  >Register */}
                                <button
                                  type='button'
                                  rel='tooltip'
                                  class='btn btn-success '
                                  onClick={this.powerresult}
                                  data-toggle='modal'
                                  data-target='#regmeet'
                                >
                                  Save
                                </button>
                                {` `}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>

                      {/* table --------------------------------------------------- */}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      );
    }

    if (this.state.trackshow) {
      return (
        <div>
          <div className='content'>
            <Row>
              <Col md='8'>
                <button
                  type='button'
                  data-toggle='modal'
                  data-target='#newmeet'
                  class='btn btn-danger btn-round'
                  onClick={this.props.back}
                >
                  Back
                </button>
                {''}
                <Card>
                  <CardHeader>
                    <h5 className='title'>Enter Meets Results </h5>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <div class='form-group row'></div>
                      {/* table --------------------------------------------------- */}
                      <Table id='my' responsive>
                        <thead>
                          <tr>
                            <th className='text-center'>#</th>
                            {/* <th>bib </th> */}
                            {/* <th>firstname</th>
            <th>lastname</th> */}
                            <th>Venue</th>
                            <th>Events</th>
                            <th>Preliminary Results Point </th>
                            <th>Preliminary Results Place </th>
                            <th>final Results Point</th>
                            <th>final Results place</th>

                            <th class='disabled-sorting text-right'>Actions</th>
                          </tr>
                        </thead>
                        <tbody id='myTable'>
                          {this.state.resultvalues.map((question, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              {/* <td >{question.bib_no}</td> */}
                              {/* <td >{question.user_name}</td>
            <td >{question.user_name}</td> */}
                              <td>
                                <input
                                  type='text'
                                  name='tvenue'
                                  onChange={(e) => this.setState({ tvenue: e.target.value })}
                                ></input>
                              </td>
                              <td>
                                <input
                                  type='text'
                                  name='tevents'
                                  onChange={(e) => this.setState({ tevents: e.target.value })}
                                ></input>
                              </td>
                              <td>
                                <input
                                  type='text'
                                  name='tprepoint'
                                  onChange={(e) => this.setState({ tprepoint: e.target.value })}
                                ></input>
                              </td>
                              <td>
                                <input
                                  type='text'
                                  name='tpreplace'
                                  onChange={(e) => this.setState({ tpreplace: e.target.value })}
                                ></input>
                              </td>
                              <td>
                                <input
                                  type='text'
                                  name='tfinalpoint'
                                  onChange={(e) => this.setState({ tfinalpoint: e.target.value })}
                                ></input>
                              </td>
                              <td>
                                <input
                                  type='text'
                                  name='tfinalplace'
                                  onChange={(e) => this.setState({ tfinalplace: e.target.value })}
                                ></input>
                              </td>
                              {/* <td><input type="text" name="tpresult" onChange={e => this.setState({ tpresult: e.target.value })} ></input></td>
            <td><input type="text" name="tpplace" onChange={e => this.setState({ tpplace: e.target.value })} ></input></td>
            <td><input type="text" name="tpwind" onChange={e => this.setState({ tpwind: e.target.value })} ></input></td> */}
                              {/* <td><input type="text" name="tcountry" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
            <td><input type="text" name="tclass" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
            <td><input type="text" name="tage" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
            <td><input type="text" name="tgender" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
            <td><input type="text" name="theatf" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
            <td><input type="text" name="tlanef" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
            <td><input type="text" name="tqualifies" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
            <td><input type="text" name="tqtime" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
            <td><input type="text" name="tbreakrecord" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
            <td><input type="text" name="t1" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
            <td><input type="text" name="t2" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
            <td><input type="text" name="t3" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
            <td><input type="text" name="t4" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
            <td><input type="text" name="theat" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
            <td><input type="text" name="theat" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
            <td><input type="text" name="theat" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
            <td><input type="text" name="t1" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
            <td><input type="text" name="t2" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
            <td><input type="text" name="t3" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
            <td><input type="text" name="t4" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
            <td><input type="text" name="theat" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
            <td><input type="text" name="theat" onChange={e => this.setState({ theat: e.target.value })} ></input></td>
            <td><input type="text" name="theat" onChange={e => this.setState({ theat: e.target.value })} ></input></td> */}

                              <td class='td-actions text-right'>
                                {/* <button type="button" rel="tooltip" class="btn btn-success"  >Register */}
                                <button
                                  type='button'
                                  rel='tooltip'
                                  class='btn btn-success '
                                  onClick={this.trackresult}
                                  data-toggle='modal'
                                  data-target='#regmeet'
                                >
                                  Save
                                </button>
                                {` `}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>

                      {/* table --------------------------------------------------- */}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      );
    }

    if (this.state.showswim) {
      return (
        <div>
          <div className='content'>
            <Row>
              <Col md='8'>
                <button
                  type='button'
                  data-toggle='modal'
                  data-target='#newmeet'
                  class='btn btn-danger btn-round'
                  onClick={this.props.back}
                >
                  Back
                </button>
                {''}
                <Card>
                  <CardHeader>
                    <h5 className='title'>Enter Meets Results </h5>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <div class='form-group row'></div>
                      {/* table --------------------------------------------------- */}

                      <Table id='my' responsive>
                        <thead>
                          <tr>
                            <th className='text-center'>#</th>
                            {/* <th>bib</th>
                    <th>Firstname</th>
                    <th>Lastname</th> */}
                            <th>Venue</th>
                            <th>Events</th>
                            <th>preliminary Results Point</th>
                            <th>preliminary Results Place</th>
                            <th>final Results Point</th>
                            <th>final Results Place</th>

                            <th class='disabled-sorting text-right'>Actions</th>
                          </tr>
                        </thead>
                        <tbody id='myTable'>
                          {this.state.resultvalues.map((question, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              {/* <td >{question.bib_no}</td>
                    <td >{question.user_name}</td>
                    <td >{question.user_name}</td> */}
                              <td>
                                <input
                                  type='text'
                                  name='svenue'
                                  onChange={(e) => this.setState({ svenue: e.target.value })}
                                ></input>
                              </td>
                              <td>
                                <input
                                  type='text'
                                  name='sevents'
                                  onChange={(e) => this.setState({ sevents: e.target.value })}
                                ></input>
                              </td>
                              <td>
                                <input
                                  type='text'
                                  name='sprepoint'
                                  onChange={(e) => this.setState({ sprepoint: e.target.value })}
                                ></input>
                              </td>
                              <td>
                                <input
                                  type='text'
                                  name='spreplace'
                                  onChange={(e) => this.setState({ spreplace: e.target.value })}
                                ></input>
                              </td>
                              <td>
                                <input
                                  type='text'
                                  name='sfinalpoint'
                                  onChange={(e) => this.setState({ sfinalpoint: e.target.value })}
                                ></input>
                              </td>
                              <td>
                                <input
                                  type='text'
                                  name='sfinalplace'
                                  onChange={(e) => this.setState({ sfinalplace: e.target.value })}
                                ></input>
                              </td>
                              {/* <td><input type="text"  name="sclass" onChange={e => this.setState({ sclass: e.target.value })}></input></td>
                    <td><input type="text"  name="sage" onChange={e => this.setState({ sage: e.target.value })}></input></td>
                    <td><input type="text"  name="sgender" onChange={e => this.setState({ sgender: e.target.value })}></input></td>
                    <td><input type="text"  name="sqtime" onChange={e => this.setState({ sqtime: e.target.value })}></input></td>
                    <td><input type="text"  name="srelaypoint" onChange={e => this.setState({ srelaypoint: e.target.value })}></input></td>
                    <td><input type="text"  name="srelaygender" onChange={e => this.setState({ srelaygender: e.target.value })}></input></td>
                    <td><input type="text"  name="srelayname" onChange={e => this.setState({ srelayname: e.target.value })}></input></td>
                    <td><input type="text"  name="seventname" onChange={e => this.setState({ seventname: e.target.value })}></input></td>
                    <td><input type="text"  name="spoints" onChange={e => this.setState({ spoints: e.target.value })}></input></td>
                    <td><input type="text"  name="srecords" onChange={e => this.setState({ srecords: e.target.value })}></input></td>
                    <td><input type="text"  name="sevents" onChange={e => this.setState({ sevents: e.target.value })}></input></td> */}
                              <td class='td-actions text-right'>
                                {/* <button type="button" rel="tooltip" class="btn btn-success"  >Register */}
                                <button
                                  type='button'
                                  rel='tooltip'
                                  class='btn btn-success '
                                  onClick={this.swimresult}
                                  data-toggle='modal'
                                  data-target='#regmeet'
                                >
                                  Save
                                </button>
                                {` `}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>

                      {/* table --------------------------------------------------- */}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      );
    }
  }
}

export default Results;
