import React from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';

import Swal from 'sweetalert2';
//import Agereload from './Agedivisionload.js'

import { Select, Input } from 'antd';
import 'antd/dist/antd.css';
import { Empty } from 'antd';
import { withRouter } from 'react-router-dom';
const url = window.globalConfig;
var x;

class Regitennis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      venues: [{}],
      venueid: [],
      tname: [],
      tenniseventdata: [],
      tennissdata: [],
      tennisevents: [],
      tabledatas: [{}],
      ranking: '',
      group: '',
      table: '',
      tenniseventsdata: '',
      // tenniseventdata: ''
    };
    this.check = this.check.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.edit = this.edit.bind(this);
    this.tinput = this.tinput.bind(this);
    // this.save = this.save.bind(this);
    this.createtennisreg = this.createtennisreg.bind(this);
    this.updatetennisreg = this.updatetennisreg.bind(this);
  }
  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    var self = this;

    let indx = sessionStorage.getItem('tennisindex');
    let ids = sessionStorage.getItem('ids');
    const tenniseventdata = (index) => {
      if (index.tabletennis_events != 'null') {
        var joined = this.state.tenniseventdata.concat(index);
        this.setState({
          tenniseventdata: joined,
        });
        //   console.log(this.state.swimeventdata)
      }
    };
    //Get Particular user register meet data
    axios.get(`${url.url}/api/getcreateregisters/${ids}`, {}).then(function (response) {
      self.setState({
        allregdatas: response.data,
      });
      {
        response.data.map((index) => tenniseventdata(index));
      }
      // console.log(response.data[parseInt(indx)])
      var valdata = self.state.tenniseventdata[parseInt(indx)].tabletennis_events.split(',');

      self.setState({
        tennissdata: self.state.tenniseventdata[parseInt(indx)],
        tennisevents: valdata,
      });
    });

    let regid = sessionStorage.getItem('regid');
    axios
      .get(`${url.url}/api/getTregisterid/${regid}`, {})
      .then(function (response) {
        self.setState({
          tabledatas: response.data,
        });
      })
      .catch(function (error) {});

    //Hide and show manager
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('resultp').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    document.getElementById('venueesss').style.display = 'block';
  }
  check = (e) => {
    var temp = [];
    if (Object.keys(this.state.tname).length != 0) {
      temp = this.state.tname;
    }
    if (!this.state.tname.some((item) => e.target.value === item.name)) {
      temp.push({ name: e.target.value, id: e.target.id });
    }
    this.setState({ tname: temp });
  };
  handlechange() {
    window.location.replace('/tennisregister');
  }

  tinput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  edit(e) {
    var self = this;
    self.setState({
      tenniseventsdata: self.state.tennisevents[e],
    });
    var loop = true;

    self.state.tabledatas.forEach((question, index) => {
      if (question.selected_events == self.state.tennisevents[e]) {
        self.setState({
          selected_id: question.id,
          selected_events: question.selected_events,
          ranking: question.ranking,
          table: question.table,
          group: question.group,

          btncheck: false,
        });
        loop = false;
        // console.log("Test");
      } else if (loop) {
        self.setState({
          selected_id: '',
          selectedevents: self.state.tennisevents[e],
          ranking: '',
          table: '',
          group: '',
          btncheck: true,
        });
        loop = true;
      }
    });

    if (self.state.tabledatas.length == 0) {
      self.setState({
        selected_id: '',
        selectedevents: self.state.tennisevents[e],
        ranking: '',
        table: '',
        group: '',
        btncheck: true,
      });
    }
  }

  createtennisreg(e) {
    var self = this;
    const setvalue = (q, index) => {
      if (self.state.tabledatas.length > index) {
        self.setState({
          selected_events: self.state.tabledatas[index].selected_events,
          ranking: self.state.tabledatas[index].ranking,
          table: self.state.tabledatas[index].table,
          group: self.state.tabledatas[index].group,
        });
      }
    };
    self.state.tennisevents.map((question, index) => {
      setvalue(question, index);
    });
    const datatosend = {
      first_name: this.state.tennissdata.firstname,
      last_name: this.state.tennissdata.lastname,
      age_division: this.state.tennissdata.tabletennis_age,
      gender: this.state.tennissdata.gender,
      selected_events: this.state.selectedevents,
      group: this.state.group,
      ranking: this.state.ranking,
      table: this.state.table,
      table_class_value: this.state.tennissdata.table_class_value,
      created_by: 1,
      register_id: parseInt(sessionStorage.getItem('regid')),
    };
    axios
      .post(`${url.url}/api/createtablereg/`, datatosend, {})
      .then(function (response) {
        if (response.status == 201) {
          Swal.fire({
            title: 'Success',
            text: 'Status updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  updatetennisreg(e) {
    var self = this;

    const datatosend = {
      first_name: this.state.tennissdata.firstname,
      last_name: this.state.tennissdata.lastname,
      age_division: this.state.tennissdata.tabletennis_age,
      gender: this.state.tennissdata.gender,
      selected_events: this.state.selected_events,
      group: this.state.group,
      ranking: this.state.ranking,
      table: this.state.table,
      table_class_value: this.state.tennissdata.table_class_value,
      created_by: 1,
    };
    axios
      .put(`${url.url}/api/updatetablereg/${this.state.selected_id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            title: 'Success',
            text: 'Status updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  render() {
    const { selectedItems } = this.state;

    return (
      <>
        <div className='content'>
          <Row>
            <Col md='8'>
              <button
                type='button'
                data-toggle='modal'
                data-target='#selecttennis'
                class='btn btn-danger btn-round'
                onClick={this.handlechange}
              >
                Back
              </button>
              <Card>
                <CardHeader>
                  <h5 className='title'>tennis Events</h5>
                </CardHeader>

                <CardBody>
                  <Form>
                    <div class='form-group row'>
                      <div class='col-xs-5'>
                        <CardHeader id='deatils_heading'>
                          <p>
                            First Name:<span>{this.state.tennissdata.firstname}</span>
                          </p>
                          <p>
                            Last Name:<span>{this.state.tennissdata.lastname}</span>
                          </p>
                          <p>
                            Table Tennis Class:<span>{this.state.tennissdata.tabletennis_class_value}</span>
                          </p>
                          <p>
                            Age Division:<span>{this.state.tennissdata.tabletennis_age}</span>
                          </p>
                          <p>
                            Gender:<span>{this.state.tennissdata.gender}</span>
                          </p>
                        </CardHeader>
                      </div>
                    </div>
                    {Object.keys(this.state.tennissdata).length == 0 ? (
                      <Empty />
                    ) : (
                      <>
                        <Table id='my' responsive>
                          <thead>
                            <tr>
                              <th>Athletes Events</th>
                              <th>Ranking</th>
                              <th>Table</th>
                              <th>Group</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody id='myTable'>
                            {this.state.tennisevents.map((question, index) => (
                              <tr>
                                <td>{question}</td>
                                {/* <td><input class="input detail_txt" id={"ranking"+index} disabled={this.state.editview} onChange={e => this.setState({ ranking: e.target.value })} name="rank" /></td>
                                                                <td><input class="input detail_txt" id={"table"+index} disabled={this.state.editview} onChange={e => this.setState({ table: e.target.value })} name="table" /></td>
                                                                <td><input class="input detail_txt" id={"group"+index} disabled={this.state.editview} onChange={e => this.setState({ group: e.target.value })}  name="group"/></td> */}
                                <td>
                                  {this.state.tabledatas.map((question1, index) =>
                                    // console.log(question1.qualifying_time),
                                    question === question1.selected_events ? question1.ranking : ''
                                  )}
                                </td>

                                <td>
                                  {this.state.tabledatas.map((question1, index) =>
                                    // console.log(question1.qualifying_time),
                                    question === question1.selected_events ? question1.table : ''
                                  )}
                                </td>

                                <td>
                                  {this.state.tabledatas.map((question1, index) =>
                                    // console.log(question1.qualifying_time),
                                    question === question1.selected_events ? question1.group : ''
                                  )}
                                </td>
                                <td>
                                  {/* <button type="button" rel="tooltip" class="btn btn-success btn-sm btn-icon"  value={index} onClick={(e) => this.save(index)} data-toggle="modal" data-target="#venueedit">
                                                                        <i class="now-ui-icons ui-2_settings-90"></i>
                                                                    </button> */}
                                  <button
                                    type='button'
                                    rel='tooltip'
                                    class='btn btn-success btn-sm btn-icon'
                                    onClick={() => this.edit(index)}
                                    data-toggle='modal'
                                    data-target='#useredit'
                                  >
                                    <i class='now-ui-icons ui-2_settings-90'></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
            {/* User table with edit delete------------------------------------------------------------------------------ */}
          </Row>
          <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
            <div class='modal-dialog'>
              <div class='modal-content'>
                <div class='modal-header '>
                  <h5 class='modal-title ' id='loginmodalLabel'>
                    {this.state.tenniseventsdata + ' ' + 'TableTennis'}{' '}
                  </h5>
                  <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <div class='modal-body'>
                  <form>
                    <div class='form-group'>
                      <label for='exampleFormControlSelect1'>Ranking</label>
                      <input
                        class='form-control'
                        value={this.state.ranking}
                        onChange={(e) => this.setState({ ranking: e.target.value })}
                        name='quatime'
                      />
                    </div>

                    <div class='form-row'>
                      <div class='form-group'>
                        <label for='inputEmail4'>Table</label>
                        <input
                          class='form-control'
                          value={this.state.table}
                          onChange={(e) => this.setState({ table: e.target.value })}
                          name='quastd'
                        />
                      </div>

                      <div class='form-group'>
                        <label for='inputPassword4'>Group</label>
                        <input
                          class='form-control'
                          value={this.state.group}
                          onChange={(e) => this.setState({ group: e.target.value })}
                          name='points'
                        />
                      </div>
                    </div>

                    {this.state.btncheck == true ? (
                      <center>
                        <button
                          type='button'
                          onClick={(e) => this.createtennisreg()}
                          data-dismiss='modal'
                          class='btn btn-danger'
                        >
                          Create
                        </button>
                      </center>
                    ) : (
                      <>
                        <center>
                          <button type='button' class='btn btn-danger' onClick={(e) => this.updatetennisreg()}>
                            Update
                          </button>
                        </center>
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Regitennis;
