import React from 'react';

import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import axios from 'axios';
import { Select, Input } from 'antd';

import './App.css';
import '../src/components/compound.css';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/bootstrap.min.css';
import './assets/css/now-ui-dashboard.css?v=1.5.0';
import './assets/demo/demo.css';
import Login from './login.js';
import Page1 from './firstpage.js';
import Home from './components/home.js';
import $ from 'jquery';
import logo from './logo.png';
import emailjs from 'emailjs-com';
import adaptive from './adaptive.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDribbble, faFacebook, faKeycdn, faTwitter, faWindows } from '@fortawesome/free-brands-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import Resultpage from './components/Dashboard.js';
import Athletevenues from './components/Athletevenues';
import Loader from 'react-loader-spinner';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from 'reactstrap';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import Swal from 'sweetalert2';
import responsiveObserve from 'antd/lib/_util/responsiveObserve';
let classdata;
const url = window.globalConfig;
class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'new',
      selectedItems: [],
      venues: [],
      venueid: [{}],
      meetdatas: [{}],
      regidata: [{}],
      venitem: [],
      value: '',
      check: false,
      rtrack: false,
      rfield: false,
      rswim: false,
      regi: false,
      Loader: true,
      dob1: '',
      dob: '',
      registered: [{}],
      trackevents: '',
      field_events: '',
      archery_events: '',
      swimevents: '',
      weightliftevents: '',
      tableevents: '',
      teamevents: '',
      fenceingevents: '',
      otherevents: '',
      trackdropdown: [],
      fielddropdown: [],
      swimdropdown: [],
      arcdropdown: [],
      weightdropdown: [],
      tabledropdown: [],
      teamdropdown: [],
      fencingdropdown: [],
      othersdropdown: [],
      selectgender: '',
      selectdata: '',
      trackvalue: [],
      fieldvalue: [],
      arcvalue: [],
      swimvalue: [],
      weightvalue: [],
      tablevalue: [],
      teamvalue: [],
      fencevalue: [],
      othervalue: [],
      registerdata: [{}],
      venueclassid: [{}],
      vclassesid: '',
      vcid: '',
      class: '',
      classdata: [{}],
      classvaluedata: '',
      selectedtrackvalue: '',
      selectedfieldvalue: '',
      selectedarcvalue: '',
      selectedswimvalue: '',
      selectedweightvalue: '',
      selectedtablevalue: '',
      selectedteamvalue: '',
      selectedfencevalue: '',
      selectedothervalue: '',
      selectdesignation: '',
      checkedvalue: 0,
      pageload: false,
      page1: true,
      tagediv: '',
      fagediv: '',
      sagediv: '',
      wagediv: '',
      oagediv: '',
      Aagediv: '',
      tabletennis_age: '',
      adaptivetennis_age: '',
      teamsports_age: '',
      age1: '',
      airrifle_age: '',
      paratriathlon100_age: '',
      paratriathlon200_age: '',
      paratriathlon500_age: '',

      boccia_age: '',
      cycling_age: '',
    };
    this.regTrack = this.regTrack.bind(this);
    this.regField = this.regField.bind(this);
    this.regSwim = this.regSwim.bind(this);
    this.regArchery = this.regArchery.bind(this);
    this.submitregister = this.submitregister.bind(this);
    this.QuaSta = this.QuaSta.bind(this);
    this.genderchange = this.genderchange.bind(this);
    this.classifiedchange = this.classifiedchange.bind(this);
    this.coachmeetchange = this.coachmeetchange.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    // this.classvalue = this.classvalue.bind(this);
    this.handleChangeChk = this.handleChangeChk.bind(this);
    this.classdata = this.classdata.bind(this);
    this.QuaSta1 = this.QuaSta1.bind(this);
    this.close = this.close.bind(this);
    this.close1 = this.close1.bind(this);
    this.handlechange2 = this.handlechange2.bind(this);
    this.handlechange1 = this.handlechange1.bind(this);
  }

  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    var self = this;
    axios.get(`${url.url}/api/venues`, {}).then(function (response) {
      self.setState({
        venues: response.data.data,
        loader: false,
      });
    });
    var self = this;
    axios.get(`${url.url}/api/meets`, {}).then(function (response) {
      self.setState({
        meetdatas: response.data.data,
      });
    });
    axios.get(`${url.url}/api/getregisterusers`, {}).then(function (response) {
      self.setState({
        registerdata: response.data,
      });
    });

    var hidenav = document.getElementById('eventset');
    hidenav.style.display = 'block';

    //Hide and show manager
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
  }

  close() {
    window.location.replace('/');
  }
  close1() {
    window.location.replace('/');
  }
  regTrack() {
    this.setState({ rtrack: true });
  }

  regField() {
    this.setState({ rfield: true });
  }

  regSwim() {
    this.setState({ rswim: true });
  }

  regArchery() {
    this.setState({ rarchery: true });
  }

  // classvalue(e) {
  //   this.setState({

  //   })
  // }

  handleChangeChk = (e) => {
    var test = e.target.value;
    var i = e.target.value.split('-');
    var l = parseInt(i[0]);
    var k = i[1];
    var m = parseInt(i[2]);
    this.setState({
      checkedvalue: l,
      selecteventval: k,
    });
    this.setState({ class: m });
    var temp = [];
    if (Object.keys(this.state.venitem).length != 0) {
      temp = this.state.venitem;
    }
    if (!this.state.venitem.some((item) => k === item.name)) {
      temp.push({
        events: this.state.venues[l].events,
        name: this.state.venues[l].name,
      });
    }

    this.setState({ venitem: temp });
    this.setState({
      value: e.target.value,
      check: !this.state.check,
    });

    if ($(e.target).prop('checked')) {
      $('.cbox').eq(l).css('display', 'block');
    } else {
      $('.cbox').eq(l).css('display', 'none');
    }
  };
  venuechange = () => {
    this.setState({ pageload: true });
  };
  classdata(e) {
    const dummy = (data) => {
      this.setState({ classdata: data });
    };
    axios.get(`${url.url}/api/geteventid/${e}`, {}).then(function (response) {
      dummy(response.data);
    });
  }

  QuaSta1(e) {
    this.setState({
      classvaluedata: e,
    });

    var l = Object.keys(this.state.venitem).length - 1;
    if (this.state.venues[this.state.checkedvalue].name == 'Track') {
      this.setState({
        selectedtrackvalue: e,
      });
    }

    if (this.state.venues[this.state.checkedvalue].name == 'Field') {
      this.setState({
        selectedfieldvalue: e,
      });
    }

    if (this.state.venues[this.state.checkedvalue].name == 'Swimming') {
      this.setState({
        selectedswimvalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'Archery') {
      this.setState({
        selectedarcvalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'WeightLift') {
      this.setState({
        selectedweightvalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'Table Tennis') {
      this.setState({
        selectedtablevalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'TeamSports') {
      this.setState({
        selectedteamvalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'Fencing') {
      this.setState({
        selectedfencevalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'Other Venues') {
      this.setState({
        selectedothervalue: e,
      });
    }
  }

  QuaSta(e, key) {
    var self = this;
    self.setState({
      value: e,
    });
    var l = Object.keys(this.state.venitem).length - 1;
    if (this.state.venues[this.state.checkedvalue].name == 'Track') {
      this.setState({
        trackvalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'Field') {
      this.setState({
        fieldvalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'Swimming') {
      this.setState({
        swimvalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'Archery') {
      this.setState({
        arcvalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'WeightLift') {
      this.setState({
        weightvalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'Table Tennis') {
      this.setState({
        tablevalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'TeamSports') {
      this.setState({
        teamvalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'Fencing') {
      this.setState({
        fencevalue: e,
      });
    }
    if (this.state.venues[this.state.checkedvalue].name == 'Other Venues') {
      this.setState({
        othervalue: e,
      });
    }

    this.classdata(key[0].id);
  }

  handlechange(e) {
    this.setState({
      selectdesignation: e.target.value,
    });
  }

  genderchange(e) {
    this.setState({
      selectgender: e.target.value,
    });
  }

  classifiedchange(e) {
    this.setState({
      selectclassified: e.target.value,
    });
  }

  coachmeetchange(e) {
    this.setState({
      selectmeetcoach: e.target.value,
    });
  }

  handlechange2(e) {
    this.setState({
      selectdso: e.target.value,
    });
  }

  //Submit Register

  submitregister(e) {
    e.preventDefault();
    let j = JSON.parse(sessionStorage.getItem('spec'));
    const datatosend = {
      firstname: this.state.fname,
      lastname: this.state.lname,
      gender: this.state.selectgender,
      email: this.state.email,
      dob: this.state.dob,
      age: this.state.age1,
      address: this.state.addr1,
      address2: this.state.addr2,
      city: this.state.city,
      state: this.state.states,
      zipcode: this.state.zipcode,
      country: this.state.country,
      homephone: this.state.homephone,
      cellphone: this.state.cellphone,
      citizenship: this.state.country,
      designation: this.state.selectdesignation,
      track_age: this.state.tagediv,
      field_age: this.state.fagediv,
      archery_age: this.state.Aagediv,
      swim_age: this.state.sagediv,
      weightlifting_age: this.state.wagediv,
      tabletennis_age: this.state.tabletennis_age,
      team_age: this.state.teamsports_age,
      fencing_age: this.state.adaptivetennis_age,
      airrifle_age: this.state.airrifle_age,
      paratriathlon100_age: this.state.paratriathlon100_age,
      paratriathlon200_age: this.state.paratriathlon200_age,
      paratriathlon500_age: this.state.paratriathlon500_age,
      boccia_age: this.state.boccia_age,
      cycling_age: this.state.cycling_age,
      othervenues_age: this.state.oagediv,
      team_name: this.state.teamname,
      headcoach_name: this.state.headcoachname,
      coach_email: this.state.coachemail,
      coach_homephone: this.state.coachhomephone,
      coach_workphone: this.state.coachworkphone,
      need_classified: this.state.selectclassified,
      coach_come_meet: this.state.selectmeetcoach,
      classification_site: this.state.classisite,
      classified_whom: this.state.classifiedby,
      sanctioned_meet: this.state.sanctioned,
      comments: this.state.comments,
      track_events: this.state.trackvalue != '' ? this.state.trackvalue.toString() : null,
      field_events: this.state.fieldvalue != '' ? this.state.fieldvalue.toString() : null,
      archery_events: this.state.arcvalue != '' ? this.state.arcvalue.toString() : null,
      swim_events: this.state.swimvalue != '' ? this.state.swimvalue.toString() : null,
      weightlift_events: this.state.weightvalue != '' ? this.state.weightvalue.toString() : null,
      tabletennis_events: this.state.tablevalue != '' ? this.state.tablevalue.toString() : null,
      team_events: this.state.teamvalue != '' ? this.state.teamvalue.toString() : null,
      fencing_events: this.state.fencevalue != '' ? this.state.fencevalue.toString() : null,
      other_events: this.state.othervalue != '' ? this.state.othervalue.toString() : null,
      meet_id: parseInt(j.id),
      track_class_value: this.state.selectedtrackvalue != '' ? this.state.selectedtrackvalue : null,
      field_class_value: this.state.selectedfieldvalue != '' ? this.state.selectedfieldvalue : null,
      archery_class_value: this.state.selectedarcvalue != '' ? this.state.selectedarcvalue : null,
      swim_class_value: this.state.selectedswimvalue != '' ? this.state.selectedswimvalue : null,
      weightlift_class_value: this.state.selectedweightvalue != '' ? this.state.selectedweightvalue : null,
      table_class_value: this.state.selectedtablevalue != '' ? this.state.selectedtablevalue : null,
      team_class_value: this.state.selectedteamvalue != '' ? this.state.selectedteamvalue : null,
      fence_class_value: this.state.selectedfencevalue != '' ? this.state.selectedfencevalue : null,
      other_class_value: this.state.selectedothervalue != '' ? this.state.selectedothervalue : null,
      ipc: this.state.ipc != '' ? this.state.ipc : null,
      DSO: this.state.selectdso,
    };

    var self = this;
    axios
      .post(`${url.url}/api/createusers`, datatosend, {})
      .then(function (response) {
        self.setState({
          registered: response.data,
          page1: false,
          pageload: true,
        });

        //window.location.replace("/athletevenues")
        //   Swal.fire({
        //     title: "Success",
        //     text: "Registered successfully",
        //     icon: "warning",
        //     showCancelButton: false,
        //     confirmButtonColor: "#3085d6",
        //     cancelButtonColor: "#d33",
        //     confirmButtonText: "Ok!",
        //   }).then(function (response) {
        //     window.location.replace("/athleteregister");
        //   });
        //  }
      })

      .catch(function (error) {
        // Swal.fire({
        //   title: "Failed",
        //   text: "Something went wrong",
        //   icon: "warning",
        //   showCancelButton: false,
        //   confirmButtonColor: "#3085d6",
        //   cancelButtonColor: "#d33",
        //   confirmButtonText: "Ok!",
        // });
      });
  }
  handleRegister(e) {
    sessionStorage.setItem('ids', this.state.meetdatas[e].id);
    sessionStorage.setItem('e', e);
    sessionStorage.getItem('ids');
    sessionStorage.setItem('spec', JSON.stringify(this.state.meetdatas[e]));

    let j = JSON.parse(sessionStorage.getItem('spec'));

    this.setState({
      kids: parseInt(j.id),
    });

    this.setState({ regi: true, regidata: this.state.meetdatas[e] });
  }
  calculate_age = (dob) => {
    var today = new Date();
    var birthDate = new Date(dob); // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = 12 - birthDate.getMonth();
    if (m < 0 || (m === 0 && 31 < birthDate.getDate())) {
      age_now--;
    }
    return age_now;
  };
  handlechange1(event) {
    this.setState({ dob: event.target.value });
    var age_latest = { age_latest: this.calculate_age(event.target.value) };
    var x = JSON.stringify(age_latest);
    var y = x.split(':');
    var z = y[1].split('}');
    this.setState({
      age1: z[0],
    });
  }

  render() {
    const { selectedItems } = this.state;

    if (this.state.pageload == true) {
      return (
        //  window.location.replace('/Trackworksheet?event=' + question.event_name)
        window.location.replace('/athletevenues?fromModule=new'),
        sessionStorage.setItem('athleteid', this.state.registered[0].id)
        //< Athletevenues registerdatas={this.state.registered}/>
      );
    }
    if (this.state.page1 == true) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='content'>
                  {/* <div class="modal-dialog2" role="document" id="registermodal"> */}
                  {/* <div class="modal-content modal-content1"> */}
                  {/* <div class="modal-header"> */}
                  {/* <h2>{this.state.meetspecfic.name}</h2> */}
                  <Row>
                    <Col md='8'>
                      <button
                        type='button'
                        class='btn btn-danger btn-round'
                        onClick={() => window.location.replace('/athleteregister')}
                      >
                        Back
                      </button>
                      <Card>
                        <CardHeader>
                          <h5 className='title'>Registration</h5>
                        </CardHeader>
                        <CardBody>
                          <Form>
                            <div class='modal-body'>
                              <form onSubmit={this.submitregister}>
                                <div class='form-group'>
                                  <label for='exampleFormControlSelect1'>Desigination</label>
                                  <select
                                    class='form-control'
                                    id='exampleFormControlSelect1'
                                    name='desg'
                                    onChange={this.handlechange}
                                    required
                                  >
                                    <option value='n/a'>Please Select</option>
                                    <option value='Athlete'>Athlete</option>
                                    <option value='headcoach'>Head Coach</option>
                                    <option value='coach'>Coach</option>
                                    <option value='assitant coach'>Assitant Coach</option>
                                    {/* <option value="parent">Parent</option>
                                <option value="staff">Support Staff</option>
                                <option value="offical">Official</option> */}
                                  </select>
                                </div>

                                <div class='form-row'>
                                  <div class='form-group col-md-6'>
                                    <label for='inputEmail4'>FirstName</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='inputEmail4'
                                      placeholder='firstname'
                                      onChange={(e) => this.setState({ fname: e.target.value })}
                                      required
                                    />
                                  </div>

                                  <div class='form-group col-md-6'>
                                    <label for='inputPassword4'>LastName</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='inputPassword4'
                                      placeholder='lastname'
                                      onChange={(e) => this.setState({ lname: e.target.value })}
                                      required
                                    />
                                  </div>
                                </div>

                                <div class='form-row'>
                                  <div class='form-group col-md-6'>
                                    <label for='email'>Email</label>
                                    <input
                                      type='email'
                                      class='form-control'
                                      id='email'
                                      placeholder='email'
                                      onChange={(e) => this.setState({ email: e.target.value })}
                                      // required
                                    />
                                  </div>

                                  <div class='form-group col-md-6'>
                                    <label for='DOB'>DOB</label>
                                    <input
                                      type='date'
                                      class='form-control'
                                      id='dob'
                                      onChange={this.handlechange1}
                                      required
                                    />
                                  </div>
                                </div>
                                <div class='form-row'>
                                  <div class='form-group col-md-6'>
                                    <label class='input' for='inputEmail4'>
                                      Age
                                    </label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='age1'
                                      value={this.state.age1}
                                      disabled
                                      // required
                                    />
                                  </div>

                                  <div class='form-group col-md-6'>
                                    <label for='inputState'>Gender</label>
                                    <select
                                      id='inputState'
                                      class='form-control'
                                      name='gender'
                                      onChange={this.genderchange}
                                      required
                                    >
                                      <option selected>Choose...</option>
                                      <option>Male</option>
                                      <option>Female</option>
                                    </select>
                                  </div>
                                </div>

                                <div class='form-row'>
                                  <div class='form-group col-md-6'>
                                    <label for='inputAddress'>Address</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='inputAddress'
                                      placeholder='Enter your address'
                                      onChange={(e) => this.setState({ addr1: e.target.value })}
                                      // required
                                    />
                                  </div>

                                  <div class='form-group col-md-6'>
                                    <label for='inputAddress2'>Address 2</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='inputAddress2'
                                      placeholder='Enter your address'
                                      onChange={(e) => this.setState({ addr2: e.target.value })}
                                    />
                                  </div>
                                </div>

                                <div class='form-row'>
                                  <div class='form-group col-md-6'>
                                    <label for='inputCity'>City</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='inputCity'
                                      placeholder='Enter city'
                                      onChange={(e) => this.setState({ city: e.target.value })}
                                      // required
                                    />
                                  </div>

                                  <div class='form-group col-md-6'>
                                    <label for='state'>State/Province</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='state'
                                      placeholder='Enter State'
                                      onChange={(e) => this.setState({ states: e.target.value })}
                                      // required
                                    />
                                  </div>

                                  <div class='form-group col-md-4'>
                                    <label for='inputZip'>Zip/Mail code</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='inputZip'
                                      placeholder='Enter Zip code'
                                      onChange={(e) => this.setState({ zipcode: e.target.value })}
                                      // required
                                    />
                                  </div>

                                  <div class='form-group col-md-4'>
                                    <label for='inputCountry'>Country</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='inputCountry'
                                      placeholder='Enter country'
                                      onChange={(e) => this.setState({ country: e.target.value })}
                                      // required
                                    />
                                  </div>

                                  <div class='form-group col-md-4'>
                                    <label for='inputCellphone'>Cellphone</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='inputCellphone'
                                      placeholder='Enter cellphone'
                                      onChange={(e) => this.setState({ cellphone: e.target.value })}
                                      required
                                    />
                                  </div>
                                  <div class='form-group col-md-4'>
                                    <label class='input'>Citizenship</label>
                                    <input
                                      type='text'
                                      id='citizenship'
                                      class='form-control'
                                      placeholder='Enter Citizenship'
                                      onChange={(e) =>
                                        this.setState({
                                          citizenship: e.target.value,
                                        })
                                      }
                                    ></input>
                                  </div>
                                  <div class='form-group col-md-4'>
                                    <label for='inputCity'>Team Name</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='inputCity'
                                      placeholder='Enter team name'
                                      onChange={(e) => this.setState({ teamname: e.target.value })}
                                      required
                                    />
                                  </div>
                                  <div class='form-group col-md-4'>
                                    <label for='ipcnum'>IPC</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='ipcnum'
                                      placeholder='Enter ipc'
                                      onChange={(e) => this.setState({ ipc: e.target.value })}
                                    />
                                  </div>
                                </div>
                                <div class='form-row'>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label for='exampleFormControlSelect1'>Track</label>
                                    <select
                                      class='form-control'
                                      value={this.state.tagediv}
                                      onChange={(e) => this.setState({ tagediv: e.target.value })}
                                      name='quatime'
                                    >
                                      <option>n/a</option>
                                      <option>U11</option>
                                      <option>U14</option>
                                      <option>U17</option>
                                      <option>U20</option>
                                      <option>Open</option>
                                      <option>M35</option>
                                      <option>M50</option>
                                      <option>M60</option>
                                    </select>
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label for='exampleFormControlSelect1'>Field</label>
                                    <select
                                      class='form-control'
                                      value={this.state.fagediv}
                                      onChange={(e) => this.setState({ fagediv: e.target.value })}
                                      name='quatime'
                                    >
                                      <option>n/a</option>
                                      <option>U11</option>
                                      <option>U14</option>
                                      <option>U17</option>
                                      <option>U20</option>
                                      <option>Open</option>
                                      <option>M35</option>
                                      <option>M50</option>
                                      <option>M60</option>
                                    </select>
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label for='exampleFormControlSelect1'>Archery</label>
                                    <select
                                      class='form-control'
                                      value={this.state.Aagediv}
                                      onChange={(e) => this.setState({ Aagediv: e.target.value })}
                                      name='quatime'
                                    >
                                      <option>n/a</option>
                                      <option>Yeoman</option>
                                      <option>U13</option>
                                      <option>U15</option>
                                      <option>U18</option>
                                      <option>U21</option>
                                      <option>Senior</option>
                                      <option>M50+</option>
                                      <option>M60+</option>
                                      <option>M70+</option>
                                    </select>
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label for='exampleFormControlSelect1'>Swimming</label>
                                    <select
                                      class='form-control'
                                      value={this.state.sagediv}
                                      onChange={(e) => this.setState({ sagediv: e.target.value })}
                                      name='quatime'
                                    >
                                      <option>n/a</option>
                                      <option>U11</option>
                                      <option>U14</option>
                                      <option>U17</option>
                                      <option>U20</option>
                                      <option>Senior</option>
                                      <option>Masters</option>
                                    </select>
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label for='exampleFormControlSelect1'>Para Powerlifting</label>
                                    <select
                                      class='form-control'
                                      value={this.state.wagediv}
                                      onChange={(e) => this.setState({ wagediv: e.target.value })}
                                      name='quatime'
                                    >
                                      <option>n/a</option>
                                      <option>Rookies</option>
                                      <option>Next Gen</option>
                                      <option>Elite</option>
                                      <option>Legends</option>
                                    </select>
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label for='exampleFormControlSelect1'>Table Tennis</label>
                                    <select
                                      class='form-control'
                                      value={this.state.tabletennis_age}
                                      onChange={(e) => this.setState({ tabletennis_age: e.target.value })}
                                      name='quatime'
                                    >
                                      <option>n/a</option>
                                      <option>U11</option>
                                      <option>U14</option>
                                      <option>U17</option>
                                      <option>U20</option>
                                      <option>U23</option>
                                      <option>Adults</option>
                                      <option>M35</option>
                                      <option>M50</option>
                                      <option>M60</option>
                                    </select>
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label for='exampleFormControlSelect1'>Adaptive Tennis</label>
                                    <select
                                      class='form-control'
                                      value={this.state.adaptivetennis_age}
                                      onChange={(e) => this.setState({ adaptivetennis_age: e.target.value })}
                                      name='quatime'
                                    >
                                      <option>n/a</option>
                                      <option>8s</option>
                                      <option>10s</option>
                                      <option>12s</option>
                                      <option>14s</option>
                                      <option>16s</option>
                                      <option>18s</option>
                                      <option>Open</option>
                                    </select>
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label for='exampleFormControlSelect1'>Team Sports</label>
                                    <select
                                      class='form-control'
                                      value={this.state.teamsports_age}
                                      onChange={(e) => this.setState({ teamsports_age: e.target.value })}
                                      name='quatime'
                                    >
                                      <option value=''>n/a</option>
                                      <option>Open</option>
                                    </select>
                                  </div>
                                  <div>Other Venues : </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label for='exampleFormControlSelect1'>Air Rifle Age</label>
                                    <select
                                      class='form-control'
                                      value={this.state.airrifle_age}
                                      onChange={(e) => this.setState({ airrifle_age: e.target.value })}
                                      name='quatime'
                                    >
                                      <option>n/a</option>
                                      <option>Sub Junior</option>
                                      <option>Junior</option>
                                      <option>Intermediate</option>
                                      <option>Adult</option>
                                      <option>Senior</option>
                                    </select>
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label for='exampleFormControlSelect1'>Paratriathlon 100 Age</label>
                                    <select
                                      class='form-control'
                                      value={this.state.paratriathlon100_age}
                                      onChange={(e) => this.setState({ paratriathlon100_age: e.target.value })}
                                      name='quatime'
                                    >
                                      <option>n/a</option>
                                      <option>Youth</option>
                                      <option>Junior</option>
                                      <option>Adult</option>
                                    </select>
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label for='exampleFormControlSelect1'>Paratriathlon 200 Age</label>
                                    <select
                                      class='form-control'
                                      value={this.state.paratriathlon200_age}
                                      onChange={(e) => this.setState({ paratriathlon200_age: e.target.value })}
                                      name='quatime'
                                    >
                                      <option>n/a</option>
                                      <option>Youth</option>
                                      <option>Junior</option>
                                      <option>Adult</option>
                                    </select>
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label for='exampleFormControlSelect1'>Paratriathlon 500 Age</label>
                                    <select
                                      class='form-control'
                                      value={this.state.paratriathlon500_age}
                                      onChange={(e) => this.setState({ paratriathlon500_age: e.target.value })}
                                      name='quatime'
                                    >
                                      <option>n/a</option>
                                      <option>Youth</option>
                                      <option>Junior</option>
                                      <option>Adult</option>
                                    </select>
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label for='exampleFormControlSelect1'>Boccia Age</label>
                                    <select
                                      class='form-control'
                                      value={this.state.boccia_age}
                                      onChange={(e) => this.setState({ boccia_age: e.target.value })}
                                      name='quatime'
                                    >
                                      <option>n/a</option>
                                      <option>Youth</option>
                                      <option>Junior</option>
                                      <option>Adult </option>
                                    </select>
                                  </div>
                                  <div class='from-group from-group1 col-md-6'>
                                    <label for='exampleFormControlSelect1'>Cycling Age</label>
                                    <select
                                      class='form-control'
                                      value={this.state.cycling_age}
                                      onChange={(e) => this.setState({ cycling_age: e.target.value })}
                                      name='quatime'
                                    >
                                      <option>n/a</option>
                                      <option>Youth</option>
                                      <option>Junior</option>
                                      <option>Adult</option>
                                    </select>
                                  </div>
                                </div>
                                <div class='form-row'>
                                  <div class='form-group col-md-4'>
                                    <label for='inputCity'>Head Coach's Name</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='inputCity'
                                      placeholder="Head coach's name"
                                      onChange={(e) =>
                                        this.setState({
                                          headcoachname: e.target.value,
                                        })
                                      }
                                      // required
                                    />
                                  </div>
                                  <div class='form-group col-md-4'>
                                    <label for='inputCity'>Coach's Home Phone</label>
                                    <input
                                      type='text'
                                      class='form-control'
                                      id='inputCity'
                                      placeholder="Coach's home phone"
                                      onChange={(e) =>
                                        this.setState({
                                          coachhomephone: e.target.value,
                                        })
                                      }
                                      // required
                                    />
                                  </div>
                                </div>
                                <div class='regisbtn'>
                                  <button class='btn btn-danger btn-round' type='submit'>
                                    Venues Setup
                                  </button>
                                </div>
                              </form>
                            </div>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
  }
}
export default Register;
