import React from 'react';

import '../App.css';
import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import { Empty } from 'antd';
import 'datatables.net';
import 'datatables.net-bs4';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
class Trackfinalworksheet extends React.Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    this.state = {
      event: queryParams.get('event'),
      event_data: [{}],
      relay: false,
      notrelay: false,
      workdata: '',
      finaldata: '',
      heatdata: '',
      lanedata: '',
      classdata: '',
      comclassdata: '',
      countrydata: '',
      rundata: '',
      bestdata: '',
      randdata: '',
      lanseqdata: '',
      eventdatas: '',
      qualifying_standard: '',
      qualifying_time: '',
      relay_points: '',
      trackdatas: [{}],
      qualtimedata: '',
      loader: true,
      finalheatdata: '',
      finallanedata: '',
      finalevent: '',
      relayfinalheatdata: '',
      relayfinallanedata: '',
      relayfinalevent: '',
    };
    this.back = this.back.bind(this);
    this.edit = this.edit.bind(this);
    this.update = this.update.bind(this);
  }
  async componentDidMount() {
    var self = this;
    const data = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    // let registerid = sessionStorage.getItem("index");
    await axios
      .post(`${url.url}/api/getregistertrackevent/`, data, {})
      .then(function (response) {
        self.setState({
          event_data: response.data,
          loader: false,
        });
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    // document.getElementById("venueesss").style.display = "block";
  }
  back() {
    window.location.replace('/venuemainpage?event=' + sessionStorage.getItem('event'));
  }

  edit(e) {
    // sessionStorage.setItem("index", e);
    var self = this;
    self.setState({
      eventdatas: this.state.event_data[e],
      finalheatdata: this.state.event_data[e].final_heat,
      finallanedata: this.state.event_data[e].final_lane,
      finalevent: this.state.event_data[e].final_event,
      relayfinalheatdata: this.state.event_data[e].relay_final_heat,
      relayfinallanedata: this.state.event_data[e].relay_final_lane,
      relayfinalevent: this.state.event_data[e].relay_final_event,
    });
  }

  update(e) {
    const datatosend = {
      final_heat: this.state.finalheatdata,
      final_lane: this.state.finallanedata,
      final_event: this.state.finalevent,
      relay_final_heat: this.state.relayfinalheatdata,
      relay_final_lane: this.state.relayfinallanedata,
      relay_final_event: this.state.relayfinalevent,
    };
    axios
      .put(`${url.url}/api/updatetrackfinalworksheet/${this.state.eventdatas.id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            title: 'Success',
            text: 'Status updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  render() {
    if (this.state.relay) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div>
                  <h1> </h1>
                  <Row>
                    <Col>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                        Back
                      </button>
                      <Card>
                        <CardHeader>
                          <h5 className='title'>
                            {this.state.event} <br></br> Final Worksheet
                          </h5>
                        </CardHeader>
                        <CardBody>
                          <Form>
                            <div class='form-group row'>
                              <div class='col-xs-5'>
                                <CardHeader>
                                  <p>Search</p>
                                  <input class='form-control' id='inputsm' placeholder='Search..' type='text' />
                                </CardHeader>
                              </div>
                            </div>
                            {Object.keys(this.state.event_data).length == 0 ? (
                              <Empty />
                            ) : (
                              <>
                                <table class='worksheet_table'>
                                  <thead>
                                    <tr class='worksheet_tr'>
                                      <th>Work Field</th>
                                      <th>Final Heat</th>
                                      <th>Final Lane</th>
                                      <th>Run Finals</th>
                                      <th>Final Groups</th>
                                      <th>Heat</th>
                                      <th>Place</th>
                                      <th>Time</th>
                                      <th>Class</th>
                                      <th>Age</th>
                                      <th>Gender</th>
                                      <th>Bib</th>
                                      <th>Last Name</th>
                                      <th>First Name</th>
                                      <th>Country</th>
                                      <th>Qual Stand</th>
                                      <th>EventName</th>
                                      <th>Event</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.event_data.map((question, index) => (
                                      <tr class='worksheet_tr'>
                                        <td>{question.workfield}</td>
                                        <td>{}</td>
                                        <td>{}</td>
                                        <td>{}</td>
                                        <td>{question.final_group}</td>
                                        <td>{question.heat}</td>
                                        <td>{question.lane}</td>
                                        <td>{question.qualifying_time}</td>
                                        <td>{question.track_class_value}</td>
                                        <td>{question.age_division}</td>
                                        <td>{question.gender}</td>
                                        <td>{question.bib_no}</td>
                                        <td>{question.last_name}</td>
                                        <td>{question.first_name}</td>
                                        <td>{question.country}</td>
                                        <td>{question.qualifying_standard}</td>
                                        <td>{question.selected_events}</td>
                                        <td>{}</td>
                                        <td>
                                          <button
                                            type='button'
                                            rel='tooltip'
                                            class='btn btn-success btn-sm btn-icon'
                                            onClick={() => this.edit(index)}
                                            data-toggle='modal'
                                            data-target='#useredit'
                                          >
                                            <i class='now-ui-icons ui-2_settings-90'></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </>
                            )}
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Final Worksheet
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          {/* <div class="form-row"> */}
                          <div class='form-row'>
                            <div class='form-group '>
                              <label for='inputCity'>Final Heat</label>
                              <input
                                class='form-control'
                                value={this.state.finalheatdata}
                                onChange={(e) => this.setState({ finalheatdata: e.target.value })}
                                name='relayletter'
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group '>
                              <label for='inputCity'>Final Lane</label>
                              <input
                                class='form-control'
                                value={this.state.finallanedata}
                                onChange={(e) => this.setState({ finallanedata: e.target.value })}
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group'>
                              <label for='inputCity'>Event</label>
                              <input
                                class='form-control'
                                value={this.state.finalevent}
                                onChange={(e) => this.setState({ finalevent: e.target.value })}
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          {/* </div> */}

                          <center>
                            <button type='button' class='btn btn-danger' onClick={(e) => this.update()}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (this.state.notrelay) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div>
                  <h1> </h1>
                  <Row>
                    <Col>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                        Back
                      </button>
                      <Card>
                        <CardHeader>
                          <h5 className='title'>
                            {this.state.event} <br></br> Final Worksheet
                          </h5>
                        </CardHeader>
                        <CardBody>
                          <Form>
                            <div class='form-group row'>
                              <div class='col-xs-5'>
                                <CardHeader>
                                  <p>Search</p>
                                  <input class='form-control' id='inputsm' placeholder='Search..' type='text' />
                                </CardHeader>
                              </div>
                            </div>
                            {Object.keys(this.state.event_data).length == 0 ? (
                              <Empty />
                            ) : (
                              <>
                                <table class='worksheet_table'>
                                  <thead>
                                    <tr class='worksheet_tr'>
                                      <th>Group</th>
                                      <th>Final Heat</th>
                                      <th>Final Lane</th>
                                      <th>Run Finals</th>
                                      <th>Final Groups</th>
                                      <th>Heat</th>
                                      <th>Place</th>
                                      <th>Time</th>
                                      <th>Class</th>
                                      <th>Age</th>
                                      <th>Gender</th>
                                      <th>Bib</th>
                                      <th>Last Name</th>
                                      <th>First Name</th>
                                      <th>Country</th>
                                      <th>Qual Stand</th>
                                      <th>EventName</th>
                                      <th>Event</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.event_data.map((question, index) => (
                                      <tr class='worksheet_tr'>
                                        <td>{question.workfield}</td>
                                        <td>{}</td>
                                        <td>{}</td>
                                        <td>{}</td>
                                        <td>{question.final_group}</td>
                                        <td>{question.heat}</td>
                                        <td>{question.lane}</td>
                                        <td>{question.qualifying_time}</td>
                                        <td>{question.track_class_value}</td>
                                        <td>{question.age_division}</td>
                                        <td>{question.gender}</td>
                                        <td>{question.bib_no}</td>
                                        <td>{question.last_name}</td>
                                        <td>{question.first_name}</td>
                                        <td>{question.country}</td>
                                        <td>{question.qualifying_standard}</td>
                                        <td>{question.selected_events}</td>
                                        <td>{}</td>

                                        <td>
                                          <button
                                            type='button'
                                            rel='tooltip'
                                            class='btn btn-success btn-sm btn-icon'
                                            onClick={() => this.edit(index)}
                                            data-toggle='modal'
                                            data-target='#useredit'
                                          >
                                            <i class='now-ui-icons ui-2_settings-90'></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </>
                            )}
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Final Worksheet
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-row'>
                            <div class='form-group '>
                              <label for='inputCity'>Final Heat</label>
                              <input
                                class='form-control'
                                value={this.state.finalheatdata}
                                onChange={(e) => this.setState({ finalheatdata: e.target.value })}
                                name='relayletter'
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group '>
                              <label for='inputCity'>Final Lane</label>
                              <input
                                class='form-control'
                                value={this.state.finallanedata}
                                onChange={(e) => this.setState({ finallanedata: e.target.value })}
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group'>
                              <label for='inputCity'>Event</label>
                              <input
                                class='form-control'
                                value={this.state.finalevent}
                                onChange={(e) => this.setState({ finalevent: e.target.value })}
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <center>
                            <button type='button' class='btn btn-danger' onClick={(e) => this.update()}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    return this.state.event == '4 x 100 Meters' ||
      this.state.event == '4 x 100 Meters Universal' ||
      this.state.event == '4 x 400 Meters' ||
      this.state.event == '800 Medley'
      ? (this.state.relay = true)
      : (this.state.notrelay = true);
  }
}

export default Trackfinalworksheet;
