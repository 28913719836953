import React from 'react';

import '../App.css';

import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';
import Swal from 'sweetalert2';
import 'antd/dist/antd.css';

import 'datatables.net';
import 'datatables.net-bs4';

import { Empty } from 'antd';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
class Eventsponser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItems: [],
      eventnames: [],
      eventids: [],
      country: '',
      options: this.options,
      meetdatas: [{}],
      file: null,
      meetspecfic: [{}],
      lorg: false,
      venues: [{}],
      shortname: '',
      meetcity: '',
      meetcountry: '',
      meetdirector: '',
      meetstartdate: '',
      meetenddate: '',
      eshortname: '',
      emeetcity: '',
      emeetcountry: '',
      emeetdirector: '',
      emeetstartdate: '',
      emeetenddate: '',
      orgname: 'sams',
      sponsername: '',
      meetsponser: [{}],
      editsponserdata: '',
      sid: '',
      specficmeet: [{}],
      ids: '',
      localcomiteename: '',
      localcomitedetail: [{}],
      editcomiteedata: '',
      sid1: '',
      editmeetdatas: [{}],
      eids: '',
      vdrop1: '',
      eventdropdown: [{}],
      newevents: [{}],
      managervenues: [{}],
      mids: '',
      btncheck: true,
      edituser: false,
      logo: '',
      areg: false,
      aresult: false,
      venueclasses: [{}],
      venueclassid: [{}],
      check: false,
      value: '',
      agedata: [{}],
      genderdata: [{}],
      pointdata: [{}],
      meetID: [{}],
      loader1: false,
      active2: false,
      active3: false,
      active4: false,
      active5: false,
      preloader: true,
      contact_column: '',
      editlogo: '',
    };
    this.handleChange2 = this.handleChange2.bind(this);
    this.createsponser = this.createsponser.bind(this);
    this.deletesponser = this.deletesponser.bind(this);
    this.editsponser = this.editsponser.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.updatesponser = this.updatesponser.bind(this);
    this.back1 = this.back1.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    let ids = sessionStorage.getItem('ids');
    if (!this.state.loader1) {
      var self = this;
      axios
        .get(`${url.url}/api/getsponser/${ids}`, {})
        .then(function (response) {
          self.setState({
            meetsponser: response.data,
            mname: response.data[0].meet_name,
          });
        })
        .catch(function (error) {
          self.setState({
            meetsponser: [{}],
            preloader: false,
          });
        });
    }
    var self = this;
    axios.get(`${url.url}/api/meets`, {}).then(function (response) {
      self.setState({
        meetdatas: response.data.data,
      });
    });
  }

  componentDidMount() {
    let ids = sessionStorage.getItem('ids');
    var self = this;
    axios
      .get(`${url.url}/api/getsponser/${ids}`, {})
      .then(function (response) {
        self.setState({
          meetsponser: response.data,
          mname: response.data[0].meet_name,
          preloader: false,
        });
        self.setState({ loader1: true });
      })
      .catch(function (error) {
        self.setState({
          meetsponser: [{}],
        });
      });

    var self = this;
    axios.get(`${url.url}/api/meets`, {}).then(function (response) {
      self.setState({
        meetdatas: response.data.data,
      });
    });

    var hidenav = document.getElementById('eventset');
    hidenav.style.display = 'block';

    //Hide and show manager
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    document.getElementById('homelink').style.display = 'block';
  }

  onSearch = (e) => {
    var value = e.target.value.toLowerCase();
    $('#myTable tr').filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
  };

  // add venues handle change
  handleChange2(selectedItems) {
    var self = this;
    self.setState({
      selectedItems,
    });
  }
  handleChange1(e) {
    var self = this;
    self.setState({
      [e.target.name]: e.target.value,
    });
  }

  // sponsers -----------------------------------------------------------------------
  createsponser() {
    let e = sessionStorage.getItem('e');
    const datatosend = {
      meet_name: this.state.meetdatas[e].name,
      meet_id: this.state.meetdatas[e].id,
      created_by: this.state.meetdatas[e].created_by,
      sponser_name: this.state.sponsername,
      contact_column: this.state.contact_column,
      sponser_logo: this.state.logo,
    };

    axios
      .post(`${url.url}/api/createsponser/`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'Created' || response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'sponsor Created successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong Please check the fields',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  deletesponser(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deletesponser/${this.state.meetsponser[e].id}`, {})
          .then(function (response) {})

          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });
  }

  editsponser(e) {
    var self = this;
    self.setState({
      editsponserdata: this.state.meetsponser[e].sponser_name,
      contact_column: this.state.meetsponser[e].contact_column,
      logo: this.state.meetsponser[e].sponser_logo,
      sid: parseInt(this.state.meetsponser[e].id),
    });
  }

  updatesponser(e) {
    e.preventDefault();
    const datatosend = {
      sponser_name: this.state.editsponserdata,
      contact_column: this.state.contact_column,
      sponser_logo: this.state.logo,
    };
    axios
      .put(`${url.url}/api/updatesponser/${this.state.sid}`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Sponsor Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  handleChange(event) {
    var self = this;
    var file = event.target.files[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      self.setState({
        logo: reader.result,
      });

      var data = reader.result.split(',')[1];
    };
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
    });
    reader.readAsDataURL(file);
  }

  back1() {
    var self = this;
    //sessionStorage.setItem("path","/meetviewmanager");
    // window.location.replace("/meetviewmanager");
    window.location.replace('/meetviewmanager');
    sessionStorage.removeItem('curnavitem');
  }

  // event sponser --------------------------------------------------------------------------------------

  render() {
    //sessionStorage.setItem("path","/eventsponser");
    return (
      <>
        {this.state.preloader ? (
          <>
            <div className='svg-wrapper'>
              <Loader
                type='ThreeDots'
                color='#00BFFF'
                height={70}
                width={70}
                // timeout={3000} //3 secs
              />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div className='content'>
              {/* <h2>{this.state.mname}</h2> */}

              <Row>
                <Col md='8'>
                  <button
                    type='button'
                    data-toggle='modal'
                    data-target='#newmeet'
                    class='btn btn-danger btn-round'
                    onClick={this.back1}
                  >
                    Back
                  </button>
                  {''}
                  <button type='button' data-toggle='modal' data-target='#newsponser' class='btn btn-danger btn-round'>
                    Add Sponsors
                  </button>
                  <Card>
                    <CardHeader>
                      <h5 className='title'>Event Sponsors</h5>
                    </CardHeader>

                    <CardBody>
                      <Form>
                        <div class='form-group row'>
                          <div class='col-xs-5'>
                            <CardHeader>
                              <p>Search</p>
                              <input
                                class='form-control'
                                id='inputsm'
                                onChange={(e) => this.onSearch(e)}
                                placeholder='Search..'
                                type='text'
                              />
                            </CardHeader>
                          </div>
                        </div>

                        {/* table --------------------------------------------------- */}
                        {Object.keys(this.state.meetsponser[0]).length == 0 ? (
                          <Empty />
                        ) : (
                          <>
                            <Table id='my' responsive>
                              <thead>
                                <tr>
                                  <th className='text-center'>#</th>
                                  <th>Sponsors</th>
                                  <th>Contact # or Email</th>
                                  <th>Sponsor Logo</th>
                                  <th class='disabled-sorting text-right'>Actions</th>
                                </tr>
                              </thead>
                              <tbody id='myTable'>
                                {this.state.meetsponser.map((question, index) => (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{question.sponser_name}</td>
                                    <td>{question.contact_column}</td>
                                    <td>
                                      <img src={question.sponser_logo}></img>
                                    </td>
                                    <td class='td-actions text-right'>
                                      <button
                                        type='button'
                                        rel='tooltip'
                                        class='btn btn-success btn-sm btn-icon'
                                        onClick={() => this.editsponser(index)}
                                        data-toggle='modal'
                                        data-target='#sponseredit'
                                      >
                                        <i class='now-ui-icons ui-2_settings-90'></i>
                                      </button>
                                      {` `}
                                      <button
                                        type='button'
                                        rel='tooltip'
                                        class='btn btn-danger btn-sm btn-icon'
                                        onClick={() => this.deletesponser(index)}
                                      >
                                        <i class='now-ui-icons ui-1_simple-remove'></i>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </>
                        )}
                        {/* table --------------------------------------------------- */}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* new sponser add ------------------------------------------------------------------------------ */}
              <div id='newsponser' class='modal fade' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header'>
                      <button type='button' class='close' data-dismiss='modal'>
                        &times;
                      </button>
                      <h4 class='modal-title'>Event Sponser</h4>
                    </div>
                    <div class='modal-body'>
                      <div class='form-row'>
                        <div class='form-group col-md-6'>
                          <label for='inputCity'>Sponsor name *</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputCity'
                            placeholder='Sponsor name'
                            onChange={(e) => this.setState({ sponsername: e.target.value })}
                            required
                          />
                        </div>
                        <div class='form-group col-md-6'>
                          <label for='inputCity'>Contact # or Email</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputCity'
                            placeholder='Contact column'
                            onChange={(e) => this.setState({ contact_column: e.target.value })}
                            required
                          />
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-3'>
                          <label for='inputZip1'>Sponsor logo</label>
                          <br></br>
                          <label
                            for='file-upload'
                            className='form-control custom-file-upload'
                            // class="custom-file-upload"
                          >
                            Logo Upload
                          </label>
                          <input id='file-upload' class='form-control' type='file' onChange={this.handleChange} />
                          {/* <img src={this.state.logo} /> */}
                        </div>
                      </div>
                    </div>
                    <div>
                      <center>
                        <button type='button' class='btn btn-danger' onClick={this.createsponser} data-dismiss='modal'>
                          Save
                        </button>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
              {/* new sponser ------------------------------------------------------------------------------ */}

              <div id='sponseredit' class='modal fade' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header'>
                      <button type='button' class='close' data-dismiss='modal'>
                        &times;
                      </button>
                      <h4 class='modal-title'>Edit Event Sponsor</h4>
                    </div>
                    <div class='modal-body'>
                      <div class='form-row'>
                        <div class='form-group col-md-6'>
                          <label for='inputCity'>Sponsor name *</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputCity'
                            placeholder='Sponsor name'
                            value={this.state.editsponserdata}
                            onChange={(e) => this.setState({ editsponserdata: e.target.value })}
                            required
                          />
                        </div>
                        <div class='form-group col-md-6'>
                          <label for='inputCity'>Contact # or Email</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputCity'
                            placeholder='Contact column'
                            value={this.state.contact_column}
                            onChange={(e) => this.setState({ contact_column: e.target.value })}
                            required
                          />
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group col-md-3'>
                          <label for='inputZip1'>Sponsor logo</label>
                          <br></br>
                          <label
                            for='file-upload'
                            class='form-control custom-file-upload'
                            // class="custom-file-upload"
                          >
                            Logo Upload
                          </label>
                          <input id='file-upload' class='form-control' type='file' onChange={this.handleChange} />
                          <img src={this.state.logo} />
                        </div>
                      </div>
                    </div>

                    <div>
                      <center>
                        <button type='button' class='btn btn-danger' onClick={this.updatesponser} data-dismiss='modal'>
                          Save
                        </button>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
export default Eventsponser;
