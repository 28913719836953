import React from 'react';

import '../App.css';
import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import { Empty } from 'antd';
import 'datatables.net';
import 'datatables.net-bs4';
import Loader from 'react-loader-spinner';
var x;
var y;
var arr = [];
const url = window.globalConfig;
function removeLeadingZeroes(timeString) {
  const data = timeString && timeString.replace(/^0+:/, '');
  const data1 = data && data.replace(/^0+:/, '');
  return data1 && data1.replace(/^0+/, '').replace(/^\./, '');
}
class Swimprelimheatsetup extends React.Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    this.state = {
      event: queryParams.get('event').split('_')[0],
      event_data: [{}],
      relay: false,
      notrelay: false,
      workdata: '',
      finaldata: '',
      heatdata: '',
      lanedata: '',
      classdata: '',
      comclassdata: '',
      countrydata: '',
      bestdata: '',
      randdata: '',
      lanseqdata: '',
      eventdatas: '',
      qualifying_standard: '',
      qualifying_time: '',
      relay_points: '',
      trackdatas: [{}],
      qualtimedata: '',
      loader: true,
      swimsetdata: [{}],
      swimsetrecord: '',
      swimsets: [{}],
      heatlanedatas: [],
      pointsgenderdata: [],
      relaysortbyheatlane: false,
      getrelayteamsname: [],
      showAlldata: [{}],
      allRelay: false,
      realyteamname: '',
      swimrelayletter: '',
      rundata: queryParams.get('event').split('_')[1],
    };
    this.back = this.back.bind(this);
    this.edit = this.edit.bind(this);
    this.update = this.update.bind(this);
  }
  async componentDidMount() {
    var self = this;
    const data = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    await axios
      .post(`${url.url}/api/getswimregistereventqualstd/`, data, {})
      .then(function (response) {
        self.setState({
          event_data: response.data,
          loader: false,
        });
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    document.getElementById('homelink').style.display = 'block';

    axios
      .get(`${url.url}/api/getallswimlocationset/`, {})
      .then(function (response) {
        self.setState({
          swimsetdata: response.data,
          swimsetrecord: response.data[0].current_record,
        });
      })
      .catch(function (error) {});

    axios.get(`${url.url}/api/getswimmeetid/${sessionStorage.getItem('ids')}`, {}).then(function (response) {
      self.setState({
        swimsets: response.data,
      });
    });
    await axios
      .post(`${url.url}/api/getrelaysingleteamname/`, data, {})
      .then(function (response) {
        self.setState({
          getrelayteamsname: response.data,
          loader: false,
        });
      })
      .catch(function (error) {
        self.setState({ loader: false });
      });
  }
  back() {
    window.location.replace('/venuemainpage?event=' + sessionStorage.getItem('event'));
  }
  backtoswim = () => {
    this.setState({
      allRelay: false,
      relay: true,
      notrelay: false,
      relaysortbyheatlane: false,
    });
  };
  edit(e) {
    var self = this;
    self.setState({
      eventdatas: this.state.event_data[e],
      workdata: this.state.event_data[e].work_field,
      finaldata: this.state.event_data[e].final_group,
      heatdata: this.state.event_data[e].heat,
      lanedata: this.state.event_data[e].lane,
      rundata: this.state.event_data[e].type_pool == null ? this.state.rundata : this.state.event_data[e].type_pool,
      realyteamname: this.state.event_data[e].realy_team_name,
      swimrelayletter: this.state.event_data[e].relay_letter,
    });
  }
  relayedit = (e) => {
    var self = this;
    self.setState({
      eventdatas: this.state.getrelayteamsname[e][0],
      workdata: this.state.getrelayteamsname[e][0],
      finaldata: this.state.getrelayteamsname[e][0],
      heatdata: this.state.getrelayteamsname[e][0].heat == null ? '' : this.state.getrelayteamsname[e][0].heat,
      lanedata: this.state.getrelayteamsname[e][0].lane == null ? '' : this.state.getrelayteamsname[e][0].lane,
      rundata:
        this.state.getrelayteamsname[e][0].type_pool == null
          ? this.state.rundata
          : this.state.getrelayteamsname[e][0].type_pool,
      realyteamname: this.state.getrelayteamsname[e][0].realy_team_name,
      swimrelayletter: this.state.getrelayteamsname[e][0].relay_letter,
    });
  };
  aftersort1edit = (e) => {
    var self = this;
    self.setState({
      eventdatas: this.state.heatlanedatas[e][0],
      workdata: this.state.heatlanedatas[e][0].work_field,
      finaldata: this.state.heatlanedatas[e][0].final_group,
      heatdata: this.state.heatlanedatas[e][0].heat == null ? '' : this.state.heatlanedatas[e][0].heat,
      lanedata: this.state.heatlanedatas[e][0].lane == null ? '' : this.state.heatlanedatas[e][0].lane,
      rundata:
        this.state.heatlanedatas[e][0].type_pool == null
          ? this.state.rundata
          : this.state.heatlanedatas[e][0].type_pool,
      realyteamname: this.state.heatlanedatas[e][0].realy_team_name,
      swimrelayletter: this.state.heatlanedatas[e][0].relay_letter,
    });
  };
  aftersort2edit = (e) => {
    var self = this;
    self.setState({
      eventdatas: this.state.pointsgenderdata[e][0],
      workdata: this.state.pointsgenderdata[e][0].work_field,
      finaldata: this.state.pointsgenderdata[e][0].final_group,
      heatdata: this.state.pointsgenderdata[e][0].heat == null ? '' : this.state.pointsgenderdata[e][0].heat,
      lanedata: this.state.pointsgenderdata[e][0].lane == null ? '' : this.state.pointsgenderdata[e][0].lane,
      rundata:
        this.state.pointsgenderdata[e][0].type_pool == null
          ? this.state.rundata
          : this.state.pointsgenderdata[e][0].type_pool,
      realyteamname: this.state.pointsgenderdata[e][0].realy_team_name,
      swimrelayletter: this.state.pointsgenderdata[e][0].relay_letter,
    });
  };

  relayupdate = (e) => {
    const datatosend = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
      heat: this.state.heatdata,
      lane: this.state.lanedata,
      realy_team_name: this.state.realyteamname,
      relay_letter: this.state.swimrelayletter,
      type_pool: this.state.rundata,
    };
    axios
      .post(`${url.url}/api/updateallswimprelimsetup`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  };
  update(e) {
    const datatosend = {
      work_field: this.state.workdata,
      final_group: this.state.finaldata,
      heat: this.state.heatdata,
      lane: this.state.lanedata,
      type_pool: this.state.rundata,
    };
    axios
      .put(`${url.url}/api/updateswimprelimheatsetup/${this.state.eventdatas.id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  sortbyheatlane = () => {
    var self = this;
    const data = {
      selected_events: self.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    axios.post(`${url.url}/api/getssortbyheatlanevalues/`, data, {}).then(function (response) {
      self.setState({
        heatlanedatas: response.data,
        relay: false,
        notrelay: false,
        relaysortbyheatlane: true,
        allRelay: false,
      });
    });
  };

  viewAll = (e) => {
    var self = this;
    const datas = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
      realy_team_name: e.split('_')[0],
      relay_letter: e.split('_')[1],
    };
    axios
      .post(`${url.url}/api/getrelayswimevent/`, datas, {})
      .then(function (response) {
        self.setState({
          showAlldata: response.data,
          allRelay: true,
          relay: false,
          notrelay: false,
          relaysortbyheatlane: false,
        });
      })
      .catch(function (error) {
        self.setState({ loader: false });
      });
  };
  relaypoints = (e, pts) => {
    var self = this;
    var relay_points = 0;

    Object.keys(this.state.showAlldata).map(function (key) {
      if (key == e) {
        self.state.showAlldata[e].map((question, index) => {
          relay_points = relay_points + parseInt(question.relay_points);
        });
      }
    });

    return (
      <p class='total_points'>
        {relay_points > pts ? relay_points + ' ' + 'Relay Points Total > Relay Points ' : relay_points}
      </p>
    );
  };
  onSearch = (e) => {
    var value = e.target.value.toLowerCase();
    if (!value) {
      return null;
    }
    $('#myTable tr').filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
  };
  render() {
    if (this.state.relay) {
      var self = this;
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='venuesetup'>
                  <h1> </h1>
                  <div className='content'>
                    <Row>
                      <Col>
                        <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                          Back
                        </button>
                        <Card className='scrollcard'>
                          <CardHeader>
                            <h5 className='title'>
                              {this.state.event} <br></br> Preliminary Heatsetup
                            </h5>
                          </CardHeader>
                          <CardBody>
                            <div class='form-group row printrow'>
                              <div class='col-xs-5'>
                                <CardHeader>
                                  <div>
                                    <p>Search</p>
                                    <input
                                      class='form-control'
                                      id='inputsm'
                                      onChange={(e) => this.onSearch(e)}
                                      placeholder='Search..'
                                      type='text'
                                    />
                                  </div>
                                  <center>
                                    <button
                                      type='button'
                                      class='btn btn-success printbtn'
                                      onClick={this.sortbyheatlane}
                                    >
                                      Sort by Heat/Lane
                                    </button>
                                    <label style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '10px' }}>
                                      Current Record :
                                    </label>
                                    <span>{this.state.swimsetrecord}</span>
                                  </center>
                                </CardHeader>
                              </div>
                            </div>
                            <Form>
                              <>
                                <table class='worksheet_table'>
                                  <thead>
                                    <tr class='worksheet_tr'>
                                      <th>Heat</th>
                                      <th>Lane</th>
                                      <th>Country</th>
                                      <th>Relay Points</th>
                                      <th>Relay Gender</th>
                                      <th>Relay Team Name</th>
                                      <th>Type Pool</th>
                                      <th>EventName</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody id='myTable'>
                                    <>
                                      {Object.keys(self.state.getrelayteamsname).map(function (key) {
                                        return (
                                          <>
                                            {self.state.getrelayteamsname[key].map((question, index) => {
                                              return (
                                                <>
                                                  <tr class='worksheet_tr'>
                                                    <td>{question.heat}</td>
                                                    <td>{question.lane}</td>
                                                    <td>{question.country}</td>
                                                    <td>{question.points}</td>
                                                    <td>{question.gender1}</td>
                                                    <td>{question.realy_team_name + ' ' + question.relay_letter}</td>
                                                    <td>{self.state.rundata}</td>
                                                    <td>{question.selected_events}</td>

                                                    <td>
                                                      <button
                                                        type='button'
                                                        rel='tooltip'
                                                        class='btn btn-success btn-sm btn-icon'
                                                        onClick={(e) => self.relayedit(key)}
                                                        data-toggle='modal'
                                                        data-target='#useredit'
                                                      >
                                                        <i class='now-ui-icons ui-2_settings-90'></i>
                                                      </button>
                                                      <button
                                                        type='button'
                                                        class='btn btn-success printbtn'
                                                        onClick={(e) =>
                                                          self.viewAll(
                                                            question.realy_team_name + '_' + question.relay_letter
                                                          )
                                                        }
                                                      >
                                                        View Team
                                                      </button>
                                                    </td>
                                                  </tr>
                                                </>
                                              );
                                            })}
                                          </>
                                        );
                                      })}
                                    </>
                                  </tbody>
                                </table>
                              </>
                              {/* )} */}
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Preliminary Heatsetup
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          {/* <div class="form-row"> */}
                          <div class='form-row'>
                            <div class='form-group'>
                              <label for='inputCity'>Heat</label>
                              <input
                                class='form-control'
                                value={this.state.heatdata}
                                onChange={(e) =>
                                  this.setState({
                                    heatdata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>

                            <div class='form-group'>
                              <label for='inputCity'>Lane</label>
                              <input
                                class='form-control'
                                value={this.state.lanedata}
                                onChange={(e) =>
                                  this.setState({
                                    lanedata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          {/* </div> */}

                          <center>
                            <button type='button' class='btn btn-danger' onClick={(e) => this.relayupdate()}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (this.state.relaysortbyheatlane) {
      var self = this;
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='venuesetup'>
                  <h1> </h1>
                  <div className='content'>
                    <Row>
                      <Col>
                        <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                          Back
                        </button>
                        <Card className='scrollcard'>
                          <CardHeader>
                            <h5 className='title'>
                              {this.state.event} <br></br> Preliminary Heatsetup
                            </h5>
                          </CardHeader>
                          <CardBody>
                            <div class='form-group row printrow'>
                              <div class='col-xs-5'>
                                <CardHeader>
                                  <div>
                                    <p>Search</p>
                                    <input
                                      class='form-control'
                                      id='inputsm'
                                      onChange={(e) => this.onSearch(e)}
                                      placeholder='Search..'
                                      type='text'
                                    />
                                  </div>
                                  <center>
                                    <button
                                      type='button'
                                      class='btn btn-success printbtn'
                                      onClick={this.sortbyheatlane}
                                    >
                                      Sort by Heat/Lane
                                    </button>
                                    <label style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '10px' }}>
                                      Current Record :
                                    </label>
                                    <span>{this.state.swimsetrecord}</span>
                                  </center>
                                </CardHeader>
                              </div>
                            </div>
                            <Form>
                              <>
                                <table class='worksheet_table'>
                                  <thead>
                                    <tr class='worksheet_tr'>
                                      <th>Heat</th>
                                      <th>Lane</th>
                                      <th>Country</th>
                                      <th>Relay Points</th>
                                      <th>Relay Gender</th>
                                      <th>Relay Team Name</th>
                                      <th>Type Fool</th>
                                      <th>EventName</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody id='myTable'>
                                    <>
                                      {Object.keys(self.state.heatlanedatas).map(function (key) {
                                        return (
                                          <>
                                            {self.state.heatlanedatas[key].map((question, index) => {
                                              return (
                                                <>
                                                  <tr class='worksheet_tr'>
                                                    <td>{question.heat}</td>
                                                    <td>{question.lane}</td>
                                                    <td>{question.country}</td>
                                                    <td>{question.points}</td>
                                                    <td>{question.gender1}</td>
                                                    <td>{question.realy_team_name + ' ' + question.relay_letter}</td>
                                                    <td>{question.type_pool}</td>
                                                    <td>{question.selected_events}</td>

                                                    <td>
                                                      <button
                                                        type='button'
                                                        rel='tooltip'
                                                        class='btn btn-success btn-sm btn-icon'
                                                        onClick={(e) => self.aftersort1edit(key)}
                                                        data-toggle='modal'
                                                        data-target='#useredit'
                                                      >
                                                        <i class='now-ui-icons ui-2_settings-90'></i>
                                                      </button>
                                                      <button
                                                        type='button'
                                                        class='btn btn-success printbtn'
                                                        onClick={(e) =>
                                                          self.viewAll(
                                                            question.realy_team_name + '_' + question.relay_letter
                                                          )
                                                        }
                                                      >
                                                        View Team
                                                      </button>
                                                    </td>
                                                  </tr>
                                                </>
                                              );
                                            })}
                                          </>
                                        );
                                      })}
                                    </>
                                  </tbody>
                                </table>
                              </>
                              {/* )} */}
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Preliminary Heatsetup
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          {/* <div class="form-row"> */}
                          <div class='form-row'>
                            <div class='form-group'>
                              <label for='inputCity'>Heat</label>
                              <input
                                class='form-control'
                                value={this.state.heatdata}
                                onChange={(e) =>
                                  this.setState({
                                    heatdata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>

                            <div class='form-group'>
                              <label for='inputCity'>Lane</label>
                              <input
                                class='form-control'
                                value={this.state.lanedata}
                                onChange={(e) =>
                                  this.setState({
                                    lanedata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          {/* </div> */}

                          <center>
                            <button type='button' class='btn btn-danger' onClick={(e) => this.relayupdate()}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (this.state.allRelay) {
      var self = this;
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='venuesetup'>
                  <h1> </h1>
                  <div className='content'>
                    <Row>
                      <Col>
                        <button type='button' class='btn btn-danger btn-round' onClick={this.backtoswim}>
                          Back
                        </button>
                        <Card className='scrollcard'>
                          <CardHeader>
                            <h5 className='title'>
                              {this.state.event} <br></br> Relay Setup
                            </h5>
                          </CardHeader>
                          <CardBody>
                            <Form>
                              {Object.keys(this.state.showAlldata).map(function (key) {
                                x = key.split('_');
                                return (
                                  <CardBody>
                                    <div class='realy_main'>
                                      <div>
                                        <p>
                                          <label class='relay_label'>Points</label>
                                          {x[2]}
                                        </p>
                                        <p>
                                          <label class='relay_label'>Gender</label>
                                          {x[3]}
                                        </p>
                                        <p>
                                          <label class='relay_label'>Team Name</label>
                                          {x[0] + ' ' + x[1]}
                                        </p>
                                        <p>
                                          <label class='relay_label'>Run As Finals</label>
                                          {self.state.showAlldata[key][0].run_during_final}
                                        </p>
                                      </div>
                                      <div class='realy_main1'>
                                        <label class='relay_label'>Relay Team Members</label>

                                        {self.state.showAlldata[key][0] ? (
                                          <p>
                                            {self.state.showAlldata[key][0].first_name}{' '}
                                            {self.state.showAlldata[key][0].last_name}
                                          </p>
                                        ) : (
                                          <p style={{ color: 'red' }}>Missing Relay Team Members</p>
                                        )}
                                        {self.state.showAlldata[key][1] ? (
                                          <p>
                                            {self.state.showAlldata[key][1].first_name}{' '}
                                            {self.state.showAlldata[key][1].last_name}
                                          </p>
                                        ) : (
                                          <p style={{ color: 'red' }}>Missing Relay Team Members</p>
                                        )}
                                        {self.state.showAlldata[key][2] ? (
                                          <p>
                                            {self.state.showAlldata[key][2].first_name}{' '}
                                            {self.state.showAlldata[key][2].last_name}
                                          </p>
                                        ) : (
                                          <p style={{ color: 'red' }}>Missing Relay Team Members</p>
                                        )}
                                        {self.state.showAlldata[key][3] ? (
                                          <p>
                                            {self.state.showAlldata[key][3].first_name}{' '}
                                            {self.state.showAlldata[key][3].last_name}
                                          </p>
                                        ) : (
                                          <p style={{ color: 'red' }}>Missing Relay Team Members</p>
                                        )}
                                      </div>
                                      <div class='realy_main1'>
                                        <label class='relay_label'>Relay points</label>
                                        {self.state.showAlldata[key].map((question, index) => (
                                          <p>{question.relay_points}</p>
                                        ))}

                                        {self.relaypoints(key, x[2])}
                                      </div>
                                      <div class='realy_main1'>
                                        <label class='relay_label'>Class</label>
                                        {self.state.showAlldata[key].map((question, index) => (
                                          <p>{question.swim_class_value}</p>
                                        ))}
                                      </div>
                                    </div>
                                  </CardBody>
                                );
                              })}
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (this.state.notrelay) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='venuesetup'>
                  <h1> </h1>
                  <div className='content'>
                    <Row>
                      <Col>
                        <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                          Back
                        </button>
                        <Card className='scrollcard'>
                          <CardHeader>
                            <h5 className='title'>
                              {this.state.event} <br></br> Preliminary Heat Setup
                            </h5>
                          </CardHeader>
                          <CardBody>
                            <div class='form-group row printrow'>
                              <div class='col-xs-5'>
                                <CardHeader>
                                  <p>Search</p>
                                  <input
                                    class='form-control'
                                    id='inputsm'
                                    onChange={(e) => this.onSearch(e)}
                                    placeholder='Search..'
                                    type='text'
                                  />
                                  <center>
                                    <div>
                                      <label style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '10px' }}>
                                        Current Record :
                                      </label>
                                      <span>{this.state.swimsetrecord}</span>
                                    </div>
                                  </center>
                                </CardHeader>
                              </div>
                            </div>
                            <Form>
                              {Object.keys(this.state.event_data).length == 0 ? (
                                <Empty />
                              ) : (
                                <>
                                  <table class='worksheet_table'>
                                    <thead>
                                      <tr class='worksheet_tr'>
                                        <th>Heat</th>
                                        <th>Lane</th>
                                        <th>Class</th>
                                        <th>Age</th>
                                        <th>Gender</th>
                                        <th>Qualtime</th>
                                        <th>Bib</th>
                                        <th>Last Name</th>
                                        <th>First Name</th>
                                        <th>Country</th>
                                        <th>Qual Stand</th>
                                        <th>Type Pool</th>
                                        <th>EventName</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody id='myTable'>
                                      {this.state.event_data.map((question, index) => (
                                        <tr class='worksheet_tr'>
                                          <td>{question.heat}</td>
                                          <td>{question.lane}</td>
                                          <td>
                                            {this.state.event === '25 Yd Breast' ||
                                            this.state.event === '50 Yd Breast' ||
                                            this.state.event === '100 Yd Breast' ||
                                            this.state.event === '200 Yd Breast' ||
                                            this.state.event === '50 M Breast' ||
                                            this.state.event === '100 M Breast' ||
                                            this.state.event === '200 M Breast '
                                              ? question.swim_breast_class
                                              : this.state.event === '3 X 50 M IM' ||
                                                this.state.event === '25 Yd IM' ||
                                                this.state.event === '3 X 50 Yd IM' ||
                                                this.state.event === '4 X 25 Yd IM' ||
                                                this.state.event === '4 X 100 Yd IM' ||
                                                this.state.event === '3 X 50 M IM' ||
                                                this.state.event === '4 X 50 M IM' ||
                                                this.state.event === '4 X 100 M IM'
                                              ? question.swim_medley_class
                                              : question.swim_class_value}
                                          </td>
                                          <td>{question.age_division}</td>
                                          <td>{question.gender}</td>
                                          <td>{question.qualifying_time}</td>
                                          <td>{question.bib_no}</td>
                                          <td>{question.last_name}</td>
                                          <td>{question.first_name}</td>
                                          <td>{question.country}</td>
                                          <td>
                                            {removeLeadingZeroes(question.qualifying_standard) ||
                                              removeLeadingZeroes(question.qual_stand)}
                                          </td>
                                          <td>{this.state.rundata}</td>
                                          <td>{question.selected_events}</td>

                                          <td>
                                            <button
                                              type='button'
                                              rel='tooltip'
                                              class='btn btn-success btn-sm btn-icon'
                                              onClick={() => this.edit(index)}
                                              data-toggle='modal'
                                              data-target='#useredit'
                                            >
                                              <i class='now-ui-icons ui-2_settings-90'></i>
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </>
                              )}
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Preliminary Heatsetup
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-row'>
                            <div class='form-group'>
                              <label for='inputCity'>Heat</label>
                              <input
                                class='form-control'
                                value={this.state.heatdata}
                                onChange={(e) =>
                                  this.setState({
                                    heatdata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group'>
                              <label for='inputCity'>Lane</label>
                              <input
                                class='form-control'
                                value={this.state.lanedata}
                                onChange={(e) =>
                                  this.setState({
                                    lanedata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <center>
                            <button type='button' class='btn btn-danger' onClick={(e) => this.update()}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    return this.state.event == '4 X 25 Yd Medley' ||
      this.state.event == '4 X 25 Yd Free' ||
      this.state.event == '4 X 50 Yd Free' ||
      this.state.event == '4 X 50 Yd Medley' ||
      this.state.event == '4 X 50 M Free' ||
      this.state.event == '4 X 50 M Medley'
      ? (this.state.relay = true)
      : (this.state.notrelay = true);
  }
}

export default Swimprelimheatsetup;
