import React from 'react';

import '../App.css';
import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';

import { Empty } from 'antd';
import 'datatables.net';
import 'datatables.net-bs4';
import Loader from 'react-loader-spinner';
var x;
var y;
var arr = [];
const url = window.globalConfig;
function removeLeadingZeroes(timeString) {
  const data = timeString && timeString.replace(/^0+:/, '');
  const data1 = data && data.replace(/^0+:/, '');
  return data1 && data1.replace(/^0+/, '').replace(/^\./, '');
}
// function timeStringToSeconds(timeString) {
//   if (!timeString) return null; // Handle null time strings
//   const [hours, minutes, seconds] = timeString.split(':').map(parseFloat);
//   return hours * 3600 + minutes * 60 + seconds;
// }
function timeStringToMilliseconds(timeString) {
  if (!timeString) return null; // Handle null time strings

  const timeParts = timeString.split(':').map(parseFloat);
  let milliseconds;

  if (timeParts.length === 3) {
    // Format: HH:MM:SS.ss
    milliseconds = timeParts[0] * 3600000 + timeParts[1] * 60000 + timeParts[2] * 1000;
  } else if (timeParts.length === 2) {
    // Format: MM:SS.ss
    milliseconds = timeParts[0] * 60000 + timeParts[1] * 1000;
  } else if (timeParts.length === 1) {
    // Format: SS.ss
    milliseconds = timeParts[0] * 1000;
  } else {
    return null; // Invalid time format
  }

  return milliseconds;
}

class PreliminaryTrackresult extends React.Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    this.state = {
      event: queryParams.get('event'),
      event_data: [{}],
      getonetrackevent: [],
      showallrelay: [{}],
      relay: false,
      notrelay: false,
      allRelay: false,
      windfinaldata: '',
      heatfinaldata: '',
      lanefinaldata: '',
      breakdata: '',
      recorddata: '',
      nationaldata: '',
      americasdata: '',
      americandata: '',
      njdcdata: '',
      trackdatas: [{}],
      prelimresultdata: '',
      prelimplacedata: '',
      finalplacedata: '',
      finalresultdata: '',
      norelayfinalplacedata: '',
      norelayfinalresultdata: '',
      norelayprelimplacedata: '',
      norelarprelimresultdata: '',
      qualifiesdata: '',
      loader: true,
      tracknjdcdatas: [{}],
      performancedatas: [{}],
      windprelimdata: '',
      trackset: [{}],
      tracksetrecord: '',
      trackatfusasamsnjdcdatas: [{}],
      runasfinal: '',
      heat: queryParams.get('heat'),
      final_group: queryParams.get('final_group'),
      finals: queryParams.get('finals'),
      sortbyclassgendername: [{}],
      sortbyclassgendernameresult: [{}],
      relaysortbyrelayclassgendername: [{}],
      relaysortbyrelayclassgendernameresult: [],
      loader: true,
      issortbyrelayclassgendername: false,
      issortbyrelayclassgendernameresult: false,
      isrelaysortbyrelayclassgendername: false,
      isrelaysortbyrelayclassgendernameresult: false,
      relayteamname: '',
      relayletter: '',
      // events : queryParams.get("eventname"),
    };
    this.back = this.back.bind(this);
    this.edit = this.edit.bind(this);
    this.update = this.update.bind(this);
  }
  async componentDidMount() {
    var self = this;
    const data = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
      heat: this.state.heat,
      final_group: this.state.final_group,
      finals: this.state.finals,
    };
    await axios
      .post(`${url.url}/api/getnewregisterevent/`, data, {})
      .then(function (response) {
        self.setState({ event_data: response.data, loader: false });
      })
      .catch(function (error) {
        self.setState({ loader: false });
      });
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    document.getElementById('homelink').style.display = 'block';

    await axios
      .post(`${url.url}/api/getonerelaytrackevent/`, data, {})
      .then(function (response) {
        self.setState({
          getonetrackevent: response.data,
          loader: false,
        });
      })
      .catch(function (error) {
        self.setState({ loader: false });
      });

    //Track njdc record
    const getdata = {
      sport: 'Track',
      event: this.state.event,
      class: this.state.event_data[0] && this.state.event_data[0].track_class_value,
      AG: this.state.event_data[0] && this.state.event_data[0].age_division,
      sex: this.state.event_data[0] && this.state.event_data[0].gender,
    };
    await axios
      .post(`${url.url}/api/gettracktnjdcrecordvalues/`, getdata, {})
      .then(function (response) {
        self.setState({ tracknjdcdatas: response.data.data });
      })
      .catch(function (error) {});

    //Track atfusasams record
    const getdatas = {
      sport: 'Track',
      event: this.state.event,
      class: this.state.event_data[0] && this.state.event_data[0].track_class_value,
      AG: this.state.event_data[0] && this.state.event_data[0].age_division,
      sex: this.state.event_data[0] && this.state.event_data[0].gender,
    };
    await axios
      .post(`${url.url}/api/gettracktfusasamsrecordvalues/`, getdatas, {})
      .then(function (response) {
        self.setState({ trackatfusasamsnjdcdatas: response.data.data });
      })
      .catch(function (error) {});

    //Performance Record Data
    const performancerecorddata = {
      Sport_Venue: 'Track',
      Event: this.state.event,
      Classification: this.state.event_data[0] && this.state.event_data[0].track_class_value,
      Age_Group: this.state.event_data[0] && this.state.event_data[0].age_division,
      Gender: this.state.event_data[0] && this.state.event_data[0].gender,
    };
    await axios
      .post(`${url.url}/api/getperformancerecord/`, performancerecorddata, {})
      .then(function (response) {
        self.setState({ performancedatas: response.data.data });
      })
      .catch(function (error) {});

    await axios
      .get(`${url.url}/api/getalltracklocationset/`, {})
      .then(function (response) {
        self.setState({ trackset: response.data, tracksetrecord: response.data[0].current_record });
      })
      .catch(function (error) {});
  }
  back() {
    window.location.replace('/venuemainpage?event=' + sessionStorage.getItem('event'));
  }
  backtotrack = () => {
    this.setState({
      loader: false,
      notrelay: false,
      issortbyrelayclassgendername: false,
      issortbyrelayclassgendernameresult: false,
      isrelaysortbyrelayclassgendername: false,
      isrelaysortbyrelayclassgendernameresult: false,
      relay: true,
      allRelay: false,
    });
  };
  edit(e) {
    var self = this;
    self.setState({
      eventdatas: this.state.event_data[e],
      prelimresultdata: this.state.event_data[e].prelim_result,
      prelimplacedata: this.state.event_data[e].prelim_place,
      finalresultdata: this.state.event_data[e].final_result,
      finalplacedata: this.state.event_data[e].final_place,
      windfinaldata: this.state.event_data[e].wind_final || '',
      heatfinaldata: this.state.event_data[e].heat_final,
      lanefinaldata: this.state.event_data[e].lane_final,
      norelarprelimresultdata: this.state.event_data[e].prelim_result || '',
      norelayprelimplacedata: this.state.event_data[e].prelim_place || '',
      norelayfinalresultdata: this.state.event_data[e].final_result || '',
      norelayfinalplacedata: this.state.event_data[e].final_place || '',
      qualifiesdata: this.state.event_data[e].qualifies,
      windprelimdata: this.state.event_data[e].wind_prelim || '',
      runasfinal: this.state.event_data[e].run_during_final,
      relayteamname: this.state.event_data[e].relay_team_name,
      relayletter: this.state.event_data[e].relay_letter,
    });
  }
  relayedit = (e) => {
    var self = this;
    self.setState({
      prelimresultdata:
        this.state.getonetrackevent[e][0].prelim_result == null ? '' : this.state.getonetrackevent[e][0].prelim_result,
      prelimplacedata:
        this.state.getonetrackevent[e][0].prelim_place == null ? '' : this.state.getonetrackevent[e][0].prelim_place,
      windprelimdata:
        this.state.getonetrackevent[e][0].wind_prelim == null ? '' : this.state.getonetrackevent[e][0].wind_prelim,
      relayteamname: this.state.getonetrackevent[e][0].relay_team_name,
      relayletter: this.state.getonetrackevent[e][0].relay_letter,
    });
  };

  aftsortedit = (e) => {
    var self = this;
    self.setState({
      eventdatas: this.state.relaysortbyrelayclassgendernameresult[e][0],
      prelimresultdata:
        this.state.relaysortbyrelayclassgendernameresult[e][0].prelim_result == null
          ? ''
          : this.state.relaysortbyrelayclassgendernameresult[e][0].prelim_result,
      prelimplacedata:
        this.state.relaysortbyrelayclassgendernameresult[e][0].prelim_place == null
          ? ''
          : this.state.relaysortbyrelayclassgendernameresult[e][0].prelim_place,
      windprelimdata:
        this.state.relaysortbyrelayclassgendernameresult[e][0].wind_prelim == null
          ? ''
          : this.state.relaysortbyrelayclassgendernameresult[e][0].wind_prelim,
      relayteamname: this.state.relaysortbyrelayclassgendernameresult[e][0].relay_team_name,
      relayletter: this.state.relaysortbyrelayclassgendernameresult[e][0].relay_letter,
    });
  };
  relayupdate = (e) => {
    const datatosend = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
      prelim_result: this.state.prelimresultdata,
      prelim_place: this.state.prelimplacedata,
      wind_prelim: this.state.windprelimdata,
      relay_team_name: this.state.relayteamname,
      relay_letter: this.state.relayletter,
    };
    axios
      .post(`${url.url}/api/updatealltrackprelimsetupresult`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  };
  clearData = () => {
    axios
      .put(`${url.url}/api/deleteResults/${this.state.eventdatas.id}`, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Data Cleared',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  };
  update(e) {
    const datatosend = {
      prelim_result: this.state.prelimresultdata,
      prelim_place: this.state.prelimplacedata,
      final_result: this.state.finalresultdata,
      final_place: this.state.finalplacedata,
      wind_final: this.state.windfinaldata,
      norelay_prelim_result: this.state.norelarprelimresultdata,
      norelay_prelim_place: this.state.norelayprelimplacedata,
      norelay_final_result: this.state.norelayfinalresultdata,
      norelay_final_place: this.state.norelayfinalplacedata,
      qualifies: this.state.qualifiesdata,
      wind_prelim: this.state.windprelimdata,
    };
    axios
      .put(`${url.url}/api/updatetrackprelimresult/${this.state.eventdatas.id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  sortbyclassgendernameresult = () => {
    var self = this;
    const sortdata = {
      selected_events: self.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    axios.post(`${url.url}/api/getsortbyclassagegenderresultvalues/`, sortdata, {}).then(function (response) {
      self.setState({
        sortbyclassgendernameresult: response.data,
        loader: false,
        notrelay: false,
        issortbyrelayclassgendername: false,
        issortbyrelayclassgendernameresult: true,
        isrelaysortbyrelayclassgendername: false,
        isrelaysortbyrelayclassgendernameresult: false,
        relay: false,
      });
    });
  };
  relaysortbyrelayclassgendername = () => {
    var self = this;
    const sortdata = {
      selected_events: self.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    axios.post(`${url.url}/api/getsortbyrelayclassagegendernamevalues/`, sortdata, {}).then(function (response) {
      self.setState({
        relaysortbyrelayclassgendername: response.data,
        loader: false,
        notrelay: false,
        issortbyrelayclassgendername: false,
        issortbyrelayclassgendernameresult: false,
        isrelaysortbyrelayclassgendername: true,
        isrelaysortbyrelayclassgendernameresult: false,
        relay: false,
      });
    });
  };
  relaysortbyrelayclassgendernameresult = () => {
    var self = this;
    const sortdata = {
      selected_events: self.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    axios.post(`${url.url}/api/getsortbyrelayclassagegenderresultvalues/`, sortdata, {}).then(function (response) {
      self.setState({
        relaysortbyrelayclassgendernameresult: response.data,
        loader: false,
        notrelay: false,
        issortbyrelayclassgendername: false,
        issortbyrelayclassgendernameresult: false,
        isrelaysortbyrelayclassgendername: false,
        isrelaysortbyrelayclassgendernameresult: true,
        relay: false,
      });
    });
  };
  viewAll = (e) => {
    var self = this;
    const datas = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
      relay_team_name: e.split('_')[0],
      relay_letter: e.split('_')[1],
    };
    axios
      .post(`${url.url}/api/getrelaytrackevent/`, datas, {})
      .then(function (response) {
        self.setState({
          showallrelay: response.data,
          loader: false,
          notrelay: false,
          issortbyrelayclassgendername: false,
          issortbyrelayclassgendernameresult: false,
          isrelaysortbyrelayclassgendername: false,
          isrelaysortbyrelayclassgendernameresult: false,
          relay: false,
          allRelay: true,
        });
      })
      .catch(function (error) {
        self.setState({ loader: false });
      });
  };
  relaypoints = (e, pts) => {
    var self = this;
    var relay_points = 0;

    Object.keys(this.state.showallrelay).map(function (key) {
      if (key == e) {
        self.state.showallrelay[e].map((question, index) => {
          relay_points = relay_points + parseInt(question.relay_points);
        });
      }
    });

    return (
      <p class='total_points'>
        {relay_points > pts ? relay_points + ' ' + 'Relay Points Total > Relay Points ' : relay_points}
      </p>
    );
  };
  onSearch = (e) => {
    var value = e.target.value.toLowerCase();
    $('#notrelay tr').filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
  };
  render() {
    var self = this;
    if (this.state.relay) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div>
                  <h1> </h1>
                  <Row>
                    <Col>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                        Back
                      </button>
                      <Card>
                        <CardHeader>
                          <h5 className='title'>
                            {this.state.event} <br></br> Preliminary Results
                          </h5>
                        </CardHeader>
                        <CardBody>
                          <div class='form-group row printrow'>
                            <div class='col-xs-5'>
                              <CardHeader>
                                <center>
                                  <div>
                                    <label style={{ fontSize: '14px', fontWeight: 'bold' }}>Current Record :</label>
                                    <span>{this.state.tracksetrecord}</span>
                                  </div>
                                </center>
                              </CardHeader>
                            </div>
                          </div>
                          <Form>
                            <>
                              <table class='worksheet_table'>
                                <thead>
                                  <tr class='worksheet_tr'>
                                    <th>Heat</th>
                                    <th>Lane</th>
                                    <th>Prelim Results</th>
                                    <th>Prelim Place</th>
                                    <th>Wind Prelim</th>
                                    <th>Final Results</th>
                                    <th>Final Place</th>
                                    <th>Wind Final</th>
                                    <th>Run As Finals</th>
                                    <th>Break Record</th>
                                    <th>Record</th>
                                    <th>Current Record</th>
                                    <th>Relay Points</th>
                                    <th>Relay Gender</th>
                                    <th>Relay Team Name</th>
                                    <th>EventName</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <>
                                    {Object.keys(self.state.getonetrackevent).map(function (key) {
                                      return (
                                        <>
                                          {self.state.getonetrackevent[key].map((question, index) => {
                                            return (
                                              <>
                                                <tr class='worksheet_tr'>
                                                  <td>{question.heat}</td>
                                                  <td>{question.lane}</td>
                                                  <td>{question.prelim_result}</td>
                                                  <td>{question.prelim_place}</td>
                                                  <td>{question.wind_prelim}</td>
                                                  <td>
                                                    {question.run_during_final == 'Yes'
                                                      ? question.prelim_result
                                                      : question.final_result}
                                                  </td>
                                                  <td>
                                                    {question.run_during_final == 'Yes'
                                                      ? question.prelim_place
                                                      : question.final_place}
                                                  </td>
                                                  <td>
                                                    {question.run_during_final == 'Yes'
                                                      ? question.wind_prelim
                                                      : question.wind_final}
                                                  </td>
                                                  <td>{question.run_during_final}</td>
                                                  <td>
                                                    {question.final_result <= question.result
                                                      ? question.result == null
                                                        ? 'NRF'
                                                        : 'Yes'
                                                      : 'No'}
                                                  </td>
                                                  <td>{self.state.tracksetrecord}</td>
                                                  <td>{removeLeadingZeroes(question.result)}</td>
                                                  <td>{question.points}</td>
                                                  <td>{question.gender1}</td>
                                                  <td>{question.relay_team_name + ' ' + question.relay_letter}</td>
                                                  <td>{question.selected_events}</td>
                                                  <td>
                                                    <button
                                                      type='button'
                                                      rel='tooltip'
                                                      class='btn btn-success btn-sm btn-icon'
                                                      onClick={(e) => self.relayedit(key)}
                                                      data-toggle='modal'
                                                      data-target='#useredit'
                                                    >
                                                      <i class='now-ui-icons ui-2_settings-90'></i>
                                                    </button>
                                                    <button
                                                      type='button'
                                                      class='btn btn-success printbtn'
                                                      onClick={(e) =>
                                                        self.viewAll(
                                                          question.relay_team_name + '_' + question.relay_letter
                                                        )
                                                      }
                                                    >
                                                      View Team
                                                    </button>
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          })}
                                        </>
                                      );
                                    })}
                                  </>
                                </tbody>
                              </table>
                            </>
                            {/* )} */}
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Preliminary Result
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          {this.state.runasfinal == 'No' ? (
                            <>
                              <div class='form-row'>
                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Prelim Result</label>
                                  <input
                                    class='form-control'
                                    value={this.state.prelimresultdata}
                                    onChange={(e) =>
                                      this.setState({
                                        prelimresultdata: e.target.value,
                                      })
                                    }
                                    name='relayletter'
                                  />
                                </div>

                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Prelim Place</label>
                                  <input
                                    class='form-control'
                                    value={this.state.prelimplacedata}
                                    onChange={(e) =>
                                      this.setState({
                                        prelimplacedata: e.target.value,
                                      })
                                    }
                                    name='relaypoint'
                                  />
                                </div>

                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Wind Prelim</label>
                                  <input
                                    class='form-control'
                                    value={this.state.windprelimdata}
                                    onChange={(e) =>
                                      this.setState({
                                        windprelimdata: e.target.value,
                                      })
                                    }
                                    name='relayletter'
                                  />
                                </div>
                              </div>

                              <div class='form-row'>
                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Final Place</label>
                                  <input
                                    class='form-control'
                                    value={this.state.finalplacedata}
                                    onChange={(e) =>
                                      this.setState({
                                        finalplacedata: e.target.value,
                                      })
                                    }
                                    name='relaypoint'
                                  />
                                </div>

                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Final Result</label>
                                  <input
                                    class='form-control'
                                    value={this.state.finalresultdata}
                                    onChange={(e) =>
                                      this.setState({
                                        finalresultdata: e.target.value,
                                      })
                                    }
                                    name='relaypoint'
                                  />
                                </div>

                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Wind Final</label>
                                  <input
                                    class='form-control'
                                    value={this.state.windfinaldata}
                                    onChange={(e) =>
                                      this.setState({
                                        windfinaldata: e.target.value,
                                      })
                                    }
                                    name='relayletter'
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div class='form-row'>
                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Prelim Result</label>
                                  <input
                                    class='form-control'
                                    value={this.state.prelimresultdata}
                                    onChange={(e) =>
                                      this.setState({
                                        prelimresultdata: e.target.value,
                                      })
                                    }
                                    name='relayletter'
                                  />
                                </div>

                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Prelim Place</label>
                                  <input
                                    class='form-control'
                                    value={this.state.prelimplacedata}
                                    onChange={(e) =>
                                      this.setState({
                                        prelimplacedata: e.target.value,
                                      })
                                    }
                                    name='relaypoint'
                                  />
                                </div>

                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Wind Prelim</label>
                                  <input
                                    class='form-control'
                                    value={this.state.windprelimdata}
                                    onChange={(e) =>
                                      this.setState({
                                        windprelimdata: e.target.value,
                                      })
                                    }
                                    name='relayletter'
                                  />
                                </div>
                              </div>
                            </>
                          )}

                          <center>
                            <button type='button' class='btn btn-danger' onClick={(e) => this.relayupdate()}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }

    if (this.state.isrelaysortbyrelayclassgendernameresult) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div>
                  <h1> </h1>
                  <Row>
                    <Col>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                        Back
                      </button>
                      <Card>
                        <CardHeader>
                          <h5 className='title'>
                            {this.state.event} <br></br> Preliminary Worksheet
                          </h5>
                        </CardHeader>
                        <CardBody>
                          <div class='form-group row printrow'>
                            <div class='col-xs-5'>
                              <CardHeader>
                                <center>
                                  <div>
                                    <label style={{ fontSize: '14px', fontWeight: 'bold' }}>Current Record :</label>
                                    <span>{this.state.tracksetrecord}</span>
                                  </div>
                                </center>
                              </CardHeader>
                            </div>
                          </div>
                          <Form>
                            <>
                              <table class='worksheet_table'>
                                <thead>
                                  <tr class='worksheet_tr'>
                                    <th>Heat</th>
                                    <th>Lane</th>
                                    <th>Prelim Results</th>
                                    <th>Prelim Place</th>
                                    <th>Wind Prelim</th>
                                    <th>Final Results</th>
                                    <th>Final Place</th>
                                    <th>Wind Final</th>
                                    <th>Run As Finals</th>
                                    <th>Break Record</th>
                                    <th>Record</th>
                                    <th>Current Record</th>
                                    <th>Relay Points</th>
                                    <th>Relay Gender</th>
                                    <th>Relay Team Name</th>
                                    <th>EventName</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <>
                                    {Object.keys(self.state.relaysortbyrelayclassgendernameresult).map(function (key) {
                                      return (
                                        <>
                                          {self.state.relaysortbyrelayclassgendernameresult[key].map(
                                            (question, index) => {
                                              return (
                                                <>
                                                  <tr class='worksheet_tr'>
                                                    <td>{question.heat}</td>
                                                    <td>{question.lane}</td>
                                                    <td>{question.prelim_result}</td>
                                                    <td>{question.prelim_place}</td>
                                                    <td>{question.wind_prelim}</td>
                                                    <td>
                                                      {question.run_during_final == 'Yes'
                                                        ? question.final_result
                                                        : question.prelim_result}
                                                    </td>
                                                    <td>
                                                      {question.run_during_final == 'Yes'
                                                        ? question.final_place
                                                        : question.prelim_place}
                                                    </td>
                                                    <td>
                                                      {question.run_during_final == 'Yes'
                                                        ? question.wind_final
                                                        : question.wind_prelim}
                                                    </td>
                                                    <td>{question.run_during_final}</td>
                                                    <td>
                                                      {question.final_result <= question.result
                                                        ? question.result == null
                                                          ? 'NRF'
                                                          : 'Yes'
                                                        : 'No'}
                                                    </td>
                                                    <td>{self.state.tracksetrecord}</td>
                                                    <td>{removeLeadingZeroes(question.result)}</td>
                                                    <td>{question.points}</td>
                                                    <td>{question.gender1}</td>
                                                    <td>{question.relay_team_name + ' ' + question.relay_letter}</td>
                                                    <td>{question.selected_events}</td>
                                                    <td>
                                                      <button
                                                        type='button'
                                                        rel='tooltip'
                                                        class='btn btn-success btn-sm btn-icon'
                                                        onClick={(e) => self.aftsortedit(key)}
                                                        data-toggle='modal'
                                                        data-target='#useredit'
                                                      >
                                                        <i class='now-ui-icons ui-2_settings-90'></i>
                                                      </button>
                                                      <button
                                                        type='button'
                                                        class='btn btn-success printbtn'
                                                        onClick={(e) =>
                                                          self.viewAll(
                                                            question.relay_team_name + '_' + question.relay_letter
                                                          )
                                                        }
                                                      >
                                                        View Team
                                                      </button>
                                                    </td>
                                                  </tr>
                                                </>
                                              );
                                            }
                                          )}
                                        </>
                                      );
                                    })}
                                  </>
                                </tbody>
                              </table>
                            </>
                            {/* )} */}
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Preliminary Worksheet
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          {this.state.runasfinal == 'No' ? (
                            <>
                              <div class='form-row'>
                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Prelim Result</label>
                                  <input
                                    class='form-control'
                                    value={this.state.prelimresultdata}
                                    onChange={(e) =>
                                      this.setState({
                                        prelimresultdata: e.target.value,
                                      })
                                    }
                                    name='relayletter'
                                  />
                                </div>

                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Prelim Place</label>
                                  <input
                                    class='form-control'
                                    value={this.state.prelimplacedata}
                                    onChange={(e) =>
                                      this.setState({
                                        prelimplacedata: e.target.value,
                                      })
                                    }
                                    name='relaypoint'
                                  />
                                </div>

                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Wind Prelim</label>
                                  <input
                                    class='form-control'
                                    value={this.state.windprelimdata}
                                    onChange={(e) =>
                                      this.setState({
                                        windprelimdata: e.target.value,
                                      })
                                    }
                                    name='relayletter'
                                  />
                                </div>
                              </div>

                              <div class='form-row'>
                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Final Place</label>
                                  <input
                                    class='form-control'
                                    value={this.state.finalplacedata}
                                    onChange={(e) =>
                                      this.setState({
                                        finalplacedata: e.target.value,
                                      })
                                    }
                                    name='relaypoint'
                                  />
                                </div>

                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Final Result</label>
                                  <input
                                    class='form-control'
                                    value={this.state.finalresultdata}
                                    onChange={(e) =>
                                      this.setState({
                                        finalresultdata: e.target.value,
                                      })
                                    }
                                    name='relaypoint'
                                  />
                                </div>

                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Wind Final</label>
                                  <input
                                    class='form-control'
                                    value={this.state.windfinaldata}
                                    onChange={(e) =>
                                      this.setState({
                                        windfinaldata: e.target.value,
                                      })
                                    }
                                    name='relayletter'
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div class='form-row'>
                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Prelim Result</label>
                                  <input
                                    class='form-control'
                                    value={this.state.prelimresultdata}
                                    onChange={(e) =>
                                      this.setState({
                                        prelimresultdata: e.target.value,
                                      })
                                    }
                                    name='relayletter'
                                  />
                                </div>

                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Prelim Place</label>
                                  <input
                                    class='form-control'
                                    value={this.state.prelimplacedata}
                                    onChange={(e) =>
                                      this.setState({
                                        prelimplacedata: e.target.value,
                                      })
                                    }
                                    name='relaypoint'
                                  />
                                </div>

                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Wind Prelim</label>
                                  <input
                                    class='form-control'
                                    value={this.state.windprelimdata}
                                    onChange={(e) =>
                                      this.setState({
                                        windprelimdata: e.target.value,
                                      })
                                    }
                                    name='relayletter'
                                  />
                                </div>
                              </div>
                            </>
                          )}

                          <center>
                            <button type='button' class='btn btn-danger' onClick={(e) => this.relayupdate()}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (this.state.allRelay) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='venuesetup'>
                  <h1> </h1>
                  <div className='content'>
                    <Row>
                      <Col>
                        <button type='button' class='btn btn-danger btn-round' onClick={this.backtotrack}>
                          Back
                        </button>
                        <Card>
                          <CardHeader>
                            <h5 className='title'>
                              {this.state.event} <br></br> Relay Setup
                            </h5>
                          </CardHeader>
                          <CardBody>
                            <Form>
                              {Object.keys(this.state.showallrelay).map(function (key) {
                                x = key.split('_');
                                return (
                                  <CardBody>
                                    <div class='realy_main'>
                                      <div>
                                        <p>
                                          <label class='relay_label'>Points</label>
                                          {x[2]}
                                        </p>
                                        <p>
                                          <label class='relay_label'>Gender</label>
                                          {x[3]}
                                        </p>
                                        <p>
                                          <label class='relay_label'>Team Name</label>
                                          {x[0] + ' ' + x[1]}
                                        </p>
                                        <p>
                                          <label class='relay_label'>Run As Finals</label>
                                          {self.state.showallrelay[key][0].run_during_final}
                                        </p>
                                      </div>
                                      <div class='realy_main1'>
                                        <label class='relay_label'>Relay Team Members</label>

                                        {self.state.showallrelay[key][0] ? (
                                          <p>
                                            {self.state.showallrelay[key][0].first_name}{' '}
                                            {self.state.showallrelay[key][0].last_name}
                                          </p>
                                        ) : (
                                          <p style={{ color: 'red' }}>Missing Relay Team Members</p>
                                        )}
                                        {self.state.showallrelay[key][1] ? (
                                          <p>
                                            {self.state.showallrelay[key][1].first_name}{' '}
                                            {self.state.showallrelay[key][1].last_name}
                                          </p>
                                        ) : (
                                          <p style={{ color: 'red' }}>Missing Relay Team Members</p>
                                        )}
                                        {self.state.showallrelay[key][2] ? (
                                          <p>
                                            {self.state.showallrelay[key][2].first_name}{' '}
                                            {self.state.showallrelay[key][2].last_name}
                                          </p>
                                        ) : (
                                          <p style={{ color: 'red' }}>Missing Relay Team Members</p>
                                        )}
                                        {self.state.showallrelay[key][3] ? (
                                          <p>
                                            {self.state.showallrelay[key][3].first_name}{' '}
                                            {self.state.showallrelay[key][3].last_name}
                                          </p>
                                        ) : (
                                          <p style={{ color: 'red' }}>Missing Relay Team Members</p>
                                        )}
                                      </div>
                                      <div class='realy_main1'>
                                        <label class='relay_label'>Relay points</label>
                                        {self.state.showallrelay[key].map((question, index) => (
                                          <p>{question.relay_points}</p>
                                        ))}

                                        {self.relaypoints(key, x[2])}
                                      </div>
                                      <div class='realy_main1'>
                                        <label class='relay_label'>Class</label>
                                        {self.state.showallrelay[key].map((question, index) => (
                                          <p>{question.track_class_value}</p>
                                        ))}
                                      </div>
                                    </div>
                                  </CardBody>
                                );
                              })}
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (this.state.notrelay) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div>
                  <h1> </h1>
                  <Row>
                    <Col>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                        Back
                      </button>
                      <Card className='scrollcard'>
                        <CardHeader>
                          <h5 className='title'>
                            {this.state.event} <br></br> Preliminary Results
                          </h5>
                        </CardHeader>
                        <CardBody>
                          <div class='form-group row printrow'>
                            <div class='col-xs-5'>
                              <CardHeader>
                                <div>
                                  <p>Search</p>
                                  <input
                                    class='form-control'
                                    id='inputsm'
                                    onChange={(e) => this.onSearch(e)}
                                    placeholder='Search'
                                    type='text'
                                  />
                                </div>
                                <center>
                                  <button
                                    type='button'
                                    class='btn btn-success printbtn'
                                    onClick={this.sortbyclassgendernameresult}
                                  >
                                    Check Placing Finals
                                  </button>
                                  <div style={{ marginLeft: '15%' }}>
                                    <label style={{ fontSize: '14px', fontWeight: 'bold' }}>Current Record :</label>
                                    <span>{this.state.tracksetrecord}</span>
                                  </div>
                                </center>
                              </CardHeader>
                            </div>
                          </div>
                          <Form>
                            {Object.keys(this.state.event_data).length == 0 ? (
                              <Empty />
                            ) : (
                              <>
                                <table class='worksheet_table'>
                                  <thead>
                                    <tr class='worksheet_tr'>
                                      <th>Final Group</th>
                                      <th>Heat</th>
                                      <th>Lane</th>
                                      <th>Prelim Results</th>
                                      <th>Prelim Place</th>
                                      <th>Wind Prelim</th>
                                      <th>Final Results</th>
                                      <th>Final Place</th>
                                      <th>Wind Final</th>
                                      <th>Bib</th>
                                      <th>Last Name</th>
                                      <th>First Name</th>
                                      <th>Country</th>
                                      <th>Class</th>
                                      <th>Class for Placing</th>
                                      <th>Age</th>
                                      <th>Gender</th>
                                      <th>Run As Finals</th>
                                      <th>Qualifies</th>
                                      <th>Qual Stand</th>
                                      <th>Break Record</th>
                                      {/* <th>Record</th> */}
                                      <th>Current Record</th>
                                      <th>EventName</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody id='notrelay'>
                                    {this.state.event_data.map((question, index) => (
                                      <tr class='worksheet_tr'>
                                        <td>{question.final_group}</td>
                                        <td>{question.heat}</td>
                                        <td>{question.lane}</td>
                                        <td>{question.prelim_result}</td>
                                        <td>{question.prelim_place}</td>
                                        <td>{question.wind_prelim}</td>
                                        <td>
                                          {question.run_during_final == 'Yes'
                                            ? question.prelim_result
                                            : question.final_result}
                                        </td>
                                        <td>
                                          {question.run_during_final == 'Yes'
                                            ? question.prelim_place
                                            : question.final_place}
                                        </td>
                                        <td>
                                          {question.run_during_final == 'Yes'
                                            ? question.wind_prelim
                                            : question.wind_final}
                                        </td>
                                        <td>{question.bib_no}</td>
                                        <td>{question.last_name}</td>
                                        <td>{question.first_name}</td>
                                        <td>{question.country}</td>
                                        <td>{question.track_class_value}</td>
                                        <td>{question.track_class_value}</td>
                                        <td>{question.age_division}</td>
                                        <td>{question.gender}</td>
                                        <td>{question.run_during_final}</td>
                                        <td>
                                          {!question.qual_stand
                                            ? 'NRF'
                                            : (() => {
                                                const time1InSeconds = timeStringToMilliseconds(
                                                  question.final_result ? question.final_result : question.prelim_result
                                                );
                                                const time2InSeconds =
                                                  question.qual_stand && timeStringToMilliseconds(question.qual_stand);
                                                if (
                                                  time1InSeconds !== null &&
                                                  time2InSeconds !== null &&
                                                  time1InSeconds <= time2InSeconds
                                                ) {
                                                  return 'Yes';
                                                } else {
                                                  return 'No';
                                                }
                                              })()}
                                        </td>
                                        <td>{removeLeadingZeroes(question.qual_stand)}</td>
                                        <td>
                                          {!question.result && !question.open_result
                                            ? 'NRF'
                                            : (() => {
                                                const time1InSeconds = timeStringToMilliseconds(
                                                  question.final_result ? question.final_result : question.prelim_result
                                                );
                                                const time2InSeconds =
                                                  question.result && timeStringToMilliseconds(question.result);
                                                if (
                                                  time1InSeconds !== null &&
                                                  time2InSeconds !== null &&
                                                  time1InSeconds <= time2InSeconds
                                                ) {
                                                  return 'Yes';
                                                } else {
                                                  return 'No';
                                                }
                                              })()}
                                        </td>
                                        <td>
                                          {removeLeadingZeroes(question.result) ||
                                            removeLeadingZeroes(question.open_result)}
                                        </td>
                                        <td>{question.selected_events}</td>
                                        <td>
                                          <button
                                            type='button'
                                            rel='tooltip'
                                            class='btn btn-success btn-sm btn-icon'
                                            onClick={() => this.edit(index)}
                                            data-toggle='modal'
                                            data-target='#useredit'
                                          >
                                            <i class='now-ui-icons ui-2_settings-90'></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </>
                            )}
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Preliminary Result
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          {this.state.runasfinal == 'No' ? (
                            <>
                              <div class='form-row'>
                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Prelim Result</label>
                                  <input
                                    class='form-control'
                                    value={this.state.prelimresultdata}
                                    onChange={(e) =>
                                      this.setState({
                                        prelimresultdata: e.target.value,
                                      })
                                    }
                                    name='relayletter'
                                  />
                                </div>

                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Prelim Place</label>
                                  <input
                                    class='form-control'
                                    value={this.state.prelimplacedata}
                                    onChange={(e) =>
                                      this.setState({
                                        prelimplacedata: e.target.value,
                                      })
                                    }
                                    name='relaypoint'
                                  />
                                </div>

                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Wind Prelim</label>
                                  <input
                                    class='form-control'
                                    value={this.state.windprelimdata}
                                    onChange={(e) =>
                                      this.setState({
                                        windprelimdata: e.target.value,
                                      })
                                    }
                                    name='relayletter'
                                  />
                                </div>
                              </div>

                              <div class='form-row'>
                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Final Result</label>
                                  <input
                                    class='form-control'
                                    value={this.state.finalresultdata}
                                    onChange={(e) =>
                                      this.setState({
                                        finalresultdata: e.target.value,
                                      })
                                    }
                                    name='relaypoint'
                                  />
                                </div>
                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Final Place</label>
                                  <input
                                    class='form-control'
                                    value={this.state.finalplacedata}
                                    onChange={(e) =>
                                      this.setState({
                                        finalplacedata: e.target.value,
                                      })
                                    }
                                    name='relaypoint'
                                  />
                                </div>

                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Wind Final</label>
                                  <input
                                    class='form-control'
                                    value={this.state.windfinaldata}
                                    onChange={(e) =>
                                      this.setState({
                                        windfinaldata: e.target.value,
                                      })
                                    }
                                    name='relayletter'
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div class='form-row'>
                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Prelim Result</label>
                                  <input
                                    class='form-control'
                                    value={this.state.prelimresultdata}
                                    onChange={(e) =>
                                      this.setState({
                                        prelimresultdata: e.target.value,
                                      })
                                    }
                                    name='relayletter'
                                  />
                                </div>

                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Prelim Place</label>
                                  <input
                                    class='form-control'
                                    value={this.state.prelimplacedata}
                                    onChange={(e) =>
                                      this.setState({
                                        prelimplacedata: e.target.value,
                                      })
                                    }
                                    name='relaypoint'
                                  />
                                </div>

                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Wind Prelim</label>
                                  <input
                                    class='form-control'
                                    value={this.state.windprelimdata}
                                    onChange={(e) =>
                                      this.setState({
                                        windprelimdata: e.target.value,
                                      })
                                    }
                                    name='relayletter'
                                  />
                                </div>
                              </div>
                            </>
                          )}
                          <center>
                            <button type='button' class='btn btn-danger' onClick={(e) => this.update()}>
                              Update
                            </button>
                            <button type='button' class='btn btn-danger' onClick={() => this.clearData()}>
                              Clear
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (this.state.issortbyrelayclassgendernameresult) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div>
                  <h1> </h1>
                  <Row>
                    <Col>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                        Back
                      </button>
                      <Card>
                        <CardHeader>
                          <h5 className='title'>
                            {this.state.event} <br></br> Preliminary Worksheet
                          </h5>
                        </CardHeader>
                        <CardBody>
                          <div class='form-group row printrow'>
                            <div class='col-xs-5'>
                              <CardHeader>
                                <div>
                                  <p>Search</p>
                                  <input
                                    class='form-control'
                                    id='inputsm'
                                    onChange={(e) => this.onSearch(e)}
                                    placeholder='Search'
                                    type='text'
                                  />
                                </div>
                                <center>
                                  <button
                                    type='button'
                                    class='btn btn-success printbtn'
                                    onClick={this.sortbyclassgendernameresult}
                                  >
                                    Check Placing Finals
                                  </button>
                                  <div style={{ marginLeft: '15%' }}>
                                    <label style={{ fontSize: '14px', fontWeight: 'bold' }}>Current Record :</label>
                                    <span>{this.state.tracksetrecord}</span>
                                  </div>
                                </center>
                              </CardHeader>
                            </div>
                          </div>
                          <Form>
                            {Object.keys(this.state.sortbyclassgendernameresult).length == 0 ? (
                              <Empty />
                            ) : (
                              <>
                                <table class='worksheet_table'>
                                  <thead>
                                    <tr class='worksheet_tr'>
                                      <th>Final Group</th>
                                      <th>Heat</th>
                                      <th>Lane</th>
                                      <th>Prelim Results</th>
                                      <th>Prelim Place</th>
                                      <th>Wind Prelim</th>
                                      <th>Final Results</th>
                                      <th>Final Place</th>
                                      <th>Wind Final</th>
                                      <th>Bib</th>
                                      <th>Last Name</th>
                                      <th>First Name</th>
                                      <th>Country</th>
                                      <th>Class</th>
                                      <th>Class for Placing</th>
                                      <th>Age</th>
                                      <th>Gender</th>
                                      <th>Run As Finals</th>
                                      <th>Qualifies</th>
                                      <th>Qual Stand</th>
                                      <th>Break Record</th>
                                      <th>Current Record</th>
                                      <th>EventName</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody id='notrelay'>
                                    {this.state.sortbyclassgendernameresult.map((question, index) => (
                                      <tr class='worksheet_tr'>
                                        <td>{question.final_group}</td>
                                        <td>{question.heat}</td>
                                        <td>{question.lane}</td>
                                        <td>{question.prelim_result}</td>
                                        <td>{question.prelim_place}</td>
                                        <td>{question.wind_prelim}</td>
                                        <td>
                                          {question.run_during_final == 'Yes'
                                            ? question.prelim_result
                                            : question.final_result}
                                        </td>
                                        <td>
                                          {question.run_during_final == 'Yes'
                                            ? question.prelim_place
                                            : question.final_place}
                                        </td>
                                        <td>
                                          {question.run_during_final == 'Yes'
                                            ? question.wind_prelim
                                            : question.wind_final}
                                        </td>
                                        <td>{question.bib_no}</td>
                                        <td>{question.last_name}</td>
                                        <td>{question.first_name}</td>
                                        <td>{question.country}</td>
                                        <td>{question.track_class_value}</td>
                                        <td>{question.track_class_value}</td>
                                        <td>{question.age_division}</td>
                                        <td>{question.gender}</td>
                                        <td>{question.run_during_final}</td>
                                        <td>
                                          {!question.qual_stand
                                            ? 'NRF'
                                            : (() => {
                                                const time1InSeconds = timeStringToMilliseconds(
                                                  question.final_result ? question.final_result : question.prelim_result
                                                );
                                                const time2InSeconds =
                                                  question.qual_stand && timeStringToMilliseconds(question.qual_stand);
                                                if (
                                                  time1InSeconds !== null &&
                                                  time2InSeconds !== null &&
                                                  time1InSeconds <= time2InSeconds
                                                ) {
                                                  return 'Yes';
                                                } else {
                                                  return 'No';
                                                }
                                              })()}
                                        </td>
                                        <td>{removeLeadingZeroes(question.qual_stand)}</td>
                                        <td>
                                          {!question.result && !question.open_result
                                            ? 'NRF'
                                            : (() => {
                                                const time1InSeconds = timeStringToMilliseconds(
                                                  question.final_result ? question.final_result : question.prelim_result
                                                );
                                                const time2InSeconds =
                                                  question.result && timeStringToMilliseconds(question.result);
                                                if (
                                                  time1InSeconds !== null &&
                                                  time2InSeconds !== null &&
                                                  time1InSeconds <= time2InSeconds
                                                ) {
                                                  return 'Yes';
                                                } else {
                                                  return 'No';
                                                }
                                              })()}
                                        </td>
                                        <td>
                                          {removeLeadingZeroes(question.result) ||
                                            removeLeadingZeroes(question.open_result)}
                                        </td>
                                        <td>{question.selected_events}</td>
                                        <td>
                                          <button
                                            type='button'
                                            rel='tooltip'
                                            class='btn btn-success btn-sm btn-icon'
                                            onClick={() => this.edit(index)}
                                            data-toggle='modal'
                                            data-target='#useredit'
                                          >
                                            <i class='now-ui-icons ui-2_settings-90'></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </>
                            )}
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
              <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Preliminary Worksheet
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        {this.state.runasfinal == 'No' ? (
                          <>
                            <div class='form-row'>
                              <div class='form-group col-md-4'>
                                <label for='inputCity'>Prelim Result</label>
                                <input
                                  class='form-control'
                                  value={this.state.prelimresultdata}
                                  onChange={(e) =>
                                    this.setState({
                                      prelimresultdata: e.target.value,
                                    })
                                  }
                                  name='relayletter'
                                />
                              </div>

                              <div class='form-group col-md-4'>
                                <label for='inputCity'>Prelim Place</label>
                                <input
                                  class='form-control'
                                  value={this.state.prelimplacedata}
                                  onChange={(e) =>
                                    this.setState({
                                      prelimplacedata: e.target.value,
                                    })
                                  }
                                  name='relaypoint'
                                />
                              </div>

                              <div class='form-group col-md-4'>
                                <label for='inputCity'>Wind Prelim</label>
                                <input
                                  class='form-control'
                                  value={this.state.windprelimdata}
                                  onChange={(e) =>
                                    this.setState({
                                      windprelimdata: e.target.value,
                                    })
                                  }
                                  name='relayletter'
                                />
                              </div>
                            </div>

                            <div class='form-row'>
                              <div class='form-group col-md-4'>
                                <label for='inputCity'>Final Place</label>
                                <input
                                  class='form-control'
                                  value={this.state.finalplacedata}
                                  onChange={(e) =>
                                    this.setState({
                                      finalplacedata: e.target.value,
                                    })
                                  }
                                  name='relaypoint'
                                />
                              </div>

                              <div class='form-group col-md-4'>
                                <label for='inputCity'>Final Result</label>
                                <input
                                  class='form-control'
                                  value={this.state.finalresultdata}
                                  onChange={(e) =>
                                    this.setState({
                                      finalresultdata: e.target.value,
                                    })
                                  }
                                  name='relaypoint'
                                />
                              </div>

                              <div class='form-group col-md-4'>
                                <label for='inputCity'>Wind Final</label>
                                <input
                                  class='form-control'
                                  value={this.state.windfinaldata}
                                  onChange={(e) =>
                                    this.setState({
                                      windfinaldata: e.target.value,
                                    })
                                  }
                                  name='relayletter'
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div class='form-row'>
                              <div class='form-group col-md-4'>
                                <label for='inputCity'>Prelim Result</label>
                                <input
                                  class='form-control'
                                  value={this.state.prelimresultdata}
                                  onChange={(e) =>
                                    this.setState({
                                      prelimresultdata: e.target.value,
                                    })
                                  }
                                  name='relayletter'
                                />
                              </div>

                              <div class='form-group col-md-4'>
                                <label for='inputCity'>Prelim Place</label>
                                <input
                                  class='form-control'
                                  value={this.state.prelimplacedata}
                                  onChange={(e) =>
                                    this.setState({
                                      prelimplacedata: e.target.value,
                                    })
                                  }
                                  name='relaypoint'
                                />
                              </div>

                              <div class='form-group col-md-4'>
                                <label for='inputCity'>Wind Prelim</label>
                                <input
                                  class='form-control'
                                  value={this.state.windprelimdata}
                                  onChange={(e) =>
                                    this.setState({
                                      windprelimdata: e.target.value,
                                    })
                                  }
                                  name='relayletter'
                                />
                              </div>
                            </div>
                          </>
                        )}

                        <center>
                          <button type='button' class='btn btn-danger' onClick={(e) => this.update()}>
                            Update
                          </button>
                          <button type='button' class='btn btn-danger' onClick={() => this.clearData()}>
                            Clear
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    return this.state.event == '4 X 100 Meters' ||
      this.state.event == '4 X 400 Meters' ||
      this.state.event == '800 Medley' ||
      this.state.event == '4 X 100 Universal'
      ? (this.state.relay = true)
      : (this.state.notrelay = true);
  }
}

export default PreliminaryTrackresult;
