import React, { useCallback } from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import { Empty } from 'antd';
import './compound.css';
import Swal from 'sweetalert2';
import Loader from 'react-loader-spinner';
import e from 'cors';
const url = window.globalConfig;
//import Iconsreload from './Meetload.js'
let tempDis = '';
class Swimset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      swimsetdata: [{}],
      swimsets: '',
      venuenamedata: '',
      venuelocationdata: '',
      numberdata: '',
      lanedata: '',
      currentdata: '',
      eventdata: '',
      eventno: '',
      typedata: '',
      maxheat: '',
      promotedata: '',
      seeddata: '',
      relaydata: '',
      teamdata: '',
      venuedatas: [{}],
      venuespecfic: [{}],
      swimeventsdata: '',
      swimlocationset: {},
      swimlocset: {},
      loader: true,
      geteventid: '',
    };

    this.createswimset = this.createswimset.bind(this);
    this.editswimset = this.editswimset.bind(this);
    // this.optionchange = this.optionchange.bind(this);
    this.dropdownchange = this.dropdownchange.bind(this);
    this.dropdownchange1 = this.dropdownchange1.bind(this);
    this.dropdownchange2 = this.dropdownchange2.bind(this);
    this.dropdownchange3 = this.dropdownchange3.bind(this);
    this.dropdownchange4 = this.dropdownchange4.bind(this);
    this.eventnochange = this.eventnochange.bind(this);
    this.updateswimset = this.updateswimset.bind(this);
    this.optionchange1 = this.optionchange1.bind(this);
    this.optionchange2 = this.optionchange2.bind(this);
    this.optionchange3 = this.optionchange3.bind(this);
    this.optionchange4 = this.optionchange4.bind(this);
    this.optionchange5 = this.optionchange5.bind(this);
    this.optionchange6 = this.optionchange6.bind(this);
    this.createswimlocationset = this.createswimlocationset.bind(this);
    this.editlocationset = this.editlocationset.bind(this);
    this.updateswimlocationset = this.updateswimlocationset.bind(this);
    this.delete = this.delete.bind(this);
    this.back = this.back.bind(this);
    // this.valueChange = this.valueChange.bind(this);
    // this.valueChange1 = this.valueChange1.bind(this);
    // this.valueChange2 = this.valueChange2.bind(this);
    // this.valueChange4 = this.valueChange4.bind(this);
  }

  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    var self = this;
    var count = 0;
    let textval = sessionStorage.getItem('x');
    axios.get(`${url.url}/api/venues`, {}).then(function (response) {
      self.setState({
        venuedatas: response.data.data,
        venuespecfic: response.data.data[textval],
        venueevents: response.data.data[textval].events,
      });
      count++;
      if (count == 3) {
        self.setState({
          loader: false,
        });
      }
    });
    //swim set preference
    let meetid = sessionStorage.getItem('ids');
    axios
      .get(`${url.url}/api/getswimmeetid/${meetid}`, {})
      .then(function (response) {
        self.setState({
          swimsetdata: response.data,
        });
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
    axios
      .get(`${url.url}/api/swimlocationmeetid/${meetid}`, {})
      .then(function (response) {
        self.setState({
          swimlocationset: response.data,
          swimlocset: response.data[0],
        });
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {});
    //Hide and show manager
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    // document.getElementById("venueesss").style.display = "block";
  }

  //dropdown values
  dropdownchange(e) {
    this.setState({
      typedata: e.target.value,
    });
    this.setState({
      selectval: e.target.value,
    });
  }

  dropdownchange1(e) {
    this.setState({
      promotedata: e.target.value,
    });
    this.setState({
      selectval1: e.target.value,
    });
  }

  dropdownchange2(e) {
    this.setState({
      seeddata: e.target.value,
    });
    this.setState({
      selectval2: e.target.value,
    });
  }

  dropdownchange3(e) {
    this.setState({
      relaydata: e.target.value,
    });
    this.setState({
      selectval3: e.target.value,
    });
  }

  dropdownchange4(e) {
    this.setState({
      teamdata: e.target.value,
    });
    this.setState({
      selectval4: e.target.value,
    });
  }

  // optionchange(e) {
  //   this.state.venuespecfic.events.map(
  //     (question, index) => {
  //       if(question.name===e.target.value){
  //   this.setState({
  //     eventdata: e.target.value,
  //     geteventid:question.id,
  //   })
  //   this.setState({
  //     [e.target.name]: e.target.value,
  //   });
  //     }})
  // }

  optionchange1(e) {
    this.setState({
      maxdata: e.target.value,
    });
  }

  optionchange2(e) {
    this.setState({
      venuenamedata: e.target.value,
    });
  }
  optionchange3(e) {
    this.setState({
      venuelocationdata: e.target.value,
    });
  }

  optionchange4(e) {
    this.setState({
      numberdata: e.target.value,
    });
  }

  optionchange5(e) {
    this.setState({
      lanedata: e.target.value,
    });
  }

  optionchange6(e) {
    this.setState({
      currentdata: e.target.value,
    });
  }
  eventnochange(e) {
    this.setState({
      eventno: e.target.value,
    });
  }

  //Edit swimset
  editswimset(e) {
    var self = this;
    self.setState({
      swimeventsdata: self.state.venuespecfic.events[e].name,
    });
    var loop = true;
    let textval = sessionStorage.getItem('x');
    self.state.swimsetdata.forEach((question, index) => {
      if (question.event_name == self.state.venuespecfic.events[e].name) {
        self.setState({
          swimsets: question,
          selected_id: question.id,
          eventno: question.event_no,
          eventdata: question.event_name,
          typedata: question.type_pool,
          maxdata: question.max_per_heat,
          promotedata: question.promote_time_only,
          seeddata: question.seed_method,
          relaydata: question.relay,
          teamdata: question.team_scoring,
          btncheck: false,
        });
        loop = false;
      } else if (loop) {
        self.setState({
          swimsets: '',
          selected_id: '',
          eventno: '',
          eventdata: self.state.venuespecfic.events[e].name,
          typedata: this.state.venueevents[e].typepool,
          maxdata: '8',
          promotedata: 'No',
          seeddata: this.state.venueevents[e].is_relay == 1 ? 'Lanes/2' : 'Lanes',
          relaydata: this.state.venueevents[e].is_relay == 1 ? 'Yes' : 'No',
          teamdata: 'Yes',
          btncheck: true,
        });
        loop = true;
      }
    });
    if (self.state.swimsetdata.length == 0) {
      self.setState({
        swimsets: '',
        selected_id: '',
        eventno: '',
        eventdata: '',
        typedata: '',
        maxdata: '',
        promotedata: 'No',
        seeddata: 'Lanes',
        relaydata: this.state.venueevents[e].is_relay == 1 ? 'Yes' : 'No',
        teamdata: 'Yes',
        btncheck: true,
      });
    }
  }
  editlocationset() {
    let textval = sessionStorage.getItem('x');
    if (this.state.venuedatas[textval].name == this.state.swimlocset.venue_name) {
      this.setState({
        venuedata: this.state.swimlocationset[0].venue_name,
        venuelocationdata: this.state.swimlocationset[0].venue_location,
        numberdata: this.state.swimlocationset[0].number_of_events,
        lanedata: this.state.swimlocationset[0].number_of_lanes,
        currentdata: this.state.swimlocationset[0].current_record,
        btncheck1: false,
      });
    } else {
      this.setState({
        venuedata: this.state.venuedatas[textval].name,
        venuelocationdata: '',
        numberdata: this.state.venuespecfic.events.length,
        lanedata: '',
        currentdata: '',
        btncheck1: true,
      });
    }
  }

  createswimlocationset() {
    const datatosend = {
      venue_name: this.state.venuedata,
      venue_location: this.state.venuelocationdata,
      number_of_events: this.state.numberdata,
      number_of_lanes: this.state.lanedata,
      current_record: this.state.currentdata,
      meet_id: parseInt(sessionStorage.getItem('ids')),
    };
    axios
      .post(`${url.url}/api/createswimlocationset`, datatosend, {})
      .then(function (response) {
        if (response.statusText == 'Created' || response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'SwimLocation Set Created successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  updateswimlocationset() {
    var self = this;
    //   e.preventDefault()
    const datatosend = {
      venue_name: this.state.venuedata,
      venue_location: this.state.venuelocationdata,
      number_of_events: this.state.numberdata,
      number_of_lanes: this.state.lanedata,
      current_record: this.state.currentdata,
    };
    axios
      .put(`${url.url}/api/updateswimlocationset/${this.state.swimlocationset[0].id}`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'SwimLocation Set Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  //Create swim set
  createswimset() {
    var self = this;
    const setvalue = (q, index) => {
      if (self.state.swimsetdata.length > index) {
        self.setState({
          swimsets: self.state.swimsetdata[index],
          selected_id: self.state.swimsetdata[index].id,
          eventno: self.state.swimsetdata[index].event_no,
          eventdata: self.state.swimsetdata[index].event_name,
          typedata: self.state.swimsetdata[index].type_pool,
          maxdata: self.state.swimsetdata[index].max_per_heat,
          promotedata: self.state.swimsetdata[index].promote_time_only,
          seeddata: self.state.swimsetdata[index].seed_method,
          relaydata: self.state.swimsetdata[index].relay,
          teamdata: this.state.swimsetdata[index].team_scoring,
        });
      }
    };
    self.state.swimsetdata.map((question, index) => {
      setvalue(question, index);
    });
    const datatosend = {
      event_no: this.state.eventno,
      event_name: this.state.swimeventsdata,
      type_pool: this.state.typedata,
      max_per_heat: this.state.maxdata,
      promote_time_only: this.state.promotedata,
      seed_method: this.state.seeddata,
      relay: this.state.relaydata,
      team_scoring: this.state.teamdata,
      created_by: 1,
      meet_id: parseInt(sessionStorage.getItem('ids')),
    };
    axios
      .post(`${url.url}/api/createswimsetrecords`, datatosend, {})
      .then(function (response) {
        if (response.statusText == 'Created' || response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'Swimset Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  clearswimset = (e) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        //document.getElementById("myTable").deleteRow(e);
        axios
          .delete(`${url.url}/api/deleteswimset/${this.state.swimsets.id}`, {})
          .then(function (response) {
            if (response.status) {
              Swal.fire('Deleted!', 'Record has been deleted.', 'success').then((result) => {
                window.location.reload();
              });
            }
          })
          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
      }
      // } else if (result.dismiss === Swal.DismissReason.cancel) {
      //   Swal.fire("Cancelled", "Cancelled :)", "error");
      // }
    });
  };
  //Update swimset
  updateswimset(e) {
    var self = this;
    //   e.preventDefault()
    const datatosend = {
      event_no: this.state.eventno,
      event_name: this.state.swimsets.event_name,
      type_pool: this.state.typedata,
      max_per_heat: this.state.maxdata,
      seed_method: this.state.seeddata,
      relay: this.state.relaydata,
      team_scoring: this.state.teamdata,
      meet_id: parseInt(sessionStorage.getItem('ids')),
    };
    axios
      .put(`${url.url}/api/updateswimsetrecords/${this.state.swimsets.id}`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'SwimSet Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something Went Wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  delete(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deleteswimsetrecords/${this.state.swimsetdata[e].id}`, {})
          .then(function (response) {})

          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });
  }
  testFunction = (e) => {
    var x = e.split('-');
    var name = x[0];
    var id = x[1];
    tempDis = '';
    this.state.swimsetdata.map((element, index) => {
      if (name === element.event_name && id == element.event_id) {
        tempDis = 'disabled';
        return null;
      }
    });
    return tempDis;
  };
  //back Button
  back() {
    window.location.replace('/eventpreference');
  }
  newset = () => {
    this.setState({
      swimsets: '',
      selected_id: '',
      //eventdata: "",
      typedata: '',
      maxdata: '8',
      promotedata: 'No',
      seeddata: '',
      // relaydata: "",
      teamdata: 'Yes',
      btncheck: true,
    });
  };
  looper = (question1) => {
    const temp = this.state.venuespecfic.events.map((question, index) => {
      if (question1.event_name === question.name) {
        return (
          <>
            <td>{index + 1}</td>
            <td>{question1.event_name}</td>
            <td>{question1.type_pool}</td>
            <td>{question1.max_per_heat}</td>
            <td>{question1.promote_time_only}</td>
            <td>{question1.seed_method}</td>
            <td>{question1.relay}</td>
            <td>{question1.team_scoring}</td>
            <td class='td-actions text-right'>
              <button
                type='button'
                rel='tooltip'
                class='btn btn-success btn-sm btn-icon'
                onClick={() => this.editswimset()}
                data-toggle='modal'
                data-target='#meetedit'
              >
                <i class='now-ui-icons ui-2_settings-90'></i>
              </button>
              {` `}
              <button
                type='button'
                rel='tooltip'
                class='btn btn-danger btn-sm btn-icon'
                onClick={() => this.delete(index)}
              >
                <i class='now-ui-icons ui-1_simple-remove'></i>
              </button>
              {` `}
            </td>
          </>
        );
      }
    });
    return temp;
  };
  render() {
    let textval = sessionStorage.getItem('x');
    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader
                type='ThreeDots'
                color='#00BFFF'
                height={70}
                width={70}
                // timeout={3000} //3 secs
              />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div className='content'>
              <Row>
                <Col md='8'>
                  {/* <button type="button" data-toggle="modal" data-target="#newmeet" class="btn btn-danger btn-round">New Swim set</button> */}
                  <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                    Back
                  </button>
                  {''}
                  {/* <button
                    type="button"
                    class="btn btn-danger btn-round"
                    rel="tooltip"
                    data-toggle="modal"
                    data-target="#newaddmeet"
                    onClick={this.newset}
                  >
                    New Set
                  </button> */}
                  {''}
                  <Card>
                    <CardHeader>
                      <h5 className='title'>Swimming Set Preferences </h5>
                    </CardHeader>

                    <CardBody>
                      <Form>
                        <div class='form-group row'>
                          <div class='col-xs-5'>
                            {Object.keys(this.state.venuespecfic).length == 1 ? (
                              <Empty />
                            ) : (
                              <CardHeader id='deatils_heading'>
                                <tr>
                                  <th>
                                    <p>
                                      Venue Name:
                                      <span>{this.state.venuedatas[textval].name}</span>
                                    </p>
                                  </th>
                                  {''}
                                  <th>
                                    <p>
                                      Venue Location:
                                      <span>
                                        {this.state.swimlocationset[0]
                                          ? this.state.swimlocationset[0].venue_location
                                          : ''}
                                      </span>
                                    </p>
                                  </th>
                                  {''}
                                  {/* <th>
                                    <p>
                                      Number of Events:
                                      <span>
                                        {this.state.venuespecfic.events.length}
                                      </span>
                                    </p>
                                  </th>
                                  {""} */}
                                  <th>
                                    <p>
                                      Number of Lanes:
                                      <span>
                                        {this.state.swimlocationset[0]
                                          ? this.state.swimlocationset[0].number_of_lanes
                                          : ''}
                                      </span>
                                    </p>
                                  </th>
                                  {''}
                                  <th>
                                    <p>
                                      Current Record:
                                      <span>
                                        {this.state.swimlocationset[0]
                                          ? this.state.swimlocationset[0].current_record
                                          : ''}
                                      </span>
                                    </p>
                                  </th>
                                  {''}
                                  <th>
                                    <button
                                      type='button'
                                      rel='tooltip'
                                      data-toggle='modal'
                                      data-target='#newmeet'
                                      class='btn btn-success btn-sm btn-icon'
                                      onClick={this.editlocationset}
                                    >
                                      <i class='now-ui-icons ui-2_settings-90'></i>
                                    </button>
                                  </th>
                                </tr>
                              </CardHeader>
                            )}
                          </div>
                        </div>

                        {/* table --------------------------------------------------- */}
                        {Object.keys(this.state.venuespecfic).length == 1 ? (
                          <Empty />
                        ) : (
                          <>
                            <Table id='my' responsive>
                              <thead>
                                <tr>
                                  <th className='text-center'>Event #</th>
                                  <th>Event Name</th>
                                  <th>Type Pool</th>
                                  <th>Max per Heat</th>
                                  {/* <th>Promote time only</th> */}
                                  <th>Seed Method</th>
                                  <th>Relay</th>
                                  <th>Team Scoring</th>
                                  <th class='disabled-sorting text-right'>Actions</th>
                                </tr>
                              </thead>
                              <tbody id='myTable'>
                                {this.state.venuespecfic.events.map((question, index) => (
                                  <tr>
                                    <td>
                                      {this.state.swimsetdata.map((question1, index1) =>
                                        question.name === question1.event_name ? question1.event_no : ''
                                      )}
                                    </td>
                                    <td>{question.name}</td>
                                    <td>
                                      {this.state.swimsetdata.map((question1, index1) =>
                                        question.name === question1.event_name ? question1.type_pool : ''
                                      )}
                                    </td>
                                    <td>
                                      {this.state.swimsetdata.map((question1, index1) =>
                                        question.name === question1.event_name ? question1.max_per_heat : ''
                                      )}
                                    </td>
                                    {/* <td>
                                        {this.state.swimsetdata.map(
                                          (question1, index1) =>
                                            question.name ===
                                            question1.event_name
                                              ? question1.promote_time_only
                                              : ""
                                        )}
                                      </td> */}
                                    <td>
                                      {this.state.swimsetdata.map((question1, index1) =>
                                        question.name === question1.event_name ? question1.seed_method : ''
                                      )}
                                    </td>
                                    <td>
                                      {this.state.swimsetdata.map((question1, index1) =>
                                        question.name === question1.event_name ? question1.relay : ''
                                      )}
                                    </td>
                                    <td>
                                      {this.state.swimsetdata.map((question1, index1) =>
                                        question.name === question1.event_name ? question1.team_scoring : ''
                                      )}
                                    </td>
                                    <td class='td-actions text-right'>
                                      <button
                                        type='button'
                                        rel='tooltip'
                                        class='btn btn-success btn-sm btn-icon'
                                        onClick={() => this.editswimset(index)}
                                        data-toggle='modal'
                                        data-target='#meetedit'
                                      >
                                        <i class='now-ui-icons ui-2_settings-90'></i>
                                      </button>
                                      {` `}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </>
                        )}
                        {/* table --------------------------------------------------- */}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* edit meet modal ----------------------------------------------------------------------------------------------------------------- */}
              <div class='modal fade' id='meetedit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        {this.state.swimeventsdata + ' ' + 'Swimset'}
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='inputAddress'>Event #</label>
                          <input
                            class='form-control'
                            id='inputAddress'
                            name='evntno'
                            value={this.state.eventno}
                            onChange={this.eventnochange}
                            // required
                            // disabled
                          ></input>
                        </div>
                        <div class='form-group'>
                          <label for='inputAddress'>Event Name</label>
                          <input
                            class='form-control'
                            id='inputAddress'
                            placeholder='Event Name'
                            name='evntname'
                            value={this.state.eventdata}
                            // onChange={this.optionchange}
                            // required
                            // disabled
                          ></input>
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Type Pool</label>
                          <input
                            class='form-control'
                            id='exampleFormControlSelect1'
                            value={this.state.typedata}
                            onChange={this.dropdownchange}
                            required
                          />
                          {/* <option value="YD">YD</option>
                            <option value="LC">LC</option>
                            <option value="SC">SC</option>
                          </select> */}
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>max Per Heat</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            placeholder='max per Heat'
                            name='mfh'
                            value={this.state.maxdata}
                            onChange={this.optionchange1}
                            required
                          />
                        </div>

                        {/* <div class="form-group">
                          <label for="inputAddress">Promote Time only</label>
                          <select
                            class="form-control"
                            id="exampleFormControlSelect1"
                            value={this.state.promotedata}
                            onChange={this.dropdownchange1}
                            required
                          >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                        </div> */}

                        <div class='form-group'>
                          <label for='inputAddress'>Seed Method</label>
                          <input
                            class='form-control'
                            id='exampleFormControlSelect1'
                            value={this.state.seeddata}
                            onChange={this.dropdownchange2}
                            required
                          />
                          {/* <option value="Lanes">Lanes</option>
                            <option value="Lanes/2">Lanes/2</option>
                            <option value="Open/Lanes">Open Lanes</option>
                          </select> */}
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Relay *</label>
                          <select
                            class='form-control'
                            id='exampleFormControlSelect1'
                            value={this.state.relaydata}
                            onChange={this.dropdownchange3}
                            required
                          >
                            <option>Please select</option>
                            <option value='No'>No</option>
                            <option value='Yes'>Yes</option>
                          </select>
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Team Scoring</label>
                          <select
                            class='form-control'
                            id='exampleFormControlSelect1'
                            value={this.state.teamdata}
                            onChange={this.dropdownchange4}
                            required
                          >
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                          </select>
                        </div>
                        {this.state.btncheck == true ? (
                          <center>
                            <button
                              type='Submit'
                              onClick={(e) => this.createswimset(e)}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Create
                            </button>
                          </center>
                        ) : (
                          <>
                            <center>
                              <button type='button' class='btn btn-danger' onClick={(e) => this.updateswimset()}>
                                Update
                              </button>
                              <button type='button' class='btn btn-danger' onClick={(e) => this.clearswimset(e)}>
                                Clear
                              </button>
                            </center>
                          </>
                        )}

                        {/* <center><button type="submit" onClick={this.updateswimset} data-dismiss="modal" class="btn btn-danger">Update</button></center> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class='modal fade'
                id='newaddmeet'
                tabindex='-1'
                role='dialog'
                aria-hidden='true'
                onClick={this.newset}
              >
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        {' '}
                        {this.state.swimeventsdata + ' ' + 'Swimset'}{' '}
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='inputAddress'>Event Name</label>
                          <input
                            class='form-control'
                            id='inputAddress'
                            placeholder='Event Name'
                            name='evntname'
                            value={this.state.swimeventsdata}
                            // onChange={this.optionchange}
                            // required
                            disabled
                          ></input>
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Type Pool</label>
                          <input
                            class='form-control'
                            id='exampleFormControlSelect1'
                            value={this.state.typedata}
                            onChange={this.dropdownchange}
                            required
                          />
                          {/* <option value="YD" selected>YD</option>
                            <option value="LC">LC</option>
                            <option value="SC">SC</option>
                          </select> */}
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>max Per Heat</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            placeholder='max per Heat'
                            name='mfh'
                            value={this.state.maxdata}
                            onChange={this.optionchange1}
                            required
                          />
                        </div>

                        {/* <div class="form-group">
                          <label for="inputAddress">Promote Time only</label>
                          <select
                            class="form-control"
                            id="exampleFormControlSelect1"
                            value={this.state.promotedata}
                            onChange={this.dropdownchange1}
                            required
                          >
                            <option value="No" selected>No</option>
                            <option value="Yes">Yes</option>
                          </select>
                        </div> */}

                        <div class='form-group'>
                          <label for='inputAddress'>Seed Method</label>
                          <select
                            class='form-control'
                            id='exampleFormControlSelect1'
                            value={this.state.seeddata}
                            onChange={this.dropdownchange2}
                            required
                          >
                            <option value='Lanes' selected>
                              Lanes
                            </option>
                            <option value='Lanes/2'>Lanes/2</option>
                            <option value='Open/Lanes'>Open Lanes</option>
                          </select>
                        </div>

                        {/* <div class="form-group">
                          <label for="inputAddress">Relay *</label>
                          <select
                            class="form-control"
                            id="exampleFormControlSelect1"
                            value={this.state.relaydata}
                            onChange={this.dropdownchange3}
                            noValidate
                            required
                          >
                            <option>Please select</option>
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                        </div> */}

                        <div class='form-group'>
                          <label for='inputAddress'>Team Scoring</label>
                          <select
                            class='form-control'
                            id='exampleFormControlSelect1'
                            value={this.state.teamdata}
                            onChange={this.dropdownchange4}
                            required
                          >
                            <option value='Yes' selected>
                              Yes
                            </option>
                            <option value='No'>No</option>
                          </select>
                        </div>
                        <center>
                          <button
                            type='button'
                            onClick={(e) => this.createswimset()}
                            data-dismiss='modal'
                            class='btn btn-danger'
                          >
                            Create
                          </button>
                        </center>

                        {/* <center><button type="submit" onClick={this.updatetrackset} data-dismiss="modal" class="btn btn-danger">Update</button></center> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class='modal fade' id='newmeet' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Swim Location set
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='inputAddress'>Venue Name</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='venuename'
                            value={this.state.venuedata}
                            onChange={this.optionchange2}
                            placeholder='Enter venue name'
                            disabled='disabled'
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Venue location</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='venuelocation'
                            value={this.state.venuelocationdata}
                            onChange={this.optionchange3}
                            placeholder='Enter Venue location'
                            required
                          />
                        </div>

                        {/* <div class="form-group">
                          <label for="inputAddress">Number of events</label>
                          <input
                            type="text"
                            class="form-control"
                            id="inputAddress"
                            name="numevents"
                            value={this.state.numberdata}
                            onChange={this.optionchange4}
                            placeholder="Enter Number of events"
                            disabled="disabled"
                            required
                          />
                        </div> */}

                        <div class='form-group'>
                          <label for='inputAddress'>Number of Lanes</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='numevents'
                            value={this.state.lanedata}
                            onChange={this.optionchange5}
                            placeholder='Enter Number of lanes'
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Current Record Level</label>
                          <select
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='currecord'
                            value={this.state.currentdata}
                            onChange={this.optionchange6}
                            placeholder='Enter Current record'
                            required
                          >
                            <option>Please Select</option>
                            <option value='National'>National</option>
                            <option value='American IPC'>American IPC</option>
                            <option value='Americas IPC'>Americas IPC</option>
                            <option value='NJDC'>NJDC</option>
                          </select>
                        </div>

                        {this.state.btncheck1 == true ? (
                          <center>
                            <button
                              type='button'
                              onClick={(e) => this.createswimlocationset()}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Create
                            </button>
                          </center>
                        ) : (
                          <>
                            <center>
                              <button
                                type='button'
                                class='btn btn-danger'
                                onClick={(e) => this.updateswimlocationset()}
                              >
                                Update
                              </button>
                            </center>
                          </>
                        )}
                        {/* <center><button type="submit" onClick={this.updateswimset} data-dismiss="modal" class="btn btn-danger">Update</button></center> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              {/* add meet------------------------------------------------------------------------------------------------- */}
            </div>
          </>
        )}
      </>
    );
  }
}

export default Swimset;
