import React from 'react';

import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';
import { CountryDropdown } from 'react-country-region-selector';
import Swal from 'sweetalert2';

import { Select, Input } from 'antd';
import 'antd/dist/antd.css';

import 'datatables.net';
import 'datatables.net-bs4';
import Atheletereg from './atheleteregister.js';
import Atheleteresult from './atheleteresult.js';

import { Empty } from 'antd';

import { tsImportEqualsDeclaration } from '@babel/types';
const { Option } = Select;
const url = window.globalConfig;

var x, y, z;
var tempArray = [];
class ManagerVenue extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItems: [],
      eventnames: [],
      eventids: [],
      country: '',
      options: this.options,
      meetdatas: [{}],
      meethome: true,
      viewmeet: true,
      file: null,
      meetspecfic: [{}],
      eventsponser: false,
      lorg: false,
      venueview: false,
      venues: [{}],
      shortname: '',
      meetcity: '',
      meetcountry: '',
      meetdirector: '',
      meetstartdate: '',
      meetenddate: '',
      eshortname: '',
      emeetcity: '',
      emeetcountry: '',
      emeetdirector: '',
      emeetstartdate: '',
      emeetenddate: '',
      orgname: 'sams',
      sponsername: '',
      meetsponser: [{}],
      editsponserdata: '',
      sid: '',
      specficmeet: [{}],
      ids: '',
      localcomiteename: '',
      localcomitedetail: [{}],
      editcomiteedata: '',
      sid1: '',
      editmeetdatas: [{}],
      eids: '',
      vdrop1: '',
      eventdropdown: [{}],
      eventdata: [{}],
      newevents: [{}],
      managervenues: [{}],
      mids: '',
      btncheck: true,
      edituser: false,
      logo: '',
      areg: false,
      aresult: false,
      venueclasses: [{}],
      venueclassid: [{}],
      check: false,
      value: '',
      agedata: [{}],
      genderdata: [{}],
      pointdata: [{}],
      loader3: false,
      managerdata: '',
      mandata: '',
      ddrop: '',
      qsdata: '',
      qtdata: '',
      addata: '',
      gendata: '',
      pointsdata: '',
      vclasses: '',
      vclassesid: '',
      clasven: '',
      classvalue: '',
      vcid: '',
      mname: '',
      teamdata: [{}],
      addven: false,
      editven: true,
      editvenues: false,
      test: true,
      isteamdata: '',
      qualdata: [{}],
      trackeventdata: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.venues = this.venues.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.venueschange = this.venueschange.bind(this);
    this.createvenues = this.createvenues.bind(this);
    this.venueschange1 = this.venueschange1.bind(this);
    this.managervenueedits = this.managervenueedits.bind(this);
    this.updatemanagervenue = this.updatemanagervenue.bind(this);
    this.deletemanagervenue = this.deletemanagervenue.bind(this);
    this.back1 = this.back1.bind(this);
    this.back = this.back.bind(this);
    this.addven = this.addven.bind(this);
    this.selectCountry = this.selectCountry.bind(this);
    this.venuesclasschange = this.venuesclasschange.bind(this);
    this.classvalue = this.classvalue.bind(this);
    this.handleChangeChk = this.handleChangeChk.bind(this);
    this.handleChangeChk1 = this.handleChangeChk1.bind(this);
  }

  // add venues handle change

  componentWillMount() {
    let ids = sessionStorage.getItem('ids');
    var self = this;
    axios.get(`${url.url}/api/venues`, {}).then(function (response) {
      self.setState({
        venues: response.data.data,
      });
    });
    axios
      .get(`${url.url}/api/getmanagervenues/${ids}`, {})
      .then(function (response) {
        self.setState({
          managervenues: response.data,
          mname: response.data[0].meet_name,
        });
      })
      .catch(function (error) {
        self.setState({
          managervenues: [{}],
        });
      });
    //meetData
    axios.get(`${url.url}/api/meets`, {}).then(function (response) {
      self.setState({
        meetdatas: response.data.data,
      });
    });
  }

  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    var self = this;
    let ids = sessionStorage.getItem('ids');
    axios.get(`${url.url}/api/venues`, {}).then(function (response) {
      self.setState({
        venues: response.data.data,
      });
    });
    axios
      .get(`${url.url}/api/getmanagervenues/${ids}`, {})
      .then(function (response) {
        self.setState({
          managervenues: response.data,
          mname: response.data[0].meet_name,
        });
      })
      .catch(function (error) {
        self.setState({
          managervenues: [{}],
        });
      });

    //meetData
    axios.get(`${url.url}/api/meets`, {}).then(function (response) {
      self.setState({
        meetdatas: response.data.data,
      });
    });
    axios
      .get(`${url.url}/api/getallage/`, {})
      .then(function (response) {
        self.setState({
          agedata: response.data.data,
        });
      })
      .catch(function (error) {
        self.setState({
          agedata: [{}],
        });
      });

    axios
      .get(`${url.url}/api/getallgender`, {})
      .then(function (response) {
        self.setState({
          genderdata: response.data.data,
        });
      })
      .catch(function (error) {
        self.setState({
          genderdata: [{}],
        });
      });
    axios
      .get(`${url.url}/api/getallpoints`, {})
      .then(function (response) {
        self.setState({
          pointdata: response.data.data,
        });
      })
      .catch(function (error) {
        self.setState({
          pointdata: [{}],
        });
      });

    //Teammaster
    axios.get(`${url.url}/api/getteamdetails`, {}).then(function (response) {
      self.setState({
        teamdata: response.data,
      });
    });

    //qualifing standard
    axios.get(`${url.url}/api/getqualifyingstandards`, {}).then(function (response) {
      self.setState({
        qualdata: response.data.data,
      });
    });

    var hidenav = document.getElementById('eventset');
    hidenav.style.display = 'none';

    //Hide and show manager
    document.getElementById('sponser').style.display = 'block';
    document.getElementById('local').style.display = 'block';
    // document.getElementById("venuee").style.display = "block";
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('resultp').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    document.getElementById('venueesss').style.display = 'block';

    const trackeventdata = (index) => {
      if (index.track_events != null) {
        var joined = this.state.trackeventdata.concat(index);
        this.setState({
          trackeventdata: joined,
        });
      }
    };
    //Get Particular user register meet data
    axios.get(`${url.url}/api/getcreateregisters/${ids}`, {}).then(function (response) {
      self.setState({
        allregdatas: response.data,
      });
      {
        response.data.map((index) => trackeventdata(index));
      }
    });
  }

  handleChange(event) {
    var self = this;
    var file = event.target.files[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      self.setState({
        logo: reader.result,
      });

      var data = reader.result.split(',')[1];
    };
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
    });
    reader.readAsDataURL(file);
  }

  // pages =------------------------------------------------------------------

  // venues create delete edit start---------------------------------------------------------------------------
  venues() {
    axios.get(`${url.url}/api/venues`, {}).then(function (response) {
      self.setState({
        ddrop: response.data.data,
      });
    });
    axios
      .get(`${url.url}/api/getmanagervenues/${this.state.meetspecfic.id}`, {})
      .then(function (response) {
        self.setState({
          managervenues: response.data,
        });
      })
      .catch(function (error) {
        self.setState({
          managervenues: [{}],
        });
      });
    var self = this;
    self.setState({
      meethome: false,
      viewmeet: false,
      eventsponser: false,
      lorg: false,
      venueview: true,
    });
  }

  managervenueedits(e) {
    var test = 0;
    var elid;
    var classid = [];
    var self = this;
    var array = this.state.venues;
    self.setState({
      viewmeet: false,
    });
    this.state.venues.map((key, index) => {
      if (key.id == this.state.managervenues[e].venue_id) {
        var ven = key.id + '-' + index;
        self.setState({
          managerdata: ven,
        });
        self.venueschange(ven);
        key.venueclasses.map((element, index2) => {
          if (element.name == this.state.managervenues[e].venue_class) {
            var ten = index2;
            self.setState({
              vclasses: ten,
            });
            elid = element.id;
          }
        });
        var class_value = this.state.managervenues[e].class_value;
        axios
          .get(`${url.url}/api/getvenueclassid/${elid}`, {})
          .then(function (response) {
            self.setState({
              venueclassid: response.data,
            });
            classid = response.data;
            classid.map((element1, index3) => {
              if (element1.name == class_value) {
                var len = index3;
                self.setState({
                  vclassesid: len,
                });
              }
            });
          })
          .catch(function (error) {
            self.setState({
              venueclassid: [{}],
            });
          });
        return;
      }
    });

    self.setState({
      mids: parseInt(this.state.managervenues[e].id),
      mandata: this.state.managervenues[e].event_name,
      isteamdata: this.state.managervenues[e].isteam,
      qsdata: this.state.managervenues[e].Qualifying_Standard,
      qtdata: this.state.managervenues[e].Qualifying_Time,
      addata: this.state.managervenues[e].Age_Division,
      gendata: this.state.managervenues[e].Gender_Variation,
      pointsdata: this.state.managervenues[e].Points,
    });
    tempArray = [
      this.state.managervenues[e].isteam,
      this.state.managervenues[e].Qualifying_Standard,
      this.state.managervenues[e].Qualifying_Time,
      this.state.managervenues[e].Age_Division,
      this.state.managervenues[e].Gender_Variation,
      this.state.managervenues[e].Points,
    ];
  }

  enableCheckbox() {
    $.each($('input[class ="ischeck1"]'), function (index) {
      $(this).prop('checked', false);
      $('.cbox' + '#' + this.value).css('display', 'none');
      if (tempArray[index]) {
        $(this).prop('checked', true);
        $('.cbox' + '#' + this.value).css('display', 'block');
      }
    });
  }

  venueschange(e) {
    var i = e.split('-');
    var l = parseInt(i[0]);
    var k = parseInt(i[1]);
    var self = this;
    self.setState({ managerdata: e });
    axios
      .get(`${url.url}/api/getvenueclass/${this.state.venues[k].id}`, {})
      .then(function (response) {
        self.setState({
          venueclasses: response.data,
        });
      })
      .catch(function (error) {
        self.setState({
          venueclasses: [{}],
        });
      });
    self.setState({
      eventdropdown: this.state.venues[k],
    });
  }

  handleChange1(selectedItems, e) {
    var self = this;
    self.setState({
      selectedItems,
    });
  }

  handleChange2(e) {
    var self = this;
    this.setState({ mandata: e, selectedItems: e });
  }

  venueschange1(e) {
    var self = this;
    self.setState({
      newevents: this.state.eventdropdown.events[e.target.value],
    });
  }

  handleChangeChk = (e) => {
    var test = e.target.value;
    this.setState({
      value: e.target.value,
      check: !this.state.check,
    });

    if ($(e.target).prop('checked')) {
      $('#' + e.target.value).css('display', 'block');
    } else {
      $('#' + e.target.value).css('display', 'none');
    }
  };
  handleChangeChk1 = (e) => {
    var test = e.target.value;
    this.setState({ test: false });

    if ($(e.target).prop('checked')) {
      $('.cbox' + '#' + e.target.value).css('display', 'block');
    } else {
      $('.cbox' + '#' + e.target.value).css('display', 'none');
    }
  };
  venuesclasschange(e) {
    var self = this;
    self.setState({ vclasses: e });
    if (e == 'Please Select') {
      self.setState({
        clasven: { e },
      });
    } else {
      self.setState({ clasven: this.state.venueclasses[e] });
      axios
        .get(`${url.url}/api/getvenueclassid/${this.state.venueclasses[e].id}`, {})
        .then(function (response) {
          self.setState({
            venueclassid: response.data,
          });
        })
        .catch(function (error) {
          self.setState({
            venueclassid: [{}],
          });
        });
    }
  }

  classvalue(e) {
    this.setState({ vclassesid: e });
    this.setState({ vcid: this.state.venueclassid[e] });
  }

  createvenues() {
    var self = this;

    {
      this.state.selectedItems.map(
        (a) => (this.state.eventnames.push(a.split('-')[0]), this.state.eventids.push(parseInt(a.split('-')[1])))
      );
    }
    let e = sessionStorage.getItem('e');
    const datatosend = {
      meet_name: this.state.meetdatas[e].name,
      meet_id: this.state.meetdatas[e].id,
      created_by: this.state.meetdatas[e].created_by,
      venue_id: parseInt(this.state.selectedItems[0].split('-')[2]),
      event_id: this.state.eventids.toString(),
      name: this.state.eventdropdown.name,
      event_name: this.state.eventnames.toString(),
      isteam: this.state.isteam,
      Qualifying_Standard: this.state.QuaSta,
      Qualifying_Time: this.state.Qualifyingtime,
      Age_Division: this.state.agedivision,
      Gender_Variation: this.state.gendervariation,
      Points: this.state.point,
      venue_class: this.state.clasven.name,
      class_value: this.state.vcid.name,
    };
    self.setState({
      eventnames: [],
      eventids: [],

      selectedItems: [],
      eventdropdown: [{}],
    });

    axios
      .post(`${url.url}/api/createmanagervenue`, datatosend, {})
      .then(function (response) {
        if (response.status == 201) {
          Swal.fire({
            title: 'Success',
            text: 'Venues Created successfully',
            icon: 'warning',
            confirmButtonText: 'OK',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong Please check the fields',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  updatemanagervenue(e) {
    var self = this;

    {
      this.state.selectedItems.map(
        (a) => (this.state.eventnames.push(a.split('-')[0]), this.state.eventids.push(parseInt(a.split('-')[1])))
      );
    }
    const datatosend = {
      name: this.state.eventdropdown.name,
      //"venue_id": parseInt(this.state.selectedItems[0].split("-")[2]),
      event_name: this.state.eventnames.toString(),
      event_id: this.state.eventids.toString(),
      isteam: !$('#tm').prop('checked') ? '' : this.state.isteamdata,
      Qualifying_Standard: !$('#qs').prop('checked') ? '' : this.state.qsdata,
      Qualifying_Time: !$('#qt').prop('checked') ? '' : this.state.qtdata,
      Age_Division: !$('#ad').prop('checked') ? '' : this.state.addata,
      Gender_Variation: !$('#gv').prop('checked') ? '' : this.state.gendata,
      Points: !$('#pnt').prop('checked') ? '' : this.state.pointsdata,
      venue_class: this.state.clasven.e == 'Please Select' ? 'null' : this.state.clasven.name,
      class_value: this.state.clasven.e == 'Please Select' ? 'null' : this.state.vcid.name,
    };
    self.setState({
      eventnames: [],
      eventids: [],
      selectedItems: [],
      eventdropdown: [{}],
    });
    axios
      .put(`${url.url}/api/updatemanagervenue/${this.state.mids}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            title: 'Sucess',
            text: 'Data updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  deletemanagervenue(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deletemanagervenue/${this.state.managervenues[e].id}`, {})
          .then(function (response) {})
          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });
  }

  // venues create edit delte end---------------------------------------------------------------------end----------------------------------------

  // comitee ----------------------------------------------------------------------
  addven() {
    this.setState({ addven: true, viewmeet: false });
  }

  back1() {
    //sessionStorage.setItem("path","/meetviewmanager");
    // window.location.replace("/meetviewmanager");
    window.location.replace('/meets');
    sessionStorage.removeItem('curnavitem');
  }

  back() {
    window.location.replace('/managervenues');
  }

  selectCountry(val) {
    this.setState({ country: val });
  }

  // comitee ---------------------------------------------------------------------
  render() {
    //sessionStorage.setItem("path","/managervenues");
    const { selectedItems } = this.state;

    const { eventnames } = this.state;

    // venues -----------------------------------------------------------------------------------------------------
    if (this.state.viewmeet == true) {
      return (
        <div className='content'>
          {/* <div>{ this.state.managervenues.map((x)=>(
  <h2>{x.meet_name}</h2>
))}</div> */}
          <h2>{this.state.mname}</h2>
          <>
            <Row>
              <Col md='8'>
                <button
                  type='button'
                  data-toggle='modal'
                  data-target='#newmeet'
                  class='btn btn-danger btn-round'
                  onClick={this.back1}
                >
                  Back
                </button>
                {''}
                <button
                  type='button'
                  data-toggle='modal'
                  data-target='#addvenues'
                  class='btn btn-danger btn-round'
                  onClick={this.addven}
                >
                  Add Venues
                </button>
                <Card>
                  <CardHeader>
                    <h5 className='title'>Venues</h5>
                  </CardHeader>

                  <CardBody>
                    <Form>
                      <div class='form-group row'>
                        <div class='col-xs-5'>
                          <CardHeader>
                            <p>Search</p>
                            <input class='form-control' id='inputsm' placeholder='Search..' type='text' />
                          </CardHeader>
                        </div>
                      </div>

                      {/* table --------------------------------------------------- */}
                      {Object.keys(this.state.managervenues[0]).length == 0 ? (
                        <Empty />
                      ) : (
                        <>
                          <Table id='my' responsive>
                            <thead>
                              <tr>
                                {/* <th className="text-center">#</th> */}

                                {/* <th>Meet name</th> */}
                                <th>Venues</th>
                                <th>Event name</th>
                                <th>Age Division</th>
                                <th>Gender</th>

                                <th class='disabled-sorting text-right'>Actions</th>
                              </tr>
                            </thead>
                            <tbody id='myTable'>
                              {this.state.managervenues.map(
                                (question, index) => (
                                  (x = question.event_name.split(',').join(', ').split('"')),
                                  (
                                    <tr>
                                      {/* <td>{index+1}</td> */}
                                      {/* <td >{question.meet_name}</td> */}
                                      <td>{question.name}</td>
                                      <td>{x}</td>
                                      <td>{question.Age_Division}</td>
                                      <td>{question.Gender_Variation}</td>
                                      <td class='td-actions text-right'>
                                        <button
                                          type='button'
                                          rel='tooltip'
                                          class='btn btn-success btn-sm btn-icon'
                                          onClick={() => this.managervenueedits(index)}
                                          data-toggle='modal'
                                          data-target='#venuesedit'
                                        >
                                          <i class='now-ui-icons ui-2_settings-90'></i>
                                        </button>
                                        {` `}
                                        <button
                                          type='button'
                                          rel='tooltip'
                                          class='btn btn-danger btn-sm btn-icon'
                                          onClick={() => this.deletemanagervenue(index)}
                                        >
                                          <i class='now-ui-icons ui-1_simple-remove'></i>
                                        </button>
                                      </td>
                                    </tr>
                                  )
                                )
                              )}
                            </tbody>
                          </Table>
                        </>
                      )}
                      {/* table --------------------------------------------------- */}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        </div>
      );
    }
    {
      /* <div id="addvenues" class="modal fade" tabindex="-1" role="dialog"  aria-hidden="true">
<div class="modal-dialog">


<div class="modal-content">
<div class="modal-header">
<button type="button" class="close" data-dismiss="modal">&times;</button> */
    }
    if (this.state.addven) {
      return (
        <div className='content'>
          <h2>{this.state.mname}</h2>
          <>
            <Row>
              <Col md='8'>
                <button
                  type='button'
                  data-toggle='modal'
                  data-target='#newmeet'
                  class='btn btn-danger btn-round'
                  onClick={this.back}
                >
                  Back
                </button>
                {''}
                <Card>
                  <CardHeader>
                    <h4 class='modal-title'>Add Venues</h4>
                  </CardHeader>
                  {/* </div> */}
                  <div class='modal-body addvenue'>
                    <div class='form-group col-md-12'>
                      <label>Venues</label>
                      <select
                        class='form-control'
                        id='exampleFormControlSelect1'
                        name='vdrop1'
                        onChange={(e) => this.venueschange(e.target.value)}
                        required
                      >
                        <option>Please Select</option>
                        {Object.keys(this.state.venues).length == 1 ? (
                          <option></option>
                        ) : (
                          <>
                            {this.state.venues.map((edrop, index) => (
                              <option value={edrop.id + '-' + index}>{edrop.name}</option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>

                    <div class='form-group col-md-12 event-modal'>
                      <label>Event</label>
                      {/* <select  class="form-control" id="exampleFormControlSelect1" name="vdrop1" id="selectlen" onChange={this.venueschange1}  required>
<option>Please Select</option>
{ Object.keys(this.state.eventdropdown).length == 1 ?
<option></option>
:  <>
{ this.state.eventdropdown.events.map((edrop, index)=>(
<option value={index} >{edrop.name}</option>
))}
</>
}

</select> */}
                      <Select
                        mode='multiple'
                        placeholder='Please select'
                        value={selectedItems}
                        onChange={this.handleChange1}
                        style={{ width: '410px' }}
                      >
                        {Object.keys(this.state.eventdropdown).length == 1 ? (
                          <Select.Option key='' value=''>
                            Please select
                          </Select.Option>
                        ) : (
                          <>
                            {this.state.eventdropdown.events.map((edrop, index) => (
                              <Select.Option
                                key={edrop.name}
                                value={edrop.name + '-' + edrop.id + '-' + edrop.venue_id}
                              >
                                {edrop.name}
                              </Select.Option>
                            ))}
                          </>
                        )}
                      </Select>
                    </div>
                    {/* New field added */}
                    <div class='form-group col-md-12'>
                      <input
                        type='checkbox'
                        class='ischeck'
                        value='teamvalue'
                        onChange={(e) => this.handleChangeChk(e)}
                      />
                      <label class='chkbx'>IsTeam</label>
                    </div>
                    <div class='cbox' id='teamvalue'>
                      <label>IsTeam Event :</label>
                      <select
                        class='form-control chbox'
                        id='teamvalue'
                        name='isteam'
                        onChange={(e) => this.setState({ isteam: e.target.value })}
                        required
                      >
                        <option>Please Select</option>
                        {Object.keys(this.state.teamdata).length == 0 ? (
                          <option></option>
                        ) : (
                          <>
                            {this.state.teamdata.map((edrop, index) => (
                              <option value={edrop.index}>
                                {edrop.name}({edrop.team_value})
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>

                    {/* New field added */}
                    <div class='form-group col-md-12'>
                      <input
                        type='checkbox'
                        class='ischeck'
                        value='QualifyingStandard'
                        onChange={(e) => this.handleChangeChk(e)}
                      />
                      <label class='chkbx'>Qualifying Standard</label>
                      <input
                        type='checkbox'
                        class='ischeck'
                        value='QualifyingTime'
                        onChange={(e) => this.handleChangeChk(e)}
                      />
                      <label class='chkbx'>Qualifying Time</label>
                      <input
                        type='checkbox'
                        class='ischeck'
                        value='AgeDivision'
                        onChange={(e) => this.handleChangeChk(e)}
                      />
                      <label class='chkbx'>Age Division</label>
                      <input
                        type='checkbox'
                        class='ischeck'
                        value='GenderVariation'
                        onChange={(e) => this.handleChangeChk(e)}
                      />
                      <label class='chkbx'>Gender Variation</label>
                      <input type='checkbox' class='ischeck' value='Points' onChange={(e) => this.handleChangeChk(e)} />
                      <label class='chkbx'>Points</label>
                    </div>

                    <div class='cbox' id='QualifyingStandard'>
                      <label>Qualifying Standard :</label>
                      {/* <input type="text"  placeholder="Qualifying Standard" class="form-control chbox"  name="QuaSta" onChange={e => this.setState({ QuaSta: e.target.value })} required/> */}
                      <select
                        class='form-control chbox'
                        id='QualifyingStandard'
                        name='QuaSta'
                        onChange={(e) => this.setState({ QuaSta: e.target.value })}
                        required
                      >
                        <option>Please Select</option>
                        {Object.keys(this.state.qualdata).length == 0 ? (
                          <option></option>
                        ) : (
                          <>
                            {this.state.qualdata.map((edrop, index) => (
                              <option value={edrop.index}>{edrop.qualclass}</option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>

                    <div class='cbox' id='QualifyingTime'>
                      <label>Qualifying Time :</label>
                      <input
                        type='text'
                        placeholder='Qualifying Time'
                        class='form-control chbox'
                        id='QualifyingTime'
                        name='Qualifyingtime'
                        onChange={(e) => this.setState({ Qualifyingtime: e.target.value })}
                        required
                      />
                    </div>
                    {/* <input type="text"  placeholder="AgeDivision" class="form-control cbox" id="AgeDivision" name="AgeDivision"  required/> */}

                    <div class='cbox' id='AgeDivision'>
                      <label>AgeDivision :</label>
                      <select
                        class='form-control chbox'
                        id='AgeDivision'
                        name='agedivision'
                        onChange={(e) => this.setState({ agedivision: e.target.value })}
                        required
                      >
                        <option>Please Select</option>
                        {Object.keys(this.state.agedata).length == 0 ? (
                          <option></option>
                        ) : (
                          <>
                            {this.state.agedata.map((edrop, index) => (
                              <option value={edrop.index}>
                                {edrop.name}({edrop.age_value})
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                    {/* <input type="text"  placeholder="GenderVariation" class="form-control cbox" id="GenderVariation" name="GenderVariation"  required/> */}
                    <div class='cbox' id='GenderVariation'>
                      <label>Gender Variation :</label>
                      <select
                        class='form-control chbox'
                        id='GenderVariation'
                        name='gendervariation'
                        onChange={(e) => this.setState({ gendervariation: e.target.value })}
                        required
                      >
                        <option>Please Select</option>
                        {Object.keys(this.state.genderdata).length == 1 ? (
                          <option></option>
                        ) : (
                          <>
                            {this.state.genderdata.map((edrop, index) => (
                              <option value={edrop.index}>
                                {edrop.name}({edrop.gender_value})
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                    {/* <input type="text"  placeholder="Points" class="form-control cbox" id="Points" name="points"  required/> */}
                    <div class='cbox' id='Points'>
                      <label>Points :</label>
                      <select
                        class='form-control chbox'
                        id='Points'
                        name='point'
                        onChange={(e) => this.setState({ point: e.target.value })}
                        required
                      >
                        <option>Please Select</option>
                        {Object.keys(this.state.pointdata).length == 1 ? (
                          <option></option>
                        ) : (
                          <>
                            {this.state.pointdata.map((edrop, index) => (
                              <option value={edrop.index}>
                                {edrop.name}({edrop.point_value})
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                    {/* End */}

                    <div class='form-group col-md-12'>
                      <label>Venue classes</label>
                      <select
                        class='form-control'
                        id='exampleFormControlSelect1'
                        name='vdrop1'
                        onChange={(e) => this.venuesclasschange(e.target.value)}
                        required
                      >
                        <option value='i'>Please Select</option>
                        {Object.keys(this.state.venueclasses[0]).length == 0 ? (
                          <option></option>
                        ) : (
                          <>
                            {this.state.venueclasses.map((edrop, index) => (
                              <option value={index}>{edrop.name}</option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>

                    <div class='form-group col-md-12'>
                      <label>Venue Classes value*</label>
                      <select
                        class='form-control'
                        id='exampleFormControlSelect1'
                        name='vdrop1'
                        onChange={(e) => this.classvalue(e.target.value)}
                        required
                      >
                        <option value='i'>Please Select</option>
                        {Object.keys(this.state.venueclassid[0]).length == 0 ? (
                          <option></option>
                        ) : (
                          <>
                            {this.state.venueclassid.map((edrop, index) => (
                              <option value={index}>{edrop.name}</option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                  </div>

                  <div>
                    <center>
                      <button
                        type='button'
                        id='cvenue'
                        class='btn btn-danger'
                        onClick={this.createvenues}
                        data-dismiss='modal'
                      >
                        Save
                      </button>
                    </center>
                  </div>
                </Card>
              </Col>
            </Row>
          </>
        </div>
      );
    }
    {
      /* </div>

</div>
</div> */
    }
    {
      /* <div>
<div id="venuesedit" class="modal fade" tabindex="-1" role="dialog"  aria-hidden="true">
<div class="modal-dialog">


<div class="modal-content">
<div class="modal-header">
<button type="button" class="close" data-dismiss="modal">&times;</button> */
    }
    if (this.state.editven) {
      if (this.state.test) {
        this.enableCheckbox();
      }

      return (
        <div className='content'>
          <h2>{this.state.mname}</h2>
          <>
            <Row>
              <Col md='8'>
                <button
                  type='button'
                  data-toggle='modal'
                  data-target='#newmeet'
                  class='btn btn-danger btn-round'
                  onClick={this.back}
                >
                  Back
                </button>
                {''}
                <Card>
                  <CardHeader>
                    <h4 class='modal-title'>Edit Venues</h4>
                  </CardHeader>
                  <div class='modal-body editvenue'>
                    <div class='form-group col-md-12'>
                      <label>Venues</label>
                      <select
                        class='form-control'
                        id='exampleFormControlSelect1'
                        name='vdrop1'
                        value={this.state.managerdata}
                        onChange={(e) => this.venueschange(e.target.value)}
                        disabled
                        required
                      >
                        {/* <option>Please Select</option> */}
                        {Object.keys(this.state.venues).length == 1 ? (
                          <option></option>
                        ) : (
                          <>
                            {this.state.venues.map((edrop, index) => (
                              <option value={edrop.id + '-' + index}>{edrop.name}</option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>

                    <div class='form-group col-md-12 event-modal'>
                      <label>Event *</label>
                      {/* <select  class="form-control" id="exampleFormControlSelect1" name="vdrop1" value={this.state.mandata} id="selectlen" onChange={this.venueschange1}  required>
<option>Please Select</option>
{ Object.keys(this.state.eventdropdown).length == 1 ?
<option></option>
:  <>
{ this.state.eventdropdown.events.map((edrop, index)=>(
<option value={index} >{edrop.name}</option>
))}
</>
}

</select> */}
                      <Select
                        mode='multiple'
                        // placeholder="Please select"
                        value={this.state.mandata}
                        onChange={this.handleChange2}
                        style={{ width: '410px' }}
                      >
                        {Object.keys(this.state.eventdropdown).length == 1 ? (
                          <Select.Option key='' value=''></Select.Option>
                        ) : (
                          <>
                            {this.state.eventdropdown.events.map((edrop, index) => (
                              <Select.Option
                                key={edrop.name}
                                value={edrop.name + '-' + edrop.id + '-' + edrop.venue_id}
                              >
                                {edrop.name}
                              </Select.Option>
                            ))}
                          </>
                        )}
                      </Select>
                    </div>

                    <div class='form-group col-md-12'>
                      <input
                        type='checkbox'
                        class='ischeck1'
                        id='tm'
                        value='teamvalue'
                        onChange={(e) => this.handleChangeChk1(e)}
                      />
                      <label class='chkbx'>IsTeam</label>
                    </div>

                    <div class='cbox' id='teamvalue'>
                      <label>IsTeam Event :</label>
                      <select
                        class='form-control chbox'
                        id='teamvalue'
                        name='isteam'
                        value={this.state.isteamdata}
                        onChange={(e) => this.setState({ isteamdata: e.target.value })}
                        required
                      >
                        <option>Please Select</option>
                        {Object.keys(this.state.teamdata).length == 0 ? (
                          <option></option>
                        ) : (
                          <>
                            {this.state.teamdata.map((edrop, index) => (
                              <option value={edrop.index}>
                                {edrop.name}({edrop.team_value})
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>

                    {/* New field added */}

                    <div class='form-group col-md-12'>
                      <input
                        type='checkbox'
                        class='ischeck1'
                        id='qs'
                        value='QualifyingStandard'
                        onChange={(e) => this.handleChangeChk1(e)}
                      />
                      <label class='chkbx'>Qualifying Standard</label>
                      <input
                        type='checkbox'
                        class='ischeck1'
                        id='qt'
                        value='QualifyingTime'
                        onChange={(e) => this.handleChangeChk1(e)}
                      />
                      <label class='chkbx'>Qualifying Time</label>
                      <input
                        type='checkbox'
                        class='ischeck1'
                        id='ad'
                        value='AgeDivision'
                        onChange={(e) => this.handleChangeChk1(e)}
                      />
                      <label class='chkbx'>Age Division</label>
                      <input
                        type='checkbox'
                        class='ischeck1'
                        id='gv'
                        value='GenderVariation'
                        onChange={(e) => this.handleChangeChk1(e)}
                      />
                      <label class='chkbx'>Gender Variation</label>
                      <input
                        type='checkbox'
                        class='ischeck1'
                        id='pnt'
                        value='Points'
                        onChange={(e) => this.handleChangeChk1(e)}
                      />
                      <label class='chkbx'>Points</label>
                    </div>

                    <div class='cbox' id='QualifyingStandard'>
                      <label>Qualifying Standard :</label>
                      {/* <input type="text"  class="form-control chbox"  name="QuaSta" value={this.state.qsdata} onChange={e => this.setState({ qsdata: e.target.value })} required/> */}
                      <select
                        class='form-control chbox'
                        id='QualifyingStandard'
                        name='QuaSta'
                        value={this.state.qsdata}
                        onChange={(e) => this.setState({ qsdata: e.target.value })}
                        required
                      >
                        <option>Please Select</option>
                        {Object.keys(this.state.qualdata).length == 0 ? (
                          <option></option>
                        ) : (
                          <>
                            {this.state.qualdata.map((edrop, index) => (
                              <option value={edrop.index}>{edrop.qualclass}</option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>

                    <div class='cbox' id='QualifyingTime'>
                      <label>Qualifying Time :</label>
                      <input
                        type='text'
                        class='form-control chbox'
                        id='QualifyingTime'
                        name='Qualifyingtime'
                        value={this.state.qtdata}
                        onChange={(e) => this.setState({ qtdata: e.target.value })}
                        required
                      />
                    </div>
                    {/* <input type="text"  placeholder="AgeDivision" class="form-control cbox" id="AgeDivision" name="AgeDivision"  required/> */}

                    <div class='cbox' id='AgeDivision'>
                      <label>AgeDivision :</label>
                      <select
                        class='form-control chbox'
                        id='AgeDivision'
                        name='agedivision'
                        value={this.state.addata}
                        onChange={(e) => this.setState({ addata: e.target.value })}
                        required
                      >
                        <option>Please Select</option>
                        {Object.keys(this.state.agedata).length == 0 ? (
                          <option></option>
                        ) : (
                          <>
                            {this.state.agedata.map((edrop, index) => (
                              <option value={edrop.index}>
                                {edrop.name}({edrop.age_value})
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                    {/* <input type="text"  placeholder="GenderVariation" class="form-control cbox" id="GenderVariation" name="GenderVariation"  required/> */}
                    <div class='cbox' id='GenderVariation'>
                      <label>Gender Variation :</label>
                      <select
                        class='form-control chbox'
                        id='GenderVariation'
                        name='gendervariation'
                        value={this.state.gendata}
                        onChange={(e) => this.setState({ gendata: e.target.value })}
                        required
                      >
                        <option>Please Select</option>
                        {Object.keys(this.state.genderdata).length == 1 ? (
                          <option></option>
                        ) : (
                          <>
                            {this.state.genderdata.map((edrop, index) => (
                              <option value={edrop.index}>
                                {edrop.name}({edrop.gender_value})
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                    {/* <input type="text"  placeholder="Points" class="form-control cbox" id="Points" name="points"  required/> */}
                    <div class='cbox' id='Points'>
                      <label>Points :</label>
                      <select
                        class='form-control chbox'
                        id='Points'
                        name='point'
                        value={this.state.pointsdata}
                        onChange={(e) => this.setState({ pointsdata: e.target.value })}
                        required
                      >
                        <option>Please Select</option>
                        {Object.keys(this.state.pointdata).length == 1 ? (
                          <option></option>
                        ) : (
                          <>
                            {this.state.pointdata.map((edrop, index) => (
                              <option value={edrop.index}>
                                {edrop.name}({edrop.point_value})
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                    {/* End */}

                    <div class='form-group col-md-12'>
                      <label>Venue classes</label>
                      <select
                        class='form-control'
                        id='exampleFormControlSelect1'
                        name='vdrop1'
                        value={this.state.vclasses}
                        onChange={(e) => this.venuesclasschange(e.target.value)}
                        required
                      >
                        <option>Please Select</option>
                        {Object.keys(this.state.venueclasses[0]).length == 0 ? (
                          <option></option>
                        ) : (
                          <>
                            {this.state.venueclasses.map((edrop, index) => (
                              <option value={index}>{edrop.name}</option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>

                    <div class='form-group col-md-12'>
                      <label>Venue Classes value*</label>
                      <select
                        class='form-control'
                        id='exampleFormControlSelect1'
                        name='vdrop1'
                        value={this.state.vclassesid}
                        onChange={(e) => this.classvalue(e.target.value)}
                        required
                      >
                        <option>Please Select</option>
                        {Object.keys(this.state.venueclassid[0]).length == 0 ? (
                          <option></option>
                        ) : (
                          <>
                            {this.state.venueclassid.map((edrop, index) => (
                              <option value={index}>{edrop.name}</option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                  </div>

                  <div>
                    <center>
                      <button
                        type='button'
                        id='cvenue'
                        class='btn btn-danger'
                        onClick={this.updatemanagervenue}
                        data-dismiss='modal'
                      >
                        Save
                      </button>
                    </center>
                  </div>
                </Card>
              </Col>
            </Row>
          </>
        </div>
      );
    }
    {
      /* <div id="venuesedit" class="modal fade" tabindex="-1" role="dialog"  aria-hidden="true">
<div class="modal-dialog">
<div class="modal-content">
<div class="modal-header">
<button type="button" class="close" data-dismiss="modal">&times;</button>
<h4 class="modal-title">Edit Venues</h4>
</div>
<div class="modal-body">
<div class="form-group col-md-4">
<label >Venues</label>
<select  class="form-control" id="exampleFormControlSelect1" name="vdrop1"  id="selectlen"  onChange={this.venueschange}  required>
<option>{this.state.managerdata}</option>
{ Object.keys(this.state.venues).length == 1 ?
<option>{this.state.managerdata}</option>
:  <>
{ this.state.venues.map((edrop,index)=>(
<option value={index} >{edrop.name}</option>
))}
</>
}

</select>
</div>


<div class="form-group col-md-4">
<label >Event </label>
<Select
        mode="multiple"
        placeholder="Please select"
        value={this.state.mandata}
        onChange={this.handleChange2}
        style={{ width: '410px' }}


      >
{ Object.keys(this.state.eventdropdown).length == 1 ?
    <Select.Option key="" value="">
            Please select
          </Select.Option>
:  <>
{ this.state.eventdropdown.events.map((edrop, index)=>(
 <Select.Option key={edrop.name} value={edrop.name+"-"+edrop.id+"-"+edrop.venue_id}>
 {edrop.name}
</Select.Option>
))}
</>
}
      </Select>

</div>

</div>
<div >
<center><button type="button" class="btn btn-danger" onClick={this.updatemanagervenue} data-dismiss="modal">Save</button></center>
</div>
</div>

</div>
</div> */
    }
  }
}

export default ManagerVenue;
