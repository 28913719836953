import React from 'react';

import '../App.css';
import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import { Empty } from 'antd';
import 'datatables.net';
import 'datatables.net-bs4';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
class DispalyallFieldresultentry extends React.Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    this.state = {
      event: queryParams.get('event'),
      sort: queryParams.get('sort'),
      event_data: [{}],
      relay: false,
      notrelay: false,
      finalresultdata: '',
      finalplacedata: '',
      winddata: '',
      implementdata: '',
      wtfinaldata: '',
      breakdata: '',
      njdcrecorddata: '',
      nationaldata: '',
      americandata: '',
      americasdata: '',
      njdcdata: '',
      pointdata: '',
      bestdata: '',
      performancedata: '',
      eventdatedata: '',
      qualifydata: '',
      teampointdata: '',
      meetdatas: [{}],
      loader: true,
      sortbyvaluesdata: [{}],
      issortdata: false,
      ispitdata: true,
      fieldnjdcdatas: [{}],
      performancedatas: [{}],
      implementweightdata: [{}],
      fieldset: [{}],
      fieldsetrecord: '',
      fieldatfusasamsdatas: [{}],
    };
    this.back = this.back.bind(this);
    this.edit = this.edit.bind(this);
    this.update = this.update.bind(this);
    this.teampoints = this.teampoints.bind(this);
    this.sortbyvalues = this.sortbyvalues.bind(this);
  }
  async componentDidMount() {
    var self = this;
    var count = 0;
    const data = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
      sort: this.state.sort,
    };
    await axios
      .post(`${url.url}/api/geteventbypit/`, data, {})
      .then(function (response) {
        self.setState({ event_data: response.data });
        count++;
        if (count == 2) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {});
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    document.getElementById('homelink').style.display = 'block';

    axios
      .get(`${url.url}/api/getmeetdetail/${sessionStorage.getItem('ids')}`, {})
      .then(function (response) {
        self.setState({
          meetdatas: response.data,
        });
        count++;
        if (count == 2) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {});

    axios
      .get(`${url.url}/api/getallfieldlocationset/`, {})
      .then(function (response) {
        self.setState({
          fieldset: response.data,
          fieldsetrecord: response.data[0].current_record,
        });
      })
      .catch(function (error) {});

    const getdata = {
      sport: 'Field',
      event: this.state.event,
      class: this.state.event_data[0].field_class_value,
      AG: this.state.event_data[0].age_division,
      sex: this.state.event_data[0].gender,
    };
    axios
      .post(`${url.url}/api/getfieldnjdcvalues/`, getdata, {})
      .then(function (response) {
        self.setState({ fieldnjdcdatas: response.data.data });
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
    const getdatas = {
      sport: 'Field',
      event: this.state.event,
      class: this.state.event_data[0].field_class_value,
      AG: this.state.event_data[0].age_division,
      sex: this.state.event_data[0].gender,
    };
    await axios
      .post(`${url.url}/api/getfieldatfusasamsvalues/`, getdatas, {})
      .then(function (response) {
        self.setState({ fieldatfusasamsdatas: response.data.data });
      })
      .catch(function (error) {});

    //Performance Record Data
    const performancerecorddata = {
      Sport_Venue: 'Field',
      Event: this.state.event,
      Classification: this.state.event_data[0].field_class_value,
      Age_Group: this.state.event_data[0].age_division,
      Gender: this.state.event_data[0].gender,
    };
    await axios
      .post(`${url.url}/api/getperformancerecord/`, performancerecorddata, {})
      .then(function (response) {
        self.setState({ performancedatas: response.data.data });
      })
      .catch(function (error) {});
  }

  onSearch = (e) => {
    var value = e.target.value.toLowerCase();
    $('#wsheet tr').filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
  };

  back() {
    window.location.replace('/venuemainpage?event=' + sessionStorage.getItem('event'));
  }

  edit(e) {
    var self = this;
    self.setState({
      eventdatas: this.state.event_data[e],
      finalresultdata: this.state.event_data[e].final_results,
      finalplacedata: this.state.event_data[e].final_place,
      winddata: this.state.event_data[e].wind,
      implementdata: this.state.event_data[e].implements,
      teampointdata: this.state.event_data[e].points,
    });
  }

  update(e) {
    const datatosend = {
      final_results: this.state.finalresultdata,
      final_place: this.state.finalplacedata,
      wind: this.state.winddata,
      implements: this.state.implementdata,
      qualifies: this.state.qualifydata,
    };
    axios
      .put(`${url.url}/api/updatefieldresultentry/${this.state.eventdatas.id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  teampoints(e) {
    if (e == 1) {
      return <td>{this.state.meetdatas[0].place1}</td>;
    } else if (e == 2) {
      return <td>{this.state.meetdatas[0].place2} </td>;
    } else if (e == 3) {
      return <td>{this.state.meetdatas[0].place3} </td>;
    } else if (e == 4) {
      return <td>{this.state.meetdatas[0].place4} </td>;
    } else if (e == 5) {
      return <td>{this.state.meetdatas[0].place5} </td>;
    } else if (e == 6) {
      return <td>{this.state.meetdatas[0].place6} </td>;
    } else if (e == 7) {
      return <td>{this.state.meetdatas[0].place7} </td>;
    } else if (e == 8) {
      return <td>{this.state.meetdatas[0].place8} </td>;
    } else if (e == 9) {
      return <td>{this.state.meetdatas[0].place9} </td>;
    } else {
      return <td>{this.state.meetdatas[0].place10} </td>;
    }
  }

  sortbyvalues() {
    var self = this;
    const sortdata = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    axios.post(`${url.url}/api/getsortbyvalues/`, sortdata, {}).then(function (response) {
      self.setState({
        sortbyvaluesdata: response.data,
        loader: false,
        issortdata: true,
        ispitdata: false,
      });
    });
  }

  render() {
    if (this.state.ispitdata) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div>
                  <h1> </h1>
                  <Row>
                    <Col>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                        Back
                      </button>
                      <Card>
                        <CardHeader>
                          <h5 className='title'>
                            {this.state.event} <br></br> Result Entry By Pit
                          </h5>
                        </CardHeader>
                        <CardBody>
                          <div class='form-group row printrow'>
                            <div class='col-xs-5'>
                              <CardHeader>
                                <input
                                  class='form-control'
                                  id='inputsm'
                                  onChange={(e) => this.onSearch(e)}
                                  placeholder='Find Pit and Group'
                                  type='text'
                                />
                                <center>
                                  <button type='button' class='btn btn-success printbtn' onClick={this.sortbyvalues}>
                                    Sort by Class/Age/Gender/Final Results
                                  </button>
                                  <div style={{ float: 'right', marginRight: '3%', marginTop: '1%' }}>
                                    <label style={{ fontSize: '14px', fontWeight: 'bold' }}>Current Record :</label>
                                    <span>{this.state.fieldsetrecord}</span>
                                  </div>
                                </center>
                              </CardHeader>
                            </div>
                          </div>
                          <Form className='scrollcard1'>
                            {Object.keys(this.state.event_data).length == 0 ? (
                              <Empty />
                            ) : (
                              <>
                                <table class='worksheet_table'>
                                  <thead>
                                    <tr class='worksheet_tr'>
                                      <th>Pit</th>
                                      <th>Groups</th>
                                      <th>Bib</th>
                                      <th>Last Name</th>
                                      <th>First Name</th>
                                      <th>Class</th>
                                      <th>Placing class</th>
                                      <th>Age</th>
                                      <th>Gender</th>
                                      <th>Final Results</th>
                                      <th>Final Place</th>
                                      <th>Wind</th>
                                      <th>Implement Num</th>
                                      <th>Wtfinal</th>
                                      <th>Qual Std</th>
                                      <th>Qualifies</th>
                                      <th>Break Record</th>
                                      <th>Current Record</th>
                                      <th>EventName</th>
                                      <th>Points</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody id='wsheet'>
                                    {this.state.event_data.map((question, index) => (
                                      <tr class='worksheet_tr'>
                                        <td>{question.pits}</td>
                                        <td>{question.groups}</td>
                                        <td>{question.bib_no}</td>
                                        <td>{question.last_name}</td>
                                        <td>{question.first_name}</td>
                                        <td>{question.field_class_value}</td>
                                        <td>{question.field_class_value}</td>
                                        <td>{question.age_division}</td>
                                        <td>{question.gender}</td>
                                        <td>{question.final_results}</td>
                                        <td>{question.final_place}</td>
                                        <td>{question.wind}</td>
                                        <td>{question.implements}</td>
                                        <td>{question.wtfinal}</td>
                                        <td>{question.qualifying_standard}</td>
                                        <td>
                                          {question.final_results >= question.qualifying_standard
                                            ? question.qualifying_standard == null
                                              ? 'NRF'
                                              : 'Yes'
                                            : 'No'}
                                        </td>
                                        <td>
                                          {question.final_results < this.state.fieldatfusasamsdatas[0].result
                                            ? this.state.fieldatfusasamsdatas[0].result == null
                                              ? 'NRF'
                                              : 'Yes'
                                            : 'No'}
                                        </td>
                                        <td>{this.state.fieldnjdcdatas[0].result}</td>
                                        <td>{question.selected_events}</td>
                                        {this.teampoints(question.final_place)}
                                        <td>
                                          <button
                                            type='button'
                                            rel='tooltip'
                                            class='btn btn-success btn-sm btn-icon'
                                            onClick={() => this.edit(index)}
                                            data-toggle='modal'
                                            data-target='#useredit'
                                          >
                                            <i class='now-ui-icons ui-2_settings-90'></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </>
                            )}
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Result Entry Worksheet
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-row'>
                            <div class='form-group col-md-6'>
                              <label for='inputCity'>Final results</label>
                              <input
                                class='form-control'
                                value={this.state.finalresultdata}
                                onChange={(e) =>
                                  this.setState({
                                    finalresultdata: e.target.value,
                                  })
                                }
                                name='relayletter'
                              />
                            </div>

                            <div class='form-group col-md-6'>
                              <label for='inputCity'>Final Place</label>
                              <input
                                class='form-control'
                                value={this.state.finalplacedata}
                                onChange={(e) =>
                                  this.setState({
                                    finalplacedata: e.target.value,
                                  })
                                }
                                name='relayletter'
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group col-md-6'>
                              <label for='inputCity'>wind</label>
                              <input
                                class='form-control'
                                value={this.state.winddata}
                                onChange={(e) => this.setState({ winddata: e.target.value })}
                                name='relayletter'
                              />
                            </div>

                            <div class='form-group col-md-6'>
                              <label for='inputCity'>Implement Num</label>
                              <input
                                class='form-control'
                                value={this.state.implementdata}
                                onChange={(e) => this.setState({ implementdata: e.target.value })}
                                name='relaypoint'
                              />
                            </div>
                          </div>
                          <center>
                            <button type='button' class='btn btn-danger' onClick={(e) => this.update()}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (this.state.issortdata) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div>
                  <h1> </h1>
                  <Row>
                    <Col>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                        Back
                      </button>
                      <Card>
                        <CardHeader>
                          <h5 className='title'>
                            {this.state.event} <br></br> Result Entry By Pit
                          </h5>
                        </CardHeader>
                        <CardBody>
                          <div class='form-group row printrow'>
                            <div class='col-xs-5'>
                              <CardHeader>
                                <input
                                  class='form-control'
                                  id='inputsm'
                                  onChange={(e) => this.onSearch(e)}
                                  placeholder='Find Pit and Group'
                                  type='text'
                                />
                                <center>
                                  <button type='button' class='btn btn-success printbtn' onClick={this.sortbyvalues}>
                                    Sort by Class/Age/Gender/Final Results
                                  </button>
                                  <div>
                                    <label style={{ fontSize: '14px', fontWeight: 'bold' }}>Current Record :</label>
                                    <span>{this.state.fieldsetrecord}</span>
                                  </div>
                                </center>
                              </CardHeader>
                            </div>
                          </div>
                          <Form className='scrollcard1'>
                            {Object.keys(this.state.sortbyvaluesdata).length == 0 ? (
                              <Empty />
                            ) : (
                              <>
                                <table class='worksheet_table'>
                                  <thead>
                                    <tr class='worksheet_tr'>
                                      <th>Pit</th>
                                      <th>Groups</th>
                                      <th>Bib</th>
                                      <th>Last Name</th>
                                      <th>First Name</th>
                                      <th>Class</th>
                                      <th>Placing class</th>
                                      <th>Age</th>
                                      <th>Gender</th>
                                      <th>Final Results</th>
                                      <th>Final Place</th>
                                      <th>Wind</th>
                                      <th>Implement Num</th>
                                      <th>Wtfinal</th>
                                      <th>Qual Std</th>
                                      <th>Qualifies</th>
                                      <th>Break Record</th>
                                      <th>Current Record</th>
                                      <th>EventName</th>
                                      <th>Points</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody id='wsheet'>
                                    {this.state.sortbyvaluesdata.map((question, index) => (
                                      <tr class='worksheet_tr'>
                                        <td>{question.pits}</td>
                                        <td>{question.groups}</td>
                                        <td>{question.bib_no}</td>
                                        <td>{question.last_name}</td>
                                        <td>{question.first_name}</td>
                                        <td>{question.field_class_value}</td>
                                        <td>{question.field_class_value}</td>
                                        <td>{question.age_division}</td>
                                        <td>{question.gender}</td>
                                        <td>{question.final_results}</td>
                                        <td>{question.final_place}</td>
                                        <td>{question.wind}</td>
                                        <td>{question.implements}</td>
                                        <td>{question.wtfinal}</td>
                                        <td>{question.qualifying_standard}</td>
                                        <td>
                                          {question.final_results >= question.qualifying_standard
                                            ? question.qualifying_standard == null
                                              ? 'NRF'
                                              : 'Yes'
                                            : 'No'}
                                        </td>
                                        <td>
                                          {question.final_results > this.state.fieldatfusasamsdatas[0].result
                                            ? this.state.fieldatfusasamsdatas[0].result == null
                                              ? 'NRF'
                                              : 'Yes'
                                            : 'No'}
                                        </td>
                                        <td>{this.state.fieldnjdcdatas[0].result}</td>
                                        <td>{question.selected_events}</td>
                                        {this.teampoints(question.final_place)}
                                        <td>
                                          <button
                                            type='button'
                                            rel='tooltip'
                                            class='btn btn-success btn-sm btn-icon'
                                            onClick={() => this.edit(index)}
                                            data-toggle='modal'
                                            data-target='#useredit'
                                          >
                                            <i class='now-ui-icons ui-2_settings-90'></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </>
                            )}
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Result Entry Worksheet
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-row'>
                            <div class='form-group col-md-6'>
                              <label for='inputCity'>Final results</label>
                              <input
                                class='form-control'
                                value={this.state.finalresultdata}
                                onChange={(e) =>
                                  this.setState({
                                    finalresultdata: e.target.value,
                                  })
                                }
                                name='relayletter'
                              />
                            </div>

                            <div class='form-group col-md-6'>
                              <label for='inputCity'>Final Place</label>
                              <input
                                class='form-control'
                                value={this.state.finalplacedata}
                                onChange={(e) =>
                                  this.setState({
                                    finalplacedata: e.target.value,
                                  })
                                }
                                name='relayletter'
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group col-md-6'>
                              <label for='inputCity'>wind</label>
                              <input
                                class='form-control'
                                value={this.state.winddata}
                                onChange={(e) => this.setState({ winddata: e.target.value })}
                                name='relayletter'
                              />
                            </div>
                            <div class='form-group col-md-6'>
                              <label for='inputCity'>Implement Num</label>
                              <input
                                class='form-control'
                                value={this.state.implementdata}
                                onChange={(e) => this.setState({ implementdata: e.target.value })}
                                name='relaypoint'
                              />
                            </div>
                          </div>
                          <center>
                            <button type='button' class='btn btn-danger' onClick={(e) => this.update()}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
  }
}

export default DispalyallFieldresultentry;
