import React, { useCallback } from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import { Empty } from 'antd';
import './compound.css';
import Swal from 'sweetalert2';
import Loader from 'react-loader-spinner';
import e from 'cors';
//import Iconsreload from './Meetload.js'
const url = window.globalConfig;
class Tabletennisset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tablesetdata: [{}],
      tablesets: '',
      eventdata: '',
      venuenamedata: '',
      venuelocationdata: '',
      numberdata: '',
      currentdata: '',
      teamdata: '',
      typedata: '',
      venuedatas: [{}],
      venuespecfic: [{}],
      venueevents: '',
      tableeventsdata: '',
      tabletennislocationset: {},
      tabletennislocset: {},
      loader: true,
    };

    this.createtableset = this.createtableset.bind(this);
    this.edittableset = this.edittableset.bind(this);
    this.optionchange = this.optionchange.bind(this);
    this.dropdownchange1 = this.dropdownchange1.bind(this);
    // this.deletetableset = this.deletetableset.bind(this);
    this.updatetableset = this.updatetableset.bind(this);
    this.optionchange1 = this.optionchange1.bind(this);
    this.optionchange2 = this.optionchange2.bind(this);
    this.optionchange3 = this.optionchange3.bind(this);
    this.optionchange4 = this.optionchange4.bind(this);
    this.editlocationset = this.editlocationset.bind(this);
    this.createtabletennislocationset = this.createtabletennislocationset.bind(this);
    this.updatetabletennislocationset = this.updatetabletennislocationset.bind(this);
    this.back = this.back.bind(this);
  }

  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    var self = this;
    var count = 0;
    let textval = sessionStorage.getItem('x');
    axios.get(`${url.url}/api/venues`, {}).then(function (response) {
      self.setState({
        venuedatas: response.data.data,
        venuespecfic: response.data.data[textval],
        venueevents: response.data.data[textval].events,
      });
      // console.log(response.data.data[textval]);
      // console.log(response.data.data);
      count++;
      if (count == 3) {
        self.setState({
          loader: false,
        });
      }
    });
    //tabletennis set preference
    let meetid = sessionStorage.getItem('ids');
    axios
      .get(`${url.url}/api/gettablemeetid/${meetid}`, {})
      .then(function (response) {
        self.setState({
          tablesetdata: response.data,
        });
        // console.log(response.data);
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
    axios
      .get(`${url.url}/api/tablelocationmeetid/${meetid}`, {})
      .then(function (response) {
        self.setState({
          tabletennislocationset: response.data,
          tabletennislocset: response.data[0],
        });
        // console.log(response.data);
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {});
    //Hide and show manager
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    // document.getElementById("venueesss").style.display = "block";
  }

  //dropdown values

  dropdownchange1(e) {
    this.setState({
      teamdata: e.target.value,
    });
    this.setState({
      selectval1: e.target.value,
    });
  }

  optionchange(e) {
    this.setState({
      eventdata: e.target.value,
    });
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  optionchange1(e) {
    this.setState({
      venuenamedata: e.target.value,
    });
  }

  optionchange2(e) {
    this.setState({
      venuelocationdata: e.target.value,
    });
  }

  optionchange3(e) {
    this.setState({
      numberdata: e.target.value,
    });
  }

  optionchange4(e) {
    this.setState({
      currentdata: e.target.value,
    });
  }

  //Edit tabletennisset
  edittableset(e) {
    var self = this;
    // console.log(e);
    self.setState({
      tableeventsdata: self.state.venuespecfic.events[e].name,
    });
    var loop = true;
    let textval = sessionStorage.getItem('x');
    self.state.tablesetdata.forEach((question, index) => {
      if (question.event_name == self.state.venuespecfic.events[e].name) {
        self.setState({
          tablesets: question,
          selected_id: question.id,
          eventdata: question.event_name,
          teamdata: question.team_scoring,
          btncheck: false,
        });
        loop = false;
        // console.log("Test");
      } else if (loop) {
        self.setState({
          tablesets: '',
          selected_id: '',
          eventdata: self.state.venuespecfic.events[e].name,
          teamdata: 'Yes',
          btncheck: true,
        });
        loop = true;
        // console.log("Test444");
      }
    });
    if (self.state.tablesetdata.length == 0) {
      self.setState({
        tablesets: '',
        selected_id: '',
        eventdata: self.state.venuespecfic.events[e].name,
        teamdata: 'Yes',
        btncheck: true,
      });
      // console.log("dsds");
    }
  }
  editlocationset() {
    let textval = sessionStorage.getItem('x');
    if (this.state.venuedatas[textval].name == this.state.tabletennislocset.venue_name) {
      this.setState({
        venuedata: this.state.tabletennislocationset[0].venue_name,
        venuelocationdata: this.state.tabletennislocationset[0].venue_location,
        numberdata: this.state.tabletennislocationset[0].number_of_events,
        currentdata: this.state.tabletennislocationset[0].current_record,
        btncheck1: false,
      });
    } else {
      this.setState({
        venuedata: this.state.venuedatas[textval].name,
        venuelocationdata: '',
        numberdata: this.state.venuespecfic.events.length,
        currentdata: '',
        btncheck1: true,
      });
    }
  }

  createtabletennislocationset() {
    const datatosend = {
      venue_name: this.state.venuedata,
      venue_location: this.state.venuelocationdata,
      number_of_events: this.state.numberdata,
      current_record: this.state.currentdata,
      meet_id: parseInt(sessionStorage.getItem('ids')),
    };
    axios
      .post(`${url.url}/api/createtablelocationset`, datatosend, {})
      .then(function (response) {
        if (response.statusText == 'Created' || response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'TableTennis Location Set Created successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  updatetabletennislocationset() {
    var self = this;
    //   e.preventDefault()
    const datatosend = {
      venue_name: this.state.venuedata,
      venue_location: this.state.venuelocationdata,
      number_of_events: this.state.numberdata,
      current_record: this.state.currentdata,
    };

    // console.log(datatosend);
    axios
      .put(`${url.url}/api/updatetablelocationset/${this.state.tabletennislocationset[0].id}`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'TableTennis Location Set Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  //Create tabletennis set
  createtableset() {
    var self = this;
    const setvalue = (q, index) => {
      if (self.state.tablesetdata.length > index) {
        self.setState({
          tablesets: self.state.tablesetdata[index],
          selected_id: self.state.tablesetdata[index].id,
          eventdata: self.state.tablesetdata[index].event_name,
          teamdata: self.state.tablesetdata[index].team_scoring,
        });
      }
    };
    self.state.tablesetdata.map((question, index) => {
      setvalue(question, index);
    });
    const datatosend = {
      event_name: this.state.eventdata,
      team_scoring: this.state.teamdata,
      created_by: 1,
      meet_id: parseInt(sessionStorage.getItem('ids')),
    };

    // console.log(datatosend);
    axios
      .post(`${url.url}/api/createtablesetrecords`, datatosend, {})
      .then(function (response) {
        // console.log(response);
        if (response.statusText == 'Created' || response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'TableTennis Set Created successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  //Update tabletennisset
  updatetableset(e) {
    var self = this;
    //   e.preventDefault()
    const datatosend = {
      event_name: this.state.eventdata,
      team_scoring: this.state.teamdata,
    };

    // console.log(datatosend);
    axios
      .put(`${url.url}/api/updatetablesetrecords/${this.state.tablesets.id}`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Tabletennis Set Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  //back Button
  back() {
    window.location.replace('/eventpreference');
  }

  render() {
    let textval = sessionStorage.getItem('x');
    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader
                type='ThreeDots'
                color='#00BFFF'
                height={70}
                width={70}
                // timeout={3000} //3 secs
              />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div className='content'>
              <Row>
                <Col md='8'>
                  {/* <button type="button" data-toggle="modal" data-target="#newmeet" class="btn btn-danger btn-round">New TableTennis set</button> */}
                  <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                    Back
                  </button>
                  {''}
                  <Card>
                    <CardHeader>
                      <h5 className='title'>Table Tennis Set Preferences</h5>
                    </CardHeader>

                    <CardBody>
                      <Form>
                        <div class='form-group row'>
                          <div class='col-xs-5'>
                            {Object.keys(this.state.venuespecfic).length == 1 ? (
                              <Empty />
                            ) : (
                              <CardHeader id='deatils_heading'>
                                <tr>
                                  <th>
                                    <p>
                                      Venue Name:
                                      <span>{this.state.venuespecfic.name}</span>
                                    </p>
                                  </th>
                                  {''}
                                  <th>
                                    <p>
                                      Venue Location:
                                      <span>
                                        {this.state.tabletennislocationset[0]
                                          ? this.state.tabletennislocationset[0].venue_location
                                          : ''}
                                      </span>
                                    </p>
                                  </th>
                                  {''}
                                  {/* <th>
                                    <p>
                                      Number of Events:
                                      <span>
                                        {this.state.venueevents.length}
                                      </span>
                                    </p>
                                  </th>
                                  {""} */}
                                  <th>
                                    <p>
                                      Current Record Level:
                                      <span>
                                        {this.state.tabletennislocationset[0]
                                          ? this.state.tabletennislocationset[0].current_record
                                          : ''}
                                      </span>
                                    </p>
                                  </th>
                                  {''}
                                  <th>
                                    <button
                                      type='button'
                                      rel='tooltip'
                                      data-toggle='modal'
                                      data-target='#newmeet'
                                      class='btn btn-success btn-sm btn-icon'
                                      onClick={this.editlocationset}
                                    >
                                      <i class='now-ui-icons ui-2_settings-90'></i>
                                    </button>
                                  </th>
                                </tr>
                              </CardHeader>
                            )}
                          </div>
                        </div>

                        {/* table --------------------------------------------------- */}
                        {Object.keys(this.state.venuespecfic).length == 1 ? (
                          <Empty />
                        ) : (
                          <>
                            <Table id='my' responsive>
                              <thead>
                                <tr>
                                  <th className='text-center'>#</th>
                                  <th>Event name</th>
                                  <th>Team Scoring</th>

                                  <th class='disabled-sorting text-right'>Actions</th>
                                </tr>
                              </thead>
                              <tbody id='myTable'>
                                {this.state.venuespecfic.events.map((question, index) => (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{question.name}</td>
                                    <td>
                                      {this.state.tablesetdata.map((question1, index1) =>
                                        question.name === question1.event_name ? question1.team_scoring : ''
                                      )}
                                    </td>

                                    <td class='td-actions text-right'>
                                      <button
                                        type='button'
                                        rel='tooltip'
                                        class='btn btn-success btn-sm btn-icon'
                                        onClick={() => this.edittableset(index)}
                                        data-toggle='modal'
                                        data-target='#meetedit'
                                      >
                                        <i class='now-ui-icons ui-2_settings-90'></i>
                                      </button>
                                      {` `}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </>
                        )}
                        {/* table --------------------------------------------------- */}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* edit meet modal ----------------------------------------------------------------------------------------------------------------- */}
              <div class='modal fade' id='meetedit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        {this.state.tableeventsdata + ' ' + 'TableTennisSet'}{' '}
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='inputAddress'>Event name</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            placeholder='1234 Main St'
                            name='evntname'
                            value={this.state.eventdata}
                            onChange={this.optionchange}
                            disabled='disabled'
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Team Scoring</label>
                          <select
                            class='form-control'
                            id='exampleFormControlSelect1'
                            value={this.state.teamdata}
                            onChange={this.dropdownchange1}
                            required
                          >
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                          </select>
                        </div>

                        {this.state.btncheck == true ? (
                          <center>
                            <button
                              type='button'
                              onClick={(e) => this.createtableset()}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Create
                            </button>
                          </center>
                        ) : (
                          <>
                            <center>
                              <button type='button' class='btn btn-danger' onClick={(e) => this.updatetableset()}>
                                Update
                              </button>
                            </center>
                          </>
                        )}
                        {/* <center><button type="submit" onClick={this.updatetableset} data-dismiss="modal" class="btn btn-danger">Update</button></center> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class='modal fade' id='newmeet' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        TableTennis Location Set
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='inputAddress'>Venue Name</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='venuename'
                            value={this.state.venuedata}
                            onChange={this.optionchange1}
                            placeholder='Enter venue name'
                            disabled='disabled'
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Venue location</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='venuelocation'
                            value={this.state.venuelocationdata}
                            onChange={this.optionchange2}
                            placeholder='Enter Venue location'
                            required
                          />
                        </div>
                        {/*
                        <div class="form-group">
                          <label for="inputAddress">Number of events</label>
                          <input
                            type="text"
                            class="form-control"
                            id="inputAddress"
                            name="numevents"
                            value={this.state.numberdata}
                            onChange={this.optionchange3}
                            placeholder="Enter Number of events"
                            disabled="disabled"
                            required
                          />
                        </div> */}

                        <div class='form-group'>
                          <label for='inputAddress'>Current Record Level</label>
                          <select
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='currecord'
                            value={this.state.currentdata}
                            onChange={this.optionchange4}
                            placeholder='Enter current record'
                            required
                          >
                            <option>Please Select</option>
                            <option value='National'>National</option>
                            <option value='American IPC'>American IPC</option>
                            <option value='Americas IPC'>Americas IPC</option>
                            <option value='NJDC'>NJDC</option>
                          </select>
                        </div>

                        {this.state.btncheck1 == true ? (
                          <center>
                            <button
                              type='button'
                              onClick={(e) => this.createtabletennislocationset()}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Create
                            </button>
                          </center>
                        ) : (
                          <>
                            <center>
                              <button
                                type='button'
                                class='btn btn-danger'
                                onClick={(e) => this.updatetabletennislocationset()}
                              >
                                Update
                              </button>
                            </center>
                          </>
                        )}
                        {/* <center><button type="submit" onClick={this.updatetableset} data-dismiss="modal" class="btn btn-danger">Update</button></center> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Tabletennisset;
