import React from 'react';

import '../App.css';
import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';

import { Empty } from 'antd';
import 'datatables.net';
import 'datatables.net-bs4';
import Loader from 'react-loader-spinner';
import Fenceworksheet from './Fenceworksheet';
import ReactToPrint from 'react-to-print';
const url = window.globalConfig;
class Fenceheatsheet extends React.Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    this.state = {
      event: queryParams.get('event'),
      event_data: [{}],
      loader: true,
      groupdata: '',
      finalresultdata: '',
      finalplacedata: '',
      eventdatas: '',
    };
    this.back = this.back.bind(this);
  }
  async componentDidMount() {
    var self = this;
    const data = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    await axios
      .post(`${url.url}/api/getsortregisteradtennisevent/`, data, {})
      .then(function (response) {
        self.setState({ event_data: response.data, loader: false });
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    // document.getElementById("venueesss").style.display = "block";
  }

  back() {
    window.location.replace('/venuemainpage?event=' + sessionStorage.getItem('event'));
  }

  render() {
    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader
                type='ThreeDots'
                color='#00BFFF'
                height={70}
                width={70}
                // timeout={3000} //3 secs
              />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div>
              <div>
                <h1> </h1>
                <Row>
                  <Col>
                    <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                      Back
                    </button>
                    <Card>
                      <CardHeader>
                        <h5 className='title'>
                          {this.state.event} <br></br> Show Results Worksheet
                        </h5>
                      </CardHeader>
                      <CardBody>
                        <div class='form-group row printrow'>
                          <div class='col-xs-5'>
                            <CardHeader>
                              <center>
                                <ReactToPrint
                                  trigger={() => (
                                    <button type='button' class='btn btn-success printbtn'>
                                      Print Show Result Worksheet
                                    </button>
                                  )}
                                  content={() => this.componentRef2}
                                />
                                <div class='textnone' style={{ display: 'none' }}>
                                  <Fenceheatsheet ref={(el) => (this.componentRef2 = el)} event={this.state.event} />
                                </div>
                              </center>
                            </CardHeader>
                          </div>
                        </div>
                        <Form>
                          {Object.keys(this.state.event_data).length == 0 ? (
                            <Empty />
                          ) : (
                            <>
                              <Table class='worksheet_table'>
                                <thead>
                                  <tr class='worksheet_tr'>
                                    <th>Groups</th>
                                    <th>Class</th>
                                    <th>Age</th>
                                    <th>Gender</th>
                                    <th>Qualmark</th>
                                    <th>Bib</th>
                                    <th>Last Name</th>
                                    <th>First Name</th>
                                    <th>Final Results</th>
                                    <th>Final Place</th>
                                    <th>EventName</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.event_data.map((question, index) => (
                                    <tr class='worksheet_tr'>
                                      <td>{question.groups}</td>
                                      <td>{question.fence_class_value}</td>
                                      <td>{question.age_division}</td>
                                      <td>{question.gender}</td>
                                      <td>{question.Qualifying_marks}</td>
                                      <td>{question.bib_no}</td>
                                      <td>{question.last_name}</td>
                                      <td>{question.first_name}</td>
                                      <td>{question.final_results}</td>
                                      <td>{question.final_place}</td>
                                      <td>{question.selected_events}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </>
                          )}
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
              <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Show Results Worksheet
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-row'>
                          <div class='form-group'>
                            <label for='inputCity'>Group</label>
                            <input
                              class='form-control'
                              value={this.state.groupdata}
                              onChange={(e) => this.setState({ groupdata: e.target.value })}
                              name='relayletter'
                            />
                          </div>
                        </div>

                        <div class='form-row'>
                          <div class='form-group'>
                            <label for='inputCity'>Final Result</label>
                            <input
                              class='form-control'
                              value={this.state.finalresultdata}
                              onChange={(e) => this.setState({ finalresultdata: e.target.value })}
                              name='relayletter'
                            />
                          </div>
                        </div>

                        <div class='form-row'>
                          <div class='form-group'>
                            <label for='inputCity'>Final Place</label>
                            <input
                              class='form-control'
                              value={this.state.finalplacedata}
                              onChange={(e) => this.setState({ finalplacedata: e.target.value })}
                              name='relayletter'
                            />
                          </div>
                        </div>

                        <center>
                          <button type='button' class='btn btn-danger' onClick={(e) => this.update()}>
                            Update
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Fenceheatsheet;
