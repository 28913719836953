import React from 'react';

import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';

import Swal from 'sweetalert2';

import { Select, Input } from 'antd';
import 'antd/dist/antd.css';
const { Option } = Select;
const url = window.globalConfig;

class Rstatus extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      regdetails: [{}],
    };
  }
  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });
    var self = this;
    axios
      .get(`${url.url}/api/getregisterdetail/${this.props.logindata.user.id}`, {})
      .then(function (response) {
        self.setState({
          regdetails: response.data,
        });
      })
      .catch(function (error) {
        self.setState({
          regdetails: [{}],
        });
      });
  }

  render() {
    //sessionStorage.setItem("path","/regstatus");
    return (
      <div>
        <div className='content'>
          <Row>
            <Col md='8'>
              <Card>
                <CardHeader>
                  <h5 className='title'>Status</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div class='form-group row'>
                      <div class='col-xs-5'>
                        <CardHeader>
                          <p>Search</p>
                          <input class='form-control' id='inputsm' placeholder='Search..' type='text' />
                        </CardHeader>
                      </div>
                    </div>
                    {/* table --------------------------------------------------- */}
                    <Table id='my' responsive>
                      <thead>
                        <tr>
                          <th className='text-center'>#</th>
                          <th>Meet Name</th>
                          <th>Venue Name</th>
                          <th>Event Name</th>
                          <th>Bib No</th>
                          <th>Approval Status</th>
                          <th>Reason of Rejection</th>
                        </tr>
                      </thead>
                      <tbody id='myTable'>
                        {this.state.regdetails.map((question, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{question.meetname}</td>
                            <td>{question.venuename}</td>
                            <td>{question.eventname}</td>
                            <td>{question.bib_no}</td>
                            <td>{question.status}</td>
                            <td>{question.reason}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {/* table --------------------------------------------------- */}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Rstatus;
