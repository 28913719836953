import React from 'react';

import '../App.css';
import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import { Empty } from 'antd';
import 'datatables.net';
import 'datatables.net-bs4';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
class Trackfinalresult extends React.Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    this.state = {
      event: queryParams.get('event'),
      event_data: [{}],
      relay: false,
      notrelay: false,
      windfinaldata: '',
      heatfinaldata: '',
      lanefinaldata: '',
      breakdata: '',
      recorddata: '',
      nationaldata: '',
      americasdata: '',
      americandata: '',
      njdcdata: '',
      trackdatas: [{}],
      prelimresultdata: '',
      prelimplacedata: '',
      finalplacedata: '',
      finalresultdata: '',
      norelayfinalplacedata: '',
      norelayfinalresultdata: '',
      norelayprelimplacedata: '',
      norelayprelimresultdata: '',
      qualifiesdata: '',
      loader: true,
      trackset: [{}],
      tracksetrecord: '',
      performancedatas: [{}],
      trackatfusasamsnjdcdatas: [{}],
      tracknjdcdatas: [{}],
    };
    this.back = this.back.bind(this);
    this.edit = this.edit.bind(this);
    this.update = this.update.bind(this);
  }
  async componentDidMount() {
    var self = this;
    const data = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    // console.log(data);

    await axios
      .post(`${url.url}/api/getregistertrackevent/`, data, {})
      .then(function (response) {
        self.setState({ event_data: response.data, loader: false });
        // console.log(response.data);
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    // document.getElementById("venueesss").style.display = "block";

    await axios
      .get(`${url.url}/api/getalltracklocationset/`, {})
      .then(function (response) {
        self.setState({ trackset: response.data, tracksetrecord: response.data[0].current_record });
        // console.log(response.data.data[0]);
      })
      .catch(function (error) {});

    //Track njdc record
    const getdata = {
      sport: 'Track',
      event: this.state.event,
      class: this.state.event_data[0].track_class_value,
      AG: this.state.event_data[0].age_division,
      sex: this.state.event_data[0].gender,
    };
    //   console.log(getdata)
    await axios
      .post(`${url.url}/api/gettracktnjdcrecordvalues/`, getdata, {})
      .then(function (response) {
        self.setState({ tracknjdcdatas: response.data.data });
        //   console.log(response.data.data);
      })
      .catch(function (error) {});

    //Track atfusasams record
    const getdatas = {
      sport: 'Track',
      event: this.state.event,
      class: this.state.event_data[0].track_class_value,
      AG: this.state.event_data[0].age_division,
      sex: this.state.event_data[0].gender,
    };
    //   console.log(getdatas)
    await axios
      .post(`${url.url}/api/gettracktfusasamsrecordvalues/`, getdatas, {})
      .then(function (response) {
        self.setState({ trackatfusasamsnjdcdatas: response.data.data });
        //   console.log(response.data.data);
      })
      .catch(function (error) {});

    const performancerecorddata = {
      Sport_Venue: 'Track',
      Event: this.state.event,
      Classification: this.state.event_data[0].track_class_value,
      Age_Group: this.state.event_data[0].age_division,
      Gender: this.state.event_data[0].gender,
    };
    //   console.log(performancerecorddata)
    await axios
      .post(`${url.url}/api/getperformancerecord/`, performancerecorddata, {})
      .then(function (response) {
        self.setState({ performancedatas: response.data.data });
        // console.log(response.data.data[0]);
      })
      .catch(function (error) {});
  }
  back() {
    window.location.replace('/venuemainpage?event=' + sessionStorage.getItem('event'));
  }

  edit(e) {
    var self = this;
    self.setState({
      eventdatas: this.state.event_data[e],
      prelimresultdata: this.state.event_data[e].prelim_result,
      prelimplacedata: this.state.event_data[e].prelim_place,
      windfinaldata: !this.state.event_data[e].wind_final ? '' : this.state.event_data[e].wind_final,
      heatfinaldata: this.state.event_data[e].heat_final,
      lanefinaldata: this.state.event_data[e].lane_final,
      norelayfinalresultdata: !this.state.event_data[e].final_result ? '' : this.state.event_data[e].final_result,
      norelayfinalplacedata: !this.state.event_data[e].final_place ? '' : this.state.event_data[e].final_place,
    });
  }

  update(e) {
    const datatosend = {
      prelim_result: this.state.prelimresultdata,
      prelim_place: this.state.prelimplacedata,
      wind_final: this.state.windfinaldata,
      final_result: this.state.norelayfinalresultdata,
      final_place: this.state.norelayfinalplacedata,
    };
    axios
      .put(`${url.url}/api/updatetrackfinalresult/${this.state.eventdatas.id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  render() {
    if (this.state.relay) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div>
                  <h1> </h1>
                  <Row>
                    <Col>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                        Back
                      </button>
                      <Card className='scrollcard'>
                        <CardHeader>
                          <h5 className='title'>
                            {this.state.event} <br></br> Final Results
                          </h5>
                        </CardHeader>
                        <CardBody>
                          <div class='form-group row printrow'>
                            <div class='col-xs-5'>
                              <CardHeader>
                                <center>
                                  <div>
                                    <label style={{ fontSize: '14px', fontWeight: 'bold' }}>Current Record :</label>
                                    <span>{this.state.tracksetrecord}</span>
                                  </div>
                                </center>
                              </CardHeader>
                            </div>
                          </div>
                          <Form>
                            {Object.keys(this.state.event_data).length == 0 ? (
                              <Empty />
                            ) : (
                              <>
                                <table class='worksheet_table'>
                                  <thead>
                                    <tr class='worksheet_tr'>
                                      <th>Group</th>
                                      <th>Heat</th>
                                      <th>Lane</th>
                                      <th>Results</th>
                                      <th>Place</th>
                                      <th>Wind</th>
                                      <th>Bib</th>
                                      <th>Last Name</th>
                                      <th>First Name</th>
                                      <th>Country</th>
                                      <th>Class</th>
                                      <th>Age</th>
                                      <th>Gender</th>
                                      <th>Qualifies</th>
                                      <th>Qualifying Standard</th>
                                      <th>NJDC Record</th>
                                      <th>Break Record</th>
                                      <th>Record</th>
                                      {/* {this.state.tracksetrecord == "National"?
                                                                            <th>National</th>
                                                                            : ""}
                                                                            {this.state.tracksetrecord == "American"?
                                                                            <th>American</th>
                                                                            : ""}
                                                                            {this.state.tracksetrecord == "Americas"?
                                                                            <th>Americas</th>
                                                                            : ""}
                                                                            {this.state.tracksetrecord == "NJDC"?
                                                                            <th>NJDC </th>
                                                                            : ""} */}
                                      <th>EventName</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.event_data.map((question, index) => (
                                      <tr class='worksheet_tr'>
                                        <td></td>
                                        <td>{question.heat}</td>
                                        <td>{question.lane}</td>
                                        <td>{question.prelim_result}</td>
                                        <td>{question.prelim_place}</td>
                                        <td>{}</td>
                                        <td>{question.bib_no}</td>
                                        <td>{question.last_name}</td>
                                        <td>{question.first_name}</td>
                                        <td>{question.country}</td>
                                        <td>{question.track_class_value}</td>
                                        <td>{question.age_division}</td>
                                        <td>{question.gender}</td>
                                        <td>
                                          {question.final_result >= question.qualifying_standard
                                            ? question.qualifying_standard == null
                                              ? 'NRF'
                                              : 'Yes'
                                            : 'No'}
                                        </td>
                                        <td>{question.qualifying_standard}</td>
                                        {this.state.tracknjdcdatas.map((question1, index) => (
                                          <td>
                                            {question.track_class_value == question1.class ? question1.result : ''}
                                          </td>
                                        ))}
                                        <td>
                                          {question.final_result > this.state.trackatfusasamsnjdcdatas[0].result
                                            ? this.state.trackatfusasamsnjdcdatas[0].result == null
                                              ? 'NRF'
                                              : 'Yes'
                                            : 'No'}
                                        </td>
                                        <td>{this.state.tracksetrecord}</td>
                                        {/* {this.state.tracksetrecord == "National"?
                                                                                        <td>{this.state.trackatfusasamsnjdcdatas[0].result != null ? "Yes" : "No"}</td>
                                                                                        : ""}
                                                                                        {this.state.tracksetrecord == "American"?
                                                                                        <td>{"No"}</td>
                                                                                        : ""}
                                                                                        {this.state.tracksetrecord == "Americas"?
                                                                                        <td>{"No"}</td>
                                                                                        : ""}
                                                                                        {this.state.tracksetrecord == "NJDC"?
                                                                                        <td>{this.state.tracknjdcdatas[0].result != null ? "Yes" : "No"}</td>
                                                                                        : ""} */}
                                        <td>{question.selected_events}</td>
                                        <td>
                                          <button
                                            type='button'
                                            rel='tooltip'
                                            class='btn btn-success btn-sm btn-icon'
                                            onClick={() => this.edit(index)}
                                            data-toggle='modal'
                                            data-target='#useredit'
                                          >
                                            <i class='now-ui-icons ui-2_settings-90'></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </>
                            )}
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Final Result
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-row'>
                            <div class='form-group '>
                              <label for='inputCity'>Prelim Result</label>
                              <input
                                class='form-control'
                                value={this.state.prelimresultdata}
                                onChange={(e) =>
                                  this.setState({
                                    prelimresultdata: e.target.value,
                                  })
                                }
                                name='relayletter'
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group '>
                              <label for='inputCity'>Prelim Place</label>
                              <input
                                class='form-control'
                                value={this.state.prelimplacedata}
                                onChange={(e) =>
                                  this.setState({
                                    prelimplacedata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group '>
                              <label for='inputCity'>Wind</label>
                              <input
                                class='form-control'
                                value={this.state.windfinaldata}
                                onChange={(e) =>
                                  this.setState({
                                    windfinaldata: e.target.value,
                                  })
                                }
                                name='relayletter'
                              />
                            </div>
                          </div>

                          <center>
                            <button type='button' class='btn btn-danger' onClick={(e) => this.update()}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (this.state.notrelay) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div>
                  <h1> </h1>
                  <Row>
                    <Col>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                        Back
                      </button>
                      <Card className='scrollcard'>
                        <CardHeader>
                          <h5 className='title'>
                            {this.state.event} <br></br> Final Results
                          </h5>
                        </CardHeader>
                        <CardBody>
                          <div class='form-group row printrow'>
                            <div class='col-xs-5'>
                              <CardHeader>
                                <center>
                                  <div>
                                    <label style={{ fontSize: '14px', fontWeight: 'bold' }}>Current Record :</label>
                                    <span>{this.state.tracksetrecord}</span>
                                  </div>
                                </center>
                              </CardHeader>
                            </div>
                          </div>
                          <Form>
                            {Object.keys(this.state.event_data).length == 0 ? (
                              <Empty />
                            ) : (
                              <>
                                <table class='worksheet_table'>
                                  <thead>
                                    <tr class='worksheet_tr'>
                                      <th>Group</th>
                                      <th>Heat</th>
                                      <th>Lane</th>
                                      <th>Results</th>
                                      <th>Place</th>
                                      <th>Wind</th>
                                      <th>Bib</th>
                                      <th>Last Name</th>
                                      <th>First Name</th>
                                      <th>Country</th>
                                      <th>Class</th>
                                      <th>Age</th>
                                      <th>Gender</th>
                                      <th>Qualifies</th>
                                      <th>Qualifying Standard</th>
                                      <th>Current Record</th>
                                      <th>Break Record</th>
                                      {/* <th>Record</th> */}
                                      {/* {this.state.tracksetrecord == "National"?
                                                                            <th>National</th>
                                                                            : ""}
                                                                            {this.state.tracksetrecord == "American"?
                                                                            <th>American</th>
                                                                            : ""}
                                                                            {this.state.tracksetrecord == "Americas"?
                                                                            <th>Americas</th>
                                                                            : ""}
                                                                            {this.state.tracksetrecord == "NJDC"?
                                                                            <th>NJDC </th>
                                                                            : ""} */}
                                      <th>EventName</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.event_data.map((question, index) => (
                                      <tr class='worksheet_tr'>
                                        <td>{question.final_group}</td>

                                        <td>{question.heat}</td>
                                        <td>{question.lane}</td>
                                        <td>{question.final_result}</td>
                                        <td>{question.final_place}</td>

                                        <td>{question.wind_final}</td>
                                        <td>{question.bib_no}</td>
                                        <td>{question.last_name}</td>
                                        <td>{question.first_name}</td>
                                        <td>{question.country}</td>
                                        <td>{question.track_class_value}</td>

                                        <td>{question.age_division}</td>
                                        <td>{question.gender}</td>
                                        <td>
                                          {question.final_result >= question.qualifying_standard
                                            ? question.qualifying_standard == null
                                              ? 'NRF'
                                              : 'Yes'
                                            : 'No'}
                                        </td>
                                        <td>{question.qualifying_standard}</td>
                                        {this.state.tracknjdcdatas.map((question1, index) => (
                                          <td>
                                            {question.track_class_value == question1.class ? question1.result : ''}
                                          </td>
                                        ))}
                                        <td>
                                          {question.final_result > this.state.trackatfusasamsnjdcdatas[0].result
                                            ? this.state.trackatfusasamsnjdcdatas[0].result == null
                                              ? 'NRF'
                                              : 'Yes'
                                            : 'No'}
                                        </td>
                                        {/* <td>{this.state.tracksetrecord}</td>                                                                                    */}
                                        {/* {this.state.tracksetrecord == "National"?
                                                                                        <td>{this.state.trackatfusasamsnjdcdatas[0].result != null ? "Yes" : "No"}</td>
                                                                                        : ""}
                                                                                        {this.state.tracksetrecord == "American"?
                                                                                        <td>{"No"}</td>
                                                                                        : ""}
                                                                                        {this.state.tracksetrecord == "Americas"?
                                                                                        <td>{"No"}</td>
                                                                                        : ""}
                                                                                        {this.state.tracksetrecord == "NJDC"?
                                                                                        <td>{this.state.tracknjdcdatas[0].result != null ? "Yes" : "No"}</td>
                                                                                        : ""} */}
                                        <td>{question.selected_events}</td>
                                        <td>
                                          <button
                                            type='button'
                                            rel='tooltip'
                                            class='btn btn-success btn-sm btn-icon'
                                            onClick={() => this.edit(index)}
                                            data-toggle='modal'
                                            data-target='#useredit'
                                          >
                                            <i class='now-ui-icons ui-2_settings-90'></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </>
                            )}
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Final Result
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-row'>
                            <div class='form-group'>
                              <label for='inputCity'>Results</label>
                              <input
                                class='form-control'
                                value={this.state.norelayfinalresultdata}
                                onChange={(e) =>
                                  this.setState({
                                    norelayfinalresultdata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group'>
                              <label for='inputCity'>Place</label>
                              <input
                                class='form-control'
                                value={this.state.norelayfinalplacedata}
                                onChange={(e) =>
                                  this.setState({
                                    norelayfinalplacedata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group '>
                              <label for='inputCity'>Wind</label>
                              <input
                                class='form-control'
                                value={this.state.windfinaldata}
                                onChange={(e) =>
                                  this.setState({
                                    windfinaldata: e.target.value,
                                  })
                                }
                                name='relayletter'
                              />
                            </div>
                          </div>

                          <center>
                            <button type='button' class='btn btn-danger' onClick={(e) => this.update()}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    return this.state.event == '4 X 100 Meters' ||
      this.state.event == '4 X 400 Meters' ||
      this.state.event == '800 Medley' ||
      this.state.event == '4 X 100 Universal'
      ? (this.state.relay = true)
      : (this.state.notrelay = true);
  }
}

export default Trackfinalresult;
