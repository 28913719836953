import React from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';

import Swal from 'sweetalert2';

import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
class Pentathlon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pendatas: [{}],
      managers: [{}],
      meetname: [{}],
      assignedmanager: '',
      name: '',
      defaultoption: '',
      managerid: '',
      ppointdata: [{}],
      evntname: '',
      gendername: '',
      clasname: '',
      aname: '',
      bname: '',
      cname: '',
      typename: '',
      noname: '',
      offset: 0,
      tableData: [],
      activePage: 1,
      itemsCountPerPage: 1,
      totalItemsCount: 1,
      pageRangeDisplayed: 5,
      loader: true,
    };

    this.editrecord = this.editrecord.bind(this);
    this.optionchange = this.optionchange.bind(this);
    this.updaterecord = this.updaterecord.bind(this);
    this.deleterecord = this.deleterecord.bind(this);
    this.addrecord = this.addrecord.bind(this);
    this.optionchange1 = this.optionchange1.bind(this);
    this.optionchange2 = this.optionchange2.bind(this);
    this.optionchange3 = this.optionchange3.bind(this);
    this.optionchange4 = this.optionchange4.bind(this);
    this.optionchange5 = this.optionchange5.bind(this);
    this.optionchange6 = this.optionchange6.bind(this);
    this.optionchange7 = this.optionchange7.bind(this);
  }

  async handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });

    var self = this;
    await axios
      .get(`${url.url}/api/getallpentathlonrecord?page=` + pageNumber, {})
      .then(function (response) {
        self.setState({
          pendatas: response.data.data,
          itemsCountPerPage: response.data.per_page,
          totalItemsCount: response.data.total,
          activePage: response.data.current_page,
          loader: false,
        });
      })
      .catch(function (error) {});
  }

  async componentDidMount() {
    // $(document).ready(function () {
    //   $("#inputsm").on("keyup", function () {
    //     var value = $(this).val().toLowerCase();
    //     $("#myTable tr").filter(function () {
    //       $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    //     });
    //   });
    // });

    this.handlePageChange();

    // var self = this;
    // await axios
    //   .get(`${url.url}/api/getallpentathlonrecord`, {})
    //   .then(function (response) {
    //     self.setState({
    //       pendatas: response.data.data,
    //       loader: false,
    //     })
    //      console.log(response.data.data);
    //   }).catch(function(error){
    //     self.setState({
    //     loader: true,
    //   })
    // });
  }

  onSearch = (e) => {
    var value = e.target.value.toLowerCase();
    $('#myTable tr').filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
  };
  // edit meet------------------------------------------------------------------------------------------------

  editrecord(e) {
    var self = this;
    self.setState({
      ppointdata: this.state.pendatas[e],
      evntname: this.state.pendatas[e].event,
      gendername: this.state.pendatas[e].gender,
      clasname: this.state.pendatas[e].class,
      aname: this.state.pendatas[e].A,
      bname: this.state.pendatas[e].B,
      cname: this.state.pendatas[e].C,
      typename: this.state.pendatas[e].typerecord,
      // noname: this.state.pendatas[e].eventno
    });
  }

  optionchange2(e) {
    this.setState({
      evntname: e.target.value,
    });
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  optionchange1(e) {
    this.setState({
      clasname: e.target.value,
    });
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  optionchange(e) {
    this.setState({
      gendername: e.target.value,
    });
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  optionchange3(e) {
    this.setState({
      aname: e.target.value,
    });
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  optionchange4(e) {
    this.setState({
      bname: e.target.value,
    });
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  optionchange5(e) {
    this.setState({
      cname: e.target.value,
    });
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  optionchange6(e) {
    this.setState({
      typename: e.target.value,
    });
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  optionchange7(e) {
    this.setState({
      noname: e.target.value,
    });
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  updaterecord() {
    var self = this;
    var y = this.state.evntname + this.state.gendername;
    const datatosend = {
      event: this.state.eventrecord,
      gender: this.state.gendername,
      class: this.state.clasname,
      A: this.state.aname,
      B: this.state.bname,
      C: this.state.cname,
      evntgenclass: y,
      typerecord: this.state.typename,
      //  'eventno': this.state.noname
    };

    axios
      .put(`${url.url}/api/updatepentathlonrecord/${this.state.ppointdata.id}`, datatosend, {})

      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Record Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  // edit meet------------------------------------------------------------------------------------------------

  deleterecord(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deletepentathlonrecord/${this.state.pendatas[e].id}`, {})
          .then(function (response) {})

          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });
  }
  // ---------------------------------------------------------edit meet--------------------------------------------------

  addrecord(e) {
    var self = this;
    var x = this.state.eventrecord + this.state.genderrecord;
    //  ;
    const datatosend = {
      event: this.state.eventrecord,
      gender: this.state.genderrecord,
      class: this.state.classrecord,
      A: this.state.avalue,
      B: this.state.bvalue,
      C: this.state.cvalue,
      evntgenclass: x,
      typerecord: this.state.typerecord,
      //  'eventno': this.state.numvalue,
      created_by: 1,
    };
    axios
      .post(`${url.url}/api/createpentathlonrecord`, datatosend, {})
      .then(function (response) {
        if (response.statusText == 'Created' || response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'Record Created successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  render() {
    //sessionStorage.setItem("path","/meets");
    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader
                type='ThreeDots'
                color='#00BFFF'
                height={70}
                width={70}
                // timeout={3000} //3 secs
              />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div className='content'>
              <Row>
                <Col md='8'>
                  <button type='button' data-toggle='modal' data-target='#newmeet' class='btn btn-danger btn-round'>
                    Add Pentathlon
                  </button>
                  <Card>
                    <CardHeader>
                      <h5 className='title'> Pentathlon / Performance Points</h5>
                    </CardHeader>

                    <CardBody>
                      <Form>
                        <div class='form-group row'>
                          <div class='col-xs-5'>
                            <CardHeader>
                              <p>Search</p>
                              <input
                                class='form-control'
                                id='inputsm'
                                onChange={(e) => this.onSearch(e)}
                                placeholder='Search..'
                                type='text'
                              />
                            </CardHeader>
                          </div>
                        </div>

                        {/* table --------------------------------------------------- */}
                        <Table id='my' responsive>
                          <thead>
                            <tr>
                              <th className='text-center'>#</th>
                              <th>Event</th>
                              <th>Gender</th>
                              <th>Class</th>
                              <th>A</th>
                              <th>B</th>
                              <th>C</th>
                              <th>Mevntgenclass</th>
                              <th>Typerecord</th>
                              {/* <th>eventno</th> */}

                              <th class='disabled-sorting text-right'>Actions</th>
                            </tr>
                          </thead>
                          <tbody id='myTable'>
                            {this.state.pendatas.map((tdata, index) => (
                              <tr>
                                <td>{tdata.id}</td>
                                <td>{tdata.event}</td>
                                <td>{tdata.gender}</td>
                                <td>{tdata.class}</td>
                                <td>{tdata.A}</td>
                                <td>{tdata.B}</td>
                                <td>{tdata.C}</td>
                                <td>{tdata.evntgenclass}</td>
                                <td>{tdata.typerecord}</td>
                                {/* <td>{question.eventno}</td> */}

                                <td class='td-actions text-right'>
                                  <button
                                    type='button'
                                    rel='tooltip'
                                    class='btn btn-success btn-sm btn-icon'
                                    onClick={() => this.editrecord(index)}
                                    data-toggle='modal'
                                    data-target='#meetedit'
                                  >
                                    <i class='now-ui-icons ui-2_settings-90'></i>
                                  </button>
                                  {` `}
                                  <button
                                    type='button'
                                    rel='tooltip'
                                    class='btn btn-danger btn-sm btn-icon'
                                    onClick={() => this.deleterecord(index)}
                                  >
                                    <i class='now-ui-icons ui-1_simple-remove'></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.itemsCountPerPage}
                          totalItemsCount={this.state.totalItemsCount}
                          pageRangeDisplayed={this.state.pageRangeDisplayed}
                          onChange={(pageNumber) => this.handlePageChange(pageNumber)}
                          itemClass='page-item'
                          linkClass='page-link'
                        />
                        {/* table --------------------------------------------------- */}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* edit meet modal ----------------------------------------------------------------------------------------------------------------- */}
              <div class='modal fade' id='meetedit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Edit Pentathlon points{' '}
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='inputAddress'>Event</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            placeholder='1234 Main St'
                            name='eventrecord'
                            value={this.state.evntname}
                            onChange={this.optionchange2}
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Gender</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            placeholder='1234 Main St'
                            name='genderrecord'
                            value={this.state.gendername}
                            onChange={this.optionchange}
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Class</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            placeholder='1234 Main St'
                            name='classrecord'
                            value={this.state.clasname}
                            onChange={this.optionchange1}
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>A</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            placeholder='1234 Main St'
                            name='a'
                            value={this.state.aname}
                            onChange={this.optionchange3}
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>B</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            placeholder='1234 Main St'
                            name='b'
                            value={this.state.bname}
                            onChange={this.optionchange4}
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>C</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            placeholder='1234 Main St'
                            name='c'
                            value={this.state.cname}
                            onChange={this.optionchange5}
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>typerecord</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            placeholder='1234 Main St'
                            name='trecord'
                            value={this.state.typename}
                            onChange={this.optionchange6}
                            required
                          />
                        </div>

                        {/* <div class="form-group">
                    <label for="inputAddress">eventno</label>
                    <input type="text" class="form-control" id="inputAddress"  placeholder="1234 Main St" name="norecord" value={this.state.noname} onChange={this.optionchange7}  required/>
                    </div> */}

                        <center>
                          <button type='submit' onClick={this.updaterecord} data-dismiss='modal' class='btn btn-danger'>
                            Update
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              {/* add meet------------------------------------------------------------------------------------------------- */}
              <div class='modal fade' id='newmeet' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        New Pentathlon Points
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='inputAddress'>Event</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            // placeholder="1234 Main St"
                            name='eventrecord'
                            onChange={this.optionchange}
                            placeholder='Enter event'
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Gender</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            // placeholder="1234 Main St"
                            name='genderrecord'
                            onChange={this.optionchange}
                            placeholder='Enter gender'
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Class</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            // placeholder="1234 Main St"
                            name='classrecord'
                            onChange={this.optionchange}
                            placeholder='Enter class'
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>A</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            // placeholder="1234 Main St"
                            name='avalue'
                            onChange={this.optionchange}
                            placeholder='Enter values'
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>B</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            // placeholder="1234 Main St"
                            name='bvalue'
                            onChange={this.optionchange}
                            placeholder='Enter values'
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>C</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            // placeholder="1234 Main St"
                            name='cvalue'
                            onChange={this.optionchange}
                            placeholder='Enter values'
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Typerecord</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            // placeholder="1234 Main St"
                            name='typerecord'
                            onChange={this.optionchange}
                            placeholder='Enter Typerecord'
                            required
                          />
                        </div>

                        {/* <div class="form-group">
<label for="inputAddress">eventno</label>
<input type="text" class="form-control" id="inputAddress"  placeholder="1234 Main St" name="numvalue"  onChange={this.optionchange} placeholder="Enter event number" required/>
</div> */}

                        <center>
                          <button type='submit' onClick={this.addrecord} data-dismiss='modal' class='btn btn-danger'>
                            Create
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Pentathlon;
