import React from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';

import Swal from 'sweetalert2';
//import Agereload from './Agedivisionload.js'

import { Select, Input } from 'antd';
import 'antd/dist/antd.css';
import { Empty } from 'antd';
import { withRouter } from 'react-router-dom';
const url = window.globalConfig;
var x;

class Regitrack extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      venues: [{}],
      venueid: [],
      tname: [],
      trackeventdata: [],
      tracksdata: {},
      trackevents: [],
      trackdatas: [],
      trackdatas1: {},
      qualifying_standard: '',
      qualifying_time: '',
      gender1: '',
      points: '',
      relay_points: '',
      relay_letter: '',
      relay_team_name: '',
      selected_events: '',
      editview: '',
      btncheck: true,
      selectedevents: '',
      trackeventsdata: '',
      // trackeventdata: []
    };
    this.check = this.check.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.edit = this.edit.bind(this);
    this.tinput = this.tinput.bind(this);
    // this.save = this.save.bind(this);
    this.createtrackreg = this.createtrackreg.bind(this);
    this.updatetrackreg = this.updatetrackreg.bind(this);
  }
  async componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    var self = this;

    let indx = sessionStorage.getItem('trackindex');
    let ids = sessionStorage.getItem('ids');
    const trackeventdata = (index) => {
      if (index.track_events != 'null') {
        var joined = this.state.trackeventdata.concat(index);
        this.setState({
          trackeventdata: joined,
        });
        //   console.log(this.state.swimeventdata)
      }
    };
    //Get Particular user register meet data
    await axios.get(`${url.url}/api/getcreateregisters/${ids}`, {}).then(function (response) {
      self.setState({
        allregdatas: response.data.data,
      });
      // console.log(response.data)

      {
        response.data.data.map((index) => trackeventdata(index));
      }
      // console.log(response.data[parseInt(indx)])
      var valdata = self.state.trackeventdata[parseInt(indx)].track_events.split(',');

      self.setState({
        tracksdata: self.state.trackeventdata[parseInt(indx)],
        trackevents: valdata,
      });
      // console.log(response.data[parseInt(indx)])
    });
    let regid = sessionStorage.getItem('regid');

    axios
      .get(`${url.url}/api/getregisterid/${regid}`, {})
      .then(function (response) {
        self.setState({
          trackdatas: response.data,
          trackdatas1: response.data[0],
        });
      })
      .catch(function (error) {});

    //Hide and show manager
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('resultp').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    document.getElementById('venueesss').style.display = 'block';
  }
  check = (e) => {
    var temp = [];
    if (Object.keys(this.state.tname).length != 0) {
      temp = this.state.tname;
    }
    if (!this.state.tname.some((item) => e.target.value === item.name)) {
      temp.push({ name: e.target.value, id: e.target.id });
    }
    this.setState({ tname: temp });
  };
  handlechange() {
    window.location.replace('/trackregister');
  }

  tinput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  edit(e) {
    var self = this;
    // self.setState({ editview: e })
    // console.log(e)
    // console.log(self.state.trackevents[e])
    // console.log(self.state.trackdatas.length);
    self.setState({
      trackeventsdata: self.state.trackevents[e],
    });
    var loop = true;

    self.state.trackdatas.forEach((question, index) => {
      // console.log(index+ " " +self.state.trackdatas.length);
      // console.log(self.state.trackdatas);
      if (question.selected_events == self.state.trackevents[e]) {
        self.setState({
          selected_id: question.id,
          selected_events: question.selected_events,
          qualifying_time: question.qualifying_time,
          qualifying_standard: question.qualifying_standard,
          points: question.points,
          gender1: question.gender1,
          relay_team_name: question.relay_team_name,
          relay_letter: question.relay_letter,
          relay_points: question.relay_points,
          btncheck: false,
        });
        loop = false;
        // console.log("Test");
      } else if (loop) {
        self.setState({
          selected_id: '',
          selectedevents: self.state.trackevents[e],
          qualifying_time: '',
          qualifying_standard: '',
          points: '',
          gender1: '',
          relay_team_name: '',
          relay_letter: '',
          relay_points: '',
          btncheck: true,
        });
        loop = true;
        // console.log("Test444");
      }
    });
    console.log(this.state.selected_id);

    //     var loop = true;
    //     if(question.selected_events==self.state.trackevents[e]){

    //         console.log("hhh")
    //         loop = false;
    //     }
    //     else{
    //         self.setState({
    //             selected_id:"",
    //             selectedevents:self.state.trackevents[e],
    //             qualifying_time:"",
    //             qualifying_standard:"",
    //             points: "",
    //             gender1: "",
    //             relay_team_name: "",
    //             relay_letter: "",
    //             relay_points: "",
    //             btncheck: true

    //         })
    //         loop = true;
    //         console.log("yyy")
    //     }

    //     if(!loop){
    //         break
    //     }
    // })
    if (self.state.trackdatas.length == 0) {
      self.setState({
        selected_id: '',
        selectedevents: self.state.trackevents[e],
        qualifying_time: '',
        qualifying_standard: '',
        points: '',
        gender1: '',
        relay_team_name: '',
        relay_letter: '',
        relay_points: '',
        btncheck: true,
      });
    }
  }

  createtrackreg(e) {
    var self = this;
    const setvalue = (q, index) => {
      if (self.state.trackdatas.length > index) {
        self.setState({
          selected_events: self.state.trackdatas[index].selected_events,
          qualifying_time: self.state.trackdatas[index].qualifying_time,
          qualifying_standard: self.state.trackdatas[index].qualifying_standard,
          points: self.state.trackdatas[index].points,
          gender1: self.state.trackdatas[index].gender1,
          relay_team_name: self.state.trackdatas[index].relay_team_name,
          relay_letter: self.state.trackdatas[index].relay_letter,
          relay_points: self.state.trackdatas[index].relay_points,
        });
      }
    };
    self.state.trackevents.map((question, index) => {
      setvalue(question, index);
    });

    const datatosend = {
      first_name: this.state.tracksdata.firstname,
      last_name: this.state.tracksdata.lastname,
      age_division: this.state.tracksdata.track_age,
      gender: this.state.tracksdata.gender,
      selected_events: this.state.selectedevents,
      qualifying_time: this.state.qualifying_time,
      qualifying_standard: this.state.qualifying_standard,
      points: this.state.points,
      gender1: this.state.gender1,
      relay_team_name: this.state.relay_team_name,
      relay_letter: this.state.relay_letter,
      relay_points: this.state.relay_points,
      register_id: parseInt(sessionStorage.getItem('regid')),
    };
    axios
      .post(`${url.url}/api/createtrackreg/`, datatosend, {})
      .then(function (response) {
        if (response.status == 201) {
          Swal.fire({
            title: 'Success',
            text: 'Status updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  //Update track reg
  updatetrackreg(e) {
    var self = this;

    const datatosend = {
      first_name: this.state.tracksdata.firstname,
      last_name: this.state.tracksdata.lastname,
      age_division: this.state.tracksdata.track_age,
      gender: this.state.tracksdata.gender,
      selected_events: this.state.selected_events,
      qualifying_time: this.state.qualifying_time,
      qualifying_standard: this.state.qualifying_standard,
      points: this.state.points,
      gender1: this.state.gender1,
      relay_team_name: this.state.relay_team_name,
      relay_letter: this.state.relay_letter,
      relay_points: this.state.relay_points,
    };
    axios
      .put(`${url.url}/api/updatetrackreg/${this.state.selected_id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            title: 'Success',
            text: 'Status updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  render() {
    const { selectedItems } = this.state;

    return (
      <>
        <div className='content'>
          <Row>
            <Col md='8'>
              <button
                type='button'
                data-toggle='modal'
                data-target='#selecttrack'
                class='btn btn-danger btn-round'
                onClick={this.handlechange}
              >
                Back
              </button>
              <Card>
                <CardHeader>
                  <h5 className='title'>Track Events</h5>
                </CardHeader>

                <CardBody>
                  <Form>
                    <div class='form-group row'>
                      <div class='col-xs-5'>
                        <CardHeader id='deatils_heading'>
                          <p>
                            First Name:<span>{this.state.tracksdata.firstname}</span>
                          </p>
                          <p>
                            Last Name:<span>{this.state.tracksdata.lastname}</span>
                          </p>
                          <p>
                            Track Class:<span>{this.state.tracksdata.track_class_value}</span>
                          </p>
                          <p>
                            Age Division:<span>{this.state.tracksdata.track_age}</span>
                          </p>
                          <p>
                            Gender:<span>{this.state.tracksdata.gender}</span>
                          </p>
                        </CardHeader>
                      </div>
                    </div>
                    {Object.keys(this.state.trackevents).length == 0 ? (
                      <Empty />
                    ) : (
                      <>
                        <Table id='my' responsive>
                          <thead>
                            <tr>
                              <th>Athletes Events</th>
                              <th>Qualifying Time</th>
                              <th>Qualifying Standard</th>
                              {/* <th>Venue Class</th> */}
                              <th>Points</th>
                              <th>Event Gender</th>
                              <th>Relay Team Name</th>
                              <th>Relay Letter</th>
                              <th>Relay Points</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody id='myTable'>
                            {this.state.trackevents.map((question, index) => (
                              <tr>
                                <td>{question}</td>
                                {/* <td><input class="input detail_txt"  disabled={this.state.editview} value = {question == this.state.selected_events?this.state.qualifying_time:""} onChange={e => this.setState({ qualifying_time: e.target.value })} name="quatime"/></td>
                                                                <td><input class="input detail_txt"  disabled={this.state.editview} value = {question == this.state.selected_events?this.state.qualifying_standard:""} onChange={e => this.setState({ qualifying_standard: e.target.value })}  name="quastd"/></td>
                                                                <td><input class="input detail_txt"  disabled={this.state.editview} value = {question == this.state.selected_events?this.state.points:""} onChange={e => this.setState({ points: e.target.value })}  name="points"/></td>
                                                                <td><input class="input detail_txt"  disabled={this.state.editview} value = {question == this.state.selected_events?this.state.gender1:""} onChange={e => this.setState({ gender1: e.target.value })}  name="entgender"/></td>
                                                                <td><input class="input detail_txt"  disabled={this.state.editview}  value = {question == this.state.selected_events?this.state.relay_team_name:""} onChange={e => this.setState({ relay_team_name: e.target.value })}  name="relayteamname"/></td>
                                                                <td><input class="input detail_txt"  disabled={this.state.editview} value = {question == this.state.selected_events?this.state.relay_letter:""} onChange={e => this.setState({ relay_letter: e.target.value })}  name="relayletter"/></td>
                                                                <td><input class="input detail_txt"  disabled={this.state.editview} value = {question == this.state.selected_events?this.state.relay_points:""} onChange={e => this.setState({ relay_points: e.target.value })}  name="relaypoint"/></td> */}
                                <td>
                                  {this.state.trackdatas.map((question1, index) =>
                                    // console.log(question1.qualifying_time),
                                    question === question1.selected_events ? question1.qualifying_time : ''
                                  )}
                                </td>
                                <td>
                                  {this.state.trackdatas.map((question1, index) =>
                                    // console.log(question1.qualifying_time),
                                    question === question1.selected_events ? question1.qualifying_standard : ''
                                  )}
                                </td>

                                <td>
                                  {this.state.trackdatas.map((question1, index) =>
                                    // console.log(question1.points),
                                    question === question1.selected_events ? question1.points : ''
                                  )}
                                </td>

                                <td>
                                  {this.state.trackdatas.map((question1, index) =>
                                    // console.log(question1.gender1),
                                    question === question1.selected_events ? question1.gender1 : ''
                                  )}
                                </td>

                                <td>
                                  {this.state.trackdatas.map((question1, index) =>
                                    // console.log(question1.gender1),
                                    question === question1.selected_events ? question1.relay_team_name : ''
                                  )}
                                </td>

                                <td>
                                  {this.state.trackdatas.map((question1, index) =>
                                    // console.log(question1.gender1),
                                    question === question1.selected_events ? question1.relay_letter : ''
                                  )}
                                </td>

                                <td>
                                  {this.state.trackdatas.map((question1, index) =>
                                    // console.log(question1.gender1),
                                    question === question1.selected_events ? question1.relay_points : ''
                                  )}
                                </td>

                                <td>
                                  <button
                                    type='button'
                                    rel='tooltip'
                                    class='btn btn-success btn-sm btn-icon'
                                    onClick={() => this.edit(index)}
                                    data-toggle='modal'
                                    data-target='#useredit'
                                  >
                                    <i class='now-ui-icons ui-2_settings-90'></i>
                                  </button>
                                  {/* <button type="button" rel="tooltip" class="btn btn-danger btn-sm btn-icon"   >
                                                                        <i class="now-ui-icons ui-2_settings-90"></i>
                                                                    </button> */}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
            {/* User table with edit delete------------------------------------------------------------------------------ */}
          </Row>

          {/* track event reg model */}

          <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
            <div class='modal-dialog'>
              <div class='modal-content'>
                <div class='modal-header '>
                  <h5 class='modal-title ' id='loginmodalLabel'>
                    {this.state.trackeventsdata + ' ' + 'Track'}{' '}
                  </h5>
                  <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <div class='modal-body'>
                  <form>
                    <div class='form-group'>
                      <label for='exampleFormControlSelect1'>Qualifying Time</label>
                      <input
                        class='form-control'
                        value={this.state.qualifying_time}
                        onChange={(e) => this.setState({ qualifying_time: e.target.value })}
                        name='quatime'
                      />
                    </div>

                    <div class='form-row'>
                      <div class='form-group'>
                        <label for='inputEmail4'>Qualifying Standard</label>
                        <input
                          class='form-control'
                          value={this.state.qualifying_standard}
                          onChange={(e) => this.setState({ qualifying_standard: e.target.value })}
                          name='quastd'
                        />
                      </div>

                      <div class='form-group'>
                        <label for='inputPassword4'>Points</label>
                        <input
                          class='form-control'
                          value={this.state.points}
                          onChange={(e) => this.setState({ points: e.target.value })}
                          name='points'
                        />
                      </div>
                    </div>

                    <div class='form-row'>
                      <div class='form-group'>
                        <label for='inputCity'>Event Gender</label>
                        <input
                          class='form-control'
                          value={this.state.gender1}
                          onChange={(e) => this.setState({ gender1: e.target.value })}
                          name='entgender'
                        />
                      </div>
                    </div>

                    <div class='form-row'>
                      <div class='form-group'>
                        <label for='inputCity'>Relay Team Name</label>
                        <input
                          class='form-control'
                          value={this.state.relay_team_name}
                          onChange={(e) => this.setState({ relay_team_name: e.target.value })}
                          name='relayteamname'
                        />
                      </div>
                    </div>

                    <div class='form-row'>
                      <div class='form-group'>
                        <label for='inputCity'>Relay Letter</label>
                        <input
                          class='form-control'
                          value={this.state.relay_letter}
                          onChange={(e) => this.setState({ relay_letter: e.target.value })}
                          name='relayletter'
                        />
                      </div>
                    </div>

                    <div class='form-row'>
                      <div class='form-group'>
                        <label for='inputCity'>Relay Points</label>
                        <input
                          class='form-control'
                          value={this.state.relay_points}
                          onChange={(e) => this.setState({ relay_points: e.target.value })}
                          name='relaypoint'
                        />
                      </div>
                    </div>

                    {this.state.btncheck == true ? (
                      <center>
                        <button
                          type='button'
                          onClick={(e) => this.createtrackreg()}
                          data-dismiss='modal'
                          class='btn btn-danger'
                        >
                          Create
                        </button>
                      </center>
                    ) : (
                      <>
                        <center>
                          <button type='button' class='btn btn-danger' onClick={(e) => this.updatetrackreg()}>
                            Update
                          </button>
                        </center>
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Regitrack;
