import React from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';

import Swal from 'sweetalert2';

import { Select, Input } from 'antd';
import 'antd/dist/antd.css';
import { Empty } from 'antd';
import { withRouter } from 'react-router-dom';

var x;
const url = window.globalConfig;
class Assignbench extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      venues: [{}],
      venueid: [],
      tname: [],
      weightlifteventdata: [],
      Qualifying_marks: '',
      qualifying_standard: '',
      pits: '',
      btncheck: true,
      selectedevents: '',
      allregdatas: [{}],
      classdata: [{}],
      getfieldbibdata: [{}],
      targetdata: '',
      groupdata: '',
      typebowdata: '',
      bowstandata: '',
      benchdata: '',
    };
    this.edit = this.edit.bind(this);
    this.update = this.update.bind(this);
    this.handlechange = this.handlechange.bind(this);
  }
  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });
    var self = this;
    const data = {
      meet_id: sessionStorage.getItem('ids'),
    };
    axios
      .post(`${url.url}/api/getassignbenches/`, data, {})
      .then(function (response) {
        self.setState({
          weightlifteventdata: response.data,
        });
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });

    //Hide and show manager
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
  }

  handlechange() {
    window.location.replace('/venuemainpage?event=' + sessionStorage.getItem('event'));
  }

  edit(e) {
    var self = this;
    self.setState({
      eventdatas: e,
      benchdata: e[0].bench,
      groupdata: e[0].group,
    });
  }

  update(e) {
    var self = this;
    const datatosend = {
      bench: this.state.benchdata,
      group: this.state.groupdata,
    };

    this.state.eventdatas.map((question, index) => {
      axios
        .put(`${url.url}/api/updateassignbenches/${question.id}`, datatosend, {})
        .then(function (response) {
          if (response.status == 200) {
            Swal.fire({
              icon: 'success',
              title: 'Status Updated successfully',
              showConfirmButton: false,
              timer: 1000,
            }).then(function (response) {
              window.location.reload();
            });
          }
        })

        .catch(function (error) {
          Swal.fire({
            title: 'Failed',
            text: 'Something went wrong',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          });
        });
    });
  }

  render() {
    const { selectedItems } = this.state;
    var self = this;
    return (
      <>
        <div className='content'>
          <Row>
            <Col md='8'>
              <button
                type='button'
                data-toggle='modal'
                data-target='#selectfield'
                class='btn btn-danger btn-round'
                onClick={this.handlechange}
              >
                Back
              </button>
              <Card>
                <CardHeader>
                  <h5 className='title'>Assign Bench</h5>
                </CardHeader>

                <CardBody>
                  <Form>
                    {Object.keys(this.state.weightlifteventdata).map(function (key) {
                      return (
                        <div class='realy_main arcassign'>
                          <div>
                            <p>
                              <label class='relay_label'>Athlete's Name</label>
                              {self.state.weightlifteventdata[key][0].first_name}{' '}
                              {self.state.weightlifteventdata[key][0].last_name}
                            </p>
                            <p>
                              <span>
                                <label class='relay_label'>Bib</label>
                                {self.state.weightlifteventdata[key][0].bib_no}
                              </span>

                              <span class='relay_label1'>
                                <label class='relay_label'>Group</label>
                                {self.state.weightlifteventdata[key][0].group}
                              </span>
                            </p>

                            <p>
                              <span>
                                {' '}
                                <label class='relay_label'>Class</label>
                                {self.state.weightlifteventdata[key][0].weight_class_value}
                              </span>
                              <span class='relay_label1'>
                                <label class='relay_label'>Age</label>
                                {self.state.weightlifteventdata[key][0].age_division}
                              </span>
                            </p>

                            <p>
                              <span>
                                {' '}
                                <label class='relay_label'>Gender</label>
                                {self.state.weightlifteventdata[key][0].gender}
                              </span>
                              <span class='relay_label1'>
                                <label class='relay_label'>Register Number</label>
                                {'R' + self.state.weightlifteventdata[key][0].register_id}
                              </span>
                            </p>
                          </div>

                          <div>
                            <label class='relay_label'>Events</label>
                            {self.state.weightlifteventdata[key].map((keys, i) => (
                              <p>{keys.selected_events}</p>
                            ))}
                          </div>
                          <div>
                            <label class='relay_label'>Bench</label>
                            <p>
                              {self.state.weightlifteventdata[key].map((keys, i) => (
                                <p>{keys.bench}</p>
                              ))}
                            </p>
                          </div>
                          <div>
                            <label class='relay_label'>Actions</label>
                            <p>
                              <button
                                type='button'
                                rel='tooltip'
                                class='btn btn-success btn-sm btn-icon'
                                onClick={(e) => self.edit(self.state.weightlifteventdata[key])}
                                data-toggle='modal'
                                data-target='#useredit'
                              >
                                <i class='now-ui-icons ui-2_settings-90'></i>
                              </button>
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </Form>
                </CardBody>
              </Card>
            </Col>
            {/* User table with edit delete------------------------------------------------------------------------------ */}
          </Row>

          <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
            <div class='modal-dialog'>
              <div class='modal-content'>
                <div class='modal-header '>
                  <h5 class='modal-title ' id='loginmodalLabel'>
                    Assign Bench
                  </h5>
                  <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <div class='modal-body'>
                  <form>
                    <div class='form-row'>
                      <div class='form-group '>
                        <label for='inputCity'>Bench </label>
                        <input
                          class='form-control'
                          value={this.state.benchdata}
                          onChange={(e) => this.setState({ benchdata: e.target.value })}
                          name='relaypoint'
                        />
                      </div>
                    </div>

                    <div class='form-row'>
                      <div class='form-group '>
                        <label for='inputCity'>Group</label>
                        <input
                          class='form-control'
                          value={this.state.groupdata}
                          onChange={(e) => this.setState({ groupdata: e.target.value })}
                          name='relaypoint'
                        />
                      </div>
                    </div>

                    <center>
                      <button type='button' class='btn btn-danger' onClick={(e) => this.update()}>
                        Update
                      </button>
                    </center>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Assignbench;
