import React from 'react';

import '../App.css';
import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import { Empty } from 'antd';
import 'datatables.net';
import 'datatables.net-bs4';
import Loader from 'react-loader-spinner';
var x;
var y;
var arr = [];
const url = window.globalConfig;
function removeLeadingZeroes(timeString) {
  const data = timeString && timeString.replace(/^0+:/, '');
  const data1 = data && data.replace(/^0+:/, '');
  return data && data1.replace(/^0+/, '').replace(/^\./, '');
}
class Trackworksheet extends React.Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    this.state = {
      event: queryParams.get('event'),
      event_data: [{}],
      relay: false,
      notrelay: false,
      getonetrackevent: [],
      getalltrackevent: [{}],
      allRelay: false,
      workdata: '',
      finaldata: '',
      heatdata: '',
      lanedata: '',
      classdata: '',
      comclassdata: '',
      countrydata: '',
      rundata: 'Yes',
      bestdata: '',
      randdata: '',
      lanseqdata: '',
      eventdatas: [{}],
      eventdatassort: [{}],
      qualifying_standard: '',
      qualifying_time: '',
      // relay_points: "",
      points: '',
      trackdatas: [{}],
      qualtimedata: '',
      loader: true,
      sortheatlanedatas: [{}],
      sortrelayheatlanedatas: [],
      sortgendernamedatas: [{}],
      sortrelaygendernamedatas: [],
      sortbyclassgendername: [{}],
      sortbyclassgendernameresult: [{}],
      relaysortbyrelayclassgendername: [{}],
      relaysortbyrelayclassgendernameresult: [{}],
      issortheatlane: false,
      isrelaysortheatlane: false,
      issortbyrelayclassgendername: false,
      issortbyrelayclassgendernameresult: false,
      isrelaysortbyrelayclassgendername: false,
      isrelaysortbyrelayclassgendernameresult: false,
      viewallindex: '',
      relayteamname: '',
      relayletter: '',
    };
    this.back = this.back.bind(this);
    this.edit = this.edit.bind(this);
    this.update = this.update.bind(this);
    this.sortbyheatlane = this.sortbyheatlane.bind(this);
    this.isnotrelaysortbyheatlane = this.isnotrelaysortbyheatlane.bind(this);
  }
  async componentDidMount() {
    var self = this;
    const data = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    await axios
      .post(`${url.url}/api/getregistereventqualstd/`, data, {})
      .then(function (response) {
        self.setState({
          event_data: response.data,
          loader: false,
        });
      })
      .catch(function (error) {
        self.setState({ loader: false });
      });

    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    // document.getElementById("venueesss").style.display = "block";
    const datas = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    await axios
      .post(`${url.url}/api/getonerelaytrackevent/`, datas, {})
      .then(function (response) {
        self.setState({
          getonetrackevent: response.data,
          loader: false,
        });
      })
      .catch(function (error) {
        self.setState({ loader: false });
      });
  }
  back() {
    window.location.replace('/venuemainpage?event=' + sessionStorage.getItem('event'));
  }
  backtotrack = () => {
    this.setState({
      allRelay: false,
      loader: false,
      notrelay: false,
      issortheatlane: false,
      isrelaysortbyclassgendername: false,
      isrelaysortbyclassgendernameresult: false,
      issortbyclassgendernameresult: false,
      issortbyclassgendername: false,
      isrelaysortheatlane: false,
      relay: true,
    });
  };
  edit(e) {
    var self = this;
    self.setState({
      eventdatas: this.state.event_data[e],
      workdata: this.state.event_data[e].workfield,
      finaldata: this.state.event_data[e].final_group || '',
      heatdata: this.state.event_data[e].heat || '',
      lanedata: this.state.event_data[e].lane || '',
      rundata: 'Yes',
      qualifying_time: this.state.event_data[e].qualifying_time,
      relayteamname: this.state.event_data[e].relay_team_name,
      relayletter: this.state.event_data[e].relay_letter,
    });
  }
  aftersort1edit = (e) => {
    var self = this;
    self.setState({
      eventdatas: this.state.sortrelayheatlanedatas[e][0],
      workdata: this.state.sortrelayheatlanedatas[e][0].workfield,
      finaldata: this.state.sortrelayheatlanedatas[e][0].final_group,
      heatdata:
        this.state.sortrelayheatlanedatas[e][0].heat == null ? '' : this.state.sortrelayheatlanedatas[e][0].heat,
      lanedata:
        this.state.sortrelayheatlanedatas[e][0].lane == null ? '' : this.state.sortrelayheatlanedatas[e][0].lane,
      rundata: 'Yes',
      qualifying_time: this.state.sortrelayheatlanedatas[e][0].qualifying_time,
      relayteamname: this.state.sortrelayheatlanedatas[e][0].relay_team_name,
      relayletter: this.state.sortrelayheatlanedatas[e][0].relay_letter,
    });
  };
  aftersort2edit = (e) => {
    var self = this;
    self.setState({
      eventdatas: this.state.sortrelaygendernamedatas[e][0],
      workdata: this.state.sortrelaygendernamedatas[e][0].workfield,
      finaldata: this.state.sortrelaygendernamedatas[e][0].final_group,
      heatdata:
        this.state.sortrelaygendernamedatas[e][0].heat == null ? '' : this.state.sortrelaygendernamedatas[e][0].heat,
      lanedata:
        this.state.sortrelaygendernamedatas[e][0].lane == null ? '' : this.state.sortrelaygendernamedatas[e][0].lane,
      rundata: 'Yes',
      qualifying_time: this.state.sortrelaygendernamedatas[e][0].qualifying_time,
      relayteamname: this.state.sortrelaygendernamedatas[e][0].relay_team_name,
      relayletter: this.state.sortrelaygendernamedatas[e][0].relay_letter,
    });
  };
  relayedit = (e) => {
    var self = this;
    self.setState({
      eventdatas: this.state.getonetrackevent[e][0],
      workdata: this.state.getonetrackevent[e][0].workfield,
      finaldata: this.state.getonetrackevent[e][0].final_group,
      heatdata: this.state.getonetrackevent[e][0].heat == null ? '' : this.state.getonetrackevent[e][0].heat,
      lanedata: this.state.getonetrackevent[e][0].lane == null ? '' : this.state.getonetrackevent[e][0].lane,
      rundata: 'Yes',
      qualifying_time: this.state.getonetrackevent[e][0].qualifying_time,
      relayteamname: this.state.getonetrackevent[e][0].relay_team_name,
      relayletter: this.state.getonetrackevent[e][0].relay_letter,
    });
  };
  sortAfterEdit = (e) => {
    var found = this.state.sortheatlanedatas.find((question) => question.id == e);
    this.setState({
      eventdatassort: found,
      workdata: found.workfield,
      finaldata: found.final_group,
      heatdata: found.heat,
      lanedata: found.lane,
      rundata: 'Yes',
      qualifying_time: found.qualifying_time,
    });
  };
  sortAfterEditbtn = (e) => {
    var found = this.state.sortgendernamedatas.find((question) => question.id == e);
    this.setState({
      eventdatassort: found,
      workdata: found.workfield,
      finaldata: found.final_group,
      heatdata: found.heat,
      lanedata: found.lane,
      rundata: 'Yes',
      qualifying_time: found.qualifying_time,
    });
  };
  sortAfterEditbtn1 = (e) => {
    var found = this.state.sortbyclassgendername.find((question) => question.id == e);
    this.setState({
      eventdatassort: found,
      workdata: found.workfield,
      finaldata: found.final_group,
      heatdata: found.heat,
      lanedata: found.lane,
      rundata: 'Yes',
      qualifying_time: found.qualifying_time,
    });
  };
  sortAfterEditbtn2 = (e) => {
    var found = this.state.sortbyclassgendernameresult.find((question) => question.id == e);
    this.setState({
      eventdatassort: found,
      workdata: found.workfield,
      finaldata: found.final_group,
      heatdata: found.heat,
      lanedata: found.lane,
      rundata: 'Yes',
      qualifying_time: found.qualifying_time,
    });
  };
  relaysortAfterEditbtn1 = (e) => {
    var found = this.state.relaysortbyrelayclassgendername.find((question) => question.id == e);
    this.setState({
      eventdatassort: found,
      workdata: found.workfield,
      finaldata: found.final_group,
      heatdata: found.heat,
      lanedata: found.lane,
      rundata: 'Yes',
      qualifying_time: found.qualifying_time,
    });
  };
  relaysortAfterEditbtn2 = (e) => {
    var found = this.state.relaysortbyrelayclassgendernameresult.find((question) => question.id == e);
    this.setState({
      eventdatassort: found,
      workdata: found.workfield,
      finaldata: found.final_group,
      heatdata: found.heat,
      lanedata: found.lane,
      rundata: 'Yes',
      qualifying_time: found.qualifying_time,
    });
  };

  relayupdate = (e) => {
    const datatosend = {
      // final_group: this.state.finaldata,
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
      heat: this.state.heatdata,
      lane: this.state.lanedata,
      run_during_final: this.state.rundata,
      relay_team_name: this.state.relayteamname,
      relay_letter: this.state.relayletter,
    };
    axios
      .post(`${url.url}/api/updatealltrackprelimsetup`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  };

  update(e) {
    const datatosend = {
      final_group: this.state.finaldata,
      heat: this.state.heatdata,
      lane: this.state.lanedata,
      run_during_final: this.state.rundata,
    };
    axios
      .put(`${url.url}/api/updatetrackprelimsetup/${this.state.eventdatas.id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  sortAfterupdate = (e) => {
    const datatosend = {
      final_group: this.state.finaldata,
      heat: this.state.heatdata,
      lane: this.state.lanedata,
      run_during_final: this.state.rundata,
    };
    axios
      .put(`${url.url}/api/updatetrackprelimsetup/${this.state.eventdatassort.id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  };

  sortAfterupdatebtn = (e) => {
    const datatosend = {
      final_group: this.state.finaldata,
      heat: this.state.heatdata,
      lane: this.state.lanedata,
      run_during_final: this.state.rundata,
    };
    axios
      .put(`${url.url}/api/updatetrackprelimsetup/${this.state.eventdatassort.id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  };

  isnotrelaysortbyheatlane() {
    var self = this;
    const sortdata = {
      selected_events: self.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    axios.post(`${url.url}/api/sortbyheatlanevalues/`, sortdata, {}).then(function (response) {
      self.setState({
        sortheatlanedatas: response.data,
        loader: false,
        notrelay: false,
        issortheatlane: true,
        isrelaysortbyclassgendername: false,
        isrelaysortbyclassgendernameresult: false,
        issortbyclassgendernameresult: false,
        issortbyclassgendername: false,
        isrelaysortheatlane: false,
        allRelay: false,
        relay: false,
      });
    });
  }

  isnotrelaysortbygendername() {
    var self = this;
    const sortdata = {
      selected_events: self.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    axios.post(`${url.url}/api/getsortbygenderresultvalues/`, sortdata, {}).then(function (response) {
      self.setState({
        sortgendernamedatas: response.data,
        loader: false,
        notrelay: false,
        issortheatlane: false,
        isrelaysortbyclassgendername: false,
        isrelaysortbyclassgendernameresult: false,
        issortbyclassgendernameresult: false,
        issortbyclassgendername: false,
        allRelay: false,
        relay: false,
      });
    });
  }

  sortbyheatlane() {
    var self = this;
    const sortdata = {
      selected_events: self.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    axios.post(`${url.url}/api/getsortbyheatlanevalues/`, sortdata, {}).then(function (response) {
      self.setState({
        sortrelayheatlanedatas: response.data,
        loader: false,
        notrelay: false,
        issortheatlane: false,
        isrelaysortbyclassgendername: false,
        isrelaysortbyclassgendernameresult: false,
        issortbyclassgendernameresult: false,
        issortbyclassgendername: false,
        isrelaysortheatlane: true,
        allRelay: false,
        relay: false,
      });
    });
  }

  sortbygendername() {
    var self = this;
    const sortdata = {
      selected_events: self.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    axios.post(`${url.url}/api/getsortbygendernamevalues/`, sortdata, {}).then(function (response) {
      self.setState({
        sortrelaygendernamedatas: response.data,
        loader: false,
        notrelay: false,
        issortheatlane: false,
        isrelaysortbyclassgendername: false,
        isrelaysortbyclassgendernameresult: false,
        issortbyclassgendernameresult: false,
        issortbyclassgendername: false,
        allRelay: false,
        relay: false,
      });
    });
  }
  sortbyclassgendername = () => {
    var self = this;
    const sortdata = {
      selected_events: self.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    axios.post(`${url.url}/api/getsortbyclassagegenderresultvalues/`, sortdata, {}).then(function (response) {
      self.setState({
        sortbyclassgendername: response.data,
        loader: false,
        notrelay: false,
        issortheatlane: false,
        issortbyrelayclassgendername: true,
        issortbyrelayclassgendernameresult: false,
        isrelaysortbyrelayclassgendername: false,
        isrelaysortbyrelayclassgendernameresult: false,
        allRelay: false,
        relay: false,
      });
    });
  };
  sortbyclassgendernameresult = () => {
    var self = this;
    const sortdata = {
      selected_events: self.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    axios.post(`${url.url}/api/getsortbyclassagegenderresultvalues/`, sortdata, {}).then(function (response) {
      self.setState({
        sortbyclassgendernameresult: response.data,
        loader: false,
        notrelay: false,
        issortheatlane: false,
        issortbyrelayclassgendername: false,
        issortbyrelayclassgendernameresult: true,
        isrelaysortbyrelayclassgendername: false,
        isrelaysortbyrelayclassgendernameresult: false,
        allRelay: false,
        relay: false,
      });
    });
  };
  relaysortbyrelayclassgendername = () => {
    var self = this;
    const sortdata = {
      selected_events: self.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    axios.post(`${url.url}/api/getsortbyrelayclassagegendernamevalues/`, sortdata, {}).then(function (response) {
      self.setState({
        relaysortbyrelayclassgendername: response.data,
        loader: false,
        notrelay: false,
        issortheatlane: false,
        issortbyrelayclassgendername: false,
        issortbyrelayclassgendernameresult: false,
        isrelaysortbyrelayclassgendername: true,
        isrelaysortbyrelayclassgendernameresult: false,
        allRelay: false,
        relay: false,
      });
    });
  };
  relaysortbyrelayclassgendernameresult = () => {
    var self = this;
    const sortdata = {
      selected_events: self.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    axios.post(`${url.url}/api/getsortbyrelayclassagegendernamevalues/`, sortdata, {}).then(function (response) {
      self.setState({
        relaysortbyrelayclassgendernameresult: response.data,
        loader: false,
        notrelay: false,
        issortheatlane: false,
        issortbyrelayclassgendername: false,
        issortbyrelayclassgendernameresult: false,
        isrelaysortbyrelayclassgendername: false,
        isrelaysortbyrelayclassgendernameresult: true,
        allRelay: false,
        relay: false,
      });
    });
  };
  optionchange = (e) => {
    this.setState({
      rundata: e.target.value,
    });
  };

  relaypoints = (e, pts) => {
    var self = this;
    var relay_points = 0;

    Object.keys(this.state.getalltrackevent).map(function (key) {
      if (key == e) {
        self.state.getalltrackevent[e].map((question, index) => {
          relay_points = relay_points + parseInt(question.relay_points);
        });
      }
    });

    return (
      <p class='total_points'>
        {relay_points > pts ? relay_points + ' ' + 'Relay Points Total > Relay Points ' : relay_points}
      </p>
    );
  };

  viewAll = (e) => {
    var self = this;
    const datas = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
      relay_team_name: e.split('_')[0],
      relay_letter: e.split('_')[1],
    };
    axios
      .post(`${url.url}/api/getrelaytrackevent/`, datas, {})
      .then(function (response) {
        self.setState({
          getalltrackevent: response.data,
          allRelay: true,
          loader: false,
          notrelay: false,
          issortheatlane: false,
          issortbyrelayclassgendername: false,
          issortbyrelayclassgendernameresult: false,
          isrelaysortbyrelayclassgendername: false,
          isrelaysortbyrelayclassgendernameresult: false,
          isrelaysortheatlane: false,
          relay: false,
        });
      })
      .catch(function (error) {
        self.setState({ loader: false });
      });
  };
  onSearch = (e) => {
    var value = e.target.value.toLowerCase();
    $('#notrelay tr').filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
  };
  render() {
    var self = this;
    if (this.state.relay) {
      return (
        <>
          {/* {this.state.loader ? (
            <>
              <div className="svg-wrapper">
                <Loader
                  type="ThreeDots"
                  color="#00BFFF"
                  height={70}
                  width={70}
                // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : ( */}
          <>
            <div>
              <div className='venuesetup'>
                <h1> </h1>
                <div className='content'>
                  <Row>
                    <Col>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                        Back
                      </button>
                      <Card>
                        <CardHeader>
                          <h5 className='title'>
                            {this.state.event} <br></br> Preliminary Worksheet
                          </h5>
                        </CardHeader>
                        <CardBody>
                          <div class='form-group row printrow'>
                            <div class='col-xs-5'>
                              <CardHeader>
                                <center>
                                  <button type='button' class='btn btn-success printbtn' onClick={this.sortbyheatlane}>
                                    Sort by Heat/Lane
                                  </button>
                                </center>
                              </CardHeader>
                            </div>
                          </div>
                          <Form>
                            {/* {Object.keys(this.state.getonetrackevent).length != 0 ? (
                              <Empty />
                            ) : ( */}
                            <>
                              <table class='worksheet_table'>
                                <thead>
                                  <tr class='worksheet_tr'>
                                    <th>Heat</th>
                                    <th>Lane</th>
                                    <th>Country</th>
                                    <th>Relay Points</th>
                                    <th>Relay Gender</th>
                                    <th>Relay Team Name</th>
                                    <th>Run As Finals</th>
                                    <th>EventName</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <>
                                    {Object.keys(self.state.getonetrackevent).map(function (key) {
                                      return (
                                        <>
                                          {self.state.getonetrackevent[key].map((question, index) => {
                                            return (
                                              <>
                                                <tr class='worksheet_tr'>
                                                  <td>{question.heat}</td>
                                                  <td>{question.lane}</td>
                                                  <td>{question.country}</td>
                                                  <td>{question.points}</td>
                                                  <td>{question.gender1}</td>
                                                  <td>{question.relay_team_name + ' ' + question.relay_letter}</td>
                                                  <td>{question.run_during_final}</td>
                                                  <td>{question.selected_events}</td>
                                                  <td>
                                                    <button
                                                      type='button'
                                                      rel='tooltip'
                                                      class='btn btn-success btn-sm btn-icon'
                                                      onClick={(e) => self.relayedit(key)}
                                                      data-toggle='modal'
                                                      data-target='#useredit'
                                                    >
                                                      <i class='now-ui-icons ui-2_settings-90'></i>
                                                    </button>
                                                    <button
                                                      type='button'
                                                      class='btn btn-success printbtn'
                                                      onClick={(e) =>
                                                        self.viewAll(
                                                          question.relay_team_name + '_' + question.relay_letter
                                                        )
                                                      }
                                                    >
                                                      View Team
                                                    </button>
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          })}
                                        </>
                                      );
                                    })}
                                  </>
                                </tbody>
                              </table>
                            </>
                            {/* )} */}
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
              <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Preliminary Worksheet
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        {/* <div class="form-row ">
                              <div class="form-group">
                                <label for="inputCity">Final Group</label>
                                <input
                                  class="form-control"
                                  value={this.state.finaldata}
                                  onChange={(e) =>
                                    this.setState({ finaldata: e.target.value })
                                  }
                                  name="relaypoint"
                                />
                              </div>
                          </div> */}

                        <div class='form-row '>
                          <div class='form-group'>
                            <label for='inputCity'>Heat</label>
                            <input
                              class='form-control'
                              value={this.state.heatdata}
                              onChange={(e) =>
                                this.setState({
                                  heatdata: e.target.value,
                                })
                              }
                              name='relaypoint'
                            />
                          </div>
                        </div>

                        <div class='form-row '>
                          <div class='form-group'>
                            <label for='inputCity'>Lane</label>
                            <input
                              class='form-control'
                              value={this.state.lanedata}
                              onChange={(e) =>
                                this.setState({
                                  lanedata: e.target.value,
                                })
                              }
                              name='relaypoint'
                            />
                          </div>
                        </div>

                        <div class='form-row '>
                          <div class='form-group'>
                            <label for='inputCity'>Run As Finals</label>
                            <select
                              type='text'
                              class='form-control'
                              id='inputAddress'
                              name='currecord'
                              value={this.state.rundata}
                              onChange={(e) =>
                                this.setState({
                                  rundata: e.target.value,
                                })
                              }
                              required
                            >
                              <option value='Yes'>Yes</option>
                              <option value='No'>No</option>
                            </select>
                          </div>
                        </div>

                        <center>
                          <button type='button' class='btn btn-danger' onClick={(e) => this.relayupdate(e)}>
                            Update
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
          {/* )} */}
        </>
      );
    }
    if (this.state.isrelaysortheatlane) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='venuesetup'>
                  <h1> </h1>
                  <div className='content'>
                    <Row>
                      <Col>
                        <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                          Back
                        </button>
                        <Card>
                          <CardHeader>
                            <h5 className='title'>
                              {this.state.event} <br></br> Preliminary Worksheet
                            </h5>
                          </CardHeader>
                          <CardBody>
                            <div class='form-group row printrow'>
                              <div class='col-xs-5'>
                                <CardHeader>
                                  <center>
                                    <button
                                      type='button'
                                      class='btn btn-success printbtn'
                                      onClick={this.sortbyheatlane}
                                    >
                                      Sort by Heat/Lane
                                    </button>
                                  </center>
                                </CardHeader>
                              </div>
                            </div>
                            <Form>
                              <>
                                <table class='worksheet_table'>
                                  <thead>
                                    <tr class='worksheet_tr'>
                                      <th>Heat</th>
                                      <th>Lane</th>
                                      <th>Country</th>
                                      <th>Relay Points</th>
                                      <th>Relay Gender</th>
                                      <th>Relay Team Name</th>
                                      <th>Run As Finals</th>
                                      <th>EventName</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <>
                                      {Object.keys(self.state.sortrelayheatlanedatas).map(function (key) {
                                        return (
                                          <>
                                            {self.state.sortrelayheatlanedatas[key].map((question, index) => {
                                              return (
                                                <>
                                                  <tr class='worksheet_tr'>
                                                    <td>{question.heat}</td>
                                                    <td>{question.lane}</td>
                                                    <td>{question.country}</td>
                                                    <td>{question.points}</td>
                                                    <td>{question.gender1}</td>
                                                    <td>{question.relay_team_name + ' ' + question.relay_letter}</td>
                                                    <td>{question.run_during_final}</td>
                                                    <td>{question.selected_events}</td>
                                                    <td>
                                                      <button
                                                        type='button'
                                                        rel='tooltip'
                                                        class='btn btn-success btn-sm btn-icon'
                                                        onClick={(e) => self.aftersort1edit(key)}
                                                        data-toggle='modal'
                                                        data-target='#useredit'
                                                      >
                                                        <i class='now-ui-icons ui-2_settings-90'></i>
                                                      </button>
                                                      <button
                                                        type='button'
                                                        class='btn btn-success printbtn'
                                                        onClick={(e) =>
                                                          self.viewAll(
                                                            question.relay_team_name + '_' + question.relay_letter
                                                          )
                                                        }
                                                      >
                                                        View Team
                                                      </button>
                                                    </td>
                                                  </tr>
                                                </>
                                              );
                                            })}
                                          </>
                                        );
                                      })}
                                    </>
                                  </tbody>
                                </table>
                              </>
                              {/* )} */}
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Preliminary Worksheet
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          {/* <div class="form-row ">
                              <div class="form-group">
                                <label for="inputCity">Final Group</label>
                                <input
                                  class="form-control"
                                  value={this.state.finaldata}
                                  onChange={(e) =>
                                    this.setState({ finaldata: e.target.value })
                                  }
                                  name="relaypoint"
                                />
                              </div>
                          </div> */}

                          <div class='form-row '>
                            <div class='form-group'>
                              <label for='inputCity'>Heat</label>
                              <input
                                class='form-control'
                                value={this.state.heatdata}
                                onChange={(e) =>
                                  this.setState({
                                    heatdata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <div class='form-row '>
                            <div class='form-group'>
                              <label for='inputCity'>Lane</label>
                              <input
                                class='form-control'
                                value={this.state.lanedata}
                                onChange={(e) =>
                                  this.setState({
                                    lanedata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <div class='form-row '>
                            <div class='form-group'>
                              <label for='inputCity'>Run As Finals</label>
                              <select
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='currecord'
                                value={this.state.rundata}
                                onChange={(e) =>
                                  this.setState({
                                    rundata: e.target.value,
                                  })
                                }
                                required
                              >
                                <option value='Yes'>Yes</option>
                                <option value='No'>No</option>
                              </select>
                            </div>
                          </div>

                          <center>
                            <button type='button' class='btn btn-danger' onClick={(e) => this.relayupdate(e)}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (this.state.allRelay) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='venuesetup'>
                  <h1> </h1>
                  <div className='content'>
                    <Row>
                      <Col>
                        <button type='button' class='btn btn-danger btn-round' onClick={this.backtotrack}>
                          Back
                        </button>
                        <Card>
                          <CardHeader>
                            <h5 className='title'>
                              {this.state.event} <br></br> Relay Setup
                            </h5>
                          </CardHeader>
                          <CardBody>
                            <Form>
                              {Object.keys(this.state.getalltrackevent).map(function (key) {
                                x = key.split('_');
                                return (
                                  <CardBody>
                                    <div class='realy_main'>
                                      <div>
                                        <p>
                                          <label class='relay_label'>Points</label>
                                          {x[2]}
                                        </p>
                                        <p>
                                          <label class='relay_label'>Gender</label>
                                          {x[3]}
                                        </p>
                                        <p>
                                          <label class='relay_label'>Team Name</label>
                                          {x[0] + ' ' + x[1]}
                                        </p>
                                        <p>
                                          <label class='relay_label'>Run As Finals</label>
                                          {self.state.getalltrackevent[key][0].run_during_final}
                                        </p>
                                      </div>
                                      <div class='realy_main1'>
                                        <label class='relay_label'>Relay Team Members</label>

                                        {self.state.getalltrackevent[key][0] ? (
                                          <p>
                                            {self.state.getalltrackevent[key][0].first_name}{' '}
                                            {self.state.getalltrackevent[key][0].last_name}
                                          </p>
                                        ) : (
                                          <p style={{ color: 'red' }}>Missing Relay Team Members</p>
                                        )}
                                        {self.state.getalltrackevent[key][1] ? (
                                          <p>
                                            {self.state.getalltrackevent[key][1].first_name}{' '}
                                            {self.state.getalltrackevent[key][1].last_name}
                                          </p>
                                        ) : (
                                          <p style={{ color: 'red' }}>Missing Relay Team Members</p>
                                        )}
                                        {self.state.getalltrackevent[key][2] ? (
                                          <p>
                                            {self.state.getalltrackevent[key][2].first_name}{' '}
                                            {self.state.getalltrackevent[key][2].last_name}
                                          </p>
                                        ) : (
                                          <p style={{ color: 'red' }}>Missing Relay Team Members</p>
                                        )}
                                        {self.state.getalltrackevent[key][3] ? (
                                          <p>
                                            {self.state.getalltrackevent[key][3].first_name}{' '}
                                            {self.state.getalltrackevent[key][3].last_name}
                                          </p>
                                        ) : (
                                          <p style={{ color: 'red' }}>Missing Relay Team Members</p>
                                        )}
                                      </div>
                                      <div class='realy_main1'>
                                        <label class='relay_label'>Relay points</label>
                                        {self.state.getalltrackevent[key].map((question, index) => (
                                          <p>{question.relay_points}</p>
                                        ))}

                                        {self.relaypoints(key, x[2])}
                                      </div>
                                      <div class='realy_main1'>
                                        <label class='relay_label'>Class</label>
                                        {self.state.getalltrackevent[key].map((question, index) => (
                                          <p>{question.track_class_value}</p>
                                        ))}
                                      </div>
                                    </div>
                                  </CardBody>
                                );
                              })}
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }

    /////
    if (this.state.notrelay) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='venuesetup'>
                  <h1> </h1>
                  <div className='content'>
                    <Row>
                      <Col>
                        <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                          Back
                        </button>
                        <Card>
                          <CardHeader>
                            <h5 className='title'>
                              {this.state.event} <br></br> Preliminary Worksheet
                            </h5>
                          </CardHeader>
                          <CardBody>
                            <div class='form-group row printrow'>
                              <div class='col-xs-5'>
                                <CardHeader>
                                  <div>
                                    <p>Search</p>
                                    <input
                                      class='form-control'
                                      id='inputsm'
                                      onChange={(e) => this.onSearch(e)}
                                      placeholder='Search'
                                      type='text'
                                    />
                                  </div>
                                  <center>
                                    <button
                                      type='button'
                                      class='btn btn-success printbtn'
                                      onClick={this.isnotrelaysortbyheatlane}
                                    >
                                      Sort by Final Group/Heat/Lane
                                    </button>
                                  </center>
                                </CardHeader>
                              </div>
                            </div>
                            <Form>
                              {Object.keys(this.state.event_data).length == 0 ? (
                                <Empty />
                              ) : (
                                <>
                                  <table class='worksheet_table'>
                                    <thead>
                                      <tr class='worksheet_tr'>
                                        {/* <th>Work Field</th> */}
                                        <th>Final Group</th>
                                        <th>Heat</th>
                                        <th>Lane</th>
                                        <th>Class</th>
                                        <th>Combined class</th>
                                        <th>Age</th>
                                        <th>Gender</th>
                                        <th>Qualtime</th>
                                        <th>Bib</th>
                                        <th>Last Name</th>
                                        <th>First Name</th>
                                        <th>Country</th>
                                        <th>Qual Stand</th>
                                        <th>Run As Finals</th>
                                        <th>EventName</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody id='notrelay'>
                                      {this.state.event_data.map((question, index) => (
                                        <tr class='worksheet_tr'>
                                          <td>{question.final_group}</td>
                                          <td>{question.heat}</td>
                                          <td>{question.lane}</td>
                                          <td>{question.track_class_value}</td>
                                          <td>{question.track_class_value}</td>
                                          <td>{question.age_division}</td>
                                          <td>{question.gender}</td>
                                          <td>{question.qualifying_time}</td>
                                          <td>{question.bib_no}</td>
                                          <td>{question.last_name}</td>
                                          <td>{question.first_name}</td>
                                          <td>{question.country}</td>
                                          <td>{removeLeadingZeroes(question.qual_stand)}</td>
                                          <td>{question.run_during_final}</td>
                                          <td>{question.selected_events}</td>
                                          <td>
                                            <button
                                              type='button'
                                              rel='tooltip'
                                              class='btn btn-success btn-sm btn-icon'
                                              onClick={() => this.edit(index)}
                                              data-toggle='modal'
                                              data-target='#useredit'
                                            >
                                              <i class='now-ui-icons ui-2_settings-90'></i>
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </>
                              )}
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Preliminary Worksheet
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-row'>
                            <div class='form-group'>
                              <label for='inputCity'>Final Group</label>
                              <input
                                class='form-control'
                                value={this.state.finaldata}
                                onChange={(e) =>
                                  this.setState({
                                    finaldata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group'>
                              <label for='inputCity'>Heat</label>
                              <input
                                class='form-control'
                                value={this.state.heatdata}
                                onChange={(e) =>
                                  this.setState({
                                    heatdata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <div class='form-row '>
                            <div class='form-group'>
                              <label for='inputCity'>Lane</label>
                              <input
                                class='form-control'
                                value={this.state.lanedata}
                                onChange={(e) =>
                                  this.setState({
                                    lanedata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <div class='form-row '>
                            <div class='form-group'>
                              <label for='inputCity'>Run As Finals</label>
                              <select
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='currecord'
                                value={this.state.rundata}
                                onChange={(e) => this.optionchange(e)}
                                required
                              >
                                <option value='Yes'>Yes</option>
                                <option value='No'>No</option>
                              </select>
                            </div>
                          </div>

                          <center>
                            <button
                              type='button'
                              class='btn btn-danger'
                              onClick={(e) => this.update(this.state.relay_team_name)}
                            >
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }

    ////
    if (this.state.issortheatlane) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='venuesetup'>
                  <h1> </h1>
                  <div className='content'>
                    <Row>
                      <Col>
                        <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                          Back
                        </button>
                        <Card>
                          <CardHeader>
                            <h5 className='title'>
                              {this.state.event} <br></br> Preliminary Worksheet
                            </h5>
                          </CardHeader>
                          <CardBody>
                            <div class='form-group row printrow'>
                              <div class='col-xs-5'>
                                <CardHeader>
                                  <div>
                                    <p>Search</p>
                                    <input
                                      class='form-control'
                                      id='inputsm'
                                      onChange={(e) => this.onSearch(e)}
                                      placeholder='Search'
                                      type='text'
                                    />
                                  </div>
                                  <center>
                                    <button
                                      type='button'
                                      class='btn btn-success printbtn'
                                      onClick={this.isnotrelaysortbyheatlane}
                                    >
                                      Sort by Final Group/Heat/Lane
                                    </button>
                                  </center>
                                </CardHeader>
                              </div>
                            </div>
                            <Form>
                              {Object.keys(this.state.sortheatlanedatas).length == 0 ? (
                                <Empty />
                              ) : (
                                <>
                                  <table class='worksheet_table'>
                                    <thead>
                                      <tr class='worksheet_tr'>
                                        {/* <th>Work Field</th> */}
                                        <th>Final Group</th>
                                        <th>Heat</th>
                                        <th>Lane</th>
                                        <th>Class</th>
                                        <th>Combined class</th>
                                        <th>Age</th>
                                        <th>Gender</th>
                                        <th>Qualtime</th>
                                        <th>Bib</th>
                                        <th>Last Name</th>
                                        <th>First Name</th>
                                        <th>Country</th>
                                        <th>Qual Stand</th>
                                        <th>Run As Finals</th>
                                        <th>EventName</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody id='notrelay'>
                                      {this.state.sortheatlanedatas.map((question, index) => (
                                        <tr class='worksheet_tr'>
                                          {/* <td>{question.workfield}</td> */}
                                          <td>{question.final_group}</td>
                                          <td>{question.heat}</td>
                                          <td>{question.lane}</td>
                                          <td>{question.track_class_value}</td>
                                          <td>{question.track_class_value}</td>
                                          <td>{question.age_division}</td>
                                          <td>{question.gender}</td>
                                          <td>{question.qualifying_time}</td>
                                          <td>{question.bib_no}</td>
                                          <td>{question.last_name}</td>
                                          <td>{question.first_name}</td>
                                          <td>{question.country}</td>
                                          <td>{removeLeadingZeroes(question.qual_stand)}</td>
                                          <td>{question.run_during_final}</td>
                                          <td>{question.selected_events}</td>
                                          <td>
                                            <button
                                              type='button'
                                              rel='tooltip'
                                              class='btn btn-success btn-sm btn-icon'
                                              onClick={() => this.sortAfterEdit(question.id)}
                                              data-toggle='modal'
                                              data-target='#useredit'
                                            >
                                              <i class='now-ui-icons ui-2_settings-90'></i>
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </>
                              )}
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Preliminary Worksheet
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-row'>
                            <div class='form-group'>
                              <label for='inputCity'>Final Group</label>
                              <input
                                class='form-control'
                                value={this.state.finaldata}
                                onChange={(e) =>
                                  this.setState({
                                    finaldata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group'>
                              <label for='inputCity'>Heat</label>
                              <input
                                class='form-control'
                                value={this.state.heatdata}
                                onChange={(e) =>
                                  this.setState({
                                    heatdata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <div class='form-row '>
                            <div class='form-group'>
                              <label for='inputCity'>Lane</label>
                              <input
                                class='form-control'
                                value={this.state.lanedata}
                                onChange={(e) =>
                                  this.setState({
                                    lanedata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <div class='form-row '>
                            <div class='form-group'>
                              <label for='inputCity'>Run As Finals</label>
                              <select
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='currecord'
                                value={this.state.rundata}
                                onChange={(e) =>
                                  this.setState({
                                    rundata: e.target.value,
                                  })
                                }
                                required
                              >
                                <option value='Yes'>Yes</option>
                                <option value='No'>No</option>
                              </select>
                            </div>
                          </div>

                          <center>
                            <button type='button' class='btn btn-danger' onClick={this.sortAfterupdate}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (this.state.issortbyrelayclassgendername) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='venuesetup'>
                  <h1> </h1>
                  <div className='content'>
                    <Row>
                      <Col>
                        <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                          Back
                        </button>
                        <Card>
                          <CardHeader>
                            <h5 className='title'>
                              {this.state.event} <br></br> Preliminary Worksheet
                            </h5>
                          </CardHeader>
                          <CardBody>
                            <div class='form-group row printrow'>
                              <div class='col-xs-5'>
                                <CardHeader>
                                  <center>
                                    <button
                                      type='button'
                                      class='btn btn-success printbtn'
                                      onClick={this.isnotrelaysortbyheatlane}
                                    >
                                      Sort by Heat/Lane
                                    </button>

                                    <button
                                      type='button'
                                      class='btn btn-success printbtn'
                                      onClick={this.isnotrelaysortbygendername}
                                    >
                                      Sort by Gender/Name
                                    </button>
                                    {/* <button type="button" class="btn btn-success printbtn" onClick={this.sortbyclassgendername}>Sort by Class/Age/Gender/Prelim_results</button>
                                  <button type="button" class="btn btn-success printbtn" onClick={this.sortbyclassgendernameresult}>Sort by Class/Age/Gender/Final_results</button> */}
                                  </center>
                                </CardHeader>
                              </div>
                            </div>
                            <Form>
                              {Object.keys(this.state.sortbyclassgendername).length == 0 ? (
                                <Empty />
                              ) : (
                                <>
                                  <table class='worksheet_table'>
                                    <thead>
                                      <tr class='worksheet_tr'>
                                        {/* <th>Work Field</th> */}
                                        <th>Final Group</th>
                                        <th>Heat</th>
                                        <th>Lane</th>
                                        <th>Class</th>
                                        <th>Combined class</th>
                                        <th>Age</th>
                                        <th>Gender</th>
                                        <th>Qualtime</th>
                                        <th>Bib</th>
                                        <th>Last Name</th>
                                        <th>First Name</th>
                                        <th>Country</th>
                                        <th>Qual Stand</th>
                                        <th>Run As Finals</th>
                                        <th>EventName</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.sortbyclassgendername.map((question, index) => (
                                        <tr class='worksheet_tr'>
                                          {/* <td>{question.workfield}</td> */}
                                          <td>{question.final_group}</td>
                                          <td>{question.heat}</td>
                                          <td>{question.lane}</td>
                                          <td>{question.track_class_value}</td>
                                          <td>{question.track_class_value}</td>
                                          <td>{question.age_division}</td>
                                          <td>{question.gender}</td>
                                          <td>{question.qualifying_time}</td>
                                          <td>{question.bib_no}</td>
                                          <td>{question.last_name}</td>
                                          <td>{question.first_name}</td>
                                          <td>{question.country}</td>
                                          {/* <td>{question.qualifying_standard}</td> */}
                                          <td>{removeLeadingZeroes(question.qual_stand)}</td>
                                          <td>{question.run_during_final}</td>
                                          <td>{question.selected_events}</td>
                                          <td>
                                            <button
                                              type='button'
                                              rel='tooltip'
                                              class='btn btn-success btn-sm btn-icon'
                                              onClick={() => this.sortAfterEditbtn1(question.id)}
                                              data-toggle='modal'
                                              data-target='#useredit'
                                            >
                                              <i class='now-ui-icons ui-2_settings-90'></i>
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </>
                              )}
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Preliminary Worksheet
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-row'>
                            <div class='form-group'>
                              <label for='inputCity'>Final Group</label>
                              <input
                                class='form-control'
                                value={this.state.finaldata}
                                onChange={(e) =>
                                  this.setState({
                                    finaldata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group'>
                              <label for='inputCity'>Heat</label>
                              <input
                                class='form-control'
                                value={this.state.heatdata}
                                onChange={(e) =>
                                  this.setState({
                                    heatdata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <div class='form-row '>
                            <div class='form-group'>
                              <label for='inputCity'>Lane</label>
                              <input
                                class='form-control'
                                value={this.state.lanedata}
                                onChange={(e) =>
                                  this.setState({
                                    lanedata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <div class='form-row '>
                            <div class='form-group'>
                              <label for='inputCity'>Run As Finals</label>
                              <select
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='currecord'
                                value={this.state.rundata}
                                onChange={(e) =>
                                  this.setState({
                                    rundata: e.target.value,
                                  })
                                }
                                required
                              >
                                <option value='Yes'>Yes</option>
                                <option value='No'>No</option>
                              </select>
                            </div>
                          </div>

                          <center>
                            <button type='button' class='btn btn-danger' onClick={this.sortAfterupdatebtn}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (this.state.issortbyrelayclassgendernameresult) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='venuesetup'>
                  <h1> </h1>
                  <div className='content'>
                    <Row>
                      <Col>
                        <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                          Back
                        </button>
                        <Card>
                          <CardHeader>
                            <h5 className='title'>
                              {this.state.event} <br></br> Preliminary Worksheet
                            </h5>
                          </CardHeader>
                          <CardBody>
                            <div class='form-group row printrow'>
                              <div class='col-xs-5'>
                                <CardHeader>
                                  <center>
                                    <button
                                      type='button'
                                      class='btn btn-success printbtn'
                                      onClick={this.isnotrelaysortbyheatlane}
                                    >
                                      Sort by Heat/Lane
                                    </button>

                                    <button
                                      type='button'
                                      class='btn btn-success printbtn'
                                      onClick={this.isnotrelaysortbygendername}
                                    >
                                      Sort by Gender/Name
                                    </button>
                                    {/* <button type="button" class="btn btn-success printbtn" onClick={this.sortbyclassgendername}>Sort by Class/Age/Gender/Prelim_results</button>
                                  <button type="button" class="btn btn-success printbtn" onClick={this.sortbyclassgendernameresult}>Sort by Class/Age/Gender/Final_results</button> */}
                                  </center>
                                </CardHeader>
                              </div>
                            </div>
                            <Form>
                              {Object.keys(this.state.sortbyclassgendernameresult).length == 0 ? (
                                <Empty />
                              ) : (
                                <>
                                  <table class='worksheet_table'>
                                    <thead>
                                      <tr class='worksheet_tr'>
                                        {/* <th>Work Field</th> */}
                                        <th>Final Group</th>
                                        <th>Heat</th>
                                        <th>Lane</th>
                                        <th>Class</th>
                                        <th>Combined class</th>
                                        <th>Age</th>
                                        <th>Gender</th>
                                        <th>Qualtime</th>
                                        <th>Bib</th>
                                        <th>Last Name</th>
                                        <th>First Name</th>
                                        <th>Country</th>
                                        <th>Qual Stand</th>
                                        <th>Run As Finals</th>
                                        <th>EventName</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.sortbyclassgendernameresult.map((question, index) => (
                                        <tr class='worksheet_tr'>
                                          {/* <td>{question.workfield}</td> */}
                                          <td>{question.final_group}</td>
                                          <td>{question.heat}</td>
                                          <td>{question.lane}</td>
                                          <td>{question.track_class_value}</td>
                                          <td>{question.track_class_value}</td>
                                          <td>{question.age_division}</td>
                                          <td>{question.gender}</td>
                                          <td>{question.qualifying_time}</td>
                                          <td>{question.bib_no}</td>
                                          <td>{question.last_name}</td>
                                          <td>{question.first_name}</td>
                                          <td>{question.country}</td>
                                          {/* <td>{question.qualifying_standard}</td> */}
                                          <td>{removeLeadingZeroes(question.qual_stand)}</td>
                                          <td>{question.run_during_final}</td>
                                          <td>{question.selected_events}</td>
                                          <td>
                                            <button
                                              type='button'
                                              rel='tooltip'
                                              class='btn btn-success btn-sm btn-icon'
                                              onClick={() => this.sortAfterEditbtn2(question.id)}
                                              data-toggle='modal'
                                              data-target='#useredit'
                                            >
                                              <i class='now-ui-icons ui-2_settings-90'></i>
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </>
                              )}
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Preliminary Worksheet
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-row'>
                            <div class='form-group'>
                              <label for='inputCity'>Final Group</label>
                              <input
                                class='form-control'
                                value={this.state.finaldata}
                                onChange={(e) =>
                                  this.setState({
                                    finaldata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group'>
                              <label for='inputCity'>Heat</label>
                              <input
                                class='form-control'
                                value={this.state.heatdata}
                                onChange={(e) =>
                                  this.setState({
                                    heatdata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <div class='form-row '>
                            <div class='form-group'>
                              <label for='inputCity'>Lane</label>
                              <input
                                class='form-control'
                                value={this.state.lanedata}
                                onChange={(e) =>
                                  this.setState({
                                    lanedata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <div class='form-row '>
                            <div class='form-group'>
                              <label for='inputCity'>Run As Finals</label>
                              <select
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                name='currecord'
                                value={this.state.rundata}
                                onChange={(e) =>
                                  this.setState({
                                    rundata: e.target.value,
                                  })
                                }
                                required
                              >
                                <option value='Yes'>Yes</option>
                                <option value='No'>No</option>
                              </select>
                            </div>
                          </div>

                          <center>
                            <button type='button' class='btn btn-danger' onClick={this.sortAfterupdatebtn}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    return this.state.event == '4 X 100 Meters' ||
      this.state.event == '4 X 400 Meters' ||
      this.state.event == '800 Medley' ||
      this.state.event == '4 X 100 Universal'
      ? (this.state.relay = true)
      : (this.state.notrelay = true);
  }
}

export default Trackworksheet;
