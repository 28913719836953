import React from 'react';

import '../App.css';
import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import { Empty } from 'antd';
import 'datatables.net';
import 'datatables.net-bs4';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
class Printweightliftperformancerecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event_data: [{}],
      relay: false,
      notrelay: false,
      windfinaldata: '',
      heatfinaldata: '',
      lanefinaldata: '',
      breakdata: '',
      recorddata: '',
      nationaldata: '',
      americasdata: '',
      americandata: '',
      njdcdata: '',
      trackdatas: [{}],
      prelimresultdata: '',
      prelimplacedata: '',
      finalplacedata: '',
      finalresultdata: '',
      norelayfinalplacedata: '',
      norelayfinalresultdata: '',
      norelayprelimplacedata: '',
      norelarprelimresultdata: '',
      qualifiesdata: '',
      loader: true,
      tracknjdcdatas: [{}],
      performancedatas: [{}],
    };
  }
  async componentDidMount() {
    var self = this;
    // var count = 0;
    const data = {
      meet_id: sessionStorage.getItem('ids'),
    };

    await axios
      .post(`${url.url}/api/getprintweightsummary/`, data, {})
      .then(function (response) {
        self.setState({ event_data: response.data, loader: false });
      })
      .catch(function (error) {});
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    document.getElementById('homelink').style.display = 'block';

    //Performance Record Data
    const performancerecorddata = {
      Sport_Venue: 'Weightlift',
      Event: this.state.event,
      Classification: this.state.event_data[0].archery_class_value,
      Age_Group: this.state.event_data[0].age_division,
      Gender: this.state.event_data[0].gender,
    };

    axios
      .post(`${url.url}/api/getperformancerecord/`, performancerecorddata, {})
      .then(function (response) {
        self.setState({ performancedatas: response.data.data[0] });
      })
      .catch(function (error) {});
  }
  render() {
    return (
      <>
        <Form>
          <CardHeader>
            <center>
              <h5 className='title'>Para Powerlifting Performance Record</h5>
            </center>
          </CardHeader>
          {Object.keys(this.state.event_data).length == 0 ? (
            <Empty />
          ) : (
            <>
              <table class='worksheet_table'>
                <thead>
                  <tr class='worksheet_tr'>
                    <th>Bib</th>
                    <th>Last Name</th>
                    <th>First Name</th>
                    <th>Lifts 1-3 Place</th>
                    <th>Best Lift</th>
                    <th>Class</th>
                    <th>Age</th>
                    <th>Gender</th>
                    <th>EventName</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.event_data.map((question, index) => (
                    <>
                      <tr class='worksheet_tr'>
                        <td>{question.bib_no}</td>
                        <td>{question.last_name}</td>
                        <td>{question.first_name}</td>
                        <td>{question.final_place}</td>
                        <td>
                          {question.check_color3 == 'good'
                            ? question.lift3
                            : question.check_color2 == 'good'
                            ? question.lift2
                            : question.lift1}
                        </td>
                        <td>{question.weight_class_value}</td>
                        <td>{question.age_division}</td>
                        <td>{question.gender}</td>
                        <td>{question.selected_events}</td>
                      </tr>

                      <tr>
                        <th></th>
                        <th>National</th>
                        <td>
                          {this.state.performancedatas[0].record}
                          {this.state.performancedatas[0].record != null ? 'Yes' : 'No'}
                        </td>
                        <th>American</th>
                        <td>
                          {}
                          {'No'}
                        </td>
                        <th>Americas</th>
                        <td>
                          {}
                          {'No'}
                        </td>
                        <th>Junior National</th>
                        <td>
                          {this.state.performancedatas[0].record}
                          {this.state.performancedatas[0].record != null ? 'Yes' : 'No'}
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </Form>
      </>
    );
  }
}

export default Printweightliftperformancerecord;
