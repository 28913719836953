import React from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $, { event } from 'jquery';
import axios from 'axios';
import './compound.css';
import Swal from 'sweetalert2';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import Pagination from 'react-js-pagination';

import Loader from 'react-loader-spinner';
const url = window.globalConfig;
class Relay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      relaydatas: [{}],
      managers: [{}],
      meetname: [{}],
      assignedmanager: '',
      name: '',
      defaultoption: '',
      managerid: '',
      agename: '',
      pointname: '',
      relayname: '',
      relaypointdata: [{}],
      classvaluedata: [{}],
      offset: 0,
      tableData: [],
      activePage: 1,
      itemsCountPerPage: 1,
      totalItemsCount: 1,
      pageRangeDisplayed: 5,
      loader: true,
      allrelaydatas: [{}],
      isrelaypoint: true,
      issearchrelaypoint: false,
    };

    this.editrecord = this.editrecord.bind(this);
    this.editRecordAfterSearch = this.editRecordAfterSearch.bind(this);
    //  this.handleChange=this.handleChange.bind(this);
    this.optionchange = this.optionchange.bind(this);
    this.updaterecord = this.updaterecord.bind(this);
    this.deleterecord = this.deleterecord.bind(this);
    this.addrecord = this.addrecord.bind(this);
    this.optionchange1 = this.optionchange1.bind(this);
    this.optionchange2 = this.optionchange2.bind(this);
    // this.onSearch=this.onSearch.bind(this);

    this.handlePageChange = this.handlePageChange.bind(this);
  }

  //
  handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber });

    var self = this;
    axios.get(`${url.url}/api/getallrelayrecord?page=` + pageNumber, {}).then(function (response) {
      self.setState({
        relaydatas: response.data.data,
        itemsCountPerPage: response.data.per_page,

        totalItemsCount: response.data.total,
        activePage: response.data.current_page,
        loader: false,
      });
      // console.log(activePage)
    });
  }

  componentDidMount() {
    // $(document).ready(function () {
    //   $("#inputsm").on("keyup", function () {
    //     var value = $(this).val().toLowerCase();
    //     $("#myTable tr").filter(function () {
    //       $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    //     });
    //   });
    // });

    // var self=this;

    this.handlePageChange();

    var self = this;
    axios.get(`${url.url}/api/getallrelaypointrecord`, {}).then(function (response) {
      self.setState({
        allrelaydatas: response.data,
        loader: false,
      });
      // console.log(response.data);
    });
  }

  //

  // //////////
  onSearch = (e) => {
    //console.log($("#inputsm"))
    // $("#inputsm").on("keyup",function(){
    // setSearchText(e.target.value);

    var value = e.target.value.toLowerCase();
    // var result = [];
    if (!value) {
      // console.log("reee")
      this.setState({
        isrelaypoint: true,
        issearchrelaypoint: false,
      });
      return null;
    }
    $('#myTable tr').filter(function () {
      $(this).toggle($(this).text().toLowerCase().search(value) > -1);
    });
    //  console.log(event+"22")
    this.setState({
      isrelaypoint: false,
      issearchrelaypoint: true,
    });
  };

  // edit meet------------------------------------------------------------------------------------------------

  editrecord(e) {
    var self = this;
    // console.log(this.state.meetdatas[e],'fjgd')
    self.setState({
      relaypointdata: this.state.relaydatas[e],
      relayname: this.state.relaydatas[e].class,
      agename: this.state.relaydatas[e].age,
      pointname: this.state.relaydatas[e].points,
    });
    // console.log(this.state.relaydatas[e])
  }
  editRecordAfterSearch(e) {
    this.setState({
      relaypointdata: this.state.allrelaydatas[e],
      relayname: this.state.allrelaydatas[e].class,
      agename: this.state.allrelaydatas[e].age,
      pointname: this.state.allrelaydatas[e].points,
    });
  }

  optionchange2(e) {
    this.setState({
      relayname: e.target.value,
    });
    this.setState({
      [e.target.name]: e.target.value,
    });
    //console.log(e)
  }

  optionchange1(e) {
    this.setState({
      pointname: e.target.value,
    });
    this.setState({
      [e.target.name]: e.target.value,
    });
    //console.log(e.target.value)
  }

  optionchange(e) {
    this.setState({
      agename: e.target.value,
    });
    this.setState({
      [e.target.name]: e.target.value,
    });
    // console.log(e.target.value)
  }

  updaterecord() {
    var self = this;
    const datatosend = {
      class: this.state.relayname,
      age: this.state.agename,
      points: this.state.pointname,
    };

    axios
      .put(`${url.url}/api/updaterelayrecord/${this.state.relaypointdata.id}`, datatosend, {})

      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Record Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  // edit meet------------------------------------------------------------------------------------------------

  deleterecord(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deleterelayrecord/${this.state.relaydatas[e].id}`, {})
          .then(function (response) {})

          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });
  }
  // ---------------------------------------------------------edit meet--------------------------------------------------

  addrecord(e) {
    var self = this;
    const datatosend = {
      class: this.state.classrecord,
      age: this.state.agerecord,
      points: this.state.point,
    };
    // console.log(datatosend)
    axios
      .post(`${url.url}/api/createrelayrecord`, datatosend, {})
      .then(function (response) {
        if (response.statusText == 'Created' || response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'Record Created successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  render() {
    //sessionStorage.setItem("path","/meets");
    if (this.state.isrelaypoint) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div className='content'>
                <Row>
                  <Col md='8'>
                    <button type='button' data-toggle='modal' data-target='#newmeet' class='btn btn-danger btn-round'>
                      Add Relaypoints
                    </button>
                    <Card>
                      <CardHeader>
                        <h5 className='title'>Relay Points</h5>
                      </CardHeader>

                      <CardBody>
                        <Form>
                          <div class='form-group row'>
                            <div class='col-xs-5'>
                              <CardHeader>
                                <p>Search</p>
                                <input
                                  class='form-control'
                                  id='inputsm'
                                  onChange={this.onSearch}
                                  placeholder='Search..'
                                  type='text'
                                />
                              </CardHeader>
                            </div>
                          </div>

                          {/* table --------------------------------------------------- */}
                          <Table id='my' responsive>
                            <thead>
                              <tr>
                                <th className='text-center'>#</th>
                                <th>Class</th>
                                <th>Age</th>
                                <th>Points</th>

                                <th class='disabled-sorting text-right'>Actions</th>
                              </tr>
                            </thead>
                            <tbody id='myTable'>
                              {this.state.relaydatas.map((question, index) => (
                                <tr>
                                  <td>{question.id}</td>
                                  <td>{question.class}</td>
                                  <td>{question.age}</td>
                                  <td>{question.points}</td>
                                  <td class='td-actions text-right'>
                                    <button
                                      type='button'
                                      rel='tooltip'
                                      class='btn btn-success btn-sm btn-icon'
                                      onClick={() => this.editrecord(index)}
                                      data-toggle='modal'
                                      data-target='#meetedit'
                                    >
                                      <i class='now-ui-icons ui-2_settings-90'></i>
                                    </button>
                                    {` `}
                                    <button
                                      type='button'
                                      rel='tooltip'
                                      class='btn btn-danger btn-sm btn-icon'
                                      onClick={() => this.deleterecord(index)}
                                    >
                                      <i class='now-ui-icons ui-1_simple-remove'></i>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.itemsCountPerPage}
                            totalItemsCount={this.state.totalItemsCount}
                            pageRangeDisplayed={this.state.pageRangeDisplayed}
                            onChange={(pageNumber) => this.handlePageChange(pageNumber)}
                            itemClass='page-item'
                            linkClass='page-link'
                          />
                          {/* table --------------------------------------------------- */}
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                {/* edit meet modal ----------------------------------------------------------------------------------------------------------------- */}
                <div class='modal fade' id='meetedit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Edit Relaypoints{' '}
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-group'>
                            <label for='inputAddress'>Class</label>
                            <input
                              type='text'
                              class='form-control'
                              id='inputAddress'
                              placeholder='Class'
                              name='classrecord'
                              value={this.state.relayname}
                              onChange={this.optionchange2}
                              required
                            />
                          </div>

                          <div class='form-group'>
                            <label for='inputAddress'>Age</label>
                            <input
                              type='text'
                              class='form-control'
                              id='inputAddress'
                              placeholder='Age'
                              name='agerecord'
                              value={this.state.agename}
                              onChange={this.optionchange}
                              required
                            />
                          </div>

                          <div class='form-group'>
                            <label for='inputAddress'>Points</label>
                            <input
                              type='text'
                              class='form-control'
                              id='inputAddress'
                              placeholder='Points'
                              name='point'
                              value={this.state.pointname}
                              onChange={this.optionchange1}
                              required
                            />
                          </div>

                          <center>
                            <button
                              type='submit'
                              onClick={this.updaterecord}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                {/* add meet------------------------------------------------------------------------------------------------- */}
                <div class='modal fade' id='newmeet' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          New RelayPoints
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-group'>
                            <label for='inputAddress'>Class</label>
                            <input
                              type='text'
                              class='form-control'
                              id='inputAddress'
                              // placeholder="Class"
                              name='classrecord'
                              onChange={this.optionchange}
                              placeholder='Enter class'
                              required
                            />
                          </div>

                          <div class='form-group'>
                            <label for='inputAddress'>Age</label>
                            <input
                              type='text'
                              class='form-control'
                              id='inputAddress'
                              // placeholder="Age"
                              name='agerecord'
                              onChange={this.optionchange}
                              placeholder='Enter age'
                              required
                            />
                          </div>

                          <div class='form-group'>
                            <label for='inputAddress'>Points</label>
                            <input
                              type='text'
                              class='form-control'
                              id='inputAddress'
                              // placeholder="Points"
                              name='point'
                              onChange={this.optionchange}
                              placeholder='Enter points'
                              required
                            />
                          </div>

                          <center>
                            <button type='submit' onClick={this.addrecord} data-dismiss='modal' class='btn btn-danger'>
                              Create
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (this.state.issearchrelaypoint) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div className='content'>
                <Row>
                  <Col md='8'>
                    <button type='button' data-toggle='modal' data-target='#newmeet' class='btn btn-danger btn-round'>
                      Add Relaypoints
                    </button>
                    <Card>
                      <CardHeader>
                        <h5 className='title'>Relay Points</h5>
                      </CardHeader>

                      <CardBody>
                        <Form>
                          <div class='form-group row'>
                            <div class='col-xs-5'>
                              <CardHeader>
                                <p>Search</p>
                                <input
                                  class='form-control'
                                  id='inputsm'
                                  onChange={this.onSearch}
                                  placeholder='Search..'
                                  type='text'
                                />
                              </CardHeader>
                            </div>
                          </div>

                          {/* table --------------------------------------------------- */}
                          <Table id='my' responsive>
                            <thead>
                              <tr>
                                <th className='text-center'>#</th>
                                <th>Class</th>
                                <th>Age</th>
                                <th>Points</th>

                                <th class='disabled-sorting text-right'>Actions</th>
                              </tr>
                            </thead>
                            <tbody id='myTable'>
                              {this.state.allrelaydatas.map((question, index) => (
                                <tr>
                                  <td>{question.id}</td>
                                  <td>{question.class}</td>
                                  <td>{question.age}</td>
                                  <td>{question.points}</td>

                                  <td class='td-actions text-right'>
                                    <button
                                      type='button'
                                      rel='tooltip'
                                      class='btn btn-success btn-sm btn-icon'
                                      onClick={() => this.editRecordAfterSearch(index)}
                                      data-toggle='modal'
                                      data-target='#meetedit'
                                    >
                                      <i class='now-ui-icons ui-2_settings-90'></i>
                                    </button>
                                    {` `}
                                    <button
                                      type='button'
                                      rel='tooltip'
                                      class='btn btn-danger btn-sm btn-icon'
                                      onClick={() => this.deleterecord(index)}
                                    >
                                      <i class='now-ui-icons ui-1_simple-remove'></i>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.itemsCountPerPage}
                            totalItemsCount={this.state.totalItemsCount}
                            pageRangeDisplayed={this.state.pageRangeDisplayed}
                            onChange={(pageNumber) => this.handlePageChange(pageNumber)}
                            itemClass='page-item'
                            linkClass='page-link'
                          />
                          {/* table --------------------------------------------------- */}
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                {/* edit meet modal ----------------------------------------------------------------------------------------------------------------- */}
                <div class='modal fade' id='meetedit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Edit Relaypoints{' '}
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-group'>
                            <label for='inputAddress'>Class</label>
                            <input
                              type='text'
                              class='form-control'
                              id='inputAddress'
                              placeholder='Class'
                              name='classrecord'
                              value={this.state.relayname}
                              onChange={this.optionchange2}
                              required
                            />
                          </div>

                          <div class='form-group'>
                            <label for='inputAddress'>Age</label>
                            <input
                              type='text'
                              class='form-control'
                              id='inputAddress'
                              placeholder='Age'
                              name='agerecord'
                              value={this.state.agename}
                              onChange={this.optionchange}
                              required
                            />
                          </div>

                          <div class='form-group'>
                            <label for='inputAddress'>Points</label>
                            <input
                              type='text'
                              class='form-control'
                              id='inputAddress'
                              placeholder='Points'
                              name='point'
                              value={this.state.pointname}
                              onChange={this.optionchange1}
                              required
                            />
                          </div>

                          <center>
                            <button
                              type='submit'
                              onClick={this.updaterecord}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                {/* add meet------------------------------------------------------------------------------------------------- */}
                <div class='modal fade' id='newmeet' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          New RelayPoints
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-group'>
                            <label for='inputAddress'>Class</label>
                            <input
                              type='text'
                              class='form-control'
                              id='inputAddress'
                              // placeholder="Class"
                              name='classrecord'
                              onChange={this.optionchange}
                              placeholder='Enter class'
                              required
                            />
                          </div>

                          <div class='form-group'>
                            <label for='inputAddress'>Age</label>
                            <input
                              type='text'
                              class='form-control'
                              id='inputAddress'
                              // placeholder="Age"
                              name='agerecord'
                              onChange={this.optionchange}
                              placeholder='Enter age'
                              required
                            />
                          </div>

                          <div class='form-group'>
                            <label for='inputAddress'>Points</label>
                            <input
                              type='text'
                              class='form-control'
                              id='inputAddress'
                              // placeholder="Points"
                              name='point'
                              onChange={this.optionchange}
                              placeholder='Enter points'
                              required
                            />
                          </div>

                          <center>
                            <button type='submit' onClick={this.addrecord} data-dismiss='modal' class='btn btn-danger'>
                              Create
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
  }
}

export default Relay;
