import React, { Component } from 'react';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from 'reactstrap';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/bootstrap.min.css';
import './assets/css/now-ui-dashboard.css?v=1.5.0';
import './assets/demo/demo.css';
import Swal from 'sweetalert2';
import axios from 'axios';
import $ from 'jquery';
import logo from './logo.png';
import emailjs from 'emailjs-com';
import adaptive from './adaptive.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDribbble, faFacebook, faTwitter, faWindows } from '@fortawesome/free-brands-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import Home from './components/home.js';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      backgroundColor: 'white',
      loader: false,
      forpass: false,
      newpass: false,
      homepage: false,
      fname: '',
      lname: '',
      email: '',
      errors: {},
      temppass: '',
      showError: false,
      messageFromServer: '',
      mess: '',
      text1: '',
      cpassword: '',
    };
    this.submitlogin = this.submitlogin.bind(this);
    this.forpass = this.forpass.bind(this);
    this.handlechange1 = this.handlechange1.bind(this);
    this.handlechange2 = this.handlechange2.bind(this);
    this.close = this.close.bind(this);
    this.close1 = this.close1.bind(this);
  }
  submitlogin(e) {
    e.preventDefault();
    if (this.state.username === '' || this.state.password === '') {
      this.validateForm();
    } else {
      const datatosend = {
        email: this.state.username,
        password: this.state.password,
      };

      var self = this;
      self.setState({ loggedIn: true });
      axios
        .post(`${url.url}/api/login`, datatosend, {})
        .then(function (response) {
          if (response.status) {
            let userdetails = {
              token: response.data.data.token,
              id: response.data.data.user.id,
              holedata: response.data,
            };
            sessionStorage.setItem('userdetails', JSON.stringify(userdetails));
            if (response.data.data.user.first_time_user == 'true') {
              self.setState({
                newpass: true,
              });
              sessionStorage.removeItem('userdetails');
            } else {
              self.setState({ homepage: true, loginid: response.data.data });
            }
          }
        })
        .then(function (response) {
          document.getElementById('social').style.display = 'none';
        })

        .catch(function (error) {
          Swal.fire({
            title: 'Log in Failed',
            text: 'Please check your credential',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          });
        });
    }
  }
  forpass() {
    this.setState({ forpass: true });
  }
  forget(e) {
    if (this.state.username === '') {
      this.setState({
        showError: true,
        // messageFromServer: "dssdsd",
      });
    } else if (this.state.username === 'email not in db') {
      this.setState({
        showError: true,
        messageFromServer: '',
      });
    } else if (this.state.username === 'recovery email sent') {
      this.setState({
        showError: false,
        messageFromServer: 'recovery email sent',
      });
    }
  }
  handlechange1(e) {
    e.preventDefault();
    this.setState({
      loader: true,
    });
    const data = { email: this.state.username };
    const otp = (otp) => {
      this.setState({
        mess: otp,
      });
    };
    // const sendmail = (retVal) => {
    //   this.setState({ newpass: true });
    //   var templateParams = {
    //     from_name: 'MU Meet Management Application',
    //     to_name: this.state.username,
    //     name: this.state.username,
    //     to_email: this.state.username,
    //     message: retVal,
    //   };
    //   console.log(templateParams);
    //   emailjs.send('service_3zm0qsj', 'template_d2ce8no', templateParams, 'user_EVR0vOEkWZCA4wY6MNnKp').then(
    //     function (res) {
    //       console.log(res);
    //     },
    //     function (error) {}
    //   );
    //   return retVal;
    // };
    var length = 8,
      charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
      retVal = '';
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
      otp(retVal);
    }
    var templateParams = {
      from_name: 'MU Meet Management Application',
      to: this.state.username,
      // name: this.state.username,
      // to_email: this.state.username,
      temp_password: retVal,
    };
    axios
      .post(`${url.url}/api/forgetpassword`, templateParams, {})
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            newpass: true,
            loader: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
        });
        Swal.fire({
          title: 'Failed',
          text: 'Email id does not exist',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  handlechange2(e) {
    e.preventDefault();
    var pass = this.state.password;
    if (this.state.mess === this.state.text1 && this.state.password === this.state.cpassword) {
      const datatosend = {
        email: this.state.username,
      };
      axios
        .post(`${url.url}/api/checkemail`, datatosend, {})

        .then(function (response) {
          if (response.statusText === 'OK' || response.status === 200) {
            const data = {
              password: pass,
              first_time_user: 'false',
            };
            axios
              .put(`${url.url}/api/updateuserpass/${response.data.data[0].id}`, data, {})
              .then(function (response) {
                Swal.fire({
                  title: 'Success',
                  text: 'Your Password is updated successfully',
                  icon: 'success',
                  showCancelButton: false,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Ok!',
                }).then(function (response) {
                  window.location.reload();
                });
              })
              .catch(function (error) {
                Swal.fire({
                  title: 'Failed',
                  text: 'Something went wrong',
                  icon: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Ok!',
                });
              });
          }
        });

      // alert("success")
    }
    // else{
    //   alert("failed")
    // }
  }
  validateForm() {
    let errors = {};
    let formIsValid = true;
    if (!this.state.username) {
      formIsValid = false;
      errors['username'] = '*Please enter your email.';
    }
    if (this.state.username !== 'undefined') {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(this.state.username)) {
        formIsValid = false;
        errors['emailid'] = '*Please enter valid email-ID.';
      }
    }
    if (!this.state.password) {
      formIsValid = false;
      errors['password'] = '*Please enter your password.';
    }

    if (typeof this.state.password !== 'undefined') {
      if (!this.state.password.match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
        formIsValid = false;
        errors['password'] = '*Please enter secure and strong password.';
      }
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  close() {
    this.setState({
      forpass: false,
    });
  }
  close1() {
    // this.setState({
    //   forpass:false,
    // })
    window.location.replace('/');
  }

  render() {
    if (this.state.newpass) {
      return (
        <>
          <div>
            <nav class='navbar navbar-expand-lg bg-white'>
              <div class='container-fluid'>
                <div class='navbar-wrapper'>
                  <img src={logo} alt='' width='100px' height='50px' />
                  <img src={adaptive} alt='' width='100px' height='50px' />
                </div>
              </div>
            </nav>
            <div class='wrapper wrapper-full-page wrapper1'>
              {/* <div class="full-page login-page section-image" filter-color="black" >
      </div> */}
              {/* <div class="modal fade" id="loginmodal" tabindex="-1" role="dialog"  aria-hidden="true"> */}
              <div class='modal-dialog modal-dialog1' id='loginmodal'>
                <div class='modal-content'>
                  <div class='modal-header '>
                    <h5 class='modal-title ' id='loginmodalLabel'>
                      Log in
                    </h5>
                    {/* <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
       </button> */}
                  </div>
                  <div class='modal-body'>
                    <form>
                      <div class='form-group'>
                        <label for='exampleInputEmail1'>Email address</label>
                        <input
                          type='email'
                          class='form-control'
                          id='exampleInputEmail1'
                          name='username'
                          onChange={(e) => this.setState({ username: e.target.value })}
                          aria-describedby='emailHelp'
                          placeholder='Enter email'
                          required
                        />
                      </div>
                      <div class='form-group'>
                        <label for='text1'>Temporary Password</label>
                        <input
                          type='text'
                          class='form-control'
                          id='text1'
                          name='text1'
                          onChange={(e) => this.setState({ text1: e.target.value })}
                          aria-describedby='emailHelp'
                          placeholder='Temporary password'
                          required
                        />
                      </div>
                      <div class='form-group'>
                        <label for='exampleInputPassword1'>New Password</label>
                        <input
                          type='password'
                          class='form-control'
                          id='exampleInputPassword1'
                          name='password'
                          onChange={(e) => this.setState({ password: e.target.value })}
                          placeholder='Password'
                          required
                        />
                      </div>
                      <div class='form-group'>
                        <label for='exampleInputPassword1'>Confirm Password</label>
                        <input
                          type='password'
                          class='form-control'
                          id='exampleInputPassword1'
                          name='password'
                          onChange={(e) => this.setState({ cpassword: e.target.value })}
                          placeholder='Password'
                          required
                        />
                      </div>
                      <button type='submit' class='btn btn-danger' onClick={this.handlechange2}>
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* </div>  */}
          </div>

          <div id='icons' class=' container-fluid  '>
            <nav></nav>
          </div>
          <div class='col-md-4' id='social'>
            <ul>
              <a href='#'>
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a href='#'>
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a href='#'>
                <FontAwesomeIcon icon={faDribbble} />
              </a>
              <a href='#'>
                <FontAwesomeIcon icon={faGithub} />
              </a>
            </ul>
          </div>
          <div class='col-md-4'>
            <h4 class='text-primary font-weight-light mb-2'>Thank you for supporting us!</h4>
          </div>
          <footer>
            <hr></hr>
            <p id='footer' class='col-md-4'>
              © 2020 MU Meet Management Application.{' '}
            </p>
          </footer>
        </>
      );
    }
    if (this.state.forpass) {
      return (
        <>
          <div>
            <nav class='navbar navbar-expand-lg bg-white'>
              <div class='container-fluid'>
                <div class='navbar-wrapper'>
                  <img src={logo} alt='' width='100px' height='50px' />
                  <img src={adaptive} alt='' width='100px' height='50px' />
                </div>
              </div>
            </nav>
            <div class='wrapper wrapper-full-page wrapper1' filter-color='black'>
              {/* <div class="full-page login-page section-image" filter-color="black" >
      </div> */}
              {/* <div class="modal fade" id="loginmodal" tabindex="-1" role="dialog"  aria-hidden="true"> */}
              <div class='modal-dialog modal-dialog1' id='loginmodal'>
                <div class='modal-content'>
                  <div class='modal-header '>
                    <h5 class='modal-title ' id='loginmodalLabel'>
                      Log in
                    </h5>
                    <button type='button' onClick={this.close} class='close' data-dismiss='modal' aria-label='Close'>
                      <span aria-hidden='true'>&times;</span>
                    </button>
                  </div>
                  <div class='modal-body'>
                    <form>
                      <div class='form-group'>
                        <label for='exampleInputEmail'>Email address</label>
                        <input
                          type='email'
                          class='form-control'
                          id='exampleInputEmail'
                          name='username'
                          onChange={(e) => this.setState({ username: e.target.value })}
                          aria-describedby='emailHelp'
                          placeholder='Enter email'
                          required
                        />
                      </div>
                      <button type='submit' class='btn btn-danger' onClick={this.handlechange1}>
                        {this.state.loader ? (
                          <>
                            <div>
                              <Loader type='ThreeDots' color='#FFFFFF' height={8} width={20} />
                            </div>
                          </>
                        ) : (
                          'submit'
                        )}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* </div>  */}
          </div>

          <div id='icons' class=' container-fluid  '>
            <nav></nav>
          </div>
          <div class='col-md-4' id='social'>
            <ul>
              <a href='#'>
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a href='#'>
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a href='#'>
                <FontAwesomeIcon icon={faDribbble} />
              </a>
              <a href='#'>
                <FontAwesomeIcon icon={faGithub} />
              </a>
            </ul>
          </div>
          <div class='col-md-4'>
            <h4 class='text-primary font-weight-light mb-2'>Thank you for supporting us!</h4>
          </div>
          <footer>
            <hr></hr>
            <p id='footer' class='col-md-4'>
              © 2020 MU Meet Management Application.{' '}
            </p>
          </footer>
        </>
      );
    }
    if (this.state.showError) {
      return (
        <div>
          <p>The email address isn't recognized.</p>
        </div>
      );
    }
    if (this.state.messageFromServer) {
      return (
        <div>
          <p>Temporary Password Sent to your Email Successfully</p>
        </div>
      );
    }
    return (
      <>
        {this.state.homepage ? (
          <Home logindata={this.state.loginid} />
        ) : (
          <div>
            <nav class='navbar navbar-expand-lg bg-white'>
              <div class='container-fluid'>
                <div class='navbar-wrapper'>
                  <img src={logo} alt='' width='100px' height='50px' />
                  <img src={adaptive} alt='' width='100px' height='50px' />
                </div>
              </div>
            </nav>
            <div class='wrapper wrapper-full-page wrapper1 ' filter-color='black'>
              {/* <div class="modal fade" id="loginmodal" tabindex="-1" role="dialog"  aria-hidden="true"> */}
              <div class='modal-dialog modal-dialog1' id='loginmodal'>
                <div class='modal-content'>
                  <div class='modal-header '>
                    <h5 class='modal-title ' id='loginmodalLabel'>
                      Log in
                    </h5>
                    <button type='button' class='close' onClick={this.close1} data-dismiss='modal' aria-label='Close'>
                      <span aria-hidden='true'>&times;</span>
                    </button>
                  </div>
                  <div class='modal-body'>
                    <form>
                      <div class='form-group'>
                        <label for='exampleInputEmail1'>Email address</label>
                        <input
                          type='email'
                          class='form-control'
                          id='exampleInputEmail1'
                          name='username'
                          onChange={(e) => this.setState({ username: e.target.value })}
                          aria-describedby='emailHelp'
                          placeholder='Enter email'
                          required
                        />
                        <div className='errorMsg'>{this.state.errors.username}</div>
                      </div>
                      <div class='form-group'>
                        <label for='exampleInputPassword1'>Password</label>
                        <input
                          type='password'
                          class='form-control'
                          id='exampleInputPassword1'
                          name='password'
                          onChange={(e) => this.setState({ password: e.target.value })}
                          placeholder='Password'
                          required
                        />
                        <div className='errorMsg'>{this.state.errors.password}</div>
                      </div>
                      <a href='#' class='fgtpwd' onClick={this.forpass}>
                        Forget Password ?
                      </a>
                      <button type='submit' class='btn btn-danger' onClick={this.submitlogin}>
                        Log in
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <div class="full-page login-page section-image" filter-color="black" >
 </div>

 </div>  */}

        <div id='icons' class=' container-fluid  '>
          <nav></nav>
        </div>
        <div class='col-md-4' id='social'>
          <ul>
            <a href='#'>
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href='#'>
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href='#'>
              <FontAwesomeIcon icon={faDribbble} />
            </a>
            <a href='#'>
              <FontAwesomeIcon icon={faGithub} />
            </a>
          </ul>
        </div>
        <div class='col-md-4'>
          <h4 class='text-primary font-weight-light mb-2'>Thank you for supporting us!</h4>
        </div>
        <footer>
          <hr></hr>
          <p id='footer' class='col-md-4'>
            © 2020 MU Meet Management Application.{' '}
          </p>
        </footer>
      </>
    );
  }
}
export default Login;
