import React from 'react';

import '../App.css';
import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Empty } from 'antd';
import 'datatables.net';
import 'datatables.net-bs4';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
class Airgunpitentry extends React.Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    this.state = {
      event: queryParams.get('event'),
      event_data: [{}],
      loader: true,
      pitdata: '',
      groupdata: '',
      finalresultdata: '',
      finalplacedata: '',
      prelimresultdata: '',
      prelimplacedata: '',
      isnotsort: true,
      issortpitgroup: false,
      sortbypitdatas: [],
      sortgendernamedatas: [{}],
      editsortbypitdatas: [{}],
    };
    this.back = this.back.bind(this);
    this.edit = this.edit.bind(this);
    this.update = this.update.bind(this);
  }
  async componentDidMount() {
    var self = this;
    const data = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    await axios
      .post(`${url.url}/api/getregisterotherevent/`, data, {})
      .then(function (response) {
        self.setState({ event_data: response.data, loader: false });
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
  }

  back() {
    window.location.replace('/venuemainpage?event=' + sessionStorage.getItem('event'));
  }

  edit(e) {
    var self = this;
    self.setState({
      eventdatas: this.state.event_data[e],
      prelimresultdata: this.state.event_data[e].prelim_result || '',
      prelimplacedata: this.state.event_data[e].prelim_place || '',
      finalresultdata: this.state.event_data[e].final_result || '',
      finalplacedata: this.state.event_data[e].final_place || '',
    });
  }

  editAfterSort = (e) => {
    var found = this.state.sortbypitdatas.find((question) => question.id == e);
    this.setState({
      editsortbypitdatas: found,
      prelimresultdata: found.prelim_result || '',
      prelimplacedata: found.prelim_place || '',
      finalresultdata: found.final_result || '',
      finalplacedata: found.final_place || '',
    });
  };

  update(e) {
    const datatosend = {
      prelim_result: this.state.prelimresultdata,
      prelim_place: this.state.prelimplacedata,
      final_result: this.state.finalresultdata,
      final_place: this.state.finalplacedata,
    };
    axios
      .put(`${url.url}/api/updateairgunpitentry/${this.state.eventdatas.id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  updateAfterSort = (e) => {
    const datatosend = {
      prelim_result: this.state.prelimresultdata,
      prelim_place: this.state.prelimplacedata,
      final_result: this.state.finalresultdata,
      final_place: this.state.finalplacedata,
    };
    axios
      .put(`${url.url}/api/updateairgunpitentryAfterSort/${this.state.editsortbypitdatas.id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  };

  sortbypitgroup = () => {
    var self = this;
    const sortdata = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    axios.post(`${url.url}/api/getothersortbypitgroup/`, sortdata, {}).then(function (response) {
      self.setState({
        sortbypitdatas: response.data,
        loader: false,
        isnotsort: false,
        issortpitgroup: true,
      });
    });
  };

  render() {
    if (this.state.isnotsort) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div>
                  <h1> </h1>
                  <Row>
                    <Col>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                        Back
                      </button>
                      <Card className='scrollcard'>
                        <CardHeader>
                          <h5 className='title'>
                            {this.state.event} <br></br> Results Entry by Pit
                          </h5>
                        </CardHeader>
                        <CardBody>
                          <div class='form-group row printrow'>
                            <div class='col-xs-5'>
                              <CardHeader>
                                <center>
                                  <button type='button' class='btn btn-success printbtn' onClick={this.sortbypitgroup}>
                                    sort by pit/group/gender/class/age
                                  </button>
                                </center>
                              </CardHeader>
                            </div>
                          </div>
                          <Form>
                            {Object.keys(this.state.event_data).length == 0 ? (
                              <Empty />
                            ) : (
                              <>
                                <Table class='worksheet_table'>
                                  <thead>
                                    <tr class='worksheet_tr'>
                                      <th>Pits</th>
                                      <th>Groups</th>
                                      <th>Bib</th>
                                      <th>Last Name</th>
                                      <th>First Name</th>
                                      <th>Class</th>
                                      <th>Age</th>
                                      <th>Gender</th>
                                      <th>Preliminary Results</th>
                                      <th>Preliminary Place</th>
                                      <th>Final Results</th>
                                      <th>Final Place</th>
                                      <th>Qualifies</th>
                                      <th>Qual Stand</th>
                                      <th>Break Record</th>
                                      <th>EventName</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.event_data.map((question, index) => (
                                      <tr class='worksheet_tr'>
                                        <td>{question.pits}</td>
                                        <td>{question.group}</td>
                                        <td>{question.bib_no}</td>
                                        <td>{question.last_name}</td>
                                        <td>{question.first_name}</td>
                                        <td>{question.other_class_value}</td>
                                        <td>{question.age_division}</td>
                                        <td>{question.gender}</td>
                                        <td>{question.prelim_result}</td>
                                        <td>{question.prelim_place}</td>
                                        <td>{question.final_result}</td>
                                        <td>{question.final_place}</td>
                                        <td>
                                          {question.final_result >= question.Qualifying_marks
                                            ? question.Qualifying_marks == null
                                              ? 'NRF'
                                              : 'Yes'
                                            : 'No'}
                                        </td>
                                        <td>{}</td>
                                        <td>{'NRF'}</td>
                                        <td>{question.selected_events}</td>
                                        <td>
                                          <button
                                            type='button'
                                            rel='tooltip'
                                            class='btn btn-success btn-sm btn-icon'
                                            onClick={() => this.edit(index)}
                                            data-toggle='modal'
                                            data-target='#useredit'
                                          >
                                            <i class='now-ui-icons ui-2_settings-90'></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </>
                            )}
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Results Entry by Pit
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-row'>
                            <div class='form-group col-md-6'>
                              <label for='inputCity'>Preliminary Result</label>
                              <input
                                class='form-control'
                                value={this.state.prelimresultdata}
                                onChange={(e) => this.setState({ prelimresultdata: e.target.value })}
                                name='relayletter'
                              />
                            </div>
                            <div class='form-group col-md-6'>
                              <label for='inputCity'>Preliminary Place</label>
                              <input
                                class='form-control'
                                value={this.state.prelimplacedata}
                                onChange={(e) => this.setState({ prelimplacedata: e.target.value })}
                                name='relayletter'
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group col-md-6'>
                              <label for='inputCity'>Final Result</label>
                              <input
                                class='form-control'
                                value={this.state.finalresultdata}
                                onChange={(e) => this.setState({ finalresultdata: e.target.value })}
                                name='relayletter'
                              />
                            </div>
                            <div class='form-group col-md-6'>
                              <label for='inputCity'>Final Place</label>
                              <input
                                class='form-control'
                                value={this.state.finalplacedata}
                                onChange={(e) => this.setState({ finalplacedata: e.target.value })}
                                name='relayletter'
                              />
                            </div>
                          </div>

                          <center>
                            <button type='button' class='btn btn-danger' onClick={this.update}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }

    if (this.state.issortpitgroup) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div>
                  <h1> </h1>
                  <Row>
                    <Col>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                        Back
                      </button>
                      <Card className='scrollcard'>
                        <CardHeader>
                          <h5 className='title'>
                            {this.state.event} <br></br> Results Entry by Pit
                          </h5>
                        </CardHeader>
                        <CardBody>
                          <div class='form-group row printrow'>
                            <div class='col-xs-5'>
                              <CardHeader>
                                <center>
                                  <button type='button' class='btn btn-success printbtn' onClick={this.sortbypitgroup}>
                                    sort by pit/group/gender/class/age
                                  </button>
                                </center>
                              </CardHeader>
                            </div>
                          </div>
                          <Form>
                            {Object.keys(this.state.sortbypitdatas).length == 0 ? (
                              <Empty />
                            ) : (
                              <>
                                <Table class='worksheet_table'>
                                  <thead>
                                    <tr class='worksheet_tr'>
                                      <th>Pits</th>
                                      <th>Groups</th>
                                      <th>Bib</th>
                                      <th>Last Name</th>
                                      <th>First Name</th>
                                      <th>Class</th>
                                      <th>Age</th>
                                      <th>Gender</th>
                                      <th>Preliminary Results</th>
                                      <th>Preliminary Place</th>
                                      <th>Final Results</th>
                                      <th>Final Place</th>
                                      <th>Qualifies</th>
                                      <th>Qual Stand</th>
                                      <th>Break Record</th>
                                      <th>EventName</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.sortbypitdatas.map((question, index) => (
                                      <tr class='worksheet_tr'>
                                        <td>{question.pits}</td>
                                        <td>{question.group}</td>
                                        <td>{question.bib_no}</td>
                                        <td>{question.last_name}</td>
                                        <td>{question.first_name}</td>
                                        <td>{question.other_class_value}</td>
                                        <td>{question.age_division}</td>
                                        <td>{question.gender}</td>
                                        <td>{question.prelim_result}</td>
                                        <td>{question.prelim_place}</td>
                                        <td>{question.final_result}</td>
                                        <td>{question.final_place}</td>
                                        <td>{question.final_result != null ? 'Yes' : 'no'}</td>
                                        <td>{}</td>
                                        <td>{'NRF'}</td>
                                        <td>{question.selected_events}</td>
                                        <td>
                                          <button
                                            type='button'
                                            rel='tooltip'
                                            class='btn btn-success btn-sm btn-icon'
                                            onClick={() => this.editAfterSort(question.id)}
                                            data-toggle='modal'
                                            data-target='#useredit'
                                          >
                                            <i class='now-ui-icons ui-2_settings-90'></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </>
                            )}
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Results Entry by Pit
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-row'>
                            <div class='form-group col-md-6'>
                              <label for='inputCity'>Preliminary Result</label>
                              <input
                                class='form-control'
                                value={this.state.prelimresultdata}
                                onChange={(e) => this.setState({ prelimresultdata: e.target.value })}
                                name='relayletter'
                              />
                            </div>
                            <div class='form-group col-md-6'>
                              <label for='inputCity'>Preliminary Place</label>
                              <input
                                class='form-control'
                                value={this.state.prelimplacedata}
                                onChange={(e) => this.setState({ prelimplacedata: e.target.value })}
                                name='relayletter'
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group col-md-6'>
                              <label for='inputCity'>Final Result</label>
                              <input
                                class='form-control'
                                value={this.state.finalresultdata}
                                onChange={(e) => this.setState({ finalresultdata: e.target.value })}
                                name='relayletter'
                              />
                            </div>
                            <div class='form-group col-md-6'>
                              <label for='inputCity'>Final Place</label>
                              <input
                                class='form-control'
                                value={this.state.finalplacedata}
                                onChange={(e) => this.setState({ finalplacedata: e.target.value })}
                                name='relayletter'
                              />
                            </div>
                          </div>

                          <center>
                            <button type='button' class='btn btn-danger' onClick={this.updateAfterSort}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
  }
}

export default Airgunpitentry;
