import React from 'react';

import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';
import { CountryDropdown } from 'react-country-region-selector';
import Swal from 'sweetalert2';

import { Select, Input } from 'antd';
import 'antd/dist/antd.css';

import 'datatables.net';
import 'datatables.net-bs4';
import { Empty } from 'antd';

import Loader from 'react-loader-spinner';
const { Option } = Select;
const url = window.globalConfig;
class LocalCommit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItems: [],
      eventnames: [],
      eventids: [],
      country: '',
      options: this.options,
      meetdatas: [{}],
      meethome: true,
      viewmeet: false,
      file: null,
      meetspecfic: [{}],
      eventsponser: false,
      lorg: false,
      venueview: false,
      venues: [{}],
      shortname: '',
      meetcity: '',
      meetcountry: '',
      meetdirector: '',
      meetstartdate: '',
      meetenddate: '',
      eshortname: '',
      emeetcity: '',
      emeetcountry: '',
      emeetdirector: '',
      emeetstartdate: '',
      emeetenddate: '',
      orgname: 'sams',
      sponsername: '',
      meetsponser: [{}],
      editsponserdata: '',
      sid: '',
      specficmeet: [{}],
      ids: '',
      localcomiteename: '',
      localcomitedetail: [{}],
      editcomiteedata: '',
      sid1: '',
      editmeetdatas: [{}],
      eids: '',
      vdrop1: '',
      eventdropdown: [{}],
      newevents: [{}],
      managervenues: [{}],
      mids: '',
      btncheck: true,
      edituser: false,
      logo: '',
      areg: false,
      aresult: false,
      venueclasses: [{}],
      venueclassid: [{}],
      check: false,
      value: '',
      loader2: false,
      meetspecfic: [{}],
      preloader: true,
      contact: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    //this.localorg = this.localorg.bind(this);

    this.handleChange1 = this.handleChange1.bind(this);

    this.createscomitte = this.createscomitte.bind(this);
    this.updatecomitee = this.updatecomitee.bind(this);
    this.deletecomitee = this.deletecomitee.bind(this);

    this.back1 = this.back1.bind(this);
    this.selectCountry = this.selectCountry.bind(this);

    this.handleChangeChk = this.handleChangeChk.bind(this);
  }

  // add venues handle change
  handleChange2(selectedItems) {
    var self = this;
    self.setState({
      selectedItems,
    });
  }
  // add venues handle change

  componentWillMount() {
    let ids = sessionStorage.getItem('ids');
    if (!this.state.loader2) {
      var self = this;
      axios
        .get(`${url.url}/api/getcommite/${ids}`, {})
        .then(function (response) {
          self.setState({
            localcomitedetail: response.data,
            mname: response.data[0].meet_name,
          });
          self.setState({ loader2: true });
        })
        .catch(function (error) {
          self.setState({
            localcomitedetail: [{}],
          });
        });
    }
  }

  componentDidMount() {
    var self = this;
    let ids = sessionStorage.getItem('ids');
    axios
      .get(`${url.url}/api/getcommite/${ids}`, {})
      .then(function (response) {
        self.setState({
          localcomitedetail: response.data,
          mname: response.data[0].meet_name,
          preloader: false,
        });
        self.setState({ loader2: true });
      })
      .catch(function (error) {
        self.setState({
          localcomitedetail: [{}],
          preloader: false,
        });
      });

    var self = this;
    axios.get(`${url.url}/api/meets`, {}).then(function (response) {
      self.setState({
        meetdatas: response.data.data,
      });
    });

    var hidenav = document.getElementById('eventset');
    hidenav.style.display = 'block';

    //Hide and show manager
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    // document.getElementById("venueesss").style.display = "block";
  }

  onSearch = (e) => {
    var value = e.target.value.toLowerCase();
    $('#myTable tr').filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
  };

  handleChange(event) {
    var self = this;
    var file = event.target.files[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      self.setState({
        logo: reader.result,
      });

      var data = reader.result.split(',')[1];
    };
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
    });
    reader.readAsDataURL(file);
  }

  handleChange1(e) {
    var self = this;
    self.setState({
      [e.target.name]: e.target.value,
    });
  }
  // pages =------------------------------------------------------------------
  // venues create delete edit start---------------------------------------------------------------------------

  handleChangeChk = (e) => {
    // e.preventDefault();
    var test = e.target.value;
    this.setState({
      value: e.target.value,
      check: !this.state.check,
    });

    if ($(e.target).prop('checked')) {
      $('#' + e.target.value).css('display', 'block');
    } else {
      $('#' + e.target.value).css('display', 'none');
    }
  };

  // comitee ----------------------------------------------------------------------
  createscomitte() {
    let e = sessionStorage.getItem('e');
    const datatosend = {
      meet_name: this.state.meetdatas[e].name,
      meet_id: this.state.meetdatas[e].id,
      created_by: this.state.meetdatas[e].created_by,
      localorg_name: this.state.localcomiteename,
      contact: this.state.contact,
    };
    axios
      .post(`${url.url}/api/createcommite/`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'Created' || response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'Localcommittee Created successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong Please check the fields',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  editcomitee(e) {
    var self = this;
    self.setState({
      editcomiteedata: this.state.localcomitedetail[e].localorg_name,
      contact: this.state.localcomitedetail[e].contact,
      sid1: parseInt(this.state.localcomitedetail[e].id),
    });
  }

  updatecomitee(e) {
    e.preventDefault();
    const datatosend = {
      localorg_name: this.state.editcomiteedata,
      contact: this.state.contact,
    };

    axios
      .put(`${url.url}/api/updatecomitee/${this.state.sid1}`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Localcommittee Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  deletecomitee(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deletecomitee/${this.state.localcomitedetail[e].id}`, {})
          .then(function (response) {})

          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });
  }

  back1() {
    //sessionStorage.setItem("path","/meetviewmanager");
    // window.location.replace("/meetviewmanager");
    window.location.replace('/meetviewmanager');
    // window.location.reload();
    sessionStorage.removeItem('curnavitem');
  }

  selectCountry(val) {
    this.setState({ country: val });
  }

  // comitee ---------------------------------------------------------------------
  render() {
    //sessionStorage.setItem("path","/localcommittee");
    return (
      <>
        {this.state.preloader ? (
          <>
            <div className='svg-wrapper'>
              <Loader
                type='ThreeDots'
                color='#00BFFF'
                height={70}
                width={70}
                // timeout={3000} //3 secs
              />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div className='content'>
              {/* <h2>{this.state.mname}</h2> */}
              <Row>
                <Col md='8'>
                  <button
                    type='button'
                    data-toggle='modal'
                    data-target='#newmeet'
                    class='btn btn-danger btn-round'
                    onClick={this.back1}
                  >
                    Back
                  </button>
                  {''}
                  <button type='button' data-toggle='modal' data-target='#newcomitte' class='btn btn-danger btn-round'>
                    Add Organizing Committee
                  </button>
                  <Card>
                    <CardHeader>
                      <h5 className='title'>Local Organizing Committee</h5>
                    </CardHeader>

                    <CardBody>
                      <Form>
                        <div class='form-group row'>
                          <div class='col-xs-5'>
                            <CardHeader>
                              <p>Search</p>
                              <input
                                class='form-control'
                                id='inputsm'
                                onChange={(e) => this.onSearch(e)}
                                placeholder='Search..'
                                type='text'
                              />
                            </CardHeader>
                          </div>
                        </div>

                        {/* table --------------------------------------------------- */}
                        {Object.keys(this.state.localcomitedetail[0]).length == 0 ? (
                          <Empty />
                        ) : (
                          <>
                            <Table id='my' responsive>
                              <thead>
                                <tr>
                                  <th className='text-center'>#</th>

                                  <th>Local Comittee</th>
                                  <th>Contact # or Email</th>
                                  <th class='disabled-sorting text-right'>Actions</th>
                                </tr>
                              </thead>
                              <tbody id='myTable'>
                                {this.state.localcomitedetail.map((question, index) => (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{question.localorg_name}</td>
                                    <td>{question.contact}</td>
                                    <td class='td-actions text-right'>
                                      <button
                                        type='button'
                                        rel='tooltip'
                                        class='btn btn-success btn-sm btn-icon'
                                        onClick={() => this.editcomitee(index)}
                                        data-toggle='modal'
                                        data-target='#comiteedit'
                                      >
                                        <i class='now-ui-icons ui-2_settings-90'></i>
                                      </button>
                                      {` `}
                                      <button
                                        type='button'
                                        rel='tooltip'
                                        class='btn btn-danger btn-sm btn-icon'
                                        onClick={() => this.deletecomitee(index)}
                                      >
                                        <i class='now-ui-icons ui-1_simple-remove'></i>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </>
                        )}
                        {/* table --------------------------------------------------- */}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* add--------------------------------------------------------- */}

              <div id='newcomitte' class='modal fade' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header'>
                      <button type='button' class='close' data-dismiss='modal'>
                        &times;
                      </button>
                      <h4 class='modal-title'>Add Local Organizing Committee</h4>
                    </div>
                    <div class='modal-body'>
                      <div class='form-row form-committe'>
                        <div class='form-group'>
                          <label for='inputCity'>Committee Name *</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputCity'
                            placeholder='Enter local comittee name'
                            onChange={(e) => this.setState({ localcomiteename: e.target.value })}
                            required
                          />
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group'>
                          <label for='inputCity'>Contact # or Email</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputCity'
                            placeholder='Enter Contact Number'
                            onChange={(e) => this.setState({ contact: e.target.value })}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <center>
                        <button type='button' class='btn btn-danger' onClick={this.createscomitte} data-dismiss='modal'>
                          Save
                        </button>
                      </center>
                    </div>
                  </div>
                </div>
              </div>

              {/* edit------------------------------------------------------- */}

              <div id='comiteedit' class='modal fade' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header'>
                      <button type='button' class='close' data-dismiss='modal'>
                        &times;
                      </button>
                      <h4 class='modal-title'>Edit Local Organizing Committee</h4>
                    </div>
                    <div class='modal-body'>
                      <div class='form-row form-committe'>
                        <div class='form-group'>
                          <label for='inputCity'>Committee name </label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputCity'
                            name='editcomiteedata'
                            value={this.state.editcomiteedata}
                            onChange={this.handleChange1}
                            required
                          />
                        </div>
                      </div>
                      <div class='form-row'>
                        <div class='form-group'>
                          <label for='inputCity'>Contact # or Email</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputCity'
                            placeholder='Enter Contact Number'
                            value={this.state.contact}
                            onChange={(e) => this.setState({ contact: e.target.value })}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <center>
                        <button type='button' class='btn btn-danger' onClick={this.updatecomitee} data-dismiss='modal'>
                          Save
                        </button>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default LocalCommit;
