import React from 'react';

import '../App.css';
import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import { Empty } from 'antd';
import 'datatables.net';
import 'datatables.net-bs4';
import Loader from 'react-loader-spinner';
var x;
var y;
var arr = [];
const url = window.globalConfig;
function removeLeadingZeroes(timeString) {
  const data = timeString && timeString.replace(/^0+:/, '');
  const data1 = data && data.replace(/^0+:/, '');
  return data1 && data1.replace(/^0+/, '').replace(/^\./, '');
}
class Swimprelimresult extends React.Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    this.state = {
      event: queryParams.get('event').split('_')[0],
      heat: queryParams.get('heat'),
      event_data: [{}],
      getrelayteamsname: [],
      showAlldata: [{}],
      allRelay: false,
      // relay_event_data:[{}],
      relay: false,
      notrelay: false,
      windfinaldata: '',
      heatfinaldata: '',
      lanefinaldata: '',
      breakdata: '',
      recorddata: '',
      nationaldata: '',
      americasdata: '',
      americandata: '',
      njdcdata: '',
      trackdatas: [{}],
      prelimresultdata: '',
      prelimplacedata: '',
      finalplacedata: '',
      finalresultdata: '',
      norelayfinalplacedata: '',
      norelayfinalresultdata: '',
      norelayprelimplacedata: '',
      norelarprelimresultdata: '',
      qualifiesdata: '',
      relayteamname: '',
      relayletter_: '',
      loader: true,
      meetdatas: [{}],
      swimsetdata: [{}],
      swimsetrecord: '',
      performancedatas: [{}],
      swimatfusasamsnjdcdatas: [{}],
      swimnjdcdatas: [{}],
      sortgendernamedatas: [{}],
      relaypointsortgenderdatas: [],
      issortgendername: false,
      isrelaysortgendername: false,
    };
    this.back = this.back.bind(this);
    this.edit = this.edit.bind(this);
    this.update = this.update.bind(this);
    this.teampoints = this.teampoints.bind(this);
  }
  async componentDidMount() {
    var self = this;
    var count = 0;
    const data = {
      selected_events: this.state.event,
      heat: this.state.heat,
      meet_id: sessionStorage.getItem('ids'),
    };

    await axios
      .post(`${url.url}/api/getrelayteamname/`, data, {})
      .then(function (response) {
        self.setState({ event_data: response.data });
        count++;
        if (count == 2) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    document.getElementById('homelink').style.display = 'block';

    await axios
      .post(`${url.url}/api/getrelaysingleteamname/`, data, {})
      .then(function (response) {
        self.setState({
          getrelayteamsname: response.data,
          loader: false,
        });
      })
      .catch(function (error) {
        self.setState({ loader: false });
      });

    axios
      .get(`${url.url}/api/getmeetdetail/${sessionStorage.getItem('ids')}`, {})
      .then(function (response) {
        self.setState({
          meetdatas: response.data,
        });
        count++;
        if (count == 2) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {});

    axios
      .get(`${url.url}/api/getallswimlocationset/`, {})
      .then(function (response) {
        self.setState({
          swimsetdata: response.data,
          swimsetrecord: response.data[0].current_record,
        });
      })
      .catch(function (error) {});

    //swim njdc record
    const getdata = {
      sport: 'Swimming',
      event: this.state.event,
      class: this.state.event_data[0].swim_class_value,
      AG: this.state.event_data[0].age_division,
      sex: this.state.event_data[0].gender,
    };
    await axios
      .post(`${url.url}/api/getswimnjdcvalues/`, getdata, {})
      .then(function (response) {
        self.setState({ swimnjdcdatas: response.data.data });
      })
      .catch(function (error) {});

    //swim atfusasams record
    const getdatas = {
      sport: 'Swimming',
      event: this.state.event,
      class: this.state.event_data[0].swim_class_value,
      AG: this.state.event_data[0].age_division,
      sex: this.state.event_data[0].gender,
    };
    await axios
      .post(`${url.url}/api/getswimfusasamsrecordvalues/`, getdatas, {})
      .then(function (response) {
        self.setState({ swimatfusasamsnjdcdatas: response.data.data });
      })
      .catch(function (error) {});

    //Performance Record Data
    const performancerecorddata = {
      Sport_Venue: 'Swimming',
      Event: this.state.event,
      Classification: this.state.event_data[0].track_class_value,
      Age_Group: this.state.event_data[0].age_division,
      Gender: this.state.event_data[0].gender,
    };
    await axios
      .post(`${url.url}/api/getperformancerecord/`, performancerecorddata, {})
      .then(function (response) {
        self.setState({ performancedatas: response.data.data });
      })
      .catch(function (error) {});
  }
  back() {
    window.location.replace('/venuemainpage?event=' + sessionStorage.getItem('event'));
  }
  backtoswim = () => {
    this.setState({
      allRelay: false,
      loader: false,
      notrelay: false,
      isrelaysortgendername: false,
      issortgendername: false,
      relay: true,
    });
  };

  relayedit = (e) => {
    var self = this;
    self.setState({
      eventdatas: this.state.getrelayteamsname[e][0],
      prelimresultdata:
        this.state.getrelayteamsname[e][0].prelim_result == null
          ? ''
          : this.state.getrelayteamsname[e][0].prelim_result,
      prelimplacedata:
        this.state.getrelayteamsname[e][0].prelim_place == null ? '' : this.state.getrelayteamsname[e][0].prelim_place,
      relayteamname: this.state.getrelayteamsname[e][0].realy_team_name,
      relayletter_: this.state.getrelayteamsname[e][0].relay_letter,
    });
  };
  aftersort1edit = (e) => {
    var self = this;
    self.setState({
      eventdatas: this.state.relaypointsortgenderdatas[e][0],
      prelimresultdata:
        this.state.relaypointsortgenderdatas[e][0].prelim_result == null
          ? ''
          : this.state.relaypointsortgenderdatas[e][0].prelim_result,
      prelimplacedata:
        this.state.relaypointsortgenderdatas[e][0].prelim_place == null
          ? ''
          : this.state.relaypointsortgenderdatas[e][0].prelim_place,
      relayteamname:
        this.state.relaypointsortgenderdatas[e][0].realy_team_name == null
          ? ''
          : this.state.relaypointsortgenderdatas[e][0].realy_team_name,
      relayletter_: this.state.relaypointsortgenderdatas[e][0].relay_letter,
    });
  };
  edit(e) {
    var self = this;
    self.setState({
      eventdatas: this.state.event_data[e],
      prelimresultdata: this.state.event_data[e].prelim_result,
      prelimplacedata: this.state.event_data[e].prelim_place,
      finalresultdata: this.state.event_data[e].final_result,
      finalplacedata: this.state.event_data[e].final_place,
      heatfinaldata: this.state.event_data[e].heatf,
      lanefinaldata: this.state.event_data[e].lanef,
      relayteamname: this.state.event_data[e].realy_team_name,
      relayletter_: this.state.event_data[e].relay_letter,
    });
  }
  relaysortAfterEdit = (e) => {
    var found = this.state.relaysortgendernamedatas.find((question) => question.id == e);

    this.setState({
      eventdatas: found,
      prelimresultdata: found.prelim_result,
      prelimplacedata: found.prelim_place,
      finalresultdata: found.final_result,
      finalplacedata: found.final_place,
      heatfinaldata: found.heatf,
      lanefinaldata: found.lanef,
    });
  };
  sortAfterEdit = (e) => {
    var found = this.state.sortgendernamedatas.find((question) => question.id == e);
    this.setState({
      eventdatas: found,
      prelimresultdata: found.prelim_result,
      prelimplacedata: found.prelim_place,
      finalresultdata: found.final_result,
      finalplacedata: found.final_place,
      heatfinaldata: found.heatf,
      lanefinaldata: found.lanef,
    });
  };

  relayupdate = (e) => {
    const datatosend = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
      prelim_result: this.state.prelimresultdata,
      prelim_place: this.state.prelimplacedata,
      realy_team_name: this.state.relayteamname,
      relay_letter: this.state.relayletter_,
    };
    axios
      .post(`${url.url}/api/updateallswimprelimsetupresult`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  };

  update(e) {
    const datatosend = {
      prelim_result: this.state.prelimresultdata,
      prelim_place: this.state.prelimplacedata,
      final_result: this.state.finalresultdata,
      final_place: this.state.finalplacedata,
      heatf: this.state.heatfinaldata,
      lanef: this.state.lanefinaldata,
    };
    axios
      .put(`${url.url}/api/updateswimprelimresult/${this.state.eventdatas.id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  teampoints = (e) => {
    if (e == 1) {
      return <td>{this.state.meetdatas[0].place1}</td>;
    } else if (e == 2) {
      return <td>{this.state.meetdatas[0].place2} </td>;
    } else if (e == 3) {
      return <td>{this.state.meetdatas[0].place3} </td>;
    } else if (e == 4) {
      return <td>{this.state.meetdatas[0].place4} </td>;
    } else if (e == 5) {
      return <td>{this.state.meetdatas[0].place5} </td>;
    } else if (e == 6) {
      return <td>{this.state.meetdatas[0].place6} </td>;
    } else if (e == 7) {
      return <td>{this.state.meetdatas[0].place7} </td>;
    } else if (e == 8) {
      return <td>{this.state.meetdatas[0].place8} </td>;
    } else if (e == 9) {
      return <td>{this.state.meetdatas[0].place9} </td>;
    } else {
      return <td>{this.state.meetdatas[0].place10} </td>;
    }
  };
  isrelaysortbygendername = () => {
    var self = this;
    const sortdata = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    axios.post(`${url.url}/api/getrelayprintswimprelimworksheet/`, sortdata, {}).then(function (response) {
      self.setState({
        relaypointsortgenderdatas: response.data,
        loader: false,
        notrelay: false,
        isrelaysortgendername: true,
        issortgendername: false,
        relay: false,
      });
    });
  };
  issortbygendername = () => {
    var self = this;
    const sortdata = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    axios.post(`${url.url}/api/getprintswimprelimworksheet/`, sortdata, {}).then(function (response) {
      self.setState({
        sortgendernamedatas: response.data,
        loader: false,
        notrelay: false,
        isrelaysortgendername: false,
        issortgendername: true,
        relay: false,
      });
    });
  };
  viewAll = (e) => {
    var self = this;
    const datas = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
      realy_team_name: e.split('_')[0],
      relay_letter: e.split('_')[1],
    };
    axios
      .post(`${url.url}/api/getrelayswimevent/`, datas, {})
      .then(function (response) {
        self.setState({
          showAlldata: response.data,
          allRelay: true,
          loader: false,
          notrelay: false,
          isrelaysortgendername: false,
          issortgendername: false,
          relay: false,
        });
      })
      .catch(function (error) {
        self.setState({ loader: false });
      });
  };
  relaypoints = (e, pts) => {
    var self = this;
    var relay_points = 0;

    Object.keys(this.state.showAlldata).map(function (key) {
      if (key == e) {
        self.state.showAlldata[e].map((question, index) => {
          relay_points = relay_points + parseInt(question.relay_points);
        });
      }
    });

    return (
      <p class='total_points'>
        {relay_points > pts ? relay_points + ' ' + 'Relay Points Total > Relay Points ' : relay_points}
      </p>
    );
  };
  onSearch = (e) => {
    var value = e.target.value.toLowerCase();
    $('#myTable tr').filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
    this.setState({
      notrelay: true,
    });
  };
  render() {
    var self = this;
    if (this.state.relay) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div>
                  <h1> </h1>
                  <Row>
                    <Col>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                        Back
                      </button>
                      <Card>
                        <CardHeader>
                          <h5 className='title'>
                            {this.state.event} <br></br> Preliminary Results
                          </h5>
                        </CardHeader>
                        <CardBody>
                          <div class='form-group row printrow'>
                            <div class='col-xs-5'>
                              <CardHeader>
                                <div>
                                  <p>Search</p>
                                  <input
                                    class='form-control'
                                    id='inputsm'
                                    onChange={(e) => this.onSearch(e)}
                                    placeholder='Search..'
                                    type='text'
                                  />
                                </div>
                                <center>
                                  <button
                                    type='button'
                                    class='btn btn-success printbtn'
                                    onClick={this.isrelaysortbygendername}
                                  >
                                    Relay-Check Placing Finals
                                  </button>
                                  <label style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '10px' }}>
                                    Current Record :
                                  </label>
                                  <span>{this.state.swimsetrecord}</span>
                                </center>
                              </CardHeader>
                            </div>
                          </div>
                          <Form>
                            <>
                              <table class='worksheet_table'>
                                <thead>
                                  <tr class='worksheet_tr'>
                                    <th>Heat </th>
                                    <th>Lane </th>
                                    <th>Prelim Results</th>
                                    <th>Prelim Place</th>
                                    <th>Final Results</th>
                                    <th>Final Place</th>

                                    <th>Record</th>
                                    <th>Break Record</th>

                                    <th>Relay Points</th>
                                    <th>Relay gender</th>
                                    <th>Relay Team Name</th>
                                    <th>EventName</th>
                                    <th>Points</th>

                                    {/* <th>Event</th> */}
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody id='myTable'>
                                  <>
                                    {Object.keys(self.state.getrelayteamsname).map(function (key) {
                                      return (
                                        <>
                                          {self.state.getrelayteamsname[key].map((question, index) => {
                                            return (
                                              <>
                                                <tr class='worksheet_tr'>
                                                  <td>{question.heat}</td>
                                                  <td>{question.lane}</td>
                                                  <td>{question.prelim_result}</td>
                                                  <td>{question.prelim_place}</td>
                                                  <td>
                                                    {question.final_place
                                                      ? question.final_place
                                                      : question.prelim_place}
                                                  </td>
                                                  <td>
                                                    {question.final_result
                                                      ? question.final_result
                                                      : question.prelim_result}
                                                  </td>

                                                  <td>{question.result}</td>
                                                  <td>
                                                    {question.final_result <= '20.6'
                                                      ? 'Yes'
                                                      : question.result == null
                                                      ? 'NRF'
                                                      : 'No'}
                                                  </td>

                                                  <td>{question.points}</td>
                                                  <td>{question.gender1}</td>
                                                  <td>{question.realy_team_name + ' ' + question.relay_letter}</td>
                                                  <td>{question.selected_events}</td>
                                                  {/* <td>{ }</td> */}
                                                  {self.teampoints(
                                                    question.final_place ? question.final_place : question.prelim_place
                                                  )}

                                                  {/* <td>{ }</td> */}
                                                  <td>
                                                    <button
                                                      type='button'
                                                      rel='tooltip'
                                                      class='btn btn-success btn-sm btn-icon'
                                                      onClick={(e) => self.relayedit(key)}
                                                      data-toggle='modal'
                                                      data-target='#useredit'
                                                    >
                                                      <i class='now-ui-icons ui-2_settings-90'></i>
                                                    </button>
                                                    <button
                                                      type='button'
                                                      class='btn btn-success printbtn'
                                                      onClick={(e) =>
                                                        self.viewAll(
                                                          question.realy_team_name + '_' + question.relay_letter
                                                        )
                                                      }
                                                    >
                                                      View Team
                                                    </button>
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          })}
                                        </>
                                      );
                                    })}
                                  </>
                                </tbody>
                              </table>
                            </>
                            {/* )} */}
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Preliminary Results
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-row'>
                            <div class='form-group'>
                              <label for='inputCity'>Prelim Result</label>
                              <input
                                class='form-control'
                                value={this.state.prelimresultdata}
                                onChange={(e) =>
                                  this.setState({
                                    prelimresultdata: e.target.value,
                                  })
                                }
                                name='relayletter'
                              />
                            </div>
                          </div>
                          <div class='form-row'>
                            <div class='form-group'>
                              <label for='inputCity'>Prelim Place</label>
                              <input
                                class='form-control'
                                value={this.state.prelimplacedata}
                                onChange={(e) =>
                                  this.setState({
                                    prelimplacedata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>
                          <center>
                            <button type='button' class='btn btn-danger' onClick={(e) => this.relayupdate(e)}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (this.state.isrelaysortgendername) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div>
                  <h1> </h1>
                  <Row>
                    <Col>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                        Back
                      </button>
                      <Card className='scrollcard'>
                        <CardHeader>
                          <h5 className='title'>
                            {this.state.event} <br></br> Preliminary Results
                          </h5>
                        </CardHeader>
                        <CardBody>
                          <div class='form-group row printrow'>
                            <div class='col-xs-5'>
                              <CardHeader>
                                <div>
                                  <p>Search</p>
                                  <input
                                    class='form-control'
                                    id='inputsm'
                                    onChange={(e) => this.onSearch(e)}
                                    placeholder='Search..'
                                    type='text'
                                  />
                                </div>
                                <center>
                                  <button
                                    type='button'
                                    class='btn btn-success printbtn'
                                    onClick={this.isrelaysortbygendername}
                                  >
                                    Relay-Check Placing Finals
                                  </button>
                                  <label style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '10px' }}>
                                    Current Record :
                                  </label>
                                  <span>{this.state.swimsetrecord}</span>
                                </center>
                              </CardHeader>
                            </div>
                          </div>
                          <Form>
                            <>
                              <table class='worksheet_table'>
                                <thead>
                                  <tr class='worksheet_tr'>
                                    <th>Heat </th>
                                    <th>Lane </th>
                                    <th>Prelim Results</th>
                                    <th>Prelim Place</th>
                                    <th>Final Results</th>
                                    <th>Final Place</th>
                                    <th>Record</th>
                                    <th>Break Record</th>
                                    <th>Relay Points</th>
                                    <th>Relay gender</th>
                                    <th>Relay Team Name</th>
                                    <th>EventName</th>
                                    <th>Points</th>
                                    {/* <th>Event</th> */}
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody id='myTable'>
                                  <>
                                    {Object.keys(self.state.relaypointsortgenderdatas).map(function (key) {
                                      return (
                                        <>
                                          {self.state.relaypointsortgenderdatas[key].map((question, index) => {
                                            return (
                                              <>
                                                <tr class='worksheet_tr'>
                                                  <td>{question.heat}</td>
                                                  <td>{question.lane}</td>
                                                  <td>{question.prelim_result}</td>
                                                  <td>{question.prelim_place}</td>
                                                  <td>
                                                    {question.final_place
                                                      ? question.final_place
                                                      : question.prelim_place}
                                                  </td>
                                                  <td>
                                                    {question.final_result
                                                      ? question.final_result
                                                      : question.prelim_result}
                                                  </td>

                                                  <td>{question.result}</td>
                                                  <td>
                                                    {question.final_result <= '20.6'
                                                      ? 'Yes'
                                                      : question.result == null
                                                      ? 'NRF'
                                                      : 'No'}
                                                  </td>

                                                  <td>{question.points}</td>
                                                  <td>{question.gender1}</td>
                                                  <td>{question.realy_team_name + ' ' + question.relay_letter}</td>
                                                  <td>{question.selected_events}</td>
                                                  {/* <td>{ }</td> */}
                                                  {this.teampoints(
                                                    question.final_place ? question.final_place : question.prelim_place
                                                  )}

                                                  {/* <td>{ }</td> */}
                                                  <td>
                                                    <button
                                                      type='button'
                                                      rel='tooltip'
                                                      class='btn btn-success btn-sm btn-icon'
                                                      onClick={(e) => self.aftersort1edit(key)}
                                                      data-toggle='modal'
                                                      data-target='#useredit'
                                                    >
                                                      <i class='now-ui-icons ui-2_settings-90'></i>
                                                    </button>
                                                    <button
                                                      type='button'
                                                      class='btn btn-success printbtn'
                                                      onClick={(e) =>
                                                        self.viewAll(
                                                          question.realy_team_name + '_' + question.relay_letter
                                                        )
                                                      }
                                                    >
                                                      View Team
                                                    </button>
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          })}
                                        </>
                                      );
                                    })}
                                  </>
                                </tbody>
                              </table>
                            </>
                            {/* )} */}
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Preliminary Results
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-row'>
                            <div class='form-group'>
                              <label for='inputCity'>Prelim Result</label>
                              <input
                                class='form-control'
                                value={this.state.prelimresultdata}
                                onChange={(e) =>
                                  this.setState({
                                    prelimresultdata: e.target.value,
                                  })
                                }
                                name='relayletter'
                              />
                            </div>
                          </div>
                          <div class='form-row'>
                            <div class='form-group'>
                              <label for='inputCity'>Prelim Place</label>
                              <input
                                class='form-control'
                                value={this.state.prelimplacedata}
                                onChange={(e) =>
                                  this.setState({
                                    prelimplacedata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>
                          <center>
                            <button type='button' class='btn btn-danger' onClick={(e) => this.relayupdate(e)}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (this.state.allRelay) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div>
                  <h1> </h1>
                  <Row>
                    <Col>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.backtoswim}>
                        Back
                      </button>
                      <Card className='scrollcard'>
                        <CardHeader>
                          <h5 className='title'>
                            {this.state.event} <br></br> Relay Setup
                          </h5>
                        </CardHeader>
                        <CardBody>
                          <Form>
                            {Object.keys(this.state.showAlldata).map(function (key) {
                              x = key.split('_');
                              return (
                                <CardBody>
                                  <div class='realy_main'>
                                    <div>
                                      <p>
                                        <label class='relay_label'>Points</label>
                                        {x[2]}
                                      </p>
                                      <p>
                                        <label class='relay_label'>Gender</label>
                                        {x[3]}
                                      </p>
                                      <p>
                                        <label class='relay_label'>Team Name</label>
                                        {x[0] + ' ' + x[1]}
                                      </p>
                                      <p>
                                        <label class='relay_label'>Run As Finals</label>
                                        {self.state.showAlldata[key][0].run_during_final}
                                      </p>
                                    </div>
                                    <div class='realy_main1'>
                                      <label class='relay_label'>Relay Team Members</label>

                                      {self.state.showAlldata[key][0] ? (
                                        <p>
                                          {self.state.showAlldata[key][0].first_name}{' '}
                                          {self.state.showAlldata[key][0].last_name}
                                        </p>
                                      ) : (
                                        <p style={{ color: 'red' }}>Missing Relay Team Members</p>
                                      )}
                                      {self.state.showAlldata[key][1] ? (
                                        <p>
                                          {self.state.showAlldata[key][1].first_name}{' '}
                                          {self.state.showAlldata[key][1].last_name}
                                        </p>
                                      ) : (
                                        <p style={{ color: 'red' }}>Missing Relay Team Members</p>
                                      )}
                                      {self.state.showAlldata[key][2] ? (
                                        <p>
                                          {self.state.showAlldata[key][2].first_name}{' '}
                                          {self.state.showAlldata[key][2].last_name}
                                        </p>
                                      ) : (
                                        <p style={{ color: 'red' }}>Missing Relay Team Members</p>
                                      )}
                                      {self.state.showAlldata[key][3] ? (
                                        <p>
                                          {self.state.showAlldata[key][3].first_name}{' '}
                                          {self.state.showAlldata[key][3].last_name}
                                        </p>
                                      ) : (
                                        <p style={{ color: 'red' }}>Missing Relay Team Members</p>
                                      )}
                                    </div>
                                    <div class='realy_main1'>
                                      <label class='relay_label'>Relay points</label>
                                      {self.state.showAlldata[key].map((question, index) => (
                                        <p>{question.relay_points}</p>
                                      ))}

                                      {self.relaypoints(key, x[2])}
                                    </div>
                                    <div class='realy_main1'>
                                      <label class='relay_label'>Class</label>
                                      {self.state.showAlldata[key].map((question, index) => (
                                        <p>{question.swim_class_value}</p>
                                      ))}
                                    </div>
                                  </div>
                                </CardBody>
                              );
                            })}
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (this.state.notrelay) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div>
                  <h1> </h1>
                  <Row>
                    <Col>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                        Back
                      </button>
                      <Card className='scrollcard'>
                        <CardHeader>
                          <h5 className='title'>
                            {this.state.event} <br></br> Preliminary Results
                          </h5>
                        </CardHeader>
                        <CardBody>
                          <div class='form-group row printrow'>
                            <div class='col-xs-5'>
                              <CardHeader>
                                <div>
                                  <p>Search</p>
                                  <input
                                    class='form-control'
                                    id='inputsm'
                                    onChange={(e) => this.onSearch(e)}
                                    placeholder='Search..'
                                    type='text'
                                  />
                                </div>

                                <center>
                                  <button
                                    type='button'
                                    class='btn btn-success printbtn'
                                    onClick={this.issortbygendername}
                                  >
                                    Check Placing Finals
                                  </button>
                                  <label style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '10px' }}>
                                    Current Record :
                                  </label>
                                  <span>{this.state.swimsetrecord}</span>
                                </center>
                              </CardHeader>
                            </div>
                          </div>
                          <Form>
                            {Object.keys(this.state.event_data).length == 0 ? (
                              <Empty />
                            ) : (
                              <>
                                <table class='worksheet_table'>
                                  <thead>
                                    <tr class='worksheet_tr'>
                                      <th>Heat </th>
                                      <th>Lane </th>
                                      <th>Prelim Results</th>
                                      <th>Prelim Place</th>
                                      <th>Final Results</th>
                                      <th>Final Place</th>
                                      <th>Bib</th>
                                      <th>Last Name</th>
                                      <th>First Name</th>
                                      <th>Class</th>
                                      <th>Age</th>
                                      <th>Gender</th>
                                      <th>Qualifies</th>
                                      <th>Qual Stand</th>
                                      <th>Record</th>
                                      <th>Break Record</th>
                                      <th>EventName</th>
                                      <th>Points</th>

                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody id='myTable'>
                                    {this.state.event_data.map((question, index) => (
                                      <tr class='worksheet_tr'>
                                        <td>{question.heat}</td>
                                        <td>{question.lane}</td>
                                        <td>{question.prelim_result}</td>
                                        <td>{question.prelim_place}</td>
                                        <td>
                                          {question.final_result ? question.final_result : question.prelim_result}
                                        </td>
                                        <td>{question.final_place ? question.final_place : question.prelim_place}</td>
                                        <td>{question.bib_no}</td>
                                        <td>{question.last_name}</td>
                                        <td>{question.first_name}</td>
                                        <td>
                                          {this.state.event === '25 Yd Breast' ||
                                          this.state.event === '50 Yd Breast' ||
                                          this.state.event === '100 Yd Breast' ||
                                          this.state.event === '200 Yd Breast' ||
                                          this.state.event === '50 M Breast' ||
                                          this.state.event === '100 M Breast' ||
                                          this.state.event === '200 M Breast '
                                            ? question.swim_breast_class
                                            : this.state.event === '3 X 50 M IM' ||
                                              this.state.event === '25 Yd IM' ||
                                              this.state.event === '3 X 50 Yd IM' ||
                                              this.state.event === '4 X 25 Yd IM' ||
                                              this.state.event === '4 X 100 Yd IM' ||
                                              this.state.event === '3 X 50 M IM' ||
                                              this.state.event === '4 X 50 M IM' ||
                                              this.state.event === '4 X 100 M IM'
                                            ? question.swim_medley_class
                                            : question.swim_class_value}
                                        </td>
                                        <td>{question.age_division}</td>
                                        <td>{question.gender}</td>
                                        <td>
                                          {!question.qualifying_standard
                                            ? 'NRF'
                                            : (Number(question.final_result) || Number(question.prelim_result)) <=
                                              Number(removeLeadingZeroes(question.qualifying_standard))
                                            ? 'Yes'
                                            : 'No'}
                                        </td>
                                        <td>{removeLeadingZeroes(question.qualifying_standard)}</td>
                                        <td>{removeLeadingZeroes(question.result)}</td>
                                        <td>
                                          {!question.result
                                            ? 'NRF'
                                            : (Number(question.final_results) || Number(question.prelim_result)) <=
                                              Number(removeLeadingZeroes(question.result))
                                            ? 'Yes'
                                            : 'No'}
                                        </td>
                                        <td>{question.selected_events}</td>
                                        {/* <td>{ }</td> */}
                                        {this.teampoints(
                                          question.final_place ? question.final_place : question.prelim_place
                                        )}

                                        {/* <td>{ }</td> */}
                                        <td>
                                          <button
                                            type='button'
                                            rel='tooltip'
                                            class='btn btn-success btn-sm btn-icon'
                                            onClick={() => this.edit(index)}
                                            data-toggle='modal'
                                            data-target='#useredit'
                                          >
                                            <i class='now-ui-icons ui-2_settings-90'></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </>
                            )}
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Preliminary Results
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-row'>
                            <div class='form-group'>
                              <label for='inputCity'>Prelim Result</label>
                              <input
                                class='form-control'
                                value={this.state.prelimresultdata}
                                onChange={(e) =>
                                  this.setState({
                                    prelimresultdata: e.target.value,
                                  })
                                }
                                name='relayletter'
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group'>
                              <label for='inputCity'>Prelim Place</label>
                              <input
                                class='form-control'
                                value={this.state.prelimplacedata}
                                onChange={(e) =>
                                  this.setState({
                                    prelimplacedata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <center>
                            <button type='button' class='btn btn-danger' onClick={(e) => this.update(e)}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (this.state.issortgendername) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div>
                  <h1> </h1>
                  <Row>
                    <Col>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                        Back
                      </button>
                      <Card className='scrollcard'>
                        <CardHeader>
                          <h5 className='title'>
                            {this.state.event} <br></br> Preliminary Results
                          </h5>
                        </CardHeader>
                        <CardBody>
                          <div class='form-group row printrow'>
                            <div class='col-xs-5'>
                              <CardHeader>
                                <div>
                                  <p>Search</p>
                                  <input
                                    class='form-control'
                                    id='inputsm'
                                    onChange={(e) => this.onSearch(e)}
                                    placeholder='Search..'
                                    type='text'
                                  />
                                </div>
                                <center>
                                  <button
                                    type='button'
                                    class='btn btn-success printbtn'
                                    onClick={this.issortbygendername}
                                  >
                                    Check Placing Finals
                                  </button>
                                  <label style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '10px' }}>
                                    Current Record :
                                  </label>
                                  <span>{this.state.swimsetrecord}</span>
                                </center>
                              </CardHeader>
                            </div>
                          </div>
                          <Form>
                            {Object.keys(this.state.sortgendernamedatas).length == 0 ? (
                              <Empty />
                            ) : (
                              <>
                                <table class='worksheet_table'>
                                  <thead>
                                    <tr class='worksheet_tr'>
                                      <th>Heat </th>
                                      <th>Lane </th>
                                      <th>Prelim Results</th>
                                      <th>Prelim Place</th>
                                      <th>Final Results</th>
                                      <th>Final Place</th>
                                      <th>Bib</th>
                                      <th>Last Name</th>
                                      <th>First Name</th>
                                      <th>Class</th>
                                      <th>Age</th>
                                      <th>Gender</th>
                                      <th>Qualifies</th>
                                      <th>Qual Stand</th>
                                      <th>Record</th>
                                      <th>Break Record</th>
                                      <th>EventName</th>
                                      <th>Points</th>

                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody id='myTable'>
                                    {this.state.sortgendernamedatas.map((question, index) => (
                                      <tr class='worksheet_tr'>
                                        <td>{question.heat}</td>
                                        <td>{question.lane}</td>
                                        <td>{question.prelim_result}</td>
                                        <td>{question.prelim_place}</td>
                                        <td>
                                          {question.final_result ? question.final_result : question.prelim_result}
                                        </td>
                                        <td>{question.final_place ? question.final_place : question.prelim_place}</td>
                                        <td>{question.bib_no}</td>
                                        <td>{question.last_name}</td>
                                        <td>{question.first_name}</td>
                                        <td>
                                          {this.state.event === '25 Yd Breast' ||
                                          this.state.event === '50 Yd Breast' ||
                                          this.state.event === '100 Yd Breast' ||
                                          this.state.event === '200 Yd Breast' ||
                                          this.state.event === '50 M Breast' ||
                                          this.state.event === '100 M Breast' ||
                                          this.state.event === '200 M Breast '
                                            ? question.swim_breast_class
                                            : this.state.event === '3 X 50 M IM' ||
                                              this.state.event === '25 Yd IM' ||
                                              this.state.event === '3 X 50 Yd IM' ||
                                              this.state.event === '4 X 25 Yd IM' ||
                                              this.state.event === '4 X 100 Yd IM' ||
                                              this.state.event === '3 X 50 M IM' ||
                                              this.state.event === '4 X 50 M IM' ||
                                              this.state.event === '4 X 100 M IM'
                                            ? question.swim_medley_class
                                            : question.swim_class_value}
                                        </td>
                                        <td>{question.age_division}</td>
                                        <td>{question.gender}</td>
                                        <td>
                                          {question.qualifying_standard == null || !question.qualifying_standard
                                            ? 'NRF'
                                            : Number(question.final_result) <=
                                              Number(removeLeadingZeroes(question.qualifying_standard))
                                            ? 'Yes'
                                            : 'No'}
                                        </td>
                                        <td>{removeLeadingZeroes(question.qualifying_standard)}</td>
                                        <td>{removeLeadingZeroes(question.result)}</td>
                                        <td>
                                          {(Number(question.final_results) || Number(question.prelim_result)) <=
                                          Number(removeLeadingZeroes(question.result))
                                            ? question.result == null
                                              ? 'NRF'
                                              : 'Yes'
                                            : 'No'}
                                        </td>
                                        <td>{question.selected_events}</td>
                                        {/* <td>{ }</td> */}
                                        {this.teampoints(
                                          question.final_place ? question.final_place : question.prelim_place
                                        )}

                                        {/* <td>{ }</td> */}
                                        <td>
                                          <button
                                            type='button'
                                            rel='tooltip'
                                            class='btn btn-success btn-sm btn-icon'
                                            onClick={() => this.sortAfterEdit(question.id)}
                                            data-toggle='modal'
                                            data-target='#useredit'
                                          >
                                            <i class='now-ui-icons ui-2_settings-90'></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </>
                            )}
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Preliminary Results
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-row'>
                            <div class='form-group'>
                              <label for='inputCity'>Prelim Result</label>
                              <input
                                class='form-control'
                                value={this.state.prelimresultdata}
                                onChange={(e) =>
                                  this.setState({
                                    prelimresultdata: e.target.value,
                                  })
                                }
                                name='relayletter'
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group'>
                              <label for='inputCity'>Prelim Place</label>
                              <input
                                class='form-control'
                                value={this.state.prelimplacedata}
                                onChange={(e) =>
                                  this.setState({
                                    prelimplacedata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <center>
                            <button type='button' class='btn btn-danger' onClick={(e) => this.update(e)}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    return this.state.event == '4 X 25 Yd Medley' ||
      this.state.event == '4 X 25 Yd Free' ||
      this.state.event == '4 X 50 Yd Free' ||
      this.state.event == '4 X 50 Yd Medley' ||
      this.state.event == '4 X 50 M Free' ||
      this.state.event == '4 X 50 M Medley'
      ? (this.state.relay = true)
      : (this.state.notrelay = true);
  }
}

export default Swimprelimresult;
