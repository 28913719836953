import React from 'react';

import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';
import { CountryDropdown } from 'react-country-region-selector';
import Swal from 'sweetalert2';

import { Select, Input } from 'antd';
import 'antd/dist/antd.css';

import 'datatables.net';
import 'datatables.net-bs4';
import Atheletereg from './atheleteregister.js';
import Atheleteresult from './atheleteresult.js';
import Eventsponser from './Eventsponser';
import Atheleteregister from './atheleteregister';
import LocalCommit from './Localcommittee';
import ManagerVenue from './Managervenues';

import Meetviewmanager from './Meetsview';

import Venueset from './Venuesetup.js';
import Loader from 'react-loader-spinner';
import Login from '../login';
import logo from '../logo.png';
import emailjs from 'emailjs-com';
import adaptive from '../adaptive.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDribbble, faFacebook, faTwitter, faWindows } from '@fortawesome/free-brands-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
const { Option } = Select;
var text;
var arry;
const url = window.globalConfig;
class Meetmanager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItems: [],
      eventnames: [],
      eventids: [],
      country: '',
      options: this.options,
      meetdatas: [{}],
      meethome: true,
      viewmeet: false,
      file: null,
      meetspecfic: [{}],
      eventsponser: false,
      lorg: false,
      venueview: false,
      venues: [{}],
      shortname: '',
      meetcity: '',
      meetcountry: '',
      meetdirector: '',
      meetstartdate: '',
      meetenddate: '',
      eshortname: '',
      emeetcity: '',
      emeetcountry: '',
      emeetdirector: '',
      emeetstartdate: '',
      emeetenddate: '',
      orgname: 'sams',
      sponsername: '',
      meetsponser: [{}],
      editsponserdata: '',
      sid: '',
      specficmeet: [{}],
      ids: '',
      localcomiteename: '',
      localcomitedetail: [{}],
      editcomiteedata: '',
      sid1: '',
      editmeetdatas: [{}],
      eids: '',
      vdrop1: '',
      eventdropdown: [{}],
      newevents: [{}],
      managervenues: [{}],
      mids: '',
      btncheck: true,
      edituser: false,
      logo: '',
      areg: false,
      aresult: false,
      venueclasses: [{}],
      venueclassid: [{}],
      check: false,
      value: '',
      agedata: [{}],
      genderdata: [{}],
      pointdata: [{}],
      meetID: [{}],
      active1: false,
      isactive: false,
      meetid: [],
      userdetails: JSON.parse(sessionStorage.getItem('userdetails')),
      loader: true,
      islogin: false,
      managerhome: true,
      username: '',
    };
    this.viewmeets = this.viewmeets.bind(this);
  }

  async componentDidMount() {
    // $(document).ready(function () {
    //   $("#inputsm").on("keyup", function () {
    //     var value = $(this).val().toLowerCase();
    //     $("#myTable tr").filter(function () {
    //       $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    //     });
    //   });
    // });
    var userdetails = sessionStorage.getItem('userdetails');
    // if(this.state.userdetails.holedata.data.user.first_time_user == "true"){
    //   this.setState({
    //     managerhome:false,
    //     islogin: true
    //   })
    //   sessionStorage.removeItem("userdetails");

    // }
    // else {
    //   this.setState({
    //     managerhome: true,
    //     islogin: false
    //   })
    // }
    var self = this;
    await axios
      .get(`${url.url}/api/getuseralldetails/${this.state.userdetails.id}`, {})
      .then(function (response) {
        self.setState({
          username: response.data.data[0].firstname,
        });
      })
      .catch(function (e) {});
    var self = this;
    await axios.get(`${url.url}/api/meets`, {}).then(function (response) {
      self.setState({
        meetdatas: response.data.data,
      });
    });
    const manage = (e) => {
      var joined = e.split('_');
      this.setState({
        meetid: joined,
      });
    };
    axios
      .get(`${url.url}/api/getmanageriddetails/${self.state.userdetails.id}`, {})
      .then(function (response) {
        self.setState({
          datas: response.data.data[0].manager_name,
          loader: false,
        });
        {
          response.data.data.map((ques) => manage(ques.manager_name));
        }
      })
      .catch(function (e) {});
    document.getElementById('titleclass').style.display = 'none';
  }

  onSearch = (e) => {
    var value = e.target.value.toLowerCase();
    $('#myTable tr').filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
  };

  viewmeets(e) {
    var self = this;
    sessionStorage.setItem('ids', this.state.meetdatas[e].id);
    sessionStorage.setItem('e', e);
    sessionStorage.getItem('ids');
    sessionStorage.setItem('spec', JSON.stringify(this.state.meetdatas[e]));
    let j = JSON.parse(sessionStorage.getItem('spec'));
    self.setState({ active1: true });
    self.setState({
      meethome: false,
      viewmeet: true,
    });

    self.setState({
      isactive: !this.state.isactive,
    });
  }
  render() {
    //sessionStorage.setItem("path","/meets");
    const { selectedItems } = this.state;

    const { eventnames } = this.state;
    if (this.state.viewmeet) {
      // return <Redirect to="/meetviewmanager" />;
      window.location.replace('/meetviewmanager');
    }

    if (this.state.managerhome) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div className='content '>
                <Row>
                  <Col md='8'>
                    <Card>
                      <CardHeader>
                        <h5 className='title'>Meets</h5>
                      </CardHeader>

                      <CardBody>
                        <Form>
                          <div class='form-group row'>
                            <div class='col-xs-5'>
                              <CardHeader>
                                <p>Search</p>
                                <input
                                  class='form-control'
                                  id='inputsm'
                                  onChange={(e) => this.onSearch(e)}
                                  placeholder='Search..'
                                  type='text'
                                />
                              </CardHeader>
                            </div>
                          </div>

                          {/* table --------------------------------------------------- */}
                          <Table id='my1' responsive>
                            <thead>
                              <tr>
                                {/* <th className="text-center">#</th> */}
                                <th>Name</th>
                                <th>Location</th>
                                <th>Meet Start Date</th>
                                <th>Meet End Date</th>

                                <th class='disabled-sorting text-right'>Actions</th>
                              </tr>
                            </thead>
                            <tbody id='myTable'>
                              {this.state.meetdatas.map((question, index) =>
                                question.manager == null ? (
                                  ''
                                ) : question.manager.split(',').includes(this.state.username) ? (
                                  <tr>
                                    {/* <td>{index1 + 1}</td> */}
                                    <td>{question.name}</td>
                                    <td>{question.Location}</td>
                                    <td>{question.meet_date}</td>
                                    <td>{question.meet_end_date}</td>
                                    <td class='td-actions text-right'>
                                      <button
                                        type='button'
                                        rel='tooltip'
                                        class='btn btn-success btn-sm btn-icon'
                                        onClick={() => this.viewmeets(index)}
                                        data-toggle='modal'
                                        data-target='#meetedit'
                                      >
                                        <i class='now-ui-icons sport_user-run'></i>
                                      </button>
                                      {` `}
                                    </td>
                                  </tr>
                                ) : (
                                  ''
                                )
                              )}
                            </tbody>
                          </Table>

                          {/* table --------------------------------------------------- */}
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                {/* <Switch>
<Route path="/samsreact/meetviewmanager">
  <Meetviewmanager/>
  </Route>
  </Switch> */}
              </div>
            </>
          )}
        </>
      );
    }
  }
}

export default Meetmanager;
