import React from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';

import Swal from 'sweetalert2';

import { Select, Input } from 'antd';
import 'antd/dist/antd.css';
import { Empty } from 'antd';
import { withRouter } from 'react-router-dom';
const url = window.globalConfig;

class Teammaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [],
      agename: '',
      agedata: [{}],
      // agedata1:[{}],

      ageditdata: [{}],
      splitdata: [],
      cdata: '',
      rerend: false,
      names: [],
      mdata: '',
      ndata: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.agedelete = this.agedelete.bind(this);
    this.ageedit = this.ageedit.bind(this);
    this.handleChangeval = this.handleChangeval.bind(this);
    this.handleSubmittemp = this.handleSubmittemp.bind(this);
  }

  ageedit(e) {
    var self = this;

    self.setState({
      ageditdata: this.state.agedata[e],
      splitdata: this.state.agedata[e].team_value.split(','),
    });
    document.getElementById('myform').reset();
  }

  createUI() {
    return this.state.values.map((el, i) => (
      <div key={i}>
        <input
          type='text'
          class='form-control valueData'
          value={el || ''}
          placeholder='Enter team master..'
          onChange={this.handleChange.bind(this, i)}
        />
        <input type='button' class='btn btn-danger' value='remove' onClick={this.removeClick.bind(this, i)} />
      </div>
    ));
  }

  handleChange = (i, event) => {
    let values = [...this.state.values];

    values[i] = event.target.value;

    this.setState({ values });
  };

  addClick() {
    //console.log("In Add Click");
    this.setState((prevState) => ({ values: [...prevState.values, ''] }));
    //console.log(this.state)
  }

  removeClick(i) {
    let values = [...this.state.values];
    values.splice(i, 1);
    this.setState({ values });
  }
  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    var self = this;
    axios.get(`${url.url}/api/getteamdetails`, {}).then(function (response) {
      self.setState({
        agedata: response.data,
      });
    });
    sessionStorage.removeItem('venueid');
    sessionStorage.removeItem('cvenues');
    sessionStorage.removeItem('classv');
  }

  handleSubmit(event) {
    var self = this;
    event.preventDefault();
    const datatosend = {
      name: this.state.agename,
      team_value: this.state.values.toString(),
      created_by: 1,
    };

    axios
      .post(`${url.url}/api/createteam`, datatosend, {})
      .then(function (response) {
        if (response.status == 201) {
          axios.get(`${url.url}/api/getteamdetails`, {}).then(function (response) {
            self.setState({
              agedata: response.data.data,
            });
          });

          Swal.fire({
            title: 'Success',
            text: 'Teammaster Created successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  handleChangeval(e) {
    //console.log(this.state.splitdata)
    // console.log(e )
    // var self=this
    // self.setState({
    // [e.target.name]: e.target.value
    // })
  }

  agedelete(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deleteteam/${this.state.agedata[e].id}`, {})
          .then(function (response) {})

          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });
  }

  handleSubmittemp(e) {
    var self = this;
    e.preventDefault();

    var newStateArray = [];
    var valueData = $('.myForm .valueData');
    valueData.map(function (index, data) {
      newStateArray.push(data.value);
    });

    const datatosends = {
      name: this.state.mdata,
      team_value: newStateArray.toString(),
    };

    axios.put(`${url.url}/api/updateteam/${this.state.ageditdata.id}`, datatosends, {}).then(function (response) {
      if (response.status == 200) {
        axios.get(`${url.url}/api/getteamdetails`, {}).then(function (response) {
          self.setState({
            agedata: response.data,
          });
        });

        Swal.fire({
          title: 'Success',
          text: 'Team master Updated successfully',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        }).then(function (response) {
          window.location.reload();
        });
      }
    });
  }

  render() {
    //sessionStorage.setItem("path","/teammaster");
    //sessionStorage.setItem("classname","Agedivision")
    try {
      return (
        <>
          <div className='content'>
            <div>
              <Row>
                <Col md='8'>
                  <button type='button' data-toggle='modal' data-target='#addage' class='btn btn-danger btn-round'>
                    Add Team Set
                  </button>
                  <Card>
                    <CardHeader>
                      <h5 className='title'>Team master</h5>
                    </CardHeader>

                    <CardBody>
                      <Form>
                        <div class='form-group row'>
                          <div class='col-xs-5'>
                            <CardHeader>
                              <p>Search</p>
                              <input class='form-control' id='inputsm' placeholder='Search..' type='text' />
                            </CardHeader>
                          </div>
                        </div>
                        {Object.keys(this.state.agedata[0]).length == 0 ? (
                          <Empty />
                        ) : (
                          <>
                            <Table id='my' responsive>
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Values</th>

                                  <th class='disabled-sorting text-right'>Actions</th>
                                </tr>
                              </thead>
                              <tbody id='myTable'>
                                {this.state.agedata.map((question, index) => (
                                  <tr>
                                    <td>{question.name}</td>
                                    <td>{question.team_value.split(',').join(', ')}</td>

                                    <td class='td-actions text-right'>
                                      <button
                                        type='button'
                                        rel='tooltip'
                                        class='btn btn-success btn-sm btn-icon'
                                        onClick={() => this.ageedit(index)}
                                        data-toggle='modal'
                                        data-target='#agedit'
                                      >
                                        <i class='now-ui-icons ui-2_settings-90'></i>
                                      </button>
                                      {` `}
                                      <button
                                        type='button'
                                        rel='tooltip'
                                        class='btn btn-danger btn-sm btn-icon'
                                        onClick={() => this.agedelete(index)}
                                      >
                                        <i class='now-ui-icons ui-1_simple-remove'></i>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </>
                        )}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                {/* User table with edit delete------------------------------------------------------------------------------ */}
              </Row>
            </div>

            <div class='modal fade' id='addage' tabindex='-1' role='dialog' aria-hidden='true'>
              <div class='modal-dialog' id='addage'>
                <div class='modal-content'>
                  <div class='modal-header '>
                    <h5 class='modal-title ' id='loginmodalLabel'>
                      Add team master
                    </h5>
                    <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                      <span aria-hidden='true'>&times;</span>
                    </button>
                  </div>
                  <div class='modal-body'>
                    <form>
                      <div class='form-group'>
                        <label for='exampleInputEmail1'>Name:</label>
                        <input
                          type='text'
                          class='form-control'
                          id='exampleInputEmail1'
                          name='username'
                          onChange={(e) => this.setState({ agename: e.target.value })}
                          aria-describedby='emailHelp'
                          placeholder='Enter name'
                          required
                        />
                      </div>
                      <label>Values:</label>

                      {this.createUI()}
                      <input
                        type='button'
                        class='btn btn-success'
                        value='add more'
                        onClick={this.addClick.bind(this)}
                      />
                      <input
                        type='submit'
                        class='btn btn-danger'
                        onClick={this.handleSubmit}
                        data-dismiss='modal'
                        value='Save'
                      />
                    </form>
                    {/* <center><button type="submit"   class="btn btn-danger">Save</button></center> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class='modal fade' id='agedit' tabindex='-1' role='dialog' aria-hidden='true'>
            <div class='modal-dialog'>
              <div class='modal-content'>
                <div class='modal-header '>
                  <h5 class='modal-title ' id='loginmodalLabel'>
                    Edit Team master
                  </h5>
                  <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <div class='modal-body'>
                  <form class='myForm' id='myform'>
                    <div class='form-group'>
                      <label for='inputAddress'>Name</label>
                      <input
                        type='text'
                        class='form-control'
                        id='inputAddress'
                        placeholder='1234 Main St'
                        name='mdata'
                        defaultValue={this.state.ageditdata.name}
                        onChange={(e) => this.setState({ mdata: e.target.value })}
                        required
                      />
                    </div>

                    <div class='form-group'>
                      {this.state.splitdata.map((question, index) => (
                        //console.log(question),
                        <div>
                          <label>Value</label>
                          <input
                            type='text'
                            class='form-control valueData'
                            name={'cdata_' + index}
                            defaultValue={question}
                            onChange={(e) => this.setState({ cdata: e.target.value })}
                          />
                          <br></br>
                        </div>
                      ))}
                      <label>Values:</label>

                      {this.createUI()}
                      <input
                        type='button'
                        class='btn btn-success'
                        value='add more'
                        onClick={this.addClick.bind(this)}
                      />
                      <input
                        type='submit'
                        class='btn btn-danger'
                        data-dismiss='modal'
                        onClick={this.handleSubmittemp}
                        value='Save'
                      />

                      {/* <select class="form-control" id="exampleFormControlSelect1" name="assignedmanager" onChange={this.optionchange} required>
{ this.state.managers.map((question, index)=>(

<option>{question.firstname}</option>
))}


</select > */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } catch {
      return (
        <>
          <div className='content'>
            <div>
              <Row>
                <Col md='8'>
                  <button type='button' data-toggle='modal' data-target='#addage' class='btn btn-danger btn-round'>
                    Add age Division
                  </button>
                  <Card>
                    <CardHeader>
                      <h5 className='title'>Points</h5>
                    </CardHeader>

                    <CardBody>
                      <Form>
                        <div class='form-group row'>
                          <div class='col-xs-5'>
                            <CardHeader>
                              <p>Search</p>
                              <input class='form-control' id='inputsm' placeholder='Search..' type='text' />
                            </CardHeader>
                          </div>
                        </div>
                        :{' '}
                        <>
                          <Table id='my' responsive>
                            <thead>
                              <tr>
                                <th className='text-center'>#</th>
                                <th>Name</th>
                                <th>Values</th>

                                <th class='disabled-sorting text-right'>Actions</th>
                              </tr>
                            </thead>
                            <tbody id='myTable'>
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>

                                <td class='td-actions text-right'>
                                  <button
                                    type='button'
                                    rel='tooltip'
                                    class='btn btn-success btn-sm btn-icon'
                                    data-toggle='modal'
                                    data-target='#agedit'
                                  >
                                    <i class='now-ui-icons ui-2_settings-90'></i>
                                  </button>
                                  {` `}
                                  <button type='button' rel='tooltip' class='btn btn-danger btn-sm btn-icon'>
                                    <i class='now-ui-icons ui-1_simple-remove'></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                {/* User table with edit delete------------------------------------------------------------------------------ */}
              </Row>
            </div>
          </div>
        </>
      );
    }
  }
}

export default Teammaster;
