import React from 'react';

import '../App.css';

import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import 'datatables.net';
import 'datatables.net-bs4';
import { faWindows } from '@fortawesome/free-brands-svg-icons';

import Eventset from './Eventsetup.js';

import Trackset from './Trackset.js';
import Fieldset from './Fieldset.js';
import Arcset from './Arcset.js';
import Swimset from './Swimset';
import Tabletannisset from './Tableset.js';
import Fenceset from './Fencingset.js';
import Otherset from './Otherset';
import Paratriathlonset from './Paratriathlonsset.js';
import Paratriathlontwoset from './Paratwoset.js';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
class Venueset extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      venuedatas: [{}],
      newvenue: '',
      vname: '',
      vvalue: '',
      venuehome: true,
      eventhome: false,
      venueclasshome: false,
      venuespecfic: [{}],
      editename: '',
      editeid: '',
      newevents: '',
      newvenueclass: '',
      evenueclassname: '',
      evenueclassid: '',
      venuebyid: [{}],
      newclassvalue: '',
      vid: [{}],
      newclsvalue: '',
      newclsid: '',
      rerend: false,
      active1: false,
      loader: false,
      venuesetdata: [{}],
      trackset: false,
      Fieldset: false,
      arcset: false,
      swimset: false,
      tableset: false,
      weightset: false,
      teamset: false,
      fencingset: false,
      airset: false,
      preloader: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.createvenue = this.createvenue.bind(this);
    this.delete = this.delete.bind(this);
    this.updatevenue = this.updatevenue.bind(this);
    this.eventd = this.eventd.bind(this);
    this.eventedit = this.eventedit.bind(this);
    this.updateevent = this.updateevent.bind(this);
    this.eventdelete = this.eventdelete.bind(this);
    this.createevent = this.createevent.bind(this);
    this.createvenueclass = this.createvenueclass.bind(this);
    this.venueclassedit = this.venueclassedit.bind(this);
    this.updatevenueclass = this.updatevenueclass.bind(this);
    this.venueclassdelete = this.venueclassdelete.bind(this);
    this.vhome = this.vhome.bind(this);
    this.backhome = this.backhome.bind(this);
  }

  componentDidMount() {
    // $(document).ready(function () {
    //   $("#inputsm").on("keyup", function () {
    //     var value = $(this).val().toLowerCase();
    //     $("#myTable tr").filter(function () {
    //       $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    //     });
    //   });
    // });

    var self = this;
    self.setState({ venuespecfic: this.props.did });
    axios.get(`${url.url}/api/venues`, {}).then(function (response) {
      //let venueset = {"venueset":response.data.data}
      self.setState({
        venuedatas: response.data.data,
        preloader: false,
      });
    });

    axios.get(`${url.url}/api/getallvenues`, {}).then(function (response) {
      self.setState({
        venuesetdata: response.data,
      });
    });

    //Hide and show manager
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    // document.getElementById("venueesss").style.display = "block";
  }

  onSearch = (e) => {
    var value = e.target.value.toLowerCase();
    $('#myTable tr').filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
  };

  handleChange(e) {
    var self = this;
    self.setState({
      [e.target.name]: e.target.value,
    });
  }

  createvenue(e) {
    var self = this;
    e.preventDefault();
    const datatosend = {
      name: this.state.newvenue,
      created_by: 1,
    };

    axios
      .post(`${url.url}/api/createvenue/`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 201) {
          Swal.fire({
            title: 'Sucess',
            text: 'Venue Created successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong Please check the field name',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  // delete-----------------------------------------------------------
  delete(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deletevenue/${this.state.venuedatas[e].id}`, {})
          .then(function (response) {})

          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });
  }
  edit(e) {
    var self = this;
    self.setState({
      vname: this.state.venuedatas[e].name,
      vvalue: this.state.venuedatas[e].id,
    });
  }
  updatevenue(e) {
    var self = this;

    const datatosend = {
      name: this.state.vname,
      created_by: 1,
    };

    axios
      .put(`${url.url}/api/updatevenue/${this.state.vvalue}`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 200) {
          Swal.fire({
            title: 'Sucess',
            text: 'Venue updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  eventd(e) {
    var self = this;
    //let venuess = {"edata":this.state.venuedatas[e]}
    var y = e.split('-');
    var z = y[0];
    var z1 = y[1];
    sessionStorage.setItem('x', z1);
    //self.setState({active1: true})
    if (z == 'Track') {
      this.setState({
        venuehome: false,
        trackset: true,
      });
    }

    if (z == 'Field') {
      this.setState({
        venuehome: false,
        fieldset: true,
      });
    }

    if (z == 'Swimming') {
      this.setState({
        venuehome: false,
        swimset: true,
      });
    }

    if (z == 'Archery') {
      this.setState({
        venuehome: false,
        arcset: true,
      });
    }
    if (z == 'Para Powerlifting') {
      this.setState({
        venuehome: false,
        weightset: true,
      });
    }

    if (z == 'Table Tennis') {
      this.setState({
        venuehome: false,
        tableset: true,
      });
    }

    if (z == 'TeamSports') {
      this.setState({
        venuehome: false,
        teamset: true,
      });
    }

    if (z == 'Adaptive Tennis') {
      this.setState({
        venuehome: false,
        fencingset: true,
      });
    }

    if (z == 'Other Venues') {
      this.setState({
        venuehome: false,
        airset: true,
      });
    }
  }

  // event edit delete ---------------------------------------------------------------
  eventedit(e) {
    var self = this;
    self.setState({
      editename: this.state.venuespecfic.events[e].name,
      editeid: this.state.venuespecfic.events[e].id,
    });
  }
  updateevent(e) {
    var self = this;
    e.preventDefault();
    const datatosend = {
      name: this.state.editename,
      created_by: 1,
    };
    axios
      .put(`${url.url}/api/updateevent/${this.state.editeid}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            title: 'Success',
            text: 'Event updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  eventdelete(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deleteevent/${this.state.venuespecfic.events[e].id}`, {})
          .then(function (response) {})

          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });
  }

  createevent(e) {
    var self = this;
    e.preventDefault();
    const datatosend = {
      name: this.state.newevents,
      created_by: 1,
      venue_id: parseInt(this.state.venuespecfic.id),
    };
    axios
      .post(`${url.url}/api/createevent/`, datatosend, {})
      .then(function (response) {
        if (response.status == 201) {
          Swal.fire({
            title: 'Sucess',
            text: 'Event Created successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong Please check the field name',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  // event edit add delete ---------------------------------------------------------------

  createvenueclass(e) {
    var self = this;
    e.preventDefault();
    const datatosend = {
      name: this.state.newvenueclass,
      created_by: 1,
      venue_id: parseInt(this.state.venuespecfic.id),
    };

    axios
      .post(`${url.url}/api/createvenueclass/`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 201) {
          Swal.fire({
            title: 'Sucess',
            text: 'Venue Class Created Successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong Please check the field name',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  venueclassedit(e) {
    var self = this;
    self.setState({
      evenueclassname: this.state.venuespecfic.venueclasses[e].name,
      evenueclassid: parseInt(this.state.venuespecfic.venueclasses[e].id),
    });
  }
  updatevenueclass(e) {
    var self = this;
    e.preventDefault();
    const datatosend = {
      name: this.state.evenueclassname,
      created_by: 1,
      venue_id: parseInt(this.state.venuespecfic.id),
    };
    axios
      .put(`${url.url}/api/updatevenueclass/${this.state.evenueclassid}`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 200) {
          Swal.fire({
            title: 'Sucess',
            text: 'Event updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  venueclassdelete(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable1').deleteRow(e);
        axios
          .delete(`${url.url}/api/deletevenueclass/${this.state.venuespecfic.venueclasses[e].id}`, {})
          .then(function (response) {})
          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });
  }

  // class value edit delete create----------------------------------------------------------------------------
  vhome(e) {
    var self = this;

    sessionStorage.setItem('venue_id', this.state.venuespecfic.venueclasses[e].id);
    self.setState({
      loader: true,
      vid: this.state.venuespecfic.venueclasses[e],
      trackset: true,
    });
  }

  backhome() {
    var self = this;
    sessionStorage.removeItem('venueid');
    self.setState({
      venuehome: true,
      eventhome: false,
    });
  }

  back1() {
    window.location.replace('/meets');
    sessionStorage.removeItem('curnavitem');
  }
  render() {
    // venue home ----------------------------------------------------------------------------------------------------
    if (this.state.venuehome) {
      return (
        <>
          {this.state.preloader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div className='content'>
                <Row>
                  <Col md='8'>
                    {/* <button
                      type="button"
                      class="btn btn-danger btn-round"
                      onClick={this.back1}
                    >
                      Back
                    </button> */}
                    {''}
                    <Card>
                      <CardHeader>
                        <h5 className='title'>Event Set Preference</h5>
                      </CardHeader>
                      <CardBody>
                        <Form>
                          <div class='form-group row'>
                            <div class='col-xs-5'>
                              <CardHeader>
                                <p>Search</p>
                                <input
                                  class='form-control'
                                  id='inputsm'
                                  onChange={(e) => this.onSearch(e)}
                                  placeholder='Search..'
                                  type='text'
                                />
                              </CardHeader>
                            </div>
                          </div>
                          {/* Table -------------------------------------------- */}
                          <Table id='my' responsive>
                            <thead>
                              <tr>
                                <th className='text-center'>#</th>
                                <th>Name</th>

                                <th class='disabled-sorting text-right'>Actions</th>
                              </tr>
                            </thead>
                            <tbody id='myTable'>
                              {this.state.venuedatas.map((question, index) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{question.name}</td>
                                  <td class='td-actions text-right'>
                                    <button
                                      type='button'
                                      rel='tooltip'
                                      class='btn btn-success btn-sm btn-icon'
                                      onClick={() => this.eventd(question.name + '-' + index)}
                                    >
                                      <i class='now-ui-icons gestures_tap-01'></i>
                                    </button>
                                    {` `}
                                    {/* <button type="button" rel="tooltip" class="btn btn-success btn-sm btn-icon"  onClick={() => this.eventd(question.name)}>
<i class="now-ui-icons gestures_tap-01"></i>
</button>{` `} */}
                                    {/* <button type="button" rel="tooltip" class="btn btn-warning btn-sm btn-icon"  onClick={() => this.edit()} data-toggle="modal" data-target="#venueedit">
<i class="now-ui-icons ui-2_settings-90"></i>
</button>{` `}
<button type="button" rel="tooltip" class="btn btn-danger btn-sm btn-icon" onClick={() => this.delete()}>
<i class="now-ui-icons ui-1_simple-remove"></i>
</button> */}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          {/* Table -------------------------------------------- */}
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <div class='modal fade' id='addvenue' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog '>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Add venue{' '}
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <form>
                        <div class='modal-body'></div>

                        <div class='form-group col-md-8'>
                          <label for='inputEmail4'>Name *</label>
                          <input type='text' class='form-control' name='newvenue' onChange={this.handleChange} />
                        </div>

                        <center>
                          <button type='submit' onClick={this.createvenue} data-dismiss='modal' class='btn btn-danger'>
                            Create venue
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>

                {/* edit */}
                <div class='modal fade' id='venueedit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog '>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Edit venue{' '}
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <form>
                        <div class='modal-body'></div>
                        <div class='form-group col-md-8'>
                          <label for='inputEmail4'>Name *</label>
                          <input
                            type='text'
                            class='form-control'
                            name='vname'
                            value={this.state.vname}
                            onChange={this.handleChange}
                          />
                        </div>
                        <center>
                          <button type='submit' onClick={this.updatevenue} data-dismiss='modal' class='btn btn-danger'>
                            Update venue
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }

    // Event of tracks-------------------------------------------------------------------------------------------
    // if(this.state.eventhome){
    //   return(

    //     <Redirect to = "/eventpreference"/>
    //   )

    // }

    //Trackset preference
    if (this.state.trackset) {
      return <Redirect to='/tracksetpreference' />;
    }

    //Field preference
    if (this.state.fieldset) {
      return <Redirect to='/fieldsetpreference' />;
    }

    //Arc preference
    if (this.state.arcset) {
      return <Redirect to='/archerysetpreference' />;
    }

    //Swimset
    if (this.state.swimset) {
      return <Redirect to='/swimsetpreference' />;
    }

    //Tabletannisset
    if (this.state.tableset) {
      return <Redirect to='/tabletennissetpreference' />;
    }

    //WeightliftingSet
    if (this.state.weightset) {
      return <Redirect to='/Weightliftingsetpreference' />;
    }

    //TeamSet
    if (this.state.teamset) {
      return <Redirect to='/teamsportsetpreference' />;
    }

    //Fencingset
    if (this.state.fencingset) {
      return <Redirect to='/fencingsetpreference' />;
    }

    //Airgunset
    if (this.state.airset) {
      return <Redirect to='/airgunsetpreference' />;
    }
  }
}

export default Venueset;
