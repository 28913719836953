import React from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';

import Swal from 'sweetalert2';

import { Select, Input } from 'antd';
import 'antd/dist/antd.css';
import { Empty } from 'antd';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
class Athleteother extends React.Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    this.state = {
      event: queryParams.get('event'),
      selectedItems: [],
      venues: [{}],
      venueid: [],
      tname: [],
      tnamesid: '',
      othereventdata: [],
      othersdata: [],
      otherevents: [],
      otherdatas: [],
      Qualifying_marks: '',
      pits: '',
      group: '',
      other_class_value: '',
      othereventsdata: '',
      allregdatas: [{}],
      othersets: [{}],
      classdata: [{}],
      loader: true,
      age: '',
      otherclass: '',
      selected_events: '',
      setage: '',
      airrifle_age: '',
      paratriathlon100_age: '',
      paratriathlon200_age: '',
      paratriathlon500_age: '',
      boccia_age: '',
      cycling_age: '',
      setotherage: '',
    };
    this.check = this.check.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.edit = this.edit.bind(this);
    this.tinput = this.tinput.bind(this);
    this.createotherreg = this.createotherreg.bind(this);
    this.updateotherreg = this.updateotherreg.bind(this);
    this.editclassage = this.editclassage.bind(this);
    this.onchange1 = this.onchange1.bind(this);
    this.onchange2 = this.onchange2.bind(this);
    this.updateclassage = this.updateclassage.bind(this);
    this.deleteotherreg = this.deleteotherreg.bind(this);
  }
  async componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    var self = this;
    var count = 0;
    let indx = sessionStorage.getItem('otherindex');
    let ids = sessionStorage.getItem('ids');
    axios.get(`${url.url}/api/getothermeetid/${ids}`, {}).then(function (response) {
      self.setState({
        othersets: response.data,
      });
    });
    const othereventdata = (index) => {
      if (index.other_events != 'null') {
        var joined = this.state.othereventdata.concat(index);
        this.setState({
          othereventdata: joined,
          othersdata: joined[0],
        });
      } else {
        self.setState({
          othersdata: index,
        });
      }
    };
    let athleteid = sessionStorage.getItem('athleteid');
    await axios
      .get(`${url.url}/api/getathleteregisterid/${athleteid}`, {})
      .then(function (response) {
        self.setState({
          allregdatas: response.data,
        });
        {
          response.data.map((index) => othereventdata(index));
        }
        var valdata = self.state.othereventdata[0].other_events.split(',');
        self.setState({
          othersdata: self.state.othereventdata[0],
          otherevents: valdata,
        });
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });

    let venid = sessionStorage.getItem('venuesid');
    axios.get(`${url.url}/api/getclassvalue/${venid}`, {}).then(function (response) {
      self.setState({
        classdata: response.data,
      });
      count++;
      if (count == 3) {
        self.setState({
          loader: false,
        });
      }
    });
    let regid = sessionStorage.getItem('regid');
    var qstd = {};
    const data = {
      selected_events: this.state.tname,
      meet_id: sessionStorage.getItem('ids'),
    };
    axios
      .get(`${url.url}/api/getoregisterid/${athleteid}`, data, {})
      .then(function (response) {
        self.setState({
          otherdatas: response.data,
          otherdatas1: response.data[0],
        });
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      });

    //Hide and show manager
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    const dataz = {
      selected_events: this.state.tname,
      meet_id: sessionStorage.getItem('ids'),
      register_id: parseInt(sessionStorage.getItem('athleteid')),
    };
    axios
      .post(`${url.url}/api/getclassbyselectedevents`, dataz, {})
      .then(function (response) {
        this.setState({
          otherclass: response.data[0].other_class_value,
        });
      })
      .catch(function (error) {});
  }
  check = (test) => {
    var split = test.split('_');
    var splitx = split[0];
    var splity = split[1];
    var temp = [];
    if (Object.keys(this.state.tname).length != 0) {
      temp = this.state.tname;
    }
    if (!this.state.tname.some((item) => split[1] === item.name)) {
      temp.push({ name: split[1], id: split[0] });
    }
    this.setState({ tname: temp, tnamesid: split[1] });
    this.edit(split[0]);
  };

  handlechange() {
    window.location.replace('/athletevenues');
  }

  tinput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  edit(e) {
    var self = this;

    self.setState({
      othereventsdata: self.state.otherevents[e],
    });
    var loop = true;

    self.state.otherdatas.forEach((question, index) => {
      if (question.selected_events == self.state.othersets[e].event_name) {
        if (question.selected_events === 'Air Rifle') {
          self.setState({ othervenues_age: question.airrifle_age });
        }
        if (question.selected_events === 'Boccia') {
          self.setState({ othervenues_age: question.boccia_age });
        }
        if (question.selected_events === 'Cycling') {
          self.setState({ othervenues_age: question.cycling_age });
        }
        if (question.selected_events === 'Paratriathlon 100') {
          self.setState({ othervenues_age: question.paratriathlon100_age });
        }
        if (question.selected_events === 'Paratriathlon 200') {
          self.setState({ othervenues_age: question.paratriathlon200_age });
        }
        if (question.selected_events === 'Paratriathlon 500') {
          self.setState({ othervenues_age: question.paratriathlon500_age });
        }
        self.setState({
          selected_id: question.id,
          selected_events: question.selected_events,
          Qualifying_marks: question.Qualifying_marks,
          group: question.group,
          pits: question.pits,
          otherclass: question.other_class_value,
          btncheck: false,
        });
        loop = false;
      } else if (loop) {
        self.setState({
          selected_id: '',
          selected_events: self.state.othersets[e].event_name,
          othervenues_age: '',
          Qualifying_marks: '',
          group: '',
          pits: '',
          otherclass: '',
          btncheck: true,
        });
        loop = true;
      }
    });

    if (self.state.otherdatas.length == 0) {
      self.setState({
        selected_id: '',
        selected_events: self.state.othersets[e].event_name,
        Qualifying_marks: '',
        group: '',
        pits: '',
        otherclass: '',
        btncheck: true,
      });
    }
  }
  editclassage() {
    var self = this;
    self.setState({
      otherclass: self.state.allregdatas[0].other_class_value,
      otherage: self.state.allregdatas[0].othervenues_age,
    });
  }
  onchange1(e) {
    this.setState({ otherclass: e.target.value });
  }
  onchange2(e) {
    this.setState({ otherage: e.target.value });
  }
  updateclassage() {
    const data = {
      othervenues_age: this.state.otherage,
      other_class_value: this.state.otherclass,
    };
    let athleteid = sessionStorage.getItem('athleteid');
    axios
      .put(`${url.url}/api/updateclassagevalue/${athleteid}`, data, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  createotherreg(e) {
    var self = this;
    const setvalue = (q, index) => {
      if (self.state.otherdatas.length > index) {
        self.setState({
          selected_events: self.state.otherdatas[index].selected_events,
          Qualifying_marks: self.state.otherdatas[index].Qualifying_marks,
          pits: self.state.otherdatas[index].pits,
          group: self.state.otherdatas[index].group,
          age_division: '',
        });
      }
    };
    self.state.otherevents.map((question, index) => {
      setvalue(question, index);
    });
    const datatosend = {
      first_name: this.state.othersdata.firstname,
      last_name: this.state.othersdata.lastname,
      airrifle_age: this.state.selected_events === 'Air Rifle' ? this.state.othervenues_age : null,
      boccia_age: this.state.selected_events === 'Boccia' ? this.state.othervenues_age : null,
      cycling_age: this.state.selected_events === 'Cycling' ? this.state.othervenues_age : null,
      paratriathlon100_age: this.state.selected_events === 'Paratriathlon 100' ? this.state.othervenues_age : null,
      paratriathlon200_age: this.state.selected_events === 'Paratriathlon 200' ? this.state.othervenues_age : null,
      paratriathlon500_age: this.state.selected_events === 'Paratriathlon 500' ? this.state.othervenues_age : null,
      gender: this.state.othersdata.gender,
      selected_events: this.state.selected_events,
      Qualifying_marks: this.state.Qualifying_marks,
      group: this.state.group,
      pits: this.state.pits,
      other_class_value: this.state.otherclass,
      created_by: 1,
      register_id: parseInt(sessionStorage.getItem('athleteid')),
      meet_id: parseInt(sessionStorage.getItem('ids')),
      bib_no: this.state.allregdatas[0].bib_no,
      country: this.state.allregdatas[0].country,
      register_num: this.state.allregdatas[0].register_num,
    };
    axios
      .post(`${url.url}/api/createotherreg/`, datatosend, {})
      .then(function (response) {
        if (response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  updateotherreg(e) {
    var self = this;
    const datatosend = {
      first_name: this.state.othersdata.firstname,
      last_name: this.state.othersdata.lastname,
      airrifle_age: this.state.selected_events === 'Air Rifle' ? this.state.othervenues_age : null,
      boccia_age: this.state.selected_events === 'Boccia' ? this.state.othervenues_age : null,
      cycling_age: this.state.selected_events === 'Cycling' ? this.state.othervenues_age : null,
      paratriathlon100_age: this.state.selected_events === 'Paratriathlon 100' ? this.state.othervenues_age : null,
      paratriathlon200_age: this.state.selected_events === 'Paratriathlon 200' ? this.state.othervenues_age : null,
      paratriathlon500_age: this.state.selected_events === 'Paratriathlon 500' ? this.state.othervenues_age : null,
      gender: this.state.othersdata.gender,
      selected_events: this.state.selected_events,
      bib_no: this.state.allregdatas[0].bib_no,
      Qualifying_marks: this.state.Qualifying_marks,
      group: this.state.group,
      pits: this.state.pits,
      other_class_value: this.state.otherclass,
    };
    axios
      .put(`${url.url}/api/updateotherreg/${this.state.selected_id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  deleteotherreg(e) {
    const datatosend = {
      selected_events: '',
      Qualifying_marks: '',
      group: '',
      pits: '',
      other_class_value: '',
    };
    axios
      .delete(`${url.url}/api/deleteotherreg/${this.state.selected_id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 202) {
          Swal.fire({
            icon: 'success',
            title: 'Status Deleted successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  render() {
    const { selectedItems } = this.state;

    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div className='content'>
              <Row>
                <Col md='8'>
                  <button
                    type='button'
                    data-toggle='modal'
                    data-target='#selectother'
                    class='btn btn-danger btn-round'
                    onClick={this.handlechange}
                  >
                    Back
                  </button>
                  <Card>
                    <CardHeader>
                      <h5 className='title'>Other Events</h5>
                    </CardHeader>

                    <CardBody>
                      <Form>
                        <div class='form-group row'>
                          <div class='col-xs-5'>
                            <CardHeader className='athlete_othertb' id='deatils_heading'>
                              <div>
                                <th>
                                  <tr>
                                    <div>
                                      <label>Registration no: </label>
                                      <span>{this.state.othersdata.register_num}</span>
                                    </div>
                                    <div>
                                      <label> First Name: </label>
                                      <span>{this.state.othersdata.firstname}</span>
                                    </div>

                                    <div>
                                      <label> Last Name: </label>
                                      <span>{this.state.othersdata.lastname}</span>
                                    </div>
                                    <div>
                                      <label>BIB no: </label>
                                      <span>{this.state.othersdata.bib_no}</span>
                                    </div>

                                    <div>
                                      <label> Gender: </label>
                                      <span>{this.state.othersdata.gender}</span>
                                    </div>
                                  </tr>
                                </th>
                              </div>
                            </CardHeader>
                          </div>
                        </div>
                        {Object.keys(this.state.othersdata).length == 0 ? (
                          <Empty />
                        ) : (
                          <>
                            <Table id='my' responsive>
                              <thead>
                                <tr>
                                  <th>Athletes Events</th>

                                  <th>Selected Events</th>
                                  <th>Qualifying Marks</th>
                                  <th>Age</th>
                                  <th>Class</th>
                                  <th>Pits</th>
                                  <th>Group</th>
                                </tr>
                              </thead>
                              <tbody id='myTable'>
                                {this.state.othersets.map((question, index) => (
                                  <tr>
                                    <td>
                                      <button
                                        type='button'
                                        class='btn btn-success btn-sm'
                                        data-toggle='modal'
                                        data-target='#useredit'
                                        id={index}
                                        value={question.event_name}
                                        onClick={(e) => this.check(index + '_' + question.event_name)}
                                      >
                                        {question.event_name}
                                      </button>
                                    </td>
                                    <td>
                                      {this.state.otherdatas.map((question1, index) =>
                                        question.event_name === question1.selected_events
                                          ? question1.selected_events
                                          : ''
                                      )}
                                    </td>
                                    <td>
                                      {this.state.otherdatas.map((question1, index) =>
                                        question.event_name === question1.selected_events
                                          ? question1.Qualifying_marks
                                          : ''
                                      )}
                                    </td>
                                    <td>
                                      {this.state.otherdatas.map((question1, index) => {
                                        if (
                                          question.event_name === question1.selected_events &&
                                          question1.selected_events === 'Boccia'
                                        ) {
                                          return question1.boccia_age;
                                        }
                                        if (
                                          question.event_name === question1.selected_events &&
                                          question1.selected_events === 'Air Rifle'
                                        ) {
                                          return question1.airrifle_age;
                                        }
                                        if (
                                          question.event_name === question1.selected_events &&
                                          question1.selected_events === 'Cycling'
                                        ) {
                                          return question1.cycling_age;
                                        }
                                        if (
                                          question.event_name === question1.selected_events &&
                                          question1.selected_events === 'Paratriathlon 100'
                                        ) {
                                          return question1.paratriathlon100_age;
                                        }
                                        if (
                                          question.event_name === question1.selected_events &&
                                          question1.selected_events === 'Paratriathlon 200'
                                        ) {
                                          return question1.paratriathlon200_age;
                                        }
                                        if (
                                          question.event_name === question1.selected_events &&
                                          question1.selected_events === 'Paratriathlon 500'
                                        ) {
                                          return question1.paratriathlon500_age;
                                        } else {
                                          return '';
                                        }
                                      })}
                                    </td>
                                    <td>
                                      {this.state.otherdatas.map((question1, index) =>
                                        question.event_name === question1.selected_events
                                          ? question1.other_class_value
                                          : ''
                                      )}
                                    </td>
                                    <td>
                                      {this.state.otherdatas.map((question1, index) =>
                                        question.event_name === question1.selected_events ? question1.pits : ''
                                      )}
                                    </td>

                                    <td>
                                      {this.state.otherdatas.map((question1, index) =>
                                        question.event_name === question1.selected_events ? question1.group : ''
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </>
                        )}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                {/* User table with edit delete------------------------------------------------------------------------------ */}
              </Row>
              <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        {this.state.tnamesid + ' ' + 'Othervenues'}{' '}
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='exampleFormControlSelect1'>Qualifying Marks</label>
                          <input
                            class='form-control'
                            value={this.state.Qualifying_marks}
                            onChange={(e) =>
                              this.setState({
                                Qualifying_marks: e.target.value,
                              })
                            }
                            name='quatime'
                          />
                        </div>
                        <div class='form-group'>
                          <label for='exampleFormControlSelect1'>Other Class</label>
                          <input
                            class='form-control'
                            value={this.state.otherclass}
                            onChange={(e) => this.setState({ otherclass: e.target.value })}
                            name='quastd'
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputEmail4'>Age</label>
                          <input
                            class='form-control'
                            value={this.state.othervenues_age}
                            onChange={(e) => this.setState({ othervenues_age: e.target.value })}
                            name='age'
                          />
                        </div>

                        <div class='form-row'>
                          <div class='form-group'>
                            <label for='inputEmail4'>Pits</label>
                            <input
                              class='form-control'
                              value={this.state.pits}
                              onChange={(e) => this.setState({ pits: e.target.value })}
                              name='quastd'
                            />
                          </div>

                          <div class='form-group'>
                            <label for='inputPassword4'>Group</label>
                            <input
                              class='form-control'
                              value={this.state.group}
                              onChange={(e) => this.setState({ group: e.target.value })}
                              name='points'
                            />
                          </div>
                        </div>

                        {this.state.btncheck == true ? (
                          <center>
                            <button
                              type='button'
                              onClick={(e) => this.createotherreg()}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Create
                            </button>
                          </center>
                        ) : (
                          <>
                            <center>
                              <button type='button' class='btn btn-danger' onClick={(e) => this.updateotherreg()}>
                                Update
                              </button>
                              {` `}
                              <button
                                type='button'
                                class='btn btn-danger'
                                data-dismiss='modal'
                                onClick={(e) => this.deleteotherreg()}
                              >
                                Delete
                              </button>
                            </center>
                          </>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class='modal fade' id='meetedit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Othervenues
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
export default Athleteother;
