import React from 'react';

// reactstrap components
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
// core components

import axios from 'axios';
import Swal from 'sweetalert2';
// import { Redirect } from "react-router";
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';

import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import { Empty } from 'antd';
// import * as XLSX from 'xlsx';
// import Papa from "papaparse";
var c;
var d;
var f;
var g;
var typepoolarr = ['Yards', 'Meters LC', 'Meters SC'];
const url = window.globalConfig;
class Showallqstd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qdatas: [{}],
      qualdatas: [{}],
      eventsdropdown: [{}],
      classdropdown: [{}],
      venueclassid: [{}],
      agediv: [{}],
      venue: '',
      gender: '',
      qb: '',
      implement: '',
      qclass: '',
      qstd: '',
      adivision: '',
      event: '',
      vclass: '',
      clsvalue: '',
      agechange: '',
      classvalue: [{}],
      venueid: '',
      vclassid: '',
      defaultgender: 'Both',
      defaultvenuename: '',
      defaulteventname: '',
      defqb: '',
      defqstd: '',
      defimpl: '',
      defqcls: '',
      defagebreak: '',
      defagediv: '',
      defid: '',
      defaultvenueclass: '',
      defaultclassvalue: '',
      defdso: '',
      deftypepool: '',
      classvaluedata: [],
      trackview: false,
      activePage: 1,
      itemsCountPerPage: 1,
      totalItemsCount: 1,
      pageRangeDisplayed: 5,
      agedata: [],
      geteventids: '',
      getclassid: '',
      qstddata: {},
      loader: true,
      reset: false,
      showtable: false,
      editqstddata: {},
      VEC: [],
      qstd: [],
    };
    this.optionchange = this.optionchange.bind(this);
    this.optionchange1 = this.optionchange1.bind(this);
    this.optionchange2 = this.optionchange2.bind(this);
    this.optionchange3 = this.optionchange3.bind(this);
    this.optionchange4 = this.optionchange4.bind(this);
    this.optionchange5 = this.optionchange5.bind(this);
    this.optionchange6 = this.optionchange6.bind(this);
    this.optionchange7 = this.optionchange7.bind(this);
    this.optionchange8 = this.optionchange8.bind(this);
    this.optionchange9 = this.optionchange9.bind(this);
    this.createqstandard = this.createqstandard.bind(this);
    this.venueclasschange = this.venueclasschange.bind(this);
    this.delete = this.delete.bind(this);
    // this.edit = this.edit.bind(this);
    this.update = this.update.bind(this);
    //this.classdata = this.classdata.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.findstandard = this.findstandard.bind(this);
    this.clearstandard = this.clearstandard.bind(this);
    // this.handleUpload = this.handleUpload.bind(this);
  }

  clearstandard() {
    this.setState({
      eventsdropdown: [{}],
      classdropdown: [{}],
      venueclassid: [{}],
      agediv: [{}],
      venue: '',
      gender: '',
      qb: '',
      implement: '',
      qclass: '',
      qstd: '',
      adivision: '',
      event: '',
      vclass: '',
      clsvalue: '',
      agechange: '',
      classvalue: [{}],
      venueid: '',
      vclassid: '',
      defaultgender: 'Both',
      defaultvenuename: '',
      defaulteventname: '',
      defqb: '',
      defqstd: '',
      defimpl: '',
      defqcls: '',
      defagebreak: '',
      defagediv: '',
      defid: '',
      defaultvenueclass: '',
      defaultclassvalue: '',
      defdso: '',
      deftypepool: '',
      classvaluedata: [],
      trackview: false,
      activePage: 1,
      itemsCountPerPage: 1,
      totalItemsCount: 1,
      pageRangeDisplayed: 5,
      agedata: [],
      geteventids: '',
      getclassid: '',
      qstddata: {},
      reset: false,
      showtable: false,
      editqstddata: {},
    });
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });

    var self = this;
    axios.get(`${url.url}/api/getallqstd?page=` + pageNumber, {}).then(function (response) {
      self.setState({
        qdatas: response.data.data,
        itemsCountPerPage: response.data.per_page,

        totalItemsCount: response.data.total,
        activePage: response.data.current_page,
        loader: false,
      });
    });
  }

  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    this.handlePageChange();

    var self = this;
    axios
      .get(`${url.url}/api/getallqstd`, {})
      .then(function (response) {
        self.setState({
          qdatas: response.data.data,
          loader: false,
        });
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
    axios.get(`${url.url}/api/venues`, {}).then(function (response) {
      self.setState({
        qualdatas: response.data.data,
      });
    });
    axios.get(`${url.url}/api/agedivisions`, {}).then(function (response) {
      self.setState({
        agediv: response.data.data,
      });
    });

    //
  }

  createqstandard(e) {
    e.preventDefault();
    var x =
      this.state.evntd +
      this.state.defaultclassvalue.split('-')[0] +
      this.state.dagediv +
      this.state.gender.split('')[0] +
      this.state.gender.split('')[1] +
      this.state.evnt;
    const datatosend = {
      venue_name: this.state.defaultvenuename.split('-')[0],
      Class_values: this.state.defaultclassvalue.split('-')[0],
      Age_division: this.state.defagediv.split('-')[0],
      Gender: this.state.defaultgender,
      event_name: this.state.defaulteventname.split('-')[0],
      B_standard: this.state.defqb,
      qualifying_standards: this.state.defqstd,
      Implement: this.state.defimpl,

      DSO: this.state.defdso,
      Type_pool: this.state.deftypepool,
    };
    axios
      .post(`${url.url}/api/createqualifyingstandard/`, datatosend, {})
      .then(function (response) {
        if (response.status == 201) {
          Swal.fire({
            title: 'Success',
            text: 'Qualifying standard created successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong Please check the fields',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  delete(e) {
    var self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${url.url}/api/deletequalifyingstandard/${e}`, {})
          .then(function (response) {
            if (response.status) {
              Swal.fire('Deleted!', 'Record has been deleted.', 'success').then((result) => {
                self.findstandard();
              });
            }
          })
          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
      }
    });
  }

  optionchange5(e) {
    this.setState({
      defqstd: e.target.value,
    });
  }

  optionchange6(e) {
    this.setState({
      defqb: e.target.value,
    });
  }

  optionchange7(e) {
    this.setState({
      defimpl: e.target.value,
    });
  }

  optionchange8(e) {
    this.setState({
      defdso: e.target.value,
    });
  }

  optionchange9(e) {
    this.setState({
      deftypepool: e.target.value,
    });
  }
  valuechange = () => {
    var loop = true;
    this.state.qstddata.forEach((question, index) => {
      if (question.venue_name == 'Field') {
        loop = false;
      } else if (loop) {
        loop = true;
      }
    });
    if (loop == false) {
      return <th>Implement</th>;
    } else {
      return '';
    }
  };

  swimchange = () => {
    var loop = true;
    this.state.qstddata.forEach((question, index) => {
      if (question.venue_name == 'Swimming') {
        loop = false;
      } else if (loop) {
        loop = true;
      }
    });
    if (loop == false) {
      return <th>TypePool</th>;
    } else {
      return '';
    }
  };

  edit(e) {
    var self = this;
    self.setState({
      editqstddata: this.state.qstddata[e].id,
      defaultvenuename: this.state.qstddata[e].venue_name,
      defaulteventname: this.state.qstddata[e].event_name,
      defaultclassvalue: this.state.qstddata[e].Class_values,
      defagediv: this.state.qstddata[e].Age_division,
      defaultgender: this.state.qstddata[e].Gender,
      defdso: this.state.qstddata[e].DSO,
      defqb: this.state.qstddata[e].B_standard,
      defqstd: this.state.qstddata[e].qualifying_standards,
      defimpl: this.state.qstddata[e].Implement,
      deftypepool: this.state.qstddata[e].Type_pool,
    });
  }

  optionchange(e) {
    var i = this.state.qualdatas[parseInt(e.split('-')[1])];
    var j = this.state.qualdatas[parseInt(e.split('-')[1])];
    var k = e.split('-')[0];
    let qstd = [...this.state.qstd];
    qstd[0] = 'v';
    qstd.length = 1;
    var self = this;
    self.setState({
      qstd,
      defaultvenuename: e,
      eventsdropdown: i,
      classdropdown: j,
      venueid: i.id,
      agedata: [],
      classvaluedata: [],
    });

    var c = e.split('-')[0].toString().split('');
    var g = c[0] + c[1];

    var x = e.split('-');
    var m = x[2];
    self.setState({
      evntd: g,
    });
  }
  optionchange1(e) {
    var self = this;
    var d = e.split('-')[0].split(' ').join('');
    var geteventids = e.split('-')[1];
    let qstd = [...this.state.qstd];
    qstd[1] = 'e';
    qstd.length = 2;
    self.setState({
      qstd,
      evnt: d,
      geteventids: geteventids,
    });
    self.setState({
      defaulteventname: e,
      classvaluedata: [],
    });
    const dummy = (data) => {
      this.setState({
        classvaluedata: data,
      });
    };
    axios.get(`${url.url}/api/geteventid/${geteventids}`, {}).then(function (response) {
      dummy(response.data);
    });
  }
  optionchange3(e) {
    var self = this;
    let qstd = [...this.state.qstd];
    qstd[3] = 'a';
    qstd.length = 4;

    self.setState({
      qstd,
      defagediv: e,
      qstddata: [{}],
      showtable: false,
    });
  }

  optionchange4(e) {
    var self = this;
    self.setState({
      defaultgender: e.target.value,
    });
  }

  optionchange6(e) {
    var self = this;
    self.setState({
      defaultgender: e.target.value,
    });
  }

  venueclasschange(e) {
    var i = e.split('-');
    var l = parseInt(i[0]);
    var k = parseInt(i[1]);
    var self = this;
    self.setState({
      defaultvenueclass: e,
      //vclassid:i.id
    });

    self.setState({
      clasven: this.state.classdropdown.venueclasses[k],
      venclid: this.state.classdropdown.venueclasses[k].id,
    });
    axios
      .get(`${url.url}/api/getvenueclassid/${this.state.classdropdown.venueclasses[k].id}`, {})
      .then(function (response) {
        self.setState({
          venueclassid: response.data,
        });
      })
      .catch(function (error) {
        self.setState({
          venueclassid: [{}],
        });
      });
  }
  optionchange2(e) {
    var self = this;
    var classsplit = e.split('-');
    var classindex = classsplit[1];
    let qstd = [...this.state.qstd];
    qstd[2] = 'c';
    qstd.length = 3;
    self.setState({
      qstd,
      defaultclassvalue: e,
      classindex: classindex,
      agedata: [],
    });
    const dummy = (data) => {
      this.setState({ agedata: data });
    };
    axios.get(`${url.url}/api/getclasseventid/${this.state.geteventids}/${classindex}`, {}).then(function (response) {
      dummy(response.data);
    });
  }

  update(e) {
    e.preventDefault();

    var self = this;
    const datatosend = {
      venue_name: this.state.defaultvenuename.split('-')[0],
      Class_values: this.state.defaultclassvalue.split('-')[0],
      Age_division: this.state.defagediv.split('-')[0],
      Gender: this.state.defaultgender,
      event_name: this.state.defaulteventname.split('-')[0],
      B_standard: this.state.defqb,
      qualifying_standards: this.state.defqstd,
      Implement: this.state.defimpl,
      DSO: this.state.defdso,
      Type_pool: this.state.deftypepool,
    };
    axios
      .put(`${url.url}/api/updatequalifyingstandard/${this.state.editqstddata}`, datatosend, {})

      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then((response) => {
            self.findstandard();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  back = () => {
    window.location.replace('/Qualifyingstandard');
  };

  findstandard() {
    var self = this;
    let qstd = this.state.qstd;
    var qstds = qstd.join('');
    var typepooldata = '';
    if (this.state.defaultvenuename.split('-')[0] == 'Swimming') {
      if (this.state.defaultvenuename.split('-')[3] == 'Yards') {
        typepooldata = 'YD';
      } else if (this.state.defaultvenuename.split('-')[3] == 'Meters LC') {
        typepooldata = 'LC';
      } else if (this.state.defaultvenuename.split('-')[3] == 'Meters SC') {
        typepooldata = 'SC';
      }
    } else {
      typepooldata = '';
    }

    const datatosend = {
      qstds,
      venue_name: this.state.defaultvenuename.split('-')[0],
      event_name: this.state.defaulteventname.split('-')[0],
      Class_values: this.state.defaultclassvalue.split('-')[0],
      Age_division: this.state.defagediv,
      Gender: this.state.defaultgender,
      Type_pool: typepooldata,
    };
    axios
      .post(`${url.url}/api/getparticularqualstd/`, datatosend, {})
      .then(function (response) {
        self.setState({
          qstddata: response.data.data,
          reset: true,
          showtable: true,
        });
      })
      .catch(function (error) {
        self.setState({
          reset: true,
          showtable: true,
        });
      });
  }

  render() {
    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div className='content'>
              <Row>
                <Col md='8'>
                  <button type='button' data-toggle='modal' data-target='#addqual' class='btn btn-danger btn-round'>
                    New Qualifying standards
                  </button>
                  <Card>
                    <CardHeader>
                      <h5 className='title'>Qualifying Standards</h5>
                    </CardHeader>

                    <CardBody>
                      <Form>
                        <div class='form-group row'>
                          <div class='col-xs-5'>
                            <CardHeader>
                              <div class='form-row'>
                                <div class='form-group col-md-6'>
                                  <label for='inputCity'>Venue *</label>
                                  <select
                                    class='form-control'
                                    id='exampleFormControlSelect1'
                                    name='venue'
                                    value={this.state.defaultvenuename}
                                    onChange={(e) => this.optionchange(e.target.value)}
                                    required
                                  >
                                    <option>Please Select</option>
                                    {this.state.qualdatas.map((qevent, index) =>
                                      qevent.name == 'Track' || qevent.name == 'Field' || qevent.name == 'Swimming' ? (
                                        qevent.name == 'Swimming' ? (
                                          typepoolarr.map((item) => (
                                            <option value={qevent.name + '-' + index + '-' + qevent.id + '-' + item}>
                                              {qevent.name + '-' + item}
                                            </option>
                                          ))
                                        ) : (
                                          <option value={qevent.name + '-' + index + '-' + qevent.id}>
                                            {' '}
                                            {qevent.name}{' '}
                                          </option>
                                        )
                                      ) : (
                                        <></>
                                      )
                                    )}
                                  </select>
                                </div>

                                <div class='form-group col-md-6'>
                                  <label>Event *</label>
                                  <select
                                    class='form-control'
                                    id='exampleFormControlSelect1'
                                    name='event'
                                    value={
                                      this.state.defaulteventname == 'Please Select' ? '' : this.state.defaulteventname
                                    }
                                    onChange={(e) => this.optionchange1(e.target.value)}
                                    required
                                  >
                                    <option>Please Select</option>
                                    {Object.keys(this.state.eventsdropdown).length == 1 ? (
                                      <option>Please Select</option>
                                    ) : (
                                      <>
                                        {this.state.eventsdropdown.events.map((edrop, index) => (
                                          <option value={edrop.name + '-' + edrop.id + '-' + edrop.venue_id}>
                                            {edrop.name}
                                          </option>
                                        ))}
                                      </>
                                    )}
                                  </select>
                                </div>
                                <div class='form-group col-md-4'>
                                  <label for='inputCity'>Class Value</label>
                                  <select
                                    class='form-control'
                                    id='exampleFormControlSelect1'
                                    name='clsvalue'
                                    onChange={(e) => this.optionchange2(e.target.value)}
                                  >
                                    <option>Please Select</option>
                                    {Object.keys(this.state.classvaluedata).length == 0 ? (
                                      <option></option>
                                    ) : (
                                      <>
                                        {this.state.classvaluedata.map((cvalue, index) => (
                                          <option value={cvalue.name + '-' + cvalue.id}>{cvalue.name}</option>
                                        ))}
                                      </>
                                    )}
                                  </select>
                                </div>
                                <div class='form-group col-md-4'>
                                  <label>Age Division</label>
                                  <select
                                    class='form-control'
                                    id='exampleFormControlSelect1'
                                    name='agechange'
                                    onChange={(e) => this.optionchange3(e.target.value)}
                                  >
                                    <option>Please Select</option>
                                    {Object.keys(this.state.agedata).length == 0 ? (
                                      <option></option>
                                    ) : (
                                      <>
                                        {this.state.agedata.map((agedivision, index) => (
                                          <option value={agedivision.name}>{agedivision.name}</option>
                                        ))}
                                      </>
                                    )}
                                  </select>
                                </div>
                                <div class='form-group col-md-4'>
                                  <label>Gender</label>
                                  <select
                                    class='form-control'
                                    id='exampleFormControlSelect1'
                                    name='gender'
                                    value={this.state.defaultgender}
                                    onChange={this.optionchange4}
                                  >
                                    <option value='Both'>Both</option>
                                    <option value='Female'>Female</option>
                                    <option value='Male'>Male</option>
                                  </select>
                                </div>
                              </div>
                              <div class='form-row'>
                                <div class='form-group findbttn' style={{ marginLeft: '40%' }}>
                                  <button
                                    type='button'
                                    id='findbtn'
                                    onClick={this.findstandard}
                                    class='btn btn-success'
                                  >
                                    Find
                                  </button>
                                  <button
                                    type='button'
                                    id='clearbtn'
                                    onClick={this.clearstandard}
                                    class='btn btn-danger'
                                  >
                                    Clear
                                  </button>
                                </div>
                              </div>
                              {this.state.showtable == false ? (
                                ''
                              ) : (
                                <>
                                  <Table id='my mytable2' className='mytable1' responsive>
                                    <thead>
                                      <tr>
                                        <th>Venues</th>
                                        <th>Event</th>
                                        <th>A Standard</th>
                                        <th>B Standard</th>
                                        <th>Class Values</th>
                                        <th>Age Division</th>
                                        <th>Gender</th>
                                        {this.state.defaultvenuename.split('-')[0] == 'Field' ? <th>Implement</th> : ''}
                                        {this.state.defaultvenuename.split('-')[0] == 'Swimming' ? (
                                          <th>Type Pool</th>
                                        ) : (
                                          ''
                                        )}
                                        <th class='disabled-sorting text-right'>Actions</th>
                                      </tr>
                                    </thead>

                                    {Object.keys(this.state.qstddata).length == 0 ? (
                                      <tr>
                                        <td colSpan='10'>
                                          <Empty />
                                        </td>
                                      </tr>
                                    ) : (
                                      <>
                                        <tbody id='myTable'>
                                          {this.state.qstddata.map((question, index) => (
                                            <tr>
                                              <td>{question.venue_name}</td>
                                              <td>{question.event_name}</td>
                                              <td>{question.qualifying_standards}</td>
                                              <td>{question.B_standard}</td>
                                              <td>{question.Class_values}</td>
                                              <td>{question.Age_division}</td>
                                              <td>{question.Gender}</td>
                                              {question.venue_name == 'Field' ? <td>{question.Implement}</td> : ''}

                                              {question.venue_name == 'Swimming' ? <td>{question.Type_pool}</td> : ''}
                                              <td class='td-actions text-right'>
                                                <button
                                                  type='button'
                                                  rel='tooltip'
                                                  class='btn btn-success btn-sm btn-icon'
                                                  onClick={() => this.edit(index)}
                                                  data-toggle='modal'
                                                  data-target='#deletequal'
                                                >
                                                  <i class='now-ui-icons ui-2_settings-90'></i>
                                                </button>
                                                {` `}
                                                <button
                                                  type='button'
                                                  rel='tooltip'
                                                  class='btn btn-danger btn-sm btn-icon'
                                                  onClick={() => this.delete(question.id)}
                                                >
                                                  <i class='now-ui-icons ui-2_settings-90'></i>
                                                </button>
                                                {` `}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </>
                                    )}
                                  </Table>
                                </>
                              )}
                            </CardHeader>
                          </div>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <div class='modal fade' id='addqual' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog modal-lg'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Add Qualifying Standards Details
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-row'>
                          <div class='form-group col-md-4'>
                            <label for='inputCity'>Venue *</label>
                            <select
                              class='form-control'
                              id='exampleFormControlSelect1'
                              name='venue'
                              onChange={(e) => this.optionchange(e.target.value)}
                              required
                            >
                              <option>Please Select</option>
                              {this.state.qualdatas.map((qevent, index) =>
                                qevent.name == 'Track' || qevent.name == 'Field' || qevent.name == 'Swimming' ? (
                                  <option value={qevent.name + '-' + index + '-' + qevent.id}>{qevent.name}</option>
                                ) : (
                                  <></>
                                )
                              )}
                            </select>
                          </div>

                          <div class='form-group col-md-4'>
                            <label>Event *</label>
                            <select
                              class='form-control'
                              id='exampleFormControlSelect1'
                              name='event'
                              onChange={(e) => this.optionchange1(e.target.value)}
                              required
                            >
                              <option>Please Select</option>
                              {Object.keys(this.state.eventsdropdown).length == 1 ? (
                                <option></option>
                              ) : (
                                <>
                                  {this.state.eventsdropdown.events.map((edrop, index) => (
                                    <option value={edrop.name + '-' + edrop.id + '-' + edrop.venue_id}>
                                      {edrop.name}
                                    </option>
                                  ))}
                                </>
                              )}
                            </select>
                          </div>

                          <div class='form-group col-md-4'>
                            <label for='inputCity'>Class Value</label>
                            <select
                              class='form-control'
                              id='exampleFormControlSelect1'
                              name='clsvalue'
                              onChange={(e) => this.optionchange2(e.target.value)}
                              required
                            >
                              <option>Please Select</option>
                              {Object.keys(this.state.classvaluedata).length == 0 ? (
                                <option></option>
                              ) : (
                                <>
                                  {this.state.classvaluedata.map((cvalue, index) => (
                                    <option value={cvalue.name + '-' + cvalue.id}>{cvalue.name}</option>
                                  ))}
                                </>
                              )}
                            </select>
                          </div>
                        </div>
                        <div class='form-row'>
                          <div class='form-group col-md-4'>
                            <label>Age Division</label>
                            <select
                              class='form-control'
                              id='exampleFormControlSelect1'
                              name='agechange'
                              onChange={(e) => this.optionchange3(e.target.value)}
                              required
                            >
                              <option>Please Select</option>
                              {Object.keys(this.state.agedata).length == 1 ? (
                                <option></option>
                              ) : (
                                <>
                                  {this.state.agedata.map((agedivision, index) => (
                                    <option value={agedivision.name}>{agedivision.name}</option>
                                  ))}
                                </>
                              )}
                            </select>
                          </div>
                          <div class='form-group col-md-4'>
                            <label>Gender *</label>
                            <select
                              class='form-control'
                              id='exampleFormControlSelect5'
                              name='gender'
                              onChange={this.optionchange4}
                              required
                            >
                              <option>Please select</option>
                              <option value='Male'>Male</option>
                              <option value='Female'>Female</option>
                            </select>
                          </div>
                          <div class='form-group col-md-4'>
                            <label for='inputCity'>A Standard</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter A Standard'
                              name='defqstd'
                              onChange={this.optionchange5}
                              required
                            />
                          </div>
                        </div>
                        <div class='form-row'>
                          <div class='form-group col-md-6'>
                            <label>Qualstandard B</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter B Qualstandard'
                              name='defqb'
                              onChange={this.optionchange6}
                              required
                            />
                          </div>
                          <div class='form-group col-md-6'>
                            <label>Implement</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Implement'
                              name='defimpl'
                              onChange={this.optionchange7}
                              required
                            />
                          </div>
                        </div>
                        <div class='form-row'>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>DSO</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter DSO'
                              name='defagebreak'
                              onChange={this.optionchange8}
                              required
                            />
                          </div>

                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Type Pool</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter Type pool'
                              name='defagebreak'
                              onChange={this.optionchange9}
                              required
                            />
                          </div>
                        </div>

                        <div class='form-group'>
                          <center>
                            <button
                              type='submit'
                              onClick={this.createqstandard}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Create Qualifying Standards
                            </button>
                          </center>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div class='modal fade' id='deletequal' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog modal-lg'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Edit Qualifying Standards Details
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form id='myform'>
                        <div class='form-row'>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Venue </label>
                            <select
                              class='form-control'
                              id='exampleFormControlSelect1'
                              name='venue'
                              value={this.state.defaultvenuename}
                              onChange={(e) => this.optionchange(e.target.value)}
                              required
                            >
                              <option>Please Select</option>
                              {this.state.qualdatas.map((qevent, index) => (
                                <option value={qevent.name}>{qevent.name}</option>
                              ))}
                            </select>
                          </div>

                          <div class='form-group col-md-6'>
                            <label>Event </label>
                            <select
                              class='form-control'
                              id='exampleFormControlSelect2'
                              name='event'
                              value={this.state.defaulteventname}
                              onChange={(e) => this.optionchange1(e.target.value)}
                              required
                            >
                              <option>Please Select</option>
                              {Object.keys(this.state.eventsdropdown).length == 1 ? (
                                <option></option>
                              ) : (
                                <>
                                  {this.state.eventsdropdown.events.map((edrop, index) => (
                                    <option key={edrop.name} value={edrop.name}>
                                      {edrop.name}
                                    </option>
                                  ))}
                                </>
                              )}
                            </select>
                          </div>
                        </div>

                        <div class='form-row'>
                          <div class='form-group col-md-4'>
                            <label for='inputCity'>Class Value </label>
                            <select
                              class='form-control'
                              id='exampleFormControlSelect3'
                              name='clsvalue'
                              value={this.state.defaultclassvalue}
                              onChange={(e) => this.optionchange2(e.target.value)}
                              required
                            >
                              <option>Please Select</option>
                              {Object.keys(this.state.classvaluedata).length == 0 ? (
                                <option></option>
                              ) : (
                                <>
                                  {this.state.classvaluedata.map((cvalue, index) => (
                                    <option value={cvalue.name}>{cvalue.name}</option>
                                  ))}
                                </>
                              )}
                            </select>
                          </div>

                          <div class='form-group col-md-4'>
                            <label>Age Division </label>
                            <select
                              class='form-control'
                              id='exampleFormControlSelect4'
                              name='defagediv'
                              value={this.state.defagediv}
                              onChange={this.optionchange3}
                              required
                            >
                              <option>Please Select</option>
                              {Object.keys(this.state.agedata).length == 0 ? (
                                <option></option>
                              ) : (
                                <>
                                  {this.state.agedata.map((agedivision, index) => (
                                    <option value={agedivision.name}>{agedivision.name}</option>
                                  ))}
                                </>
                              )}
                            </select>
                          </div>
                          <div class='form-group col-md-4'>
                            <label>Gender *</label>
                            <select
                              class='form-control'
                              id='exampleFormControlSelect5'
                              name='gender'
                              value={this.state.defaultgender}
                              onChange={this.optionchange4}
                              required
                            >
                              <option>Please select</option>
                              <option value='Male'>Male</option>
                              <option value='Female'>Female</option>
                            </select>
                          </div>
                        </div>

                        <div class='form-row'>
                          <div class='form-group col-md-4'>
                            <label for='inputCity'>A Standard</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter A Standard'
                              name='defqstd'
                              value={this.state.defqstd}
                              onChange={this.optionchange5}
                              required
                            />
                          </div>

                          <div class='form-group col-md-4'>
                            <label>Qualstandard B</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter B Qualstandard'
                              name='defqb'
                              value={this.state.defqb}
                              onChange={this.optionchange6}
                              required
                            />
                          </div>
                          {this.state.defaultvenuename == 'Field' ? (
                            <div class='form-group col-md-4'>
                              <label>Implement</label>
                              <input
                                type='text'
                                class='form-control'
                                placeholder='Enter Implement'
                                name='defimpl'
                                value={this.state.defimpl}
                                onChange={this.optionchange7}
                                required
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>

                        <div class='form-row'>
                          <div class='form-group col-md-4'>
                            <label for='inputCity'>DSO</label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter DSO'
                              name='defagebreak'
                              value={this.state.defdso}
                              onChange={this.optionchange8}
                              required
                            />
                          </div>
                          {this.state.defaultvenuename == 'Swimming' ? (
                            <div class='form-group col-md-4'>
                              <label for='inputCity'>Type Pool</label>
                              <input
                                type='text'
                                class='form-control'
                                placeholder='Enter Type pool'
                                name='defagebreak'
                                value={this.state.deftypepool}
                                onChange={this.optionchange9}
                                required
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>

                        <div class='form-group'>
                          <center>
                            <button type='submit' onClick={this.update} data-dismiss='modal' class='btn btn-danger'>
                              Update{' '}
                            </button>
                          </center>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Showallqstd;
