import React from 'react';

import '../App.css';
import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $, { event } from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import { Empty } from 'antd';
import 'datatables.net';
import 'datatables.net-bs4';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
class Specialheat extends React.Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    this.state = {
      event: queryParams.get('event'),
      event_data: [{}],
      displayheat: '',
      relay: false,
      notrelay: false,
      heatfinaldata: '',
      lanedata: '',
      specialfinalresultdata: '',
      specialfinalplacedata: '',
      getspecialheatdata: [{}],
      specialheatdata: '',
      speciallanedata: '',
      prelimresultdata: '',
      loader: true,
      sortgenderresultdatas: [{}],
      eventdatas: {},
      heatno: false,
    };
    this.back = this.back.bind(this);
    this.edit = this.edit.bind(this);
    this.update = this.update.bind(this);
  }
  async componentDidMount() {
    var self = this;
    const data = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };

    await axios
      .post(`${url.url}/api/getsortbygenderresultvalues/`, data, {})
      .then(function (response) {
        self.setState({ event_data: response.data, loader: false });
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
  }
  back() {
    window.location.replace('/venuemainpage?event=' + sessionStorage.getItem('event'));
  }

  edit(e) {
    // console.log(this.state.event_data[e]);
    sessionStorage.setItem('index', e);
    var self = this;
    self.setState({
      eventdatas: this.state.event_data[e],
      speciallanedata: this.state.event_data[e].special_lane || '',
      specialheatdata: this.state.event_data[e].special_heat || '',
      specialfinalresultdata: this.state.event_data[e].special_final_results || '',
      specialfinalplacedata: this.state.event_data[e].special_final_place || '',
      prelimresultdata: this.state.event_data[e].prelim_result,
    });
  }

  update(e) {
    // console.log(e);
    const datatosend = {
      special_final_results: this.state.specialfinalresultdata,
      special_final_place: this.state.specialfinalplacedata,
      special_heat: this.state.specialheatdata,
      special_lane: this.state.speciallanedata,
      prelim_result: this.state.prelimresultdata,
    };
    // console.log(datatosend);
    axios
      .put(`${url.url}/api/updatetrackspecialheat/${this.state.eventdatas.id}`, datatosend, {})
      .then(function (response) {
        // console.log(response);
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  sortbygenderresult = (eventname) => {
    $('#auseredit').addClass('show');
    $('#auseredit').css('display', 'block');

    var self = this;
    self.setState({
      eventname: eventname,
    });
  };
  close = () => {
    $('#auseredit').removeClass('show');
    $('#auseredit').css('display', 'none');
    this.setState({ heatno: false, notrelay: true, displayheat: '' });
  };
  findheat = () => {
    this.setState({
      heatno: true,
      notrelay: false,
    });
    $('#auseredit').css('display', 'none');
  };
  render() {
    if (this.state.heatno === true) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='venuesetup'>
                  <h1> </h1>
                  <div className='content'>
                    <Row>
                      <Col>
                        <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                          Back
                        </button>
                        <Card>
                          <CardHeader>
                            <h5 className='title'>
                              {this.state.event} <br></br> Special Heat Sheet
                            </h5>
                          </CardHeader>
                          <CardBody>
                            <div class='form-group row printrow'>
                              <div class='col-xs-5'>
                                <CardHeader>
                                  <center>
                                    <button
                                      type='button'
                                      class='btn btn-success printbtn'
                                      onClick={this.sortbygenderresult}
                                    >
                                      Display Selected Heat
                                    </button>
                                  </center>
                                </CardHeader>
                              </div>
                            </div>
                            <Form>
                              {Object.keys(this.state.event_data).length == 0 ? (
                                <Empty />
                              ) : (
                                <>
                                  <table class='worksheet_table'>
                                    <thead>
                                      <tr class='worksheet_tr'>
                                        <th>Heat</th>
                                        <th>Lane</th>
                                        <th>Special Heat Final Results</th>
                                        <th>Special Heat Final Place</th>
                                        <th>Bib</th>
                                        <th>Last Name</th>
                                        <th>First Name</th>
                                        <th>Class</th>
                                        <th>Age</th>
                                        <th>Gender</th>
                                        <th>100m Best Result</th>
                                        <th>100m Final Result</th>
                                        <th>100m Prelim Result</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody id='myTable'>
                                      {this.state.event_data.map((question, index) => (
                                        <tr class='worksheet_tr'>
                                          {this.state.displayheat === question.special_heat ? (
                                            <>
                                              <td>{question.special_heat}</td>
                                              <td>{question.special_lane}</td>
                                              <td>{question.special_final_results}</td>
                                              <td>{question.special_final_place}</td>
                                              <td>{question.bib_no}</td>
                                              <td>{question.last_name}</td>
                                              <td>{question.first_name}</td>
                                              <td>{question.track_class_value}</td>
                                              <td>{question.age_division}</td>
                                              <td>{question.gender}</td>
                                              <td>{question.best_results}</td>
                                              <td>
                                                {question.final_result ? question.final_result : question.prelim_result}
                                              </td>
                                              <td>{question.prelim_result}</td>
                                              <td>
                                                <button
                                                  type='button'
                                                  rel='tooltip'
                                                  class='btn btn-success btn-sm btn-icon'
                                                  onClick={() => this.edit(index)}
                                                  data-toggle='modal'
                                                  data-target='#useredit'
                                                >
                                                  <i class='now-ui-icons ui-2_settings-90'></i>
                                                </button>
                                              </td>
                                            </>
                                          ) : (
                                            ''
                                          )}
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </>
                              )}
                              <div class='modal fade' id='auseredit' tabindex='-1' role='dialog' aria-hidden='true'>
                                <div class='modal-dialog'>
                                  <div class='modal-content'>
                                    <div class='modal-header '>
                                      <h5 class='modal-title ' id='loginmodalLabel'>
                                        Display Selected Heat
                                      </h5>
                                      <button
                                        type='button'
                                        class='close'
                                        data-dismiss='modal'
                                        aria-label='Close'
                                        onClick={this.close}
                                      >
                                        <span aria-hidden='true'>&times;</span>
                                      </button>
                                    </div>
                                    <div class='modal-body'>
                                      <form>
                                        <div class='form-row '>
                                          <div class='form-group' style={{ textAlign: 'center' }}>
                                            <label>What Heat to display</label>
                                            <input
                                              type='input'
                                              id='no_filter'
                                              name='chklift1'
                                              value={this.state.displayheat}
                                              onChange={(e) =>
                                                this.setState({
                                                  ...this.state.displayheat,
                                                  displayheat: e.target.value,
                                                })
                                              }
                                              style={{ marginLeft: '10px', marginRight: '10px' }}
                                            />
                                          </div>
                                        </div>
                                        <center>
                                          <button type='button' class='btn btn-danger' onClick={() => this.findheat()}>
                                            Update
                                          </button>
                                        </center>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Special Heat Worksheet
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-row'>
                            <div class='form-group col-md-6'>
                              <label for='inputCity'>Heat </label>
                              <input
                                class='form-control'
                                value={this.state.specialheatdata}
                                onChange={(e) =>
                                  this.setState({
                                    specialheatdata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>

                            <div class='form-group col-md-6'>
                              <label for='inputCity'>lane</label>
                              <input
                                class='form-control'
                                value={this.state.speciallanedata}
                                onChange={(e) =>
                                  this.setState({
                                    speciallanedata: e.target.value,
                                  })
                                }
                                name='relayletter'
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group col-md-6'>
                              <label for='inputCity'>Special Heat Final Results</label>
                              <input
                                class='form-control'
                                value={this.state.specialfinalresultdata}
                                onChange={(e) =>
                                  this.setState({
                                    specialfinalresultdata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                            <div class='form-group col-md-6'>
                              <label for='inputCity'> Special Heat Final Place</label>
                              <input
                                class='form-control'
                                value={this.state.specialfinalplacedata}
                                onChange={(e) =>
                                  this.setState({
                                    specialfinalplacedata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <center>
                            <button type='button' class='btn btn-danger' onClick={(e) => this.update()}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (this.state.notrelay === true) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='venuesetup'>
                  <h1> </h1>
                  <div className='content'>
                    <Row>
                      <Col>
                        <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                          Back
                        </button>
                        <Card>
                          <CardHeader>
                            <h5 className='title'>
                              {this.state.event} <br></br> Special Heat Sheet
                            </h5>
                          </CardHeader>
                          <CardBody>
                            <div class='form-group row printrow'>
                              <div class='col-xs-5'>
                                <CardHeader>
                                  <center>
                                    <button
                                      type='button'
                                      class='btn btn-success printbtn'
                                      onClick={this.sortbygenderresult}
                                    >
                                      Display Selected Heat
                                    </button>
                                  </center>
                                </CardHeader>
                              </div>
                            </div>
                            <Form>
                              {Object.keys(this.state.event_data).length == 0 ? (
                                <Empty />
                              ) : (
                                <>
                                  <table class='worksheet_table'>
                                    <thead>
                                      <tr class='worksheet_tr'>
                                        <th>Heat</th>
                                        <th>Lane</th>
                                        <th>Special Heat Final Results</th>
                                        <th>Special Heat Final Place</th>
                                        <th>Bib</th>
                                        <th>Last Name</th>
                                        <th>First Name</th>
                                        <th>Class</th>
                                        <th>Age</th>
                                        <th>Gender</th>
                                        <th>100m Best Result</th>
                                        <th>100m Final Result</th>
                                        <th>100m Prelim Result</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody id='myTable'>
                                      {this.state.event_data.map((question, index) => (
                                        <tr class='worksheet_tr'>
                                          <td>{question.special_heat}</td>
                                          <td>{question.special_lane}</td>
                                          <td>{question.special_final_results}</td>
                                          <td>{question.special_final_place}</td>
                                          <td>{question.bib_no}</td>
                                          <td>{question.last_name}</td>
                                          <td>{question.first_name}</td>
                                          <td>{question.track_class_value}</td>
                                          <td>{question.age_division}</td>
                                          <td>{question.gender}</td>
                                          <td>{question.best_results}</td>
                                          <td>
                                            {question.final_result ? question.final_result : question.prelim_result}
                                          </td>
                                          <td>{question.prelim_result}</td>
                                          <td>
                                            <button
                                              type='button'
                                              rel='tooltip'
                                              class='btn btn-success btn-sm btn-icon'
                                              onClick={() => this.edit(index)}
                                              data-toggle='modal'
                                              data-target='#useredit'
                                            >
                                              <i class='now-ui-icons ui-2_settings-90'></i>
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </>
                              )}

                              <div class='modal fade' id='auseredit' tabindex='-1' role='dialog' aria-hidden='true'>
                                <div class='modal-dialog'>
                                  <div class='modal-content'>
                                    <div class='modal-header '>
                                      <h5 class='modal-title ' id='loginmodalLabel'>
                                        Display Selected Heat
                                      </h5>
                                      <button
                                        type='button'
                                        class='close'
                                        data-dismiss='modal'
                                        aria-label='Close'
                                        onClick={this.close}
                                      >
                                        <span aria-hidden='true'>&times;</span>
                                      </button>
                                    </div>
                                    <div class='modal-body'>
                                      <form>
                                        <div class='form-row '>
                                          <div class='form-group' style={{ textAlign: 'center' }}>
                                            <label>What Heat to display</label>
                                            <input
                                              type='input'
                                              id='no_filter'
                                              name='chklift1'
                                              onChange={(e) => this.setState({ displayheat: e.target.value })}
                                              style={{ marginLeft: '10px', marginRight: '10px' }}
                                            />
                                          </div>
                                        </div>
                                        <center>
                                          <button type='button' class='btn btn-danger' onClick={() => this.findheat()}>
                                            Update
                                          </button>
                                        </center>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Special Heat Worksheet
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-row'>
                            <div class='form-group col-md-6'>
                              <label for='inputCity'>Heat </label>
                              <input
                                class='form-control'
                                value={this.state.specialheatdata}
                                onChange={(e) =>
                                  this.setState({
                                    specialheatdata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>

                            <div class='form-group col-md-6'>
                              <label for='inputCity'>lane</label>
                              <input
                                class='form-control'
                                value={this.state.speciallanedata}
                                onChange={(e) =>
                                  this.setState({
                                    speciallanedata: e.target.value,
                                  })
                                }
                                name='relayletter'
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group col-md-6'>
                              <label for='inputCity'> Special Heat Final Results</label>
                              <input
                                class='form-control'
                                value={this.state.specialfinalresultdata}
                                onChange={(e) =>
                                  this.setState({
                                    specialfinalresultdata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                            <div class='form-group col-md-6'>
                              <label for='inputCity'> Special Heat Final Place</label>
                              <input
                                class='form-control'
                                value={this.state.specialfinalplacedata}
                                onChange={(e) =>
                                  this.setState({
                                    specialfinalplacedata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <center>
                            <button type='button' class='btn btn-danger' onClick={(e) => this.update()}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (this.state.relay) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div>
                  <h1> </h1>
                  <Row>
                    <Col>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                        Back
                      </button>
                      <Card>
                        <CardHeader>
                          <h5 className='title'>
                            {this.state.event} <br></br> Special Heat Sheet
                          </h5>
                        </CardHeader>
                        <CardBody>
                          <Form>
                            <div class='form-group row'>
                              <div class='col-xs-5'>
                                <CardHeader>
                                  <p>Search</p>
                                  <input class='form-control' id='inputsm' placeholder='Search..' type='text' />
                                </CardHeader>
                              </div>
                            </div>
                            {Object.keys(this.state.event_data).length == 0 ? (
                              <Empty />
                            ) : (
                              <>
                                <table class='worksheet_table'>
                                  <thead>
                                    <tr class='worksheet_tr'>
                                      <th>Heat</th>
                                      <th>Lane</th>
                                      <th>Final result</th>
                                      <th>Final Place </th>
                                      <th>Age</th>
                                      <th>Gender</th>
                                      <th>Bib</th>
                                      <th>Last Name</th>
                                      <th>First Name</th>
                                      <th>Best result</th>
                                      <th>Final result</th>
                                      <th>Prelim result</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.event_data.map((question, index) => (
                                      <tr class='worksheet_tr'>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>{question.age_division}</td>
                                        <td>{question.gender}</td>
                                        <td>{question.bib_no}</td>
                                        <td>{question.last_name}</td>
                                        <td>{question.first_name}</td>
                                        <td>{question.final_result}</td>
                                        <td>{question.final_result}</td>
                                        <td>{question.prelim_result}</td>
                                        <td>
                                          <button
                                            type='button'
                                            rel='tooltip'
                                            class='btn btn-success btn-sm btn-icon'
                                            onClick={() => this.edit(index)}
                                            data-toggle='modal'
                                            data-target='#useredit'
                                          >
                                            <i class='now-ui-icons ui-2_settings-90'></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </>
                            )}
                            <div class='modal fade' id='auseredit' tabindex='-1' role='dialog' aria-hidden='true'>
                              <div class='modal-dialog'>
                                <div class='modal-content'>
                                  <div class='modal-header '>
                                    <h5 class='modal-title ' id='loginmodalLabel'>
                                      Display Selected Heat
                                    </h5>
                                    <button
                                      type='button'
                                      class='close'
                                      data-dismiss='modal'
                                      aria-label='Close'
                                      onClick={this.close}
                                    >
                                      <span aria-hidden='true'>&times;</span>
                                    </button>
                                  </div>
                                  <div class='modal-body'>
                                    <form>
                                      <div class='form-row '>
                                        <div class='form-group' style={{ textAlign: 'center' }}>
                                          <label>What Heat to display</label>
                                          <input
                                            type='input'
                                            id='no_filter'
                                            name='chklift1'
                                            value={this.state.displayheat}
                                            onChange={(e) => this.setState({ displayheat: e.target.value })}
                                            style={{ marginLeft: '10px', marginRight: '10px' }}
                                          />
                                        </div>
                                      </div>
                                      <center>
                                        <button type='button' class='btn btn-danger' onClick={() => this.findheat()}>
                                          Update
                                        </button>
                                      </center>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Relay Special Heat Worksheet
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-row'>
                            <div class='form-group '>
                              <label for='inputCity'>Prelim Result </label>
                              <input
                                class='form-control'
                                value={this.state.prelimresultdata}
                                onChange={(e) =>
                                  this.setState({
                                    prelimresultdata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <center>
                            <button type='button' class='btn btn-danger' onClick={(e) => this.update()}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    return this.state.event == '4 X 100 Meters' ||
      this.state.event == '4 X 400 Meters' ||
      this.state.event == '800 Medley' ||
      this.state.event == '4 X 100 Universal'
      ? (this.state.relay = true)
      : (this.state.notrelay = true);
  }
}

export default Specialheat;
