import React from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';

import Swal from 'sweetalert2';

import { Select, Input } from 'antd';
import 'antd/dist/antd.css';
import { Empty } from 'antd';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner';

var x;
const url = window.globalConfig;
class Athleteweight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      venues: [{}],
      venueid: [],
      tname: [],
      tnamesid: '',
      weightlifteventdata: [],
      weightliftsets: [{}],
      weightliftsdata: [],
      weightliftevents: [],
      weightdatas: [{}],
      qualifying_marks: '',
      bench: '',
      group: '',
      weighteventsdata: '',
      allregdatas: [{}],
      classdata: [{}],
      weightclass: '',
      loader: true,
      weightclass2: '',
      unitmeasure: 'LBS',
      weightclass1: '',
      weightage: '',
    };
    this.check = this.check.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.edit = this.edit.bind(this);
    this.tinput = this.tinput.bind(this);
    this.createweightreg = this.createweightreg.bind(this);
    this.updateweightreg = this.updateweightreg.bind(this);
    this.editclassage = this.editclassage.bind(this);
    this.onchange1 = this.onchange1.bind(this);
    this.onchange2 = this.onchange2.bind(this);
    this.updateclassage = this.updateclassage.bind(this);
    this.deleteweightreg = this.deleteweightreg.bind(this);
  }
  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    let itemindex = sessionStorage.getItem('itemindex');
    var self = this;
    var count = 0;
    let ids = sessionStorage.getItem('ids');
    axios.get(`${url.url}/api/getweightmeetidevent/${ids}`, {}).then(function (response) {
      self.setState({
        weightliftsets: response.data,
      });
    });
    const weightlifteventdata = (index) => {
      if (index.weightlift_events != 'null') {
        var joined = this.state.weightlifteventdata.concat(index);
        this.setState({
          weightlifteventdata: joined,
          weightliftsdata: joined[0],
        });
      } else {
        self.setState({
          weightliftsdata: index,
        });
      }
    };
    let athleteid = sessionStorage.getItem('athleteid');
    axios
      .get(`${url.url}/api/getathleteregisterid/${athleteid}`, {})
      .then(function (response) {
        self.setState({
          allregdatas: response.data,
        });
        {
          response.data.map((index) => weightlifteventdata(index));
        }
        var valdata = self.state.weightlifteventdata[0].weightlift_events.split(',');
        self.setState({
          weightliftsdata: self.state.weightlifteventdata[0],
          weightliftevents: valdata,
        });
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });

    let venid = sessionStorage.getItem('venuesid');
    axios.get(`${url.url}/api/getclassvalue/${venid}`, {}).then(function (response) {
      self.setState({
        classdata: response.data,
      });
      count++;
      if (count == 3) {
        self.setState({
          loader: false,
        });
      }
    });

    let regid = sessionStorage.getItem('regid');
    axios
      .get(`${url.url}/api/getwregisterid/${athleteid}`, {})
      .then(function (response) {
        self.setState({
          weightdatas: response.data,
          weightdatas1: response.data[0],
        });
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });

    //Hide and show manager
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
  }
  check = (e) => {
    var temp = [];
    if (Object.keys(this.state.tname).length != 0) {
      temp = this.state.tname;
    }
    if (!this.state.tname.some((item) => e.target.value === item.name)) {
      temp.push({ name: e.target.value, id: e.target.id });
    }
    this.setState({ tname: temp, tnamesid: e.target.value });
    this.edit(e.target.id);
  };
  handlechange() {
    window.location.replace('/athletevenues');
  }

  tinput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  async edit(e) {
    var self = this;
    self.setState({
      weighteventsdata: self.state.weightliftevents[e],
    });
    var loop = true;

    self.state.weightdatas.forEach((question, index) => {
      if (question.selected_events == self.state.weightliftsets[e].event_name) {
        self.setState({
          selected_id: question.id,
          selected_events: question.selected_events,
          qualifying_marks: question.qualifying_marks,
          bench: question.bench,
          group: question.group,

          btncheck: false,
        });
        loop = false;
      } else if (loop) {
        self.setState({
          selected_id: '',
          selectedevents: self.state.weightliftsets[e].event_name,
          qualifying_marks: '',
          bench: '',
          group: '',
          btncheck: true,
        });
        loop = true;
      }
    });

    if (self.state.weightdatas.length == 0) {
      self.setState({
        selected_id: '',
        selectedevents: self.state.weightliftsets[e].event_name,
        qualifying_marks: '',
        bench: '',
        group: '',
        btncheck: true,
      });
    }
  }

  editclassage() {
    var self = this;
    self.setState({
      weightclass1: self.state.allregdatas[0].weightlift_class_value,
      weightage: self.state.allregdatas[0].weightlifting_age,
      unitmeasure: self.state.allregdatas[0].unit_measure,
    });
  }
  onchange1(e) {
    this.setState({ weightclass1: e.target.value });
  }
  onchange2(e) {
    this.setState({ weightage: e.target.value });
  }
  updateclassage() {
    var calweightclass = '';
    var weightindata = '';
    if (this.state.unitmeasure == 'LBS') {
      calweightclass = this.state.weightclass1 * 0.45;
      weightindata = Math.round(calweightclass) + ' ' + 'KG';
    } else {
      calweightclass = this.state.weightclass1 * 2.2;
      weightindata = Math.round(calweightclass) + ' ' + 'LBS';
    }

    const data = {
      weightlifting_age: this.state.weightage,
      weight_in: this.state.weightclass1,
      weightlift_class_value: this.state.weightclass1,
      unit_measure: this.state.unitmeasure,
      set_weight_lbs: this.state.weightclass1,
    };
    let athleteid = sessionStorage.getItem('athleteid');
    axios
      .put(`${url.url}/api/updateclassagevalue/${athleteid}`, data, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  createweightreg(e) {
    var self = this;

    const setvalue = (q, index) => {
      if (self.state.weightdatas.length > index) {
        self.setState({
          selected_events: self.state.weightdatas[index].selected_events,
          qualifying_marks: self.state.weightdatas[index].qualifying_marks,
          bench: self.state.weightdatas[index].bench,
          group: self.state.weightdatas[index].group,
        });
      }
    };
    self.state.weightliftevents.map((question, index) => {
      setvalue(question, index);
    });

    const datatosend = {
      first_name: this.state.weightliftsdata.firstname,
      last_name: this.state.weightliftsdata.lastname,
      weightlifting_age: this.state.allregdatas[0].weightlifting_age,
      gender: this.state.weightliftsdata.gender,
      selected_events: this.state.selectedevents,
      qualifying_marks: this.state.qualifying_marks,
      bench: this.state.bench,
      group: this.state.group,
      weight_class_value: this.state.weightliftsdata.weightlift_class_value,
      created_by: 1,
      register_id: parseInt(sessionStorage.getItem('athleteid')),
      meet_id: parseInt(sessionStorage.getItem('ids')),
      bib_no: this.state.allregdatas[0].bib_no,
      country: this.state.allregdatas[0].country,
      team_name: this.state.allregdatas[0].team_name,
      set_weight_lbs: this.state.allregdatas[0].weight_in,
      register_num: this.state.allregdatas[0].register_num,
    };
    axios
      .post(`${url.url}/api/createweightliftreg/`, datatosend, {})
      .then(function (response) {
        if (response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  updateweightreg(e) {
    var self = this;

    const datatosend = {
      first_name: this.state.weightliftsdata.firstname,
      last_name: this.state.weightliftsdata.lastname,
      weightlifting_age: this.state.weightliftsdata.weightlift_age ? this.state.weightliftsdata.weightlift_age : 'null',
      gender: this.state.weightliftsdata.gender,
      selected_events: this.state.selected_events,
      qualifying_marks: this.state.qualifying_marks,
      bench: this.state.bench,
      group: this.state.group,
      weight_class_value: this.state.weightliftsdata.weightlift_class_value,
      bib_no: this.state.allregdatas[0].bib_no,
    };
    axios
      .put(`${url.url}/api/updateweightliftreg/${this.state.selected_id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  deleteweightreg(e) {
    const datatosend = {
      selected_events: '',
      qualifying_marks: '',
      group: '',
      bench: '',
    };
    axios
      .delete(`${url.url}/api/deleteweightreg/${this.state.selected_id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 202) {
          Swal.fire({
            icon: 'success',
            title: 'Status Deleted successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  render() {
    const { selectedItems } = this.state;

    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div className='content'>
              <Row>
                <Col md='8'>
                  <button
                    type='button'
                    data-toggle='modal'
                    data-target='#selectweightlift'
                    class='btn btn-danger btn-round'
                    onClick={this.handlechange}
                  >
                    Back
                  </button>
                  <Card>
                    <CardHeader>
                      <h5 className='title'>Para Powerlifting Events</h5>
                    </CardHeader>

                    <CardBody>
                      <Form>
                        <div class='form-group row'>
                          <div class='col-xs-5'>
                            <CardHeader className='athlete_weighttb' id='deatils_heading'>
                              <div>
                                <th>
                                  <tr>
                                    <div>
                                      <label>Registration no:</label>
                                      <span>{this.state.weightliftsdata.register_num}</span>
                                    </div>
                                    <div>
                                      <label> First Name:</label>
                                      <span>{this.state.weightliftsdata.firstname}</span>
                                    </div>

                                    <div>
                                      <label> Last Name:</label>
                                      <span>{this.state.weightliftsdata.lastname}</span>
                                    </div>
                                  </tr>
                                  <tr>
                                    <div>
                                      <label> BIB no:</label>
                                      <span>{this.state.weightliftsdata.bib_no}</span>
                                    </div>
                                    <div>
                                      <label>Pre-Weighin Class:</label>
                                      <span>
                                        {this.state.weightliftsdata.weightlift_class_value
                                          ? this.state.weightliftsdata.weightlift_class_value
                                          : 'n/a'}
                                      </span>
                                    </div>

                                    <div>
                                      <label>Age Division:</label>
                                      <span>{this.state.weightliftsdata.weightlifting_age}</span>
                                    </div>

                                    <div>
                                      <label>Gender:</label>
                                      <span>{this.state.weightliftsdata.gender}</span>
                                    </div>
                                  </tr>
                                </th>
                              </div>

                              <button
                                type='button'
                                rel='tooltip'
                                data-toggle='modal'
                                data-target='#meetedit'
                                class='btn btn-success btn-sm btn-icon'
                                onClick={this.editclassage}
                              >
                                <i class='now-ui-icons ui-2_settings-90'></i>
                              </button>
                            </CardHeader>
                          </div>
                        </div>
                        {Object.keys(this.state.weightliftsdata).length == 0 ? (
                          <Empty />
                        ) : (
                          <>
                            <Table id='my' responsive>
                              <thead>
                                <tr>
                                  <th>Athletes Events</th>
                                  <th>Selected Events</th>
                                  <th>Qualifying Marks</th>
                                  <th>Bench</th>
                                  <th>Group</th>
                                </tr>
                              </thead>
                              <tbody id='myTable'>
                                {this.state.weightliftsets.map((question, index) => (
                                  <tr>
                                    <td>
                                      <button
                                        type='button'
                                        class='btn btn-success btn-sm'
                                        data-toggle='modal'
                                        data-target='#useredit'
                                        id={index}
                                        value={question.event_name}
                                        onClick={(e) => this.check(e)}
                                      >
                                        {question.event_name}
                                      </button>
                                    </td>
                                    <td>
                                      {this.state.weightdatas.map((question1, index) =>
                                        question.event_name === question1.selected_events
                                          ? question1.selected_events
                                          : ''
                                      )}
                                    </td>
                                    <td>
                                      {this.state.weightdatas.map((question1, index) =>
                                        question.event_name === question1.selected_events
                                          ? question1.qualifying_marks
                                          : ''
                                      )}
                                    </td>

                                    <td>
                                      {this.state.weightdatas.map((question1, index) =>
                                        question.event_name === question1.selected_events ? question1.bench : ''
                                      )}
                                    </td>

                                    <td>
                                      {this.state.weightdatas.map((question1, index) =>
                                        question.event_name === question1.selected_events ? question1.group : ''
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </>
                        )}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                {/* User table with edit delete------------------------------------------------------------------------------ */}
              </Row>
              <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        {this.state.tnamesid + ' ' + 'Weightlift'}{' '}
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='exampleFormControlSelect1'>Qualifying Marks</label>
                          <input
                            class='form-control'
                            value={this.state.qualifying_marks}
                            onChange={(e) =>
                              this.setState({
                                qualifying_marks: e.target.value,
                              })
                            }
                            name='quatime'
                          />
                        </div>

                        <div class='form-row'>
                          <div class='form-group'>
                            <label for='inputEmail4'>Bench</label>
                            <input
                              class='form-control'
                              value={this.state.bench}
                              onChange={(e) => this.setState({ bench: e.target.value })}
                              name='quastd'
                            />
                          </div>

                          <div class='form-group'>
                            <label for='inputPassword4'>Group</label>
                            <input
                              class='form-control'
                              value={this.state.group}
                              onChange={(e) => this.setState({ group: e.target.value })}
                              name='points'
                            />
                          </div>
                        </div>

                        {this.state.btncheck == true ? (
                          <center>
                            <button
                              type='button'
                              onClick={(e) => this.createweightreg()}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Create
                            </button>
                          </center>
                        ) : (
                          <>
                            <center>
                              <button type='button' class='btn btn-danger' onClick={(e) => this.updateweightreg()}>
                                Update
                              </button>
                              {` `}
                              <button
                                type='button'
                                class='btn btn-danger'
                                data-dismiss='modal'
                                onClick={(e) => this.deleteweightreg()}
                              >
                                Delete
                              </button>
                            </center>
                          </>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class='modal fade' id='meetedit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Weightlifting
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='exampleFormControlSelect1'>Pre-Weighin Class</label>

                          <input
                            class='form-control'
                            value={this.state.weightclass1}
                            onChange={this.onchange1}
                            name='quastd'
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputEmail4'>Age Division</label>
                          <input
                            class='form-control'
                            value={this.state.weightage}
                            onChange={this.onchange2}
                            name='quastd'
                          />
                        </div>
                        <center>
                          <button type='button' class='btn btn-danger' onClick={(e) => this.updateclassage()}>
                            Update
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
export default Athleteweight;
