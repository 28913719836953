import React from 'react';
import '../App.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';

import Swal from 'sweetalert2';

import { Select, Input } from 'antd';
import 'antd/dist/antd.css';
import 'datatables.net';
import 'datatables.net-bs4';

import { Empty } from 'antd';
import Viewall from './Viewall';

const { Option } = Select;

var mdata = sessionStorage.getItem('index');
let ids = sessionStorage.getItem('ids');
const url = window.globalConfig;
class Viewall1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      meetdatas: [{}],
      regdetails: [{}],
      regdatas: [{}],
      datacheck: false,
      alldata: false,
      adetail: false,
      bibno: '',
      feedback: '',
      datas: {},
      viewall: false,
    };

    this.statusreject = this.statusreject.bind(this);
    this.statusupdate = this.statusupdate.bind(this);
    this.Rejected = this.Rejected.bind(this);
  }
  componentWillMount() {
    //console.log(mdata)
    let ids = sessionStorage.getItem('ids');
    var self = this;
    axios
      .get(`${url.url}/api/getallregister/`, {})
      // axios.get(`${url.url}/api/getregmeet/${ids}`,{
      // })
      // axios.get(`${url.url}/api/getregmeet/${ids}`,{
      // })
      .then(function (response) {
        var output = [];

        response.data.data.forEach(function (item, index) {
          var existing = output.filter(function (v, i) {
            return v.payment_no == item.payment_no;
          });
          // console.log(existing);

          if (existing.length) {
            var existingIndex = output.indexOf(existing[0]);
            //   console.log(existingIndex);
            output[existingIndex].venuename = output[existingIndex].venuename.concat(item.venuename) + ',';
            output[existingIndex].eventname = output[existingIndex].eventname.concat(item.eventname) + ',';
            output[existingIndex].venueid = output[existingIndex].venueid.concat(item.venueid) + ',';
            output[existingIndex].eventid = output[existingIndex].eventid.concat(item.eventid) + ',';
            output[existingIndex].id = output[existingIndex].id.concat(item.id) + ',';
          } else {
            //   if (typeof item.value == 'string'){
            item.venuename = [item.venuename];
            item.eventname = [item.eventname];
            item.venueid = [item.venueid];
            item.eventid = [item.eventid];
            item.id = [item.id];
            //   }

            output.push(item);
          }
        });
        //let mdata = sessionStorage.getItem("index")
        self.setState({
          regdetails: output,
        });
        //var mdata = sessionStorage.getItem("index")
        var regdata = response.data.data[0];
        axios.get(`${url.url}/api/getuserid/${regdata.user_id}`, {}).then(function (response) {
          self.setState({ datas: response.data.data });
        });
      })
      .catch(function (error) {
        self.setState({
          regdetails: [{}],
        });
      });
  }
  componentDidMount() {
    let ids = sessionStorage.getItem('ids');
    var self = this;
    axios
      .get(`${url.url}/api/getallregister/`, {})
      // axios.get(`${url.url}/api/getregmeet/${ids}`,{
      // })
      .then(function (response) {
        var output = [];

        response.data.data.forEach(function (item, index) {
          var existing = output.filter(function (v, i) {
            return v.payment_no == item.payment_no;
          });
          // console.log(existing);
          if (existing.length) {
            var existingIndex = output.indexOf(existing[0]);
            //   console.log(existingIndex);
            output[existingIndex].venuename = output[existingIndex].venuename.concat(item.venuename) + ',';
            output[existingIndex].eventname = output[existingIndex].eventname.concat(item.eventname) + ',';
            output[existingIndex].venueid = output[existingIndex].venueid.concat(item.venueid) + ',';
            output[existingIndex].eventid = output[existingIndex].eventid.concat(item.eventid) + ',';
            output[existingIndex].id = output[existingIndex].id.concat(item.id) + ',';
          } else {
            //   if (typeof item.value == 'string'){
            item.venuename = [item.venuename];
            item.eventname = [item.eventname];
            item.venueid = [item.venueid];
            item.eventid = [item.eventid];
            item.id = [item.id];
            //   }

            output.push(item);
          }
        });

        self.setState({
          regdetails: output,
        });
        //let mdata = sessionStorage.getItem("index")
        var regdata = response.data.data[0];
        axios.get(`${url.url}/api/getuserid/${regdata.user_id}`, {}).then(function (response) {
          self.setState({ datas: response.data.data });
        });
      })
      .catch(function (error) {
        self.setState({
          regdetails: [{}],
        });
      });

    var self = this;
    axios.get(`${url.url}/api/meets`, {}).then(function (response) {
      self.setState({
        meetdatas: response.data.data,
      });
    });
  }

  Rejected(e) {
    var i = parseInt(e.split('-')[0]);
    if (e.split('-')[1] == 'rejected') {
      var j = 0;
    }

    var k = e.split('-')[1];
    this.setState({ rejecteddata: k, inter: i });
    //console.log(k)
  }

  statusreject(event) {
    event.preventDefault();
    //console.log("hai")
    var j = 0;
    const datatosend = {
      status: this.state.rejecteddata,
      bib_no: 0,
      reason: this.state.feedback,
    };
    //console.log(datatosend)
    axios
      .put(`${url.url}/api/updateRegstatus/${this.state.regdetails[this.state.inter].id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            title: 'Sucess',
            text: 'Status updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  statusupdate(e) {
    var i = parseInt(e.split('-')[0]);

    if (e.split('-')[1] == 'approved') {
      var j = parseInt(this.state.bibno) + 1;
    } else if (e.split('-')[1] == 'rejected') {
      var j = 0;
    }

    const datatosend = {
      status: e.split('-')[1],
      bib_no: j,
      reason: 'null',
    };
    axios
      .put(`${url.url}/api/updateRegstatus/${this.state.regdetails[0].id}`, datatosend, {})
      .then(function (response) {
        //console.log(response)
        if (response.status == 200) {
          Swal.fire({
            title: 'Success',
            text: 'Status updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  detailview(e) {
    var self = this;
    //sessionStorage.setItem("index",e)
    //sessionStorage.getItem("index")
    self.setState({
      adetail: true,
      alldata: true,
      detaillist: this.state.regdetails[e],
    });
  }

  back() {
    //sessionStorage.setItem("path","/atheleteregister");
    window.location.replace('/atheleteregister');
  }

  render() {
    var mdata = sessionStorage.getItem('index');
    //sessionStorage.setItem("path","/viewall");

    if (this.state.alldata == false) {
      return (
        <div>
          <div class='modal fade' id='feedback' tabindex='-1' role='dialog' aria-hidden='true'>
            <div class='modal-dialog' id='feedback'>
              <div class='modal-content'>
                <div class='modal-header '>
                  <h5 class='modal-title ' id='loginmodalLabel'>
                    Feedback
                  </h5>
                  <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <div class='modal-body'>
                  <form onSubmit={this.submitlogin}>
                    <div class='form-group'>
                      <label for='exampleInputPassword1'>Reason for Rejection</label>
                      <input
                        type='text'
                        class='form-control'
                        id='rejecttxt'
                        onChange={(e) => this.setState({ feedback: e.target.value })}
                        placeholder='Please enter the reason'
                        required
                      />
                    </div>
                    <button type='submit' class='btn btn-danger' onClick={(event) => this.statusreject(event)}>
                      Send
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className='content'>
            <h2>{this.state.mname}</h2>
            <Row>
              <Col md='8'>
                <button
                  type='button'
                  data-toggle='modal'
                  data-target='#newmeet'
                  class='btn btn-danger btn-round'
                  onClick={this.back}
                >
                  Back
                </button>
                {''}
                {/* <button type="button" data-toggle="modal" data-target="#newmeet" class="btn btn-danger btn-round" onClick={this.pending}>View Pending Approval</button>{''} */}
                {/* <button type="button" data-toggle="modal" data-target="#newmeet" class="btn btn-danger btn-round" onClick={this.viewall}>View All</button>{''} */}
                <Card>
                  <CardHeader>
                    <h5 className='title'>Meet Enrolment</h5>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <div class='form-group row'>
                        <div class='col-xs-5'>
                          <CardHeader>
                            <p>Search</p>
                            <input class='form-control' id='inputsm' placeholder='Search..' type='text' />
                          </CardHeader>
                        </div>
                      </div>
                      {/* table --------------------------------------------------- */}
                      {Object.keys(this.state.regdetails).length == 0 ? (
                        <Empty />
                      ) : (
                        <>
                          <Table id='my' responsive>
                            <thead>
                              <tr>
                                <th className='text-center'>#</th>
                                <th>Meet Name</th>
                                <th>Venue Name</th>
                                <th>Event Name</th>
                                <th>Name</th>
                                <th>Payment Reciept no</th>
                                <th>Approval Status</th>
                              </tr>
                            </thead>
                            <tbody id='myTable'>
                              {this.state.regdetails.map((question, index) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{question.meetname}</td>
                                  <td>{question.venuename}</td>
                                  <td>{question.eventname}</td>
                                  <td>{question.user_name}</td>
                                  <td>{question.payment_no}</td>

                                  <td>{question.status}</td>
                                  <td class='td-actions text-right'>
                                    <button
                                      type='button'
                                      rel='tooltip'
                                      class='btn btn-success btn-grid'
                                      onClick={() => this.detailview(index)}
                                    >
                                      Details
                                    </button>
                                    {` `}
                                    <button
                                      type='button'
                                      rel='tooltip'
                                      class='btn btn-success '
                                      value='approved'
                                      onClick={() => this.statusupdate(index + '-' + 'approved')}
                                    >
                                      Approve
                                    </button>
                                    {` `}
                                    <button
                                      type='button'
                                      rel='tooltip'
                                      class='btn btn-danger'
                                      value='rejected'
                                      onClick={() => this.Rejected(index + '-' + 'Rejected')}
                                      data-toggle='modal'
                                      data-target='#feedback'
                                    >
                                      Reject
                                    </button>
                                    {` `}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </>
                      )}
                      <div class='pagination'>
                        <ol id='numbers'></ol>
                      </div>

                      {/* table --------------------------------------------------- */}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      );
    }

    //--------------------------------
    if (this.state.adetail == true) {
      return (
        <div>
          <div class='modal fade' id='feedback' tabindex='-1' role='dialog' aria-hidden='true'>
            <div class='modal-dialog' id='feedback'>
              <div class='modal-content'>
                <div class='modal-header '>
                  <h5 class='modal-title ' id='loginmodalLabel'>
                    Feedback
                  </h5>
                  <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <div class='modal-body'>
                  <form onSubmit={this.submitlogin}>
                    <div class='form-group'>
                      <label for='exampleInputPassword1'>Reason for Rejection</label>
                      <input
                        type='text'
                        class='form-control'
                        id='rejecttxt'
                        onChange={(e) => this.setState({ feedback: e.target.value })}
                        placeholder='Please enter the reason'
                        required
                      />
                    </div>
                    <button type='submit' class='btn btn-danger' onClick={(event) => this.statusreject(event)}>
                      Send
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className='content detailpage'>
            <Row>
              <Col md='8'>
                <button
                  type='button'
                  data-toggle='modal'
                  data-target='#newmeet'
                  class='btn btn-danger btn-round'
                  onClick={this.back}
                >
                  Back
                </button>
                {''}
                <Card>
                  <CardHeader>
                    <h5 className='title'>Details</h5>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      {/* <div class="form-group row">
<div class="col-xs-5">
{/* <CardHeader>
<p>Search</p>
<input class="form-control" id="inputsm" placeholder="Search.." type="text"/>
</CardHeader> */}
                      {/* </div>
</div>  */}
                      {/* table --------------------------------------------------- */}
                      {Object.keys(this.state.regdetails).length == 0 ? (
                        <Empty />
                      ) : (
                        <>
                          {/* { this.state.detaillist.map((question, index)=>(    */}
                          <div class='container'>
                            <div class='form-row'>
                              <div class='form-group col-md-12'>
                                <label for='inputEmail4'>Name</label>
                                <p>{this.state.detaillist.user_name}</p>
                              </div>
                              <div class='form-group col-md-12'>
                                <label for='inputPassword4'>Age</label>
                                <p>{this.state.datas.age}</p>
                              </div>
                              <div class='form-group col-md-12'>
                                <label for='inputPassword4'>Gender</label>
                                <p>{this.state.datas.gender}</p>
                              </div>
                              <div class='form-group col-md-12'>
                                <label for='inputPassword4'>Meet Name</label>
                                <p>{this.state.detaillist.meetname}</p>
                              </div>
                              <div class='form-group col-md-12'>
                                <label for='inputPassword4'>Venue Name</label>
                                <p>{this.state.detaillist.venuename}</p>
                              </div>
                              <div class='form-group col-md-12'>
                                <label for='inputPassword4'>Event Name</label>
                                <p>{this.state.detaillist.eventname}</p>
                              </div>
                              <div class='form-group col-md-12'>
                                <label for='inputPassword4'>Payment Reciept no</label>
                                <p>{this.state.detaillist.payment_no}</p>
                              </div>
                              <div class='form-group col-md-12'>
                                <label for='inputPassword4'>Approval Status</label>
                                <p>{this.state.detaillist.status}</p>
                              </div>
                              <div class='form-group col-md-12'>
                                <label for='inputPassword4'>Agedivision</label>
                                <p>{this.state.detaillist.agedivision}</p>
                              </div>
                              <div class='form-group col-md-12'>
                                <label for='inputPassword4'>gendervariation</label>
                                <p>{this.state.detaillist.gendervariation}</p>
                              </div>
                              <div class='form-group col-md-12'>
                                <label for='inputPassword4'>Reason</label>
                                <p>{this.state.detaillist.reason}</p>
                              </div>

                              <div class='detailbtn'>
                                <button
                                  type='button'
                                  rel='tooltip'
                                  class='btn btn-success '
                                  value='approved'
                                  onClick={() => this.statusupdate(mdata + '-' + 'approved')}
                                >
                                  Approve
                                </button>
                                {` `}
                                <button
                                  type='button'
                                  rel='tooltip'
                                  class='btn btn-danger'
                                  value='rejected'
                                  onClick={() => this.Rejected(mdata + '-' + 'Rejected')}
                                  data-toggle='modal'
                                  data-target='#feedback'
                                >
                                  Reject
                                </button>
                                {` `}
                              </div>
                            </div>
                          </div>
                          {/* ))}  */}
                        </>
                      )}
                      <div class='pagination'>
                        <ol id='numbers'></ol>
                      </div>

                      {/* table --------------------------------------------------- */}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      );

      // All approal ---------------------------------------------end--------------------------------------------------
    }
  }
}

export default Viewall1;
