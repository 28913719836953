import React from 'react';

import '../App.css';
import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import { Empty } from 'antd';
import 'datatables.net';
import 'datatables.net-bs4';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
class Swimprelimheatsetup extends React.Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    this.state = {
      event: queryParams.get('event'),
      event_data: [{}],
      relay: false,
      notrelay: false,
      workdata: '',
      finaldata: '',
      heatdata: '',
      lanedata: '',
      classdata: '',
      comclassdata: '',
      countrydata: '',
      rundata: '',
      bestdata: '',
      randdata: '',
      lanseqdata: '',
      eventdatas: '',
      qualifying_standard: '',
      qualifying_time: '',
      relay_points: '',
      trackdatas: [{}],
      qualtimedata: '',
      loader: true,
      swimsetdata: [{}],
      swimsetrecord: '',
      swimsets: [{}],
    };
    this.back = this.back.bind(this);
    this.edit = this.edit.bind(this);
    this.update = this.update.bind(this);
  }
  async componentDidMount() {
    var self = this;
    const data = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };

    await axios
      .post(`${url.url}/api/getregisterswimevent/`, data, {})
      .then(function (response) {
        self.setState({
          event_data: response.data,
          loader: false,
        });
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    document.getElementById('homelink').style.display = 'block';

    axios
      .get(`${url.url}/api/getallswimlocationset/`, {})
      .then(function (response) {
        self.setState({
          swimsetdata: response.data,
          swimsetrecord: response.data[0].current_record,
        });
      })
      .catch(function (error) {});

    axios.get(`${url.url}/api/getswimmeetid/${sessionStorage.getItem('ids')}`, {}).then(function (response) {
      self.setState({
        swimsets: response.data,
      });
    });
  }
  back() {
    window.location.replace('/venuemainpage?event=' + sessionStorage.getItem('event'));
  }

  edit(e) {
    var self = this;
    self.setState({
      eventdatas: this.state.event_data[e],
      workdata: this.state.event_data[e].work_field,
      finaldata: this.state.event_data[e].final_group,
      heatdata: this.state.event_data[e].heat,
      lanedata: this.state.event_data[e].lane,
      rundata: this.state.event_data[e].type_pool,
    });
  }

  update(e) {
    const datatosend = {
      work_field: this.state.workdata,
      final_group: this.state.finaldata,
      heat: this.state.heatdata,
      lane: this.state.lanedata,
      type_pool: this.state.rundata,
    };
    axios
      .put(`${url.url}/api/updateswimprelimheatsetup/${this.state.eventdatas.id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  render() {
    if (this.state.relay) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='venuesetup'>
                  <h1> </h1>
                  <div className='content'>
                    <Row>
                      <Col>
                        <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                          Back
                        </button>
                        <Card className='scrollcard'>
                          <CardHeader>
                            <h5 className='title'>
                              {this.state.event} <br></br> Preliminary Heatsetup
                            </h5>
                          </CardHeader>
                          <CardBody>
                            <div class='form-group row printrow'>
                              <div class='col-xs-5'>
                                <CardHeader>
                                  <center>
                                    <div>
                                      <label style={{ fontSize: '14px', fontWeight: 'bold' }}>Current Record :</label>
                                      <span>{this.state.swimsetrecord}</span>
                                    </div>
                                  </center>
                                </CardHeader>
                              </div>
                            </div>
                            <Form>
                              {Object.keys(this.state.event_data).length == 0 ? (
                                <Empty />
                              ) : (
                                <>
                                  <table class='worksheet_table'>
                                    <thead>
                                      <tr class='worksheet_tr'>
                                        <th>Work Field</th>
                                        <th>Final Group</th>
                                        <th>Heat</th>
                                        <th>Lane</th>
                                        <th>Class</th>
                                        <th>Combined class</th>
                                        <th>Age</th>
                                        <th>Gender</th>
                                        <th>Bib</th>
                                        <th>Last Name</th>
                                        <th>First Name</th>
                                        <th>Country</th>
                                        <th>Relay Points</th>
                                        <th>Event gender</th>
                                        <th>Team name</th>
                                        <th>Qual Stand</th>
                                        <th>Type Pool</th>
                                        <th>EventName</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody id='myTable'>
                                      {this.state.event_data.map((question, index) => (
                                        <tr class='worksheet_tr'>
                                          <td>{question.work_field}</td>
                                          <td>{question.final_group}</td>
                                          <td>{question.heat}</td>
                                          <td>{question.lane}</td>
                                          <td>{question.swim_class_value}</td>
                                          <td>{question.swim_class_value}</td>
                                          <td>{question.age_division}</td>
                                          <td>{question.gender}</td>
                                          <td>{question.bib_no}</td>
                                          <td>{question.last_name}</td>
                                          <td>{question.first_name}</td>
                                          <td>{question.country}</td>
                                          <td>{question.relay_points}</td>
                                          <td>{question.gender}</td>
                                          <td>{question.realy_team_name + '' + question.relay_letter}</td>
                                          <td>{question.qualifying_standard}</td>
                                          <td>{question.type_pool}</td>
                                          <td>{question.selected_events}</td>

                                          <td>
                                            <button
                                              type='button'
                                              rel='tooltip'
                                              class='btn btn-success btn-sm btn-icon'
                                              onClick={() => this.edit(index)}
                                              data-toggle='modal'
                                              data-target='#useredit'
                                            >
                                              <i class='now-ui-icons ui-2_settings-90'></i>
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </>
                              )}
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Preliminary Heatsetup
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-row '>
                            <div class='form-group col-md-6'>
                              <label for='inputCity'>Work</label>
                              <input
                                class='form-control'
                                value={this.state.workdata}
                                onChange={(e) => this.setState({ workdata: e.target.value })}
                                name='relayletter'
                              />
                            </div>

                            <div class='form-group col-md-6'>
                              <label for='inputCity'>Final Group</label>
                              <input
                                class='form-control'
                                value={this.state.finaldata}
                                onChange={(e) => this.setState({ finaldata: e.target.value })}
                                name='relaypoint'
                              />
                            </div>
                          </div>
                          <div class='form-row'>
                            <div class='form-group col-md-4'>
                              <label for='inputCity'>Heat</label>
                              <input
                                class='form-control'
                                value={this.state.heatdata}
                                onChange={(e) =>
                                  this.setState({
                                    heatdata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>

                            <div class='form-group col-md-4'>
                              <label for='inputCity'>Lane</label>
                              <input
                                class='form-control'
                                value={this.state.lanedata}
                                onChange={(e) =>
                                  this.setState({
                                    lanedata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>

                            <div class='form-group col-md-4'>
                              <label for='inputCity'>Type Pool</label>
                              <input
                                class='form-control'
                                value={this.state.rundata}
                                onChange={(e) => this.setState({ rundata: e.target.value })}
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          {/* </div> */}

                          <center>
                            <button type='button' class='btn btn-danger' onClick={(e) => this.update()}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (this.state.notrelay) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='venuesetup'>
                  <h1> </h1>
                  <div className='content'>
                    <Row>
                      <Col>
                        <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                          Back
                        </button>
                        <Card className='scrollcard'>
                          <CardHeader>
                            <h5 className='title'>
                              {this.state.event} <br></br> Preliminary Heat Setup
                            </h5>
                          </CardHeader>
                          <CardBody>
                            <div class='form-group row printrow'>
                              <div class='col-xs-5'>
                                <CardHeader>
                                  <center>
                                    <div>
                                      <label style={{ fontSize: '14px', fontWeight: 'bold' }}>Current Record :</label>
                                      <span>{this.state.swimsetrecord}</span>
                                    </div>
                                  </center>
                                </CardHeader>
                              </div>
                            </div>
                            <Form>
                              {Object.keys(this.state.event_data).length == 0 ? (
                                <Empty />
                              ) : (
                                <>
                                  <table class='worksheet_table'>
                                    <thead>
                                      <tr class='worksheet_tr'>
                                        <th>Heat</th>
                                        <th>Lane</th>
                                        <th>Class</th>
                                        <th>Age</th>
                                        <th>Gender</th>
                                        <th>Qualtime</th>
                                        <th>Bib</th>
                                        <th>Last Name</th>
                                        <th>First Name</th>
                                        <th>Country</th>
                                        <th>Qual Stand</th>
                                        <th>Type Pool</th>
                                        <th>EventName</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.event_data.map((question, index) => (
                                        <tr class='worksheet_tr'>
                                          <td>{question.heat}</td>
                                          <td>{question.lane}</td>
                                          <td>{question.swim_class_value}</td>
                                          <td>{question.age_division}</td>
                                          <td>{question.gender}</td>
                                          <td>{question.qualifying_time}</td>
                                          <td>{question.bib_no}</td>
                                          <td>{question.last_name}</td>
                                          <td>{question.first_name}</td>
                                          <td>{question.country}</td>
                                          <td>{question.qualifying_standard}</td>
                                          <td>{this.state.swimsets[0].type_pool}</td>
                                          <td>{question.selected_events}</td>

                                          <td>
                                            <button
                                              type='button'
                                              rel='tooltip'
                                              class='btn btn-success btn-sm btn-icon'
                                              onClick={() => this.edit(index)}
                                              data-toggle='modal'
                                              data-target='#useredit'
                                            >
                                              <i class='now-ui-icons ui-2_settings-90'></i>
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </>
                              )}
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Preliminary Heatsetup
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-row'>
                            <div class='form-group'>
                              <label for='inputCity'>Heat</label>
                              <input
                                class='form-control'
                                value={this.state.heatdata}
                                onChange={(e) =>
                                  this.setState({
                                    heatdata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group'>
                              <label for='inputCity'>Lane</label>
                              <input
                                class='form-control'
                                value={this.state.lanedata}
                                onChange={(e) =>
                                  this.setState({
                                    lanedata: e.target.value,
                                  })
                                }
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <center>
                            <button type='button' class='btn btn-danger' onClick={(e) => this.update()}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    return this.state.event == '4 X 25 Yd Medley' ||
      this.state.event == '4 X 25 Yd Free' ||
      this.state.event == '4 X 50 Yd Free' ||
      this.state.event == '4 X 50 Yd Medley' ||
      this.state.event == '4 X 50 M Free' ||
      this.state.event == '4 X 50 M Medley'
      ? (this.state.relay = true)
      : (this.state.notrelay = true);
  }
}

export default Swimprelimheatsetup;
