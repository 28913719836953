/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React from 'react';
import ReactToPrint from 'react-to-print';
import '../App.css';
import Printtrackresultsummary from './Printtrackresultsummary.js';

import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import 'datatables.net';
import 'datatables.net-bs4';
import fileDownload from 'js-file-download';
import Loader from 'react-loader-spinner';
// import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import Atheleteregister from './atheleteregister.js';
import Printalltrackworksheet from './Printalltrackworksheet';
import Printtennisresultsummary from './Printtennisresultsummary';
import Printweightliftsummary from './Printweightliftsummary';
import Printotherresultsummary from './Printotherresultsummary';
import Printarcheryresultsummary from './Printarcheryresultsummary';
import Printfieldresultsummary from './Printfieldresultsummary.js';
import Printswimresultsummary from './Printswimresultsummary.js';
import Printswimprelimworksheet from './Printswimprelimworksheet.js';
import Printtrackperformancerecord from './Printtrackperformancerecord';
import Printfieldperformancerecord from './Printfieldperformancerecord';
import Printswimperformancerecord from './Printswimperformancerecord';
import Printarcheryperformancerecord from './Printarcheryperformancerecord';
import Printweightliftperformancerecord from './PrintWeightliftperformancerecord';
import Printpitboximplement from './Printpitboximplement';
import Printarcheryscoresheet from './Printarcheryscoresheet';
import PreliminaryTrackresult from './Preliminarytrackresult';
const url = window.globalConfig;
class Venue_Mainpage extends React.Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    this.state = {
      venuedatas: [{}],
      newvenue: '',
      vname: '',
      vvalue: '',
      vedata: '',
      venuehome: false,
      eventhome: true,
      venueclasshome: false,
      venuespecfic: [{}],
      editename: '',
      editeid: '',
      newevents: '',
      newvenueclass: '',
      evenueclassname: '',
      evenueclassid: '',
      venuebyid: [{}],
      newclassvalue: '',
      vid: [{}],
      newclsvalue: '',
      newclsid: '',
      rerend: false,
      active1: false,
      loader: false,
      classdata: [{}],
      venuesetdata: [{}],
      selected_evt: true,
      selected_title: '',
      selected_main: queryParams.get('event'),
      preloader: true,
      tracksets: [{}],
      fieldsets: [{}],
      swimsets: [{}],
      archerysets: [{}],
      event_data: [{}],
      ovselectedevents: [],
      event_data: [],
      showprint: false,
      trackregdata: [{}],
      activeresult: false,
      heatdata: '',
      lanedata: '',
      showheatlanedata: [{}],
      prelim_reult_data: queryParams.get('heat'),
      eventname: '',
      finaldata: '',
      pitdata: '',
      showpitdata: [{}],
      fieldchecked: false,
      groupdata: '',
      benchdata: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.eventedit = this.eventedit.bind(this);
    this.updateevent = this.updateevent.bind(this);
    this.eventdelete = this.eventdelete.bind(this);
    this.createevent = this.createevent.bind(this);
    this.createclassvalue = this.createclassvalue.bind(this);
    this.updateclassvalue = this.updateclassvalue.bind(this);
    this.deleteclassvalue = this.deleteclassvalue.bind(this);
    this.backhome = this.backhome.bind(this);
    this.back = this.back.bind(this);
    this.venuechange = this.venuechange.bind(this);
    this.Selected_evt = this.Selected_evt.bind(this);
    this.fielddownload = this.fielddownload.bind(this);
    this.trackdownload = this.trackdownload.bind(this);
    this.build = this.build.bind(this);
    this.fieldbuild = this.fieldbuild.bind(this);
    this.fieldjsonimplement = this.fieldjsonimplement.bind(this);
  }
  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });
    var self = this;
    let ids = sessionStorage.getItem('ids');
    axios.get(`${url.url}/api/gettrackmeetid/${ids}`, {}).then(function (response) {
      self.setState({
        tracksets: response.data,
      });
    });

    axios.get(`${url.url}/api/getfieldmeetid/${ids}`, {}).then(function (response) {
      self.setState({
        fieldsets: response.data,
      });
    });

    axios.get(`${url.url}/api/getswimmeetid/${ids}`, {}).then(function (response) {
      self.setState({
        swimsets: response.data,
      });
    });

    axios.get(`${url.url}/api/getarcmeetid/${ids}`, {}).then(function (response) {
      self.setState({
        archerysets: response.data,
      });
    });

    var count = 0;
    let textval = sessionStorage.getItem('x');
    self.setState({ vedata: textval });
    axios.get(`${url.url}/api/venues`, {}).then(function (response) {
      self.setState({
        venuedatas: response.data.data,
        venuespecfic: response.data.data[textval],
      });
      count++;
      if (count == 2) {
        self.setState({
          preloader: false,
        });
      }
    });
    axios.get(`${url.url}/api/getallvenues`, {}).then(function (response) {
      self.setState({
        venuesetdata: response.data,
      });
      count++;
      if (count == 2) {
        self.setState({
          preloader: false,
        });
      }
    });

    document.getElementById('eventset').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    this.venuechange();
  }

  eventedit(e) {
    var self = this;
    self.setState({
      editename: this.state.venuespecfic.events[e].name,
      editeid: this.state.venuespecfic.events[e].id,
    });
  }

  async fielddownload(e) {
    var self = this;
    const data = {
      selected_events: e,
      meet_id: sessionStorage.getItem('ids'),
    };
    await axios
      .post(`${url.url}/api/getfieldcreatejsonlist/`, data, {
        responseType: 'blob',
      })
      .then(function (response) {
        fileDownload(response.data, e + '.json');
      })
      .catch(function (error) {});
  }
  async trackdownload(e) {
    var self = this;
    const data = {
      selected_events: e,
      meet_id: sessionStorage.getItem('ids'),
      sort: 'pits',
    };
    await axios
      .post(`${url.url}/api/getjsonregisterevent/`, data, {
        responseType: 'blob',
      })
      .then(function (response) {
        fileDownload(response.data, e + '.json');
      })

      .catch(function (error) {});
  }
  async build() {
    var self = this;
    const data = {
      meet_id: sessionStorage.getItem('ids'),
    };
    await axios
      .post(`${url.url}/api/gettrackjsonathletelist/`, data, {
        responseType: 'blob',
      })
      .then(function (response) {
        fileDownload(response.data, 'Track_Athletes.json');
      })
      .catch(function (error) {});
  }
  async fieldbuild() {
    var self = this;
    const data = {
      meet_id: sessionStorage.getItem('ids'),
    };
    await axios
      .post(`${url.url}/api/getfieldjsonathletelist/`, data, {
        responseType: 'blob',
      })
      .then(function (response) {
        fileDownload(response.data, 'Field_Athletes.json');
      })
      .catch(function (error) {});
  }

  async fieldjsonimplement() {
    var self = this;
    const data = {
      meet_id: sessionStorage.getItem('ids'),
    };
    await axios
      .post(`${url.url}/api/getfieldjsonimplementsdata/`, data, {
        responseType: 'blob',
      })
      .then(function (response) {
        fileDownload(response.data, 'All_Field_implement_data.json');
      })
      .catch(function (error) {});
  }

  updateevent(e) {
    var self = this;
    e.preventDefault();
    const datatosend = {
      name: this.state.editename,
      created_by: 1,
    };
    axios
      .put(`${url.url}/api/updateevent/${this.state.editeid}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            title: 'Success',
            text: 'Event updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  eventdelete(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deleteevent/${this.state.venuespecfic.events[e].id}`, {})
          .then(function (response) {})

          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });
  }

  createevent(e) {
    var self = this;
    e.preventDefault();
    const datatosend = {
      name: this.state.newevents,
      created_by: 1,
      venue_id: parseInt(this.state.venuespecfic.id),
    };
    axios
      .post(`${url.url}/api/createevent/`, datatosend, {})
      .then(function (response) {
        if (response.status == 201) {
          Swal.fire({
            title: 'Sucess',
            text: 'Event Created successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong Please check the field name',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  // event edit add delete ---------------------------------------------------------------

  // ----------------------------------------

  handleChange(e) {
    var self = this;
    self.setState({
      [e.target.name]: e.target.value,
    });
  }

  createclassvalue(e) {
    var self = this;
    e.preventDefault();
    let id = parseInt(this.state.venuespecfic.id);
    const datatosend = {
      name: this.state.newclassvalue,
      created_by: 1,
      venue_id: id,
    };
    axios
      .post(`${url.url}/api/createclassvalue/`, datatosend, {})
      .then(function (response) {
        if (response.status == 201) {
          Swal.fire({
            title: 'Sucess',
            text: 'Venue Class value Created Successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong Please check the field name',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  venueclassvalueedit(e) {
    var self = this;
    self.setState({
      newclsvalue: this.state.classdata[e].name,
      newclsid: this.state.classdata[e].id,
    });
  }

  updateclassvalue(e) {
    var self = this;
    e.preventDefault();
    const datatosend = {
      name: this.state.newclsvalue,
    };
    axios
      .put(`${url.url}/api/updateclass/${this.state.newclsid}`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 200) {
          Swal.fire({
            title: 'Success',
            text: 'Venue class value updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  deleteclassvalue(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deleteclass/${this.state.classdata[e].id}`, {})
          .then(function (response) {})

          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });
  }
  async venuechange(e) {
    $('.' + sessionStorage.getItem('event') + 'td').show();
    this.setState({
      selected_main: e,
    });
    if (e == 'Track') {
      sessionStorage.setItem('x', 0);
      sessionStorage.setItem('event', e);
    }

    if (e == 'Field') {
      sessionStorage.setItem('x', 1);
      sessionStorage.setItem('event', e);
    }

    if (e == 'Swimming') {
      sessionStorage.setItem('x', 2);
      sessionStorage.setItem('event', e);
    }

    if (e == 'Archery') {
      sessionStorage.setItem('x', 3);
      sessionStorage.setItem('event', e);
    }

    if (e == 'Para Powerlifting') {
      sessionStorage.setItem('x', 4);
      sessionStorage.setItem('event', e);
    }

    if (e == 'Table Tennis') {
      sessionStorage.setItem('x', 5);
      sessionStorage.setItem('event', e);
    }

    if (e == 'TeamSports') {
      sessionStorage.setItem('x', 6);
      sessionStorage.setItem('event', e);
    }

    if (e == 'Adaptive Tennis') {
      sessionStorage.setItem('x', 7);
      sessionStorage.setItem('event', e);
    }

    if (e == 'Other Venues') {
      sessionStorage.setItem('x', 8);
      sessionStorage.setItem('event', e);
      // window.location.replace("/venuemainpage")
    }

    var self = this;
    let textval = sessionStorage.getItem('x');
    self.setState({ vedata: textval });
    await axios.get(`${url.url}/api/venues`, {}).then(function (response) {
      self.setState({
        venuedatas: response.data.data,
        venuespecfic: response.data.data[textval],
      });
    });
    $('.' + sessionStorage.getItem('event') + 'td').hide();
  }
  Selected_evt(e) {
    this.setState({
      selected_evt: true,
      selected_title: e,
    });
  }

  //-------------------------------------------------
  back() {
    window.location.replace('/venuemainpage');
  }

  backhome() {
    var self = this;
    sessionStorage.removeItem('x');
    window.location.replace('/venuesetups');
  }

  checkvalue = (e) => {
    this.setState({
      showprint: false,
    });
    let ovselectedevents = this.state.ovselectedevents;
    if (!ovselectedevents.includes(e)) {
      ovselectedevents.push(e);
    } else {
      ovselectedevents = ovselectedevents.filter((item) => item !== e);
    }
    this.setState({
      ovselectedevents,
    });
    this.getprintresult(ovselectedevents);
  };

  getprintresult = async (selectedvalues) => {
    const data = {
      meet_id: sessionStorage.getItem('ids'),
      selected_events: selectedvalues,
    };
    await axios
      .post(`${url.url}/api/getprintothersummary/`, data, {})
      .then((response) => {
        this.setState({ event_data: response.data, loader: false });
        this.setState({
          showprint: true,
        });
      })
      .catch(function (error) {});
  };

  showothervenueprint = () => {
    this.setState({
      showprint: true,
    });
  };

  checkresult = (e) => {
    if (e.target.checked) {
      $('#' + e.target.id + '_final').prop('disabled', false);
      $('#' + e.target.id + '_prelim').prop('disabled', true);
    } else {
      $('#' + e.target.id + '_final').prop('disabled', true);
      $('#' + e.target.id + '_prelim').prop('disabled', false);
    }
  };

  isrelaycheckresult = (e) => {
    if (e.target.checked) {
      $('#' + e.target.id + '_relay').prop('disabled', false);
    } else {
      $('#' + e.target.id + '_relay').prop('disabled', true);
    }
  };

  isswimrelaycheckresult = (e) => {
    if (e.target.checked) {
      $('#' + e.target.id + '_swimrelay').prop('disabled', false);
    } else {
      $('#' + e.target.id + '_swimrelay').prop('disabled', true);
    }
  };
  results = (e) => {
    $('#auseredit1').addClass('show');
    $('#auseredit1').css('display', 'block');

    var self = this;
    self.setState({
      eventname: e,
    });
  };
  findheatlanedata = (eventname) => {
    $('#auseredit').addClass('show');
    $('#auseredit').css('display', 'block');

    var self = this;
    self.setState({
      eventname: eventname,
    });
  };
  swimfindheatlanedata = (eventname) => {
    $('#swimuseredit').addClass('show');
    $('#swimuseredit').css('display', 'block');

    var self = this;
    self.setState({
      eventname: eventname,
    });
  };

  checkboxChange1 = (e) => {
    var checkboxes = document.getElementsByName(e.target.name);
    checkboxes.forEach((item) => {
      if (item !== e.target) item.checked = false;
    });

    if (document.getElementById('filter_pit').checked) {
      document.getElementById('group').style.display = 'block';
      document.getElementById('heat').style.display = 'none';
    } else if (document.getElementById('filter_group').checked) {
      document.getElementById('group').style.display = 'none';
      document.getElementById('heat').style.display = 'block';
    } else if (document.getElementById('filter_finals').checked) {
      document.getElementById('group').style.display = 'none';
      document.getElementById('heat').style.display = 'none';
    } else {
      document.getElementById('heat').style.display = 'none';
      document.getElementById('group').style.display = 'none';
    }
  };

  ppupdate = () => {
    if (document.getElementById('no_filter').checked) {
      window.location.replace('/weightliftbenchresult?event=' + this.state.eventname + '&sortby=nofilter');
    }
    if (document.getElementById('bench_group').checked) {
      window.location.replace(
        '/weightliftbenchresult?event=' +
          this.state.eventname +
          '&bench=' +
          this.state.benchdata +
          '&group=' +
          this.state.groupdata
      );
    }
  };
  findheatlane = (eventname) => {
    var self = this;
    if (document.getElementById('no_filter').checked) {
      window.location.replace('/preliminary_track_results?event=' + this.state.eventname);
    } else if (document.getElementById('filter_pit').checked) {
      const datatosend = {
        selected_events: this.state.eventname,
        final_group: this.state.groupdata,
        run_during_final: this.state.finaldata,
        meet_id: sessionStorage.getItem('ids'),
      };
      window.location.replace(
        '/preliminary_track_results?final_group=' + self.state.groupdata + '&event=' + self.state.eventname
      );
    } else if (document.getElementById('filter_group').checked) {
      const datatosend = {
        selected_events: this.state.eventname,
        heat: this.state.heatdata,
        meet_id: sessionStorage.getItem('ids'),
      };
      window.location.replace(
        '/preliminary_track_results?heat=' + self.state.heatdata + '&event=' + self.state.eventname
      );
    } else if (document.getElementById('filter_finals').checked) {
      const datatosend = {
        selected_events: this.state.eventname,
        finals: 'finaldata',
        meet_id: sessionStorage.getItem('ids'),
      };

      window.location.replace(
        '/preliminary_track_results?finals=' + datatosend.finals + '&event=' + self.state.eventname
      );
    }
  };

  findpitdata = (eventname) => {
    $('#abuseredit').addClass('show');
    $('#abuseredit').css('display', 'block');

    var self = this;
    self.setState({
      eventname: eventname,
    });
  };

  checkboxChange4 = (e) => {
    var checkboxes = document.getElementsByName(e.target.name);
    checkboxes.forEach((item) => {
      if (item !== e.target) item.checked = false;
    });
    if (document.getElementById('bench_group').checked) {
      document.getElementById('sortgroup').style.display = 'block';
      document.getElementById('sortheat').style.display = 'block';
    } else {
      $('#bench_group').prop('checked', false);
      document.getElementById('sortheat').style.display = 'none';
      document.getElementById('sortgroup').style.display = 'none';
    }
  };
  checkboxChange = (e) => {
    var checkboxes = document.getElementsByName(e.target.name);
    checkboxes.forEach((item) => {
      if (item !== e.target) item.checked = false;
    });

    if (document.getElementById('filter_pit').checked) {
      document.getElementById('pit').style.display = 'block';
      document.getElementById('group').style.display = 'none';
    } else if (document.getElementById('filter_group').checked) {
      document.getElementById('pit').style.display = 'none';
      document.getElementById('group').style.display = 'block';
    } else {
      document.getElementById('pit').style.display = 'none';
      document.getElementById('group').style.display = 'none';
    }
  };
  checkboxChange3 = (e) => {
    var checkboxes = document.getElementsByName(e.target.name);
    checkboxes.forEach((item) => {
      if (item !== e.target) item.checked = false;
    });

    if (document.getElementById('filter_pit').checked) {
      document.getElementById('heat').style.display = 'block';
    } else if (document.getElementById('no_filter').checked) {
      document.getElementById('heat').style.display = 'none';
    } else {
      document.getElementById('heat').style.display = 'none';
    }
  };

  findpitdatas = (e) => {
    var self = this;
    if (document.getElementById('no_filter').checked) {
      window.location.replace('/field_results?event=' + this.state.eventname);
    } else if (document.getElementById('filter_pit').checked) {
      const datatosend = {
        selected_events: this.state.eventname,
        pits: this.state.pitdata,
        meet_id: sessionStorage.getItem('ids'),
      };
      axios
        .post(`${url.url}/api/geteventbypit/`, datatosend, {})
        .then(function (response) {
          self.setState({
            showpitdata: response.data,
          });
          window.location.replace('/field_results?pits=' + self.state.pitdata + '&event=' + self.state.eventname);
        })
        .catch(function (error) {});
    } else if (document.getElementById('filter_group').checked) {
      const datatosend = {
        selected_events: this.state.eventname,
        groups: this.state.groupdata,
        meet_id: sessionStorage.getItem('ids'),
      };
      axios
        .post(`${url.url}/api/geteventbypit/`, datatosend, {})
        .then(function (response) {
          self.setState({
            showpitdata: response.data,
          });
          window.location.replace('/field_results?groups=' + self.state.groupdata + '&event=' + self.state.eventname);
        })
        .catch(function (error) {});
    }
  };

  close = () => {
    $('#nofilter').prop('checked', false);
    $('#bench_group').prop('checked', false);
    $('#filter_pit').prop('checked', false);
    $('#filter_group').prop('checked', false);

    $('#auseredit1').removeClass('show');
    $('#auseredit1').css('display', 'none');

    $('#abuseredit').removeClass('show');
    $('#abuseredit').css('display', 'none');

    $('#auseredit').removeClass('show');
    $('#auseredit').css('display', 'none');

    $('#swimuseredit').removeClass('show');
    $('#swimuseredit').css('display', 'none');
  };
  swimfindheatdatas = (e) => {
    var self = this;
    if (document.getElementById('no_filter').checked) {
      window.location.replace('/Prelim_results?event=' + this.state.eventname);
    } else if (document.getElementById('filter_pit').checked) {
      const datatosend = {
        selected_events: this.state.eventname,
        heat: this.state.heatdata,
        meet_id: sessionStorage.getItem('ids'),
      };
      axios
        .post(`${url.url}/api/getswimregisterevent/`, datatosend, {})
        .then(function (response) {
          self.setState({
            showpitdata: response.data,
          });
          window.location.replace('/Prelim_results?heat=' + self.state.heatdata + '&event=' + self.state.eventname);
        })
        .catch(function (error) {});
    }
  };

  render() {
    let textval = sessionStorage.getItem('venueid');
    const queryParams = new URLSearchParams(window.location.search);

    // Event of tracks-------------------------------------------------------------------------------------------

    if (this.state.loader) {
      return <Redirect to='/venueclasses' />;
    }
    if (Object.keys(this.state.venuedatas[0]).length == 0) {
      return (
        <>
          {this.state.preloader ? (
            <>
              <div className='svg-wrapper'>
                <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div>
                  <div className='content'>
                    <Row>
                      <Col md='8'>
                        <Card>
                          <CardHeader>
                            <h5 className='title'>Venues</h5>
                          </CardHeader>
                          <CardBody>
                            <Form>
                              <div class='form-group row'>
                                <div class='col-xs-5'>
                                  <CardHeader>
                                    <p>Search</p>
                                    <input class='form-control' id='inputsm' placeholder='Search..' type='text' />
                                  </CardHeader>
                                </div>
                              </div>
                              {/* Table -------------------------------------------- */}
                              <Table id='my' responsive>
                                <thead>
                                  <tr>
                                    <th className='text-center'>#</th>
                                    <th>Name</th>
                                    <th class='disabled-sorting text-right'>Actions</th>
                                  </tr>
                                </thead>
                                <tbody id='myTable'>
                                  <tr>
                                    <td>1</td>
                                    <td></td>
                                    <td class='td-actions text-right'>
                                      <button type='button' rel='tooltip' class='btn btn-success btn-sm btn-icon'>
                                        <i class='now-ui-icons ui-2_settings-90'></i>
                                      </button>
                                      {` `}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                              {/* Table -------------------------------------------- */}
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }

    // events ----------------------------------------------------------------------------------------

    if (!this.state.selected_evt || this.state.selected_main == '') {
      return (
        <div>
          <div className='content'>
            <Row>
              <Col md='6'>
                <button type='button' class='btn btn-danger btn-round' onClick={this.backhome}>
                  Back
                </button>
                {sessionStorage.getItem('event') ? (
                  <button
                    type='button'
                    class='btn btn-danger btn-round'
                    onClick={() => window.location.replace('/assignpits')}
                  >
                    Assign Pits
                  </button>
                ) : (
                  ''
                )}
                <Card>
                  <CardHeader>
                    <h5 className='title'>Events</h5>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <div class='form-group row'>
                        <div class='col-xs-5'>
                          <CardHeader>
                            <p>Search</p>
                            <input class='form-control' id='inputsm' placeholder='Search..' type='text' />
                          </CardHeader>
                        </div>
                      </div>
                      <div id='inline_view'>
                        <Table id='my' responsive>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Heats</th>
                              <th>Results</th>
                              <th>Relay</th>
                              <th>Special</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody id='myTable'>
                            {this.state.venuespecfic.events.map((question, index) => (
                              <tr>
                                <td>{question.name}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      );
    }
    if (queryParams.get('event') == 'Track') {
      return (
        <div>
          <div className='content'>
            <Row>
              <Col md='8'>
                <button type='button' class='btn btn-danger btn-round' onClick={this.backhome}>
                  Back
                </button>

                <Card>
                  <CardHeader>
                    <h5 className='title'>Events</h5>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <div class='form-group row printrow'>
                        <div class='col-xs-5'>
                          <CardHeader>
                            {/* <p>Search</p>
                            <input
                              class="form-control"
                              id="inputsm"
                              placeholder="Search.."
                              type="text"
                            /> */}
                            <center>
                              <div class='printrow1'>
                                <div>
                                  <ReactToPrint
                                    trigger={() => (
                                      <button type='button' class='btn btn-success printbtn'>
                                        Print All Prelim Worksheets
                                      </button>
                                    )}
                                    content={() => this.componentRef1}
                                  />
                                  <div class='textnone' style={{ display: 'none' }}>
                                    <Printalltrackworksheet ref={(el) => (this.componentRef1 = el)} />
                                  </div>
                                </div>
                                <div>
                                  <ReactToPrint
                                    trigger={() => (
                                      <button type='button' class='btn btn-success printbtn'>
                                        Print Result Summary
                                      </button>
                                    )}
                                    content={() => this.componentRef}
                                  />
                                  <div class='textnone' style={{ display: 'none' }}>
                                    <Printtrackresultsummary ref={(el) => (this.componentRef = el)} />
                                  </div>
                                </div>
                                <div>
                                  <ReactToPrint
                                    trigger={() => (
                                      <button type='button' class='btn btn-success printbtn'>
                                        Print All New Performance Records
                                      </button>
                                    )}
                                    content={() => this.componentRef2}
                                  />
                                  <div class='textnone' style={{ display: 'none' }}>
                                    <Printtrackperformancerecord ref={(el) => (this.componentRef2 = el)} />
                                  </div>
                                </div>

                                <button type='button' class='btn btn-success printbtn' onClick={() => this.build()}>
                                  Build json athlete list
                                </button>
                                <button type='button' class='btn btn-success printbtn'>
                                  Meet Manager Setup File
                                </button>
                                {/* <button
                                  type="button"
                                  class="btn btn-success printbtn"
                                >
                                  Finish Lynx Import file
                                </button> */}
                                <button type='button' class='btn btn-success printbtn'>
                                  Meet Manager Results File
                                </button>
                              </div>
                            </center>
                          </CardHeader>
                        </div>
                      </div>
                      <div id='inline_view'>
                        <Table id='my' responsive>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Heats Setup</th>
                              {/* <th>Run as Finals</th> */}
                              <th>Results</th>
                              {/* <th>Setup Relay</th> */}
                              <th>Relay</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody id='myTable'>
                            {this.state.tracksets.map((question, index) => (
                              <tr>
                                <td>{question.event_name}</td>
                                <td>
                                  <button
                                    type='button'
                                    class='btn btn-success'
                                    onClick={() =>
                                      window.location.replace('/Trackworksheet?event=' + question.event_name)
                                    }
                                  >
                                    Prelim/Final
                                  </button>{' '}
                                  {/* <button
                                      type="button"
                                      class="btn btn-danger"
                                      onClick={() =>
                                        window.location.replace(
                                          "/Trackfinalworksheet?event=" +
                                          question.event_name
                                        )}
                                    >
                                      Final
                                    </button>{" "} */}
                                  {question.event_name == '100 Meters' ? (
                                    <button
                                      type='button'
                                      class='btn btn-warning'
                                      onClick={() =>
                                        window.location.replace('/special_heat?event=' + question.event_name)
                                      }
                                    >
                                      Special
                                    </button>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                                {/* <td>
                                  <input
                                    type="checkbox"
                                    className="checkresultbox"
                                    defaultChecked={true}
                                    name={"chk_" + index}
                                    id={question.id}
                                    onChange={(e) => this.checkresult(e)}
                                  />
                                </td> */}
                                <td>
                                  <button
                                    type='button'
                                    class='btn btn-danger prelimresult'
                                    id={question.id + '_prelim'}
                                    onClick={() => this.findheatlanedata(question.event_name)}
                                  >
                                    Prelim/Final
                                  </button>{' '}
                                </td>
                                <td>
                                  {question.relay == 'Yes' ? (
                                    <button
                                      type='button'
                                      class='btn btn-success relaytrack'
                                      id={question.id + '_relay'}
                                      onClick={() =>
                                        window.location.replace('/relaysetup?event=' + question.event_name)
                                      }
                                    >
                                      Initial Setup
                                    </button>
                                  ) : (
                                    ''
                                  )}
                                </td>
                                <td>
                                  <button
                                    type='button'
                                    class='btn btn-success'
                                    onClick={() =>
                                      window.location.replace('/Trackresultworksheet?event=' + question.event_name)
                                    }
                                  >
                                    Print Results
                                  </button>{' '}
                                  <button
                                    type='button'
                                    class='btn btn-danger'
                                    onClick={() => this.trackdownload(question.event_name)}
                                  >
                                    Create json File
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div class='modal fade' id='auseredit' tabindex='-1' role='dialog' aria-hidden='true'>
                          <div class='modal-dialog'>
                            <div class='modal-content'>
                              <div class='modal-header '>
                                <h5 class='modal-title ' id='loginmodalLabel'>
                                  Preliminary Result
                                </h5>
                                <button
                                  type='button'
                                  class='close'
                                  data-dismiss='modal'
                                  aria-label='Close'
                                  onClick={this.close}
                                >
                                  <span aria-hidden='true'>&times;</span>
                                </button>
                              </div>
                              <div class='modal-body'>
                                <form>
                                  <div class='form-row '>
                                    <div class='form-group' style={{ textAlign: 'center' }}>
                                      <input
                                        type='checkbox'
                                        id='no_filter'
                                        name='chklift1'
                                        value='nofilter'
                                        onChange={(e) => this.checkboxChange1(e)}
                                        // onClick={this.checkValue}
                                        style={{ marginLeft: '10px', marginRight: '10px' }}
                                      />
                                      &nbsp;No Filter &nbsp;
                                      <input
                                        type='checkbox'
                                        id='filter_pit'
                                        name='chklift1'
                                        value='filterbypit'
                                        onChange={(e) => this.checkboxChange1(e)}
                                        // onClick={this.checkValue}
                                        style={{ marginLeft: '10px', marginRight: '10px' }}
                                      />
                                      &nbsp;Filter By Group
                                      <input
                                        type='checkbox'
                                        id='filter_group'
                                        name='chklift1'
                                        value='filterbygroup'
                                        onChange={(e) => this.checkboxChange1(e)}
                                        // onClick={this.checkValue}
                                        style={{ marginLeft: '10px', marginRight: '10px' }}
                                      />{' '}
                                      &nbsp;Filter By Heat
                                      <input
                                        type='checkbox'
                                        id='filter_finals'
                                        name='chklift1'
                                        value='filterbyfinals'
                                        onChange={(e) => this.checkboxChange1(e)}
                                        // onClick={this.checkValue}
                                        style={{ marginLeft: '10px', marginRight: '10px' }}
                                      />
                                      &nbsp;Filter by Run as Finals = No
                                    </div>
                                    <div class='form-group'>
                                      <div class='form-group'>
                                        {/* <label>Group</label>                                                   */}
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder='Enter a Group'
                                          id='group'
                                          onChange={(e) => this.setState({ groupdata: e.target.value })}
                                          style={{ display: 'none' }}
                                        />
                                      </div>

                                      <div class='form-group'>
                                        {/* <label>Heat</label>                                       */}
                                        <input
                                          type='text'
                                          class='form-control'
                                          placeholder='Enter a Heat'
                                          id='heat'
                                          onChange={(e) => this.setState({ heatdata: e.target.value })}
                                          style={{ display: 'none' }}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <center>
                                    <button type='button' class='btn btn-danger' onClick={(e) => this.findheatlane()}>
                                      Update
                                    </button>
                                  </center>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      );
    }
    if (queryParams.get('event') == 'Field') {
      return (
        <>
          {this.state.preloader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='content'>
                  <Row>
                    <Col md='8'>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.backhome}>
                        Back
                      </button>
                      {sessionStorage.getItem('event') ? (
                        <button
                          type='button'
                          class='btn btn-danger btn-round'
                          onClick={() => window.location.replace('/assignpits')}
                        >
                          Assign Pits
                        </button>
                      ) : (
                        ''
                      )}
                      {sessionStorage.getItem('event') ? (
                        <button
                          type='button'
                          class='btn btn-danger btn-round'
                          onClick={() => window.location.replace('/implement_file')}
                        >
                          Implements
                        </button>
                      ) : (
                        ''
                      )}
                      <Card>
                        <CardHeader>
                          <h5 className='title'>Events</h5>
                        </CardHeader>
                        <CardBody>
                          <Form>
                            <div class='form-group row printrow'>
                              <div class='col-xs-5'>
                                <CardHeader>
                                  <center>
                                    <div class='printrow1'>
                                      <div>
                                        <ReactToPrint
                                          trigger={() => (
                                            <button type='button' class='btn btn-success printbtn'>
                                              Print Result Summary
                                            </button>
                                          )}
                                          content={() => this.componentRef}
                                        />
                                        <div class='textnone' style={{ display: 'none' }}>
                                          <Printfieldresultsummary ref={(el) => (this.componentRef = el)} />
                                        </div>
                                      </div>
                                      <div>
                                        <ReactToPrint
                                          trigger={() => (
                                            <button type='button' class='btn btn-success printbtn'>
                                              Print All New Performance Records
                                            </button>
                                          )}
                                          content={() => this.componentRef1}
                                        />
                                        <div class='textnone' style={{ display: 'none' }}>
                                          <Printfieldperformancerecord ref={(el) => (this.componentRef1 = el)} />
                                        </div>
                                      </div>
                                      <button
                                        type='button'
                                        class='btn btn-success printbtn'
                                        onClick={() => this.fieldbuild()}
                                      >
                                        Build json athlete list
                                      </button>
                                      <div>
                                        <ReactToPrint
                                          trigger={() => (
                                            <button type='button' class='btn btn-success printbtn'>
                                              Print Pit Box Implements
                                            </button>
                                          )}
                                          content={() => this.componentRef2}
                                        />
                                        <div class='textnone' style={{ display: 'none' }}>
                                          <Printpitboximplement ref={(el) => (this.componentRef2 = el)} />
                                        </div>

                                        <button
                                          type='button'
                                          class='btn btn-success printbtn'
                                          onClick={() => this.fieldjsonimplement()}
                                        >
                                          Build json Implement list
                                        </button>
                                        <button type='button' class='btn btn-success printbtn'>
                                          Meet Manager Results File
                                        </button>
                                      </div>
                                    </div>
                                  </center>
                                </CardHeader>
                              </div>
                            </div>
                            <div id='inline_view'>
                              <Table id='my' responsive>
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Results</th>
                                    <th>Assignment</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody id='myTable'>
                                  {this.state.fieldsets.map((question, index) => (
                                    <tr>
                                      <td>{question.event_name}</td>
                                      <td>
                                        <button
                                          type='button'
                                          class='btn btn-success'
                                          // onClick={() =>
                                          //   window.location.replace(
                                          //     "/field_results?event=" +
                                          //       question.event_name +
                                          //       "&sort=pits"
                                          //   )
                                          // }
                                          onClick={() => this.findpitdata(question.event_name)}
                                        >
                                          By Pit
                                        </button>{' '}
                                        {/* <button
                                            type="button"
                                            class="btn btn-success"
                                            onClick={() =>
                                              window.location.replace(
                                                "/Display_all_pits?event=" +
                                                  question.event_name +
                                                  "&sort=pits"
                                              )
                                            }
                                            // onClick={()=>this.findpitdata(question.event_name)}
                                          >
                                            Display ALL
                                          </button>{" "} */}
                                        {/* <button
                                            type="button"
                                            class="btn btn-danger"
                                            onClick={() =>
                                              window.location.replace(
                                                "/field_results?event=" +
                                                question.event_name +
                                                "&sort=field_class_value"
                                              )
                                            }
                                          >
                                            By Class
                                          </button>{" "} */}
                                        {/* <button
                                            type="button"
                                            class="btn btn-warning"
                                            onClick={() =>
                                              window.location.replace(
                                                "/field_show_results?event=" +
                                                question.event_name
                                              )
                                            }
                                          >
                                            Worksheet
                                          </button> */}
                                      </td>
                                      <td>
                                        <button
                                          type='button'
                                          class='btn btn-success'
                                          onClick={() =>
                                            window.location.replace(
                                              '/field_pit_assignment?event=' + question.event_name
                                            )
                                          }
                                        >
                                          Pit
                                        </button>
                                      </td>
                                      <td>
                                        {/* <button
                                            type="button"
                                            class="btn btn-success"
                                          >
                                            GOTO Combined Event
                                          </button>{" "} */}
                                        <button
                                          type='button'
                                          class='btn btn-warning'
                                          onClick={() =>
                                            window.location.replace('/field_show_results?event=' + question.event_name)
                                          }
                                        >
                                          Print Results
                                        </button>{' '}
                                        <button
                                          type='button'
                                          class='btn btn-danger'
                                          onClick={() => this.fielddownload(question.event_name)}
                                        >
                                          Create json File
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                            <div class='modal fade' id='abuseredit' tabindex='-1' role='dialog' aria-hidden='true'>
                              <div class='modal-dialog'>
                                <div class='modal-content'>
                                  <div class='modal-header '>
                                    <h5 class='modal-title ' id='loginmodalLabel'>
                                      By Pit Entry
                                    </h5>
                                    <button
                                      type='button'
                                      class='close'
                                      data-dismiss='modal'
                                      aria-label='Close'
                                      onClick={this.close}
                                    >
                                      <span aria-hidden='true'>&times;</span>
                                    </button>
                                  </div>
                                  <div class='modal-body'>
                                    <form>
                                      <div class='form-row '>
                                        <div class='form-group' style={{ textAlign: 'center' }}>
                                          <input
                                            type='checkbox'
                                            id='no_filter'
                                            name='chklift1'
                                            value='nofilter'
                                            onChange={(e) => this.checkboxChange(e)}
                                            // onClick={this.checkValue}
                                            style={{ marginLeft: '10px', marginRight: '10px' }}
                                          />
                                          &nbsp;No Filter &nbsp;
                                          <input
                                            type='checkbox'
                                            id='filter_pit'
                                            name='chklift1'
                                            value='filterbypit'
                                            onChange={(e) => this.checkboxChange(e)}
                                            // onClick={this.checkValue}
                                            style={{ marginLeft: '10px', marginRight: '10px' }}
                                          />
                                          &nbsp;Filter By Pit
                                          <input
                                            type='checkbox'
                                            id='filter_group'
                                            name='chklift1'
                                            value='filterbygroup'
                                            onChange={(e) => this.checkboxChange(e)}
                                            // onClick={this.checkValue}
                                            style={{ marginLeft: '10px', marginRight: '10px' }}
                                          />{' '}
                                          &nbsp;Filter By Group
                                        </div>
                                        <div class='form-group'>
                                          <div class='form-group'>
                                            <input
                                              type='text'
                                              class='form-control'
                                              placeholder='Enter a Pit'
                                              id='pit'
                                              onChange={(e) => this.setState({ pitdata: e.target.value })}
                                              style={{ display: 'none' }}
                                            />
                                          </div>

                                          <div class='form-group'>
                                            <input
                                              type='text'
                                              class='form-control'
                                              placeholder='Enter a Group'
                                              id='group'
                                              onChange={(e) => this.setState({ groupdata: e.target.value })}
                                              style={{ display: 'none' }}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <center>
                                        <button
                                          type='button'
                                          class='btn btn-danger'
                                          onClick={(e) => this.findpitdatas(e)}
                                        >
                                          Update
                                        </button>
                                      </center>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (queryParams.get('event') == 'Swimming') {
      return (
        <>
          {this.state.preloader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='content'>
                  <Row>
                    <Col md='8'>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.backhome}>
                        Back
                      </button>
                      <Card>
                        <CardHeader>
                          <h5 className='title'>Events</h5>
                        </CardHeader>
                        <CardBody>
                          <Form>
                            <div class='form-group row printrow'>
                              <div class='col-xs-5'>
                                <CardHeader>
                                  <center>
                                    <div class='printrow1'>
                                      <div>
                                        <ReactToPrint
                                          trigger={() => (
                                            <button type='button' class='btn btn-success printbtn'>
                                              Print Result Summary
                                            </button>
                                          )}
                                          content={() => this.componentRef}
                                        />
                                        <div class='textnone' style={{ display: 'none' }}>
                                          <Printswimresultsummary ref={(el) => (this.componentRef = el)} />
                                        </div>
                                      </div>
                                      <div>
                                        <ReactToPrint
                                          trigger={() => (
                                            <button type='button' class='btn btn-success printbtn'>
                                              Print All New Performance Records
                                            </button>
                                          )}
                                          content={() => this.componentRef1}
                                        />
                                        <div class='textnone' style={{ display: 'none' }}>
                                          <Printswimperformancerecord ref={(el) => (this.componentRef1 = el)} />
                                        </div>
                                      </div>
                                      <button type='button' class='btn btn-success printbtn'>
                                        Meet Manager Setup file
                                      </button>
                                      <button type='button' class='btn btn-success printbtn'>
                                        Meet Manager Results File
                                      </button>
                                    </div>
                                  </center>
                                </CardHeader>
                              </div>
                            </div>
                            <div id='inline_view'>
                              <Table id='my' responsive>
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Heats</th>
                                    <th>Results</th>
                                    <th>Relay</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody id='myTable'>
                                  {this.state.swimsets.map((question, index) => (
                                    <tr>
                                      <td>{question.event_name}</td>
                                      <td>
                                        <button
                                          type='button'
                                          class='btn btn-success'
                                          onClick={() =>
                                            window.location.replace(
                                              '/Prelim_heatsetup?event=' +
                                                question.event_name +
                                                '_' +
                                                question.type_pool
                                            )
                                          }
                                        >
                                          Prelim/Final
                                        </button>{' '}
                                      </td>

                                      <td>
                                        <button
                                          type='button'
                                          class='btn btn-danger'
                                          onClick={() =>
                                            this.swimfindheatlanedata(question.event_name + '_' + question.type_pool)
                                          }
                                        >
                                          Prelim/Final
                                        </button>
                                      </td>
                                      <td>
                                        {question.relay === 'Yes' ? (
                                          <button
                                            type='button'
                                            class='btn btn-success relayswim'
                                            id={question.id + '_swimrelay'}
                                            onClick={() =>
                                              window.location.replace(
                                                '/relay_initial_setup?event=' + question.event_name
                                              )
                                            }
                                          >
                                            Initial Setup
                                          </button>
                                        ) : (
                                          ''
                                        )}
                                      </td>
                                      <td>
                                        <button
                                          type='button'
                                          class='btn btn-success'
                                          onClick={() =>
                                            window.location.replace(
                                              '/show_result_worksheet?event=' + question.event_name
                                            )
                                          }
                                        >
                                          Print Results
                                        </button>{' '}
                                        <button type='button' class='btn btn-danger'>
                                          Create json File
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                              <div class='modal fade' id='swimuseredit' tabindex='-1' role='dialog' aria-hidden='true'>
                                <div class='modal-dialog'>
                                  <div class='modal-content'>
                                    <div class='modal-header '>
                                      <h5 class='modal-title ' id='loginmodalLabel'>
                                        Preliminary Result
                                      </h5>
                                      <button
                                        type='button'
                                        class='close'
                                        data-dismiss='modal'
                                        aria-label='Close'
                                        onClick={this.close}
                                      >
                                        <span aria-hidden='true'>&times;</span>
                                      </button>
                                    </div>
                                    <div class='modal-body'>
                                      <form>
                                        <div class='form-row '>
                                          <div class='form-group' style={{ textAlign: 'center' }}>
                                            <input
                                              type='checkbox'
                                              id='no_filter'
                                              name='chklift1'
                                              value='nofilter'
                                              onChange={(e) => this.checkboxChange3(e)}
                                              // onClick={this.checkValue}
                                              style={{ marginLeft: '10px', marginRight: '10px' }}
                                            />
                                            &nbsp;No Filter &nbsp;
                                            <input
                                              type='checkbox'
                                              id='filter_pit'
                                              name='chklift1'
                                              value='filterbygroup'
                                              onChange={(e) => this.checkboxChange3(e)}
                                              // onClick={this.checkValue}
                                              style={{ marginLeft: '10px', marginRight: '10px' }}
                                            />{' '}
                                            &nbsp;Filter By Heat
                                          </div>
                                          <div class='form-group'>
                                            <div class='form-group'>
                                              <input
                                                type='text'
                                                class='form-control'
                                                placeholder='Enter a Heat'
                                                id='heat'
                                                onChange={(e) => this.setState({ heatdata: e.target.value })}
                                                style={{ display: 'none' }}
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <center>
                                          <button
                                            type='button'
                                            class='btn btn-danger'
                                            onClick={(e) => this.swimfindheatdatas()}
                                          >
                                            Update
                                          </button>
                                        </center>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (queryParams.get('event') == 'Archery') {
      return (
        <>
          {this.state.preloader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='content'>
                  <Row>
                    <Col md='8'>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.backhome}>
                        Back
                      </button>
                      {sessionStorage.getItem('event') ? (
                        <button
                          type='button'
                          class='btn btn-danger btn-round'
                          onClick={() => window.location.replace('/assigntarget')}
                        >
                          Assign Target
                        </button>
                      ) : (
                        ''
                      )}
                      <Card>
                        <CardHeader>
                          <h5 className='title'>Events</h5>
                        </CardHeader>
                        <CardBody>
                          <Form>
                            <div class='form-group row printrow'>
                              <div class='col-xs-5'>
                                <CardHeader>
                                  <center>
                                    <div class='printrow1'>
                                      <div>
                                        <ReactToPrint
                                          trigger={() => (
                                            <button type='button' class='btn btn-success printbtn'>
                                              Print results summary
                                            </button>
                                          )}
                                          content={() => this.componentRef}
                                        />
                                        <div class='textnone' style={{ display: 'none' }}>
                                          <Printarcheryresultsummary ref={(el) => (this.componentRef = el)} />
                                        </div>
                                      </div>
                                      <div>
                                        <ReactToPrint
                                          trigger={() => (
                                            <button type='button' class='btn btn-success printbtn'>
                                              Print Score Sheets
                                            </button>
                                          )}
                                          content={() => this.componentRef3}
                                        />
                                        <div class='textnone' style={{ display: 'none' }}>
                                          <Printarcheryscoresheet ref={(el) => (this.componentRef3 = el)} />
                                        </div>
                                      </div>
                                    </div>
                                  </center>
                                </CardHeader>
                              </div>
                            </div>
                            <div id='inline_view'>
                              <Table id='my' responsive>
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Results</th>
                                    <th>Assignment</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody id='myTable'>
                                  {this.state.archerysets.map((question, index) => (
                                    <tr>
                                      <td>{question.event_name}</td>
                                      <td>
                                        <button
                                          type='button'
                                          class='btn btn-success'
                                          onClick={() =>
                                            window.location.replace('/archerytargetresult?event=' + question.event_name)
                                          }
                                        >
                                          By Target
                                        </button>{' '}
                                        {/* <button
                                            type="button"
                                            class="btn btn-danger"
                                            onClick={() =>
                                              window.location.replace(
                                                "/archeryclassresult?event=" +
                                                question.event_name
                                              )
                                            }
                                          >
                                            By Class
                                          </button>{" "} */}
                                      </td>
                                      <td>
                                        <button
                                          type='button'
                                          class='btn btn-success'
                                          onClick={() =>
                                            window.location.replace(
                                              '/archerytargetassignment?event=' + question.event_name
                                            )
                                          }
                                        >
                                          Target
                                        </button>
                                      </td>
                                      <td>
                                        <button
                                          type='button'
                                          class='btn btn-warning'
                                          onClick={() =>
                                            window.location.replace('/archeryworksheet?event=' + question.event_name)
                                          }
                                        >
                                          Print Results
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (queryParams.get('event') == 'Para Powerlifting') {
      return (
        <>
          {this.state.preloader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='content'>
                  <Row>
                    <Col md='8'>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.backhome}>
                        Back
                      </button>
                      {sessionStorage.getItem('event') ? (
                        <button
                          type='button'
                          class='btn btn-danger btn-round'
                          onClick={() => window.location.replace('/Assignbench')}
                        >
                          Assign Benches
                        </button>
                      ) : (
                        ''
                      )}
                      <Card>
                        <CardHeader>
                          <h5 className='title'>Events</h5>
                        </CardHeader>
                        <CardBody>
                          <Form>
                            <div class='form-group row printrow'>
                              <div class='col-xs-5'>
                                <CardHeader>
                                  <center>
                                    <div class='printrow1'>
                                      {/* <div>
                                        <ReactToPrint
                                          trigger={() => (
                                            <button type='button' class='btn btn-success printbtn'>
                                              Print all new performance records
                                            </button>
                                          )}
                                          content={() => this.componentRef1}
                                        />
                                        <div class='textnone' style={{ display: 'none' }}>
                                          <Printweightliftperformancerecord ref={(el) => (this.componentRef1 = el)} />
                                        </div>
                                      </div> */}
                                      <div>
                                        <ReactToPrint
                                          trigger={() => (
                                            <button type='button' class='btn btn-success printbtn'>
                                              Print results summary
                                            </button>
                                          )}
                                          content={() => this.componentRef}
                                        />
                                        <div class='textnone' style={{ display: 'none' }}>
                                          <Printweightliftsummary ref={(el) => (this.componentRef = el)} />
                                        </div>
                                      </div>
                                    </div>
                                  </center>
                                </CardHeader>
                              </div>
                            </div>
                            <div id='inline_view'>
                              <Table id='my' responsive>
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Results</th>
                                    <th>Assignment</th>
                                  </tr>
                                </thead>
                                <tbody id='myTable'>
                                  {this.state.venuespecfic.events.map((question, index) => (
                                    <tr>
                                      <td>{question.name}</td>
                                      <td>
                                        <button
                                          type='button'
                                          class='btn btn-success'
                                          onClick={() => this.results(question.name)}
                                        >
                                          Class/Gender
                                        </button>{' '}
                                        {/* <button
                                            type="button"
                                            class="btn btn-danger"
                                            onClick={() =>
                                              window.location.replace(
                                                "/weightliftbenchclass?event=" +
                                                question.name
                                              )
                                            }
                                          >
                                            By Class
                                          </button>{" "} */}
                                        {/* <button
                                            type="button"
                                            class="btn btn-warning"
                                            onClick={() =>
                                              window.location.replace(
                                                "/weightliftworksheet?event=" +
                                                question.name
                                              )
                                            }
                                          >
                                            Worksheet
                                          </button> */}
                                      </td>
                                      <td>
                                        <button
                                          type='button'
                                          class='btn btn-success'
                                          onClick={() =>
                                            window.location.replace('/benchassignment?event=' + question.name)
                                          }
                                        >
                                          Bench/Groups
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                              <div class='modal fade' id='auseredit1' tabindex='-1' role='dialog' aria-hidden='true'>
                                <div class='modal-dialog'>
                                  <div class='modal-content'>
                                    <div class='modal-header '>
                                      <h5 class='modal-title ' id='loginmodalLabel'>
                                        Preliminary Result
                                      </h5>
                                      <button
                                        type='button'
                                        class='close'
                                        data-dismiss='modal'
                                        aria-label='Close'
                                        onClick={this.close}
                                      >
                                        <span aria-hidden='true'>&times;</span>
                                      </button>
                                    </div>
                                    <div class='modal-body'>
                                      <form>
                                        <div class='form-row'>
                                          <div class='form-group' style={{ textAlign: 'center' }}>
                                            <input
                                              type='checkbox'
                                              id='no_filter'
                                              name='chklift1'
                                              value='nofilter'
                                              onChange={(e) => this.checkboxChange4(e)}
                                              // onClick={this.checkValue}
                                              style={{ marginLeft: '15px', marginRight: '10px', marginBottom: '15px' }}
                                            />
                                            &nbsp;No Filter &nbsp;
                                            <input
                                              type='checkbox'
                                              id='bench_group'
                                              name='chklift1'
                                              value='filterbypit'
                                              onChange={(e) => this.checkboxChange4(e)}
                                              // onClick={this.checkValue}
                                              style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '15px' }}
                                            />
                                            &nbsp;Bench/Group
                                          </div>
                                          <div class='form-row' style={{ display: 'contents' }}>
                                            <div class='form-group col-md-6'>
                                              {/* <label>Heat</label>                                       */}
                                              <input
                                                type='text'
                                                class='form-control'
                                                placeholder='Enter a Bench'
                                                id='sortheat'
                                                onChange={(e) => this.setState({ benchdata: e.target.value })}
                                                style={{ display: 'none' }}
                                              />
                                            </div>
                                            <div class='form-group col-md-6'>
                                              {/* <label>Group</label>                                                   */}
                                              <input
                                                type='text'
                                                class='form-control'
                                                placeholder='Enter a Group'
                                                id='sortgroup'
                                                onChange={(e) => this.setState({ groupdata: e.target.value })}
                                                style={{ display: 'none' }}
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <center>
                                          <button type='button' class='btn btn-danger' onClick={(e) => this.ppupdate()}>
                                            Update
                                          </button>
                                        </center>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          )}
        </>
      );
    }

    if (queryParams.get('event') == 'Table Tennis') {
      return (
        <>
          {this.state.preloader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='content'>
                  <Row>
                    <Col md='8'>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.backhome}>
                        Back
                      </button>
                      <Card>
                        <CardHeader>
                          <h5 className='title'>Events</h5>
                        </CardHeader>
                        <CardBody>
                          <Form>
                            <div id='inline_view'>
                              <Table id='my' responsive>
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Setup/Results</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody id='myTable'>
                                  {this.state.venuespecfic.events.map((question, index) => (
                                    <tr>
                                      <td>{question.name}</td>
                                      <td>
                                        <button
                                          type='button'
                                          class='btn btn-success'
                                          onClick={() =>
                                            window.location.replace('/tennisworksheet?event=' + question.name)
                                          }
                                        >
                                          Worksheet
                                        </button>
                                      </td>
                                      <td>
                                        <button
                                          type='button'
                                          class='btn btn-warning'
                                          onClick={() => window.location.replace('/tennisprint?event=' + question.name)}
                                        >
                                          Print Results
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (queryParams.get('event') == 'Other Venues') {
      return (
        <>
          {this.state.preloader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div>
                  <div className='content'>
                    <Row>
                      <Col md='8'>
                        <button type='button' class='btn btn-danger btn-round' onClick={this.backhome}>
                          Back
                        </button>
                        <Card>
                          <CardHeader>
                            <h5 className='title'>Events</h5>
                          </CardHeader>

                          {this.state.venuespecfic.events.map((question, index) => (
                            <CardBody>
                              <Form>
                                <div id='inline_view'>
                                  <Table id='my' responsive>
                                    <thead>
                                      {question.name == 'Boccia' ? (
                                        <tr>
                                          <th>Name</th>
                                          <th>Setup/Results</th>
                                          <th>Actions</th>
                                        </tr>
                                      ) : (
                                        ''
                                      )}
                                      {question.name == 'Cycling' ? (
                                        <tr>
                                          <th>Name</th>
                                          <th>Setup/Results</th>
                                          <th>Actions</th>
                                        </tr>
                                      ) : (
                                        ''
                                      )}
                                      {question.name == 'Air Rifle' ? (
                                        <tr>
                                          <th>Name</th>
                                          <th>Setup/Results</th>
                                          <th>Actions</th>
                                        </tr>
                                      ) : (
                                        ''
                                      )}
                                      {question.name == 'Paratriathlon 100' ? (
                                        <tr>
                                          <th>Name</th>
                                          <th>Setup /Results</th>
                                          <th>Actions</th>
                                        </tr>
                                      ) : (
                                        ''
                                      )}
                                      {question.name == 'Paratriathlon 200' ? (
                                        <tr>
                                          <th>Name</th>
                                          <th>Setup/Results</th>
                                          <th>Actions</th>
                                        </tr>
                                      ) : (
                                        ''
                                      )}
                                      {question.name == 'Paratriathlon 500' ? (
                                        <tr>
                                          <th>Name</th>
                                          <th>Setup/Results</th>
                                          <th>Actions</th>
                                        </tr>
                                      ) : (
                                        ''
                                      )}
                                    </thead>
                                    <tbody id='myTable'>
                                      {question.name == 'Boccia' ? (
                                        <tr>
                                          <td>{question.name}</td>
                                          <td>
                                            <button
                                              type='button'
                                              class='btn btn-success'
                                              onClick={() =>
                                                window.location.replace('/boccia_worksheet?event=' + question.name)
                                              }
                                            >
                                              Worksheet
                                            </button>{' '}
                                          </td>
                                          <td>
                                            <button
                                              type='button'
                                              class='btn btn-warning'
                                              onClick={() =>
                                                window.location.replace(
                                                  '/boccia_result_heatsheet?event=' + question.name
                                                )
                                              }
                                            >
                                              Print Results
                                            </button>
                                          </td>
                                        </tr>
                                      ) : (
                                        ''
                                      )}
                                      {question.name == 'Cycling' ? (
                                        <tr>
                                          <td>{question.name}</td>
                                          <td>
                                            <button
                                              type='button'
                                              class='btn btn-success'
                                              onClick={() =>
                                                window.location.replace('/cycling_worksheet?event=' + question.name)
                                              }
                                            >
                                              Worksheet
                                            </button>{' '}
                                          </td>
                                          <td>
                                            <button
                                              type='button'
                                              class='btn btn-warning'
                                              onClick={() =>
                                                window.location.replace(
                                                  'cycling_result_heatsheet?event=' + question.name
                                                )
                                              }
                                            >
                                              Print Results
                                            </button>
                                          </td>
                                        </tr>
                                      ) : (
                                        ''
                                      )}
                                      {question.name == 'Air Rifle' ? (
                                        <tr>
                                          <td>{question.name}</td>
                                          <td>
                                            <button
                                              type='button'
                                              class='btn btn-success'
                                              onClick={() =>
                                                window.location.replace('/airrifle_worksheet?event=' + question.name)
                                              }
                                            >
                                              Worksheet
                                            </button>{' '}
                                          </td>
                                          <td>
                                            <button
                                              type='button'
                                              class='btn btn-warning'
                                              onClick={() =>
                                                window.location.replace(
                                                  '/airrifle_result_heatsheet?event=' + question.name
                                                )
                                              }
                                            >
                                              Print Results
                                            </button>
                                          </td>
                                        </tr>
                                      ) : (
                                        ''
                                      )}
                                      {question.name == 'Paratriathlon 100' ? (
                                        <tr>
                                          <td>{question.name}</td>
                                          <td>
                                            <button
                                              type='button'
                                              class='btn btn-success'
                                              onClick={() =>
                                                window.location.replace(
                                                  '/paratriathlon100_worksheet?event=' + question.name
                                                )
                                              }
                                            >
                                              Worksheet
                                            </button>{' '}
                                          </td>
                                          <td>
                                            <button
                                              type='button'
                                              class='btn btn-warning'
                                              onClick={() =>
                                                window.location.replace(
                                                  '/paratriathlon100_heatsheet?event=' + question.name
                                                )
                                              }
                                            >
                                              Print Results
                                            </button>{' '}
                                          </td>
                                        </tr>
                                      ) : (
                                        ''
                                      )}
                                      {question.name == 'Paratriathlon 200' ? (
                                        <tr>
                                          <td>{question.name}</td>
                                          <td>
                                            <button
                                              type='button'
                                              class='btn btn-success'
                                              onClick={() =>
                                                window.location.replace(
                                                  '/paratriathlon200_worksheet?event=' + question.name
                                                )
                                              }
                                            >
                                              Worksheet
                                            </button>{' '}
                                          </td>
                                          <td>
                                            <button
                                              type='button'
                                              class='btn btn-warning'
                                              onClick={() =>
                                                window.location.replace(
                                                  '/paratriathlon200_heatsheet?event=' + question.name
                                                )
                                              }
                                            >
                                              Print Results
                                            </button>{' '}
                                          </td>
                                        </tr>
                                      ) : (
                                        ''
                                      )}
                                      {question.name == 'Paratriathlon 500' ? (
                                        <tr>
                                          <td>{question.name}</td>
                                          <td>
                                            <button
                                              type='button'
                                              class='btn btn-success'
                                              onClick={() =>
                                                window.location.replace(
                                                  '/paratriathlon500_worksheet?event=' + question.name
                                                )
                                              }
                                            >
                                              Worksheet
                                            </button>{' '}
                                          </td>
                                          <td>
                                            <button
                                              type='button'
                                              class='btn btn-warning'
                                              onClick={() =>
                                                window.location.replace(
                                                  '/paratriathlon500_heatsheet?event=' + question.name
                                                )
                                              }
                                            >
                                              Print Results
                                            </button>{' '}
                                          </td>
                                        </tr>
                                      ) : (
                                        ''
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                              </Form>
                            </CardBody>
                          ))}
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
                {/* <div
                class="modal fade"
                id="newmeet"
                tabindex="-1"
                role="dialog"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header ">
                      <h5 class="modal-title " id="loginmodalLabel">
                        {" "}
                        OtherVenues
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <form>

                                  <div class="printrow1">
                                          <input type="checkbox" class="ovclass" value="AirGuns" onChange={(e) =>this.checkvalue(e.target.value)}/>AirGuns
                                          <input type="checkbox" class="ovclass" value="paratriathlon 100" onChange={(e) =>this.checkvalue(e.target.value)}/>paratriathlon 100
                                          <input type="checkbox" class="ovclass" value="paratriathlon 200" onChange={(e) =>this.checkvalue(e.target.value)}/>paratriathlon 200
                                  </div>

                                  <div>
                                     <div class="printrow1">
                                            <ReactToPrint

                                            trigger={() => <button type="button" class="btn btn-success printbtn ovprintbtn" >Print results summary</button>}
                                            content={() => this.componentRef}
                                            />

                                            <div class="textnone" style={{ display: "none" }}>

                                            <Printotherresultsummary  selectedevents={this.state.ovselectedevents} ref={el => (this.componentRef = el)} />
                                            </div>

                                      </div>
                                  </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>
            </>
          )}
        </>
      );
    }
    if (queryParams.get('event') == 'TeamSports') {
      return (
        <>
          {this.state.preloader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='content'>
                  <Row>
                    <Col md='8'>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.backhome}>
                        Back
                      </button>

                      <Card>
                        <CardHeader>
                          <h5 className='title'>Events</h5>
                        </CardHeader>
                        <CardBody>
                          <Form>
                            <div id='inline_view'>
                              <Table id='my' responsive>
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Setup/Results</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody id='myTable'>
                                  {this.state.venuespecfic.events.map((question, index) => (
                                    <tr>
                                      <td>{question.name}</td>
                                      <td>
                                        <button
                                          type='button'
                                          class='btn btn-danger btn'
                                          onClick={() =>
                                            window.location.replace('/Teamsportsinitialsetup?event=' + question.name)
                                          }
                                        >
                                          Initial Setup
                                        </button>{' '}
                                        <button
                                          type='button'
                                          class='btn btn-success'
                                          onClick={() =>
                                            window.location.replace('/teamsportsworksheet?event=' + question.name)
                                          }
                                        >
                                          Worksheet
                                        </button>{' '}
                                      </td>
                                      <td>
                                        <button
                                          type='button'
                                          class='btn btn-warning'
                                          onClick={() =>
                                            window.location.replace('/teamsportswheatsheet?event=' + question.name)
                                          }
                                        >
                                          Print Results
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (queryParams.get('event') == 'Adaptive Tennis') {
      return (
        <>
          {this.state.preloader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='content'>
                  <Row>
                    <Col md='8'>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.backhome}>
                        Back
                      </button>
                      <Card>
                        <CardHeader>
                          <h5 className='title'>Events</h5>
                        </CardHeader>
                        <CardBody>
                          <Form>
                            <div id='inline_view'>
                              <Table id='my' responsive>
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Setup/Results</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody id='myTable'>
                                  {this.state.venuespecfic.events.map((question, index) => (
                                    <tr>
                                      <td>{question.name}</td>
                                      <td>
                                        <button
                                          type='button'
                                          class='btn btn-success'
                                          onClick={() =>
                                            window.location.replace('/fence_Worksheet?event=' + question.name)
                                          }
                                        >
                                          Worksheet
                                        </button>{' '}
                                      </td>
                                      <td>
                                        <button
                                          type='button'
                                          class='btn btn-warning'
                                          onClick={() =>
                                            window.location.replace('/fence_result_heatsheet?event=' + question.name)
                                          }
                                        >
                                          Print Results
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
  }
}

export default Venue_Mainpage;
