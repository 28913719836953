import React, { useCallback } from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import { Empty } from 'antd';
import './compound.css';
import Swal from 'sweetalert2';
import Loader from 'react-loader-spinner';
import e from 'cors';
const url = window.globalConfig;
class Arcset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      arcsetdata: [{}],
      arcsets: '',
      venuenamedata: '',
      venuelocationdata: '',
      numberdata: '',
      currentdata: '',
      eventdata: '',
      teamdata: '',
      typedata: '',
      venuedatas: [{}],
      venuespecfic: [{}],
      venueevents: '',
      arceventsdata: '',
      arclocationset: {},
      arclocset: {},
      loader: true,
    };

    this.createarcset = this.createarcset.bind(this);
    this.editarcset = this.editarcset.bind(this);
    this.optionchange = this.optionchange.bind(this);
    this.optionchange1 = this.optionchange1.bind(this);
    this.optionchange2 = this.optionchange2.bind(this);
    this.optionchange3 = this.optionchange3.bind(this);
    this.optionchange4 = this.optionchange4.bind(this);
    this.dropdownchange = this.dropdownchange.bind(this);
    this.updatearcset = this.updatearcset.bind(this);
    this.createarclocationset = this.createarclocationset.bind(this);
    this.editlocationset = this.editlocationset.bind(this);
    this.updatearclocationset = this.updatearclocationset.bind(this);
    this.back = this.back.bind(this);
  }

  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    var self = this;
    var count = 0;
    let textval = sessionStorage.getItem('x');
    axios.get(`${url.url}/api/venues`, {}).then(function (response) {
      self.setState({
        venuedatas: response.data.data,
        venuespecfic: response.data.data[textval],
        venueevents: response.data.data[textval].events,
      });
      count++;
      if (count == 3) {
        self.setState({
          loader: false,
        });
      }
    });
    let meetid = sessionStorage.getItem('ids');
    axios
      .get(`${url.url}/api/getarcmeetid/${meetid}`, {})
      .then(function (response) {
        self.setState({
          arcsetdata: response.data,
        });
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
    axios
      .get(`${url.url}/api/archerylocationmeetid/${meetid}`, {})
      .then(function (response) {
        self.setState({
          arclocationset: response.data,
          arclocset: response.data[0],
        });
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
    //Hide and show manager
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
  }

  //dropdown values

  dropdownchange(e) {
    this.setState({
      teamdata: e.target.value,
    });
    this.setState({
      selectval: e.target.value,
    });
  }

  optionchange(e) {
    this.setState({
      eventdata: e.target.value,
    });
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  optionchange1(e) {
    this.setState({
      venuenamedata: e.target.value,
    });
  }

  optionchange2(e) {
    this.setState({
      venuelocationdata: e.target.value,
    });
  }

  optionchange3(e) {
    this.setState({
      numberdata: e.target.value,
    });
  }

  optionchange4(e) {
    this.setState({
      currentdata: e.target.value,
    });
  }

  //Edit trackset
  editarcset(e) {
    var self = this;
    self.setState({
      arceventsdata: self.state.venuespecfic.events[e].name,
    });
    var loop = true;
    let textval = sessionStorage.getItem('x');
    self.state.arcsetdata.forEach((question, index) => {
      if (question.event_name == self.state.venuespecfic.events[e].name) {
        self.setState({
          arcsets: question,
          selected_id: question.id,
          eventdata: question.event_name,
          teamdata: question.team_scoring,
          btncheck: false,
        });
        loop = false;
      } else if (loop) {
        self.setState({
          arcsets: '',
          selected_id: '',
          eventdata: self.state.venuespecfic.events[e].name,
          teamdata: 'Yes',
          btncheck: true,
        });
        loop = true;
      }
    });
    if (self.state.arcsetdata.length == 0) {
      self.setState({
        arcsets: '',
        selected_id: '',
        eventdata: self.state.venuespecfic.events[e].name,
        teamdata: 'Yes',
        btncheck: true,
      });
    }
  }
  editlocationset() {
    var self = this;
    let textval = sessionStorage.getItem('x');
    if (self.state.venuedatas[textval].name == self.state.arclocset.venue_name) {
      self.setState({
        venuedata: self.state.arclocationset[0].venue_name,
        venuelocationdata: self.state.arclocationset[0].venue_location,
        numberdata: self.state.arclocationset[0].number_of_events,
        currentdata: self.state.arclocationset[0].current_record,
        btncheck1: false,
      });
    } else {
      self.setState({
        venuedata: self.state.venuespecfic.name,
        venuelocationdata: '',
        numberdata: self.state.venuespecfic.events.length,
        currentdata: '',
        btncheck1: true,
      });
    }
  }
  createarclocationset() {
    const datatosend = {
      venue_name: this.state.venuedata,
      venue_location: this.state.venuelocationdata,
      number_of_events: this.state.numberdata,
      current_record: this.state.currentdata,
      meet_id: parseInt(sessionStorage.getItem('ids')),
    };
    axios
      .post(`${url.url}/api/createarcherylocationset`, datatosend, {})
      .then(function (response) {
        if (response.statusText == 'Created' || response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'Archery Location Set Created successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  updatearclocationset() {
    var self = this;
    const datatosend = {
      venue_name: this.state.venuedata,
      venue_location: this.state.venuelocationdata,
      number_of_events: this.state.numberdata,
      current_record: this.state.currentdata,
    };
    axios
      .put(`${url.url}/api/updatearcherylocationset/${this.state.arclocationset[0].id}`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Archery Location Set Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  //Create Track set
  createarcset() {
    var self = this;
    const setvalue = (q, index) => {
      if (self.state.arcsetdata.length > index) {
        self.setState({
          arcsets: self.state.arcsetdata[index],
          selected_id: self.state.arcsetdata[index].id,
          eventdata: self.state.arcsetdata[index].event_name,
          teamdata: self.state.arcsetdata[index].team_scoring,
        });
      }
    };
    self.state.arcsetdata.map((question, index) => {
      setvalue(question, index);
    });
    const datatosend = {
      event_name: this.state.eventdata,
      team_scoring: this.state.teamdata,
      created_by: 1,
      meet_id: parseInt(sessionStorage.getItem('ids')),
    };

    axios
      .post(`${url.url}/api/createarcherysetrecords`, datatosend, {})
      .then(function (response) {
        if (response.statusText == 'Created' || response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'ArcherySet Created successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  //Update trackset
  updatearcset(e) {
    var self = this;
    const datatosend = {
      event_name: this.state.eventdata,
      team_scoring: this.state.teamdata,
    };

    axios
      .put(`${url.url}/api/updatearcherysetrecords/${this.state.arcsets.id}`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'ArcherySet Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  //back Button
  back() {
    window.location.replace('/eventpreference');
  }

  render() {
    let textval = sessionStorage.getItem('x');
    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div className='content'>
              <Row>
                <Col md='8'>
                  <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                    Back
                  </button>
                  {''}
                  <Card>
                    <CardHeader>
                      <h5 className='title'>Archery Set Preferences</h5>
                    </CardHeader>

                    <CardBody>
                      <Form>
                        <div class='form-group row'>
                          <div class='col-xs-5'>
                            <CardHeader id='deatils_heading'>
                              <tr>
                                <th>
                                  <p>
                                    Venue Name:
                                    <span>{this.state.venuespecfic.name}</span>
                                  </p>
                                </th>
                                <th>
                                  <p>
                                    Venue Location:
                                    <span>
                                      {this.state.arclocationset[0] ? this.state.arclocationset[0].venue_location : ''}
                                    </span>
                                  </p>
                                </th>
                                <th>
                                  <p>
                                    Current Record:
                                    <span>
                                      {this.state.arclocationset[0] ? this.state.arclocationset[0].current_record : ''}
                                    </span>
                                  </p>
                                </th>
                                <th>
                                  <button
                                    type='button'
                                    rel='tooltip'
                                    data-toggle='modal'
                                    data-target='#newmeet'
                                    class='btn btn-success btn-sm btn-icon'
                                    onClick={this.editlocationset}
                                  >
                                    <i class='now-ui-icons ui-2_settings-90'></i>
                                  </button>
                                </th>
                              </tr>
                            </CardHeader>
                          </div>
                        </div>

                        {/* table --------------------------------------------------- */}
                        {Object.keys(this.state.venuespecfic).length == 1 ? (
                          <Empty />
                        ) : (
                          <>
                            <Table id='my' responsive>
                              <thead>
                                <tr>
                                  <th className='text-center'>#</th>
                                  <th>Event name</th>
                                  <th>Team Scoring</th>

                                  <th class='disabled-sorting text-right'>Actions</th>
                                </tr>
                              </thead>
                              <tbody id='myTable'>
                                {this.state.venuespecfic.events.map((question, index) => (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{question.name}</td>
                                    <td>
                                      {this.state.arcsetdata.map((question1, index1) =>
                                        question.name === question1.event_name ? question1.team_scoring : ''
                                      )}
                                    </td>

                                    <td class='td-actions text-right'>
                                      <button
                                        type='button'
                                        rel='tooltip'
                                        class='btn btn-success btn-sm btn-icon'
                                        onClick={() => this.editarcset(index)}
                                        data-toggle='modal'
                                        data-target='#meetedit'
                                      >
                                        <i class='now-ui-icons ui-2_settings-90'></i>
                                      </button>
                                      {` `}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </>
                        )}
                        {/* table --------------------------------------------------- */}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* edit meet modal ----------------------------------------------------------------------------------------------------------------- */}
              <div class='modal fade' id='meetedit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        {this.state.arceventsdata + ' ' + 'ArcherySet'}{' '}
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='inputAddress'>Event name</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            placeholder='Event name'
                            name='evntname'
                            value={this.state.eventdata}
                            onChange={this.optionchange}
                            disabled='disabled'
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Team Scoring</label>
                          <select
                            class='form-control'
                            id='exampleFormControlSelect1'
                            value={this.state.teamdata}
                            onChange={this.dropdownchange}
                            required
                          >
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                          </select>
                        </div>

                        {this.state.btncheck == true ? (
                          <center>
                            <button
                              type='button'
                              onClick={(e) => this.createarcset()}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Create
                            </button>
                          </center>
                        ) : (
                          <>
                            <center>
                              <button type='button' class='btn btn-danger' onClick={(e) => this.updatearcset()}>
                                Update
                              </button>
                            </center>
                          </>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class='modal fade' id='newmeet' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Archery Location Set
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='inputAddress'>Venue Name</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='venuename'
                            value={this.state.venuedata}
                            onChange={this.optionchange1}
                            placeholder='Enter venue name'
                            disabled='disabled'
                            required
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputAddress'>Venue location</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='venuelocation'
                            value={this.state.venuelocationdata}
                            onChange={this.optionchange2}
                            placeholder='Enter Venue location'
                            required
                          />
                        </div>
                        <div class='form-group'>
                          <label for='inputAddress'>Current Record Level</label>
                          <select
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='currecord'
                            value={this.state.currentdata}
                            onChange={this.optionchange4}
                            placeholder='Enter Current record'
                            required
                          >
                            <option>Please Select</option>
                            <option value='National'>National</option>
                            <option value='American IPC'>American IPC</option>
                            <option value='Americas IPC'>Americas IPC</option>
                            <option value='NJDC'>NJDC</option>
                          </select>
                        </div>

                        {this.state.btncheck1 == true ? (
                          <center>
                            <button
                              type='button'
                              onClick={(e) => this.createarclocationset()}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Create
                            </button>
                          </center>
                        ) : (
                          <>
                            <center>
                              <button type='button' class='btn btn-danger' onClick={(e) => this.updatearclocationset()}>
                                Update
                              </button>
                            </center>
                          </>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              {/* add meet------------------------------------------------------------------------------------------------- */}
            </div>
          </>
        )}
      </>
    );
  }
}

export default Arcset;
