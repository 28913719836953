import React from 'react';

import '../App.css';
import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import { Empty } from 'antd';
import 'datatables.net';
import 'datatables.net-bs4';
import Loader from 'react-loader-spinner';
import Printtrackworksheet from './Printtrackworksheet';
import ReactToPrint from 'react-to-print';
const url = window.globalConfig;
class Trackresultworksheet extends React.Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    this.state = {
      event: queryParams.get('event'),
      event_data: [{}],
      relay: false,
      notrelay: false,
      finalresultdata: '',
      finalplacedata: '',
    };
    this.back = this.back.bind(this);
    this.edit = this.edit.bind(this);
    this.update = this.update.bind(this);
  }
  async componentDidMount() {
    var self = this;
    const data = {
      selected_events: this.state.event,
      meet_id: sessionStorage.getItem('ids'),
    };
    await axios
      .post(`${url.url}/api/getregistertrackevent/`, data, {})
      .then(function (response) {
        self.setState({
          event_data: response.data,
          loader: false,
        });
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    // document.getElementById("venueesss").style.display = "block";
  }
  back() {
    window.location.replace('/venuemainpage?event=' + sessionStorage.getItem('event'));
  }

  edit(e) {
    var self = this;
    self.setState({
      eventdatas: this.state.event_data[e],
      finalresultdata: !this.state.event_data[e].final_result ? '' : this.state.event_data[e].final_result,
      finalplacedata: !this.state.event_data[e].final_place ? '' : this.state.event_data[e].final_place,
    });
  }

  update(e) {
    const datatosend = {
      final_result: this.state.finalresultdata,
      final_place: this.state.finalplacedata,
    };
    axios
      .put(`${url.url}/api/updatetrackresultworksheet/${this.state.eventdatas.id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  render() {
    if (this.state.relay) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div>
                  <h1> </h1>
                  <Row>
                    <Col>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                        Back
                      </button>
                      <Card>
                        <CardHeader>
                          <h5 className='title'>
                            {this.state.event} <br></br> Track Result Worksheet
                          </h5>
                        </CardHeader>
                        <CardBody>
                          <div class='form-group row printrow'>
                            <div class='col-xs-5'>
                              <CardHeader>
                                <center>
                                  <ReactToPrint
                                    trigger={() => (
                                      <button type='button' class='btn btn-success printbtn'>
                                        Print Show Result Worksheet
                                      </button>
                                    )}
                                    content={() => this.componentRef2}
                                  />
                                  <div class='textnone' style={{ display: 'none' }}>
                                    <Printtrackworksheet
                                      ref={(el) => (this.componentRef2 = el)}
                                      event={this.state.event}
                                    />
                                  </div>
                                </center>
                              </CardHeader>
                            </div>
                          </div>
                          <Form>
                            {Object.keys(this.state.event_data).length == 0 ? (
                              <Empty />
                            ) : (
                              <>
                                <table class='worksheet_table'>
                                  <thead>
                                    <tr class='worksheet_tr'>
                                      <th>Heat</th>
                                      <th>Lane</th>
                                      <th>Class</th>
                                      <th>Qualtime</th>
                                      <th>Age</th>
                                      <th>Gender</th>
                                      <th>Bib</th>
                                      <th>Last Name</th>
                                      <th>First Name</th>
                                      <th>Country</th>
                                      <th>Final Result</th>
                                      <th>Final Place</th>
                                      <th>Qual Stand</th>
                                      <th>EventName</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.event_data.map((question, index) => (
                                      <tr class='worksheet_tr'>
                                        <td>{question.heat}</td>
                                        <td>{question.lane}</td>
                                        <td>{question.track_class_value}</td>
                                        <td>{question.qualifying_time}</td>
                                        <td>{question.age_division}</td>
                                        <td>{question.gender}</td>
                                        <td>{question.bib_no}</td>
                                        <td>{question.last_name}</td>
                                        <td>{question.first_name}</td>
                                        <td>{question.country}</td>
                                        <td>{question.final_result}</td>
                                        <td>{question.final_place}</td>
                                        <td>{question.qualifying_standard}</td>
                                        <td>{question.selected_events}</td>

                                        <td>
                                          <button
                                            type='button'
                                            rel='tooltip'
                                            class='btn btn-success btn-sm btn-icon'
                                            onClick={() => this.edit(index)}
                                            data-toggle='modal'
                                            data-target='#useredit'
                                          >
                                            <i class='now-ui-icons ui-2_settings-90'></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </>
                            )}
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Final Worksheet
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          {/* <div class="form-row"> */}
                          <div class='form-row'>
                            <div class='form-group '>
                              <label for='inputCity'>Final Result</label>
                              <input
                                class='form-control'
                                value={this.state.finalresultdata}
                                onChange={(e) => this.setState({ finalresultdata: e.target.value })}
                                name='relayletter'
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group '>
                              <label for='inputCity'>Final Place</label>
                              <input
                                class='form-control'
                                value={this.state.finalplacedata}
                                onChange={(e) => this.setState({ finalplacedata: e.target.value })}
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          {/* </div> */}

                          <center>
                            <button type='button' class='btn btn-danger' onClick={(e) => this.update()}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    if (this.state.notrelay) {
      return (
        <>
          {this.state.loader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='venuesetup'>
                  <h1> </h1>
                  <div className='content'>
                    <Row>
                      <Col>
                        <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                          Back
                        </button>
                        <Card>
                          <CardHeader>
                            <h5 className='title'>
                              {this.state.event} <br></br> Track Result Worksheet
                            </h5>
                          </CardHeader>
                          <CardBody>
                            <div class='form-group row printrow'>
                              <div class='col-xs-5'>
                                <CardHeader>
                                  <center>
                                    <ReactToPrint
                                      trigger={() => (
                                        <button type='button' class='btn btn-success printbtn'>
                                          Print Show Result Worksheet
                                        </button>
                                      )}
                                      content={() => this.componentRef2}
                                    />
                                    <div class='textnone' style={{ display: 'none' }}>
                                      <Printtrackworksheet
                                        ref={(el) => (this.componentRef2 = el)}
                                        event={this.state.event}
                                      />
                                    </div>
                                  </center>
                                </CardHeader>
                              </div>
                            </div>
                            <Form>
                              {Object.keys(this.state.event_data).length == 0 ? (
                                <Empty />
                              ) : (
                                <>
                                  <table class='worksheet_table'>
                                    <thead>
                                      <tr class='worksheet_tr'>
                                        <th>Heat</th>
                                        <th>Lane</th>
                                        <th>Class</th>
                                        <th>Qualtime</th>
                                        <th>Age</th>
                                        <th>Gender</th>
                                        <th>Bib</th>
                                        <th>Last Name</th>
                                        <th>First Name</th>
                                        <th>Country</th>
                                        <th>Final Result</th>
                                        <th>Final Place</th>
                                        <th>Wind Final</th>
                                        <th>Qualifies</th>
                                        <th>Qual Stand</th>
                                        <th>EventName</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.event_data.map((question, index) => (
                                        <tr class='worksheet_tr'>
                                          <td>{question.heat}</td>
                                          <td>{question.lane}</td>
                                          <td>{question.track_class_value}</td>
                                          <td>{question.qualifying_time}</td>
                                          <td>{question.age_division}</td>
                                          <td>{question.gender}</td>
                                          <td>{question.bib_no}</td>
                                          <td>{question.last_name}</td>
                                          <td>{question.first_name}</td>
                                          <td>{question.country}</td>
                                          <td>
                                            {question.run_during_final == 'Yes'
                                              ? question.prelim_result
                                              : question.final_result}
                                          </td>
                                          <td>
                                            {question.run_during_final == 'Yes'
                                              ? question.prelim_place
                                              : question.final_place}
                                          </td>
                                          <td>
                                            {question.run_during_final == 'Yes'
                                              ? question.wind_prelim
                                              : question.wind_final}
                                          </td>
                                          <td>
                                            {question.qualifying_standard == null
                                              ? 'NRF'
                                              : question.final_result <= question.qualifying_standard
                                              ? 'Yes'
                                              : 'No'}
                                          </td>
                                          <td>{question.qualifying_standard}</td>
                                          <td>{question.selected_events}</td>

                                          <td>
                                            <button
                                              type='button'
                                              rel='tooltip'
                                              class='btn btn-success btn-sm btn-icon'
                                              onClick={() => this.edit(index)}
                                              data-toggle='modal'
                                              data-target='#useredit'
                                            >
                                              <i class='now-ui-icons ui-2_settings-90'></i>
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </>
                              )}
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Show Result Worksheet
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <div class='modal-body'>
                        <form>
                          <div class='form-row'>
                            <div class='form-group '>
                              <label for='inputCity'>Final Result</label>
                              <input
                                class='form-control'
                                value={this.state.finalresultdata}
                                onChange={(e) => this.setState({ finalresultdata: e.target.value })}
                                name='relayletter'
                              />
                            </div>
                          </div>

                          <div class='form-row'>
                            <div class='form-group '>
                              <label for='inputCity'>Final Place</label>
                              <input
                                class='form-control'
                                value={this.state.finalplacedata}
                                onChange={(e) => this.setState({ finalplacedata: e.target.value })}
                                name='relaypoint'
                              />
                            </div>
                          </div>

                          <center>
                            <button type='button' class='btn btn-danger' onClick={(e) => this.update()}>
                              Update
                            </button>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    return this.state.event == '4 X 100 Meters' ||
      this.state.event == '4 X 400 Meters' ||
      this.state.event == '800 Medley' ||
      this.state.event == '4 X 100 Universal'
      ? (this.state.relay = true)
      : (this.state.notrelay = true);
  }
}

export default Trackresultworksheet;
