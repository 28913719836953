/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';
import { CountryDropdown } from 'react-country-region-selector';
import Swal from 'sweetalert2';

import { Select, Input } from 'antd';
import 'antd/dist/antd.css';

import 'datatables.net';
import 'datatables.net-bs4';
import Atheleteresult from './atheleteresult.js';
import Eventsponser from './Eventsponser';
import Atheleteregister from './atheleteregister';
import LocalCommit from './Localcommittee';
import ManagerVenue from './Managervenues';

import Entermeetresult from './Entermeetresults';
import Results from './Results';
import Loader from 'react-loader-spinner';
const { Option } = Select;
const url = window.globalConfig;
let tselecten = ['', ''];
const j = JSON.parse(sessionStorage.getItem('spec'));
let ids = sessionStorage.getItem('ids');
class Meetviewmanager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItems: [],
      eventnames: [],
      eventids: [],
      country: '',
      options: this.options,
      meetdatas: [{}],
      meethome: true,
      file: null,
      meetspecfic: [{}],
      eventsponser: false,
      lorg: false,
      venueview: false,
      venues: [{}],
      shortname: '',
      meetcity: '',
      meetcountry: '',
      meetdirector: '',
      meetenddate: '',
      eshortname: '',
      emeetcity: '',
      emeetcountry: '',
      emeetdirector: '',
      emeetstartdate: '',
      emeetenddate: '',
      orgname: 'Move United ',
      sponsername: '',
      meetsponser: [{}],
      editsponserdata: '',
      sid: '',
      specficmeet: [{}],
      kids: '',
      localcomiteename: '',
      localcomitedetail: [{}],
      editcomiteedata: '',
      sid1: '',
      editmeetdatas: [{}],
      eids: '',
      vdrop1: '',
      eventdropdown: [{}],
      newevents: [{}],
      managervenues: [{}],
      mids: '',
      btncheck: true,
      edituser: false,
      logo: '',
      areg: false,
      aresult: false,
      venueclasses: [{}],
      venueclassid: [{}],
      check: false,
      value: '',
      agedata: [{}],
      genderdata: [{}],
      pointdata: [{}],
      meetID: [{}],
      active1: false,
      active2: false,
      active3: false,
      active4: false,
      active5: false,
      selecttrackbib: '',
      selectfieldbib: '',
      selectswimbib: '',
      selecttablebib: '',
      selectweightbib: '',
      selectteambib: '',
      selectfencebib: '',
      selectotherbib: '',
      stdate: '',
      etrackbib: '',
      efieldbib: '',
      earcbib: '',
      eswimbib: '',
      eweightbib: '',
      etablebib: '',
      eteambib: '',
      efencebib: '',
      eotherbib: '',
      ytrackcheck: '',
      ntrackcheck: '',
      meetcountrydata: '',
      meetstartdata: '',
      meetenddata: '',
      points1: '',
      points2: '',
      points3: '',
      points4: '',
      points5: '',
      points6: '',
      points7: '',
      points8: '',
      points9: '',
      points10: '',
      typegamedata: '',
      placing: '',
      typerecord: '',
      loader: true,
      typepooldata: 'YD',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.eventsponsers = this.eventsponsers.bind(this);
    this.localorg = this.localorg.bind(this);
    this.back = this.back.bind(this);
    this.venues = this.venues.bind(this);
    this.createmeet = this.createmeet.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.meetedits = this.meetedits.bind(this);
    this.updatemeetdetail = this.updatemeetdetail.bind(this);
    this.selectCountry = this.selectCountry.bind(this);
    this.aregview = this.aregview.bind(this);
    this.aResults = this.aResults.bind(this);

    this.trackChange = this.trackChange.bind(this);
    this.fieldChange = this.fieldChange.bind(this);
    this.arcChange = this.arcChange.bind(this);
    this.swimChange = this.swimChange.bind(this);
    this.weightliftChange = this.weightliftChange.bind(this);
    this.tableChange = this.tableChange.bind(this);
    this.teamChange = this.teamChange.bind(this);
    this.fenceChange = this.fenceChange.bind(this);
    this.otherChange = this.otherChange.bind(this);
    this.handleChange3 = this.handleChange3.bind(this);
    this.dropdownchange = this.dropdownchange.bind(this);
    this.dropdownchange1 = this.dropdownchange1.bind(this);
    this.optionchange1 = this.optionchange1.bind(this);
    this.optionchange2 = this.optionchange2.bind(this);
    this.optionchange3 = this.optionchange3.bind(this);
    this.optionchange4 = this.optionchange4.bind(this);
    this.optionchange5 = this.optionchange5.bind(this);
    this.optionchange6 = this.optionchange6.bind(this);
    this.optionchange7 = this.optionchange7.bind(this);
    this.optionchange8 = this.optionchange8.bind(this);
    this.optionchange9 = this.optionchange9.bind(this);
    this.optionchange10 = this.optionchange10.bind(this);
    this.typechange = this.typechange.bind(this);
  }

  handleChange3(e) {
    this.setState({
      emeetcountry: this.state.meetdatas[j],
    });
  }
  componentWillMount() {
    var self = this;
    let e = sessionStorage.getItem('e');
    let ids = sessionStorage.getItem('ids');
    let j = JSON.parse(sessionStorage.getItem('spec'));

    self.setState({
      meetspecfic: j,
      kids: parseInt(j.id),
    });

    axios
      .get(`${url.url}/api/getmeetdetail/${ids}`, {})
      .then(function (response) {
        self.setState({
          orgname: response.data[0].org_name,
          eids: response.data[0].id,
          eshortname: response.data[0].short_meetname,
          emeetcity: response.data[0].meet_city,
          emeetcountry: response.data[0].meet_country,
          emeetdirector: response.data[0].meet_director,
          emeetstartdate: response.data[0].start_date,
          emeetenddate: response.data[0].end_date,
          logo: response.data[0].logo,
          ehosthotel: response.data[0].host_hotel,
          eathlete: response.data[0].athlete,
          eheadcoach: response.data[0].head_coach,
          ecoach: response.data[0].coach,
          easstcoach: response.data[0].asst_coach,
          esupportstaff: response.data[0].support_staff,
          eofficial: response.data[0].official,
          etrackbib: response.data[0].track_bib,
          efieldbib: response.data[0].field_bib,
          earcbib: response.data[0].archery_bib,
          eswimbib: response.data[0].swim_bib,
          eweightbib: response.data[0].weight_bib,
          etablebib: response.data[0].table_bib,
          eteambib: response.data[0].team_bib,
          efencebib: response.data[0].fence_bib,
          eotherbib: response.data[0].other_bib,
          typegamedata: response.data[0].type_game,
          placing: response.data[0].placing,
          typerecord: response.data[0].type_record,
          points1: response.data[0].place1,
          points2: response.data[0].place2,
          points3: response.data[0].place3,
          points4: response.data[0].place4,
          points5: response.data[0].place5,
          points6: response.data[0].place6,
          points7: response.data[0].place7,
          points8: response.data[0].place8,
          points9: response.data[0].place9,
          points10: response.data[0].place10,
          btncheck: true,
          specficmeet: response.data,
        });
        if (response.data[0].track_bib !== 'no') {
          document.getElementById('track-radio').checked = true;
          document.getElementById('track-radio-no').checked = false;
        } else {
          document.getElementById('track-radio-no').checked = true;
          document.getElementById('track-radio').checked = false;
        }
        if (response.data[0].field_bib !== 'no') {
          document.getElementById('field-radio').checked = true;
          document.getElementById('field-radio-no').checked = false;
        } else {
          document.getElementById('field-radio-no').checked = true;
          document.getElementById('field-radio').checked = false;
        }
        if (response.data[0].archery_bib !== 'no') {
          document.getElementById('archery-radio').checked = true;
          document.getElementById('archery-radio-no').checked = false;
        } else {
          document.getElementById('archery-radio-no').checked = true;
          document.getElementById('archery-radio').checked = false;
        }
        if (response.data[0].swim_bib !== 'no') {
          document.getElementById('swimming-radio').checked = true;
          document.getElementById('swimming-radio-no').checked = false;
        } else {
          document.getElementById('swimming-radio-no').checked = true;
          document.getElementById('swimming-radio').checked = false;
        }
        if (response.data[0].weight_bib !== 'no') {
          document.getElementById('weightlift-radio').checked = true;
          document.getElementById('weightlift-radio-no').checked = false;
        } else {
          document.getElementById('weightlift-radio-no').checked = true;
          document.getElementById('weightlift-radio').checked = false;
        }
        if (response.data[0].table_bib !== 'no') {
          document.getElementById('tabletennis-radio').checked = true;
          document.getElementById('tabletennis-radio-no').checked = false;
        } else {
          document.getElementById('tabletennis-radio-no').checked = true;
          document.getElementById('tabletennis-radio').checked = false;
        }
        if (response.data[0].team_bib !== 'no') {
          document.getElementById('team-radio').checked = true;
          document.getElementById('team-radio-no').checked = false;
        } else {
          document.getElementById('team-radio-no').checked = true;
          document.getElementById('team-radio').checked = false;
        }
        if (response.data[0].fence_bib !== 'no') {
          document.getElementById('fencing-radio').checked = true;
          document.getElementById('fencing-radio-no').checked = false;
        } else {
          document.getElementById('fencing-radio-no').checked = true;
          document.getElementById('fencing-radio').checked = false;
        }
        if (response.data[0].other_bib !== 'no') {
          document.getElementById('other-radio').checked = true;
          document.getElementById('other-radio-no').checked = false;
        } else {
          document.getElementById('other-radio-no').checked = true;
          document.getElementById('other-radio').checked = false;
        }
      })
      .catch(function (error) {
        self.setState({
          eids: '',
          eshortname: '',
          emeetcity: '',
          emeetcountry: '',
          emeetdirector: '',
          emeetstartdate: '',
          emeetenddate: '',
          logo: '',
          ehosthotel: '',
          eathlete: '',
          eheadcoach: '',
          ecoach: '',
          easstcoach: '',
          esupportstaff: '',
          eofficial: '',
          etrackbib: '',
          efieldbib: '',
          earcbib: '',
          eswimbib: '',
          eweightbib: '',
          etablebib: '',
          eteambib: '',
          efencebib: '',
          eotherbib: '',
          type_game: '',
          placing: '',
          type_record: '',
          place1: '',
          place2: '',
          place3: '',
          place4: '',
          place5: '',
          place6: '',
          place7: '',
          place8: '',
          place9: '',
          place10: '',
          btncheck: false,
        });
      });

    var self = this;
    axios.get(`${url.url}/api/meets`, {}).then(function (response) {
      self.setState({
        meetdatas: response.data.data,
        meetcountrydata: response.data.data[e].Location,
        meetstartdata: response.data.data[e].meet_date,
        meetenddata: response.data.data[e].meet_end_date,
      });
    });
  }

  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    var self = this;
    var count = 0;
    let e = sessionStorage.getItem('e');
    let ids = sessionStorage.getItem('ids');
    let j = JSON.parse(sessionStorage.getItem('spec'));
    axios
      .get(`${url.url}/api/getmeetdetail/${j.id}`, {})
      .then(function (response) {
        self.setState({
          eids: response.data[0].id,
          eshortname: response.data[0].short_meetname,
          emeetcity: response.data[0].meet_city,
          emeetcountry: response.data[0].meet_country,
          emeetdirector: response.data[0].meet_director,
          emeetstartdate: response.data[0].start_date,
          emeetenddate: response.data[0].end_date,
          logo: response.data[0].logo,
          ehosthotel: response.data[0].host_hotel,
          eathlete: response.data[0].athlete,
          eheadcoach: response.data[0].head_coach,
          ecoach: response.data[0].coach,
          easstcoach: response.data[0].asst_coach,
          esupportstaff: response.data[0].support_staff,
          eofficial: response.data[0].official,
          etrackbib: response.data[0].track_bib,
          efieldbib: response.data[0].field_bib,
          earcbib: response.data[0].archery_bib,
          eswimbib: response.data[0].swim_bib,
          eweightbib: response.data[0].weight_bib,
          etablebib: response.data[0].table_bib,
          eteambib: response.data[0].team_bib,
          efencebib: response.data[0].fence_bib,
          eotherbib: response.data[0].other_bib,
          typepooldata: response.data[0].type_pool,
          type_game: response.data[0].typegamedata,
          placing: response.data[0].placing,
          type_record: response.data[0].typerecord,
          place1: response.data[0].points1,
          place2: response.data[0].points2,
          place3: response.data[0].points3,
          place4: response.data[0].points4,
          place5: response.data[0].points5,
          place6: response.data[0].points6,
          place7: response.data[0].points7,
          place8: response.data[0].points8,
          place9: response.data[0].points9,
          place10: response.data[0].points10,
          btncheck: true,
          specficmeet: response.data,
          loader: false,
        });
        if (response.data[0].track_bib !== 'no') {
          document.getElementById('track-radio').checked = true;
          document.getElementById('track-radio-no').checked = false;
        } else {
          document.getElementById('track-radio-no').checked = true;
          document.getElementById('track-radio').checked = false;
        }
        if (response.data[0].field_bib !== 'no') {
          document.getElementById('field-radio').checked = true;
          document.getElementById('field-radio-no').checked = false;
        } else {
          document.getElementById('field-radio-no').checked = true;
          document.getElementById('field-radio').checked = false;
        }
        if (response.data[0].archery_bib !== 'no') {
          document.getElementById('archery-radio').checked = true;
          document.getElementById('archery-radio-no').checked = false;
        } else {
          document.getElementById('archery-radio-no').checked = true;
          document.getElementById('archery-radio').checked = false;
        }
        if (response.data[0].swim_bib !== 'no') {
          document.getElementById('swimming-radio').checked = true;
          document.getElementById('swimming-radio-no').checked = false;
        } else {
          document.getElementById('swimming-radio-no').checked = true;
          document.getElementById('swimming-radio').checked = false;
        }
        if (response.data[0].weight_bib !== 'no') {
          document.getElementById('weightlift-radio').checked = true;
          document.getElementById('weightlift-radio-no').checked = false;
        } else {
          document.getElementById('weightlift-radio-no').checked = true;
          document.getElementById('weightlift-radio').checked = false;
        }
        if (response.data[0].table_bib !== 'no') {
          document.getElementById('tabletennis-radio').checked = true;
          document.getElementById('tabletennis-radio-no').checked = false;
        } else {
          document.getElementById('tabletennis-radio-no').checked = true;
          document.getElementById('tabletennis-radio').checked = false;
        }
        if (response.data[0].team_bib !== 'no') {
          document.getElementById('team-radio').checked = true;
          document.getElementById('team-radio-no').checked = false;
        } else {
          document.getElementById('team-radio-no').checked = true;
          document.getElementById('team-radio').checked = false;
        }
        if (response.data[0].fence_bib !== 'no') {
          document.getElementById('fencing-radio').checked = true;
          document.getElementById('fencing-radio-no').checked = false;
        } else {
          document.getElementById('fencing-radio-no').checked = true;
          document.getElementById('fencing-radio').checked = false;
        }
        if (response.data[0].other_bib !== 'no') {
          document.getElementById('other-radio').checked = true;
          document.getElementById('other-radio-no').checked = false;
        } else {
          document.getElementById('other-radio-no').checked = true;
          document.getElementById('other-radio').checked = false;
        }
      })
      .catch(function (error) {
        self.setState({
          eids: '',
          eshortname: '',
          emeetcity: '',
          emeetcountry: '',
          emeetdirector: '',
          emeetstartdate: '',
          emeetenddate: '',
          logo: '',
          ehosthotel: '',
          eathlete: '',
          eheadcoach: '',
          ecoach: '',
          easstcoach: '',
          esupportstaff: '',
          eofficial: '',
          etrackbib: '',
          efieldbib: '',
          earcbib: '',
          eswimbib: '',
          eweightbib: '',
          etablebib: '',
          eteambib: '',
          efencebib: '',
          eotherbib: '',
          type_game: '',
          placing: '',
          type_record: '',
          place1: '',
          place2: '',
          place3: '',
          place4: '',
          place5: '',
          place6: '',
          place7: '',
          place8: '',
          place9: '',
          place10: '',
          btncheck: false,
          loader: false,
        });
      });

    document.getElementById('eventset').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    var self = this;
    axios.get(`${url.url}/api/meets`, {}).then(function (response) {
      self.setState({
        meetdatas: response.data.data,
        meetcountrydata: response.data.data[e].Location,
        meetstartdata: response.data.data[e].meet_date,
        meetenddata: response.data.data[e].meet_end_date,
      });
    });
  }
  aregview(e) {
    var self = this;
    self.setState({
      areg: true,
    });
  }

  aResults() {
    var self = this;
    self.setState({
      aresult: true,
    });
  }

  handleChange2(selectedItems) {
    var self = this;
    self.setState({
      selectedItems,
    });
  }

  trackChange(e) {
    this.setState({
      selecttrackbib: e.target.value,
    });
  }

  fieldChange(e) {
    this.setState({
      selectfieldbib: e.target.value,
    });
  }

  arcChange(e) {
    this.setState({
      selectarcbib: e.target.value,
    });
  }

  swimChange(e) {
    this.setState({
      selectswimbib: e.target.value,
    });
  }

  weightliftChange(e) {
    this.setState({
      selectweightbib: e.target.value,
    });
  }

  tableChange(e) {
    this.setState({
      selecttablebib: e.target.value,
    });
  }

  teamChange(e) {
    this.setState({
      selectteambib: e.target.value,
    });
  }

  fenceChange(e) {
    this.setState({
      selectfencebib: e.target.value,
    });
  }

  otherChange(e) {
    this.setState({
      selectotherbib: e.target.value,
    });
  }

  createmeet(event) {
    event.preventDefault();
    let j = JSON.parse(sessionStorage.getItem('spec'));
    const datatosend = {
      org_name: this.state.orgname,
      meet_name: j.name,
      meet_id: parseInt(j.id),
      created_by: parseInt(j.created_by),
      short_meetname: this.state.eshortname,
      meet_city: this.state.emeetcity,
      meet_country: this.state.emeetcountry,
      start_date: this.state.emeetstartdate,
      end_date: this.state.emeetenddate,
      logo: this.state.logo,
      meet_director: this.state.emeetdirector,
      host_hotel: this.state.ehosthotel,
      athlete: this.state.eathlete,
      head_coach: this.state.eheadcoach,
      coach: this.state.ecoach,
      asst_coach: this.state.easstcoach,
      support_staff: this.state.esupportstaff,
      official: this.state.eofficial,
      track_bib: this.state.selecttrackbib,
      field_bib: this.state.selectfieldbib,
      archery_bib: this.state.selectarcbib,
      swim_bib: this.state.selectswimbib,
      weight_bib: this.state.selectweightbib,
      table_bib: this.state.selecttablebib,
      team_bib: this.state.selectteambib,
      fence_bib: this.state.selectfencebib,
      other_bib: this.state.selectotherbib,
      type_pool: this.state.typepooldata,
      type_game: this.state.typegamedata,
      placing: this.state.placing,
      type_record: this.state.typerecord,
      place1: this.state.points1,
      place2: this.state.points2,
      place3: this.state.points3,
      place4: this.state.points4,
      place5: this.state.points5,
      place6: this.state.points6,
      place7: this.state.points7,
      place8: this.state.points8,
      place9: this.state.points9,
      place10: this.state.points10,
    };
    axios
      .post(`${url.url}/api/createmeetdetail`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'Created' || response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'Meet Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  meetedits(e) {
    var self = this;
    self.setState({
      editmeetdatas: this.state.specficmeet[e],
      eids: this.state.specficmeet[e].id,
      eshortname: this.state.specficmeet[e].short_meetname,
      emeetcity: this.state.specficmeet[e].meet_city,
      emeetcountry: this.state.specficmeet[e].meet_country,
      emeetdirector: this.state.specficmeet[e].meet_director,
      emeetstartdate: this.state.specficmeet[e].start_date,
      emeetenddate: this.state.specficmeet[e].end_date,
      ehosthotel: this.state.specficmeet[e].host_hotel,
      eathlete: this.state.specficmeet[e].athlete,
      eheadcoach: this.state.specficmeet[e].head_coach,
      ecoach: this.state.specficmeet[e].coach,
      easstcoach: this.state.specficmeet[e].asst_coach,
      esupportstaff: this.state.specficmeet[e].support_staff,
      eofficial: this.state.specficmeet[e].official,
      etrackbib: this.state.specficmeet[e].track_bib,
      efieldbib: this.state.specficmeet[e].field_bib,
      earcbib: this.state.specficmeet[e].archery_bib,
      eswimbib: this.state.specficmeet[e].swim_bib,
      eweightbib: this.state.specficmeet[e].weight_bib,
      etablebib: this.state.specficmeet[e].table_bib,
      eteambib: this.state.specficmeet[e].team_bib,
      efencebib: this.state.specficmeet[e].fence_bib,
      eotherbib: this.state.specficmeet[e].other_bib,
      typepooldata: this.state.specficmeet[e].type_pool,
    });
  }
  updatemeetdetail(e) {
    e.preventDefault();
    const datatosend = {
      org_name: this.state.orgname,
      short_meetname: this.state.eshortname,
      meet_city: this.state.emeetcity,
      meet_country: this.state.emeetcountry,
      meet_director: this.state.emeetdirector,
      start_date: this.state.meetstartdata,
      end_date: this.state.meetenddata,
      logo: this.state.logo,
      host_hotel: this.state.ehosthotel,
      athlete: this.state.eathlete,
      head_coach: this.state.eheadcoach,
      coach: this.state.ecoach,
      asst_coach: this.state.easstcoach,
      support_staff: this.state.esupportstaff,
      official: this.state.eofficial,
      track_bib: this.state.selecttrackbib,
      field_bib: this.state.selectfieldbib,
      archery_bib: this.state.selectarcbib,
      swim_bib: this.state.selectswimbib,
      weight_bib: this.state.selectweightbib,
      table_bib: this.state.selecttablebib,
      team_bib: this.state.selectteambib,
      fence_bib: this.state.selectfencebib,
      other_bib: this.state.selectotherbib,
      type_pool: this.state.typepooldata,
      type_game: this.state.typegamedata,
      placing: this.state.placing,
      type_record: this.state.typerecord,
      place1: this.state.points1,
      place2: this.state.points2,
      place3: this.state.points3,
      place4: this.state.points4,
      place5: this.state.points5,
      place6: this.state.points6,
      place7: this.state.points7,
      place8: this.state.points8,
      place9: this.state.points9,
      place10: this.state.points10,
    };

    axios
      .put(`${url.url}/api/updatemeetdetail/${this.state.eids}`, datatosend, {})

      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Meet Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  deletemeetsponser(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deletesponser/${this.state.specficmeet[e].id}`, {})
          .then(function (response) {})

          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });
  }

  handleChange(event) {
    var self = this;
    var file = event.target.files[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      self.setState({
        logo: reader.result,
      });

      var data = reader.result.split(',')[1];
    };
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
    });
    reader.readAsDataURL(file);
  }

  eventsponsers(e) {
    var self = this;
    self.setState({
      eventsponser: true,
    });
  }
  handleChange1(e) {
    var self = this;
    self.setState({
      [e.target.name]: e.target.value,
    });
  }

  localorg() {
    var self = this;
    self.setState({
      lorg: true,
    });
  }

  venues() {
    var self = this;
    self.setState({
      venueview: true,
    });
  }

  venueschange1(e) {
    var self = this;
    self.setState({
      newevents: this.state.eventdropdown.events[e.target.value],
    });
  }

  back() {
    sessionStorage.removeItem('e');
    sessionStorage.removeItem('ids');

    window.location.replace('/meets');
  }

  selectCountry(val) {
    this.setState({ selectCountry: val });
    this.setState({
      emeetcountry: val,
    });
  }

  dropdownchange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  dropdownchange1(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  optionchange1(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  optionchange2(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  optionchange3(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  optionchange4(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  optionchange5(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  optionchange6(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  optionchange7(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  optionchange8(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  optionchange9(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  optionchange10(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  typechange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  onInputchange = (event) => {
    this.setState({
      orgname: event.target.value,
    });
  };
  onInputchange1 = (event) => {
    this.setState({
      meetstartdata: event.target.value,
    });
  };
  onInputchange2 = (event) => {
    this.setState({
      meetenddata: event.target.value,
    });
  };

  render() {
    const { selectedItems } = this.state;

    const { eventnames } = this.state;

    if (this.state.eventsponser) {
      return <Redirect to='/eventsponser' />;
    }

    if (this.state.areg) {
      return <Redirect to='/athleteregister' />;
    }

    if (this.state.aresult) {
      return <Redirect to='/athleteresult' />;
    }
    if (this.state.lorg) {
      return <Redirect to='/localcommittee' />;
    }

    if (this.state.venueview) {
      return <Redirect to={'/managervenues'} />;
    }
    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div>
              <div className='content'>
                <Row>
                  <Col md='8'>
                    <button
                      type='button'
                      data-toggle='modal'
                      data-target='#newmeet'
                      class='btn btn-danger btn-round btn-grid'
                      onClick={this.back}
                    >
                      Back
                    </button>
                    {''}
                    {/* <Router><Link to={{pathname:"/samsreact/eventsponser",
        state:{foo:"bar"}
        }} > */}
                    <button
                      type='button'
                      data-toggle='modal'
                      class='btn btn-danger btn-round btn-grid'
                      onClick={this.eventsponsers}
                    >
                      Event Sponsors
                    </button>
                    {''}
                    {/* </Link></Router>
  <Router><Link to={{pathname:"/samsreact/localcommittee",
        state:{foo:"bar"}
        }} > */}
                    <button
                      type='button'
                      data-toggle='modal'
                      class='btn btn-danger btn-round btn-grid'
                      onClick={this.localorg}
                    >
                      Local Organizing Committee
                    </button>
                    {''}
                    {/* </Link></Router>
   <Router><Link to={{pathname:"/samsreact/managervenues",
        state:{foo:"bar"}
        }} > */}
                    {/* <button type="button" data-toggle="modal"  class="btn btn-danger btn-round btn-grid" onClick={this.venues}>Venues</button>{''} */}
                    {/* </Link></Router>
  <Router> <Link to={{pathname:"/samsreact/atheleteregister",
        state:{foo:"bar"}
        }} > */}
                    {/* <button type="button" data-toggle="modal"  class="btn btn-danger btn-round test btn-grid" onClick={this.aregview}>Athelete Registrations</button>{''} */}
                    {/* </Link></Router>
   <Router><Link to={{pathname:"/samsreact/atheleteresult",
        state:{foo:"bar"}
        }} > */}
                    {/* <button type="button" data-toggle="modal"  class="btn btn-danger btn-round btn-grid" onClick={this.aResults}>Results</button>{''} */}
                    {/* </Link></Router> */}
                    <Card>
                      <CardHeader>
                        <h5 className='title'>Meet Preferences</h5>
                      </CardHeader>

                      <CardBody>
                        <Form>
                          <div class='form-group row'>
                            <div class='col-xs-5 colgroup'>
                              <CardHeader></CardHeader>
                            </div>
                          </div>

                          <form>
                            <div class='form-row'>
                              <div class='form-group col-md-6'>
                                <label for='inputEmail4'>Org Name</label>
                                <input
                                  type='text'
                                  class='form-control'
                                  id='inputEmail4'
                                  placeholder='Org Name'
                                  value={this.state.orgname}
                                  onChange={this.onInputchange}
                                />
                              </div>

                              <div class='form-group col-md-6'>
                                <label for='inputPassword4'>Meet name</label>
                                <input
                                  type='text'
                                  class='form-control'
                                  id='inputPassword4'
                                  value={this.state.meetspecfic.name}
                                />
                              </div>
                            </div>

                            <div class='form-group'>
                              <label for='inputAddress'>Short Meet Name</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress'
                                placeholder='Short Meet Name'
                                name='eshortname'
                                value={this.state.eshortname}
                                onChange={this.handleChange1}
                              />
                            </div>

                            <div class='form-group'>
                              <label for='inputAddress2'>Meet City / State</label>
                              <input
                                type='text'
                                class='form-control'
                                id='inputAddress2'
                                placeholder='Meet City'
                                name='emeetcity'
                                value={!this.state.emeetcity ? this.state.meetcountrydata : this.state.emeetcity}
                                onChange={this.handleChange1}
                              />
                            </div>

                            <div class='form-row'>
                              <div class='form-group col-md-6'>
                                <label for='inputCity'>Meet Country</label>
                                <CountryDropdown
                                  class='form-control'
                                  value={this.state.emeetcountry}
                                  onChange={(val) => this.selectCountry(val)}
                                />
                                {/* <input type="text"
                                class="form-control"
                                id="inputCity"
                                placeholder="Meet Country"
                                name='emeetcountry'
                                value={this.state.emeetcountry}
                                onChange={this.handleChange1}/> */}
                                {/* <input
                                  type="text"
                                  class="form-control"
                                  id="meetcountry"
                                  placeholder="Meet Country"
                                  name="emeetcountry"
                                  value={this.state.meetcountrydata}
                                  onChange={this.handleChange3}
                                /> */}
                              </div>

                              <div class='form-group col-md-6'>
                                <label for='inputCity0'>Meet Director</label>
                                <input
                                  type='text'
                                  class='form-control'
                                  id='inputCity'
                                  placeholder='Meet Director'
                                  name='emeetdirector'
                                  value={this.state.emeetdirector}
                                  onChange={this.handleChange1}
                                />
                              </div>

                              <div class='form-group col-md-6'>
                                <label for='inputCity1'>Host Hotel</label>
                                <input
                                  type='text'
                                  class='form-control'
                                  id='inputCity1'
                                  placeholder='Host Hotel'
                                  name='ehosthotel'
                                  value={this.state.ehosthotel}
                                  onChange={this.handleChange1}
                                />
                              </div>

                              <div class='form-group col-md-3'>
                                <label for='inputZip'>Meet Start Date</label>
                                <input
                                  type='date'
                                  class='form-control'
                                  id='stdate1'
                                  placeholder='Meet Start Date'
                                  name='emeetstartdate'
                                  value={this.state.meetstartdata}
                                  onChange={this.onInputchange1}
                                />
                              </div>

                              <div class='form-group col-md-3'>
                                <label for='inputZip0'>Meet End Date</label>
                                <input
                                  type='date'
                                  class='form-control'
                                  id='endate1'
                                  placeholder='Meet End Date'
                                  name='emeetenddate'
                                  value={this.state.meetenddata}
                                  onChange={this.onInputchange2}
                                />
                              </div>

                              <div class='form-group col-md-12'>
                                <h6 className='title'>Registrations Fees $</h6>
                              </div>

                              <div class='form-group col-md-6'>
                                <label for='inputCity2'>Athlete</label>
                                <input
                                  type='text'
                                  class='form-control'
                                  id='inputCity2'
                                  placeholder='Athlete'
                                  name='eathlete'
                                  value={this.state.eathlete}
                                  onChange={this.handleChange1}
                                />
                              </div>

                              <div class='form-group col-md-6'>
                                <label for='inputCity3'>Head Coach</label>
                                <input
                                  type='text'
                                  class='form-control'
                                  id='inputCity3'
                                  placeholder='Head Coach'
                                  name='eheadcoach'
                                  value={this.state.eheadcoach}
                                  onChange={this.handleChange1}
                                />
                              </div>

                              <div class='form-group col-md-6'>
                                <label for='inputCity4'>Coach</label>
                                <input
                                  type='text'
                                  class='form-control'
                                  id='inputCity4'
                                  placeholder='Coach'
                                  name='ecoach'
                                  value={this.state.ecoach}
                                  onChange={this.handleChange1}
                                />
                              </div>

                              <div class='form-group col-md-6'>
                                <label for='inputCity5'>Asst. Coach</label>
                                <input
                                  type='text'
                                  class='form-control'
                                  id='inputCity5'
                                  placeholder='Asst. Coach'
                                  name='easstcoach'
                                  value={this.state.easstcoach}
                                  onChange={this.handleChange1}
                                />
                              </div>

                              <div class='form-group col-md-6'>
                                <label for='inputCity6'>Support staff</label>
                                <input
                                  type='text'
                                  meetcountrydata
                                  class='form-control'
                                  id='inputCity6'
                                  placeholder='Support staff'
                                  name='esupportstaff'
                                  value={this.state.esupportstaff}
                                  onChange={this.handleChange1}
                                />
                              </div>

                              <div class='form-group col-md-6'>
                                <label for='inputCity7'>Official</label>
                                <input
                                  type='text'
                                  class='form-control'
                                  id='inputCity7'
                                  placeholder='Official'
                                  name='eofficial'
                                  value={this.state.eofficial}
                                  onChange={this.handleChange1}
                                />
                              </div>

                              <div class='form-group formtext1 col-md-12'>
                                <p>Select Yes or No to assign bib numbers for the following venues</p>
                              </div>

                              <div class='form-group yesno col-md-2'>
                                <label>Track</label>
                                <div class='radio-flex'>
                                  <input
                                    id='track-radio'
                                    class='form-control1 '
                                    type='radio'
                                    name='trackbib'
                                    value='yes'
                                    onChange={this.trackChange}
                                  />
                                  <label for='track-radio'>Yes</label>
                                  {/* <label for="track-radio">yes</label> */}
                                  <input
                                    id='track-radio-no'
                                    class='form-control1 form-control2 '
                                    type='radio'
                                    name='trackbib'
                                    value='no'
                                    onChange={this.trackChange}
                                  />
                                  <label for='track-radio-no'>No</label>
                                </div>
                              </div>

                              <div class='form-group yesno col-md-2'>
                                <label for='field-radio'>Field</label>
                                <div class='radio-flex'>
                                  <input
                                    id='field-radio'
                                    class='form-control1 '
                                    type='radio'
                                    name='fieldbib'
                                    value='yes'
                                  />
                                  <label for='field-radio'>Yes</label>
                                  <input
                                    id='field-radio-no'
                                    class='form-control1 form-control2'
                                    type='radio'
                                    name='fieldbib'
                                    value='no'
                                    onChange={this.fieldChange}
                                  />
                                  <label for='field-radio-no'>No</label>
                                </div>
                              </div>

                              <div class='form-group yesno col-md-2'>
                                <label>Para Powerlifting</label>
                                <div class='radio-flex'>
                                  <input
                                    id='weightlift-radio'
                                    class='form-control1'
                                    type='radio'
                                    name='weightbib'
                                    value='yes'
                                    onChange={this.weightliftChange}
                                  />
                                  <label for='weightlift-radio'>Yes</label>
                                  <input
                                    id='weightlift-radio-no'
                                    class='form-control1 form-control2'
                                    type='radio'
                                    name='weightbib'
                                    value='no'
                                    onChange={this.weightliftChange}
                                  />
                                  <label for='weightlift-radio-no'>No</label>
                                </div>
                              </div>

                              <div class='form-group yesno col-md-2'>
                                <label>Table tennis</label>
                                <div class='radio-flex'>
                                  <input
                                    id='tabletennis-radio'
                                    class='form-control1'
                                    type='radio'
                                    name='tablebib'
                                    value='yes'
                                    onChange={this.tableChange}
                                  />
                                  <label for='tabletennis-radio'>Yes</label>
                                  <input
                                    id='tabletennis-radio-no'
                                    class='form-control1 form-control2'
                                    type='radio'
                                    name='tablebib'
                                    value='no'
                                    onChange={this.tableChange}
                                  />
                                  <label for='tabletennis-radio-no'>No</label>
                                </div>
                              </div>

                              <div class='form-group yesno col-md-2'>
                                <label>Swimming</label>
                                <div class='radio-flex'>
                                  <input
                                    id='swimming-radio'
                                    class='form-control1'
                                    type='radio'
                                    name='swimbib'
                                    value='yes'
                                    onChange={this.swimChange}
                                  />
                                  <label for='swimming-radio'>Yes</label>
                                  <input
                                    id='swimming-radio-no'
                                    class='form-control1 form-control2'
                                    type='radio'
                                    name='swimbib'
                                    value='no'
                                    onChange={this.swimChange}
                                  />
                                  <label for='swimming-radio-no'>No</label>
                                </div>
                              </div>

                              <div class='form-group yesno col-md-2'>
                                <label>Archery</label>
                                <div class='radio-flex'>
                                  <input
                                    id='archery-radio'
                                    class='form-control1'
                                    type='radio'
                                    name='archerybib'
                                    value='yes'
                                    onChange={this.arcChange}
                                  />
                                  <label for='archery-radio'>Yes</label>
                                  <input
                                    id='archery-radio-no'
                                    class='form-control1 form-control2'
                                    type='radio'
                                    name='archerybib'
                                    value='no'
                                    onChange={this.arcChange}
                                  />
                                  <label for='archery-radio-no'>No</label>
                                </div>
                              </div>

                              <div class='form-group yesno col-md-2'>
                                <label>Wheelchair Tennis</label>
                                <div class='radio-flex'>
                                  <input
                                    id='fencing-radio'
                                    class='form-control1'
                                    type='radio'
                                    name='fencebib'
                                    value='yes'
                                    onChange={this.fenceChange}
                                  />
                                  <label for='fencing-radio'>Yes</label>
                                  <input
                                    id='fencing-radio-no'
                                    class='form-control1 form-control2'
                                    type='radio'
                                    name='fencebib'
                                    value='no'
                                    onChange={this.fenceChange}
                                  />
                                  <label for='fencing-radio-no'>No</label>
                                </div>
                              </div>

                              <div class='form-group yesno col-md-2'>
                                <label>Team</label>
                                <div class='radio-flex'>
                                  <input
                                    id='team-radio'
                                    class='form-control1'
                                    type='radio'
                                    name='teambib'
                                    value='yes'
                                    onChange={this.teamChange}
                                  />
                                  <label for='team-radio'>Yes</label>
                                  <input
                                    id='team-radio-no'
                                    class='form-control1 form-control2'
                                    type='radio'
                                    name='teambib'
                                    value='no'
                                    onChange={this.teamChange}
                                  />
                                  <label for='team-radio-no'>No</label>
                                </div>
                              </div>

                              <div class='form-group yesno col-md-2'>
                                <label>Other</label>
                                <div class='radio-flex'>
                                  <input
                                    id='other-radio'
                                    class='form-control1'
                                    type='radio'
                                    name='otherbib'
                                    value='yes'
                                    onChange={this.otherChange}
                                  />
                                  <label for='other-radio'>Yes</label>
                                  <input
                                    id='other-radio-no'
                                    class='form-control1 form-control2'
                                    type='radio'
                                    name='otherbib'
                                    value='no'
                                    onChange={this.otherChange}
                                  />
                                  <label for='other-radio-no'>No</label>
                                </div>
                              </div>

                              <div class='form-group col-md-12'>
                                <div class='form-group col-md-3'>
                                  <label for='inputZip1'>Game logo</label>
                                  <br></br>
                                  <label for='file-upload' class='form-control'>
                                    Logo Upload
                                  </label>
                                  <input
                                    id='file-upload'
                                    class='form-control'
                                    type='file'
                                    onChange={this.handleChange}
                                  />
                                  <img src={this.state.logo} />
                                </div>
                              </div>
                              <div className='content'>
                                <Row>
                                  <Col md='12'>
                                    <Card>
                                      <CardHeader id='card'>
                                        <h5 className='title'>Point Set</h5>
                                      </CardHeader>

                                      <CardBody>
                                        <Form>
                                          <div class='form-group row'>
                                            <div class='col-xs-5'>
                                              <CardHeader>
                                                <div class='form-row'>
                                                  <div class='form-group col-md-6'>
                                                    <label>Type Games</label>
                                                    <select
                                                      class='form-control'
                                                      id='exampleFormControlSelect1'
                                                      name='typegamedata'
                                                      value={this.state.typegamedata}
                                                      onChange={this.dropdownchange}
                                                      required
                                                    >
                                                      <option disabled>Please Select</option>
                                                      <option value='Local'>Local</option>
                                                      <option value='Regional'>Regional</option>
                                                      <option value='National'>National</option>
                                                      <option value='International'>International</option>
                                                    </select>
                                                  </div>
                                                  <div class='form-group col-md-6'>
                                                    <label>Placing Home Country Only</label>
                                                    <select
                                                      class='form-control'
                                                      id='exampleFormControlSelect1'
                                                      name='placing'
                                                      value={this.state.placing}
                                                      onChange={this.dropdownchange1}
                                                      required
                                                    >
                                                      <option disabled>Please Select</option>
                                                      <option value='yes'>Yes</option>
                                                      <option value='no'>No</option>
                                                    </select>
                                                  </div>
                                                  {/* <div class="form-group col-md-6">
                                                      <label>Type Record</label>
                                                      <input
                                                        type="text"
                                                        class="form-control"
                                                        id="typerecord"
                                                        name="typerecord"
                                                        value={
                                                          this.state.typerecord
                                                        }
                                                        onChange={this.typechange}
                                                      ></input>
                                                    </div> */}
                                                </div>
                                              </CardHeader>
                                            </div>
                                          </div>
                                          <Table id='my' responsive>
                                            <thead>
                                              <tr>
                                                <th>Place</th>
                                                <th>1</th>
                                                <th>2</th>
                                                <th>3</th>
                                                <th>4</th>
                                                <th>5</th>
                                                <th>6</th>
                                                <th>7</th>
                                                <th>8</th>
                                                <th>9</th>
                                                <th>10</th>
                                              </tr>
                                            </thead>
                                            <tbody id='myTable'>
                                              <tr>
                                                <td>Points</td>
                                                <td>
                                                  <input
                                                    type='text'
                                                    class='form-control points'
                                                    id='points1'
                                                    name='points1'
                                                    value={this.state.points1}
                                                    onChange={this.optionchange1}
                                                    required
                                                  ></input>
                                                </td>
                                                <td>
                                                  <input
                                                    type='text'
                                                    class='form-control points'
                                                    id='points2'
                                                    name='points2'
                                                    value={this.state.points2}
                                                    onChange={this.optionchange2}
                                                    required
                                                  ></input>
                                                </td>
                                                <td>
                                                  <input
                                                    type='text'
                                                    class='form-control points'
                                                    id='points3'
                                                    name='points3'
                                                    value={this.state.points3}
                                                    onChange={this.optionchange3}
                                                    required
                                                  ></input>
                                                </td>
                                                <td>
                                                  <input
                                                    type='text'
                                                    class='form-control points'
                                                    id='points4'
                                                    name='points4'
                                                    value={this.state.points4}
                                                    onChange={this.optionchange4}
                                                    required
                                                  ></input>
                                                </td>
                                                <td>
                                                  <input
                                                    type='text'
                                                    class='form-control points'
                                                    id='points5'
                                                    name='points5'
                                                    value={this.state.points5}
                                                    onChange={this.optionchange5}
                                                    required
                                                  ></input>
                                                </td>
                                                <td>
                                                  <input
                                                    type='text'
                                                    class='form-control points'
                                                    id='points6'
                                                    name='points6'
                                                    value={this.state.points6}
                                                    onChange={this.optionchange6}
                                                    required
                                                  ></input>
                                                </td>
                                                <td>
                                                  <input
                                                    type='text'
                                                    class='form-control points'
                                                    id='points7'
                                                    name='points7'
                                                    value={this.state.points7}
                                                    onChange={this.optionchange7}
                                                    required
                                                  ></input>
                                                </td>
                                                <td>
                                                  <input
                                                    type='text'
                                                    class='form-control points'
                                                    id='points8'
                                                    name='points8'
                                                    value={this.state.points8}
                                                    onChange={this.optionchange8}
                                                    required
                                                  ></input>
                                                </td>
                                                <td>
                                                  <input
                                                    type='text'
                                                    class='form-control points'
                                                    id='points9'
                                                    name='points9'
                                                    value={this.state.points9}
                                                    onChange={this.optionchange9}
                                                    required
                                                  ></input>
                                                </td>
                                                <td>
                                                  <input
                                                    type='text'
                                                    class='form-control points'
                                                    id='points10'
                                                    name='points10'
                                                    value={this.state.points10}
                                                    onChange={this.optionchange10}
                                                    required
                                                  ></input>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </Table>
                                        </Form>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                            {this.state.btncheck == true ? (
                              <center>
                                <button type='submit' class='btn btn-danger' onClick={(e) => this.updatemeetdetail(e)}>
                                  Update
                                </button>
                              </center>
                            ) : (
                              <>
                                <center>
                                  <button
                                    type='submit'
                                    class='btn btn-danger'
                                    onClick={(event) => this.createmeet(event)}
                                  >
                                    Update
                                  </button>
                                </center>
                              </>
                            )}
                          </form>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                {/* <Switch>
  <Route path="/samsreact/eventsponser">
  <Eventsponser/>
  </Route>
  <Route path="/samsreact/atheleteregister">
  <Atheleteregister/>
  </Route>
  <Route path="/samsreact/managervenues">
  <ManagerVenue/>
  </Route>
  <Route path="/samsreact/loacalcommittee">
  <LocalCommit/>
  </Route>
  <Route path="/samsreact/atheleteresult">
  <Atheleteresult/>
  </Route>
  </Switch> */}
              </div>

              {/* edit -------------------------------------------------------------------------------------- */}
              <div id='editmeets' class='modal fade' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog modal-lg'>
                  <div class='modal-content'>
                    <div class='modal-header'>
                      <button type='button' class='close' data-dismiss='modal'>
                        &times;
                      </button>
                      <h4 class='modal-title'>Edit Event Sponser</h4>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-row'>
                          <div class='form-group col-md-6'>
                            <label for='inputEmail4'>Org Name</label>
                            <input type='text' class='form-control' id='inputEmail4' value='Move United' />
                          </div>
                          <div class='form-group col-md-6'>
                            <label for='inputPassword4'>Meet name</label>
                            <input
                              type='text'
                              class='form-control'
                              id='inputPassword4'
                              value={this.state.editmeetdatas.meet_name}
                            />
                          </div>
                        </div>
                        <div class='form-group'>
                          <label for='inputAddress'>Short Meet Name</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress'
                            name='eshortname'
                            value={this.state.eshortname}
                            onChange={this.handleChange1}
                          />
                        </div>
                        <div class='form-group'>
                          <label for='inputAddress2'>Meet City</label>
                          <input
                            type='text'
                            class='form-control'
                            id='inputAddress2'
                            name='emeetcity'
                            value={this.state.emeetcity}
                            onChange={this.handleChange1}
                          />
                        </div>
                        <div class='form-row'>
                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Meet Country</label>
                            <input
                              type='text'
                              class='form-control'
                              id='inputCity'
                              name='emeetcountry'
                              value={this.state.emeetcountry}
                              onChange={this.handleChange1}
                            />
                          </div>

                          <div class='form-group col-md-6'>
                            <label for='inputCity'>Meet Director</label>
                            <input
                              type='text'
                              class='form-control'
                              id='inputCity'
                              name='emeetdirector'
                              value={this.state.emeetdirector}
                              onChange={this.handleChange1}
                            />
                          </div>
                          <div class='form-group col-md-2'>
                            <label for='inputZip'>Meet Start Date</label>
                            <input
                              type='date'
                              class='form-control'
                              id='inputZip'
                              name='emeetstartdate'
                              value={this.state.emeetstartdate}
                              onChange={this.handleChange1}
                            />
                          </div>
                          <div class='form-group col-md-2'>
                            <label for='inputZip'>Meet End Date</label>
                            <input
                              type='date'
                              class='form-control'
                              id='inputZip'
                              name='emeetenddate'
                              value={this.state.emeetenddate}
                              onChange={this.handleChange1}
                            />
                          </div>

                          <div class='form-group col-md-3'>
                            <label for='inputZip'>Game logo</label>
                            <br></br>
                            <label for='file-upload' class='form-control'>
                              Logo Upload
                            </label>
                            <input id='file-upload' class='form-control' type='file' onChange={this.handleChange} />
                            <img src={this.state.file} />
                          </div>
                        </div>
                      </form>
                    </div>
                    <div>
                      <center>
                        <button
                          type='button'
                          class='btn btn-danger'
                          onClick={(e) => this.updatemeetdetail(e)}
                          data-dismiss='modal'
                        >
                          Update
                        </button>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Meetviewmanager;
