import React from 'react';

// reactstrap components
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
// core components

import axios from 'axios';
import Swal from 'sweetalert2';
const url = window.globalConfig;
var c;
var d;
var f;
var g;

class Trackstandard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qdatas: [{}],
      qualdatas: [{}],
      eventsdropdown: [{}],
      classdropdown: [{}],
      venueclassid: [{}],
      agediv: [{}],
      venue: '',
      gender: '',
      qb: '',
      implement: '',
      qclass: '',
      qstd: '',
      adivision: '',
      event: '',
      vclass: '',
      clsvalue: '',
      agechange: '',
      classvalue: [{}],
      venueid: '',
      vclassid: '',
      defaultgender: '',
      defaultvenuename: '',
      defaulteventname: '',
      defqb: '',
      defqstd: '',
      defimpl: '',
      defqcls: '',
      defagebreak: '',
      defagediv: '',
      defid: '',
      defaultvenueclass: '',
      defaultclassvalue: '',
      classvaluedata: [],
    };
    this.optionchange = this.optionchange.bind(this);
    this.optionchange1 = this.optionchange1.bind(this);
    this.optionchange2 = this.optionchange2.bind(this);
    this.optionchange3 = this.optionchange3.bind(this);
    this.optionchange4 = this.optionchange4.bind(this);
    //    this.createqstandard=this.createqstandard.bind(this);
    this.venueclasschange = this.venueclasschange.bind(this);
    this.delete = this.delete.bind(this);
    this.edit = this.edit.bind(this);
    this.update = this.update.bind(this);
    this.classdata = this.classdata.bind(this);
    this.back = this.back.bind(this);
  }
  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    const load = (data) => {
      this.setState({
        qdatas: data,
      });
    };
    $(document).ready(function () {
      var data = {
        venue_name: 'Track',
      };
      axios.post(`${url.url}/api/gettrackqstd`, data, {}).then(function (response) {
        load(response.data.data);
      });
    });
  }

  edit(e) {
    var elid;
    var xy;
    var yx;
    var classid = [];
    var self = this;
    var array = this.state.qualdatas;
    this.state.qualdatas.map((key, index) => {
      if (key.name == this.state.qdatas[e].venue.name) {
        var et = key.name + '-' + index + '-' + key.id;
        self.setState({
          defaultvenuename: et,
        });
        xy = et.split('-');
        yx = xy[2];
        this.optionchange(et);
        key.events.map((element, index2) => {
          if (element.id == this.state.qdatas[e].event.id) {
            var te = element.name + '-' + element.id + '-' + element.venue_id;
            //console.log(element.id)
            // console.log(this.state.qdatas[e].event.id)
            self.setState({
              defaulteventname: te,
            });
            var d = te.split('-')[0].split(' ').join('');
            // console.log(d)
            self.setState({
              evnt: d,
            });
          }
        });
        //
        //  key.venueclasses.map((element1, index3)=>{
        //   if(element1.name == this.state.qdatas[e].venue_class.name){
        //    // console.log("hhhh"+element.name)
        //     var ten = element1.id+"-"+index3
        //     self.setState({
        //       defaultvenueclass: ten
        //     })
        //     elid = element1.id
        //   }
        //   //console.log(elid)
        // })
        this.state.agediv.map((keys, indx) => {
          if (keys.id == this.state.qdatas[e].age_division_id) {
            var fen = keys.id + '-' + indx;
            self.setState({
              defagediv: fen,
            });
            var t = fen.split('-')[1];
            self.setState({ dagediv: this.state.agediv[t].name });
          }
        });
        var class_value = this.state.qdatas[e].class.name;
        axios
          .get(`${url.url}/api/getclassvalue/${yx}`, {})
          .then(function (response) {
            self.setState({
              classvaluedata: response.data,
            });
            classid = response.data;
            classid.map((element2, index4) => {
              if (element2.name == class_value) {
                var len = element2.name + '-' + element2.id;
                self.setState({
                  defaultclassvalue: len,
                });
                //   var h = len.split("-")[1]
                //  // console.log(h)
                //   self.setState({classidname:classid[h].name})
                //  // console.log(classid[h].name)
              }
            });
          })
          .catch(function (error) {
            self.setState({
              venueclassid: [{}],
            });
          });
        return;
      }
    });
    self.setState({
      defaultgender: this.state.qdatas[e].gender,
      defqb: this.state.qdatas[e].qual_b_standard,
      defqstd: this.state.qdatas[e].qual_standard,
      defimpl: this.state.qdatas[e].implement,
      defqcls: this.state.qdatas[e].qualclass,
      defagebreak: this.state.qdatas[e].age_division_break,
      defid: parseInt(this.state.qdatas[e].id),
    });
  }
  optionchange(e) {
    var i = this.state.qualdatas[parseInt(e.split('-')[1])];
    var j = this.state.qualdatas[parseInt(e.split('-')[1])];
    var k = e.split('-')[0];
    var self = this;
    self.setState({
      defaultvenuename: e,
      eventsdropdown: i,
      classdropdown: j,
      venueid: i.id,
    });
    var c = e.split('-')[0].toString().split('');
    var g = c[0] + c[1];

    var x = e.split('-');
    var m = x[2];
    self.setState({
      evntd: g,
    });
    this.classdata(e);
  }
  classdata(e) {
    var x = e.split('-');
    var m = x[2];
    const dummy = (data) => {
      this.setState({ classvaluedata: data });
    };
    axios.get(`${url.url}/api/getclassvalue/${m}`, {}).then(function (response) {
      dummy(response.data);
    });
  }
  optionchange1(e) {
    var self = this;
    var d = e.split('-')[0].split(' ').join('');
    self.setState({
      evnt: d,
    });
    self.setState({
      defaulteventname: e,
    });
  }

  optionchanges(e) {
    var self = this;
    self.setState({
      defaulteventnames: e,
    });
  }
  optionchange3(e) {
    var self = this;
    var t = e.target.value.split('-')[1];
    var r = e.target.value.split('-')[0];
    self.setState({ dagediv: this.state.agediv[t].name });
    self.setState({
      defagediv: e.target.value,
    });
  }

  optionchange4(e) {
    var self = this;
    self.setState({
      defaultgender: e.target.value,
      [e.target.name]: e.target.value,
    });
  }

  venueclasschange(e) {
    //console.log(e.split("-"))
    var i = e.split('-');
    var l = parseInt(i[0]);
    var k = parseInt(i[1]);
    var self = this;
    self.setState({
      defaultvenueclass: e,
      //vclassid:i.id
    });

    self.setState({
      clasven: this.state.classdropdown.venueclasses[k],
      venclid: this.state.classdropdown.venueclasses[k].id,
    });
    axios
      .get(`${url.url}/api/getvenueclassid/${this.state.classdropdown.venueclasses[k].id}`, {})
      .then(function (response) {
        self.setState({
          venueclassid: response.data,
        });
        //console.log(response.data)
      })
      .catch(function (error) {
        self.setState({
          venueclassid: [{}],
        });
      });
  }
  optionchange2(e) {
    //console.log(e)
    var self = this;
    // var h = e.split("-")[1]
    // self.setState({classidname:this.state.venueclassid[h].name})
    // console.log(this.state.venueclassid[h].name)
    self.setState({
      defaultclassvalue: e,
      // [e.target.name]: e.target.value
    });
  }

  update(e) {
    e.preventDefault();
    var x =
      this.state.evntd +
      this.state.defaultclassvalue.split('-')[0] +
      this.state.dagediv +
      this.state.defaultgender.split('')[0] +
      this.state.defaultgender.split('')[1] +
      this.state.evnt;
    const datatosend = {
      venue_name: this.state.venueid,
      Class_value: this.state.defaultclassvalue.split('-')[1],
      Age_division: parseInt(this.state.defagediv),
      Gender: this.state.defaultgender,
      event_name: parseInt(this.state.defaulteventname.split('-')[1]),
      B_standard: this.state.defqb,
      Qualifying_standard: this.state.defqstd,
      Implement: this.state.defimpl,
      qualclass: x,
      Age_group: 'null',
      DSO: this.state.dso,
      Type_pool: this.state.typepool,
    };
    axios
      .put(`${url.url}/api/updatequalifyingstandard/${this.state.defid}`, datatosend, {})

      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            title: 'Success',
            text: 'Updated successfully',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  delete(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deletequalifyingstandard/${this.state.qdatas[e].id}`, {})
          .then(function (response) {})

          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });

    // delete-----------------------------------------------------------------------------------------
  }

  back() {
    window.location.replace('/Qualifyingstandard');
  }

  render() {
    return (
      <>
        <div className='content'>
          <Row>
            <Col md='8'>
              <button type='button' class='btn btn-danger btn-round' onClick={this.back}>
                Back
              </button>

              <Card>
                <CardHeader>
                  <h5 className='title'>Track Qualifying Standards</h5>
                </CardHeader>

                <CardBody>
                  <Form>
                    <div class='form-group row'>
                      <div class='col-xs-5'>
                        <CardHeader>
                          <p>Search</p>
                          <input class='form-control' id='inputsm' placeholder='Search..' type='text' />
                        </CardHeader>
                      </div>
                    </div>

                    {/* Table -------------------------------------------- */}

                    <Table id='my' responsive>
                      <thead>
                        <tr>
                          <th className='text-center'>#</th>
                          <th>Venues</th>
                          <th>Event</th>
                          <th>Qualifying Standard</th>
                          <th>B Standard</th>
                          <th>Class Values</th>
                          <th>Age Division</th>
                          <th>Gender</th>
                          <th>DSO</th>
                          <th>Implement</th>
                          <th>Type Pool</th>
                          <th>Age Group</th>
                          <th>QualClass</th>
                          <th class='disabled-sorting text-right'>Actions</th>
                        </tr>
                      </thead>
                      <tbody id='myTable'>
                        {this.state.qdatas.map((question, index) => (
                          // console.log(question),
                          // c = question.venue.name.split(''),
                          // d = c[0]+c[1],
                          // f = question.event.name.split(' ').join(''),
                          // g = question.gender.split('')[0]+question.gender.split('')[1],

                          <tr>
                            <td>{index + 1}</td>
                            <td>{question.venue_name}</td>
                            <td>{question.event_name}</td>
                            <td>{question.Qualifying_standard}</td>
                            <td>{question.B_standard}</td>
                            <td>{question.Class_values}</td>
                            <td>{question.Age_division}</td>
                            <td>{question.Gender}</td>
                            <td>{question.DSO}</td>
                            <td>{question.Implement}</td>
                            <td>{question.Type_pool}</td>
                            <td>{question.Age_group}</td>
                            <td>{question.Qual_class}</td>

                            <td class='td-actions text-right'>
                              <button
                                type='button'
                                rel='tooltip'
                                class='btn btn-success btn-sm btn-icon'
                                onClick={() => this.edit(index)}
                                data-toggle='modal'
                                data-target='#deletequal'
                              >
                                <i class='now-ui-icons ui-2_settings-90'></i>
                              </button>
                              {` `}
                              <button
                                type='button'
                                rel='tooltip'
                                class='btn btn-danger btn-sm btn-icon'
                                onClick={() => this.delete(index)}
                              >
                                <i class='now-ui-icons ui-1_simple-remove'></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    {/* Table -------------------------------------------- */}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div class='modal fade' id='deletequal' tabindex='-1' role='dialog' aria-hidden='true'>
            <div class='modal-dialog modal-lg'>
              <div class='modal-content'>
                <div class='modal-header '>
                  <h5 class='modal-title ' id='loginmodalLabel'>
                    Edit Qualifying Standards Details
                  </h5>
                  <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <div class='modal-body'>
                  <form id='myform'>
                    <div class='form-row'>
                      <div class='form-group col-md-6'>
                        <label for='inputCity'>Venue *</label>
                        <select
                          class='form-control'
                          id='exampleFormControlSelect1'
                          name='venue'
                          value={this.state.defaultvenuename}
                          onChange={(e) => this.optionchange(e.target.value)}
                          required
                        >
                          <option>Please Select</option>
                          {this.state.qualdatas.map((qevent, index) => (
                            <option value={qevent.name + '-' + index + '-' + qevent.id}>{qevent.name}</option>
                          ))}
                        </select>
                      </div>

                      <div class='form-group col-md-6'>
                        <label>Event *</label>
                        <select
                          class='form-control'
                          id='exampleFormControlSelect1'
                          name='event'
                          value={this.state.defaulteventname}
                          onChange={(e) => this.optionchange1(e.target.value)}
                          required
                        >
                          <option>Please Select</option>
                          {Object.keys(this.state.eventsdropdown).length == 1 ? (
                            <option></option>
                          ) : (
                            <>
                              {this.state.eventsdropdown.events.map((edrop, index) => (
                                <option value={edrop.name + '-' + edrop.id + '-' + edrop.venue_id}>{edrop.name}</option>
                              ))}
                            </>
                          )}
                        </select>
                      </div>
                      {/* <div class="form-group col-md-4">
<label >Venue Class *</label>
<select  class="form-control" id="exampleFormControlSelect1" name="vclass" value={this.state.defaultvenueclass} onChange={(e)=>this.venueclasschange(e.target.value)} required>
<option>Please Select</option>
{ Object.keys(this.state.classdropdown).length == 1 ?
   <option></option>
  :  <>
{ this.state.classdropdown.venueclasses.map((clsdrop, index)=>(
  <option  value={clsdrop.id+"-"+index}>{clsdrop.name}</option>
))}
</>
}
</select>
</div> */}
                    </div>

                    <div class='form-row'>
                      <div class='form-group col-md-4'>
                        <label for='inputCity'>Class Value *</label>
                        <select
                          class='form-control'
                          id='exampleFormControlSelect1'
                          name='clsvalue'
                          value={this.state.defaultclassvalue}
                          onChange={(e) => this.optionchange2(e.target.value)}
                          required
                        >
                          <option>Please Select</option>
                          {Object.keys(this.state.classvaluedata).length == 0 ? (
                            <option></option>
                          ) : (
                            <>
                              {this.state.classvaluedata.map((cvalue, index) => (
                                <option value={cvalue.name + '-' + cvalue.id}>{cvalue.name}</option>
                              ))}
                            </>
                          )}
                        </select>
                      </div>

                      <div class='form-group col-md-4'>
                        <label>Age Division *</label>
                        <select
                          class='form-control'
                          id='exampleFormControlSelect1'
                          name='defagediv'
                          value={this.state.defagediv}
                          onChange={this.optionchange3}
                          required
                        >
                          <option>Please Select</option>
                          {Object.keys(this.state.agediv).length == 1 ? (
                            <option></option>
                          ) : (
                            <>
                              {this.state.agediv.map((agedivision, index) => (
                                <option value={agedivision.id + '-' + index}>{agedivision.name}</option>
                              ))}
                            </>
                          )}
                        </select>
                      </div>
                      <div class='form-group col-md-4'>
                        <label>Gender *</label>
                        <select
                          class='form-control'
                          id='exampleFormControlSelect1'
                          name='gender'
                          value={this.state.defaultgender}
                          onChange={this.optionchange4}
                          required
                        >
                          <option>Please select</option>
                          <option value='male'>Male</option>
                          <option value='female'>Female</option>
                        </select>
                      </div>
                    </div>

                    <div class='form-row'>
                      <div class='form-group col-md-4'>
                        <label for='inputCity'>Qualstandard</label>
                        <input
                          type='text'
                          class='form-control'
                          placeholder='Enter Qualstandard'
                          name='defqstd'
                          value={this.state.defqstd}
                          onChange={this.optionchange4}
                          required
                        />
                      </div>

                      <div class='form-group col-md-4'>
                        <label>Qualstandard B</label>
                        <input
                          type='text'
                          class='form-control'
                          placeholder='Enter B Qualstandard'
                          name='defqb'
                          value={this.state.defqb}
                          onChange={this.optionchange4}
                          required
                        />
                      </div>
                      <div class='form-group col-md-4'>
                        <label>Implement</label>
                        <input
                          type='text'
                          class='form-control'
                          placeholder='Enter Implement'
                          name='defimpl'
                          value={this.state.defimpl}
                          onChange={this.optionchange4}
                          required
                        />
                      </div>
                    </div>

                    <div class='form-row'>
                      <div class='form-group col-md-4'>
                        <label for='inputCity'>DSO</label>
                        <input
                          type='text'
                          class='form-control'
                          placeholder='Enter DSO'
                          name='defagebreak'
                          onChange={this.optionchange4}
                          required
                        />
                      </div>

                      <div class='form-group col-md-4'>
                        <label for='inputCity'>Type Pool</label>
                        <input
                          type='text'
                          class='form-control'
                          placeholder='Enter Type pool'
                          name='defagebreak'
                          onChange={this.optionchange4}
                          required
                        />
                      </div>

                      <div class='form-group col-md-4'>
                        <label for='inputCity'>Age Group</label>
                        <input
                          type='text'
                          class='form-control'
                          placeholder='Enter Age group'
                          name='defagebreak'
                          value={this.state.defagebreak}
                          onChange={this.optionchange4}
                          required
                        />
                      </div>

                      <div class='form-group col-md-4'>
                        <label>Qual Class *</label>
                        <input
                          type='text'
                          class='form-control'
                          placeholder='Enter Qual Class'
                          name='defqcls'
                          value={this.state.defqcls}
                          onChange={this.optionchange4}
                          required
                        />
                      </div>
                    </div>

                    <div class='form-group'>
                      <center>
                        <button type='submit' onClick={this.update} data-dismiss='modal' class='btn btn-danger'>
                          Update Qualifying Standards
                        </button>
                      </center>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Trackstandard;
