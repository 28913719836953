import React from 'react';

import '../App.css';

import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import 'datatables.net';
import 'datatables.net-bs4';
import { faWindows } from '@fortawesome/free-brands-svg-icons';

import FullScreenMap2 from './Venueclasses';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
class FullScreenMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      venuedatas: [{}],
      newvenue: '',
      vname: '',
      vvalue: '',
      vedata: '',
      venuehome: false,
      eventhome: true,
      venueclasshome: false,
      venuespecfic: [{}],
      editename: '',
      editagerange: '',
      editisrelaychecked: '',
      editeid: '',
      newevents: '',
      newageevents: '',
      newvenueclass: '',
      evenueclassname: '',
      evenueclassid: '',
      venuebyid: [{}],
      newclassvalue: '',
      vid: [{}],
      newclsvalue: '',
      newclsid: '',
      rerend: false,
      active1: false,
      loader: false,
      classdata: [{}],
      classhome: false,
      preloader: true,
      isRelaychecked: '',
      editdeftypepool: '',
      deftypepool: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.eventedit = this.eventedit.bind(this);
    this.updateevent = this.updateevent.bind(this);
    this.eventdelete = this.eventdelete.bind(this);
    this.createevent = this.createevent.bind(this);
    this.createclassvalue = this.createclassvalue.bind(this);
    this.updateclassvalue = this.updateclassvalue.bind(this);
    this.deleteclassvalue = this.deleteclassvalue.bind(this);
    this.backhome = this.backhome.bind(this);
    this.eventclassvalues = this.eventclassvalues.bind(this);
  }

  componentWillMount() {
    var self = this;
    let textval = sessionStorage.getItem('x');

    self.setState({ vedata: textval });
    // console.log(textval);
    axios.get(`${url.url}/api/venues`, {}).then(function (response) {
      self.setState({
        venuedatas: response.data.data,
        venuespecfic: response.data.data[textval],
      });
      // console.log(response.data.data[textval]);
    });
  }
  componentDidMount() {
    // $(document).ready(function () {
    //   $("#inputsm").on("keyup", function () {
    //     var value = $(this).val().toLowerCase();
    //     $("#myTable tr").filter(function () {
    //       $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    //     });
    //   });
    // });
    var self = this;

    let textval = sessionStorage.getItem('x');
    // let venuname = sessionStorage.getItem("venue_name");
    self.setState({ vedata: textval });
    // console.log(textval);
    axios.get(`${url.url}/api/venues`, {}).then(function (response) {
      self.setState({
        venuedatas: response.data.data,
        venuespecfic: response.data.data[textval],
        preloader: false,
      });
      // console.log(response.data.data[textval]);
      // console.log(response.data.data);
    });

    let venid = sessionStorage.getItem('venuesid');
    //class value get api
    axios.get(`${url.url}/api/getclassvalue/${venid}`, {}).then(function (response) {
      self.setState({
        classdata: response.data,
      });
      // console.log(response.data);
    });
  }
  onSearch = (e) => {
    //console.log($("#inputsm"))
    var value = e.target.value.toLowerCase();
    $('#myTable tr').filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
  };

  // delete-----------------------------------------------------------

  // event edit delete ---------------------------------------------------------------
  eventedit(e) {
    var self = this;

    self.setState({
      editename: this.state.venuespecfic.events[e].name,
      editeid: this.state.venuespecfic.events[e].id,
      editagerange: this.state.venuespecfic.events[e].age_range,
      editisrelaychecked: this.state.venuespecfic.events[e].is_relay,
      editdeftypepool: this.state.venuespecfic.events[e].typepool,
    });
  }

  updateevent(e) {
    var self = this;
    e.preventDefault();

    //Event Sorting Condition
    var splitevent = '';
    var spliteventnotrelay = this.state.editename.split(' ');

    if (
      (sessionStorage.getItem('venue_name') == 'Swimming' && this.state.editisrelaychecked) ||
      (sessionStorage.getItem('venue_name') == 'Track' && this.state.editisrelaychecked)
    ) {
      var relaysplit = this.state.editename.split('X');
      var relaysplit1 = relaysplit[1].trim().split(' ');
      // console.log(relaysplit1)
      splitevent = relaysplit[0].trim() + relaysplit1[0].trim();
    }
    // else if(this.state.editisrelaychecked) {
    //   var relaysplit = this.state.editename.split('X');
    //   splitevent = relaysplit[0]+relaysplit[1];

    // }
    else {
      splitevent = spliteventnotrelay[0];
    }

    //End of Event Sorting Condition
    const datatosend = {
      name: this.state.editename,
      age_range: this.state.editagerange,
      is_relay: this.state.editisrelaychecked,
      typepool: this.state.editdeftypepool,
      created_by: 1,
      eventsort:
        sessionStorage.getItem('venue_name') == 'Track' || sessionStorage.getItem('venue_name') == 'Swimming'
          ? splitevent
          : '',
    };

    axios
      .put(`${url.url}/api/updateevent/${this.state.editeid}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Event Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  eventdelete(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deleteevent/${this.state.venuespecfic.events[e].id}`, {})
          .then(function (response) {})

          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });
  }

  createevent(e) {
    // var self = this;
    e.preventDefault();

    //Event Sorting Condition
    var splitevent = '';
    var spliteventnotrelay = this.state.newevents.split(' ');

    if (
      (sessionStorage.getItem('venue_name') == 'Swimming' && this.state.isRelaychecked) ||
      (sessionStorage.getItem('venue_name') == 'Track' && this.state.isRelaychecked)
    ) {
      var relaysplit = this.state.newevents.split('X');
      var relaysplit1 = relaysplit[1].trim().split(' ');
      splitevent = relaysplit[0].trim() + relaysplit1[0].trim();
    } else {
      splitevent = spliteventnotrelay[0];
    }

    //End of Event Sorting Condition

    const datatosend = {
      name: this.state.newevents,
      age_range: this.state.newageevents,
      typepool: this.state.deftypepool,
      created_by: 1,
      venue_id: parseInt(this.state.venuespecfic.id),
      is_relay: this.state.isRelaychecked,
      eventsort:
        sessionStorage.getItem('venue_name') == 'Track' || sessionStorage.getItem('venue_name') == 'Swimming'
          ? splitevent
          : '',
    };
    axios
      .post(`${url.url}/api/createevent/`, datatosend, {})
      .then(function (response) {
        if (response.statusText == 'Created' || response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'Event Created successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong Please check the field name',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  // event edit add delete ---------------------------------------------------------------

  // ----------------------------------------

  handleChange(e) {
    var self = this;
    self.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleChange1 = (e) => {
    this.setState({
      newageevents: e.target.value,
    });
  };
  handleChange2 = (e) => {
    this.setState({
      editagerange: e.target.value,
    });
  };
  OptionChange1 = (e) => {
    this.setState({
      editdeftypepool: e.target.value,
    });
  };

  handleChange3 = (e) => {
    if (document.getElementById('isRelay').checked) {
      this.setState({
        editisrelaychecked: e.target.checked,
      });
    } else {
      this.setState({
        editisrelaychecked: '',
      });
    }
  };

  createclassvalue(e) {
    var self = this;
    e.preventDefault();
    // let id = sessionStorage.getItem("venue_id")
    let id = parseInt(this.state.venuespecfic.id);
    // console.log(id);
    const datatosend = {
      name: this.state.newclassvalue,
      created_by: 1,
      venue_id: id,
    };
    // console.log(datatosend);
    axios
      .post(`${url.url}/api/createclassvalue/`, datatosend, {})
      .then(function (response) {
        if (response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'Venue Class value Created Successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong Please check the field name',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  venueclassvalueedit(e) {
    var self = this;
    self.setState({
      newclsvalue: this.state.classdata[e].name,
      newclsid: this.state.classdata[e].id,
    });
    // console.log(this.state.classdata[e].name)
    // console.log(this.state.classdata[e].id)
  }

  updateclassvalue(e) {
    var self = this;
    e.preventDefault();
    const datatosend = {
      name: this.state.newclsvalue,
    };
    // console.log(datatosend)
    axios
      .put(`${url.url}/api/updateclass/${this.state.newclsid}`, datatosend, {})
      .then(function (response) {
        if (response.statusText === 'OK' || response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Venue Class value Updated Successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  deleteclassvalue(e) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This Data will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        document.getElementById('myTable').deleteRow(e);
        axios
          .delete(`${url.url}/api/deleteclass/${this.state.classdata[e].id}`, {})
          .then(function (response) {})

          .catch(function (error) {
            Swal.fire({
              title: 'Failed',
              text: 'Something went wrong',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok!',
            });
          });
        Swal.fire('Deleted!', 'Record has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancelled :)', 'error');
      }
    });
  }

  //-------------------------------------------------

  eventclassvalues(e) {
    // console.log(e)
    // if()
    var self = this;
    sessionStorage.setItem('eventindex', e);

    sessionStorage.setItem('eventid', this.state.venuespecfic.events[e].id);
    sessionStorage.setItem('eventname', this.state.venuespecfic.events[e].name);
    self.setState({
      classhome: true,
      editename: this.state.venuespecfic.events[e].name,
      editeid: this.state.venuespecfic.events[e].id,
      editagerange: this.state.venuespecfic.events[e].age_range,
    });
    if (
      this.state.venuespecfic.events[e].name == 'Paratriathlon 100' ||
      this.state.venuespecfic.events[e].name == 'Paratriathlon 200' ||
      this.state.venuespecfic.events[e].name == '3X3 Basketball Div1' ||
      this.state.venuespecfic.events[e].name == '3X3 Basketball Div2' ||
      this.state.venuespecfic.events[e].name == '3X3 Basketball Women'
      // (this.state.venuespecfic.events[e].name == 'Yeoman')||
      // (this.state.venuespecfic.events[e].name == 'Bowmen')||
      // (this.state.venuespecfic.events[e].name == 'Cub')||
      // (this.state.venuespecfic.events[e].name == 'Cadet')||
      // (this.state.venuespecfic.events[e].name == 'Junior')||
      // (this.state.venuespecfic.events[e].name == 'Young Adult')
    ) {
      window.location.replace('/classvalues');
      sessionStorage.setItem('classvalueid', 'NA');
      sessionStorage.removeItem('classvalueindex');
    } else {
      window.location.replace('/classvalues');
      // return <Redirect to="/classvalues" />;
    }
    // console.log(this.state.venuespecfic.events[e].name);
  }

  backhome() {
    var self = this;
    sessionStorage.removeItem('x');
    window.location.replace('/venues');
  }

  check = (e) => {
    this.setState({
      isRelaychecked: e.target.checked,
    });
  };
  optionchange = (e) => {
    this.setState({
      deftypepool: e.target.value,
    });
  };

  render() {
    let textval = sessionStorage.getItem('venueid');

    // Event of tracks-------------------------------------------------------------------------------------------

    if (this.state.loader) {
      return <Redirect to='/venueclasses' />;
    }

    //Classvalues
    if (this.state.classhome) {
      return <Redirect to='/classvalues' />;
    }

    if (Object.keys(this.state.venuedatas[0]).length == 0) {
      return (
        <>
          <div>
            <div className='content'>
              <Row>
                <Col md='8'>
                  <Card>
                    <CardHeader>
                      <h5 className='title'>Venues</h5>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <div class='form-group row'>
                          <div class='col-xs-5'>
                            <CardHeader>
                              <p>Search</p>
                              <input class='form-control' id='inputsm' placeholder='Search..' type='text' />
                            </CardHeader>
                          </div>
                        </div>
                        {/* Table -------------------------------------------- */}
                        <Table id='my' responsive>
                          <thead>
                            <tr>
                              <th className='text-center'>#</th>
                              <th>Name</th>

                              <th class='disabled-sorting text-right'>Actions</th>
                            </tr>
                          </thead>
                          <tbody id='myTable'>
                            <tr>
                              <td>1</td>
                              <td></td>
                              <td class='td-actions text-right'>
                                <button type='button' rel='tooltip' class='btn btn-danger btn-sm btn-icon'>
                                  <i class='now-ui-icons gestures_tap-01'></i>
                                </button>
                                {` `}
                                <button type='button' rel='tooltip' class='btn btn-success btn-sm btn-icon'>
                                  <i class='now-ui-icons ui-2_settings-90'></i>
                                </button>
                                {` `}
                                <button type='button' rel='tooltip' class='btn btn-danger btn-sm btn-icon'>
                                  <i class='now-ui-icons ui-1_simple-remove'></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        {/* Table -------------------------------------------- */}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </>
      );
    }

    // events ----------------------------------------------------------------------------------------

    if (Object.keys(this.state.venuespecfic).length != 0) {
      return (
        <>
          {this.state.preloader ? (
            <>
              <div className='svg-wrapper'>
                <Loader
                  type='ThreeDots'
                  color='#00BFFF'
                  height={70}
                  width={70}
                  // timeout={3000} //3 secs
                />
                <p>Loading</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='content'>
                  <Row>
                    <Col md='8'>
                      <button type='button' class='btn btn-danger btn-round' onClick={this.backhome}>
                        Back
                      </button>
                      <button
                        type='button'
                        data-toggle='modal'
                        data-target='#addevent'
                        class='btn btn-danger btn-round'
                      >
                        New Event
                      </button>{' '}
                      {/* <button type="button" data-toggle="modal" data-target="#addvenueclass" class="btn btn-danger btn-round">New Venue class</button>{' '}{' '}{' '}{' '} */}
                      {/* <button type="button" data-toggle="modal" data-target="#addclassvalues" class="btn btn-danger btn-round">New Class value</button>{' '}{' '}{' '} */}
                      <Card>
                        <CardHeader>
                          <h5 className='title'>Events</h5>
                        </CardHeader>
                        <CardBody>
                          <Form>
                            <div class='form-group row'>
                              <div class='col-xs-5'>
                                <CardHeader>
                                  <p>Search</p>
                                  <input
                                    class='form-control'
                                    id='inputsm'
                                    onChange={(e) => this.onSearch(e)}
                                    placeholder='Search..'
                                    type='text'
                                  />
                                </CardHeader>
                              </div>
                            </div>

                            <Table id='my' responsive>
                              <thead>
                                <tr>
                                  <th className='text-center'>#</th>
                                  <th>Name</th>
                                  {sessionStorage.getItem('venue_name') == 'Archery' ? <th>Age Range</th> : ''}
                                  {sessionStorage.getItem('venue_name') == 'Swimming' ? <th>Typepool</th> : ''}
                                  {sessionStorage.getItem('venue_name') == 'Track' ||
                                  sessionStorage.getItem('venue_name') == 'Swimming' ? (
                                    <th>Relay</th>
                                  ) : (
                                    ''
                                  )}
                                  <th class='disabled-sorting text-right'>Actions</th>
                                </tr>
                              </thead>
                              <tbody id='myTable'>
                                {this.state.venuespecfic.events.map((question, index) => (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{question.name}</td>
                                    {sessionStorage.getItem('venue_name') == 'Swimming' ? (
                                      <td>{question.typepool}</td>
                                    ) : (
                                      ''
                                    )}
                                    {sessionStorage.getItem('venue_name') == 'Archery' ? (
                                      <td>{question.age_range}</td>
                                    ) : (
                                      ''
                                    )}
                                    {sessionStorage.getItem('venue_name') == 'Track' ||
                                    sessionStorage.getItem('venue_name') == 'Swimming' ? (
                                      <td>
                                        <label class='chkbx'>
                                          <input
                                            type='checkbox'
                                            // id={question.name}
                                            className='ischeck'
                                            checked={question.is_relay == '1' ? 'true' : ''}
                                            // defaultChecked={question.is_relay}
                                            // onChange={(e)=>this.checkresult(e)}
                                          />
                                        </label>
                                      </td>
                                    ) : (
                                      ''
                                    )}
                                    <td class='td-actions text-right'>
                                      {question.name == '3X3 Basketball Div1' ||
                                      question.name == '3X3 Basketball Div2' ||
                                      question.name == '3X3 Basketball Women' ? (
                                        ''
                                      ) : (
                                        <button
                                          type='button'
                                          rel='tooltip'
                                          id='click'
                                          disabled={question.is_relay == '1' ? 'true' : ''}
                                          class='btn btn-success btn-sm btn-icon'
                                          onClick={(e) => this.eventclassvalues(index)}
                                        >
                                          <i class='now-ui-icons gestures_tap-01'></i>
                                        </button>
                                      )}

                                      {` `}
                                      <button
                                        type='button'
                                        rel='tooltip'
                                        class='btn btn-warning btn-sm btn-icon'
                                        onClick={() => this.eventedit(index)}
                                        data-toggle='modal'
                                        data-target='#eventedit'
                                      >
                                        <i class='now-ui-icons ui-2_settings-90'></i>
                                      </button>
                                      {` `}
                                      <button
                                        type='button'
                                        rel='tooltip'
                                        class='btn btn-danger btn-sm btn-icon'
                                        onClick={() => this.eventdelete(index)}
                                      >
                                        <i class='now-ui-icons ui-1_simple-remove'></i>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>

                {/* venue class------------------------------------------------------------ */}

                {/* <div className="content">
            <Row>
              <Col md="8">

                <Card>
                  <CardHeader>
                    <h5 className="title">Class Values</h5>
                  </CardHeader>
                  <CardBody>
                    <Form>

                      <div class="form-group row">
                        <div class="col-xs-5">
                          <CardHeader>
                            <p>Search</p>
                            <input class="form-control" id="inputsm" placeholder="Search.." type="text" />
                          </CardHeader>
                        </div>
                      </div>
                      <Table id="my" responsive>
                        <thead>
                          <tr>
                            <th className="text-center">#</th>
                            <th>Name</th>


                            <th class="disabled-sorting text-right">Actions</th>
                          </tr>
                        </thead>
                        <tbody id="myTable">
                          {this.state.classdata.map((question, index) => (
                            // console.log(question),
                            <tr>
                              <td>{index + 1}</td>
                              <td >{question.name}</td>
                              <td class="td-actions text-right">
                                <button type="button" rel="tooltip" class="btn btn-success btn-sm btn-icon" onClick={() => this.venueclassvalueedit(index)} data-toggle="modal" data-target="#editclassvalues">
                                  <i class="now-ui-icons ui-2_settings-90"></i>
                                </button>{` `}
                                <button type="button" rel="tooltip" class="btn btn-danger btn-sm btn-icon" onClick={() => this.deleteclassvalue(index)} >
                                  <i class="now-ui-icons ui-1_simple-remove"></i>
                                </button>
                              </td>
                            </tr>
                          ))}

                        </tbody>
                      </Table>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div> */}

                {/*----------------------------------------------------------------------------*/}

                {/* venue class value add */}
                <div class='modal fade' id='addclassvalues' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog '>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Add Venue class{' '}
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <form>
                        <div class='modal-body'></div>
                        <div class='form-group col-md-8'>
                          <label for='inputEmail4'>Name *</label>
                          <input
                            type='text'
                            class='form-control'
                            name='newclassvalue'
                            placeholder='please enter name'
                            onChange={this.handleChange}
                          />
                        </div>
                        <center>
                          <button
                            type='submit'
                            onClick={this.createclassvalue}
                            data-dismiss='modal'
                            class='btn btn-danger'
                          >
                            Create class value
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>

                {/* edit class value */}
                <div class='modal fade' id='editclassvalues' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog '>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Edit Class Value
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <form>
                        <div class='modal-body'></div>
                        <div class='form-group col-md-8'>
                          <label for='inputEmail4'>Name *</label>
                          <input
                            type='text'
                            class='form-control'
                            name='newclsvalue'
                            value={this.state.newclsvalue}
                            placeholder='please enter name'
                            onChange={this.handleChange}
                          />
                        </div>

                        <center>
                          <button
                            type='submit'
                            onClick={this.updateclassvalue}
                            data-dismiss='modal'
                            class='btn btn-danger'
                          >
                            Update class value
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>

                {/*----------------------------------------------------------------------------*/}

                {/* edit event ---------------------------------------------- */}
                <div class='modal fade' id='eventedit' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog '>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Edit venue{' '}
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <form>
                        <div class='modal-body'></div>
                        <div class='form-group col-md-8'>
                          <label for='inputEmail4'>Name *</label>
                          <input
                            type='text'
                            class='form-control'
                            name='editename'
                            value={this.state.editename}
                            onChange={this.handleChange}
                          />
                        </div>
                        {sessionStorage.getItem('venue_name') == 'Swimming' ? (
                          <div class='form-group col-md-4'>
                            <label>TypePool</label>
                            <select
                              class='form-control'
                              id='exampleFormControlSelect1'
                              name='editdeftypepool'
                              value={this.state.editdeftypepool}
                              onChange={this.OptionChange1}
                              required
                            >
                              <option>Please Select</option>
                              <option value='YD'>YD</option>
                              <option value='LC'>LC</option>
                              <option value='SC'>SC</option>
                            </select>
                          </div>
                        ) : (
                          ''
                        )}
                        {sessionStorage.getItem('venue_name') == 'Track' ||
                        sessionStorage.getItem('venue_name') == 'Swimming' ? (
                          <label class='chkbx1'>
                            <input
                              type='checkbox'
                              name='editisrelaychecked'
                              id='isRelay'
                              checked={this.state.editisrelaychecked == '1' ? 'true' : ''}
                              onClick={this.handleChange3}
                            />{' '}
                            Is Relay
                          </label>
                        ) : (
                          ''
                        )}
                        {sessionStorage.getItem('venue_name') == 'Archery' ? (
                          <div class='form-group col-md-8'>
                            <label for='inputEmail4'>Age Range *</label>
                            <input
                              type='text'
                              class='form-control'
                              name='editagerange'
                              value={this.state.editagerange}
                              onChange={this.handleChange2}
                            />
                          </div>
                        ) : (
                          ''
                        )}
                        <center>
                          <button type='submit' onClick={this.updateevent} data-dismiss='modal' class='btn btn-danger'>
                            Update venue
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>
                {/* edit event ---------------------------------------------- */}

                {/* add event -------------------------------------------------------------- */}
                <div class='modal fade' id='addevent' tabindex='-1' role='dialog' aria-hidden='true'>
                  <div class='modal-dialog '>
                    <div class='modal-content'>
                      <div class='modal-header '>
                        <h5 class='modal-title ' id='loginmodalLabel'>
                          Add Event{' '}
                        </h5>
                        <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                      <form>
                        <div class='modal-body'></div>
                        <div class='form-group col-md-8'>
                          <label for='inputEmail4'>Name *</label>
                          <input type='text' class='form-control' name='newevents' onChange={this.handleChange} />
                        </div>
                        {sessionStorage.getItem('venue_name') == 'Swimming' ? (
                          <div class='form-group col-md-4'>
                            <label>TypePool</label>
                            <select
                              class='form-control'
                              id='exampleFormControlSelect1'
                              name='typepool'
                              // value={this.state.deftypepool}
                              onChange={this.optionchange}
                              required
                            >
                              <option>Please Select</option>
                              <option value='YD'>YD</option>
                              <option value='LC'>LC</option>
                              <option value='SC'>SC</option>
                            </select>
                          </div>
                        ) : (
                          ''
                        )}
                        {sessionStorage.getItem('venue_name') == 'Track' ||
                        sessionStorage.getItem('venue_name') == 'Swimming' ? (
                          <label class='chkbx1'>
                            <input
                              type='checkbox'
                              id='isRelay'
                              value={this.state.isRelaychecked}
                              onChange={(e) => this.check(e)}
                              // defaultChecked={this.state.checked}
                            />{' '}
                            Is Relay
                          </label>
                        ) : (
                          ''
                        )}
                        {sessionStorage.getItem('venue_name') == 'Archery' ? (
                          <div class='form-group col-md-8'>
                            <label for='inputEmail4'>Age Range *</label>
                            <input type='text' class='form-control' name='newageevents' onChange={this.handleChange1} />
                          </div>
                        ) : (
                          ''
                        )}
                        <center>
                          <button type='submit' onClick={this.createevent} data-dismiss='modal' class='btn btn-danger'>
                            Create Event
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>
                {/* add event -------------------------------------------------------------- */}
              </div>
            </>
          )}
        </>
      );
    }
  }
}

export default FullScreenMap;
