import React from 'react';

import '../App.css';
import './compound.css';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import { Empty } from 'antd';
import 'datatables.net';
import 'datatables.net-bs4';
import Loader from 'react-loader-spinner';
const url = window.globalConfig;
class Printfieldresultsummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event_data: [{}],
      relay: false,
      notrelay: false,
      finalresultdata: '',
      finalplacedata: '',
      winddata: '',
      implementdata: '',
      wtfinaldata: '',
      breakdata: '',
      njdcrecorddata: '',
      nationaldata: '',
      americandata: '',
      americasdata: '',
      njdcdata: '',
      pointdata: '',
      bestdata: '',
      performancedata: '',
      eventdatedata: '',
      qualifydata: '',
      teampointdata: '',
      meetdatas: [{}],
      loader: true,
      sortbyvaluesdata: [{}],
      issortdata: false,
      ispitdata: true,
      fieldnjdcdatas: [{}],
    };
  }
  async componentDidMount() {
    var self = this;
    const data = {
      meet_id: sessionStorage.getItem('ids'),
    };
    await axios
      .post(`${url.url}/api/getfieldresultsummary/`, data, {})
      .then(function (response) {
        self.setState({
          event_data: response.data,
          loader: false,
        });
      })
      .catch(function (error) {});
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
    document.getElementById('homelink').style.display = 'block';
  }

  render() {
    return (
      <Form>
        <CardHeader>
          <center>
            <h5 className='title'>Field Results Summary</h5>
          </center>
        </CardHeader>
        {Object.keys(this.state.event_data).length == 0 ? (
          <Empty />
        ) : (
          <>
            <table class='worksheet_table'>
              <thead>
                <tr class='worksheet_tr'>
                  <th>Last Name</th>
                  <th>First Name</th>
                  <th>Country</th>
                  <th>Final Place</th>
                  <th>Qualifying Marks</th>
                  <th>Wind</th>
                  <th>Implements</th>
                  <th>Wtfinal</th>
                  <th>Class</th>
                  <th>Age</th>
                  <th>Gender</th>
                  <th>EventName</th>
                  <th>Team Name</th>
                </tr>
              </thead>
              <tbody>
                {this.state.event_data.map(
                  (question, index) =>
                    question.selected_events && (
                      <tr class='worksheet_tr'>
                        <td>{question.last_name}</td>
                        <td>{question.first_name}</td>
                        <td>{question.country}</td>
                        <td>{question.final_place}</td>
                        <td>{question.Qualifying_marks}</td>
                        <td>{question.wind}</td>
                        <td>{question.implements}</td>
                        <td>{question.wtfinal}</td>
                        <td>{question.field_class_value}</td>
                        <td>{question.age_division}</td>
                        <td>{question.gender}</td>
                        <td>{question.selected_events}</td>
                        <td>{question.team_name}</td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </>
        )}
      </Form>
    );
  }
}

export default Printfieldresultsummary;
