import React from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';

import Swal from 'sweetalert2';

import { Select, Input } from 'antd';
import 'antd/dist/antd.css';
import { Empty } from 'antd';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner';
var x;
const url = window.globalConfig;
class Athletefield extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      venues: [{}],
      venueid: [],
      tname: [],
      tnamesid: '',
      fieldeventdata: [],
      fieldsdata: [],
      fieldsets: [{}],
      fieldevents: [],
      fielddatas: [{}],
      Qualifying_marks: '',
      qualifying_standard: '',
      pits: '',
      btncheck: true,
      selectedevents: '',
      fieldeventsdata: '',
      allregdatas: [{}],
      classdata: [{}],
      qstddatas: {},
      loader: true,
    };
    this.check = this.check.bind(this);
    this.tinput = this.tinput.bind(this);
    this.edit = this.edit.bind(this);
    this.createfieldreg = this.createfieldreg.bind(this);
    this.updatefieldreg = this.updatefieldreg.bind(this);
    this.editclassage = this.editclassage.bind(this);
    this.onchange1 = this.onchange1.bind(this);
    this.onchange2 = this.onchange2.bind(this);
    this.updateclassage = this.updateclassage.bind(this);
    this.deletefieldreg = this.deletefieldreg.bind(this);
  }
  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    let itemindex = sessionStorage.getItem('itemindex');
    var self = this;
    var count = 0;
    let indx = sessionStorage.getItem('fieldindex');
    let ids = sessionStorage.getItem('ids');
    axios.get(`${url.url}/api/getfieldmeetid/${ids}`, {}).then(function (response) {
      self.setState({
        fieldsets: response.data,
      });
    });
    const fieldeventdata = (index) => {
      if (index.field_events != 'null') {
        var joined = this.state.fieldeventdata.concat(index);
        this.setState({
          fieldeventdata: joined,
          fieldsdata: joined[0],
        });
      } else {
        self.setState({
          fieldsdata: index,
        });
      }
    };
    let athleteid = sessionStorage.getItem('athleteid');
    axios
      .get(`${url.url}/api/getathleteregisterid/${athleteid}`, {})
      .then(function (response) {
        self.setState({
          allregdatas: response.data,
        });
        {
          response.data.map((index) => fieldeventdata(index));
        }
        var valdata = self.state.fieldeventdata[0].field_events.split(',');

        self.setState({
          fieldsdata: self.state.fieldeventdata[0],
          fieldevents: valdata,
        });
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {
        self.setState({
          loader: false,
        });
      });

    let venid = sessionStorage.getItem('venuesid');
    axios.get(`${url.url}/api/getclassvalue/${venid}`, {}).then(function (response) {
      self.setState({
        classdata: response.data,
      });
      count++;
      if (count == 3) {
        self.setState({
          loader: false,
        });
      }
    });

    let regid = sessionStorage.getItem('regid');
    axios
      .get(`${url.url}/api/getFregisterid/${athleteid}`, {})
      .then(function (response) {
        self.setState({
          fielddatas: response.data,
          fielddatas1: response.data[0],
        });
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      })
      .catch(function (error) {
        count++;
        if (count == 3) {
          self.setState({
            loader: false,
          });
        }
      });

    //Hide and show manager
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
  }
  check = (e) => {
    var temp = [];
    if (Object.keys(this.state.tname).length != 0) {
      temp = this.state.tname;
    }
    if (!this.state.tname.some((item) => e.target.value === item.name)) {
      temp.push({ name: e.target.value, id: e.target.id });
    }
    this.setState({ tname: temp, tnamesid: e.target.value });
    this.edit(e.target.id);
  };
  handlechange() {
    window.location.replace('/athletevenues');
  }

  tinput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  async edit(e) {
    var self = this;
    var qstd = {
      venue_name: 'Field',
      event_name: this.state.fieldsets[e].event_name,
      Class_values: this.state.allregdatas[0].field_class_value,
      Age_division: this.state.allregdatas[0].field_age,
      Gender: this.state.allregdatas[0].gender,
    };
    // await axios.post(`${url.url}/api/getqualstd`, qstd, {}).then(function (response) {
    //   self.setState({
    //     qstddatas: response.data.data,
    //   });
    // });
    // self.setState({
    //   fieldeventsdata: self.state.fieldevents[e],
    // });
    var loop = true;

    self.state.fielddatas.forEach((question, index) => {
      if (question.selected_events == self.state.fieldsets[e].event_name) {
        self.setState({
          selected_id: question.id,
          selected_events: question.selected_events,
          Qualifying_marks: question.Qualifying_marks,
          qualifying_standard:
            // question.qualifying_standard == null
            //   ? self.state.qstddatas[0].qualifying_standards
            //   :
            question.qualifying_standard,
          pits: question.pits,
          groups: question.groups,
          btncheck: false,
        });
        loop = false;
      } else if (loop) {
        self.setState({
          selected_id: '',
          selectedevents: self.state.fieldsets[e].event_name,
          Qualifying_marks: '',
          qualifying_standard: question.qualifying_standard,
          pits: '',
          groups: '',
          btncheck: true,
        });
        loop = true;
      }
    });

    if (self.state.fielddatas.length == 0) {
      self.setState({
        selected_id: '',
        selectedevents: self.state.fieldsets[e].event_name,
        Qualifying_marks: '',
        qualifying_standard: self.state.qstddatas[0].qualifying_standards,
        pits: '',
        groups: '',
        btncheck: true,
      });
    }
  }

  editclassage() {
    var self = this;
    self.setState({
      fieldclass: self.state.allregdatas[0].field_class_value,
      fieldage: self.state.allregdatas[0].field_age,
    });
  }
  onchange1(e) {
    this.setState({ fieldclass: e.target.value });
  }
  onchange2(e) {
    this.setState({ fieldage: e.target.value });
  }

  updateclassage() {
    const data = {
      field_age: this.state.fieldage,
      field_class_value: this.state.fieldclass,
    };
    let athleteid = sessionStorage.getItem('athleteid');
    axios
      .put(`${url.url}/api/updateclassagevalue/${athleteid}`, data, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }
  createfieldreg(e) {
    var self = this;

    const setvalue = (q, index) => {
      if (self.state.fielddatas.length > index) {
        self.setState({
          selected_events: self.state.fielddatas[index].selected_events,
          Qualifying_marks: self.state.fielddatas[index].Qualifying_marks,
          qualifying_standard: self.state.fielddatas[index].qualifying_standard,
          pits: self.state.fielddatas[index].pits,
        });
      }
    };
    self.state.fieldevents.map((question, index) => {
      setvalue(question, index);
    });

    const datatosend = {
      first_name: this.state.fieldsdata.firstname,
      last_name: this.state.fieldsdata.lastname,
      field_class_value: this.state.fieldsdata.field_class_value,
      age_division: this.state.fieldsdata.field_age,
      gender: this.state.fieldsdata.gender,
      selected_events: this.state.selectedevents,
      pentathlon_class: this.state.fieldsdata.pentathlon_class_value
        ? this.state.fieldsdata.pentathlon_class_value
        : 'null',
      field_class: this.state.fieldsdata.field_class_value,
      track_class: this.state.fieldsdata.track_class_value ? this.state.fieldsdata.track_class_value : 'null',
      Qualifying_marks: this.state.Qualifying_marks,
      qualifying_standard: this.state.qualifying_standard,
      pits: this.state.pits,
      groups: this.state.groups,
      created_by: 1,
      register_id: parseInt(sessionStorage.getItem('athleteid')),
      meet_id: parseInt(sessionStorage.getItem('ids')),
      bib_no: this.state.allregdatas[0].bib_no,
      team_name: this.state.allregdatas[0].team_name,
      country: this.state.allregdatas[0].country,
      register_num: this.state.allregdatas[0].register_num,
    };
    axios
      .post(`${url.url}/api/createfieldreg/`, datatosend, {})
      .then(function (response) {
        if (response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  updatefieldreg(e) {
    var self = this;

    const datatosend = {
      first_name: this.state.fieldsdata.firstname,
      last_name: this.state.fieldsdata.lastname,
      field_class_value: this.state.fieldsdata.field_class_value,
      age_division: this.state.fieldsdata.field_age,
      gender: this.state.fieldsdata.gender,
      selected_events: this.state.selected_events,
      pentathlon_class: this.state.fieldsdata.pentathlon_class_value
        ? this.state.fieldsdata.pentathlon_class_value
        : 'null',
      field_class: this.state.fieldsdata.field_class_value,
      track_class: this.state.fieldsdata.track_class_value ? this.state.fieldsdata.track_class_value : 'null',
      Qualifying_marks: this.state.Qualifying_marks,
      qualifying_standard: this.state.qualifying_standard,
      pits: this.state.pits,
      groups: this.state.groups,
      bib_no: this.state.allregdatas[0].bib_no,
    };
    axios
      .put(`${url.url}/api/updatefieldreg/${this.state.selected_id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  deletefieldreg(e) {
    const datatosend = {
      selected_events: '',
      Qualifying_marks: '',
      qualifying_standard: '',
      pits: '',
      groups: '',
    };
    axios
      .put(`${url.url}/api/deletefieldreg/${this.state.selected_id}`, datatosend, {})
      .then(function (response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Status Deleted successfully',
            showConfirmButton: false,
            timer: 1000,
          }).then(function (response) {
            window.location.reload();
          });
        }
      })

      .catch(function (error) {
        Swal.fire({
          title: 'Failed',
          text: 'Something went wrong',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        });
      });
  }

  render() {
    const { selectedItems } = this.state;

    return (
      <>
        {this.state.loader ? (
          <>
            <div className='svg-wrapper'>
              <Loader type='ThreeDots' color='#00BFFF' height={70} width={70} />
              <p>Loading</p>
            </div>
          </>
        ) : (
          <>
            <div className='content'>
              <Row>
                <Col md='8'>
                  <button
                    type='button'
                    data-toggle='modal'
                    data-target='#selectfield'
                    class='btn btn-danger btn-round'
                    onClick={this.handlechange}
                  >
                    Back
                  </button>
                  <Card>
                    <CardHeader>
                      <h5 className='title'>Field Events</h5>
                    </CardHeader>

                    <CardBody>
                      <Form>
                        <div class='form-group row'>
                          <div class='col-xs-5'>
                            <CardHeader className='athlete_tracktb' id='deatils_heading'>
                              <div>
                                <th>
                                  <tr>
                                    <div>
                                      <label>Registration no:</label>
                                      <span>{this.state.fieldsdata.register_num}</span>
                                    </div>

                                    <div>
                                      <label>First Name:</label>
                                      <span>{this.state.fieldsdata.firstname}</span>
                                    </div>

                                    <div>
                                      <label>Last Name:</label>
                                      <span>{this.state.fieldsdata.lastname}</span>
                                    </div>
                                  </tr>
                                  <tr>
                                    <div>
                                      <label> Field Class:</label>
                                      <span>
                                        {this.state.fieldsdata.field_class_value
                                          ? this.state.fieldsdata.field_class_value
                                          : 'u/a'}
                                      </span>
                                    </div>

                                    <div>
                                      <label> BIB no:</label>
                                      <span>{this.state.fieldsdata.bib_no}</span>
                                    </div>

                                    <div>
                                      <label>Age Division:</label>
                                      <span>{this.state.fieldsdata.field_age}</span>
                                    </div>

                                    <div>
                                      <label> Gender:</label>
                                      <span>{this.state.fieldsdata.gender}</span>
                                    </div>
                                  </tr>
                                </th>
                              </div>

                              <button
                                type='button'
                                rel='tooltip'
                                data-toggle='modal'
                                data-target='#meetedit'
                                class='btn btn-success btn-sm btn-icon'
                                onClick={this.editclassage}
                              >
                                <i class='now-ui-icons ui-2_settings-90'></i>
                              </button>
                            </CardHeader>
                          </div>
                        </div>
                        {Object.keys(this.state.fieldsets).length == 0 ? (
                          <Empty />
                        ) : (
                          <>
                            <Table id='my' responsive>
                              <thead>
                                <tr>
                                  <th>Athletes Events</th>
                                  <th></th>
                                  <th>Selected Events</th>
                                  <th>Qualifying Marks</th>
                                  <th>Qualifying Standard</th>
                                  <th>Pits</th>
                                  <th>Group</th>
                                </tr>
                              </thead>
                              <tbody id='myTable'>
                                {this.state.fieldsets.map((question, index) => (
                                  <>
                                    <tr>
                                      <td>
                                        <button
                                          type='button'
                                          class='btn btn-success btn-sm'
                                          data-toggle='modal'
                                          data-target='#useredit'
                                          id={index}
                                          value={question.event_name}
                                          onClick={(e) => this.check(e)}
                                        >
                                          {question.event_name}
                                        </button>
                                      </td>

                                      <td style={{ backgroundColor: '#ffff6a' }}></td>

                                      <td>
                                        {this.state.fielddatas.map((question1, index) =>
                                          question.event_name === question1.selected_events
                                            ? question1.selected_events
                                            : ''
                                        )}
                                      </td>

                                      <td>
                                        {this.state.fielddatas.map((question1, index) =>
                                          question.event_name === question1.selected_events
                                            ? question1.Qualifying_marks
                                            : ''
                                        )}
                                      </td>

                                      <td>
                                        {this.state.fielddatas.map((question1, index) =>
                                          question.event_name === question1.selected_events
                                            ? question1.qualifying_standard
                                            : ''
                                        )}
                                      </td>

                                      <td>
                                        {this.state.fielddatas.map((question1, index) =>
                                          question.event_name === question1.selected_events ? question1.pits : ''
                                        )}
                                      </td>
                                      <td>
                                        {this.state.fielddatas.map((question1, index) =>
                                          question.event_name === question1.selected_events ? question1.groups : ''
                                        )}
                                      </td>
                                    </tr>
                                  </>
                                ))}
                              </tbody>
                            </Table>
                          </>
                        )}
                        <p class='athletefootertxt'>
                          <span class='athletefootertxtstar'>*</span>Indicates a Possible Invalid or Non standard Event
                          for this Class, Age and Gender
                        </p>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                {/* User table with edit delete------------------------------------------------------------------------------ */}
              </Row>

              {/* Field event reg model */}

              <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        {this.state.tnamesid + ' ' + 'Field'}{' '}
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='exampleFormControlSelect1'>Qualifying Marks</label>
                          <input
                            class='form-control'
                            value={this.state.Qualifying_marks}
                            onChange={(e) =>
                              this.setState({
                                Qualifying_marks: e.target.value,
                              })
                            }
                            name='quatime'
                          />
                        </div>

                        <div class='form-row'>
                          <div class='form-group'>
                            <label for='inputEmail4'>Qualifying Standard</label>
                            <input
                              class='form-control'
                              value={this.state.qualifying_standard}
                              onChange={(e) =>
                                this.setState({
                                  qualifying_standard: e.target.value,
                                })
                              }
                              type='text'
                              name='quastd'
                              disabled='disabled'
                            />
                          </div>

                          <div class='form-group'>
                            <label for='inputPassword4'>Pits</label>
                            <input
                              class='form-control'
                              value={this.state.pits}
                              onChange={(e) => this.setState({ pits: e.target.value })}
                              name='points'
                            />
                          </div>
                          <div class='form-group'>
                            <label for='inputPassword5'>Group</label>
                            <input
                              class='form-control'
                              value={this.state.groups}
                              onChange={(e) => this.setState({ groups: e.target.value })}
                              name='group'
                            />
                          </div>
                        </div>

                        {this.state.btncheck == true ? (
                          <center>
                            <button
                              type='button'
                              onClick={(e) => this.createfieldreg()}
                              data-dismiss='modal'
                              class='btn btn-danger'
                            >
                              Create
                            </button>
                          </center>
                        ) : (
                          <>
                            <center>
                              <button type='button' class='btn btn-danger' onClick={(e) => this.updatefieldreg()}>
                                Update
                              </button>
                              {` `}
                              <button
                                type='button'
                                class='btn btn-danger'
                                data-dismiss='modal'
                                onClick={(e) => this.deletefieldreg()}
                              >
                                Delete
                              </button>
                            </center>
                          </>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class='modal fade' id='meetedit' tabindex='-1' role='dialog' aria-hidden='true'>
                <div class='modal-dialog'>
                  <div class='modal-content'>
                    <div class='modal-header '>
                      <h5 class='modal-title ' id='loginmodalLabel'>
                        Field
                      </h5>
                      <button type='button' class='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <form>
                        <div class='form-group'>
                          <label for='exampleFormControlSelect1'>Field Class</label>
                          <input
                            class='form-control'
                            value={this.state.fieldclass}
                            onChange={this.onchange1}
                            name='quastd'
                          />
                        </div>

                        <div class='form-group'>
                          <label for='inputEmail4'>Age Division</label>
                          <input
                            class='form-control'
                            value={this.state.fieldage}
                            onChange={this.onchange2}
                            name='quastd'
                          />
                        </div>
                        <center>
                          <button type='button' class='btn btn-danger' onClick={(e) => this.updateclassage()}>
                            Update
                          </button>
                        </center>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
export default Athletefield;
