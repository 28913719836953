import React from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Table } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import './compound.css';

import Swal from 'sweetalert2';

import { Select, Input } from 'antd';
import 'antd/dist/antd.css';
import { Empty } from 'antd';
import { withRouter } from 'react-router-dom';
const url = window.globalConfig;
class Assignpits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      venues: [{}],
      venueid: [],
      tname: [],
      fieldeventdata: [],
      fieldsdata: [],
      fieldevents: [],
      fielddatas: [],
      Qualifying_marks: '',
      qualifying_standard: '',
      pits: '',
      btncheck: true,
      selectedevents: '',
      allregdatas: [{}],
      classdata: [{}],
      getfieldbibdata: [{}],
      pitdata: {},
      groupdata: '',
      eventdatas: [{}],
      setpitdata: [],
      pitsdatas: [],
      searchValue: '',
    };
    this.edit = this.edit.bind(this);
    this.update = this.update.bind(this);
    this.handlechange = this.handlechange.bind(this);
  }
  componentDidMount() {
    $(document).ready(function () {
      $('#inputsm').on('keyup', function () {
        var value = $(this).val().toLowerCase();
        $('#myTable tr').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });
    var self = this;
    const data = {
      meet_id: sessionStorage.getItem('ids'),
    };
    axios.post(`${url.url}/api/getassignpits/`, data, {}).then(function (response) {
      self.setState({
        fieldeventdata: response.data,
      });
    });

    //Hide and show manager
    document.getElementById('eventset').style.display = 'block';
    document.getElementById('venuessetup').style.display = 'block';
    document.getElementById('bib').style.display = 'block';
    document.getElementById('aregis').style.display = 'block';
    document.getElementById('homelink').style.display = 'block';
    document.getElementById('meetview').style.display = 'block';
    document.getElementById('meets').style.display = 'none';
  }

  handlechange() {
    window.location.replace('/venuemainpage?event=' + sessionStorage.getItem('event'));
  }

  edit(e) {
    var self = this;
    var temp = {};
    self.setState({
      eventdatas: e,
    });

    e.forEach((key, index) => {
      temp[key.selected_events] = key.pits || '';
    });
    self.setState({
      pitdata: temp,
      groupdata: e[0].groups ? e[0].groups : '',
    });
    // }
  }

  update(e) {
    var self = this;
    var pitdatas = this.state.pitdata;
    Object.keys(pitdatas).forEach(function (item, i) {
      self.state.setpitdata.push(pitdatas[item]);
    });
    self.state.eventdatas.map((question, index) => {
      const datatosend = {
        pits: this.state.setpitdata[index],
        groups: self.state.groupdata,
      };

      axios
        .put(`${url.url}/api/updateassignpits/${question.id}`, datatosend, {})
        .then(function (response) {
          if (response.status == 200) {
            Swal.fire({
              icon: 'success',
              title: 'Status Updated successfully',
              showConfirmButton: false,
              timer: 1000,
            }).then(function (response) {
              window.location.reload();
            });
          }
        })

        .catch(function (error) {
          Swal.fire({
            title: 'Failed',
            text: 'Something went wrong',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok!',
          });
        });
    });
  }
  pitChange = (e) => {
    this.setState({
      pitdata: { ...this.state.pitdata, [e.target.name]: e.target.value },
    });

    // setInputs((pitdata) => ({ ...pitdata, [e.target.nameme]: e.target.value }));
  };
  handleclose = () => {
    this.setState({
      pitsdatas: '',
    });
  };
  onSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    this.setState({ searchValue });
  };
  render() {
    const { fieldeventdata, searchValue } = this.state;
    var self = this;
    return (
      <>
        <div className='content'>
          <Row>
            <Col md='8'>
              <button
                type='button'
                data-toggle='modal'
                data-target='#selectfield'
                class='btn btn-danger btn-round'
                onClick={this.handlechange}
              >
                Back
              </button>
              <Card>
                <CardHeader>
                  <h5 className='title'>Assign Pits</h5>
                </CardHeader>
                <CardBody>
                  <div class='form-group row printrow'>
                    <div class='col-xs-5'>
                      <CardHeader>
                        <div>
                          <p>Search</p>
                          <input
                            class='form-control'
                            id='inputsm'
                            onChange={(e) => this.onSearch(e)}
                            placeholder='Search'
                            type='text'
                          />
                        </div>
                      </CardHeader>
                    </div>
                  </div>
                  <Form>
                    {Object.keys(this.state.fieldeventdata).map(function (key, index) {
                      if (fieldeventdata[key][0].selected_events) {
                        const itemText = fieldeventdata[key]
                          .map((keys) => {
                            return Object.values(keys).join(' ');
                          })
                          .join(' ')
                          .toLowerCase();
                        if (itemText.includes(searchValue)) {
                          return (
                            <div class='realy_main' id='wsheet'>
                              <div>
                                <p>
                                  <label class='relay_label'>Athlete's Name</label>
                                  {self.state.fieldeventdata[key][0].first_name}{' '}
                                  {self.state.fieldeventdata[key][0].last_name}
                                </p>
                                <p>
                                  <label class='relay_label'>Bib</label>
                                  {self.state.fieldeventdata[key][0].bib_no}
                                </p>
                                <p>
                                  <label class='relay_label'>Group</label>
                                  {self.state.fieldeventdata[key][0].groups}
                                </p>
                                <p>
                                  <label class='relay_label'>Class</label>
                                  {self.state.fieldeventdata[key][0].field_class_value}
                                </p>
                                <p>
                                  <label class='relay_label'>Age</label>
                                  {self.state.fieldeventdata[key][0].age_division}
                                </p>
                                <p>
                                  <label class='relay_label'>Gender</label>
                                  {self.state.fieldeventdata[key][0].gender}
                                </p>
                              </div>

                              <div>
                                <label class='relay_label'>Events</label>
                                {self.state.fieldeventdata[key].map((keys, i) => (
                                  <p>{keys.selected_events}</p>
                                ))}
                              </div>
                              <div>
                                <label class='relay_label'>Pits</label>
                                <p>
                                  {self.state.fieldeventdata[key].map((keys, i) => (
                                    <p>{keys.pits}</p>
                                  ))}
                                </p>
                              </div>
                              <div>
                                <label class='relay_label'>Actions</label>
                                <p>
                                  <button
                                    type='button'
                                    rel='tooltip'
                                    class='btn btn-success btn-sm btn-icon'
                                    onClick={(e) => self.edit(self.state.fieldeventdata[key])}
                                    data-toggle='modal'
                                    data-target='#useredit'
                                  >
                                    <i class='now-ui-icons ui-2_settings-90'></i>
                                  </button>
                                </p>
                              </div>
                            </div>
                          );
                        }
                      } else {
                        return null; // Hide items that don't match the search
                      }
                    })}
                  </Form>
                </CardBody>
              </Card>
            </Col>
            {/* User table with edit delete------------------------------------------------------------------------------ */}
          </Row>

          <div class='modal fade' id='useredit' tabindex='-1' role='dialog' aria-hidden='true'>
            <div class='modal-dialog'>
              <div class='modal-content'>
                <div class='modal-header '>
                  <h5 class='modal-title ' id='loginmodalLabel'>
                    Assign Pits
                  </h5>
                  <button
                    type='button'
                    class='close'
                    data-dismiss='modal'
                    aria-label='Close'
                    onClick={() => this.handleclose()}
                  >
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <div class='modal-body'>
                  <form>
                    {self.state.eventdatas.map((items, key) => (
                      <>
                        <label for='inputCity'>{items.selected_events + ' Pits'}</label>
                        <div class='form-row'>
                          <div class='form-group '>
                            <input
                              class='form-control'
                              name={items.selected_events}
                              value={
                                this.state.pitdata[items.selected_events]
                                  ? this.state.pitdata[items.selected_events]
                                  : ''
                              }
                              onChange={(e) => this.pitChange(e)}
                            />
                          </div>
                        </div>
                      </>
                    ))}
                    <div class='form-row'>
                      <div class='form-group '>
                        <label for='inputCity'>Groups</label>
                        <input
                          class='form-control'
                          value={this.state.groupdata}
                          onChange={(e) => this.setState({ groupdata: e.target.value })}
                          name='relaypoint'
                        />
                      </div>
                    </div>

                    <center>
                      <button type='button' class='btn btn-danger' onClick={(e) => this.update()}>
                        Update
                      </button>
                    </center>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Assignpits;
